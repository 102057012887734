import React from "react";
import "./App.css";
import { UnderHeaderTabelle } from "./components/UnderHeaderTabelle";
import axios from "axios";

export class Tabelle extends React.Component {
  constructor() {
    super();
    this.state = {
      eilmeldung: "",
      sperren: "Yes",

      WelcheGruppe: 0,

      SpieleGruppe1: [
        ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
    ],
    SpieleGruppe2: [
        ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
    ],
    
    BPMPGruppe1: [["", ""], ["", ""], ["", ""], ["", ""], ["", ""], ["", ""], ["", ""], ["", ""], ["", ""], ["", ""]],
    BPMPGruppe2: [["", ""], ["", ""], ["", ""], ["", ""], ["", ""], ["", ""], ["", ""], ["", ""], ["", ""], ["", ""]],

    Liga: ["", "", "", "", "", "", "", "", "","", "", "", "", "", "", "", "", "","", "", "", "", "", "", "", "", "","", "", "", "", "", "", "", "", "","", "", "", "", "", "", "", "", ""],
    
    TabelleGruppe1: [
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
    ],
    TabelleGruppe2: [
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
    ],
  };

  }

  componentDidMount() {
    axios
    .get("/api/Vereine")
    .then((res) => {
      const message1 = res.data;
      this.setState({ sperren: message1[0] });
    })
    .catch((error) => {
      console.log(error.message1);
    });

    axios
    .get("/api/Eilmeldung")
    .then((res) => {
      const message1 = res.data;
      this.setState({ 
        eilmeldung: message1,
      });
    })
    .catch((error) => {
      console.log(error.message1);
    });

    let url = window.location.pathname
    let laenge = url.length - 1;
    let Vereinsname = url.slice(-laenge)
    while(Vereinsname.includes("_")) {
        let Teil1 = Vereinsname.slice(0, Vereinsname.indexOf("_"))
        let Teil2 = Vereinsname.slice(Vereinsname.indexOf("_") + 1, laenge)
        Vereinsname = Teil1 + " " + Teil2
    }
    this.setState({Auswahl: Vereinsname})

    var getlink6 = "".concat("/api/LigaTabelle/", url.slice(-laenge).slice(0,url.slice(-laenge).length - 8));
    axios
    .get(getlink6)
    .then((res) => {
        const message3 = res.data;
        for (let a=0; a<45; a++) {
            if (message3[a]===null) {
                message3[a]="";
            }
        }
        this.setState({Liga: message3});
    })
    .catch((error) => {
        console.log(error.message3);
    });
    
    var getlink4 = "".concat("/api/SpieleGruppe1Tabelle/", url.slice(-laenge).slice(0,url.slice(-laenge).length - 8));
    axios
    .get(getlink4)
    .then((res) => {
        const message = res.data;
        let SpieleGruppe1Neu = [
            ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
        ]
        for (let a=0; a<150; a++) {
            for (let b=0; b<9; b++) {
                if (message[a][b]===null) {
                    message[a][b]="";    
                }
                SpieleGruppe1Neu[a][b]=message[a][b];
            }
        }
        let BPMPGruppe1Neu = [["", ""], ["", ""], ["", ""], ["", ""], ["", ""], ["", ""], ["", ""], ["", ""], ["", ""], ["", ""]]
        for (let a=0; a<10; a++) {
            for (let b=0; b<2; b++) {
                if ((message[150+a][9+b]===null || message[150+a][9+b]==="") && this.state.Liga[5+a]!==null && this.state.Liga[5+a]!=="") {
                    message[150+a][9+b]="0";
                }
                BPMPGruppe1Neu[a][b]=message[150+a][9+b];
            }
        }
        
        this.setState({
            SpieleGruppe1: SpieleGruppe1Neu,
            BPMPGruppe1: BPMPGruppe1Neu,
        });
    })
    .catch((error) => {
        console.log(error.message);
    });

    var getlink5 = "".concat("/api/SpieleGruppe2Tabelle/", url.slice(-laenge).slice(0,url.slice(-laenge).length - 8));
    axios
    .get(getlink5)
    .then((res) => {
        const message2 = res.data;
        let SpieleGruppe2Neu = [
            ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""], ["", "", "", "", "", "", "", "", ""],
        ]
        for (let a=0; a<150; a++) {
            for (let b=0; b<9; b++) {
                if (message2[a][b]===null) {
                    message2[a][b]="";
                }
                SpieleGruppe2Neu[a][b]=message2[a][b];
            }
        }
        let BPMPGruppe2Neu = [["", ""], ["", ""], ["", ""], ["", ""], ["", ""], ["", ""], ["", ""], ["", ""], ["", ""], ["", ""]]
        for (let a=0; a<10; a++) {
            for (let b=0; b<2; b++) {
                if ((message2[150+a][9+b]===null || message2[150+a][9+b]==="") && this.state.Liga[5+a]!==null && this.state.Liga[5+a]!=="") {
                    message2[150+a][9+b]="0";
                }
                BPMPGruppe2Neu[a][b]=message2[150+a][9+b];
            }
        }
        this.setState({
            SpieleGruppe2: SpieleGruppe2Neu,
            BPMPGruppe2: BPMPGruppe2Neu,
        });
    })
    .catch((error) => {
        console.log(error.message2);
    });

  }

  TabelleGruppe1() {
    return(
      <div className="ligatabelle">
        <table>
            <tbody>
            <tr>
                <th>Rang</th>
                <th>Kürzel</th>
                <th>Team</th>
                <th>Matches</th>
                <th>Punkte</th>
                <th>Sätze</th>
                <th>Spiele</th>
                <th>S</th>
                <th>N2</th>
                <th>N3</th>
                <th>BP</th>
                <th>MP</th>
            </tr>
            <tr className="dunkler">
                <td><div className="zeiten">1</div></td>
                <td>{this.state.TabelleGruppe1[0][0]}</td>
                <td>{this.state.TabelleGruppe1[0][1]}</td>
                <td>{this.state.TabelleGruppe1[0][2]}</td>
                <td><div className="zeiten">{this.state.TabelleGruppe1[0][3]}</div></td>
                <td>{this.state.TabelleGruppe1[0][4]}</td>
                <td>{this.state.TabelleGruppe1[0][5]}</td>
                <td>{this.state.TabelleGruppe1[0][6]}</td>
                <td>{this.state.TabelleGruppe1[0][7]}</td>
                <td>{this.state.TabelleGruppe1[0][8]}</td>
                <td>{this.state.TabelleGruppe1[0][9]}</td>
                <td>{this.state.TabelleGruppe1[0][10]}</td>
            </tr>
            {parseInt(this.state.Liga[3]) >=2 ? (
              <tr>
                <td><div className="zeiten">2</div></td>
                <td>{this.state.TabelleGruppe1[1][0]}</td>
                <td>{this.state.TabelleGruppe1[1][1]}</td>
                <td>{this.state.TabelleGruppe1[1][2]}</td>
                <td><div className="zeiten">{this.state.TabelleGruppe1[1][3]}</div></td>
                <td>{this.state.TabelleGruppe1[1][4]}</td>
                <td>{this.state.TabelleGruppe1[1][5]}</td>
                <td>{this.state.TabelleGruppe1[1][6]}</td>
                <td>{this.state.TabelleGruppe1[1][7]}</td>
                <td>{this.state.TabelleGruppe1[1][8]}</td>
                <td>{this.state.TabelleGruppe1[1][9]}</td>
                <td>{this.state.TabelleGruppe1[1][10]}</td>
              </tr>
            ) : (
              <></>
            )}
            {parseInt(this.state.Liga[3]) >=3 ? (
              <tr className="dunkler">
                <td><div className="zeiten">3</div></td>
                <td>{this.state.TabelleGruppe1[2][0]}</td>
                <td>{this.state.TabelleGruppe1[2][1]}</td>
                <td>{this.state.TabelleGruppe1[2][2]}</td>
                <td><div className="zeiten">{this.state.TabelleGruppe1[2][3]}</div></td>
                <td>{this.state.TabelleGruppe1[2][4]}</td>
                <td>{this.state.TabelleGruppe1[2][5]}</td>
                <td>{this.state.TabelleGruppe1[2][6]}</td>
                <td>{this.state.TabelleGruppe1[2][7]}</td>
                <td>{this.state.TabelleGruppe1[2][8]}</td>
                <td>{this.state.TabelleGruppe1[2][9]}</td>
                <td>{this.state.TabelleGruppe1[2][10]}</td>
              </tr>
            ) : (
              <></>
            )}
            {parseInt(this.state.Liga[3]) >=4 ? (
              <tr>
                <td><div className="zeiten">4</div></td>
                <td>{this.state.TabelleGruppe1[3][0]}</td>
                <td>{this.state.TabelleGruppe1[3][1]}</td>
                <td>{this.state.TabelleGruppe1[3][2]}</td>
                <td><div className="zeiten">{this.state.TabelleGruppe1[3][3]}</div></td>
                <td>{this.state.TabelleGruppe1[3][4]}</td>
                <td>{this.state.TabelleGruppe1[3][5]}</td>
                <td>{this.state.TabelleGruppe1[3][6]}</td>
                <td>{this.state.TabelleGruppe1[3][7]}</td>
                <td>{this.state.TabelleGruppe1[3][8]}</td>
                <td>{this.state.TabelleGruppe1[3][9]}</td>
                <td>{this.state.TabelleGruppe1[3][10]}</td>
              </tr>
            ) : (
              <></>
            )}
            {parseInt(this.state.Liga[3]) >=5 ? (
              <tr className="dunkler">
                <td><div className="zeiten">5</div></td>
                <td>{this.state.TabelleGruppe1[4][0]}</td>
                <td>{this.state.TabelleGruppe1[4][1]}</td>
                <td>{this.state.TabelleGruppe1[4][2]}</td>
                <td><div className="zeiten">{this.state.TabelleGruppe1[4][3]}</div></td>
                <td>{this.state.TabelleGruppe1[4][4]}</td>
                <td>{this.state.TabelleGruppe1[4][5]}</td>
                <td>{this.state.TabelleGruppe1[4][6]}</td>
                <td>{this.state.TabelleGruppe1[4][7]}</td>
                <td>{this.state.TabelleGruppe1[4][8]}</td>
                <td>{this.state.TabelleGruppe1[4][9]}</td>
                <td>{this.state.TabelleGruppe1[4][10]}</td>
              </tr>
            ) : (
              <></>
            )}
            {parseInt(this.state.Liga[3]) >=6 ? (
              <tr>
                <td><div className="zeiten">6</div></td>
                <td>{this.state.TabelleGruppe1[5][0]}</td>
                <td>{this.state.TabelleGruppe1[5][1]}</td>
                <td>{this.state.TabelleGruppe1[5][2]}</td>
                <td><div className="zeiten">{this.state.TabelleGruppe1[5][3]}</div></td>
                <td>{this.state.TabelleGruppe1[5][4]}</td>
                <td>{this.state.TabelleGruppe1[5][5]}</td>
                <td>{this.state.TabelleGruppe1[5][6]}</td>
                <td>{this.state.TabelleGruppe1[5][7]}</td>
                <td>{this.state.TabelleGruppe1[5][8]}</td>
                <td>{this.state.TabelleGruppe1[5][9]}</td>
                <td>{this.state.TabelleGruppe1[5][10]}</td>
              </tr>
            ) : (
              <></>
            )}
            {parseInt(this.state.Liga[3]) >=7 ? (
              <tr className="dunkler">
                <td><div className="zeiten">7</div></td>
                <td>{this.state.TabelleGruppe1[6][0]}</td>
                <td>{this.state.TabelleGruppe1[6][1]}</td>
                <td>{this.state.TabelleGruppe1[6][2]}</td>
                <td><div className="zeiten">{this.state.TabelleGruppe1[6][3]}</div></td>
                <td>{this.state.TabelleGruppe1[6][4]}</td>
                <td>{this.state.TabelleGruppe1[6][5]}</td>
                <td>{this.state.TabelleGruppe1[6][6]}</td>
                <td>{this.state.TabelleGruppe1[6][7]}</td>
                <td>{this.state.TabelleGruppe1[6][8]}</td>
                <td>{this.state.TabelleGruppe1[6][9]}</td>
                <td>{this.state.TabelleGruppe1[6][10]}</td>
              </tr>
            ) : (
              <></>
            )}
            {parseInt(this.state.Liga[3]) >=8 ? (
              <tr>
                <td><div className="zeiten">8</div></td>
                <td>{this.state.TabelleGruppe1[7][0]}</td>
                <td>{this.state.TabelleGruppe1[7][1]}</td>
                <td>{this.state.TabelleGruppe1[7][2]}</td>
                <td><div className="zeiten">{this.state.TabelleGruppe1[7][3]}</div></td>
                <td>{this.state.TabelleGruppe1[7][4]}</td>
                <td>{this.state.TabelleGruppe1[7][5]}</td>
                <td>{this.state.TabelleGruppe1[7][6]}</td>
                <td>{this.state.TabelleGruppe1[7][7]}</td>
                <td>{this.state.TabelleGruppe1[7][8]}</td>
                <td>{this.state.TabelleGruppe1[7][9]}</td>
                <td>{this.state.TabelleGruppe1[7][10]}</td>
              </tr>
            ) : (
              <></>
            )}
            {parseInt(this.state.Liga[3]) >=9 ? (
              <tr className="dunkler">
                <td><div className="zeiten">9</div></td>
                <td>{this.state.TabelleGruppe1[8][0]}</td>
                <td>{this.state.TabelleGruppe1[8][1]}</td>
                <td>{this.state.TabelleGruppe1[8][2]}</td>
                <td><div className="zeiten">{this.state.TabelleGruppe1[8][3]}</div></td>
                <td>{this.state.TabelleGruppe1[8][4]}</td>
                <td>{this.state.TabelleGruppe1[8][5]}</td>
                <td>{this.state.TabelleGruppe1[8][6]}</td>
                <td>{this.state.TabelleGruppe1[8][7]}</td>
                <td>{this.state.TabelleGruppe1[8][8]}</td>
                <td>{this.state.TabelleGruppe1[8][9]}</td>
                <td>{this.state.TabelleGruppe1[8][10]}</td>
              </tr>
            ) : (
              <></>
            )}
            {parseInt(this.state.Liga[3]) >=10 ? (
              <tr>
                <td><div className="zeiten">10</div></td>
                <td>{this.state.TabelleGruppe1[9][0]}</td>
                <td>{this.state.TabelleGruppe1[9][1]}</td>
                <td>{this.state.TabelleGruppe1[9][2]}</td>
                <td><div className="zeiten">{this.state.TabelleGruppe1[9][3]}</div></td>
                <td>{this.state.TabelleGruppe1[9][4]}</td>
                <td>{this.state.TabelleGruppe1[9][5]}</td>
                <td>{this.state.TabelleGruppe1[9][6]}</td>
                <td>{this.state.TabelleGruppe1[9][7]}</td>
                <td>{this.state.TabelleGruppe1[9][8]}</td>
                <td>{this.state.TabelleGruppe1[9][9]}</td>
                <td>{this.state.TabelleGruppe1[9][10]}</td>
              </tr>
            ) : (
              <></>
            )}
            </tbody>
        </table>
        <h6 className="textUnterTabelle">
          S=Sieg; N2=Niederlage in 2	Sätzen (2 Pkt.); N3=Niederlge im MTB (3 Pkt.); BP=Bonuspunkte; MP=Minuspunkte
        </h6>
      </div>
    )
  }

  TabelleGruppe2() {
    return(
      <div className="ligatabelle">
        <table>
            <tbody>
            <tr>
                <th>Rang</th>
                <th>Kürzel</th>
                <th>Team</th>
                <th>Matches</th>
                <th>Punkte</th>
                <th>Sätze</th>
                <th>Spiele</th>
                <th>S</th>
                <th>N2</th>
                <th>N3</th>
                <th>BP</th>
                <th>MP</th>
            </tr>
            <tr className="dunkler">
                <td><div className="zeiten">1</div></td>
                <td>{this.state.TabelleGruppe2[0][0]}</td>
                <td>{this.state.TabelleGruppe2[0][1]}</td>
                <td>{this.state.TabelleGruppe2[0][2]}</td>
                <td><div className="zeiten">{this.state.TabelleGruppe2[0][3]}</div></td>
                <td>{this.state.TabelleGruppe2[0][4]}</td>
                <td>{this.state.TabelleGruppe2[0][5]}</td>
                <td>{this.state.TabelleGruppe2[0][6]}</td>
                <td>{this.state.TabelleGruppe2[0][7]}</td>
                <td>{this.state.TabelleGruppe2[0][8]}</td>
                <td>{this.state.TabelleGruppe2[0][9]}</td>
                <td>{this.state.TabelleGruppe2[0][10]}</td>
            </tr>
            {parseInt(this.state.Liga[3]) >=2 ? (
              <tr>
                <td><div className="zeiten">2</div></td>
                <td>{this.state.TabelleGruppe2[1][0]}</td>
                <td>{this.state.TabelleGruppe2[1][1]}</td>
                <td>{this.state.TabelleGruppe2[1][2]}</td>
                <td><div className="zeiten">{this.state.TabelleGruppe2[1][3]}</div></td>
                <td>{this.state.TabelleGruppe2[1][4]}</td>
                <td>{this.state.TabelleGruppe2[1][5]}</td>
                <td>{this.state.TabelleGruppe2[1][6]}</td>
                <td>{this.state.TabelleGruppe2[1][7]}</td>
                <td>{this.state.TabelleGruppe2[1][8]}</td>
                <td>{this.state.TabelleGruppe2[1][9]}</td>
                <td>{this.state.TabelleGruppe2[1][10]}</td>
              </tr>
            ) : (
              <></>
            )}
            {parseInt(this.state.Liga[3]) >=3 ? (
              <tr className="dunkler">
                <td><div className="zeiten">3</div></td>
                <td>{this.state.TabelleGruppe2[2][0]}</td>
                <td>{this.state.TabelleGruppe2[2][1]}</td>
                <td>{this.state.TabelleGruppe2[2][2]}</td>
                <td><div className="zeiten">{this.state.TabelleGruppe2[2][3]}</div></td>
                <td>{this.state.TabelleGruppe2[2][4]}</td>
                <td>{this.state.TabelleGruppe2[2][5]}</td>
                <td>{this.state.TabelleGruppe2[2][6]}</td>
                <td>{this.state.TabelleGruppe2[2][7]}</td>
                <td>{this.state.TabelleGruppe2[2][8]}</td>
                <td>{this.state.TabelleGruppe2[2][9]}</td>
                <td>{this.state.TabelleGruppe2[2][10]}</td>
              </tr>
            ) : (
              <></>
            )}
            {parseInt(this.state.Liga[3]) >=4 ? (
              <tr>
                <td><div className="zeiten">4</div></td>
                <td>{this.state.TabelleGruppe2[3][0]}</td>
                <td>{this.state.TabelleGruppe2[3][1]}</td>
                <td>{this.state.TabelleGruppe2[3][2]}</td>
                <td><div className="zeiten">{this.state.TabelleGruppe2[3][3]}</div></td>
                <td>{this.state.TabelleGruppe2[3][4]}</td>
                <td>{this.state.TabelleGruppe2[3][5]}</td>
                <td>{this.state.TabelleGruppe2[3][6]}</td>
                <td>{this.state.TabelleGruppe2[3][7]}</td>
                <td>{this.state.TabelleGruppe2[3][8]}</td>
                <td>{this.state.TabelleGruppe2[3][9]}</td>
                <td>{this.state.TabelleGruppe2[3][10]}</td>
              </tr>
            ) : (
              <></>
            )}
            {parseInt(this.state.Liga[3]) >=5 ? (
              <tr className="dunkler">
                <td><div className="zeiten">5</div></td>
                <td>{this.state.TabelleGruppe2[4][0]}</td>
                <td>{this.state.TabelleGruppe2[4][1]}</td>
                <td>{this.state.TabelleGruppe2[4][2]}</td>
                <td><div className="zeiten">{this.state.TabelleGruppe2[4][3]}</div></td>
                <td>{this.state.TabelleGruppe2[4][4]}</td>
                <td>{this.state.TabelleGruppe2[4][5]}</td>
                <td>{this.state.TabelleGruppe2[4][6]}</td>
                <td>{this.state.TabelleGruppe2[4][7]}</td>
                <td>{this.state.TabelleGruppe2[4][8]}</td>
                <td>{this.state.TabelleGruppe2[4][9]}</td>
                <td>{this.state.TabelleGruppe2[4][10]}</td>
              </tr>
            ) : (
              <></>
            )}
            {parseInt(this.state.Liga[3]) >=6 ? (
              <tr>
                <td><div className="zeiten">6</div></td>
                <td>{this.state.TabelleGruppe2[5][0]}</td>
                <td>{this.state.TabelleGruppe2[5][1]}</td>
                <td>{this.state.TabelleGruppe2[5][2]}</td>
                <td><div className="zeiten">{this.state.TabelleGruppe2[5][3]}</div></td>
                <td>{this.state.TabelleGruppe2[5][4]}</td>
                <td>{this.state.TabelleGruppe2[5][5]}</td>
                <td>{this.state.TabelleGruppe2[5][6]}</td>
                <td>{this.state.TabelleGruppe2[5][7]}</td>
                <td>{this.state.TabelleGruppe2[5][8]}</td>
                <td>{this.state.TabelleGruppe2[5][9]}</td>
                <td>{this.state.TabelleGruppe2[5][10]}</td>
              </tr>
            ) : (
              <></>
            )}
            {parseInt(this.state.Liga[3]) >=7 ? (
              <tr className="dunkler">
                <td><div className="zeiten">7</div></td>
                <td>{this.state.TabelleGruppe2[6][0]}</td>
                <td>{this.state.TabelleGruppe2[6][1]}</td>
                <td>{this.state.TabelleGruppe2[6][2]}</td>
                <td><div className="zeiten">{this.state.TabelleGruppe2[6][3]}</div></td>
                <td>{this.state.TabelleGruppe2[6][4]}</td>
                <td>{this.state.TabelleGruppe2[6][5]}</td>
                <td>{this.state.TabelleGruppe2[6][6]}</td>
                <td>{this.state.TabelleGruppe2[6][7]}</td>
                <td>{this.state.TabelleGruppe2[6][8]}</td>
                <td>{this.state.TabelleGruppe2[6][9]}</td>
                <td>{this.state.TabelleGruppe2[6][10]}</td>
              </tr>
            ) : (
              <></>
            )}
            {parseInt(this.state.Liga[3]) >=8 ? (
              <tr>
                <td><div className="zeiten">8</div></td>
                <td>{this.state.TabelleGruppe2[7][0]}</td>
                <td>{this.state.TabelleGruppe2[7][1]}</td>
                <td>{this.state.TabelleGruppe2[7][2]}</td>
                <td><div className="zeiten">{this.state.TabelleGruppe2[7][3]}</div></td>
                <td>{this.state.TabelleGruppe2[7][4]}</td>
                <td>{this.state.TabelleGruppe2[7][5]}</td>
                <td>{this.state.TabelleGruppe2[7][6]}</td>
                <td>{this.state.TabelleGruppe2[7][7]}</td>
                <td>{this.state.TabelleGruppe2[7][8]}</td>
                <td>{this.state.TabelleGruppe2[7][9]}</td>
                <td>{this.state.TabelleGruppe2[7][10]}</td>
              </tr>
            ) : (
              <></>
            )}
            {parseInt(this.state.Liga[3]) >=9 ? (
              <tr className="dunkler">
                <td><div className="zeiten">9</div></td>
                <td>{this.state.TabelleGruppe2[8][0]}</td>
                <td>{this.state.TabelleGruppe2[8][1]}</td>
                <td>{this.state.TabelleGruppe2[8][2]}</td>
                <td><div className="zeiten">{this.state.TabelleGruppe2[8][3]}</div></td>
                <td>{this.state.TabelleGruppe2[8][4]}</td>
                <td>{this.state.TabelleGruppe2[8][5]}</td>
                <td>{this.state.TabelleGruppe2[8][6]}</td>
                <td>{this.state.TabelleGruppe2[8][7]}</td>
                <td>{this.state.TabelleGruppe2[8][8]}</td>
                <td>{this.state.TabelleGruppe2[8][9]}</td>
                <td>{this.state.TabelleGruppe2[8][10]}</td>
              </tr>
            ) : (
              <></>
            )}
            {parseInt(this.state.Liga[3]) >=10 ? (
              <tr>
                <td><div className="zeiten">10</div></td>
                <td>{this.state.TabelleGruppe2[9][0]}</td>
                <td>{this.state.TabelleGruppe2[9][1]}</td>
                <td>{this.state.TabelleGruppe2[9][2]}</td>
                <td><div className="zeiten">{this.state.TabelleGruppe2[9][3]}</div></td>
                <td>{this.state.TabelleGruppe2[9][4]}</td>
                <td>{this.state.TabelleGruppe2[9][5]}</td>
                <td>{this.state.TabelleGruppe2[9][6]}</td>
                <td>{this.state.TabelleGruppe2[9][7]}</td>
                <td>{this.state.TabelleGruppe2[9][8]}</td>
                <td>{this.state.TabelleGruppe2[9][9]}</td>
                <td>{this.state.TabelleGruppe2[9][10]}</td>
              </tr>
            ) : (
              <></>
            )}
            </tbody>
        </table>
        <h6 className="textUnterTabelle">
          S=Sieg; N2=Niederlage in 2	Sätzen (2 Pkt.); N3=Niederlge im MTB (3 Pkt.); BP=Bonuspunkte; MP=Minuspunkte
        </h6>
        
    </div>
    )
  }

  BerechnungGruppe1() {
    let TabelleGruppe1Vorlaufig = this.state.TabelleGruppe1
    let TabelleGruppe1VorlaufigBackup = [
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
    ]
    
    for(let a=0; a<10; a++) {
      //Kuerzel
      TabelleGruppe1VorlaufigBackup[a][0] = this.state.Liga[25+a];
    }
    for(let a=0; a<10; a++) {
      //Name
      TabelleGruppe1VorlaufigBackup[a][1] = this.state.Liga[5+a];
    }
    for(let a=0; a<10; a++) {
      //Begegnungen
      var counterBegegnungen=0;
      let counterSaetze=0;
      let counterSaetzeGegen=0;
      let counterSpiele=0;
      let counterSpieleGegen=0;
      let counterSieg=0;
      let counterNiederlagein2Saetzen=0;
      let counterNiederlagein3Saetzen=0;
      let counterPunkte=0;
      if(TabelleGruppe1VorlaufigBackup[a][0] !== "") { //Nicht für die leeren Zeilen berechnen
        for(let b=0; b<150; b++) {
          for(let c=0; c<9; c++) {
            if(TabelleGruppe1VorlaufigBackup[a][0] === this.state.SpieleGruppe1[b][c]) { //Teamname = Name in einem Spiel enthalten?
              if(TabelleGruppe1VorlaufigBackup[a][0] === this.state.SpieleGruppe1[b][1]) {
                //Dann ist es das erste Team
                if(parseInt(this.state.SpieleGruppe1[b][3])>parseInt(this.state.SpieleGruppe1[b][4])) {
                  //Dann wurde der 1. Satz gewonnen (vom ersten Team)
                  counterSaetze+=1
                  counterSpiele+=parseInt(this.state.SpieleGruppe1[b][3])
                  counterSpieleGegen+=parseInt(this.state.SpieleGruppe1[b][4])
                  if(parseInt(this.state.SpieleGruppe1[b][5])>parseInt(this.state.SpieleGruppe1[b][6])) {
                    //Dann wurde der 2. Satz gewonnen (vom ersten Team)
                    counterSaetze+=1
                    counterSpiele+=parseInt(this.state.SpieleGruppe1[b][5])
                    counterSpieleGegen+=parseInt(this.state.SpieleGruppe1[b][6])
                    counterSieg+=1
                    counterPunkte+=5
                    counterBegegnungen+=1;
                  }
                  else if(parseInt(this.state.SpieleGruppe1[b][5])<parseInt(this.state.SpieleGruppe1[b][6])) {
                    //Dann wurde der 2. Satz verloren (vom ersten Team)
                    counterSaetzeGegen+=1
                    counterSpiele+=parseInt(this.state.SpieleGruppe1[b][5])
                    counterSpieleGegen+=parseInt(this.state.SpieleGruppe1[b][6])
                    if(parseInt(this.state.SpieleGruppe1[b][7])>parseInt(this.state.SpieleGruppe1[b][8])) {
                      //Dann wurde der 3. Satz gewonnen (vom ersten Team)
                      counterSaetze+=1
                      counterSpiele+=parseInt(this.state.SpieleGruppe1[b][7])
                      counterSpieleGegen+=parseInt(this.state.SpieleGruppe1[b][8])
                      counterSieg+=1
                      counterPunkte+=5
                      counterBegegnungen+=1;
                    }
                    else if(parseInt(this.state.SpieleGruppe1[b][7])<parseInt(this.state.SpieleGruppe1[b][8])) {
                      //Dann wurde der 3. Satz verloren (vom ersten Team)
                      counterSaetzeGegen+=1
                      counterSpiele+=parseInt(this.state.SpieleGruppe1[b][7])
                      counterSpieleGegen+=parseInt(this.state.SpieleGruppe1[b][8])
                      counterNiederlagein3Saetzen+=1
                      counterPunkte+=3
                      counterBegegnungen+=1;
                    }
                  }
                }
                else if(parseInt(this.state.SpieleGruppe1[b][3])<parseInt(this.state.SpieleGruppe1[b][4])) {
                  //Dann wurde der 1. Satz verloren (vom ersten Team)
                  counterSaetzeGegen+=1
                  counterSpiele+=parseInt(this.state.SpieleGruppe1[b][3])
                  counterSpieleGegen+=parseInt(this.state.SpieleGruppe1[b][4])
                  if(parseInt(this.state.SpieleGruppe1[b][5])>parseInt(this.state.SpieleGruppe1[b][6])) {
                    //Dann wurde der 2. Satz gewonnen (vom ersten Team)
                    counterSaetze+=1
                    counterSpiele+=parseInt(this.state.SpieleGruppe1[b][5])
                    counterSpieleGegen+=parseInt(this.state.SpieleGruppe1[b][6])
                    if(parseInt(this.state.SpieleGruppe1[b][7])>parseInt(this.state.SpieleGruppe1[b][8])) {
                      //Dann wurde der 3. Satz gewonnen (vom ersten Team)
                      counterSaetze+=1
                      counterSpiele+=parseInt(this.state.SpieleGruppe1[b][7])
                      counterSpieleGegen+=parseInt(this.state.SpieleGruppe1[b][8])
                      counterSieg+=1
                      counterPunkte+=5
                      counterBegegnungen+=1;
                    }
                    else if(parseInt(this.state.SpieleGruppe1[b][7])<parseInt(this.state.SpieleGruppe1[b][8])) {
                      //Dann wurde der 3. Satz verloren (vom ersten Team)
                      counterSaetzeGegen+=1
                      counterSpiele+=parseInt(this.state.SpieleGruppe1[b][7])
                      counterSpieleGegen+=parseInt(this.state.SpieleGruppe1[b][8])
                      counterNiederlagein3Saetzen+=1
                      counterPunkte+=3
                      counterBegegnungen+=1;
                    }
                  }
                  else if(parseInt(this.state.SpieleGruppe1[b][5])<parseInt(this.state.SpieleGruppe1[b][6])) {
                    //Dann wurde der 2. Satz verloren (vom ersten Team)
                    counterSaetzeGegen+=1
                    counterSpiele+=parseInt(this.state.SpieleGruppe1[b][5])
                    counterSpieleGegen+=parseInt(this.state.SpieleGruppe1[b][6])
                    counterNiederlagein2Saetzen+=1
                    counterPunkte+=2
                    counterBegegnungen+=1;
                  }
                }
              }
              else {
                //Dann ist es das zweite Team
                //Im Vergleich zum anderen Fall werden nun stetz die counter von fuer zu gegen gewechselt (und andersrum)
                //und von Sieg auf NiederlageIn2/3Saetzen (und andersrum) 
                if(parseInt(this.state.SpieleGruppe1[b][3])>parseInt(this.state.SpieleGruppe1[b][4])) {
                  //Dann wurde der 1. Satz gewonnen (vom ersten Team)
                  counterSaetzeGegen+=1
                  counterSpieleGegen+=parseInt(this.state.SpieleGruppe1[b][3])
                  counterSpiele+=parseInt(this.state.SpieleGruppe1[b][4])
                  if(parseInt(this.state.SpieleGruppe1[b][5])>parseInt(this.state.SpieleGruppe1[b][6])) {
                    //Dann wurde der 2. Satz gewonnen (vom ersten Team)
                    counterSaetzeGegen+=1
                    counterSpieleGegen+=parseInt(this.state.SpieleGruppe1[b][5])
                    counterSpiele+=parseInt(this.state.SpieleGruppe1[b][6])
                    counterNiederlagein2Saetzen+=1
                    counterPunkte+=2
                    counterBegegnungen+=1;
                  }
                  else if(parseInt(this.state.SpieleGruppe1[b][5])<parseInt(this.state.SpieleGruppe1[b][6])) {
                    //Dann wurde der 2. Satz verloren (vom ersten Team)
                    counterSaetze+=1
                    counterSpieleGegen+=parseInt(this.state.SpieleGruppe1[b][5])
                    counterSpiele+=parseInt(this.state.SpieleGruppe1[b][6])
                    if(parseInt(this.state.SpieleGruppe1[b][7])>parseInt(this.state.SpieleGruppe1[b][8])) {
                      //Dann wurde der 3. Satz gewonnen (vom ersten Team)
                      counterSaetzeGegen+=1
                      counterSpieleGegen+=parseInt(this.state.SpieleGruppe1[b][7])
                      counterSpiele+=parseInt(this.state.SpieleGruppe1[b][8])
                      counterNiederlagein3Saetzen+=1
                      counterPunkte+=3
                      counterBegegnungen+=1;
                    }
                    else if(parseInt(this.state.SpieleGruppe1[b][7])<parseInt(this.state.SpieleGruppe1[b][8])) {
                      //Dann wurde der 3. Satz verloren (vom ersten Team)
                      counterSaetze+=1
                      counterSpieleGegen+=parseInt(this.state.SpieleGruppe1[b][7])
                      counterSpiele+=parseInt(this.state.SpieleGruppe1[b][8])
                      counterSieg+=1
                      counterPunkte+=5
                      counterBegegnungen+=1;
                    }
                  }
                }
                else if(parseInt(this.state.SpieleGruppe1[b][3])<parseInt(this.state.SpieleGruppe1[b][4])) {
                  //Dann wurde der 1. Satz verloren (vom ersten Team)
                  counterSaetze+=1
                  counterSpieleGegen+=parseInt(this.state.SpieleGruppe1[b][3])
                  counterSpiele+=parseInt(this.state.SpieleGruppe1[b][4])
                  if(parseInt(this.state.SpieleGruppe1[b][5])>parseInt(this.state.SpieleGruppe1[b][6])) {
                    //Dann wurde der 2. Satz gewonnen (vom ersten Team)
                    counterSaetzeGegen+=1
                    counterSpieleGegen+=parseInt(this.state.SpieleGruppe1[b][5])
                    counterSpiele+=parseInt(this.state.SpieleGruppe1[b][6])
                    if(parseInt(this.state.SpieleGruppe1[b][7])>parseInt(this.state.SpieleGruppe1[b][8])) {
                      //Dann wurde der 3. Satz gewonnen (vom ersten Team)
                      counterSaetzeGegen+=1
                      counterSpieleGegen+=parseInt(this.state.SpieleGruppe1[b][7])
                      counterSpiele+=parseInt(this.state.SpieleGruppe1[b][8])
                      counterNiederlagein3Saetzen+=1
                      counterPunkte+=3
                      counterBegegnungen+=1;
                    }
                    else if(parseInt(this.state.SpieleGruppe1[b][7])<parseInt(this.state.SpieleGruppe1[b][8])) {
                      //Dann wurde der 3. Satz verloren (vom ersten Team)
                      counterSaetze+=1
                      counterSpieleGegen+=parseInt(this.state.SpieleGruppe1[b][7])
                      counterSpiele+=parseInt(this.state.SpieleGruppe1[b][8])
                      counterSieg+=1
                      counterPunkte+=5
                      counterBegegnungen+=1;
                    }
                  }
                  else if(parseInt(this.state.SpieleGruppe1[b][5])<parseInt(this.state.SpieleGruppe1[b][6])) {
                    //Dann wurde der 2. Satz verloren (vom ersten Team)
                    counterSaetze+=1
                    counterSpieleGegen+=parseInt(this.state.SpieleGruppe1[b][5])
                    counterSpiele+=parseInt(this.state.SpieleGruppe1[b][6])
                    counterSieg+=1
                    counterPunkte+=5
                    counterBegegnungen+=1;
                  }
                }
              }
            }
          }
        }
        // Bonus- und Minuspunkte beachten
        counterPunkte+=parseInt(this.state.BPMPGruppe1[a][0])
        counterPunkte-=parseInt(this.state.BPMPGruppe1[a][1])

        TabelleGruppe1VorlaufigBackup[a][2] = counterBegegnungen
        TabelleGruppe1VorlaufigBackup[a][3] = counterPunkte
        TabelleGruppe1VorlaufigBackup[a][4] = counterSaetze
        TabelleGruppe1VorlaufigBackup[a][5] = counterSaetzeGegen
        TabelleGruppe1VorlaufigBackup[a][6] = counterSpiele
        TabelleGruppe1VorlaufigBackup[a][7] = counterSpieleGegen
        TabelleGruppe1VorlaufigBackup[a][8] = counterSieg
        TabelleGruppe1VorlaufigBackup[a][9] = counterNiederlagein2Saetzen
        TabelleGruppe1VorlaufigBackup[a][10] = counterNiederlagein3Saetzen
      }
    }

    //Finde heraus, welches Team auf welchem Platz steht
    let Rang = [0,1,2,3,4,5,6,7,8,9] //Der Eintragin der i-ten Stelle ist das Team, was auf dem i-ten Rang stehen muss
    for(let Laufzeit=0; Laufzeit<this.state.Liga[3]*(this.state.Liga[3]-1)/2; Laufzeit++) { //So lange braucht BubbleSort schlimmstenfalls
      for(let a=0; a<this.state.Liga[3]-1; a++) {
        //Schau die Punkte an
        if(TabelleGruppe1VorlaufigBackup[Rang[a]][3]<TabelleGruppe1VorlaufigBackup[Rang[a+1]][3]) {
          //Dann tausche
          let RangBackup = []
          for(let x=0; x<10; x++) {
            RangBackup.push(Rang[x])
          }
          Rang[a]=Rang[a+1]
          Rang[a+1]=RangBackup[a]
        }
        else if(TabelleGruppe1VorlaufigBackup[Rang[a]][3] === TabelleGruppe1VorlaufigBackup[Rang[a+1]][3]) {
          //Dann schau die Satzdifferenz an
          if(TabelleGruppe1VorlaufigBackup[Rang[a]][4]-TabelleGruppe1VorlaufigBackup[Rang[a]][5]<TabelleGruppe1VorlaufigBackup[Rang[a+1]][4]-TabelleGruppe1VorlaufigBackup[Rang[a+1]][5]) {
            //Dann tausche
            let RangBackup = []
            for(let x=0; x<10; x++) {
              RangBackup.push(Rang[x])
            }
            Rang[a]=Rang[a+1]
            Rang[a+1]=RangBackup[a]
          }
          else if(TabelleGruppe1VorlaufigBackup[Rang[a]][4]-TabelleGruppe1VorlaufigBackup[Rang[a]][5] === TabelleGruppe1VorlaufigBackup[Rang[a+1]][4]-TabelleGruppe1VorlaufigBackup[Rang[a+1]][5]) {
            //Dann schau, wer mehr Saetze gewonnen hat
            if(TabelleGruppe1VorlaufigBackup[Rang[a]][4]<TabelleGruppe1VorlaufigBackup[Rang[a+1]][4]) {
              //Dann tausche
              let RangBackup = []
              for(let x=0; x<10; x++) {
                RangBackup.push(Rang[x])
              }
              Rang[a]=Rang[a+1]
              Rang[a+1]=RangBackup[a]
            }
            else if(TabelleGruppe1VorlaufigBackup[Rang[a]][4] === TabelleGruppe1VorlaufigBackup[Rang[a+1]][4]) {
              //Dann schau die Spieldifferenz an
              if(TabelleGruppe1VorlaufigBackup[Rang[a]][6]-TabelleGruppe1VorlaufigBackup[Rang[a]][7]<TabelleGruppe1VorlaufigBackup[Rang[a+1]][6]-TabelleGruppe1VorlaufigBackup[Rang[a+1]][7]) {
                //Dann tausche
                let RangBackup = []
                for(let x=0; x<10; x++) {
                  RangBackup.push(Rang[x])
                }
                Rang[a]=Rang[a+1]
                Rang[a+1]=RangBackup[a]
              }
              else if(TabelleGruppe1VorlaufigBackup[Rang[a]][6]-TabelleGruppe1VorlaufigBackup[Rang[a]][7] === TabelleGruppe1VorlaufigBackup[Rang[a+1]][6]-TabelleGruppe1VorlaufigBackup[Rang[a+1]][7]){
                //Dann schau, wer mehr Spiele gewonnen hat
                if(TabelleGruppe1VorlaufigBackup[Rang[a]][6]<TabelleGruppe1VorlaufigBackup[Rang[a+1]][6]) {
                  //Dann tausche
                  let RangBackup = []
                  for(let x=0; x<10; x++) {
                    RangBackup.push(Rang[x])
                  }
                  Rang[a]=Rang[a+1]
                  Rang[a+1]=RangBackup[a]
                }
                else if(TabelleGruppe1VorlaufigBackup[Rang[a]][6] === TabelleGruppe1VorlaufigBackup[Rang[a+1]][6]) {
                  //Dann ist alles identisch
                }
              }
            }
          }
        }
      }
    }

    //Befuelle am Ende den Array "TabelleGruppe1Vorlaufig" nach der Reihenfolge, die man vorher herausgefunden hat
    for(let a=0; a<10; a++) {
      if(TabelleGruppe1VorlaufigBackup[Rang[a]][0].toString()!==null && TabelleGruppe1VorlaufigBackup[Rang[a]][0].toString()!=="") {
        TabelleGruppe1Vorlaufig[a][0] = TabelleGruppe1VorlaufigBackup[Rang[a]][0].toString();
        TabelleGruppe1Vorlaufig[a][1] = TabelleGruppe1VorlaufigBackup[Rang[a]][1].toString();
        TabelleGruppe1Vorlaufig[a][2] = TabelleGruppe1VorlaufigBackup[Rang[a]][2].toString();
        TabelleGruppe1Vorlaufig[a][3] = TabelleGruppe1VorlaufigBackup[Rang[a]][3].toString();
        TabelleGruppe1Vorlaufig[a][4] = TabelleGruppe1VorlaufigBackup[Rang[a]][4].toString() + ":" + TabelleGruppe1VorlaufigBackup[Rang[a]][5].toString();
        TabelleGruppe1Vorlaufig[a][5] = TabelleGruppe1VorlaufigBackup[Rang[a]][6].toString() + ":" + TabelleGruppe1VorlaufigBackup[Rang[a]][7].toString();
        TabelleGruppe1Vorlaufig[a][6] = TabelleGruppe1VorlaufigBackup[Rang[a]][8].toString();
        TabelleGruppe1Vorlaufig[a][7] = TabelleGruppe1VorlaufigBackup[Rang[a]][9].toString();
        TabelleGruppe1Vorlaufig[a][8] = TabelleGruppe1VorlaufigBackup[Rang[a]][10].toString();
        TabelleGruppe1Vorlaufig[a][9] = this.state.BPMPGruppe1[Rang[a]][0];
        TabelleGruppe1Vorlaufig[a][10] = this.state.BPMPGruppe1[Rang[a]][1];
      }
    }
  }

  BerechnungGruppe2() {
    let TabelleGruppe2Vorlaufig = this.state.TabelleGruppe2
    let TabelleGruppe2VorlaufigBackup = [
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", "", ""],
    ]

    for(let a=0; a<10; a++) {
      //Kuerzel
      TabelleGruppe2VorlaufigBackup[a][0] = this.state.Liga[35+a];
    }
    for(let a=0; a<10; a++) {
      //Name
      TabelleGruppe2VorlaufigBackup[a][1] = this.state.Liga[15+a];
    }
    for(let a=0; a<10; a++) {
      //Begegnungen
      var counterBegegnungen=0;
      let counterSaetze=0;
      let counterSaetzeGegen=0;
      let counterSpiele=0;
      let counterSpieleGegen=0;
      let counterSieg=0;
      let counterNiederlagein2Saetzen=0;
      let counterNiederlagein3Saetzen=0;
      let counterPunkte=0;
      if(TabelleGruppe2VorlaufigBackup[a][0] !== "") { //Nicht für die leeren Zeilen berechnen
        for(let b=0; b<150; b++) {
          for(let c=0; c<9; c++) {
            if(TabelleGruppe2VorlaufigBackup[a][0] === this.state.SpieleGruppe2[b][c]) { //Teamname = Name in einem Spiel enthalten?
              if(TabelleGruppe2VorlaufigBackup[a][0] === this.state.SpieleGruppe2[b][1]) {
                //Dann ist es das erste Team
                if(parseInt(this.state.SpieleGruppe2[b][3])>parseInt(this.state.SpieleGruppe2[b][4])) {
                  //Dann wurde der 1. Satz gewonnen (vom ersten Team)
                  counterSaetze+=1
                  counterSpiele+=parseInt(this.state.SpieleGruppe2[b][3])
                  counterSpieleGegen+=parseInt(this.state.SpieleGruppe2[b][4])
                  if(parseInt(this.state.SpieleGruppe2[b][5])>parseInt(this.state.SpieleGruppe2[b][6])) {
                    //Dann wurde der 2. Satz gewonnen (vom ersten Team)
                    counterSaetze+=1
                    counterSpiele+=parseInt(this.state.SpieleGruppe2[b][5])
                    counterSpieleGegen+=parseInt(this.state.SpieleGruppe2[b][6])
                    counterSieg+=1
                    counterPunkte+=5
                    counterBegegnungen+=1;
                  }
                  else if(parseInt(this.state.SpieleGruppe2[b][5])<parseInt(this.state.SpieleGruppe2[b][6])) {
                    //Dann wurde der 2. Satz verloren (vom ersten Team)
                    counterSaetzeGegen+=1
                    counterSpiele+=parseInt(this.state.SpieleGruppe2[b][5])
                    counterSpieleGegen+=parseInt(this.state.SpieleGruppe2[b][6])
                    if(parseInt(this.state.SpieleGruppe2[b][7])>parseInt(this.state.SpieleGruppe2[b][8])) {
                      //Dann wurde der 3. Satz gewonnen (vom ersten Team)
                      counterSaetze+=1
                      counterSpiele+=parseInt(this.state.SpieleGruppe2[b][7])
                      counterSpieleGegen+=parseInt(this.state.SpieleGruppe2[b][8])
                      counterSieg+=1
                      counterPunkte+=5
                      counterBegegnungen+=1;
                    }
                    else if(parseInt(this.state.SpieleGruppe2[b][7])<parseInt(this.state.SpieleGruppe2[b][8])) {
                      //Dann wurde der 3. Satz verloren (vom ersten Team)
                      counterSaetzeGegen+=1
                      counterSpiele+=parseInt(this.state.SpieleGruppe2[b][7])
                      counterSpieleGegen+=parseInt(this.state.SpieleGruppe2[b][8])
                      counterNiederlagein3Saetzen+=1
                      counterPunkte+=3
                      counterBegegnungen+=1;
                    }
                  }
                }
                else if(parseInt(this.state.SpieleGruppe2[b][3])<parseInt(this.state.SpieleGruppe2[b][4])) {
                  //Dann wurde der 1. Satz verloren (vom ersten Team)
                  counterSaetzeGegen+=1
                  counterSpiele+=parseInt(this.state.SpieleGruppe2[b][3])
                  counterSpieleGegen+=parseInt(this.state.SpieleGruppe2[b][4])
                  if(parseInt(this.state.SpieleGruppe2[b][5])>parseInt(this.state.SpieleGruppe2[b][6])) {
                    //Dann wurde der 2. Satz gewonnen (vom ersten Team)
                    counterSaetze+=1
                    counterSpiele+=parseInt(this.state.SpieleGruppe2[b][5])
                    counterSpieleGegen+=parseInt(this.state.SpieleGruppe2[b][6])
                    if(parseInt(this.state.SpieleGruppe2[b][7])>parseInt(this.state.SpieleGruppe2[b][8])) {
                      //Dann wurde der 3. Satz gewonnen (vom ersten Team)
                      counterSaetze+=1
                      counterSpiele+=parseInt(this.state.SpieleGruppe2[b][7])
                      counterSpieleGegen+=parseInt(this.state.SpieleGruppe2[b][8])
                      counterSieg+=1
                      counterPunkte+=5
                      counterBegegnungen+=1;
                    }
                    else if(parseInt(this.state.SpieleGruppe2[b][7])<parseInt(this.state.SpieleGruppe2[b][8])) {
                      //Dann wurde der 3. Satz verloren (vom ersten Team)
                      counterSaetzeGegen+=1
                      counterSpiele+=parseInt(this.state.SpieleGruppe2[b][7])
                      counterSpieleGegen+=parseInt(this.state.SpieleGruppe2[b][8])
                      counterNiederlagein3Saetzen+=1
                      counterPunkte+=3
                      counterBegegnungen+=1;
                    }
                  }
                  else if(parseInt(this.state.SpieleGruppe2[b][5])<parseInt(this.state.SpieleGruppe2[b][6])) {
                    //Dann wurde der 2. Satz verloren (vom ersten Team)
                    counterSaetzeGegen+=1
                    counterSpiele+=parseInt(this.state.SpieleGruppe2[b][5])
                    counterSpieleGegen+=parseInt(this.state.SpieleGruppe2[b][6])
                    counterNiederlagein2Saetzen+=1
                    counterPunkte+=2
                    counterBegegnungen+=1;
                  }
                }
              }
              else {
                //Dann ist es das zweite Team
                //Im Vergleich zum anderen Fall werden nun stetz die counter von fuer zu gegen gewechselt (und andersrum)
                //und von Sieg auf NiederlageIn2/3Saetzen (und andersrum) 
                if(parseInt(this.state.SpieleGruppe2[b][3])>parseInt(this.state.SpieleGruppe2[b][4])) {
                  //Dann wurde der 1. Satz gewonnen (vom ersten Team)
                  counterSaetzeGegen+=1
                  counterSpieleGegen+=parseInt(this.state.SpieleGruppe2[b][3])
                  counterSpiele+=parseInt(this.state.SpieleGruppe2[b][4])
                  if(parseInt(this.state.SpieleGruppe2[b][5])>parseInt(this.state.SpieleGruppe2[b][6])) {
                    //Dann wurde der 2. Satz gewonnen (vom ersten Team)
                    counterSaetzeGegen+=1
                    counterSpieleGegen+=parseInt(this.state.SpieleGruppe2[b][5])
                    counterSpiele+=parseInt(this.state.SpieleGruppe2[b][6])
                    counterNiederlagein2Saetzen+=1
                    counterPunkte+=2
                    counterBegegnungen+=1;
                  }
                  else if(parseInt(this.state.SpieleGruppe2[b][5])<parseInt(this.state.SpieleGruppe2[b][6])) {
                    //Dann wurde der 2. Satz verloren (vom ersten Team)
                    counterSaetze+=1
                    counterSpieleGegen+=parseInt(this.state.SpieleGruppe2[b][5])
                    counterSpiele+=parseInt(this.state.SpieleGruppe2[b][6])
                    if(parseInt(this.state.SpieleGruppe2[b][7])>parseInt(this.state.SpieleGruppe2[b][8])) {
                      //Dann wurde der 3. Satz gewonnen (vom ersten Team)
                      counterSaetzeGegen+=1
                      counterSpieleGegen+=parseInt(this.state.SpieleGruppe2[b][7])
                      counterSpiele+=parseInt(this.state.SpieleGruppe2[b][8])
                      counterNiederlagein3Saetzen+=1
                      counterPunkte+=3
                      counterBegegnungen+=1;
                    }
                    else if(parseInt(this.state.SpieleGruppe2[b][7])<parseInt(this.state.SpieleGruppe2[b][8])) {
                      //Dann wurde der 3. Satz verloren (vom ersten Team)
                      counterSaetze+=1
                      counterSpieleGegen+=parseInt(this.state.SpieleGruppe2[b][7])
                      counterSpiele+=parseInt(this.state.SpieleGruppe2[b][8])
                      counterSieg+=1
                      counterPunkte+=5
                      counterBegegnungen+=1;
                    }
                  }
                }
                else if(parseInt(this.state.SpieleGruppe2[b][3])<parseInt(this.state.SpieleGruppe2[b][4])) {
                  //Dann wurde der 1. Satz verloren (vom ersten Team)
                  counterSaetze+=1
                  counterSpieleGegen+=parseInt(this.state.SpieleGruppe2[b][3])
                  counterSpiele+=parseInt(this.state.SpieleGruppe2[b][4])
                  if(parseInt(this.state.SpieleGruppe2[b][5])>parseInt(this.state.SpieleGruppe2[b][6])) {
                    //Dann wurde der 2. Satz gewonnen (vom ersten Team)
                    counterSaetzeGegen+=1
                    counterSpieleGegen+=parseInt(this.state.SpieleGruppe2[b][5])
                    counterSpiele+=parseInt(this.state.SpieleGruppe2[b][6])
                    if(parseInt(this.state.SpieleGruppe2[b][7])>parseInt(this.state.SpieleGruppe2[b][8])) {
                      //Dann wurde der 3. Satz gewonnen (vom ersten Team)
                      counterSaetzeGegen+=1
                      counterSpieleGegen+=parseInt(this.state.SpieleGruppe2[b][7])
                      counterSpiele+=parseInt(this.state.SpieleGruppe2[b][8])
                      counterNiederlagein3Saetzen+=1
                      counterPunkte+=3
                      counterBegegnungen+=1;
                    }
                    else if(parseInt(this.state.SpieleGruppe2[b][7])<parseInt(this.state.SpieleGruppe2[b][8])) {
                      //Dann wurde der 3. Satz verloren (vom ersten Team)
                      counterSaetze+=1
                      counterSpieleGegen+=parseInt(this.state.SpieleGruppe2[b][7])
                      counterSpiele+=parseInt(this.state.SpieleGruppe2[b][8])
                      counterSieg+=1
                      counterPunkte+=5
                      counterBegegnungen+=1;
                    }
                  }
                  else if(parseInt(this.state.SpieleGruppe2[b][5])<parseInt(this.state.SpieleGruppe2[b][6])) {
                    //Dann wurde der 2. Satz verloren (vom ersten Team)
                    counterSaetze+=1
                    counterSpieleGegen+=parseInt(this.state.SpieleGruppe2[b][5])
                    counterSpiele+=parseInt(this.state.SpieleGruppe2[b][6])
                    counterSieg+=1
                    counterPunkte+=5
                    counterBegegnungen+=1;
                  }
                }
              }
            }
          }
        }
        // Bonus- und Minuspunkte beachten
        counterPunkte+=parseInt(this.state.BPMPGruppe2[a][0])
        counterPunkte-=parseInt(this.state.BPMPGruppe2[a][1])

        TabelleGruppe2VorlaufigBackup[a][2] = counterBegegnungen
        TabelleGruppe2VorlaufigBackup[a][3] = counterPunkte
        TabelleGruppe2VorlaufigBackup[a][4] = counterSaetze
        TabelleGruppe2VorlaufigBackup[a][5] = counterSaetzeGegen
        TabelleGruppe2VorlaufigBackup[a][6] = counterSpiele
        TabelleGruppe2VorlaufigBackup[a][7] = counterSpieleGegen
        TabelleGruppe2VorlaufigBackup[a][8] = counterSieg
        TabelleGruppe2VorlaufigBackup[a][9] = counterNiederlagein2Saetzen
        TabelleGruppe2VorlaufigBackup[a][10] = counterNiederlagein3Saetzen
      }
    }
    
    //Finde heraus, welches Team auf welchem Platz steht
    let Rang = [0,1,2,3,4,5,6,7,8,9] //Der Eintragin der i-ten Stelle ist das Team, was auf dem i-ten Rang stehen muss
    for(let Laufzeit=0; Laufzeit<this.state.Liga[4]*(this.state.Liga[4]-1)/2; Laufzeit++) { //So lange braucht BubbleSort schlimmstenfalls
      for(let a=0; a<this.state.Liga[4]-1; a++) {
        //Schau die Punkte an
        console.log(TabelleGruppe2VorlaufigBackup[Rang[a]][3] + "<" + TabelleGruppe2VorlaufigBackup[Rang[a+1]][3])
        if(TabelleGruppe2VorlaufigBackup[Rang[a]][3]<TabelleGruppe2VorlaufigBackup[Rang[a+1]][3]) {
          console.log("WAHR")
          //Dann tausche
          let RangBackup = []
          for(let x=0; x<10; x++) {
            RangBackup.push(Rang[x])
          }
          Rang[a]=Rang[a+1]
          Rang[a+1]=RangBackup[a]
        }
        else if(TabelleGruppe2VorlaufigBackup[Rang[a]][3] === TabelleGruppe2VorlaufigBackup[Rang[a+1]][3]) {
          //Dann schau die Satzdifferenz an
          if(TabelleGruppe2VorlaufigBackup[Rang[a]][4]-TabelleGruppe2VorlaufigBackup[Rang[a]][5]<TabelleGruppe2VorlaufigBackup[Rang[a+1]][4]-TabelleGruppe2VorlaufigBackup[Rang[a+1]][5]) {
            //Dann tausche
            let RangBackup = []
            for(let x=0; x<10; x++) {
              RangBackup.push(Rang[x])
            }
            Rang[a]=Rang[a+1]
            Rang[a+1]=RangBackup[a]
          }
          else if(TabelleGruppe2VorlaufigBackup[Rang[a]][4]-TabelleGruppe2VorlaufigBackup[Rang[a]][5] === TabelleGruppe2VorlaufigBackup[Rang[a+1]][4]-TabelleGruppe2VorlaufigBackup[Rang[a+1]][5]) {
            //Dann schau, wer mehr Saetze gewonnen hat
            if(TabelleGruppe2VorlaufigBackup[Rang[a]][4]<TabelleGruppe2VorlaufigBackup[Rang[a+1]][4]) {
              //Dann tausche
              let RangBackup = []
              for(let x=0; x<10; x++) {
                RangBackup.push(Rang[x])
              }
              Rang[a]=Rang[a+1]
              Rang[a+1]=RangBackup[a]
            }
            else if(TabelleGruppe2VorlaufigBackup[Rang[a]][4] === TabelleGruppe2VorlaufigBackup[Rang[a+1]][4]) {
              //Dann schau die Spieldifferenz an
              if(TabelleGruppe2VorlaufigBackup[Rang[a]][6]-TabelleGruppe2VorlaufigBackup[Rang[a]][7]<TabelleGruppe2VorlaufigBackup[Rang[a+1]][6]-TabelleGruppe2VorlaufigBackup[Rang[a+1]][7]) {
                //Dann tausche
                let RangBackup = []
                for(let x=0; x<10; x++) {
                  RangBackup.push(Rang[x])
                }
                Rang[a]=Rang[a+1]
                Rang[a+1]=RangBackup[a]
              }
              else if(TabelleGruppe2VorlaufigBackup[Rang[a]][6]-TabelleGruppe2VorlaufigBackup[Rang[a]][7] === TabelleGruppe2VorlaufigBackup[Rang[a+1]][6]-TabelleGruppe2VorlaufigBackup[Rang[a+1]][7]){
                //Dann schau, wer mehr Spiele gewonnen hat
                if(TabelleGruppe2VorlaufigBackup[Rang[a]][6]<TabelleGruppe2VorlaufigBackup[Rang[a+1]][6]) {
                  //Dann tausche
                  let RangBackup = []
                  for(let x=0; x<10; x++) {
                    RangBackup.push(Rang[x])
                  }
                  Rang[a]=Rang[a+1]
                  Rang[a+1]=RangBackup[a]
                }
                else if(TabelleGruppe2VorlaufigBackup[Rang[a]][6] === TabelleGruppe2VorlaufigBackup[Rang[a+1]][6]) {
                  //Dann ist alles identisch
                }
              }
            }
          }
        }
      }
    }
    

    //Befuelle am Ende den Array "TabelleGruppe2Vorlaufig" nach der Reihenfolge, die man vorher herausgefunden hat
    for(let a=0; a<10; a++) {
      if(TabelleGruppe2VorlaufigBackup[Rang[a]][0].toString()!==null && TabelleGruppe2VorlaufigBackup[Rang[a]][0].toString()!=="") {
        TabelleGruppe2Vorlaufig[a][0] = TabelleGruppe2VorlaufigBackup[Rang[a]][0].toString();
        TabelleGruppe2Vorlaufig[a][1] = TabelleGruppe2VorlaufigBackup[Rang[a]][1].toString();
        TabelleGruppe2Vorlaufig[a][2] = TabelleGruppe2VorlaufigBackup[Rang[a]][2].toString();
        TabelleGruppe2Vorlaufig[a][3] = TabelleGruppe2VorlaufigBackup[Rang[a]][3].toString();
        TabelleGruppe2Vorlaufig[a][4] = TabelleGruppe2VorlaufigBackup[Rang[a]][4].toString() + ":" + TabelleGruppe2VorlaufigBackup[Rang[a]][5].toString();
        TabelleGruppe2Vorlaufig[a][5] = TabelleGruppe2VorlaufigBackup[Rang[a]][6].toString() + ":" + TabelleGruppe2VorlaufigBackup[Rang[a]][7].toString();
        TabelleGruppe2Vorlaufig[a][6] = TabelleGruppe2VorlaufigBackup[Rang[a]][8].toString();
        TabelleGruppe2Vorlaufig[a][7] = TabelleGruppe2VorlaufigBackup[Rang[a]][9].toString();
        TabelleGruppe2Vorlaufig[a][8] = TabelleGruppe2VorlaufigBackup[Rang[a]][10].toString();
        TabelleGruppe2Vorlaufig[a][9] = this.state.BPMPGruppe2[Rang[a]][0];
        TabelleGruppe2Vorlaufig[a][10] = this.state.BPMPGruppe2[Rang[a]][1];
      }
    }
  }

  SpieleGruppe1() {
    return(
      <div className="ligatabelle">
        <table>
            <tbody>
            <tr>
              <th>Datum</th>
              <th>Begegnung</th>
              <th>1. Satz</th>
              <th>2. Satz</th>
              <th>3. Satz</th>
            </tr>
            {this.EinzelneSpieleDunkelGruppe1(0)}{this.EinzelneSpieleHellGruppe1(1)}{this.EinzelneSpieleDunkelGruppe1(2)}{this.EinzelneSpieleHellGruppe1(3)}{this.EinzelneSpieleDunkelGruppe1(4)}{this.EinzelneSpieleHellGruppe1(5)}{this.EinzelneSpieleDunkelGruppe1(6)}{this.EinzelneSpieleHellGruppe1(7)}{this.EinzelneSpieleDunkelGruppe1(8)}{this.EinzelneSpieleHellGruppe1(9)}
            {this.EinzelneSpieleDunkelGruppe1(10)}{this.EinzelneSpieleHellGruppe1(11)}{this.EinzelneSpieleDunkelGruppe1(12)}{this.EinzelneSpieleHellGruppe1(13)}{this.EinzelneSpieleDunkelGruppe1(14)}{this.EinzelneSpieleHellGruppe1(15)}{this.EinzelneSpieleDunkelGruppe1(16)}{this.EinzelneSpieleHellGruppe1(17)}{this.EinzelneSpieleDunkelGruppe1(18)}{this.EinzelneSpieleHellGruppe1(19)}
            {this.EinzelneSpieleDunkelGruppe1(20)}{this.EinzelneSpieleHellGruppe1(21)}{this.EinzelneSpieleDunkelGruppe1(22)}{this.EinzelneSpieleHellGruppe1(23)}{this.EinzelneSpieleDunkelGruppe1(24)}{this.EinzelneSpieleHellGruppe1(25)}{this.EinzelneSpieleDunkelGruppe1(26)}{this.EinzelneSpieleHellGruppe1(27)}{this.EinzelneSpieleDunkelGruppe1(28)}{this.EinzelneSpieleHellGruppe1(29)}
            {this.EinzelneSpieleDunkelGruppe1(30)}{this.EinzelneSpieleHellGruppe1(31)}{this.EinzelneSpieleDunkelGruppe1(32)}{this.EinzelneSpieleHellGruppe1(33)}{this.EinzelneSpieleDunkelGruppe1(34)}{this.EinzelneSpieleHellGruppe1(35)}{this.EinzelneSpieleDunkelGruppe1(36)}{this.EinzelneSpieleHellGruppe1(37)}{this.EinzelneSpieleDunkelGruppe1(38)}{this.EinzelneSpieleHellGruppe1(39)}
            {this.EinzelneSpieleDunkelGruppe1(40)}{this.EinzelneSpieleHellGruppe1(41)}{this.EinzelneSpieleDunkelGruppe1(42)}{this.EinzelneSpieleHellGruppe1(43)}{this.EinzelneSpieleDunkelGruppe1(44)}{this.EinzelneSpieleHellGruppe1(45)}{this.EinzelneSpieleDunkelGruppe1(46)}{this.EinzelneSpieleHellGruppe1(47)}{this.EinzelneSpieleDunkelGruppe1(48)}{this.EinzelneSpieleHellGruppe1(49)}
            {this.EinzelneSpieleDunkelGruppe1(50)}{this.EinzelneSpieleHellGruppe1(51)}{this.EinzelneSpieleDunkelGruppe1(52)}{this.EinzelneSpieleHellGruppe1(53)}{this.EinzelneSpieleDunkelGruppe1(54)}{this.EinzelneSpieleHellGruppe1(55)}{this.EinzelneSpieleDunkelGruppe1(56)}{this.EinzelneSpieleHellGruppe1(57)}{this.EinzelneSpieleDunkelGruppe1(58)}{this.EinzelneSpieleHellGruppe1(59)}
            {this.EinzelneSpieleDunkelGruppe1(60)}{this.EinzelneSpieleHellGruppe1(61)}{this.EinzelneSpieleDunkelGruppe1(62)}{this.EinzelneSpieleHellGruppe1(63)}{this.EinzelneSpieleDunkelGruppe1(64)}{this.EinzelneSpieleHellGruppe1(65)}{this.EinzelneSpieleDunkelGruppe1(66)}{this.EinzelneSpieleHellGruppe1(67)}{this.EinzelneSpieleDunkelGruppe1(68)}{this.EinzelneSpieleHellGruppe1(69)}
            {this.EinzelneSpieleDunkelGruppe1(70)}{this.EinzelneSpieleHellGruppe1(71)}{this.EinzelneSpieleDunkelGruppe1(72)}{this.EinzelneSpieleHellGruppe1(73)}{this.EinzelneSpieleDunkelGruppe1(74)}{this.EinzelneSpieleHellGruppe1(75)}{this.EinzelneSpieleDunkelGruppe1(76)}{this.EinzelneSpieleHellGruppe1(77)}{this.EinzelneSpieleDunkelGruppe1(78)}{this.EinzelneSpieleHellGruppe1(79)}
            {this.EinzelneSpieleDunkelGruppe1(80)}{this.EinzelneSpieleHellGruppe1(81)}{this.EinzelneSpieleDunkelGruppe1(82)}{this.EinzelneSpieleHellGruppe1(83)}{this.EinzelneSpieleDunkelGruppe1(84)}{this.EinzelneSpieleHellGruppe1(85)}{this.EinzelneSpieleDunkelGruppe1(86)}{this.EinzelneSpieleHellGruppe1(87)}{this.EinzelneSpieleDunkelGruppe1(88)}{this.EinzelneSpieleHellGruppe1(89)}
            {this.EinzelneSpieleDunkelGruppe1(90)}{this.EinzelneSpieleHellGruppe1(91)}{this.EinzelneSpieleDunkelGruppe1(92)}{this.EinzelneSpieleHellGruppe1(93)}{this.EinzelneSpieleDunkelGruppe1(94)}{this.EinzelneSpieleHellGruppe1(95)}{this.EinzelneSpieleDunkelGruppe1(96)}{this.EinzelneSpieleHellGruppe1(97)}{this.EinzelneSpieleDunkelGruppe1(98)}{this.EinzelneSpieleHellGruppe1(99)}
            {this.EinzelneSpieleDunkelGruppe1(100)}{this.EinzelneSpieleHellGruppe1(101)}{this.EinzelneSpieleDunkelGruppe1(102)}{this.EinzelneSpieleHellGruppe1(103)}{this.EinzelneSpieleDunkelGruppe1(104)}{this.EinzelneSpieleHellGruppe1(105)}{this.EinzelneSpieleDunkelGruppe1(106)}{this.EinzelneSpieleHellGruppe1(107)}{this.EinzelneSpieleDunkelGruppe1(108)}{this.EinzelneSpieleHellGruppe1(109)}
            {this.EinzelneSpieleDunkelGruppe1(110)}{this.EinzelneSpieleHellGruppe1(111)}{this.EinzelneSpieleDunkelGruppe1(112)}{this.EinzelneSpieleHellGruppe1(113)}{this.EinzelneSpieleDunkelGruppe1(114)}{this.EinzelneSpieleHellGruppe1(115)}{this.EinzelneSpieleDunkelGruppe1(116)}{this.EinzelneSpieleHellGruppe1(117)}{this.EinzelneSpieleDunkelGruppe1(118)}{this.EinzelneSpieleHellGruppe1(119)}
            {this.EinzelneSpieleDunkelGruppe1(120)}{this.EinzelneSpieleHellGruppe1(121)}{this.EinzelneSpieleDunkelGruppe1(122)}{this.EinzelneSpieleHellGruppe1(123)}{this.EinzelneSpieleDunkelGruppe1(124)}{this.EinzelneSpieleHellGruppe1(125)}{this.EinzelneSpieleDunkelGruppe1(126)}{this.EinzelneSpieleHellGruppe1(127)}{this.EinzelneSpieleDunkelGruppe1(128)}{this.EinzelneSpieleHellGruppe1(129)}
            {this.EinzelneSpieleDunkelGruppe1(130)}{this.EinzelneSpieleHellGruppe1(131)}{this.EinzelneSpieleDunkelGruppe1(132)}{this.EinzelneSpieleHellGruppe1(133)}{this.EinzelneSpieleDunkelGruppe1(134)}{this.EinzelneSpieleHellGruppe1(135)}{this.EinzelneSpieleDunkelGruppe1(136)}{this.EinzelneSpieleHellGruppe1(137)}{this.EinzelneSpieleDunkelGruppe1(138)}{this.EinzelneSpieleHellGruppe1(139)}
            {this.EinzelneSpieleDunkelGruppe1(140)}{this.EinzelneSpieleHellGruppe1(141)}{this.EinzelneSpieleDunkelGruppe1(142)}{this.EinzelneSpieleHellGruppe1(143)}{this.EinzelneSpieleDunkelGruppe1(144)}{this.EinzelneSpieleHellGruppe1(145)}{this.EinzelneSpieleDunkelGruppe1(146)}{this.EinzelneSpieleHellGruppe1(147)}{this.EinzelneSpieleDunkelGruppe1(148)}{this.EinzelneSpieleHellGruppe1(149)}
            </tbody>
        </table>
      </div>
    )
  }

  SpieleGruppe2() {
    return(
      <div className="ligatabelle">
        <table>
            <tbody>
            <tr>
              <th>Datum</th>
              <th>Begegnung</th>
              <th>1. Satz</th>
              <th>2. Satz</th>
              <th>3. Satz</th>
            </tr>
            {this.EinzelneSpieleDunkelGruppe2(0)}{this.EinzelneSpieleHellGruppe2(1)}{this.EinzelneSpieleDunkelGruppe2(2)}{this.EinzelneSpieleHellGruppe2(3)}{this.EinzelneSpieleDunkelGruppe2(4)}{this.EinzelneSpieleHellGruppe2(5)}{this.EinzelneSpieleDunkelGruppe2(6)}{this.EinzelneSpieleHellGruppe2(7)}{this.EinzelneSpieleDunkelGruppe2(8)}{this.EinzelneSpieleHellGruppe2(9)}
            {this.EinzelneSpieleDunkelGruppe2(10)}{this.EinzelneSpieleHellGruppe2(11)}{this.EinzelneSpieleDunkelGruppe2(12)}{this.EinzelneSpieleHellGruppe2(13)}{this.EinzelneSpieleDunkelGruppe2(14)}{this.EinzelneSpieleHellGruppe2(15)}{this.EinzelneSpieleDunkelGruppe2(16)}{this.EinzelneSpieleHellGruppe2(17)}{this.EinzelneSpieleDunkelGruppe2(18)}{this.EinzelneSpieleHellGruppe2(19)}
            {this.EinzelneSpieleDunkelGruppe2(20)}{this.EinzelneSpieleHellGruppe2(21)}{this.EinzelneSpieleDunkelGruppe2(22)}{this.EinzelneSpieleHellGruppe2(23)}{this.EinzelneSpieleDunkelGruppe2(24)}{this.EinzelneSpieleHellGruppe2(25)}{this.EinzelneSpieleDunkelGruppe2(26)}{this.EinzelneSpieleHellGruppe2(27)}{this.EinzelneSpieleDunkelGruppe2(28)}{this.EinzelneSpieleHellGruppe2(29)}
            {this.EinzelneSpieleDunkelGruppe2(30)}{this.EinzelneSpieleHellGruppe2(31)}{this.EinzelneSpieleDunkelGruppe2(32)}{this.EinzelneSpieleHellGruppe2(33)}{this.EinzelneSpieleDunkelGruppe2(34)}{this.EinzelneSpieleHellGruppe2(35)}{this.EinzelneSpieleDunkelGruppe2(36)}{this.EinzelneSpieleHellGruppe2(37)}{this.EinzelneSpieleDunkelGruppe2(38)}{this.EinzelneSpieleHellGruppe2(39)}
            {this.EinzelneSpieleDunkelGruppe2(40)}{this.EinzelneSpieleHellGruppe2(41)}{this.EinzelneSpieleDunkelGruppe2(42)}{this.EinzelneSpieleHellGruppe2(43)}{this.EinzelneSpieleDunkelGruppe2(44)}{this.EinzelneSpieleHellGruppe2(45)}{this.EinzelneSpieleDunkelGruppe2(46)}{this.EinzelneSpieleHellGruppe2(47)}{this.EinzelneSpieleDunkelGruppe2(48)}{this.EinzelneSpieleHellGruppe2(49)}
            {this.EinzelneSpieleDunkelGruppe2(50)}{this.EinzelneSpieleHellGruppe2(51)}{this.EinzelneSpieleDunkelGruppe2(52)}{this.EinzelneSpieleHellGruppe2(53)}{this.EinzelneSpieleDunkelGruppe2(54)}{this.EinzelneSpieleHellGruppe2(55)}{this.EinzelneSpieleDunkelGruppe2(56)}{this.EinzelneSpieleHellGruppe2(57)}{this.EinzelneSpieleDunkelGruppe2(58)}{this.EinzelneSpieleHellGruppe2(59)}
            {this.EinzelneSpieleDunkelGruppe2(60)}{this.EinzelneSpieleHellGruppe2(61)}{this.EinzelneSpieleDunkelGruppe2(62)}{this.EinzelneSpieleHellGruppe2(63)}{this.EinzelneSpieleDunkelGruppe2(64)}{this.EinzelneSpieleHellGruppe2(65)}{this.EinzelneSpieleDunkelGruppe2(66)}{this.EinzelneSpieleHellGruppe2(67)}{this.EinzelneSpieleDunkelGruppe2(68)}{this.EinzelneSpieleHellGruppe2(69)}
            {this.EinzelneSpieleDunkelGruppe2(70)}{this.EinzelneSpieleHellGruppe2(71)}{this.EinzelneSpieleDunkelGruppe2(72)}{this.EinzelneSpieleHellGruppe2(73)}{this.EinzelneSpieleDunkelGruppe2(74)}{this.EinzelneSpieleHellGruppe2(75)}{this.EinzelneSpieleDunkelGruppe2(76)}{this.EinzelneSpieleHellGruppe2(77)}{this.EinzelneSpieleDunkelGruppe2(78)}{this.EinzelneSpieleHellGruppe2(79)}
            {this.EinzelneSpieleDunkelGruppe2(80)}{this.EinzelneSpieleHellGruppe2(81)}{this.EinzelneSpieleDunkelGruppe2(82)}{this.EinzelneSpieleHellGruppe2(83)}{this.EinzelneSpieleDunkelGruppe2(84)}{this.EinzelneSpieleHellGruppe2(85)}{this.EinzelneSpieleDunkelGruppe2(86)}{this.EinzelneSpieleHellGruppe2(87)}{this.EinzelneSpieleDunkelGruppe2(88)}{this.EinzelneSpieleHellGruppe2(89)}
            {this.EinzelneSpieleDunkelGruppe2(90)}{this.EinzelneSpieleHellGruppe2(91)}{this.EinzelneSpieleDunkelGruppe2(92)}{this.EinzelneSpieleHellGruppe2(93)}{this.EinzelneSpieleDunkelGruppe2(94)}{this.EinzelneSpieleHellGruppe2(95)}{this.EinzelneSpieleDunkelGruppe2(96)}{this.EinzelneSpieleHellGruppe2(97)}{this.EinzelneSpieleDunkelGruppe2(98)}{this.EinzelneSpieleHellGruppe2(99)}
            {this.EinzelneSpieleDunkelGruppe2(100)}{this.EinzelneSpieleHellGruppe2(101)}{this.EinzelneSpieleDunkelGruppe2(102)}{this.EinzelneSpieleHellGruppe2(103)}{this.EinzelneSpieleDunkelGruppe2(104)}{this.EinzelneSpieleHellGruppe2(105)}{this.EinzelneSpieleDunkelGruppe2(106)}{this.EinzelneSpieleHellGruppe2(107)}{this.EinzelneSpieleDunkelGruppe2(108)}{this.EinzelneSpieleHellGruppe2(109)}
            {this.EinzelneSpieleDunkelGruppe2(110)}{this.EinzelneSpieleHellGruppe2(111)}{this.EinzelneSpieleDunkelGruppe2(112)}{this.EinzelneSpieleHellGruppe2(113)}{this.EinzelneSpieleDunkelGruppe2(114)}{this.EinzelneSpieleHellGruppe2(115)}{this.EinzelneSpieleDunkelGruppe2(116)}{this.EinzelneSpieleHellGruppe2(117)}{this.EinzelneSpieleDunkelGruppe2(118)}{this.EinzelneSpieleHellGruppe2(119)}
            {this.EinzelneSpieleDunkelGruppe2(120)}{this.EinzelneSpieleHellGruppe2(121)}{this.EinzelneSpieleDunkelGruppe2(122)}{this.EinzelneSpieleHellGruppe2(123)}{this.EinzelneSpieleDunkelGruppe2(124)}{this.EinzelneSpieleHellGruppe2(125)}{this.EinzelneSpieleDunkelGruppe2(126)}{this.EinzelneSpieleHellGruppe2(127)}{this.EinzelneSpieleDunkelGruppe2(128)}{this.EinzelneSpieleHellGruppe2(129)}
            {this.EinzelneSpieleDunkelGruppe2(130)}{this.EinzelneSpieleHellGruppe2(131)}{this.EinzelneSpieleDunkelGruppe2(132)}{this.EinzelneSpieleHellGruppe2(133)}{this.EinzelneSpieleDunkelGruppe2(134)}{this.EinzelneSpieleHellGruppe2(135)}{this.EinzelneSpieleDunkelGruppe2(136)}{this.EinzelneSpieleHellGruppe2(137)}{this.EinzelneSpieleDunkelGruppe2(138)}{this.EinzelneSpieleHellGruppe2(139)}
            {this.EinzelneSpieleDunkelGruppe2(140)}{this.EinzelneSpieleHellGruppe2(141)}{this.EinzelneSpieleDunkelGruppe2(142)}{this.EinzelneSpieleHellGruppe2(143)}{this.EinzelneSpieleDunkelGruppe2(144)}{this.EinzelneSpieleHellGruppe2(145)}{this.EinzelneSpieleDunkelGruppe2(146)}{this.EinzelneSpieleHellGruppe2(147)}{this.EinzelneSpieleDunkelGruppe2(148)}{this.EinzelneSpieleHellGruppe2(149)}
            </tbody>
        </table>
      </div>
    )
  }

  EinzelneSpieleDunkelGruppe1(i) {
    if(this.state.SpieleGruppe1[i][0] !== null && this.state.SpieleGruppe1[i][0] !== "") {
      return(
        <tr className="dunkler">
        <td>
        {this.state.SpieleGruppe1[i][0]}
        </td>
        <td>
        {this.state.SpieleGruppe1[i][1] + " vs. " + this.state.SpieleGruppe1[i][2]}
        </td>
        <td>
        {this.state.SpieleGruppe1[i][3] + ":" + this.state.SpieleGruppe1[i][4]}
        </td>
        <td>
        {this.state.SpieleGruppe1[i][5] + ":" + this.state.SpieleGruppe1[i][6]}
        </td>
        <td>
        {this.state.SpieleGruppe1[i][7] + ":" + this.state.SpieleGruppe1[i][8]}
        </td>
        </tr>
      )
    }
    else {
      return(<></>)
    }
  }

  EinzelneSpieleHellGruppe1(i) {
    if(this.state.SpieleGruppe1[i][0] !== null && this.state.SpieleGruppe1[i][0] !== "") {  
      return(
        <tr>
          <td>
          {this.state.SpieleGruppe1[i][0]}
          </td>
          <td>
          {this.state.SpieleGruppe1[i][1] + " vs. " + this.state.SpieleGruppe1[i][2]}
          </td>
          <td>
          {this.state.SpieleGruppe1[i][3] + ":" + this.state.SpieleGruppe1[i][4]}
          </td>
          <td>
          {this.state.SpieleGruppe1[i][5] + ":" + this.state.SpieleGruppe1[i][6]}
          </td>
          <td>
          {this.state.SpieleGruppe1[i][7] + ":" + this.state.SpieleGruppe1[i][8]}
          </td>
        </tr>
      )
    }
    else {
      return(<></>)
    }
  }

  EinzelneSpieleDunkelGruppe2(i) {
    if(this.state.SpieleGruppe2[i][0] !== null && this.state.SpieleGruppe2[i][0] !== "") {
      return(
        <tr className="dunkler">
        <td>
        {this.state.SpieleGruppe2[i][0]}
        </td>
        <td>
        {this.state.SpieleGruppe2[i][1] + " vs. " + this.state.SpieleGruppe2[i][2]}
        </td>
        <td>
        {this.state.SpieleGruppe2[i][3] + ":" + this.state.SpieleGruppe2[i][4]}
        </td>
        <td>
        {this.state.SpieleGruppe2[i][5] + ":" + this.state.SpieleGruppe2[i][6]}
        </td>
        <td>
        {this.state.SpieleGruppe2[i][7] + ":" + this.state.SpieleGruppe2[i][8]}
        </td>
        </tr>
      )
    }
    else {
      return(<></>)
    }
  }

  EinzelneSpieleHellGruppe2(i) {
    if(this.state.SpieleGruppe2[i][0] !== null && this.state.SpieleGruppe2[i][0] !== "") {
      return(
        <tr>
          <td>
          {this.state.SpieleGruppe2[i][0]}
          </td>
          <td>
          {this.state.SpieleGruppe2[i][1] + " vs. " + this.state.SpieleGruppe2[i][2]}
          </td>
          <td>
          {this.state.SpieleGruppe2[i][3] + ":" + this.state.SpieleGruppe2[i][4]}
          </td>
          <td>
          {this.state.SpieleGruppe2[i][5] + ":" + this.state.SpieleGruppe2[i][6]}
          </td>
          <td>
          {this.state.SpieleGruppe2[i][7] + ":" + this.state.SpieleGruppe2[i][8]}
          </td>
        </tr>
      )
    }
    else {
      return(<></>)
    }
  }

  render() {
    if(this.state.sperren === "No") {
      return(
        <div>
          <UnderHeaderTabelle />
          <div className="container">
            {this.state.Liga[0] === null || this.state.Liga[0] === "" ? (
                <div>
                    Liga hat noch nicht begonnen
                </div>
            ) : ( this.state.Liga[0] === "1" ? (
              <div>
                {this.BerechnungGruppe1()}
                <div id="tabpanel">
                  <ul role="tablist" id="tablist">
                    <div>
                        <li className="weisserbuttonTabelle">
                            <button onClick={(event) => {this.setState({WelcheGruppe: 0})}}>
                              {this.state.Liga[1]}
                            </button>
                        </li>
                    </div>
                  </ul>
                </div>

                <div id="tabcontentTabelle">
                  <div>
                    {this.TabelleGruppe1()}
                  </div>
                </div>
                <div id="tabcontentTabelle">
                  <div>
                    {this.SpieleGruppe1()}
                  </div>
                </div>
              </div>
            ) : (
              <div>
                {this.BerechnungGruppe1()}
                {this.BerechnungGruppe2()}
                <div id="tabpanel">
                  <ul role="tablist" id="tablist">
                      {this.state.WelcheGruppe === 0 ? ( //1. Gruppe
                          <div>
                              <li className="weisserbuttonTabelle">
                                  <button onClick={(event) => {this.setState({WelcheGruppe: 0})}}>
                                    {this.state.Liga[1]}
                                  </button>
                              </li>
                              <li className="grauerbuttonTabelle">
                                  <button onClick={(event) => {this.setState({WelcheGruppe: 1})}}>
                                    {this.state.Liga[2]}
                                  </button>
                              </li>
                          </div>
                      ) : ( //2. Gruppe
                          <div>
                              <li className="grauerbuttonTabelle">
                                  <button onClick={(event) => {this.setState({WelcheGruppe: 0})}}>
                                    {this.state.Liga[1]}
                                  </button>
                              </li>
                              <li className="weisserbuttonTabelle">
                                  <button onClick={(event) => {this.setState({WelcheGruppe: 1})}}>
                                    {this.state.Liga[2]}
                                  </button>
                              </li>
                          </div>
                      )}
                  </ul>
                </div>

                <div id="tabcontentTabelle">
                  {this.state.WelcheGruppe === 0 ? (
                      <div>
                        {this.TabelleGruppe1()}
                      </div>
                  ) : (
                      <div>
                        {this.TabelleGruppe2()}
                      </div>
                  )}
                </div>
                <div id="tabcontentTabelle">
                  {this.state.WelcheGruppe === 0 ? (
                      <div>
                        {this.SpieleGruppe1()}
                      </div>
                  ) : (
                      <div>
                        {this.SpieleGruppe2()}
                      </div>
                  )}
                </div>
              </div>
            ) )}


                      



          </div>
        </div>
      )
    }
    else {
      return(
        <div className="container">
          <h3><p id="roteFarbe">
            <strong>{this.state.eilmeldung}</strong>
          </p></h3>
        </div>
      );
    }
  }

}