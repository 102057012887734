import React from "react";
import { UnderHeaderHauptprogramm } from "./UnderHeaderHauptprogramm";
import axios from "axios";
import { Verbotmoeglichkeiten } from "./HauptprogrammFunktionen";
import { alleNamenSchritt7 } from "./HauptprogrammFunktionen";


export class Hauptprogramm7 extends React.Component {
  constructor() {
    super();
    this.state = {
      errorSchritt7Verbot1: "",
      errorSchritt7Verbot2: "",
      errorSchritt7Verbot3: "",
      errorSchritt7Verbot4: "",
      errorSchritt7Verbot5: "",
      errorSchritt7Verbot6: "",
      errorSchritt7Verbot7: "",
      errorSchritt7Verbot8: "",
      errorSchritt7Verbot9: "",
      errorSchritt7Verbot10: "",
      errorSchritt7Verbot11: "",
      errorSchritt7Verbot12: "",
      errorSchritt7Verbot13: "",
      errorSchritt7Verbot14: "",
      errorSchritt7Verbot15: "",
      errorSchritt7Verbot16: "",
      errorSchritt7Verbot17: "",
      errorSchritt7Verbot18: "",
      errorSchritt7Verbot19: "",
      errorSchritt7Verbot20: "",
      errorSchritt7: "",
      
      verbotxx: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],

      namexxtopf1: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
      WieOft: ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0"],
      
      doppeltePerson1boolean: false,
      doppeltePerson2boolean: false,
      doppeltePerson3boolean: false,
      doppeltePerson4boolean: false,
      doppeltePerson5boolean: false,
      doppeltePerson6boolean: false,
      doppeltePerson7boolean: false,
      doppeltePerson8boolean: false,
      doppeltePerson9boolean: false,
      doppeltePerson10boolean: false,
      doppeltePerson11boolean: false,
      doppeltePerson12boolean: false,
      doppeltePerson13boolean: false,
      doppeltePerson14boolean: false,
      doppeltePerson15boolean: false,
      doppeltePerson16boolean: false,
      doppeltePerson17boolean: false,
      doppeltePerson18boolean: false,
      doppeltePerson19boolean: false,
      doppeltePerson20boolean: false, 

      trainingseinheitentopf1: "6",
      bevorzugteTopfgroessetopf1: "1",
      alternativeTopfgroessetopf1: "0",
      topfauswahl: 0,

      loading: true,
      Schritt: -1,

      naechsteSeite: false,
    };
  }


  componentDidMount() {
    var local = window.location.pathname;
    var output = "";
    if (local.length === 12) {
    output = local.slice(-1);
    }
    if (local.length === 13) {
    output = local.slice(-2);
    }
    if (local.length === 14) {
    output = local.slice(-3);
    }
    if (local.length === 15) {
    output = local.slice(-4);
    }
    if (local.length === 16) {
    output = local.slice(-5);
    }
    if (local.length === 17) {
    output = local.slice(-6);
    }
    if (local.length === 18) {
    output = local.slice(-7);
    }
    if (local.length === 19) {
    output = local.slice(-8);
    }
    var getlink1 = "".concat("/api/6/", output);
    
    axios
      .get(getlink1)
      .then((res) => {
        const message = res.data;
        console.log(message);
        let Neunamexxtopf1 = [];
        for(let a=0; a<40; a++) {
          Neunamexxtopf1[a] = message[a];
        }
        let NeuWieOft = [];
        for(let a=0; a<40; a++) {
          NeuWieOft[a] = message[40 + a];
        }
        this.setState({ 
          namexxtopf1: Neunamexxtopf1,
          WieOft: NeuWieOft,
          trainingseinheitentopf1: parseInt(message[80]),
          bevorzugteTopfgroessetopf1: parseInt(message[81]),
          alternativeTopfgroessetopf1: parseInt(message[82]),
          topfauswahl: parseInt(message[83]),
          Schritt: parseInt(message[84]),
        });
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.log(error.message);
      });
  }
  
  validateSchritt7(verbotxx) {
    let errorSchritt7Verbot1 = "";
    let errorSchritt7Verbot2 = "";
    let errorSchritt7Verbot3 = "";
    let errorSchritt7Verbot4 = "";
    let errorSchritt7Verbot5 = "";
    let errorSchritt7Verbot6 = "";
    let errorSchritt7Verbot7 = "";
    let errorSchritt7Verbot8 = "";
    let errorSchritt7Verbot9 = "";
    let errorSchritt7Verbot10 = "";
    let errorSchritt7Verbot11 = "";
    let errorSchritt7Verbot12 = "";
    let errorSchritt7Verbot13 = "";
    let errorSchritt7Verbot14 = "";
    let errorSchritt7Verbot15 = "";
    let errorSchritt7Verbot16 = "";
    let errorSchritt7Verbot17 = "";
    let errorSchritt7Verbot18 = "";
    let errorSchritt7Verbot19 = "";
    let errorSchritt7Verbot20 = "";
    let errorSchritt7 = "";

    if (verbotxx[0] !== "") {
      if (verbotxx[1] === "") {
        errorSchritt7Verbot1 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (verbotxx[2] !== "") {
      if (verbotxx[3] === "") {
        errorSchritt7Verbot2 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (verbotxx[4] !== "") {
      if (verbotxx[5] === "") {
        errorSchritt7Verbot3 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (verbotxx[6] !== "") {
      if (verbotxx[7] === "") {
        errorSchritt7Verbot4 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (verbotxx[8] !== "") {
      if (verbotxx[9] === "") {
        errorSchritt7Verbot5 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (verbotxx[10] !== "") {
      if (verbotxx[11] === "") {
        errorSchritt7Verbot6 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (verbotxx[12] !== "") {
      if (verbotxx[13] === "") {
        errorSchritt7Verbot7 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (verbotxx[14] !== "") {
      if (verbotxx[15] === "") {
        errorSchritt7Verbot8 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (verbotxx[16] !== "") {
      if (verbotxx[17] === "") {
        errorSchritt7Verbot9 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (verbotxx[18] !== "") {
      if (verbotxx[19] === "") {
        errorSchritt7Verbot10 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (verbotxx[20] !== "") {
      if (verbotxx[21] === "") {
        errorSchritt7Verbot11 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (verbotxx[22] !== "") {
      if (verbotxx[23] === "") {
        errorSchritt7Verbot12 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (verbotxx[24] !== "") {
      if (verbotxx[25] === "") {
        errorSchritt7Verbot13 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (verbotxx[26] !== "") {
      if (verbotxx[27] === "") {
        errorSchritt7Verbot14 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (verbotxx[28] !== "") {
      if (verbotxx[29] === "") {
        errorSchritt7Verbot15 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (verbotxx[30] !== "") {
      if (verbotxx[31] === "") {
        errorSchritt7Verbot16 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (verbotxx[32] !== "") {
      if (verbotxx[33] === "") {
        errorSchritt7Verbot17 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (verbotxx[34] !== "") {
      if (verbotxx[35] === "") {
        errorSchritt7Verbot18 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (verbotxx[36] !== "") {
      if (verbotxx[37] === "") {
        errorSchritt7Verbot19 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (verbotxx[38] !== "") {
      if (verbotxx[39] === "") {
        errorSchritt7Verbot20 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }

    if (
      errorSchritt7Verbot1 ||
      errorSchritt7Verbot2 ||
      errorSchritt7Verbot3 ||
      errorSchritt7Verbot4 ||
      errorSchritt7Verbot5 ||
      errorSchritt7Verbot6 ||
      errorSchritt7Verbot7 ||
      errorSchritt7Verbot8 ||
      errorSchritt7Verbot9 ||
      errorSchritt7Verbot10 ||
      errorSchritt7Verbot11 ||
      errorSchritt7Verbot12 ||
      errorSchritt7Verbot13 ||
      errorSchritt7Verbot14 ||
      errorSchritt7Verbot15 ||
      errorSchritt7Verbot16 ||
      errorSchritt7Verbot17 ||
      errorSchritt7Verbot18 ||
      errorSchritt7Verbot19 ||
      errorSchritt7Verbot20
    ) {
      this.setState({
        errorSchritt7Verbot1,
        errorSchritt7Verbot2,
        errorSchritt7Verbot3,
        errorSchritt7Verbot4,
        errorSchritt7Verbot5,
        errorSchritt7Verbot6,
        errorSchritt7Verbot7,
        errorSchritt7Verbot8,
        errorSchritt7Verbot9,
        errorSchritt7Verbot10,
        errorSchritt7Verbot11,
        errorSchritt7Verbot12,
        errorSchritt7Verbot13,
        errorSchritt7Verbot14,
        errorSchritt7Verbot15,
        errorSchritt7Verbot16,
        errorSchritt7Verbot17,
        errorSchritt7Verbot18,
        errorSchritt7Verbot19,
        errorSchritt7Verbot20,
        errorSchritt7,
      });
      return false;
    }
    this.setState({
      errorSchritt7Verbot1: "",
      errorSchritt7Verbot2: "",
      errorSchritt7Verbot3: "",
      errorSchritt7Verbot4: "",
      errorSchritt7Verbot5: "",
      errorSchritt7Verbot6: "",
      errorSchritt7Verbot7: "",
      errorSchritt7Verbot8: "",
      errorSchritt7Verbot9: "",
      errorSchritt7Verbot10: "",
      errorSchritt7Verbot11: "",
      errorSchritt7Verbot12: "",
      errorSchritt7Verbot13: "",
      errorSchritt7Verbot14: "",
      errorSchritt7Verbot15: "",
      errorSchritt7Verbot16: "",
      errorSchritt7Verbot17: "",
      errorSchritt7Verbot18: "",
      errorSchritt7Verbot19: "",
      errorSchritt7Verbot20: "",
      errorSchritt7: "",
    });
    return true;
  };

  /*
  Handle Submit
  */

 handleCheckdoppeltePerson1boolean = (event) => {
    this.setState({ doppeltePerson1boolean: event.target.checked });
  };
  handleCheckdoppeltePerson2boolean = (event) => {
    this.setState({ doppeltePerson2boolean: event.target.checked });
  };
  handleCheckdoppeltePerson3boolean = (event) => {
    this.setState({ doppeltePerson3boolean: event.target.checked });
  };
  handleCheckdoppeltePerson4boolean = (event) => {
    this.setState({ doppeltePerson4boolean: event.target.checked });
  };
  handleCheckdoppeltePerson5boolean = (event) => {
    this.setState({ doppeltePerson5boolean: event.target.checked });
  };
  handleCheckdoppeltePerson6boolean = (event) => {
    this.setState({ doppeltePerson6boolean: event.target.checked });
  };
  handleCheckdoppeltePerson7boolean = (event) => {
    this.setState({ doppeltePerson7boolean: event.target.checked });
  };
  handleCheckdoppeltePerson8boolean = (event) => {
    this.setState({ doppeltePerson8boolean: event.target.checked });
  };
  handleCheckdoppeltePerson9boolean = (event) => {
    this.setState({ doppeltePerson9boolean: event.target.checked });
  };
  handleCheckdoppeltePerson10boolean = (event) => {
    this.setState({ doppeltePerson10boolean: event.target.checked });
  };
  handleCheckdoppeltePerson11boolean = (event) => {
    this.setState({ doppeltePerson11boolean: event.target.checked });
  };
  handleCheckdoppeltePerson12boolean = (event) => {
    this.setState({ doppeltePerson12boolean: event.target.checked });
  };
  handleCheckdoppeltePerson13boolean = (event) => {
    this.setState({ doppeltePerson13boolean: event.target.checked });
  };
  handleCheckdoppeltePerson14boolean = (event) => {
    this.setState({ doppeltePerson14boolean: event.target.checked });
  };
  handleCheckdoppeltePerson15boolean = (event) => {
    this.setState({ doppeltePerson15boolean: event.target.checked });
  };
  handleCheckdoppeltePerson16boolean = (event) => {
    this.setState({ doppeltePerson16boolean: event.target.checked });
  };
  handleCheckdoppeltePerson17boolean = (event) => {
    this.setState({ doppeltePerson17boolean: event.target.checked });
  };
  handleCheckdoppeltePerson18boolean = (event) => {
    this.setState({ doppeltePerson18boolean: event.target.checked });
  };
  handleCheckdoppeltePerson19boolean = (event) => {
    this.setState({ doppeltePerson19boolean: event.target.checked });
  };
  handleCheckdoppeltePerson20boolean = (event) => {
    this.setState({ doppeltePerson20boolean: event.target.checked });
  };



  handleSubmitSchritt7 = (event) => {
    event.preventDefault();
    const isValid = this.validateSchritt7(this.state.verbotxx);

    if (isValid) {

      this.setState({ naechsteSeite: true, })

      console.log(this.state);

      let NichtAmGleichenTag = ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""];
      
      if (this.state.doppeltePerson1boolean === false) {
        NichtAmGleichenTag[0] = this.doppeltePersonengesamt()[0];
      }
      if (this.state.doppeltePerson2boolean  === false) {
        NichtAmGleichenTag[1] = this.doppeltePersonengesamt()[1];
      }
      if (this.state.doppeltePerson3boolean  === false) {
        NichtAmGleichenTag[2] = this.doppeltePersonengesamt()[2];
      }
      if (this.state.doppeltePerson4boolean  === false) {
        NichtAmGleichenTag[3] = this.doppeltePersonengesamt()[3];
      }
      if (this.state.doppeltePerson5boolean  === false) {
        NichtAmGleichenTag[4] = this.doppeltePersonengesamt()[4];
      }
      if (this.state.doppeltePerson6boolean  === false) {
        NichtAmGleichenTag[5] = this.doppeltePersonengesamt()[5];
      }
      if (this.state.doppeltePerson7boolean  === false) {
        NichtAmGleichenTag[6] = this.doppeltePersonengesamt()[6];
      }
      if (this.state.doppeltePerson8boolean  === false) {
        NichtAmGleichenTag[7] = this.doppeltePersonengesamt()[7];
      }
      if (this.state.doppeltePerson9boolean  === false) {
        NichtAmGleichenTag[8] = this.doppeltePersonengesamt()[8];
      }
      if (this.state.doppeltePerson10boolean  === false) {
        NichtAmGleichenTag[9] = this.doppeltePersonengesamt()[9];
      }
      if (this.state.doppeltePerson11boolean  === false) {
        NichtAmGleichenTag[10] = this.doppeltePersonengesamt()[10];
      }
      if (this.state.doppeltePerson12boolean  === false) {
        NichtAmGleichenTag[11] = this.doppeltePersonengesamt()[11];
      }
      if (this.state.doppeltePerson13boolean  === false) {
        NichtAmGleichenTag[12] = this.doppeltePersonengesamt()[12];
      }
      if (this.state.doppeltePerson14boolean  === false) {
        NichtAmGleichenTag[13] = this.doppeltePersonengesamt()[13];
      }
      if (this.state.doppeltePerson15boolean  === false) {
        NichtAmGleichenTag[14] = this.doppeltePersonengesamt()[14];
      }
      if (this.state.doppeltePerson16boolean  === false) {
        NichtAmGleichenTag[15] = this.doppeltePersonengesamt()[15];
      }
      if (this.state.doppeltePerson17boolean  === false) {
        NichtAmGleichenTag[16] = this.doppeltePersonengesamt()[16];
      }
      if (this.state.doppeltePerson18boolean  === false) {
        NichtAmGleichenTag[17] = this.doppeltePersonengesamt()[17];
      }
      if (this.state.doppeltePerson19boolean  === false) {
        NichtAmGleichenTag[18] = this.doppeltePersonengesamt()[18];
      }
      if (this.state.doppeltePerson20boolean  === false) {
        NichtAmGleichenTag[19] = this.doppeltePersonengesamt()[19];
      }
      



      const Sieben = {
        verbotxx: this.state.verbotxx,
        nichtAmGleichenTag: NichtAmGleichenTag,
        schritt: this.state.Schritt.toString(),
      };

      var local = window.location.pathname;
      var output = "";
      if (local.length === 12) {
      output = local.slice(-1);
      }
      if (local.length === 13) {
      output = local.slice(-2);
      }
      if (local.length === 14) {
      output = local.slice(-3);
      }
      if (local.length === 15) {
      output = local.slice(-4);
      }
      if (local.length === 16) {
      output = local.slice(-5);
      }
      if (local.length === 17) {
      output = local.slice(-6);
      }
      if (local.length === 18) {
      output = local.slice(-7);
      }
      if (local.length === 19) {
      output = local.slice(-8);
      }
      
      const postrequest = "".concat("/api/7/", output);
      
      axios.post(postrequest, Sieben, { timeout: 0 }).then(
        (response) => {
          console.log.apply(response);
          
          if(this.state.Schritt === 7 - 1) {
            if(this.state.topfauswahl === 1) {
              const abschluss = "".concat("/SchrittAbschluss/", output); //Abschließen
              document.location.href=abschluss;
            }
            else{
              const abschluss = "".concat("/schritt08/", output);
              document.location.href=abschluss;
            }
          }
          else {
            if(this.state.Schritt === 10 - 1) {
              if(this.state.topfauswahl === 2) {
                const abschluss = "".concat("/SchrittAbschluss/", output); //Abschließen
                document.location.href=abschluss;
              }
              else{
                const abschluss = "".concat("/schritt11/", output);
                document.location.href=abschluss;
              }
            }
            else {
              if(this.state.Schritt === 13 - 1) {
                if(this.state.topfauswahl === 3) {
                  const abschluss = "".concat("/SchrittAbschluss/", output); //Abschließen
                  document.location.href=abschluss;
                }
                else{
                  const abschluss = "".concat("/schritt14/", output);
                  document.location.href=abschluss;
                }
              }
              else {
                if(this.state.Schritt === 16 - 1) {
                  if(this.state.topfauswahl === 4) {
                    const abschluss = "".concat("/SchrittAbschluss/", output); //Abschließen
                    document.location.href=abschluss;
                  }
                  else{
                    const abschluss = "".concat("/schritt17/", output);
                    document.location.href=abschluss;
                  }
                }
                else {
                  if(this.state.Schritt === 19 - 1) {
                    if(this.state.topfauswahl === 5) {
                      const abschluss = "".concat("/SchrittAbschluss/", output); //Abschließen
                      document.location.href=abschluss;
                    }
                    else{
                      const abschluss = "".concat("/schritt20/", output);
                      document.location.href=abschluss;
                    }
                  }
                  else {
                    if(this.state.Schritt === 22 - 1) {
                      if(this.state.topfauswahl === 6) {
                        const abschluss = "".concat("/SchrittAbschluss/", output); //Abschließen
                        document.location.href=abschluss;
                      }
                      else{
                        const abschluss = "".concat("/schritt23/", output);
                        document.location.href=abschluss;
                      }
                    }
                    else {
                      if(this.state.Schritt === 25 - 1) {
                        if(this.state.topfauswahl === 7) {
                          const abschluss = "".concat("/SchrittAbschluss/", output); //Abschließen
                          document.location.href=abschluss;
                        }
                        else{
                          const abschluss = "".concat("/schritt26/", output);
                          document.location.href=abschluss;
                        }
                      }
                      else {
                        if(this.state.Schritt === 28 - 1) {
                          if(this.state.topfauswahl === 8) {
                            const abschluss = "".concat("/SchrittAbschluss/", output); //Abschließen
                            document.location.href=abschluss;
                          }
                          else{
                            const abschluss = "".concat("/schritt29/", output);
                            document.location.href=abschluss;
                          }
                        }
                        else {
                          if(this.state.Schritt === 31 - 1) {
                            if(this.state.topfauswahl === 9) {
                              const abschluss = "".concat("/SchrittAbschluss/", output); //Abschließen
                              document.location.href=abschluss;
                            }
                            else{
                              const abschluss = "".concat("/schritt32/", output);
                              document.location.href=abschluss;
                            }
                          }
                          else {
                            if(this.state.Schritt === 34 - 1) {
                              if(this.state.topfauswahl === 10) {
                                const abschluss = "".concat("/SchrittAbschluss/", output); //Abschließen
                                document.location.href=abschluss;
                              }
                              else{
                                const abschluss = "".concat("/schritt35/", output);
                                document.location.href=abschluss;
                              }
                            }
                            else {
                              if(this.state.Schritt === 37 - 1) {
                                if(this.state.topfauswahl === 11) {
                                  const abschluss = "".concat("/SchrittAbschluss/", output); //Abschließen
                                  document.location.href=abschluss;
                                }
                                else{
                                  const abschluss = "".concat("/schritt38/", output);
                                  document.location.href=abschluss;
                                }
                              }
                              else {
                                if(this.state.Schritt === 40 - 1) {
                                  if(this.state.topfauswahl === 12) {
                                    const abschluss = "".concat("/SchrittAbschluss/", output); //Abschließen
                                    document.location.href=abschluss;
                                  }
                                  else{
                                    const abschluss = "".concat("/schritt41/", output);
                                    document.location.href=abschluss;
                                  }
                                }
                                else {
                                  if(this.state.Schritt === 43 - 1) {
                                    if(this.state.topfauswahl === 13) {
                                      const abschluss = "".concat("/SchrittAbschluss/", output); //Abschließen
                                      document.location.href=abschluss;
                                    }
                                    else{
                                      const abschluss = "".concat("/schritt44/", output);
                                      document.location.href=abschluss;
                                    }
                                  }
                                  else {
                                    if(this.state.Schritt === 46 - 1) {
                                      if(this.state.topfauswahl === 14) {
                                        const abschluss = "".concat("/SchrittAbschluss/", output); //Abschließen
                                        document.location.href=abschluss;
                                      }
                                      else{
                                        const abschluss = "".concat("/schritt47/", output);
                                        document.location.href=abschluss;
                                      }
                                    }
                                    else {
                                      if(this.state.Schritt === 49 - 1) {
                                        if(this.state.topfauswahl === 15) {
                                          const abschluss = "".concat("/SchrittAbschluss/", output); //Abschließen
                                          document.location.href=abschluss;
                                        }
                                        else{
                                          const abschluss = "".concat("/schritt50/", output);
                                          document.location.href=abschluss;
                                        }
                                      }
                                      else {
                                        if(this.state.Schritt === 52 - 1) {
                                          if(this.state.topfauswahl === 16) {
                                            const abschluss = "".concat("/SchrittAbschluss/", output); //Abschließen
                                            document.location.href=abschluss;
                                          }
                                          else{
                                            const abschluss = "".concat("/schritt53/", output);
                                            document.location.href=abschluss;
                                          }
                                        }
                                        else {
                                          if(this.state.Schritt === 55 - 1) {
                                            if(this.state.topfauswahl === 17) {
                                              const abschluss = "".concat("/SchrittAbschluss/", output); //Abschließen
                                              document.location.href=abschluss;
                                            }
                                            else{
                                              const abschluss = "".concat("/schritt56/", output);
                                              document.location.href=abschluss;
                                            }
                                          }
                                          else {
                                            if(this.state.Schritt === 58 - 1) {
                                              if(this.state.topfauswahl === 18) {
                                                const abschluss = "".concat("/SchrittAbschluss/", output); //Abschließen
                                                document.location.href=abschluss;
                                              }
                                              else{
                                                const abschluss = "".concat("/schritt59/", output);
                                                document.location.href=abschluss;
                                              }
                                            }
                                            else {
                                              if(this.state.Schritt === 61 - 1) {
                                                if(this.state.topfauswahl === 19) {
                                                  const abschluss = "".concat("/SchrittAbschluss/", output); //Abschließen
                                                  document.location.href=abschluss;
                                                }
                                                else{
                                                  const abschluss = "".concat("/schritt62/", output);
                                                  document.location.href=abschluss;
                                                }
                                              }
                                              else {
                                                if(this.state.Schritt === 64 - 1) {
                                                  if(this.state.topfauswahl === 20) {
                                                    const abschluss = "".concat("/SchrittAbschluss/", output); //Abschließen
                                                    document.location.href=abschluss;
                                                  }
                                                }
                                              } 
                                            } 
                                          } 
                                        } 
                                      } 
                                    } 
                                  } 
                                } 
                              } 
                            } 
                          } 
                        } 
                      } 
                    } 
                  } 
                }
              } 
            }
          }
          
        },
        (error) => {
          console.log(error);
        }
      );
      
    }
  };

  handleSubmitSchritt7Back = (event) => {
    this.setState((state) => ({
      schritt: 6,
    }));
    console.log(this.state);
  };

  /*
  Handle Change
  */


  handleChangeVerbot1 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[0] = event.target.value;
    Neu[1] = "";
    this.setState({
      verbotxx: Neu,
    });
  };
  handleChangeVerbot2 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[1] = event.target.value;
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot3 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[2] = event.target.value;
    Neu[3] = "";
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot4 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[3] = event.target.value;
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot5 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[4] = event.target.value;
    Neu[5] = "";
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot6 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[5] = event.target.value;
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot7 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[6] = event.target.value;
    Neu[7] = "";
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot8 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[7] = event.target.value;
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot9 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[8] = event.target.value;
    Neu[9] = "";
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot10 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[9] = event.target.value;
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot11 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[10] = event.target.value;
    Neu[11] = "";
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot12 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[11] = event.target.value;
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot13 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[12] = event.target.value;
    Neu[13] = "";
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot14 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[13] = event.target.value;
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot15 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[14] = event.target.value;
    Neu[15] = "";
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot16 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[15] = event.target.value;
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot17 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[16] = event.target.value;
    Neu[17] = "";
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot18 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[17] = event.target.value;
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot19 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[18] = event.target.value;
    Neu[19] = "";
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot20 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[19] = event.target.value;
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot21 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[20] = event.target.value;
    Neu[21] = "";
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot22 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[21] = event.target.value;
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot23 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[22] = event.target.value;
    Neu[23] = "";
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot24 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[23] = event.target.value;
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot25 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[24] = event.target.value;
    Neu[25] = "";
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot26 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[25] = event.target.value;
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot27 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[26] = event.target.value;
    Neu[27] = "";
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot28 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[27] = event.target.value;
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot29 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[28] = event.target.value;
    Neu[29] = "";
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot30 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[29] = event.target.value;
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot31 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[30] = event.target.value;
    Neu[31] = "";
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot32 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[31] = event.target.value;
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot33 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[32] = event.target.value;
    Neu[33] = "";
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot34 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[33] = event.target.value;
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot35 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[34] = event.target.value;
    Neu[35] = "";
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot36 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[35] = event.target.value;
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot37 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[36] = event.target.value;
    Neu[37] = "";
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot38 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[37] = event.target.value;
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot39 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[38] = event.target.value;
    Neu[39] = "";
    this.setState({
        verbotxx: Neu,
    });
  };
  handleChangeVerbot40 = (event) => {
    let Neu = this.state.verbotxx;
    Neu[39] = event.target.value;
    this.setState({
        verbotxx: Neu,
    });
  };

  doppeltePersonengesamt = () => {
    let doppeltePersonengesamt = [];

    for(let i=0; i<40; i++) {
        if(parseInt(this.state.WieOft[i]) > 1) {
            doppeltePersonengesamt.push(this.state.namexxtopf1[i]);
        }
    }

    return doppeltePersonengesamt;
  };

  Topf() {
    if(this.state.Schritt === 6) {
      return(1);
    }
    else {
      if(this.state.Schritt === 9) {
        return(2);
      }
      else {
        if(this.state.Schritt === 12) {
          return(3);
        }
        else {
          if(this.state.Schritt === 15) {
            return(4);
          }
          else {
            if(this.state.Schritt === 18) {
              return(5);
            }
            else {
              if(this.state.Schritt === 21) {
                return(6);
              }
              else {
                if(this.state.Schritt === 24) {
                  return(7);
                }
                else {
                  if(this.state.Schritt === 27) {
                    return(8);
                  }
                  else {
                    if(this.state.Schritt === 30) {
                      return(9);
                    }
                    else {
                      if(this.state.Schritt === 33) {
                        return(10);
                      }
                      else {
                        if(this.state.Schritt === 36) {
                          return(11);
                        }
                        else {
                          if(this.state.Schritt === 39) {
                            return(12);
                          }
                          else {
                            if(this.state.Schritt === 42) {
                              return(13);
                            }
                            else {
                              if(this.state.Schritt === 45) {
                                return(14);
                              }
                              else {
                                if(this.state.Schritt === 48) {
                                  return(15);
                                }
                                else {
                                  if(this.state.Schritt === 51) {
                                    return(16);
                                  }
                                  else {
                                    if(this.state.Schritt === 54) {
                                      return(17);
                                    }
                                    else {
                                      if(this.state.Schritt === 57) {
                                        return(18);
                                      }
                                      else {
                                        if(this.state.Schritt === 60) {
                                          return(19);
                                        }
                                        else {
                                          if(this.state.Schritt === 63) {
                                            return(20);
                                          } 
                                        } 
                                      } 
                                    } 
                                  } 
                                } 
                              } 
                            } 
                          } 
                        } 
                      } 
                    } 
                  } 
                } 
              } 
            } 
          } 
        } 
      }
    }
  }

  KeineVerbotabfrage() {
    return(
      <div className="wuensche">
        <UnderHeaderHauptprogramm />
        <div className="container">
          <p>
            <strong id="roteFarbeGross"> 
              Topf {this.Topf()}: {" "}
            </strong>
            Gruppenverbote
          </p>
          <br/>
          <p>
            Für diesen Topf können keine Verbote angegeben werden!
          </p>
          <br />
          <div className="zurueckweiter">
            <div className="normal">
              <button onClick={this.handleSubmitSchritt7}>
                Weiter
              </button>
            </div>
          </div>
          <div className="zurueckweiter" id="roteFarbe">
            {this.state.errorSchritt7}
          </div>
        </div>
      </div>
    );
  }

  Verbotabfrage() {
    return (
      <div className="verbot">
        <UnderHeaderHauptprogramm />
        <div className="container">
          <p>
            <strong id="roteFarbeGross"> 
              Topf {this.Topf()}: {" "}
            </strong>
            Gruppenverbote
          </p>
          <br/>
          <p>
            Falls sich Ihre Schüler*innen über die Website angemeldet haben, können Sie jetzt
            die Excel-Tabelle zur Hand ziehen, die Sie vor der Einteilung in die Töpfe
            heruntergeladen haben. In der letzten Spalte stehen gegebenenfalls Wünsche!
          </p>
          <br/>
          <p>
            Tennisschüler*innen, die mehrmals in der Woche trainieren möchten,
            gelangen automatisch nicht in dieselbe Gruppe.
          </p>

          <div className="tabelleverbot">
            <table>
              <tbody>
                <tr>
                  <td>
                    <select
                      value={this.state.verbotxx[0]}
                      onChange={this.handleChangeVerbot1}
                    >
                      <option key=""></option>
                      {alleNamenSchritt7(this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map((e, key) => {
                        return <option key={e}>{e}</option>;
                      })}
                    </select>
                  </td>
                  <td>nicht mit</td>
                  <td>
                    <select
                      value={this.state.verbotxx[1]}
                      onChange={this.handleChangeVerbot2}
                    >
                      <option key=""></option>
                      {Verbotmoeglichkeiten(this.state.verbotxx[0], this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map(
                        (e, key) => {
                          return <option key={e}>{e}</option>;
                        }
                      )}
                    </select>
                  </td>
                  <td>
                    <div id="roteFarbe">
                      {this.state.errorSchritt7Verbot1}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <select
                      value={this.state.verbotxx[2]}
                      onChange={this.handleChangeVerbot3}
                    >
                      <option key=""></option>
                      {alleNamenSchritt7(this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map((e, key) => {
                        return <option key={e}>{e}</option>;
                      })}
                    </select>
                  </td>
                  <td>nicht mit</td>
                  <td>
                    <select
                      value={this.state.verbotxx[3]}
                      onChange={this.handleChangeVerbot4}
                    >
                      <option key=""></option>
                      {Verbotmoeglichkeiten(this.state.verbotxx[2], this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map(
                        (e, key) => {
                          return <option key={e}>{e}</option>;
                        }
                      )}
                    </select>
                  </td>
                  <td>
                    <div id="roteFarbe">
                      {this.state.errorSchritt7Verbot2}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <select
                      value={this.state.verbotxx[4]}
                      onChange={this.handleChangeVerbot5}
                    >
                      <option key=""></option>
                      {alleNamenSchritt7(this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map((e, key) => {
                        return <option key={e}>{e}</option>;
                      })}
                    </select>
                  </td>
                  <td>nicht mit</td>
                  <td>
                    <select
                      value={this.state.verbotxx[5]}
                      onChange={this.handleChangeVerbot6}
                    >
                      <option key=""></option>
                      {Verbotmoeglichkeiten(this.state.verbotxx[4], this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map(
                        (e, key) => {
                          return <option key={e}>{e}</option>;
                        }
                      )}
                    </select>
                  </td>
                  <td>
                    <div id="roteFarbe">
                      {this.state.errorSchritt7Verbot3}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <select
                      value={this.state.verbotxx[6]}
                      onChange={this.handleChangeVerbot7}
                    >
                      <option key=""></option>
                      {alleNamenSchritt7(this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map((e, key) => {
                        return <option key={e}>{e}</option>;
                      })}
                    </select>
                  </td>
                  <td>nicht mit</td>
                  <td>
                    <select
                      value={this.state.verbotxx[7]}
                      onChange={this.handleChangeVerbot8}
                    >
                      <option key=""></option>
                      {Verbotmoeglichkeiten(this.state.verbotxx[6], this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map(
                        (e, key) => {
                          return <option key={e}>{e}</option>;
                        }
                      )}
                    </select>
                  </td>
                  <td>
                    <div id="roteFarbe">
                      {this.state.errorSchritt7Verbot4}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <select
                      value={this.state.verbotxx[8]}
                      onChange={this.handleChangeVerbot9}
                    >
                      <option key=""></option>
                      {alleNamenSchritt7(this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map((e, key) => {
                        return <option key={e}>{e}</option>;
                      })}
                    </select>
                  </td>
                  <td>nicht mit</td>
                  <td>
                    <select
                      value={this.state.verbotxx[9]}
                      onChange={this.handleChangeVerbot10}
                    >
                      <option key=""></option>
                      {Verbotmoeglichkeiten(this.state.verbotxx[8], this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map(
                        (e, key) => {
                          return <option key={e}>{e}</option>;
                        }
                      )}
                    </select>
                  </td>
                  <td>
                    <div id="roteFarbe">
                      {this.state.errorSchritt7Verbot5}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <select
                      value={this.state.verbotxx[10]}
                      onChange={this.handleChangeVerbot11}
                    >
                      <option key=""></option>
                      {alleNamenSchritt7(this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map((e, key) => {
                        return <option key={e}>{e}</option>;
                      })}
                    </select>
                  </td>
                  <td>nicht mit</td>
                  <td>
                    <select
                      value={this.state.verbotxx[11]}
                      onChange={this.handleChangeVerbot12}
                    >
                      <option key=""></option>
                      {Verbotmoeglichkeiten(this.state.verbotxx[10], this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map(
                        (e, key) => {
                          return <option key={e}>{e}</option>;
                        }
                      )}
                    </select>
                  </td>
                  <td>
                    <div id="roteFarbe">
                      {this.state.errorSchritt7Verbot6}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <select
                      value={this.state.verbotxx[12]}
                      onChange={this.handleChangeVerbot13}
                    >
                      <option key=""></option>
                      {alleNamenSchritt7(this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map((e, key) => {
                        return <option key={e}>{e}</option>;
                      })}
                    </select>
                  </td>
                  <td>nicht mit</td>
                  <td>
                    <select
                      value={this.state.verbotxx[13]}
                      onChange={this.handleChangeVerbot14}
                    >
                      <option key=""></option>
                      {Verbotmoeglichkeiten(this.state.verbotxx[12], this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map(
                        (e, key) => {
                          return <option key={e}>{e}</option>;
                        }
                      )}
                    </select>
                  </td>
                  <td>
                    <div id="roteFarbe">
                      {this.state.errorSchritt7Verbot7}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <select
                      value={this.state.verbotxx[14]}
                      onChange={this.handleChangeVerbot15}
                    >
                      <option key=""></option>
                      {alleNamenSchritt7(this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map((e, key) => {
                        return <option key={e}>{e}</option>;
                      })}
                    </select>
                  </td>
                  <td>nicht mit</td>
                  <td>
                    <select
                      value={this.state.verbotxx[15]}
                      onChange={this.handleChangeVerbot16}
                    >
                      <option key=""></option>
                      {Verbotmoeglichkeiten(this.state.verbotxx[14], this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map(
                        (e, key) => {
                          return <option key={e}>{e}</option>;
                        }
                      )}
                    </select>
                  </td>
                  <td>
                    <div id="roteFarbe">
                      {this.state.errorSchritt7Verbot8}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <select
                      value={this.state.verbotxx[16]}
                      onChange={this.handleChangeVerbot17}
                    >
                      <option key=""></option>
                      {alleNamenSchritt7(this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map((e, key) => {
                        return <option key={e}>{e}</option>;
                      })}
                    </select>
                  </td>
                  <td>nicht mit</td>
                  <td>
                    <select
                      value={this.state.verbotxx[17]}
                      onChange={this.handleChangeVerbot18}
                    >
                      <option key=""></option>
                      {Verbotmoeglichkeiten(this.state.verbotxx[16], this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map(
                        (e, key) => {
                          return <option key={e}>{e}</option>;
                        }
                      )}
                    </select>
                  </td>
                  <td>
                    <div id="roteFarbe">
                      {this.state.errorSchritt7Verbot9}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <select
                      value={this.state.verbotxx[18]}
                      onChange={this.handleChangeVerbot19}
                    >
                      <option key=""></option>
                      {alleNamenSchritt7(this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map((e, key) => {
                        return <option key={e}>{e}</option>;
                      })}
                    </select>
                  </td>
                  <td>nicht mit</td>
                  <td>
                    <select
                      value={this.state.verbotxx[19]}
                      onChange={this.handleChangeVerbot20}
                    >
                      <option key=""></option>
                      {Verbotmoeglichkeiten(this.state.verbotxx[18], this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map(
                        (e, key) => {
                          return <option key={e}>{e}</option>;
                        }
                      )}
                    </select>
                  </td>
                  <td>
                    <div id="roteFarbe">
                      {this.state.errorSchritt7Verbot10}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <select
                      value={this.state.verbotxx[20]}
                      onChange={this.handleChangeVerbot21}
                    >
                      <option key=""></option>
                      {alleNamenSchritt7(this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map((e, key) => {
                        return <option key={e}>{e}</option>;
                      })}
                    </select>
                  </td>
                  <td>nicht mit</td>
                  <td>
                    <select
                      value={this.state.verbotxx[21]}
                      onChange={this.handleChangeVerbot22}
                    >
                      <option key=""></option>
                      {Verbotmoeglichkeiten(this.state.verbotxx[20], this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map(
                        (e, key) => {
                          return <option key={e}>{e}</option>;
                        }
                      )}
                    </select>
                  </td>
                  <td>
                    <div id="roteFarbe">
                      {this.state.errorSchritt7Verbot11}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <select
                      value={this.state.verbotxx[22]}
                      onChange={this.handleChangeVerbot23}
                    >
                      <option key=""></option>
                      {alleNamenSchritt7(this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map((e, key) => {
                        return <option key={e}>{e}</option>;
                      })}
                    </select>
                  </td>
                  <td>nicht mit</td>
                  <td>
                    <select
                      value={this.state.verbotxx[23]}
                      onChange={this.handleChangeVerbot24}
                    >
                      <option key=""></option>
                      {Verbotmoeglichkeiten(this.state.verbotxx[22], this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map(
                        (e, key) => {
                          return <option key={e}>{e}</option>;
                        }
                      )}
                    </select>
                  </td>
                  <td>
                    <div id="roteFarbe">
                      {this.state.errorSchritt7Verbot12}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <select
                      value={this.state.verbotxx[24]}
                      onChange={this.handleChangeVerbot25}
                    >
                      <option key=""></option>
                      {alleNamenSchritt7(this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map((e, key) => {
                        return <option key={e}>{e}</option>;
                      })}
                    </select>
                  </td>
                  <td>nicht mit</td>
                  <td>
                    <select
                      value={this.state.verbotxx[25]}
                      onChange={this.handleChangeVerbot26}
                    >
                      <option key=""></option>
                      {Verbotmoeglichkeiten(this.state.verbotxx[24], this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map(
                        (e, key) => {
                          return <option key={e}>{e}</option>;
                        }
                      )}
                    </select>
                  </td>
                  <td>
                    <div id="roteFarbe">
                      {this.state.errorSchritt7Verbot13}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <select
                      value={this.state.verbotxx[26]}
                      onChange={this.handleChangeVerbot27}
                    >
                      <option key=""></option>
                      {alleNamenSchritt7(this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map((e, key) => {
                        return <option key={e}>{e}</option>;
                      })}
                    </select>
                  </td>
                  <td>nicht mit</td>
                  <td>
                    <select
                      value={this.state.verbotxx[27]}
                      onChange={this.handleChangeVerbot28}
                    >
                      <option key=""></option>
                      {Verbotmoeglichkeiten(this.state.verbotxx[26], this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map(
                        (e, key) => {
                          return <option key={e}>{e}</option>;
                        }
                      )}
                    </select>
                  </td>
                  <td>
                    <div id="roteFarbe">
                      {this.state.errorSchritt7Verbot14}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <select
                      value={this.state.verbotxx[28]}
                      onChange={this.handleChangeVerbot29}
                    >
                      <option key=""></option>
                      {alleNamenSchritt7(this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map((e, key) => {
                        return <option key={e}>{e}</option>;
                      })}
                    </select>
                  </td>
                  <td>nicht mit</td>
                  <td>
                    <select
                      value={this.state.verbotxx[29]}
                      onChange={this.handleChangeVerbot30}
                    >
                      <option key=""></option>
                      {Verbotmoeglichkeiten(this.state.verbotxx[28], this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map(
                        (e, key) => {
                          return <option key={e}>{e}</option>;
                        }
                      )}
                    </select>
                  </td>
                  <td>
                    <div id="roteFarbe">
                      {this.state.errorSchritt7Verbot15}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <select
                      value={this.state.verbotxx[30]}
                      onChange={this.handleChangeVerbot31}
                    >
                      <option key=""></option>
                      {alleNamenSchritt7(this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map((e, key) => {
                        return <option key={e}>{e}</option>;
                      })}
                    </select>
                  </td>
                  <td>nicht mit</td>
                  <td>
                    <select
                      value={this.state.verbotxx[31]}
                      onChange={this.handleChangeVerbot32}
                    >
                      <option key=""></option>
                      {Verbotmoeglichkeiten(this.state.verbotxx[30], this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map(
                        (e, key) => {
                          return <option key={e}>{e}</option>;
                        }
                      )}
                    </select>
                  </td>
                  <td>
                    <div id="roteFarbe">
                      {this.state.errorSchritt7Verbot16}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <select
                      value={this.state.verbotxx[32]}
                      onChange={this.handleChangeVerbot33}
                    >
                      <option key=""></option>
                      {alleNamenSchritt7(this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map((e, key) => {
                        return <option key={e}>{e}</option>;
                      })}
                    </select>
                  </td>
                  <td>nicht mit</td>
                  <td>
                    <select
                      value={this.state.verbotxx[33]}
                      onChange={this.handleChangeVerbot34}
                    >
                      <option key=""></option>
                      {Verbotmoeglichkeiten(this.state.verbotxx[32], this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map(
                        (e, key) => {
                          return <option key={e}>{e}</option>;
                        }
                      )}
                    </select>
                  </td>
                  <td>
                    <div id="roteFarbe">
                      {this.state.errorSchritt7Verbot17}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <select
                      value={this.state.verbotxx[34]}
                      onChange={this.handleChangeVerbot35}
                    >
                      <option key=""></option>
                      {alleNamenSchritt7(this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map((e, key) => {
                        return <option key={e}>{e}</option>;
                      })}
                    </select>
                  </td>
                  <td>nicht mit</td>
                  <td>
                    <select
                      value={this.state.verbotxx[35]}
                      onChange={this.handleChangeVerbot36}
                    >
                      <option key=""></option>
                      {Verbotmoeglichkeiten(this.state.verbotxx[34], this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map(
                        (e, key) => {
                          return <option key={e}>{e}</option>;
                        }
                      )}
                    </select>
                  </td>
                  <td>
                    <div id="roteFarbe">
                      {this.state.errorSchritt7Verbot18}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <select
                      value={this.state.verbotxx[36]}
                      onChange={this.handleChangeVerbot37}
                    >
                      <option key=""></option>
                      {alleNamenSchritt7(this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map((e, key) => {
                        return <option key={e}>{e}</option>;
                      })}
                    </select>
                  </td>
                  <td>nicht mit</td>
                  <td>
                    <select
                      value={this.state.verbotxx[37]}
                      onChange={this.handleChangeVerbot38}
                    >
                      <option key=""></option>
                      {Verbotmoeglichkeiten(this.state.verbotxx[36], this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map(
                        (e, key) => {
                          return <option key={e}>{e}</option>;
                        }
                      )}
                    </select>
                  </td>
                  <td>
                    <div id="roteFarbe">
                      {this.state.errorSchritt7Verbot19}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <select
                      value={this.state.verbotxx[38]}
                      onChange={this.handleChangeVerbot39}
                    >
                      <option key=""></option>
                      {alleNamenSchritt7(this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map((e, key) => {
                        return <option key={e}>{e}</option>;
                      })}
                    </select>
                  </td>
                  <td>nicht mit</td>
                  <td>
                    <select
                      value={this.state.verbotxx[39]}
                      onChange={this.handleChangeVerbot40}
                    >
                      <option key=""></option>
                      {Verbotmoeglichkeiten(this.state.verbotxx[38], this.state.namexxtopf1, this.state.trainingseinheitentopf1, this.state.WieOft).map(
                        (e, key) => {
                          return <option key={e}>{e}</option>;
                        }
                      )}
                    </select>
                  </td>
                  <td>
                    <div id="roteFarbe">
                      {this.state.errorSchritt7Verbot20}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          {this.doppeltePersonengesamt().length === 0 ? (
            <div></div>
          ) : this.doppeltePersonengesamt().length === 1 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 2 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 3 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 4 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 5 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson5boolean}
                    onChange={this.handleCheckdoppeltePerson5boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[4]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 6 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson5boolean}
                    onChange={this.handleCheckdoppeltePerson5boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[4]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson6boolean}
                    onChange={this.handleCheckdoppeltePerson6boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[5]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 7 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson5boolean}
                    onChange={this.handleCheckdoppeltePerson5boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[4]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson6boolean}
                    onChange={this.handleCheckdoppeltePerson6boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[5]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson7boolean}
                    onChange={this.handleCheckdoppeltePerson7boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[6]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 8 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson5boolean}
                    onChange={this.handleCheckdoppeltePerson5boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[4]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson6boolean}
                    onChange={this.handleCheckdoppeltePerson6boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[5]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson7boolean}
                    onChange={this.handleCheckdoppeltePerson7boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[6]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson8boolean}
                    onChange={this.handleCheckdoppeltePerson8boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[7]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 9 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson5boolean}
                    onChange={this.handleCheckdoppeltePerson5boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[4]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson6boolean}
                    onChange={this.handleCheckdoppeltePerson6boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[5]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson7boolean}
                    onChange={this.handleCheckdoppeltePerson7boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[6]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson8boolean}
                    onChange={this.handleCheckdoppeltePerson8boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[7]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson9boolean}
                    onChange={this.handleCheckdoppeltePerson9boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[8]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 10 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson5boolean}
                    onChange={this.handleCheckdoppeltePerson5boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[4]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson6boolean}
                    onChange={this.handleCheckdoppeltePerson6boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[5]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson7boolean}
                    onChange={this.handleCheckdoppeltePerson7boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[6]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson8boolean}
                    onChange={this.handleCheckdoppeltePerson8boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[7]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson9boolean}
                    onChange={this.handleCheckdoppeltePerson9boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[8]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson10boolean}
                    onChange={this.handleCheckdoppeltePerson10boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[9]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 11 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson5boolean}
                    onChange={this.handleCheckdoppeltePerson5boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[4]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson6boolean}
                    onChange={this.handleCheckdoppeltePerson6boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[5]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson7boolean}
                    onChange={this.handleCheckdoppeltePerson7boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[6]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson8boolean}
                    onChange={this.handleCheckdoppeltePerson8boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[7]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson9boolean}
                    onChange={this.handleCheckdoppeltePerson9boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[8]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson10boolean}
                    onChange={this.handleCheckdoppeltePerson10boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[9]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson11boolean}
                    onChange={this.handleCheckdoppeltePerson11boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[10]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 12 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson5boolean}
                    onChange={this.handleCheckdoppeltePerson5boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[4]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson6boolean}
                    onChange={this.handleCheckdoppeltePerson6boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[5]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson7boolean}
                    onChange={this.handleCheckdoppeltePerson7boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[6]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson8boolean}
                    onChange={this.handleCheckdoppeltePerson8boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[7]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson9boolean}
                    onChange={this.handleCheckdoppeltePerson9boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[8]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson10boolean}
                    onChange={this.handleCheckdoppeltePerson10boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[9]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson11boolean}
                    onChange={this.handleCheckdoppeltePerson11boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[10]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson12boolean}
                    onChange={this.handleCheckdoppeltePerson12boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[11]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 13 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson5boolean}
                    onChange={this.handleCheckdoppeltePerson5boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[4]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson6boolean}
                    onChange={this.handleCheckdoppeltePerson6boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[5]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson7boolean}
                    onChange={this.handleCheckdoppeltePerson7boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[6]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson8boolean}
                    onChange={this.handleCheckdoppeltePerson8boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[7]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson9boolean}
                    onChange={this.handleCheckdoppeltePerson9boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[8]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson10boolean}
                    onChange={this.handleCheckdoppeltePerson10boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[9]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson11boolean}
                    onChange={this.handleCheckdoppeltePerson11boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[10]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson12boolean}
                    onChange={this.handleCheckdoppeltePerson12boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[11]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson13boolean}
                    onChange={this.handleCheckdoppeltePerson13boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[12]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 14 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson5boolean}
                    onChange={this.handleCheckdoppeltePerson5boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[4]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson6boolean}
                    onChange={this.handleCheckdoppeltePerson6boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[5]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson7boolean}
                    onChange={this.handleCheckdoppeltePerson7boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[6]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson8boolean}
                    onChange={this.handleCheckdoppeltePerson8boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[7]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson9boolean}
                    onChange={this.handleCheckdoppeltePerson9boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[8]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson10boolean}
                    onChange={this.handleCheckdoppeltePerson10boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[9]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson11boolean}
                    onChange={this.handleCheckdoppeltePerson11boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[10]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson12boolean}
                    onChange={this.handleCheckdoppeltePerson12boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[11]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson13boolean}
                    onChange={this.handleCheckdoppeltePerson13boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[12]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson14boolean}
                    onChange={this.handleCheckdoppeltePerson14boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[13]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 15 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson5boolean}
                    onChange={this.handleCheckdoppeltePerson5boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[4]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson6boolean}
                    onChange={this.handleCheckdoppeltePerson6boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[5]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson7boolean}
                    onChange={this.handleCheckdoppeltePerson7boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[6]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson8boolean}
                    onChange={this.handleCheckdoppeltePerson8boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[7]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson9boolean}
                    onChange={this.handleCheckdoppeltePerson9boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[8]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson10boolean}
                    onChange={this.handleCheckdoppeltePerson10boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[9]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson11boolean}
                    onChange={this.handleCheckdoppeltePerson11boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[10]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson12boolean}
                    onChange={this.handleCheckdoppeltePerson12boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[11]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson13boolean}
                    onChange={this.handleCheckdoppeltePerson13boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[12]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson14boolean}
                    onChange={this.handleCheckdoppeltePerson14boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[13]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson15boolean}
                    onChange={this.handleCheckdoppeltePerson15boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[14]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 16 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson5boolean}
                    onChange={this.handleCheckdoppeltePerson5boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[4]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson6boolean}
                    onChange={this.handleCheckdoppeltePerson6boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[5]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson7boolean}
                    onChange={this.handleCheckdoppeltePerson7boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[6]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson8boolean}
                    onChange={this.handleCheckdoppeltePerson8boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[7]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson9boolean}
                    onChange={this.handleCheckdoppeltePerson9boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[8]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson10boolean}
                    onChange={this.handleCheckdoppeltePerson10boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[9]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson11boolean}
                    onChange={this.handleCheckdoppeltePerson11boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[10]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson12boolean}
                    onChange={this.handleCheckdoppeltePerson12boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[11]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson13boolean}
                    onChange={this.handleCheckdoppeltePerson13boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[12]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson14boolean}
                    onChange={this.handleCheckdoppeltePerson14boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[13]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson15boolean}
                    onChange={this.handleCheckdoppeltePerson15boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[14]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson16boolean}
                    onChange={this.handleCheckdoppeltePerson16boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[15]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 17 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson5boolean}
                    onChange={this.handleCheckdoppeltePerson5boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[4]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson6boolean}
                    onChange={this.handleCheckdoppeltePerson6boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[5]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson7boolean}
                    onChange={this.handleCheckdoppeltePerson7boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[6]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson8boolean}
                    onChange={this.handleCheckdoppeltePerson8boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[7]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson9boolean}
                    onChange={this.handleCheckdoppeltePerson9boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[8]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson10boolean}
                    onChange={this.handleCheckdoppeltePerson10boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[9]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson11boolean}
                    onChange={this.handleCheckdoppeltePerson11boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[10]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson12boolean}
                    onChange={this.handleCheckdoppeltePerson12boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[11]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson13boolean}
                    onChange={this.handleCheckdoppeltePerson13boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[12]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson14boolean}
                    onChange={this.handleCheckdoppeltePerson14boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[13]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson15boolean}
                    onChange={this.handleCheckdoppeltePerson15boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[14]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson16boolean}
                    onChange={this.handleCheckdoppeltePerson16boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[15]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson17boolean}
                    onChange={this.handleCheckdoppeltePerson17boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[16]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 18 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson5boolean}
                    onChange={this.handleCheckdoppeltePerson5boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[4]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson6boolean}
                    onChange={this.handleCheckdoppeltePerson6boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[5]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson7boolean}
                    onChange={this.handleCheckdoppeltePerson7boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[6]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson8boolean}
                    onChange={this.handleCheckdoppeltePerson8boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[7]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson9boolean}
                    onChange={this.handleCheckdoppeltePerson9boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[8]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson10boolean}
                    onChange={this.handleCheckdoppeltePerson10boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[9]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson11boolean}
                    onChange={this.handleCheckdoppeltePerson11boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[10]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson12boolean}
                    onChange={this.handleCheckdoppeltePerson12boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[11]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson13boolean}
                    onChange={this.handleCheckdoppeltePerson13boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[12]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson14boolean}
                    onChange={this.handleCheckdoppeltePerson14boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[13]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson15boolean}
                    onChange={this.handleCheckdoppeltePerson15boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[14]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson16boolean}
                    onChange={this.handleCheckdoppeltePerson16boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[15]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson17boolean}
                    onChange={this.handleCheckdoppeltePerson17boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[16]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson18boolean}
                    onChange={this.handleCheckdoppeltePerson18boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[17]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 19 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson5boolean}
                    onChange={this.handleCheckdoppeltePerson5boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[4]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson6boolean}
                    onChange={this.handleCheckdoppeltePerson6boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[5]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson7boolean}
                    onChange={this.handleCheckdoppeltePerson7boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[6]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson8boolean}
                    onChange={this.handleCheckdoppeltePerson8boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[7]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson9boolean}
                    onChange={this.handleCheckdoppeltePerson9boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[8]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson10boolean}
                    onChange={this.handleCheckdoppeltePerson10boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[9]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson11boolean}
                    onChange={this.handleCheckdoppeltePerson11boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[10]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson12boolean}
                    onChange={this.handleCheckdoppeltePerson12boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[11]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson13boolean}
                    onChange={this.handleCheckdoppeltePerson13boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[12]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson14boolean}
                    onChange={this.handleCheckdoppeltePerson14boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[13]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson15boolean}
                    onChange={this.handleCheckdoppeltePerson15boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[14]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson16boolean}
                    onChange={this.handleCheckdoppeltePerson16boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[15]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson17boolean}
                    onChange={this.handleCheckdoppeltePerson17boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[16]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson18boolean}
                    onChange={this.handleCheckdoppeltePerson18boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[17]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson19boolean}
                    onChange={this.handleCheckdoppeltePerson19boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[18]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 20 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson5boolean}
                    onChange={this.handleCheckdoppeltePerson5boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[4]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson6boolean}
                    onChange={this.handleCheckdoppeltePerson6boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[5]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson7boolean}
                    onChange={this.handleCheckdoppeltePerson7boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[6]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson8boolean}
                    onChange={this.handleCheckdoppeltePerson8boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[7]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson9boolean}
                    onChange={this.handleCheckdoppeltePerson9boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[8]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson10boolean}
                    onChange={this.handleCheckdoppeltePerson10boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[9]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson11boolean}
                    onChange={this.handleCheckdoppeltePerson11boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[10]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson12boolean}
                    onChange={this.handleCheckdoppeltePerson12boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[11]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson13boolean}
                    onChange={this.handleCheckdoppeltePerson13boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[12]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson14boolean}
                    onChange={this.handleCheckdoppeltePerson14boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[13]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson15boolean}
                    onChange={this.handleCheckdoppeltePerson15boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[14]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson16boolean}
                    onChange={this.handleCheckdoppeltePerson16boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[15]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson17boolean}
                    onChange={this.handleCheckdoppeltePerson17boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[16]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson18boolean}
                    onChange={this.handleCheckdoppeltePerson18boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[17]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson19boolean}
                    onChange={this.handleCheckdoppeltePerson19boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[18]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson20boolean}
                    onChange={this.handleCheckdoppeltePerson20boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[19]}
              </p>
            </div>
          ) : (
            ""
          )}
          <br />
          <div className="zurueckweiter">
            <div className="normal">
              <button onClick={this.handleSubmitSchritt7}>
                Weiter
              </button>
            </div>
          </div>
          <div className="zurueckweiter" id="roteFarbe">
            {this.state.errorSchritt7}
          </div>
        </div>
      </div>
    );
  }

  NurNichtAmGleichenTag() {
    return (
      <div className="verbot">
        <UnderHeaderHauptprogramm />
        <div className="container">

          <p>
            <strong id="roteFarbeGross">Topf {this.Topf()}</strong>
          </p>

          {this.doppeltePersonengesamt().length === 0 ? (
            <div></div>
          ) : this.doppeltePersonengesamt().length === 1 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>

              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 2 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>

              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 3 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 4 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 5 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson5boolean}
                    onChange={this.handleCheckdoppeltePerson5boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[4]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 6 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson5boolean}
                    onChange={this.handleCheckdoppeltePerson5boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[4]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson6boolean}
                    onChange={this.handleCheckdoppeltePerson6boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[5]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 7 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson5boolean}
                    onChange={this.handleCheckdoppeltePerson5boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[4]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson6boolean}
                    onChange={this.handleCheckdoppeltePerson6boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[5]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson7boolean}
                    onChange={this.handleCheckdoppeltePerson7boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[6]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 8 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson5boolean}
                    onChange={this.handleCheckdoppeltePerson5boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[4]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson6boolean}
                    onChange={this.handleCheckdoppeltePerson6boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[5]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson7boolean}
                    onChange={this.handleCheckdoppeltePerson7boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[6]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson8boolean}
                    onChange={this.handleCheckdoppeltePerson8boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[7]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 9 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson5boolean}
                    onChange={this.handleCheckdoppeltePerson5boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[4]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson6boolean}
                    onChange={this.handleCheckdoppeltePerson6boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[5]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson7boolean}
                    onChange={this.handleCheckdoppeltePerson7boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[6]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson8boolean}
                    onChange={this.handleCheckdoppeltePerson8boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[7]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson9boolean}
                    onChange={this.handleCheckdoppeltePerson9boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[8]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 10 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson5boolean}
                    onChange={this.handleCheckdoppeltePerson5boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[4]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson6boolean}
                    onChange={this.handleCheckdoppeltePerson6boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[5]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson7boolean}
                    onChange={this.handleCheckdoppeltePerson7boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[6]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson8boolean}
                    onChange={this.handleCheckdoppeltePerson8boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[7]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson9boolean}
                    onChange={this.handleCheckdoppeltePerson9boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[8]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson10boolean}
                    onChange={this.handleCheckdoppeltePerson10boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[9]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 11 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson5boolean}
                    onChange={this.handleCheckdoppeltePerson5boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[4]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson6boolean}
                    onChange={this.handleCheckdoppeltePerson6boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[5]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson7boolean}
                    onChange={this.handleCheckdoppeltePerson7boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[6]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson8boolean}
                    onChange={this.handleCheckdoppeltePerson8boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[7]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson9boolean}
                    onChange={this.handleCheckdoppeltePerson9boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[8]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson10boolean}
                    onChange={this.handleCheckdoppeltePerson10boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[9]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson11boolean}
                    onChange={this.handleCheckdoppeltePerson11boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[10]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 12 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson5boolean}
                    onChange={this.handleCheckdoppeltePerson5boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[4]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson6boolean}
                    onChange={this.handleCheckdoppeltePerson6boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[5]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson7boolean}
                    onChange={this.handleCheckdoppeltePerson7boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[6]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson8boolean}
                    onChange={this.handleCheckdoppeltePerson8boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[7]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson9boolean}
                    onChange={this.handleCheckdoppeltePerson9boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[8]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson10boolean}
                    onChange={this.handleCheckdoppeltePerson10boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[9]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson11boolean}
                    onChange={this.handleCheckdoppeltePerson11boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[10]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson12boolean}
                    onChange={this.handleCheckdoppeltePerson12boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[11]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 13 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson5boolean}
                    onChange={this.handleCheckdoppeltePerson5boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[4]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson6boolean}
                    onChange={this.handleCheckdoppeltePerson6boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[5]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson7boolean}
                    onChange={this.handleCheckdoppeltePerson7boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[6]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson8boolean}
                    onChange={this.handleCheckdoppeltePerson8boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[7]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson9boolean}
                    onChange={this.handleCheckdoppeltePerson9boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[8]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson10boolean}
                    onChange={this.handleCheckdoppeltePerson10boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[9]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson11boolean}
                    onChange={this.handleCheckdoppeltePerson11boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[10]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson12boolean}
                    onChange={this.handleCheckdoppeltePerson12boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[11]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson13boolean}
                    onChange={this.handleCheckdoppeltePerson13boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[12]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 14 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson5boolean}
                    onChange={this.handleCheckdoppeltePerson5boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[4]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson6boolean}
                    onChange={this.handleCheckdoppeltePerson6boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[5]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson7boolean}
                    onChange={this.handleCheckdoppeltePerson7boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[6]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson8boolean}
                    onChange={this.handleCheckdoppeltePerson8boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[7]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson9boolean}
                    onChange={this.handleCheckdoppeltePerson9boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[8]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson10boolean}
                    onChange={this.handleCheckdoppeltePerson10boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[9]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson11boolean}
                    onChange={this.handleCheckdoppeltePerson11boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[10]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson12boolean}
                    onChange={this.handleCheckdoppeltePerson12boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[11]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson13boolean}
                    onChange={this.handleCheckdoppeltePerson13boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[12]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson14boolean}
                    onChange={this.handleCheckdoppeltePerson14boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[13]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 15 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson5boolean}
                    onChange={this.handleCheckdoppeltePerson5boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[4]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson6boolean}
                    onChange={this.handleCheckdoppeltePerson6boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[5]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson7boolean}
                    onChange={this.handleCheckdoppeltePerson7boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[6]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson8boolean}
                    onChange={this.handleCheckdoppeltePerson8boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[7]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson9boolean}
                    onChange={this.handleCheckdoppeltePerson9boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[8]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson10boolean}
                    onChange={this.handleCheckdoppeltePerson10boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[9]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson11boolean}
                    onChange={this.handleCheckdoppeltePerson11boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[10]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson12boolean}
                    onChange={this.handleCheckdoppeltePerson12boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[11]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson13boolean}
                    onChange={this.handleCheckdoppeltePerson13boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[12]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson14boolean}
                    onChange={this.handleCheckdoppeltePerson14boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[13]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson15boolean}
                    onChange={this.handleCheckdoppeltePerson15boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[14]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 16 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson5boolean}
                    onChange={this.handleCheckdoppeltePerson5boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[4]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson6boolean}
                    onChange={this.handleCheckdoppeltePerson6boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[5]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson7boolean}
                    onChange={this.handleCheckdoppeltePerson7boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[6]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson8boolean}
                    onChange={this.handleCheckdoppeltePerson8boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[7]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson9boolean}
                    onChange={this.handleCheckdoppeltePerson9boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[8]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson10boolean}
                    onChange={this.handleCheckdoppeltePerson10boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[9]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson11boolean}
                    onChange={this.handleCheckdoppeltePerson11boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[10]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson12boolean}
                    onChange={this.handleCheckdoppeltePerson12boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[11]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson13boolean}
                    onChange={this.handleCheckdoppeltePerson13boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[12]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson14boolean}
                    onChange={this.handleCheckdoppeltePerson14boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[13]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson15boolean}
                    onChange={this.handleCheckdoppeltePerson15boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[14]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson16boolean}
                    onChange={this.handleCheckdoppeltePerson16boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[15]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 17 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson5boolean}
                    onChange={this.handleCheckdoppeltePerson5boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[4]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson6boolean}
                    onChange={this.handleCheckdoppeltePerson6boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[5]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson7boolean}
                    onChange={this.handleCheckdoppeltePerson7boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[6]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson8boolean}
                    onChange={this.handleCheckdoppeltePerson8boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[7]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson9boolean}
                    onChange={this.handleCheckdoppeltePerson9boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[8]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson10boolean}
                    onChange={this.handleCheckdoppeltePerson10boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[9]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson11boolean}
                    onChange={this.handleCheckdoppeltePerson11boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[10]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson12boolean}
                    onChange={this.handleCheckdoppeltePerson12boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[11]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson13boolean}
                    onChange={this.handleCheckdoppeltePerson13boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[12]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson14boolean}
                    onChange={this.handleCheckdoppeltePerson14boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[13]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson15boolean}
                    onChange={this.handleCheckdoppeltePerson15boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[14]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson16boolean}
                    onChange={this.handleCheckdoppeltePerson16boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[15]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson17boolean}
                    onChange={this.handleCheckdoppeltePerson17boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[16]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 18 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson5boolean}
                    onChange={this.handleCheckdoppeltePerson5boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[4]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson6boolean}
                    onChange={this.handleCheckdoppeltePerson6boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[5]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson7boolean}
                    onChange={this.handleCheckdoppeltePerson7boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[6]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson8boolean}
                    onChange={this.handleCheckdoppeltePerson8boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[7]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson9boolean}
                    onChange={this.handleCheckdoppeltePerson9boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[8]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson10boolean}
                    onChange={this.handleCheckdoppeltePerson10boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[9]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson11boolean}
                    onChange={this.handleCheckdoppeltePerson11boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[10]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson12boolean}
                    onChange={this.handleCheckdoppeltePerson12boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[11]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson13boolean}
                    onChange={this.handleCheckdoppeltePerson13boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[12]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson14boolean}
                    onChange={this.handleCheckdoppeltePerson14boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[13]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson15boolean}
                    onChange={this.handleCheckdoppeltePerson15boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[14]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson16boolean}
                    onChange={this.handleCheckdoppeltePerson16boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[15]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson17boolean}
                    onChange={this.handleCheckdoppeltePerson17boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[16]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson18boolean}
                    onChange={this.handleCheckdoppeltePerson18boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[17]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 19 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson5boolean}
                    onChange={this.handleCheckdoppeltePerson5boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[4]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson6boolean}
                    onChange={this.handleCheckdoppeltePerson6boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[5]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson7boolean}
                    onChange={this.handleCheckdoppeltePerson7boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[6]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson8boolean}
                    onChange={this.handleCheckdoppeltePerson8boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[7]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson9boolean}
                    onChange={this.handleCheckdoppeltePerson9boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[8]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson10boolean}
                    onChange={this.handleCheckdoppeltePerson10boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[9]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson11boolean}
                    onChange={this.handleCheckdoppeltePerson11boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[10]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson12boolean}
                    onChange={this.handleCheckdoppeltePerson12boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[11]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson13boolean}
                    onChange={this.handleCheckdoppeltePerson13boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[12]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson14boolean}
                    onChange={this.handleCheckdoppeltePerson14boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[13]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson15boolean}
                    onChange={this.handleCheckdoppeltePerson15boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[14]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson16boolean}
                    onChange={this.handleCheckdoppeltePerson16boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[15]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson17boolean}
                    onChange={this.handleCheckdoppeltePerson17boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[16]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson18boolean}
                    onChange={this.handleCheckdoppeltePerson18boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[17]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson19boolean}
                    onChange={this.handleCheckdoppeltePerson19boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[18]}
              </p>
            </div>
          ) : this.doppeltePersonengesamt().length === 20 ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div id="blue">&#10004;</div>
                    </td>
                    <td>
                      = Es wäre in Ordnung, wenn sie/er mehrmals an einem Tag Training hat.
                    </td>
                  </tr>
                </tbody>
              </table>

              <br/>
              
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson1boolean}
                    onChange={this.handleCheckdoppeltePerson1boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[0]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson2boolean}
                    onChange={this.handleCheckdoppeltePerson2boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[1]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson3boolean}
                    onChange={this.handleCheckdoppeltePerson3boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[2]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson4boolean}
                    onChange={this.handleCheckdoppeltePerson4boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[3]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson5boolean}
                    onChange={this.handleCheckdoppeltePerson5boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[4]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson6boolean}
                    onChange={this.handleCheckdoppeltePerson6boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[5]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson7boolean}
                    onChange={this.handleCheckdoppeltePerson7boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[6]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson8boolean}
                    onChange={this.handleCheckdoppeltePerson8boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[7]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson9boolean}
                    onChange={this.handleCheckdoppeltePerson9boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[8]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson10boolean}
                    onChange={this.handleCheckdoppeltePerson10boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[9]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson11boolean}
                    onChange={this.handleCheckdoppeltePerson11boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[10]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson12boolean}
                    onChange={this.handleCheckdoppeltePerson12boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[11]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson13boolean}
                    onChange={this.handleCheckdoppeltePerson13boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[12]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson14boolean}
                    onChange={this.handleCheckdoppeltePerson14boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[13]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson15boolean}
                    onChange={this.handleCheckdoppeltePerson15boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[14]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson16boolean}
                    onChange={this.handleCheckdoppeltePerson16boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[15]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson17boolean}
                    onChange={this.handleCheckdoppeltePerson17boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[16]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson18boolean}
                    onChange={this.handleCheckdoppeltePerson18boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[17]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson19boolean}
                    onChange={this.handleCheckdoppeltePerson19boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[18]}
              </p>
              <p>
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.doppeltePerson20boolean}
                    onChange={this.handleCheckdoppeltePerson20boolean}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                {this.doppeltePersonengesamt()[19]}
              </p>
            </div>
          ) : (
            ""
          )}
          <br />
          <div className="zurueckweiter">
            <div className="normal">
              <button onClick={this.handleSubmitSchritt7}>
                Weiter
              </button>
            </div>
          </div>
          <div className="zurueckweiter" id="roteFarbe">
            {this.state.errorSchritt7}
          </div>
        </div>
      </div>
    );
  }

  Schritt7render = (event) => {
    if(this.state.trainingseinheitentopf1 === 1) {
      if(this.state.bevorzugteTopfgroessetopf1 === 1) {
        if(this.doppeltePersonengesamt().length === 0) {
          return (
            this.KeineVerbotabfrage()
          );
        }
        else {
          return (
            this.NurNichtAmGleichenTag()
          );
        }
      }
    }
    else{
      if(this.state.trainingseinheitentopf1 === 2) {
        if(this.state.bevorzugteTopfgroessetopf1 === 1) {
          if(this.doppeltePersonengesamt().length === 0) {
          return (
            this.KeineVerbotabfrage()
          );
        }
        else {
          return (
            this.NurNichtAmGleichenTag()
          );
        }
        }
      }
      else{
        if(this.state.trainingseinheitentopf1 === 3) {
          if(this.state.bevorzugteTopfgroessetopf1 === 3 || this.state.bevorzugteTopfgroessetopf1 === 1) {
            if(this.doppeltePersonengesamt().length === 0) {
              return (
                this.KeineVerbotabfrage()
              );
            }
            else {
              return (
                this.NurNichtAmGleichenTag()
              );
            }
          }
        }
        else{
          if(this.state.trainingseinheitentopf1 === 4) {
            if(this.state.bevorzugteTopfgroessetopf1 === 4 || this.state.bevorzugteTopfgroessetopf1 === 1) {
              if(this.doppeltePersonengesamt().length === 0) {
                return (
                  this.KeineVerbotabfrage()
                );
              }
              else {
                return (
                  this.NurNichtAmGleichenTag()
                );
              }
            }
          }
          else{
            if(this.state.trainingseinheitentopf1 === 5) {
              if(this.state.bevorzugteTopfgroessetopf1 === 5 || this.state.bevorzugteTopfgroessetopf1 === 1) {
                if(this.doppeltePersonengesamt().length === 0) {
                  return (
                    this.KeineVerbotabfrage()
                  );
                }
                else {
                  return (
                    this.NurNichtAmGleichenTag()
                  );
                }
              }
            }
            else{
              if(this.state.trainingseinheitentopf1 === 6) {
                if(this.state.bevorzugteTopfgroessetopf1 === 6 || this.state.bevorzugteTopfgroessetopf1 === 1) {
                  if(this.doppeltePersonengesamt().length === 0) {
                    return (
                      this.KeineVerbotabfrage()
                    );
                  }
                  else {
                    return (
                      this.NurNichtAmGleichenTag()
                    );
                  }
                }
                else {
                  if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                    return (
                      this.Verbotabfrage()
                    );
                  }
                }
              }
              else {
                if(this.state.trainingseinheitentopf1 === 7) {
                  if(this.state.bevorzugteTopfgroessetopf1 === 7 || this.state.bevorzugteTopfgroessetopf1 === 1) {
                    if(this.doppeltePersonengesamt().length === 0) {
                      return (
                        this.KeineVerbotabfrage()
                      );
                    }
                    else {
                      return (
                        this.NurNichtAmGleichenTag()
                      );
                    }
                  }
                  else {
                    if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                      return (
                        this.Verbotabfrage()
                      );
                    }
                    else {
                      if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                        if(this.state.alternativeTopfgroessetopf1 === 3) {
                          return (
                          this.Verbotabfrage()
                        );
                        }
                      }
                    }
                  }
                }
                else {
                  if(this.state.trainingseinheitentopf1 === 8) {
                    if(this.state.bevorzugteTopfgroessetopf1 === 8 || this.state.bevorzugteTopfgroessetopf1 === 1) {
                      if(this.doppeltePersonengesamt().length === 0) {
                        return (
                          this.KeineVerbotabfrage()
                        );
                      }
                      else {
                        return (
                          this.NurNichtAmGleichenTag()
                        );
                      }
                    }
                    else {
                      if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                        return (
                          this.Verbotabfrage()
                        );
                      }
                    }
                  }
                  else {
                    if(this.state.trainingseinheitentopf1 === 9) {
                      if(this.state.bevorzugteTopfgroessetopf1 === 1) {
                        if(this.doppeltePersonengesamt().length === 0) {
                          return (
                            this.KeineVerbotabfrage()
                          );
                        }
                        else {
                          return (
                            this.NurNichtAmGleichenTag()
                          );
                        }
                      }
                      else {
                        if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                          return (
                            this.Verbotabfrage()
                          );
                        }
                        else {
                          if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                            if(this.state.alternativeTopfgroessetopf1 === 5) {
                              return (
                                this.Verbotabfrage()
                              );
                            }
                          }
                          else {
                            if(this.state.bevorzugteTopfgroessetopf1 === 5) {
                              if(this.state.alternativeTopfgroessetopf1 === 4) {
                                return (
                                  this.Verbotabfrage()
                                );
                              }
                            }
                          }
                        }
                      }
                    }
                    else {
                      if(this.state.trainingseinheitentopf1 === 10) {
                        if(this.state.bevorzugteTopfgroessetopf1 === 1) {
                          if(this.doppeltePersonengesamt().length === 0) {
                            return (
                              this.KeineVerbotabfrage()
                            );
                          }
                          else {
                            return (
                              this.NurNichtAmGleichenTag()
                            );
                          }
                        }
                        else {
                          if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                            return (
                              this.Verbotabfrage()
                            );
                          }
                          else {
                            if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                              if(this.state.alternativeTopfgroessetopf1 === 3) {
                                return (
                                  this.Verbotabfrage()
                                );
                              }
                            }
                            else {
                              if(this.state.bevorzugteTopfgroessetopf1 === 5) {
                                return (
                                  this.Verbotabfrage()
                                );
                              }
                            }
                          }
                        }
                      }
                      else {
                        if(this.state.trainingseinheitentopf1 === 11) {
                          if(this.state.bevorzugteTopfgroessetopf1 === 1) {
                            if(this.doppeltePersonengesamt().length === 0) {
                              return (
                                this.KeineVerbotabfrage()
                              );
                            }
                            else {
                              return (
                                this.NurNichtAmGleichenTag()
                              );
                            }
                          }
                          else {
                            if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                              return (
                                this.Verbotabfrage()
                              );
                            }
                            else {
                              if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                                if(this.state.alternativeTopfgroessetopf1 === 3) {
                                  return (
                                    this.Verbotabfrage()
                                  );
                                }
                              }
                              else {
                                if(this.state.bevorzugteTopfgroessetopf1 === 5) {
                                  if(this.state.alternativeTopfgroessetopf1 === 6) {
                                    return (
                                      this.Verbotabfrage()
                                    );
                                  }
                                }
                                else {
                                  if(this.state.bevorzugteTopfgroessetopf1 === 6) {
                                    if(this.state.alternativeTopfgroessetopf1 === 5) {
                                      return (
                                        this.Verbotabfrage()
                                      );
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                        else {
                          if(this.state.trainingseinheitentopf1 === 12) {
                            if(this.state.bevorzugteTopfgroessetopf1 === 1) {
                              if(this.doppeltePersonengesamt().length === 0) {
                                return (
                                  this.KeineVerbotabfrage()
                                );
                              }
                              else {
                                return (
                                  this.NurNichtAmGleichenTag()
                                );
                              }
                            }
                            else {
                              if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                                return (
                                  this.Verbotabfrage()
                                );
                              }
                              else {
                                if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                                  return (
                                    this.Verbotabfrage()
                                  );
                                }
                                else {
                                  if(this.state.bevorzugteTopfgroessetopf1 === 6) {
                                    return (
                                      this.Verbotabfrage()
                                    );
                                  }
                                }
                              }
                            }
                          }
                          else {
                            if(this.state.trainingseinheitentopf1 === 13) {
                              if(this.state.bevorzugteTopfgroessetopf1 === 1) {
                                if(this.doppeltePersonengesamt().length === 0) {
                                  return (
                                    this.KeineVerbotabfrage()
                                  );
                                }
                                else {
                                  return (
                                    this.NurNichtAmGleichenTag()
                                  );
                                }
                              }
                              else {
                                if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                                  return (
                                    this.Verbotabfrage()
                                  );
                                }
                                else {
                                  if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                                    if(this.state.alternativeTopfgroessetopf1 === 3) {
                                      return (
                                        this.Verbotabfrage()
                                      );
                                    }
                                    else {
                                      if(this.state.alternativeTopfgroessetopf1 === 5) {
                                        return (
                                          this.Verbotabfrage()
                                        );
                                      }
                                    }
                                  }
                                  else {
                                    if(this.state.bevorzugteTopfgroessetopf1 === 5) {
                                      if(this.state.alternativeTopfgroessetopf1 === 4) {
                                        return (
                                          this.Verbotabfrage()
                                        );
                                      }
                                    }
                                    else {
                                      if(this.state.bevorzugteTopfgroessetopf1 === 6) {
                                        if(this.state.alternativeTopfgroessetopf1 === 7) {
                                          return (
                                            this.Verbotabfrage()
                                          );
                                        }
                                      }
                                      else {
                                        if(this.state.bevorzugteTopfgroessetopf1 === 7) {
                                          if(this.state.alternativeTopfgroessetopf1 === 6) {
                                            return (
                                              this.Verbotabfrage()
                                            );
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            else {
                              if(this.state.trainingseinheitentopf1 === 14) {
                                if(this.state.bevorzugteTopfgroessetopf1 === 1) {
                                  if(this.doppeltePersonengesamt().length === 0) {
                                    return (
                                      this.KeineVerbotabfrage()
                                    );
                                  }
                                  else {
                                    return (
                                      this.NurNichtAmGleichenTag()
                                    );
                                  }
                                }
                                else {
                                  if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                                    return (
                                      this.Verbotabfrage()
                                    );
                                  }
                                  else {
                                    if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                                      if(this.state.alternativeTopfgroessetopf1 === 3) {
                                        return (
                                          this.Verbotabfrage()
                                        );
                                      }
                                      else {
                                        if(this.state.alternativeTopfgroessetopf1 === 5) {
                                          return (
                                            this.Verbotabfrage()
                                          );
                                        }
                                      }
                                    }
                                    else {
                                      if(this.state.bevorzugteTopfgroessetopf1 === 5) {
                                        if(this.state.alternativeTopfgroessetopf1 === 4) {
                                          return (
                                            this.Verbotabfrage()
                                          );
                                        }
                                      }
                                      else {
                                        if(this.state.bevorzugteTopfgroessetopf1 === 7) {
                                          return (
                                            this.Verbotabfrage()
                                          );
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              else {
                                if(this.state.trainingseinheitentopf1 === 15) {
                                  if(this.state.bevorzugteTopfgroessetopf1 === 1) {
                                    if(this.doppeltePersonengesamt().length === 0) {
                                      return (
                                        this.KeineVerbotabfrage()
                                      );
                                    }
                                    else {
                                      return (
                                        this.NurNichtAmGleichenTag()
                                      );
                                    }
                                  }
                                  else {
                                    if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                                      return (
                                        this.Verbotabfrage()
                                      );
                                    }
                                    else {
                                      if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                                        if(this.state.alternativeTopfgroessetopf1 === 3) {
                                          return (
                                            this.Verbotabfrage()
                                          );
                                        }
                                      }
                                      else {
                                        if(this.state.bevorzugteTopfgroessetopf1 === 5) {
                                          return (
                                            this.Verbotabfrage()
                                          );
                                        }
                                        else {
                                          if(this.state.bevorzugteTopfgroessetopf1 === 7) {
                                            if(this.state.alternativeTopfgroessetopf1 === 8) {
                                              return (
                                                this.Verbotabfrage()
                                              );
                                            }
                                          }
                                          else {
                                            if(this.state.bevorzugteTopfgroessetopf1 === 8) {
                                              return (
                                                this.Verbotabfrage()
                                              );
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                                else {
                                  if(this.state.trainingseinheitentopf1 === 16) {
                                    if(this.state.bevorzugteTopfgroessetopf1 === 1) {
                                      if(this.doppeltePersonengesamt().length === 0) {
                                        return (
                                          this.KeineVerbotabfrage()
                                        );
                                      }
                                      else {
                                        return (
                                          this.NurNichtAmGleichenTag()
                                        );
                                      }
                                    }
                                    else {
                                      if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                                        return (
                                          this.Verbotabfrage()
                                        );
                                      }
                                      else {
                                        if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                                          return (
                                            this.Verbotabfrage()
                                          );
                                        }
                                        else {
                                          if(this.state.bevorzugteTopfgroessetopf1 === 5) {
                                            if(this.state.alternativeTopfgroessetopf1 === 6) {
                                              return (
                                                this.Verbotabfrage()
                                              );
                                            }
                                          }
                                          else {
                                            if(this.state.bevorzugteTopfgroessetopf1 === 6) {
                                              if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                return (
                                                  this.Verbotabfrage()
                                                );
                                              }
                                            }
                                            else {
                                              if(this.state.bevorzugteTopfgroessetopf1 === 8) {
                                                return (
                                                  this.Verbotabfrage()
                                                );
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                  else {
                                    if(this.state.trainingseinheitentopf1 === 17) {
                                      if(this.state.bevorzugteTopfgroessetopf1 === 1) {
                                        if(this.doppeltePersonengesamt().length === 0) {
                                          return (
                                            this.KeineVerbotabfrage()
                                          );
                                        }
                                        else {
                                          return (
                                            this.NurNichtAmGleichenTag()
                                          );
                                        }
                                      }
                                      else {
                                        if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                                          return (
                                            this.Verbotabfrage()
                                          );
                                        }
                                        else {
                                          if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                                            if(this.state.alternativeTopfgroessetopf1 === 3) {
                                              return (
                                                this.Verbotabfrage()
                                              );
                                            }
                                            else {
                                              if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                return (
                                                  this.Verbotabfrage()
                                                );
                                              }
                                            }
                                          }
                                          else {
                                            if(this.state.bevorzugteTopfgroessetopf1 === 5) {
                                              if(this.state.alternativeTopfgroessetopf1 === 4) {
                                                return (
                                                  this.Verbotabfrage()
                                                );
                                              }
                                              else {
                                                if(this.state.alternativeTopfgroessetopf1 === 6) {
                                                  return (
                                                    this.Verbotabfrage()
                                                  );
                                                }
                                              }
                                            }
                                            else {
                                              if(this.state.bevorzugteTopfgroessetopf1 === 6) {
                                                if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                  return (
                                                    this.Verbotabfrage()
                                                  );
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                    else {
                                      if(this.state.trainingseinheitentopf1 === 18) {
                                        if(this.state.bevorzugteTopfgroessetopf1 === 1) {
                                          if(this.doppeltePersonengesamt().length === 0) {
                                            return (
                                              this.KeineVerbotabfrage()
                                            );
                                          }
                                          else {
                                            return (
                                              this.NurNichtAmGleichenTag()
                                            );
                                          }
                                        }
                                        else {
                                          if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                                            return (
                                              this.Verbotabfrage()
                                            );
                                          }
                                          else {
                                            if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                                              if(this.state.alternativeTopfgroessetopf1 === 3) {
                                                return (
                                                  this.Verbotabfrage()
                                                );
                                              }
                                              else {
                                                if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                  return (
                                                    this.Verbotabfrage()
                                                  );
                                                }
                                              }
                                            }
                                            else {
                                              if(this.state.bevorzugteTopfgroessetopf1 === 5) {
                                                if(this.state.alternativeTopfgroessetopf1 === 4) {
                                                  return (
                                                    this.Verbotabfrage()
                                                  );
                                                }
                                              }
                                              else {
                                                if(this.state.bevorzugteTopfgroessetopf1 === 6) {
                                                  return (
                                                    this.Verbotabfrage()
                                                  );
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                      else {
                                        if(this.state.trainingseinheitentopf1 === 19) {
                                          if(this.state.bevorzugteTopfgroessetopf1 === 1) {
                                            if(this.doppeltePersonengesamt().length === 0) {
                                              return (
                                                this.KeineVerbotabfrage()
                                              );
                                            }
                                            else {
                                              return (
                                                this.NurNichtAmGleichenTag()
                                              );
                                            }
                                          }
                                          else {
                                            if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                                              return (
                                                this.Verbotabfrage()
                                              );
                                            }
                                            else {
                                              if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                                                if(this.state.alternativeTopfgroessetopf1 === 3) {
                                                  return (
                                                    this.Verbotabfrage()
                                                  );
                                                }
                                                else {
                                                  if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                    return (
                                                      this.Verbotabfrage()
                                                    );
                                                  }
                                                }
                                              }
                                              else {
                                                if(this.state.bevorzugteTopfgroessetopf1 === 5) {
                                                  if(this.state.alternativeTopfgroessetopf1 === 4) {
                                                    return (
                                                      this.Verbotabfrage()
                                                    );
                                                  }
                                                }
                                                else {
                                                  if(this.state.bevorzugteTopfgroessetopf1 === 6) {
                                                    if(this.state.alternativeTopfgroessetopf1 === 7) {
                                                      return (
                                                        this.Verbotabfrage()
                                                      );
                                                    }
                                                  }
                                                  else {
                                                    if(this.state.bevorzugteTopfgroessetopf1 === 7) {
                                                      if(this.state.alternativeTopfgroessetopf1 === 6) {
                                                        return (
                                                          this.Verbotabfrage()
                                                        );
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                        else {
                                          if(this.state.trainingseinheitentopf1 === 20) {
                                            if(this.state.bevorzugteTopfgroessetopf1 === 1) {
                                              if(this.doppeltePersonengesamt().length === 0) {
                                                return (
                                                  this.KeineVerbotabfrage()
                                                );
                                              }
                                              else {
                                                return (
                                                  this.NurNichtAmGleichenTag()
                                                );
                                              }
                                            }
                                            else {
                                              if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                                                return (
                                                  this.Verbotabfrage()
                                                );
                                              }
                                              else {
                                                if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                                                  return (
                                                    this.Verbotabfrage()
                                                  );
                                                }
                                                else {
                                                  if(this.state.bevorzugteTopfgroessetopf1 === 5) {
                                                    return (
                                                      this.Verbotabfrage()
                                                    );
                                                  }
                                                  else {
                                                    if(this.state.bevorzugteTopfgroessetopf1 === 6) {
                                                      if(this.state.alternativeTopfgroessetopf1 === 7) {
                                                        return (
                                                          this.Verbotabfrage()
                                                        );
                                                      }
                                                    }
                                                    else {
                                                      if(this.state.bevorzugteTopfgroessetopf1 === 7) {
                                                        if(this.state.alternativeTopfgroessetopf1 === 6) {
                                                          return (
                                                            this.Verbotabfrage()
                                                          );
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                          else {
                                            if(this.state.trainingseinheitentopf1 === 21) {
                                              if(this.state.bevorzugteTopfgroessetopf1 === 1) {
                                                if(this.doppeltePersonengesamt().length === 0) {
                                                  return (
                                                    this.KeineVerbotabfrage()
                                                  );
                                                }
                                                else {
                                                  return (
                                                    this.NurNichtAmGleichenTag()
                                                  );
                                                }
                                              }
                                              else {
                                                if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                                                  return (
                                                    this.Verbotabfrage()
                                                  );
                                                }
                                                else {
                                                  if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                                                    if(this.state.alternativeTopfgroessetopf1 === 3) {
                                                      return (
                                                        this.Verbotabfrage()
                                                      );
                                                    }
                                                    else {
                                                      if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                        return (
                                                          this.Verbotabfrage()
                                                        );
                                                      }
                                                    }
                                                  }
                                                  else {
                                                    if(this.state.bevorzugteTopfgroessetopf1 === 5) {
                                                      if(this.state.alternativeTopfgroessetopf1 === 4) {
                                                        return (
                                                          this.Verbotabfrage()
                                                        );
                                                      }
                                                      else {
                                                        if(this.state.alternativeTopfgroessetopf1 === 6) {
                                                          return (
                                                            this.Verbotabfrage()
                                                          );
                                                        }
                                                      }
                                                    }
                                                    else {
                                                      if(this.state.bevorzugteTopfgroessetopf1 === 6) {
                                                        if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                          return (
                                                            this.Verbotabfrage()
                                                          );
                                                        }
                                                      }
                                                      else {
                                                        if(this.state.bevorzugteTopfgroessetopf1 === 7) {
                                                          return (
                                                            this.Verbotabfrage()
                                                          );
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                            else {
                                              if(this.state.trainingseinheitentopf1 === 22) {
                                                if(this.state.bevorzugteTopfgroessetopf1 === 1) {
                                                  if(this.doppeltePersonengesamt().length === 0) {
                                                    return (
                                                      this.KeineVerbotabfrage()
                                                    );
                                                  }
                                                  else {
                                                    return (
                                                      this.NurNichtAmGleichenTag()
                                                    );
                                                  }
                                                }
                                                else {
                                                  if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                                                    return (
                                                      this.Verbotabfrage()
                                                    );
                                                  }
                                                  else {
                                                    if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                                                      if(this.state.alternativeTopfgroessetopf1 === 3) {
                                                        return (
                                                          this.Verbotabfrage()
                                                        );
                                                      }
                                                      else {
                                                        if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                          return (
                                                            this.Verbotabfrage()
                                                          );
                                                        }
                                                      }
                                                    }
                                                    else {
                                                      if(this.state.bevorzugteTopfgroessetopf1 === 5) {
                                                        if(this.state.alternativeTopfgroessetopf1 === 4) {
                                                          return (
                                                            this.Verbotabfrage()
                                                          );
                                                        }
                                                        else {
                                                          if(this.state.alternativeTopfgroessetopf1 === 6) {
                                                            return (
                                                              this.Verbotabfrage()
                                                            );
                                                          }
                                                        }
                                                      }
                                                      else {
                                                        if(this.state.bevorzugteTopfgroessetopf1 === 6) {
                                                          if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                            return (
                                                              this.Verbotabfrage()
                                                            );
                                                          }
                                                        }
                                                        else {
                                                          if(this.state.bevorzugteTopfgroessetopf1 === 7) {
                                                            if(this.state.alternativeTopfgroessetopf1 === 8) {
                                                              return (
                                                                this.Verbotabfrage()
                                                              );
                                                            }
                                                          }
                                                          else {
                                                            if(this.state.bevorzugteTopfgroessetopf1 === 8) {
                                                              return (
                                                                this.Verbotabfrage()
                                                              );
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              else {
                                                if(this.state.trainingseinheitentopf1 === 23) {
                                                  if(this.state.bevorzugteTopfgroessetopf1 === 1) {
                                                    if(this.doppeltePersonengesamt().length === 0) {
                                                      return (
                                                        this.KeineVerbotabfrage()
                                                      );
                                                    }
                                                    else {
                                                      return (
                                                        this.NurNichtAmGleichenTag()
                                                      );
                                                    }
                                                  }
                                                  else {
                                                    if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                                                      return (
                                                        this.Verbotabfrage()
                                                      );
                                                    }
                                                    else {
                                                      if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                                                        if(this.state.alternativeTopfgroessetopf1 === 3) {
                                                          return (
                                                            this.Verbotabfrage()
                                                          );
                                                        }
                                                        else {
                                                          if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                            return (
                                                              this.Verbotabfrage()
                                                            );
                                                          }
                                                        }
                                                      }
                                                      else {
                                                        if(this.state.bevorzugteTopfgroessetopf1 === 5) {
                                                          if(this.state.alternativeTopfgroessetopf1 === 4) {
                                                            return (
                                                              this.Verbotabfrage()
                                                            );
                                                          }
                                                          else {
                                                            if(this.state.alternativeTopfgroessetopf1 === 6) {
                                                              return (
                                                                this.Verbotabfrage()
                                                              );
                                                            }
                                                          }
                                                        }
                                                        else {
                                                          if(this.state.bevorzugteTopfgroessetopf1 === 6) {
                                                            if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                              return (
                                                                this.Verbotabfrage()
                                                              );
                                                            }
                                                          }
                                                          else {
                                                            if(this.state.bevorzugteTopfgroessetopf1 === 7) {
                                                              if(this.state.alternativeTopfgroessetopf1 === 8) {
                                                                return (
                                                                  this.Verbotabfrage()
                                                                );
                                                              }
                                                            }
                                                            else {
                                                              if(this.state.bevorzugteTopfgroessetopf1 === 8) {
                                                                return (
                                                                  this.Verbotabfrage()
                                                                );
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                                else {
                                                  if(this.state.trainingseinheitentopf1 === 24) {
                                                    if(this.state.bevorzugteTopfgroessetopf1 === 1) {
                                                      if(this.doppeltePersonengesamt().length === 0) {
                                                        return (
                                                          this.KeineVerbotabfrage()
                                                        );
                                                      }
                                                      else {
                                                        return (
                                                          this.NurNichtAmGleichenTag()
                                                        );
                                                      }
                                                    }
                                                    else {
                                                      if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                                                        return (
                                                          this.Verbotabfrage()
                                                        );
                                                      }
                                                      else {
                                                        if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                                                          return (
                                                            this.Verbotabfrage()
                                                          );
                                                        }
                                                        else {
                                                          if(this.state.bevorzugteTopfgroessetopf1 === 5) {
                                                            if(this.state.alternativeTopfgroessetopf1 === 4) {
                                                              return (
                                                                this.Verbotabfrage()
                                                              );
                                                            }
                                                          }
                                                          else {
                                                            if(this.state.bevorzugteTopfgroessetopf1 === 6) {
                                                              return (
                                                                this.Verbotabfrage()
                                                              );
                                                            }
                                                            else {
                                                              if(this.state.bevorzugteTopfgroessetopf1 === 8) {
                                                                return (
                                                                  this.Verbotabfrage()
                                                                );
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                  else {
                                                    if(this.state.trainingseinheitentopf1 === 25) {
                                                      if(this.state.bevorzugteTopfgroessetopf1 === 1) {
                                                        if(this.doppeltePersonengesamt().length === 0) {
                                                          return (
                                                            this.KeineVerbotabfrage()
                                                          );
                                                        }
                                                        else {
                                                          return (
                                                            this.NurNichtAmGleichenTag()
                                                          );
                                                        }
                                                      }
                                                      else {
                                                        if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                                                          return (
                                                            this.Verbotabfrage()
                                                          );
                                                        }
                                                        else {
                                                          if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                                                            if(this.state.alternativeTopfgroessetopf1 === 3) {
                                                              return (
                                                                this.Verbotabfrage()
                                                              );
                                                            }
                                                            else {
                                                              if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                                return (
                                                                  this.Verbotabfrage()
                                                                );
                                                              }
                                                            }
                                                          }
                                                          else {
                                                            if(this.state.bevorzugteTopfgroessetopf1 === 5) {
                                                              return (
                                                                this.Verbotabfrage()
                                                              );
                                                            }
                                                            else {
                                                              if(this.state.bevorzugteTopfgroessetopf1 === 6) {
                                                                if(this.state.alternativeTopfgroessetopf1 === 7) {
                                                                  return (
                                                                    this.Verbotabfrage()
                                                                  );
                                                                }
                                                              }
                                                              else {
                                                                if(this.state.bevorzugteTopfgroessetopf1 === 7) {
                                                                  if(this.state.alternativeTopfgroessetopf1 === 6) {
                                                                    return (
                                                                      this.Verbotabfrage()
                                                                    );
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                    else {
                                                      if(this.state.trainingseinheitentopf1 === 26) {
                                                        if(this.state.bevorzugteTopfgroessetopf1 === 1) {
                                                          if(this.doppeltePersonengesamt().length === 0) {
                                                            return (
                                                              this.KeineVerbotabfrage()
                                                            );
                                                          }
                                                          else {
                                                            return (
                                                              this.NurNichtAmGleichenTag()
                                                            );
                                                          }
                                                        }
                                                        else {
                                                          if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                                                            return (
                                                              this.Verbotabfrage()
                                                            );
                                                          }
                                                          else {
                                                            if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                                                              if(this.state.alternativeTopfgroessetopf1 === 3) {
                                                                return (
                                                                  this.Verbotabfrage()
                                                                );
                                                              }
                                                              else {
                                                                if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                                  return (
                                                                    this.Verbotabfrage()
                                                                  );
                                                                }
                                                              }
                                                            }
                                                            else {
                                                              if(this.state.bevorzugteTopfgroessetopf1 === 5) {
                                                                if(this.state.alternativeTopfgroessetopf1 === 4) {
                                                                  return (
                                                                    this.Verbotabfrage()
                                                                  );
                                                                }
                                                                else {
                                                                  if(this.state.alternativeTopfgroessetopf1 === 6) {
                                                                    return (
                                                                      this.Verbotabfrage()
                                                                    );
                                                                  }
                                                                }
                                                              }
                                                              else {
                                                                if(this.state.bevorzugteTopfgroessetopf1 === 6) {
                                                                  if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                                    return (
                                                                      this.Verbotabfrage()
                                                                    );
                                                                  }
                                                                  else {
                                                                    if(this.state.alternativeTopfgroessetopf1 === 7) {
                                                                      return (
                                                                        this.Verbotabfrage()
                                                                      );
                                                                    }
                                                                  }
                                                                }
                                                                else {
                                                                  if(this.state.bevorzugteTopfgroessetopf1 === 7) {
                                                                    if(this.state.alternativeTopfgroessetopf1 === 6) {
                                                                      return (
                                                                        this.Verbotabfrage()
                                                                      );
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                      else {
                                                        if(this.state.trainingseinheitentopf1 === 27) {
                                                          if(this.state.bevorzugteTopfgroessetopf1 === 1) {
                                                            if(this.doppeltePersonengesamt().length === 0) {
                                                              return (
                                                                this.KeineVerbotabfrage()
                                                              );
                                                            }
                                                            else {
                                                              return (
                                                                this.NurNichtAmGleichenTag()
                                                              );
                                                            }
                                                          }
                                                          else {
                                                            if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                                                              return (
                                                                this.Verbotabfrage()
                                                              );
                                                            }
                                                            else {
                                                              if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                                                                if(this.state.alternativeTopfgroessetopf1 === 3) {
                                                                  return (
                                                                    this.Verbotabfrage()
                                                                  );
                                                                }
                                                                else {
                                                                  if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                                    return (
                                                                      this.Verbotabfrage()
                                                                    );
                                                                  }
                                                                }
                                                              }
                                                              else {
                                                                if(this.state.bevorzugteTopfgroessetopf1 === 5) {
                                                                  if(this.state.alternativeTopfgroessetopf1 === 4) {
                                                                    return (
                                                                      this.Verbotabfrage()
                                                                    );
                                                                  }
                                                                  else {
                                                                    if(this.state.alternativeTopfgroessetopf1 === 6) {
                                                                      return (
                                                                        this.Verbotabfrage()
                                                                      );
                                                                    }
                                                                  }
                                                                }
                                                                else {
                                                                  if(this.state.bevorzugteTopfgroessetopf1 === 6) {
                                                                    if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                                      return (
                                                                        this.Verbotabfrage()
                                                                      );
                                                                    }
                                                                    else {
                                                                      if(this.state.alternativeTopfgroessetopf1 === 7) {
                                                                        return (
                                                                          this.Verbotabfrage()
                                                                        );
                                                                      }
                                                                    }
                                                                  }
                                                                  else {
                                                                    if(this.state.bevorzugteTopfgroessetopf1 === 7) {
                                                                      if(this.state.alternativeTopfgroessetopf1 === 6) {
                                                                        return (
                                                                          this.Verbotabfrage()
                                                                        );
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                        else {
                                                          if(this.state.trainingseinheitentopf1 === 28) {
                                                            if(this.state.bevorzugteTopfgroessetopf1 === 1) {
                                                              if(this.doppeltePersonengesamt().length === 0) {
                                                                return (
                                                                  this.KeineVerbotabfrage()
                                                                );
                                                              }
                                                              else {
                                                                return (
                                                                  this.NurNichtAmGleichenTag()
                                                                );
                                                              }
                                                            }
                                                            else {
                                                              if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                                                                return (
                                                                  this.Verbotabfrage()
                                                                );
                                                              }
                                                              else {
                                                                if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                                                                  return (
                                                                    this.Verbotabfrage()
                                                                  );
                                                                }
                                                                else {
                                                                  if(this.state.bevorzugteTopfgroessetopf1 === 5) {
                                                                    if(this.state.alternativeTopfgroessetopf1 === 4) {
                                                                      return (
                                                                        this.Verbotabfrage()
                                                                      );
                                                                    }
                                                                    else {
                                                                      if(this.state.alternativeTopfgroessetopf1 === 6) {
                                                                        return (
                                                                          this.Verbotabfrage()
                                                                        );
                                                                      }
                                                                    }
                                                                  }
                                                                  else {
                                                                    if(this.state.bevorzugteTopfgroessetopf1 === 6) {
                                                                      if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                                        return (
                                                                          this.Verbotabfrage()
                                                                        );
                                                                      }
                                                                    }
                                                                    else {
                                                                      if(this.state.bevorzugteTopfgroessetopf1 === 7) {
                                                                        return (
                                                                          this.Verbotabfrage()
                                                                        );
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                          else {
                                                            if(this.state.trainingseinheitentopf1 === 29) {
                                                              if(this.state.bevorzugteTopfgroessetopf1 === 1) {
                                                                if(this.doppeltePersonengesamt().length === 0) {
                                                                  return (
                                                                    this.KeineVerbotabfrage()
                                                                  );
                                                                }
                                                                else {
                                                                  return (
                                                                    this.NurNichtAmGleichenTag()
                                                                  );
                                                                }
                                                              }
                                                              else {
                                                                if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                                                                  return (
                                                                    this.Verbotabfrage()
                                                                  );
                                                                }
                                                                else {
                                                                  if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                                                                    if(this.state.alternativeTopfgroessetopf1 === 3) {
                                                                      return (
                                                                        this.Verbotabfrage()
                                                                      );
                                                                    }
                                                                    else {
                                                                      if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                                        return (
                                                                          this.Verbotabfrage()
                                                                        );
                                                                      }
                                                                    }
                                                                  }
                                                                  else {
                                                                    if(this.state.bevorzugteTopfgroessetopf1 === 5) {
                                                                      if(this.state.alternativeTopfgroessetopf1 === 4) {
                                                                        return (
                                                                          this.Verbotabfrage()
                                                                        );
                                                                      }
                                                                      else {
                                                                        if(this.state.alternativeTopfgroessetopf1 === 6) {
                                                                          return (
                                                                            this.Verbotabfrage()
                                                                          );
                                                                        }
                                                                      }
                                                                    }
                                                                    else {
                                                                      if(this.state.bevorzugteTopfgroessetopf1 === 6) {
                                                                        if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                                          return (
                                                                            this.Verbotabfrage()
                                                                          );
                                                                        }
                                                                      }
                                                                      else {
                                                                        if(this.state.bevorzugteTopfgroessetopf1 === 7) {
                                                                          if(this.state.alternativeTopfgroessetopf1 === 8) {
                                                                            return (
                                                                              this.Verbotabfrage()
                                                                            );
                                                                          }
                                                                        }
                                                                        else {
                                                                          if(this.state.bevorzugteTopfgroessetopf1 === 8) {
                                                                            return (
                                                                              this.Verbotabfrage()
                                                                            );
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                            else {
                                                              if(this.state.trainingseinheitentopf1 === 30) {
                                                                if(this.state.bevorzugteTopfgroessetopf1 === 1) {
                                                                  if(this.doppeltePersonengesamt().length === 0) {
                                                                    return (
                                                                      this.KeineVerbotabfrage()
                                                                    );
                                                                  }
                                                                  else {
                                                                    return (
                                                                      this.NurNichtAmGleichenTag()
                                                                    );
                                                                  }
                                                                }
                                                                else {
                                                                  if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                                                                    return (
                                                                      this.Verbotabfrage()
                                                                    );
                                                                  }
                                                                  else {
                                                                    if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                                                                      if(this.state.alternativeTopfgroessetopf1 === 3) {
                                                                        return (
                                                                          this.Verbotabfrage()
                                                                        );
                                                                      }
                                                                      else {
                                                                        if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                                          return (
                                                                            this.Verbotabfrage()
                                                                          );
                                                                        }
                                                                      }
                                                                    }
                                                                    else {
                                                                      if(this.state.bevorzugteTopfgroessetopf1 === 5) {
                                                                        return (
                                                                          this.Verbotabfrage()
                                                                        );
                                                                      }
                                                                      else {
                                                                        if(this.state.bevorzugteTopfgroessetopf1 === 6) {
                                                                          return (
                                                                            this.Verbotabfrage()
                                                                          );
                                                                        }
                                                                        else {
                                                                          if(this.state.bevorzugteTopfgroessetopf1 === 7) {
                                                                            if(this.state.alternativeTopfgroessetopf1 === 8) {
                                                                              return (
                                                                                this.Verbotabfrage()
                                                                              );
                                                                            }
                                                                          }
                                                                          else {
                                                                            if(this.state.bevorzugteTopfgroessetopf1 === 8) {
                                                                              return (
                                                                                this.Verbotabfrage()
                                                                              );
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                              else {
                                                                if(this.state.trainingseinheitentopf1 === 31) {
                                                                  if(this.state.bevorzugteTopfgroessetopf1 === 1) {
                                                                    if(this.doppeltePersonengesamt().length === 0) {
                                                                      return (
                                                                        this.KeineVerbotabfrage()
                                                                      );
                                                                    }
                                                                    else {
                                                                      return (
                                                                        this.NurNichtAmGleichenTag()
                                                                      );
                                                                    }
                                                                  }
                                                                  else {
                                                                    if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                                                                      return (
                                                                        this.Verbotabfrage()
                                                                      );
                                                                    }
                                                                    else {
                                                                      if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                                                                        if(this.state.alternativeTopfgroessetopf1 === 3) {
                                                                          return (
                                                                            this.Verbotabfrage()
                                                                          );
                                                                        }
                                                                        else {
                                                                          if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                                            return (
                                                                              this.Verbotabfrage()
                                                                            );
                                                                          }
                                                                        }
                                                                      }
                                                                      else {
                                                                        if(this.state.bevorzugteTopfgroessetopf1 === 5) {
                                                                          if(this.state.alternativeTopfgroessetopf1 === 4) {
                                                                            return (
                                                                              this.Verbotabfrage()
                                                                            );
                                                                          }
                                                                          else {
                                                                            if(this.state.alternativeTopfgroessetopf1 === 6) {
                                                                              return (
                                                                                this.Verbotabfrage()
                                                                              );
                                                                            }
                                                                          }
                                                                        }
                                                                        else {
                                                                          if(this.state.bevorzugteTopfgroessetopf1 === 6) {
                                                                            if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                                              return (
                                                                                this.Verbotabfrage()
                                                                              );
                                                                            }
                                                                            else {
                                                                              if(this.state.alternativeTopfgroessetopf1 === 7) {
                                                                                return (
                                                                                  this.Verbotabfrage()
                                                                                );
                                                                              }
                                                                            }
                                                                          }
                                                                          else {
                                                                            if(this.state.bevorzugteTopfgroessetopf1 === 7) {
                                                                              if(this.state.alternativeTopfgroessetopf1 === 6) {
                                                                                return (
                                                                                  this.Verbotabfrage()
                                                                                );
                                                                              }
                                                                              else {
                                                                                if(this.state.alternativeTopfgroessetopf1 === 8) {
                                                                                  return (
                                                                                    this.Verbotabfrage()
                                                                                  );
                                                                                }
                                                                              }
                                                                            }
                                                                            else {
                                                                              if(this.state.bevorzugteTopfgroessetopf1 === 8) {
                                                                                return (
                                                                                  this.Verbotabfrage()
                                                                                );
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                                else {
                                                                  if(this.state.trainingseinheitentopf1 === 32) {
                                                                    if(this.state.bevorzugteTopfgroessetopf1 === 1) {
                                                                      if(this.doppeltePersonengesamt().length === 0) {
                                                                        return (
                                                                          this.KeineVerbotabfrage()
                                                                        );
                                                                      }
                                                                      else {
                                                                        return (
                                                                          this.NurNichtAmGleichenTag()
                                                                        );
                                                                      }
                                                                    }
                                                                    else {
                                                                      if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                                                                        return (
                                                                          this.Verbotabfrage()
                                                                        );
                                                                      }
                                                                      else {
                                                                        if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                                                                          return (
                                                                            this.Verbotabfrage()
                                                                          );
                                                                        }
                                                                        else {
                                                                          if(this.state.bevorzugteTopfgroessetopf1 === 5) {
                                                                            if(this.state.alternativeTopfgroessetopf1 === 4) {
                                                                              return (
                                                                                this.Verbotabfrage()
                                                                              );
                                                                            }
                                                                            else {
                                                                              if(this.state.alternativeTopfgroessetopf1 === 6) {
                                                                                return (
                                                                                  this.Verbotabfrage()
                                                                                );
                                                                              }
                                                                            }
                                                                          }
                                                                          else {
                                                                            if(this.state.bevorzugteTopfgroessetopf1 === 6) {
                                                                              if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                                                return (
                                                                                  this.Verbotabfrage()
                                                                                );
                                                                              }
                                                                              else {
                                                                                if(this.state.alternativeTopfgroessetopf1 === 7) {
                                                                                  return (
                                                                                    this.Verbotabfrage()
                                                                                  );
                                                                                }
                                                                              }
                                                                            }
                                                                            else {
                                                                              if(this.state.bevorzugteTopfgroessetopf1 === 7) {
                                                                                if(this.state.alternativeTopfgroessetopf1 === 6) {
                                                                                  return (
                                                                                    this.Verbotabfrage()
                                                                                  );
                                                                                }
                                                                              }
                                                                              else {
                                                                                if(this.state.bevorzugteTopfgroessetopf1 === 8) {
                                                                                  return (
                                                                                    this.Verbotabfrage()
                                                                                  );
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                  else {
                                                                    if(this.state.trainingseinheitentopf1 === 33) {
                                                                      if(this.state.bevorzugteTopfgroessetopf1 === 1) {
                                                                        if(this.doppeltePersonengesamt().length === 0) {
                                                                          return (
                                                                            this.KeineVerbotabfrage()
                                                                          );
                                                                        }
                                                                        else {
                                                                          return (
                                                                            this.NurNichtAmGleichenTag()
                                                                          );
                                                                        }
                                                                      }
                                                                      else {
                                                                        if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                                                                          return (
                                                                            this.Verbotabfrage()
                                                                          );
                                                                        }
                                                                        else {
                                                                          if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                                                                            if(this.state.alternativeTopfgroessetopf1 === 3) {
                                                                              return (
                                                                                this.Verbotabfrage()
                                                                              );
                                                                            }
                                                                            else {
                                                                              if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                                                return (
                                                                                  this.Verbotabfrage()
                                                                                );
                                                                              }
                                                                            }
                                                                          }
                                                                          else {
                                                                            if(this.state.bevorzugteTopfgroessetopf1 === 5) {
                                                                              if(this.state.alternativeTopfgroessetopf1 === 4) {
                                                                                return (
                                                                                  this.Verbotabfrage()
                                                                                );
                                                                              }
                                                                              else {
                                                                                if(this.state.alternativeTopfgroessetopf1 === 6) {
                                                                                  return (
                                                                                    this.Verbotabfrage()
                                                                                  );
                                                                                }
                                                                              }
                                                                            }
                                                                            else {
                                                                              if(this.state.bevorzugteTopfgroessetopf1 === 6) {
                                                                                if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                                                  return (
                                                                                    this.Verbotabfrage()
                                                                                  );
                                                                                }
                                                                                else {
                                                                                  if(this.state.alternativeTopfgroessetopf1 === 7) {
                                                                                    return (
                                                                                      this.Verbotabfrage()
                                                                                    );
                                                                                  }
                                                                                }
                                                                              }
                                                                              else {
                                                                                if(this.state.bevorzugteTopfgroessetopf1 === 7) {
                                                                                  if(this.state.alternativeTopfgroessetopf1 === 6) {
                                                                                    return (
                                                                                      this.Verbotabfrage()
                                                                                    );
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                    else {
                                                                      if(this.state.trainingseinheitentopf1 === 34) {
                                                                        if(this.state.bevorzugteTopfgroessetopf1 === 1) {
                                                                          if(this.doppeltePersonengesamt().length === 0) {
                                                                            return (
                                                                              this.KeineVerbotabfrage()
                                                                            );
                                                                          }
                                                                          else {
                                                                            return (
                                                                              this.NurNichtAmGleichenTag()
                                                                            );
                                                                          }
                                                                        }
                                                                        else {
                                                                          if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                                                                            return (
                                                                              this.Verbotabfrage()
                                                                            );
                                                                          }
                                                                          else {
                                                                            if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                                                                              if(this.state.alternativeTopfgroessetopf1 === 3) {
                                                                                return (
                                                                                  this.Verbotabfrage()
                                                                                );
                                                                              }
                                                                              else {
                                                                                if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                                                  return (
                                                                                    this.Verbotabfrage()
                                                                                  );
                                                                                }
                                                                              }
                                                                            }
                                                                            else {
                                                                              if(this.state.bevorzugteTopfgroessetopf1 === 5) {
                                                                                if(this.state.alternativeTopfgroessetopf1 === 4) {
                                                                                  return (
                                                                                    this.Verbotabfrage()
                                                                                  );
                                                                                }
                                                                                else {
                                                                                  if(this.state.alternativeTopfgroessetopf1 === 6) {
                                                                                    return (
                                                                                      this.Verbotabfrage()
                                                                                    );
                                                                                  }
                                                                                }
                                                                              }
                                                                              else {
                                                                                if(this.state.bevorzugteTopfgroessetopf1 === 6) {
                                                                                  if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                                                    return (
                                                                                      this.Verbotabfrage()
                                                                                    );
                                                                                  }
                                                                                  else {
                                                                                    if(this.state.alternativeTopfgroessetopf1 === 7) {
                                                                                      return (
                                                                                        this.Verbotabfrage()
                                                                                      );
                                                                                    }
                                                                                  }
                                                                                }
                                                                                else {
                                                                                  if(this.state.bevorzugteTopfgroessetopf1 === 7) {
                                                                                    if(this.state.alternativeTopfgroessetopf1 === 6) {
                                                                                      return (
                                                                                        this.Verbotabfrage()
                                                                                      );
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                      else {
                                                                        if(this.state.trainingseinheitentopf1 === 35) {
                                                                          if(this.state.bevorzugteTopfgroessetopf1 === 1) {
                                                                            if(this.doppeltePersonengesamt().length === 0) {
                                                                              return (
                                                                                this.KeineVerbotabfrage()
                                                                              );
                                                                            }
                                                                            else {
                                                                              return (
                                                                                this.NurNichtAmGleichenTag()
                                                                              );
                                                                            }
                                                                          }
                                                                          else {
                                                                            if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                                                                              return (
                                                                                this.Verbotabfrage()
                                                                              );
                                                                            }
                                                                            else {
                                                                              if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                                                                                if(this.state.alternativeTopfgroessetopf1 === 3) {
                                                                                  return (
                                                                                    this.Verbotabfrage()
                                                                                  );
                                                                                }
                                                                                else {
                                                                                  if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                                                    return (
                                                                                      this.Verbotabfrage()
                                                                                    );
                                                                                  }
                                                                                }
                                                                              }
                                                                              else {
                                                                                if(this.state.bevorzugteTopfgroessetopf1 === 5) {
                                                                                  return (
                                                                                    this.Verbotabfrage()
                                                                                  );
                                                                                }
                                                                                else {
                                                                                  if(this.state.bevorzugteTopfgroessetopf1 === 6) {
                                                                                    if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                                                      return (
                                                                                        this.Verbotabfrage()
                                                                                      );
                                                                                    }
                                                                                  }
                                                                                  else {
                                                                                    if(this.state.bevorzugteTopfgroessetopf1 === 7) {
                                                                                      return (
                                                                                        this.Verbotabfrage()
                                                                                      );
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                        else {
                                                                          if(this.state.trainingseinheitentopf1 === 36) {
                                                                            if(this.state.bevorzugteTopfgroessetopf1 === 1) {
                                                                              if(this.doppeltePersonengesamt().length === 0) {
                                                                                return (
                                                                                  this.KeineVerbotabfrage()
                                                                                );
                                                                              }
                                                                              else {
                                                                                return (
                                                                                  this.NurNichtAmGleichenTag()
                                                                                );
                                                                              }
                                                                            }
                                                                            else {
                                                                              if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                                                                                return (
                                                                                  this.Verbotabfrage()
                                                                                );
                                                                              }
                                                                              else {
                                                                                if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                                                                                  return (
                                                                                    this.Verbotabfrage()
                                                                                  );
                                                                                }
                                                                                else {
                                                                                  if(this.state.bevorzugteTopfgroessetopf1 === 5) {
                                                                                    if(this.state.alternativeTopfgroessetopf1 === 4) {
                                                                                      return (
                                                                                        this.Verbotabfrage()
                                                                                      );
                                                                                    }
                                                                                    else {
                                                                                      if(this.state.alternativeTopfgroessetopf1 === 6) {
                                                                                        return (
                                                                                          this.Verbotabfrage()
                                                                                        );
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                  else {
                                                                                    if(this.state.bevorzugteTopfgroessetopf1 === 6) {
                                                                                      return (
                                                                                        this.Verbotabfrage()
                                                                                      );
                                                                                    }
                                                                                    else {
                                                                                      if(this.state.bevorzugteTopfgroessetopf1 === 7) {
                                                                                        if(this.state.alternativeTopfgroessetopf1 === 8) {
                                                                                          return (
                                                                                            this.Verbotabfrage()
                                                                                          );
                                                                                        }
                                                                                      }
                                                                                      else {
                                                                                        if(this.state.bevorzugteTopfgroessetopf1 === 8) {
                                                                                          return (
                                                                                            this.Verbotabfrage()
                                                                                          );
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                          else {
                                                                            if(this.state.trainingseinheitentopf1 === 37) {
                                                                              if(this.state.bevorzugteTopfgroessetopf1 === 1) {
                                                                                if(this.doppeltePersonengesamt().length === 0) {
                                                                                  return (
                                                                                    this.KeineVerbotabfrage()
                                                                                  );
                                                                                }
                                                                                else {
                                                                                  return (
                                                                                    this.NurNichtAmGleichenTag()
                                                                                  );
                                                                                }
                                                                              }
                                                                              else {
                                                                                if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                                                                                  return (
                                                                                    this.Verbotabfrage()
                                                                                  );
                                                                                }
                                                                                else {
                                                                                  if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                                                                                    if(this.state.alternativeTopfgroessetopf1 === 3) {
                                                                                      return (
                                                                                        this.Verbotabfrage()
                                                                                      );
                                                                                    }
                                                                                    else {
                                                                                      if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                                                        return (
                                                                                          this.Verbotabfrage()
                                                                                        );
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                  else {
                                                                                    if(this.state.bevorzugteTopfgroessetopf1 === 5) {
                                                                                      if(this.state.alternativeTopfgroessetopf1 === 4) {
                                                                                        return (
                                                                                          this.Verbotabfrage()
                                                                                        );
                                                                                      }
                                                                                      else {
                                                                                        if(this.state.alternativeTopfgroessetopf1 === 6) {
                                                                                          return (
                                                                                            this.Verbotabfrage()
                                                                                          );
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                    else {
                                                                                      if(this.state.bevorzugteTopfgroessetopf1 === 6) {
                                                                                        if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                                                          return (
                                                                                            this.Verbotabfrage()
                                                                                          );
                                                                                        }
                                                                                        else {
                                                                                          if(this.state.alternativeTopfgroessetopf1 === 7) {
                                                                                            return (
                                                                                              this.Verbotabfrage()
                                                                                            );
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                      else {
                                                                                        if(this.state.bevorzugteTopfgroessetopf1 === 7) {
                                                                                          if(this.state.alternativeTopfgroessetopf1 === 6) {
                                                                                            return (
                                                                                              this.Verbotabfrage()
                                                                                            );
                                                                                          }
                                                                                          else {
                                                                                            if(this.state.alternativeTopfgroessetopf1 === 8) {
                                                                                              return (
                                                                                                this.Verbotabfrage()
                                                                                              );
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                        else {
                                                                                          if(this.state.bevorzugteTopfgroessetopf1 === 8) {
                                                                                            return (
                                                                                              this.Verbotabfrage()
                                                                                            );
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                            else {
                                                                              if(this.state.trainingseinheitentopf1 === 38) {
                                                                                if(this.state.bevorzugteTopfgroessetopf1 === 1) {
                                                                                  if(this.doppeltePersonengesamt().length === 0) {
                                                                                    return (
                                                                                      this.KeineVerbotabfrage()
                                                                                    );
                                                                                  }
                                                                                  else {
                                                                                    return (
                                                                                      this.NurNichtAmGleichenTag()
                                                                                    );
                                                                                  }
                                                                                }
                                                                                else {
                                                                                  if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                                                                                    return (
                                                                                      this.Verbotabfrage()
                                                                                    );
                                                                                  }
                                                                                  else {
                                                                                    if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                                                                                      if(this.state.alternativeTopfgroessetopf1 === 3) {
                                                                                        return (
                                                                                          this.Verbotabfrage()
                                                                                        );
                                                                                      }
                                                                                      else {
                                                                                        if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                                                          return (
                                                                                            this.Verbotabfrage()
                                                                                          );
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                    else {
                                                                                      if(this.state.bevorzugteTopfgroessetopf1 === 5) {
                                                                                        if(this.state.alternativeTopfgroessetopf1 === 4) {
                                                                                          return (
                                                                                            this.Verbotabfrage()
                                                                                          );
                                                                                        }
                                                                                        else {
                                                                                          if(this.state.alternativeTopfgroessetopf1 === 6) {
                                                                                            return (
                                                                                              this.Verbotabfrage()
                                                                                            );
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                      else {
                                                                                        if(this.state.bevorzugteTopfgroessetopf1 === 6) {
                                                                                          if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                                                            return (
                                                                                              this.Verbotabfrage()
                                                                                            );
                                                                                          }
                                                                                          else {
                                                                                            if(this.state.alternativeTopfgroessetopf1 === 7) {
                                                                                              return (
                                                                                                this.Verbotabfrage()
                                                                                              );
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                        else {
                                                                                          if(this.state.bevorzugteTopfgroessetopf1 === 7) {
                                                                                            if(this.state.alternativeTopfgroessetopf1 === 6) {
                                                                                              return (
                                                                                                this.Verbotabfrage()
                                                                                              );
                                                                                            }
                                                                                            else {
                                                                                              if(this.state.alternativeTopfgroessetopf1 === 8) {
                                                                                                return (
                                                                                                  this.Verbotabfrage()
                                                                                                );
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                          else {
                                                                                            if(this.state.bevorzugteTopfgroessetopf1 === 8) {
                                                                                              return (
                                                                                                this.Verbotabfrage()
                                                                                              );
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                              else {
                                                                                if(this.state.trainingseinheitentopf1 === 39) {
                                                                                  if(this.state.bevorzugteTopfgroessetopf1 === 1) {
                                                                                    if(this.doppeltePersonengesamt().length === 0) {
                                                                                      return (
                                                                                        this.KeineVerbotabfrage()
                                                                                      );
                                                                                    }
                                                                                    else {
                                                                                      return (
                                                                                        this.NurNichtAmGleichenTag()
                                                                                      );
                                                                                    }
                                                                                  }
                                                                                  else {
                                                                                    if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                                                                                      return (
                                                                                        this.Verbotabfrage()
                                                                                      );
                                                                                    }
                                                                                    else {
                                                                                      if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                                                                                        if(this.state.alternativeTopfgroessetopf1 === 3) {
                                                                                          return (
                                                                                            this.Verbotabfrage()
                                                                                          );
                                                                                        }
                                                                                        else {
                                                                                          if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                                                            return (
                                                                                              this.Verbotabfrage()
                                                                                            );
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                      else {
                                                                                        if(this.state.bevorzugteTopfgroessetopf1 === 5) {
                                                                                          if(this.state.alternativeTopfgroessetopf1 === 4) {
                                                                                            return (
                                                                                              this.Verbotabfrage()
                                                                                            );
                                                                                          }
                                                                                          else {
                                                                                            if(this.state.alternativeTopfgroessetopf1 === 6) {
                                                                                              return (
                                                                                                this.Verbotabfrage()
                                                                                              );
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                        else {
                                                                                          if(this.state.bevorzugteTopfgroessetopf1 === 6) {
                                                                                            if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                                                              return (
                                                                                                this.Verbotabfrage()
                                                                                              );
                                                                                            }
                                                                                            else {
                                                                                              if(this.state.alternativeTopfgroessetopf1 === 7) {
                                                                                                return (
                                                                                                  this.Verbotabfrage()
                                                                                                );
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                          else {
                                                                                            if(this.state.bevorzugteTopfgroessetopf1 === 7) {
                                                                                              if(this.state.alternativeTopfgroessetopf1 === 6) {
                                                                                                return (
                                                                                                  this.Verbotabfrage()
                                                                                                );
                                                                                              }
                                                                                              else {
                                                                                                if(this.state.alternativeTopfgroessetopf1 === 8) {
                                                                                                  return (
                                                                                                    this.Verbotabfrage()
                                                                                                  );
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                            else {
                                                                                              if(this.state.bevorzugteTopfgroessetopf1 === 8) {
                                                                                                return (
                                                                                                  this.Verbotabfrage()
                                                                                                );
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                                else {
                                                                                  if(this.state.trainingseinheitentopf1 === 40) {
                                                                                    if(this.state.bevorzugteTopfgroessetopf1 === 1) {
                                                                                      if(this.doppeltePersonengesamt().length === 0) {
                                                                                        return (
                                                                                          this.KeineVerbotabfrage()
                                                                                        );
                                                                                      }
                                                                                      else {
                                                                                        return (
                                                                                          this.NurNichtAmGleichenTag()
                                                                                        );
                                                                                      }
                                                                                    }
                                                                                    else {
                                                                                      if(this.state.bevorzugteTopfgroessetopf1 === 3) {
                                                                                        return (
                                                                                          this.Verbotabfrage()
                                                                                        );
                                                                                      }
                                                                                      else {
                                                                                        if(this.state.bevorzugteTopfgroessetopf1 === 4) {
                                                                                          return (
                                                                                            this.Verbotabfrage()
                                                                                          );
                                                                                        }
                                                                                        else {
                                                                                          if(this.state.bevorzugteTopfgroessetopf1 === 5) {
                                                                                            return (
                                                                                              this.Verbotabfrage()
                                                                                            );
                                                                                          }
                                                                                          else {
                                                                                            if(this.state.bevorzugteTopfgroessetopf1 === 6) {
                                                                                              if(this.state.alternativeTopfgroessetopf1 === 5) {
                                                                                                return (
                                                                                                  this.Verbotabfrage()
                                                                                                );
                                                                                              }
                                                                                              else {
                                                                                                if(this.state.alternativeTopfgroessetopf1 === 7) {
                                                                                                  return (
                                                                                                    this.Verbotabfrage()
                                                                                                  );
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                            else {
                                                                                              if(this.state.bevorzugteTopfgroessetopf1 === 7) {
                                                                                                if(this.state.alternativeTopfgroessetopf1 === 6) {
                                                                                                  return (
                                                                                                    this.Verbotabfrage()
                                                                                                  );
                                                                                                }
                                                                                              }
                                                                                              else {
                                                                                                if(this.state.bevorzugteTopfgroessetopf1 === 8) {
                                                                                                  return (
                                                                                                    this.Verbotabfrage()
                                                                                                  );
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                  else {
                                                                                    return(
                                                                                      <div>Loading...</div> //Sonst kommt am anfang kein return
                                                                                    );
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*

-------------------------- RENDER --------------------------

  */

  render() {
    if(this.state.Schritt === -1) {
      return(<div>Sie sind nicht berechtigt!</div>);
    }
    else {
      if(this.state.loading === true ) {
        return <div>Loading...</div>;
      }
      else {
        if(this.state.naechsteSeite === true) {
          return(
            <div className="container">
              Loading...
            </div>
          );
        }
        else {
          return (
            this.Schritt7render()
          );
        }
      }
    }
  }

} //Ende der class