import React from "react";
import { UnderHeaderDemo } from "./UnderHeaderDemo";
import "./demo.css";

export class Demo extends React.Component {
  render() {
    if(window.screen.availWidth > 900) {
      return (
        <div>
          <UnderHeaderDemo />
          <br/>
          <div className="container">
            <big>
              Die Demo bietet lediglich einen Überblick darüber, welche Daten verwendet werden.
              Sie stellt keinesfalls eine exakte Abbildung des eigentlichen Programms dar.<br/>
              Um die richtige Version sehen zu können, <a href="/kontakt"><u>kontaktieren</u></a> Sie
              uns bitte.
            </big>
              {/* <big>
                Klicken Sie {" "}
                  <a href="demoerklaervideo" target="_blank"><u>hier</u></a>
                {" "} um sich ein 5-minütiges Erklärvideo der Demo anzugucken.
              </big> */}
          </div>
          <br/>
          <div> 
            <div className="container"> 
              <table>
                <tbody>
                  <tr>
                    <td>
                      <big><big><strong className="blauerhintergrund">
                          Schritt 1:
                        </strong></big></big>
                    </td>
                    <td>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </td>
                    <td>
                      <big>Anzahl der Anmeldungen</big>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <big><big><strong className="blauerhintergrund">
                          Schritt 2:
                        </strong></big></big>
                    </td>
                    <td>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </td>
                    <td>
                      <big>
                        Anzahl der zur Verfügung stehenden Plätze
                      </big>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <big><big><strong className="blauerhintergrund">
                          Schritt 3:
                        </strong></big></big>
                    </td>
                    <td>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </td>
                    <td>
                      <big>
                        Anzahl an „Töpfen“. Was mit einem „Topf“ gemeint ist, erfahren Sie dort!
                      </big>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <big><big><strong className="blauerhintergrund">
                          Schritt 4:
                        </strong></big></big>
                    </td>
                    <td>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </td>
                    <td>
                      <big>
                        Gruppengröße
                      </big>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <big><big><strong className="blauerhintergrund">
                          Schritt 5:
                        </strong></big></big>
                    </td>
                    <td>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </td>
                    <td>
                      <big>
                        Namen und Zeiten
                      </big>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <big><big><strong className="blauerhintergrund">
                          Schritt 6:
                        </strong></big></big>
                    </td>
                    <td>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </td>
                    <td>
                      <big>
                        Gruppenwünsche
                      </big>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <big><big><strong className="blauerhintergrund">
                          Schritt 7:
                        </strong></big></big>
                    </td>
                    <td>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </td>
                    <td>
                      <big>
                        Gruppenverbote
                      </big>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <br/>
          <div className="container">
            <big>Achten Sie daruf, realitätsnahe Angaben zu tätigen!</big>
            <br/>
            <br/>
            <div className="normal">
              <button
                onClick={(event) => {document.location.href="/demoprogramm"}}>
                Starten Sie hier unsere Demo
              </button>
            </div>
          </div>

        </div>
      );
    }
    else {
      return (
        <div>
          <UnderHeaderDemo />
          <br/>
          <div className="container">
            <big>
              Die Demo bietet lediglich einen Überblick darüber, welche Daten verwendet werden.
              Sie stellt keinesfalls eine exakte Abbildung des eigentlichen Programms dar.<br/>
              Um die richtige Version sehen zu können, <a href="/kontakt"><u>kontaktieren</u></a> Sie
              uns bitte.
            </big>
              {/* <big>
                Klicken Sie {" "}
                  <a href="demoerklaervideo" target="_blank"><u>hier</u></a>
                {" "} um sich ein 5-minütiges Erklärvideo der Demo anzugucken.
              </big> */}
          </div>
          <br/>
          <div> 
            <div className="container"> 
              <table>
                <tbody>
                  <tr>
                    <td>
                      <big><big><strong className="blauerhintergrund">
                          Schritt 1:
                        </strong></big></big>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <big>Anzahl der Anmeldungen</big>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <big><big><strong className="blauerhintergrund">
                          Schritt 2:
                        </strong></big></big>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <big>
                        Anzahl der zur Verfügung stehenden Plätze
                      </big>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <big><big><strong className="blauerhintergrund">
                          Schritt 3:
                        </strong></big></big>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <big>
                        Anzahl an „Töpfen“. Was mit einem „Topf“ gemeint ist, erfahren Sie dort!
                      </big>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <big><big><strong className="blauerhintergrund">
                          Schritt 4:
                        </strong></big></big>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <big>
                        Gruppengröße
                      </big>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <big><big><strong className="blauerhintergrund">
                          Schritt 5:
                        </strong></big></big>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <big>
                        Namen und Zeiten
                      </big>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <big><big><strong className="blauerhintergrund">
                          Schritt 6:
                        </strong></big></big>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <big>
                        Gruppenwünsche
                      </big>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <big><big><strong className="blauerhintergrund">
                          Schritt 7:
                        </strong></big></big>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <big>
                        Gruppenverbote
                      </big>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <br/>
          <br/>
          <div className="container">
            <big>Achten Sie daruf, realitätsnahe Angaben zu tätigen!</big>
            <br/>
            <br/>
            <div className="normal">
              <button
                onClick={(event) => {document.location.href="/demoprogramm"}}>
                Starten Sie hier unsere Demo
              </button>
            </div>
          </div>

        </div>
      );
    }
  }
}
