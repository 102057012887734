import React from "react";
import { UnderHeaderGruenderstory } from "./components/UnderHeaderGruenderstory";
import "./App.css";

export const Gruenderstory = () => (
  <div>
    <UnderHeaderGruenderstory />
    <div className="container gruenderstory">
      <table className="Test">
        <tbody>
          <tr>
            <td>2018</td>
            <td>
              Mein Name ist Philipp Frohn und alles fing{" "}
              <strong>Ende 2018</strong> an, kurz nachdem die
              Tennisschule des Vereins den Besitzer wechselte. Ich fragte den
              neuen Inhaber, den ich schon seit mehreren Jahren kannte, ob er
              den Wochenplan für die Wintersaison „per Hand“ einteile oder es
              mittlerweile eine digitale Hilfe hierfür gibt. Als er mir
              mitteilte, dass er diesen in stundenlanger aufwendiger Arbeit
              zusammenstellte, da er keine Alternative kennt, habe ich mich im
              Internet auf die Suche nach einer solchen Möglichkeit begeben.
              Nach vergebener Suche im deutschsprachigen Raum, suchte ich sogar
              in mehreren anderen Sprachen, um zu schauen, ob es vielleicht in
              anderen Ländern Alternativen gibt. Auch dieser Versuch scheiterte!
              Einzig und allein stieß ich jedes Mal nur auf Foren, in denen
              Personen Ihre Erfahrungen und Tipps teilten, wie sie die
              Einteilung möglichst einfach und schnell abwickeln.
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              Wie ich in dem Moment des Trainings auf die Idee kam, diese eine
              Frage zu stellen, die viele Steine ins Rollen brachte, weiß ich
              selbst nicht ganz genau! Kurz zuvor, genaugenommen im{" "}
              <strong>Sommer 2018</strong>, hörte ich jedoch, als Teil meines
              Mathematikstudiums, eine Vorlesung, die sich mit Problemlösungen
              von Dingen genau dieser Art beschäftigte. Diese hatte ich sogar
              damals nur aufgrund von Mangel an Alternativen gehört, was sich im
              Nachhinein als die vielleicht beste Wahl meines Studiums
              herausstellte.
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              Nun war bei mir der Ehrgeiz geweckt, meine Erkenntnisse aus dem
              Studium in der Realität zur Anwendung zu bringen. An die Gründung
              eines eigenen Unternehmens habe ich zu diesem Zeitpunkt noch gar
              nicht gedacht, viel mehr sah ich dies als nettes Projekt für
              nebenbei. Dabei trübten jedoch zwei Dinge meine Euphorie: Zum
              einen hatte ich (so gut wie) keine Programmierkenntnisse, was
              schlichtweg notwendig wäre und zum anderen war ich{" "}
              <strong>Ende 2018</strong> in den Vorbereitungen zu meiner
              Bachelorarbeit. Aufgrund letzterem habe ich die Idee auf Anfang
              {" "}<strong>April 2019</strong> verschoben, weil mir klar war,
              dass der Abgabetermin der Arbeit auf Ende März fallen würde. Nach
              der erfolgreichen Abgabe meiner Arbeit fing jedoch direkt mein
              erstes Mastersemester und der Erwerb der C-Trainerlizenz an,
              wodurch sich mein Vorhaben erneut verschob.
            </td>
          </tr>
          <tr>
            <td>2019</td>
            <td>
              Nach meiner letzten Klausur in diesem Semester hatte ich nun
              endlich die nötige Zeit, um mich intensiv mit dem Thema
              auseinanderzusetzen. Mein erstes Problem von damals (die mangelnde
              Programmiererfahrung) bestand aber immer noch. Daher überlegte
              ich, wer mir weiterhelfen könnte und kam auch auf den Gedanken der
              Unternehmensgründung. Morgens, am <strong>24.07.2019</strong>,
              schrieb ich daher Niklas Wienand von meiner Idee. Ich wusste, dass
              er programmieren kann und auch die Vorlesung gehört hatte, die
              sich mit dieser Art von Problemlösung beschäftigte, die ich vorhin
              schon einmal angesprochen hatte. Zudem spielt er selbst
              ebenfalls Tennis, konnte die Problematik also gut nachvollziehen
              und kam für mich auch sonst sehr gut als Geschäftspartner in
              Frage. Wir trafen uns noch am selben Tag und besprachen alle
              Details inklusive meiner ersten Programmüberlegungen und wie diese
              umgesetzt werden können. Noch am Abend programmierte er die ersten
              Zeilen des Programms. Diese waren die perfekte Vorlage für mich,
              um die Programmiersprache mithilfe von zusätzlichen Lernvideos zu
              erlernen.
            </td>
          </tr>
          <tr>
            <td>2020</td>
            <td>
              Allein bis zur Gründung zu <strong>Beginn des Jahres 2020</strong>{" "}
              verbrachten wir jeweils hunderte von Stunden für die Überlegung
              und Umsetzung unserer Ideen. Durch die Unternehmensgründung
              mussten wir uns dann konsequenterweise auch mit
              betriebswirtschaftlichen und juristischen Dingen
              auseinandersetzen. Das Programm war zu diesem Zeitpunkt im Großen
              und Ganzen fertig, jedoch ging es dann an die Erstellung der
              Website, auf der auch das Programm eingebunden werden sollte. Das
              Erstellen der Website an sich, ist aufgrund vieler vorhandener
              Lernvideos kein Problem gewesen. Wie wir allerdings unser Programm
              eingebunden bekommen, dazu hatte wir vorerst absolut keine Antwort
              gefunden.
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              Durch etliches Fragen von verschiedensten Personen, die sich mit
              Websitebuilding auskennen, haben wir nach und nach immer mehr
              Puzzleteile zugeschoben bekommen, wodurch wir am Ende mit weiterer
              stundenlanger Wiederholung von Recherche im Internet und Hinweisen
              von Personen das Puzzle lösen konnten und nun wussten, was zu tun
              war. Wir hatten uns vorher vorgenommen, bis zum Start der{" "}
              <strong>Sommersaison 2020</strong>, unser Produkt einsatzbereit zu
              haben. Diese Ambition mussten wir aber leider über den Haufen
              werfen, als sich die Probleme mit der Website ergaben.
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              Durch die immer verschärften Regelungen zur Eindämmung der
              vorherschenden Corona-Pandemie befürchteten wir, dass in der{" "}
              <strong>Wintersaison 2020</strong> gar kein Training stattfinden
              kann und die Tennisschulen nicht dazu bereit wären, ein Tool für
              Ihre Trainingsplanung zu kaufen. Daher beschlossen wir, uns noch
              nicht an die Tennisschulen zu wenden, sondern unser Produkt weiter
              zu verbessern und zu erweitern.
              In der Zwischenzeit haben wir zudem beispielsweise am{" "}
              <strong>14.09.2020</strong> erfolgreich vor einer Jury des Landes
              NRW uns und unser Unternehmen präsentiert, woraufhin wir noch am
              selben Tag eine Zusage zum NRW Gründerstipendium erhalten haben.
              Am <strong>16.11.2020</strong> belegten wir außerdem den 2. Platz
              des Existenzgründerpreises im Rhein-Erft-Kreis.
            </td>
          </tr>
          <tr>
            <td>2021</td>
            <td>
              Es war am Abend des <strong>02.02.2021</strong>, an dem nach
              edlichen Monaten Arbeit endlich alles fertiggestellt war und unser
              Programm den Tennisschulen nun vollumpfänglich über unsere Website
              zur Verfügung stand. Genau rechtzeitig, damit die schon vorher
              eingebundenen Tennisschulen das Tool für die {" "}
              <strong>Sommersaison 2021</strong> nutzen konnten. Hierbei hatten
              wir allerdings noch einen erheblichen Entwicklungsschritt mit
              veröffentlicht. Es war nicht nur möglich den Stundenplan berechnen
              zu lassen, es war sogar auch möglich, dass sich die Schüler bzw.
              deren Erziehungsberechtigten direkt über unsere Website zum
              Training in Ihrem Verein anmelden konnten. Somit sparten sich die
              die Tennisschulen die Arbeit Ihre Schüler*innen in das Programm
              einzugeben. Aber nicht nur das! Sie haben gleichzeitig den
              Anmeldeprozess vollständig digitalisiert.
            </td>
          </tr>

          <tr>
            <td className="arrow"></td>
            <td>To be continued...</td>
          </tr>
        </tbody>
      </table>

      <br/>
      <br/>

      <big><big>
        <strong className="blauerhintergrund">
          Danksagung
        </strong>
      </big></big>

      <p>
        An dieser Stelle wollen wir uns vielmals bei Philipps Chef-Trainer
        Niclas Dose bedanken, der uns rund um die Gestaltung seines Wochenplans
        Frage und Antwort gestellt hat, wodurch wir wissen, dass wir jedes
        Detail in unserem Programm beachtet haben.
      </p>
      <p>
        Ein weiteres Dankeschön widmen wir Philipps Cousin Rune Frohn, der uns bei
        vielen technischen Fragen weiterhelfen konnte und uns somit einige Stunden
        an Arbeit erspart hat.
      </p>
      <p>
        Last, but not least: Danke an Carina Dumitrescu, Lukas Bertzen, Peter
        Lintermann und allen, die uns auf unterschiedliche Art und Weise unterstützt
        haben.
      </p>
    </div>
  </div>
);
