import React from "react";
import { UnderHeaderProdukte } from "./components/UnderHeaderProdukte";
import "./App.css";



export class Produkte extends React.Component {
  constructor() {
    super();
    this.state = {
      Menu: 0,
    };
  }

  render() {
    if(this.state.Menu === 0) {
      return(
        <div>
          <UnderHeaderProdukte />
          <div className="container">
            <div className="version">
              <big><center>
              {window.screen.availWidth > 600 ? (
                <div><big><big><big>Wählen Sie Ihr Wunschpaket aus:</big></big></big></div>
              ) : (
                <div>Wählen Sie Ihr Wunschpaket aus:</div>
              )}
               
              </center></big>
              
              <br/>

              <table className="produkttabelle">
                <tbody>
                  <tr>
                    <td></td>
                    <td><center>TGO Anmeldung<span className="info">
                    <button onClick={(event) => {this.setState({Menu: 1}); window.scrollTo(0, 0);}}>
                      &#9432;</button></span></center></td>
                    <td><center>TGO Wochenplan<span className="info">
                    <button onClick={(event) => {this.setState({Menu: 2}); window.scrollTo(0, 0);}}>
                      &#9432;</button></span></center></td>
                    <td><center>TGO All-in<span className="info">
                    <button onClick={(event) => {this.setState({Menu: 3}); window.scrollTo(0, 0);}}>
                      &#9432;</button></span></center></td>
                  </tr>
                  <tr>
                  <td>Wochenplanberechnung</td>
                  <td><div className="produkt">&#10006;</div></td>
                  <td><div className="produkt">&#10003;</div></td>
                  <td><div className="produkt">&#10003;</div></td>
                  </tr>
                  <tr>
                  <td>Digitalisierter Anmeldeprozess</td>
                  <td><div className="produkt">&#10003;</div></td>
                  <td><div className="produkt">&#10006;</div></td>
                  <td><div className="produkt">&#10003;</div></td>
                  </tr>
                  <tr>
                  <td>Eigene URL für die Anmeldung</td>
                  <td><div className="produkt">&#10003;</div></td>
                  <td><div className="produkt">&#10006;</div></td>
                  <td><div className="produkt">&#10003;</div></td>
                  </tr>
                  <tr>
                  <td>Anmeldungen/Wochenplan als Excel-Datei</td>
                  <td><div className="produkt">&#10003;</div></td>
                  <td><div className="produkt">&#10003;</div></td>
                  <td><div className="produkt">&#10003;</div></td>
                  </tr>
                  <tr>
                  <td>Kostenloser technischer Support</td>
                  <td><div className="produkt">&#10003;</div></td>
                  <td><div className="produkt">&#10003;</div></td>
                  <td><div className="produkt">&#10003;</div></td>
                  </tr>
                  <tr>
                  <td>Laufzeit</td>
                  <td><div className="produkt">1 Saison</div></td>
                  <td><div className="produkt">1 Saison</div></td>
                  <td><div className="produkt">1 Saison</div></td>
                  </tr>
                  <tr><td>&nbsp;</td></tr>
                  <tr>
                    <td>Einmaliger Preis (zzgl. Mehrwertsteuer)</td>
                    <td><center>0,50€/Person</center></td>
                    <td><center>0,80€/Person</center></td>
                    <td><center>1,00€/Person</center></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td><center><div className="normal">
                      <button
                        onClick={(event) => {document.location.href="/produktkaufAnmeldung"}}>
                        Jetzt kaufen
                      </button></div></center></td>
                    <td><center><div className="normal">
                      <button
                        onClick={(event) => {document.location.href="/produktkaufWochenplan"}}>
                        Jetzt kaufen
                      </button></div></center></td>
                    <td><center><div className="normal">
                      <button
                        onClick={(event) => {document.location.href="/produktkaufAllin"}}>
                        Jetzt kaufen
                      </button></div></center></td>
                  </tr>
                  <tr><td>&nbsp;</td></tr>
                  <tr>
                    <td>
                      <div className="normal">
                        <button
                        onClick={(event) => {document.location.href="/produktkaufgratis"}}>
                          1 Saison kostenlos testen
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
    }
    else {
      if(this.state.Menu === 1) {
        return(
          <div>
            <UnderHeaderProdukte />
            <div className="container">
              <li>
                Nachdem Sie das Programm bestellt haben, erhalten Sie Ihre
                Zugangsdaten per Mail. Über das Feld "Login" in der obigen Menüleiste
                können Sie sich anschließend mit Ihrem Account einloggen.
              </li>
              <br/>
              <li>
                Hier legen Sie zunächt einige Details für das Anmeldeformular fest,
                über welches sich Ihre Schüler zum Training anmelden. Diese sind dem folgenden
                Bild zu entnehmen:
                <div className="xscroll"><div className="ErstellungAnmeldeformulargross"></div></div>
              </li>
              <br/>
              <li>
                Ihre Schüler können sich nun über den Link
                "tennisgrouporganizer.com/IHRVEREIN" für Ihr Training anmelden.
              </li>
              <br/>
              <li>
                In Ihrem Login-Bereich haben Sie eine Live-Ansicht von allen Schülern, die
                sich bereits für Ihr Training angemeldet haben. Zusätlich haben Sie jederzeit
                die Möglichkeit diese Daten als Excel-Datei zu exportieren.
              </li>
              <br/>
              <li>
                Sie können auch gerne <a href="/kontakt"><u>Kontakt</u></a> mit uns aufnehmen,
                dann erklären wir Ihnen gerne alle Funktionen und beantworten Ihre Fragen.
              </li>

              <br/>
              <br/>

              <div className="normal">
                <button
                  onClick={(event) => {this.setState({Menu: 0}); window.scrollTo(0, 0);}}>
                  Zurück
                </button>
              </div>
            </div>
          </div>
        );
      }
      else {
        if(this.state.Menu === 2) {
          return(
            <div>
              <UnderHeaderProdukte />
              <div className="container">
                <li>
                  Nachdem Sie das Programm bestellt haben, erhalten Sie Ihre
                  Zugangsdaten per Mail. Über das Feld "Login" in der obigen Menüleiste
                  können Sie sich anschließend mit Ihrem Account einloggen.
                </li>
                <br/>
                <li>
                  Sofort können Sie damit beginnen, die relevanten Daten einzutragen, die unser
                  Programm benötigt, um Ihren Wochenplan berechnen zu können. Einen Überblick
                  darüber, welche Daten eingegeben werden müssen und welche Funktionen unser
                  Programm bietet, können Sie sich mit
                  unserer <a href="/demo"><u>Demo</u></a> verschaffen.
                </li>
                <br/>
                <li>
                  Sobald Ihr Wochenplan berechnet wurde erhalten Sie diesen automatisch als
                  Excel-Datei per Mail.
                </li>
                <br/>
                <li>
                  Sie können auch gerne <a href="/kontakt"><u>Kontakt</u></a> mit uns aufnehmen,
                  dann erklären wir Ihnen gerne alle Funktionen unseres Programms und beantworten
                  Ihre Fragen.
                </li>

                <br/>
                <br/>

                <div className="normal">
                  <button
                    onClick={(event) => {this.setState({Menu: 0}); window.scrollTo(0, 0);}}>
                    Zurück
                  </button>
                </div>
              </div>
            </div>
          );
        }
        else {
          if(this.state.Menu === 3) {
            return(
              <div>
                <UnderHeaderProdukte />
                <div className="container">
                  <li>
                    Nachdem Sie das Programm bestellt haben, erhalten Sie Ihre
                    Zugangsdaten per Mail. Über das Feld "Login" in der obigen Menüleiste
                    können Sie sich anschließend mit Ihrem Account einloggen.
                  </li>
                  <br/>
                  <li>
                    Hier legen Sie zunächt einige Details für das Anmeldeformular fest,
                    über welches sich Ihre Schüler zum Training anmelden. Diese sind dem folgenden
                    Bild zu entnehmen:
                    <div className="xscroll"><div className="ErstellungAnmeldeformulargross"></div></div>
                  </li>
                  <br/>
                  <li>
                    Ihre Schüler können sich nun über den Link
                    "tennisgrouporganizer.com/IHRVEREIN" für Ihr Training anmelden.
                  </li>
                  <br/>
                  <li>
                    In Ihrem Login-Bereich haben Sie eine Live-Ansicht von allen Schülern, die
                    sich bereits für Ihr Training angemeldet haben. Zusätlich haben Sie jederzeit
                    die Möglichkeit diese Daten als Excel-Datei zu exportieren.
                  </li>
                  <br/>
                  <li>
                    Haben sich alle Schüler angemeldet, so können Sie die Wochenplanberechnung
                    starten. Dazu teilen Sie Ihre Schüler in Spielstärken ein, sodass unser Algorithmus
                    homogene Gruppen bilden kann. Danach können Sie bspw. noch Wünsche Ihrer Schüler
                    angeben. Einen Überblick über weitere Funktionen unseres Programms können Sie sich
                    mit unserer <a href="/demo"><u>Demo</u></a> verschaffen.
                  </li>
                  <br/>
                  <li>
                    Sobald Ihr Wochenplan berechnet wurde erhalten Sie diesen automatisch als
                    Excel-Datei per Mail.
                  </li>
                  <br/>
                  <li>
                    Sie können auch gerne <a href="/kontakt"><u>Kontakt</u></a> mit uns aufnehmen,
                    dann erklären wir Ihnen gerne alle Funktionen unseres Programms und beantworten
                    Ihre Fragen.
                  </li>

                  <br/>
                  <br/>

                  <div className="normal">
                    <button
                      onClick={(event) => {this.setState({Menu: 0}); window.scrollTo(0, 0);}}>
                      Zurück
                    </button>
                  </div>
                </div>
              </div>
            );
          }
          else {
            if(this.state.Menu === 4) {
              if(window.screen.availWidth > 700) {
                return (
                  <div>
                    <UnderHeaderProdukte />
                      <div className="container">
                        <p>
                            Für eine detailiertere Erklärung testen Sie gerne <a href="/demo"><u>hier</u></a> unsere
                            kostenlose Demo.
                        </p>
          
                        <br/>
                        <br/>
          
                        <div className="SchrittErklaerung">
          
                        </div>
          
                        <br/>
                        <br/>
          
                        <p className="normal">
                          <button onClick={() => this.setState({Menu: 2})}>
                            Zurück
                          </button>
                        </p>
          
                        <br/>
                        <br/>
          
                      </div>
                  </div>
                );
              }
              else {
                if(window.screen.availWidth > 660) {
                  return(
                    <div>
                      <UnderHeaderProdukte />
                        <div className="container">
                          <p>
                              Für eine detailiertere Erklärung testen Sie gerne <a href="/demo"><u>hier</u></a> unsere
                              kostenlose Demo.
                          </p>
          
                          <br/>
          
                          <div className="SchrittErklaerungHoch600px">
          
                          </div>
          
                          <br/>
          
                          <p className="normal">
                            <button onClick={() => this.setState({Menu: 2})}>
                              Zurück
                            </button>
                          </p>
          
                          <br/>
          
                        </div>
                    </div>
                  );
                }
                else {
                  if(window.screen.availWidth > 560) {
                    return(
                      <div>
                        <UnderHeaderProdukte />
                          <div className="container">
                            <p>
                                Für eine detailiertere Erklärung testen Sie gerne <a href="/demo"><u>hier</u></a> unsere
                                kostenlose Demo.
                            </p>
          
                            <br/>
            
                            <div className="SchrittErklaerungHoch500px">
            
                            </div>
          
                            <br/>
          
                            <p className="normal">
                              <button onClick={() => this.setState({Menu: 2})}>
                                Zurück
                              </button>
                            </p>
          
                            <br/>
          
                          </div>
                      </div>
                    );
                  }
                  else {
                    if(window.screen.availWidth > 460) {
                      return(
                        <div>
                          <UnderHeaderProdukte />
                            <div className="container">
                              <p>
                                  Für eine detailiertere Erklärung testen Sie gerne <a href="/demo"><u>hier</u></a> unsere
                                  kostenlose Demo.
                              </p>
          
                              <br/>
              
                              <div className="SchrittErklaerungHoch400px">
              
                              </div>
          
                              <br/>
          
                              <p className="normal">
                                <button onClick={() => this.setState({Menu: 2})}>
                                  Zurück
                                </button>
                              </p>
          
                              <br/>
                              
                          
                            </div>
                        </div>
                      );
                    }
                    else {
                      if(window.screen.availWidth > 360) {
                        return(
                          <div>
                            <UnderHeaderProdukte />
                              <div className="container">
                                <p>
                                    Für eine detailiertere Erklärung testen Sie gerne <a href="/demo"><u>hier</u></a> unsere
                                    kostenlose Demo.
                                </p>
          
                                <br/>
                
                                <div className="SchrittErklaerungHoch300px">
                
                                </div>
          
                                <br/>
          
                                <p className="normal">
                                  <button onClick={() => this.setState({Menu: 2})}>
                                    Zurück
                                  </button>
                                </p>
          
                                <br/>
                                
                              </div>
                          </div>
                        );
                      }
                      else {
                        return(
                          <div>
                            <UnderHeaderProdukte />
                              <div className="container">
                                <p>
                                    Für eine detailiertere Erklärung testen Sie gerne <a href="/demo"><u>hier</u></a> unsere
                                    kostenlose Demo.
                                </p>
          
                                <br/>
                
                                <div className="SchrittErklaerungHoch250px">
                
                                </div>
          
                                <br/>
          
                                <p className="normal">
                                  <button onClick={() => this.setState({Menu: 2})}>
                                    Zurück
                                  </button>
                                </p>
          
                                <br/>
          
                              </div>
                          </div>
                        );
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      
    }
  }

}
