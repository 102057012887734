import React from "react";

export function TopfWunschMoeglich(trainingseinheitentopfx, bevorzugteTopfgroessetopfx, alternativeTopfgroessetopfx) {
    if(trainingseinheitentopfx === "0") {
        return("Wählen Sie in diesem Topf mindestens eine Trainingseinheit aus oder reduzieren Sie die Anzahl der Töpfe!");
    }
    else {
        if(alternativeTopfgroessetopfx === "") {
            return("Wählen Sie eine alternative Gruppengröße!");
        }
        else {
            if(trainingseinheitentopfx === "1") {
                if(bevorzugteTopfgroessetopfx === "1") {
                    //OK
                }
                else {
                    return( "Es würde klappen, wenn Sie hier die bevorzugte Gruppengröße auf 1 setzen!");
                }
            }
            else {
                if(trainingseinheitentopfx === "2") {
                    if(bevorzugteTopfgroessetopfx === "1") {
                        //OK
                    }
                    else {
                        return( "Es würde klappen, wenn Sie hier die bevorzugte Gruppengröße auf 1 setzen!");
                    }
                }
                else {
                    if(trainingseinheitentopfx === "3") {
                        if(bevorzugteTopfgroessetopfx === "3" || bevorzugteTopfgroessetopfx === "1") {
                            //OK
                        }
                        else {
                            return( "Es würde klappen, wenn Sie hier die bevorzugte Gruppengröße auf 1 oder 3 setzen!");
                        }
                    }
                    else {
                        if(trainingseinheitentopfx === "4") {
                            if(bevorzugteTopfgroessetopfx === "4" || bevorzugteTopfgroessetopfx === "1") {
                                //OK
                            }
                            else {
                                return( "Es würde klappen, wenn Sie hier die bevorzugte Gruppengröße auf 1 oder 4 setzen!");
                            }
                        }
                        else {
                            if(trainingseinheitentopfx === "5") {
                                if(bevorzugteTopfgroessetopfx === "5" || bevorzugteTopfgroessetopfx === "1") {
                                    //OK
                                }
                                else {
                                    return( "Es würde klappen, wenn Sie hier die bevorzugte Gruppengröße auf 1 oder 5 setzen!");
                                }
                            }
                            else {
                                if(trainingseinheitentopfx === "6") {
                                    if(bevorzugteTopfgroessetopfx === "3" || bevorzugteTopfgroessetopfx === "6" || bevorzugteTopfgroessetopfx === "1") {
                                        //OK
                                    }
                                    else {
                                        return( "Es würde klappen, wenn Sie hier die bevorzugte Gruppengröße auf 1, 3 oder 6 setzen!");
                                    }
                                }
                                else {
                                    if(trainingseinheitentopfx === "7") {
                                        if(bevorzugteTopfgroessetopfx === "3" || bevorzugteTopfgroessetopfx === "1") {
                                            // Ok
                                        }
                                        else {
                                            if(bevorzugteTopfgroessetopfx === "4") {
                                                if(alternativeTopfgroessetopfx === "3") {
                                                    //ok
                                                }
                                                else {
                                                    return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 3 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3 oder 7 setzen!");
                                                }
                                            }
                                            else {
                                                if(bevorzugteTopfgroessetopfx === "7") {
                                                    //ok
                                                }
                                                else {
                                                    return( "Es würde klappen, wenn Sie hier die bevorzugte Gruppengröße auf 1, 3, 4 oder 7 setzen!");
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        if(trainingseinheitentopfx === "8") {
                                            if(bevorzugteTopfgroessetopfx === "4" || bevorzugteTopfgroessetopfx === "8" || bevorzugteTopfgroessetopfx === "1") {
                                                //ok
                                            }
                                            else {
                                                return( "Es würde klappen, wenn Sie hier die bevorzugte Gruppengröße auf 1, 4 oder 8 setzen!");
                                            }
                                        }
                                        else {
                                            if(trainingseinheitentopfx === "9") {
                                                if(bevorzugteTopfgroessetopfx === "3" || bevorzugteTopfgroessetopfx === "1") {
                                                    //ok
                                                }
                                                else {
                                                    if(bevorzugteTopfgroessetopfx === "4") {
                                                        if(alternativeTopfgroessetopfx === "5") {
                                                            //ok
                                                        }
                                                        else {
                                                            return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 5 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3 oder 5 setzen!");
                                                        }
                                                    }
                                                    else {
                                                        if(bevorzugteTopfgroessetopfx === "5") {
                                                            if(alternativeTopfgroessetopfx === "4") {
                                                                //ok
                                                            }
                                                            else {
                                                                return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 4 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3 oder 4 setzen!");
                                                            }
                                                        }
                                                        else {
                                                            return( "Es würde klappen, wenn Sie hier die bevorzugte Gruppengröße auf 1, 3, 4 oder 5 setzen!");
                                                        }
                                                    }
                                                }
                                            }
                                            else {
                                                if(trainingseinheitentopfx === "10") {
                                                    if(bevorzugteTopfgroessetopfx === "3" || bevorzugteTopfgroessetopfx === "1") {
                                                        //ok
                                                    }
                                                    else {
                                                        if(bevorzugteTopfgroessetopfx === "4") {
                                                            if(alternativeTopfgroessetopfx === "3") {
                                                                //ok
                                                            }
                                                            else {
                                                                return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 3 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3 oder 5 setzen!");
                                                            }
                                                        }
                                                        else {
                                                            if(bevorzugteTopfgroessetopfx === "5") {
                                                                //ok
                                                            }
                                                            else {
                                                                return( "Es würde klappen, wenn Sie hier die bevorzugte Gruppengröße auf 1, 3, 4 oder 5 setzen!");
                                                            }
                                                        }
                                                    }
                                                }
                                                else {
                                                    if(trainingseinheitentopfx === "11") {
                                                        if(bevorzugteTopfgroessetopfx === "3" || bevorzugteTopfgroessetopfx === "1") {
                                                            //ok
                                                        }
                                                        else {
                                                            if(bevorzugteTopfgroessetopfx === "4") {
                                                                if(alternativeTopfgroessetopfx === "3") {
                                                                    //ok
                                                                }
                                                                else {
                                                                    return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 3 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 5 oder 6 setzen!");
                                                                }
                                                            }
                                                            else {
                                                                if(bevorzugteTopfgroessetopfx === "5") {
                                                                    if(alternativeTopfgroessetopfx === "6") {
                                                                        //ok
                                                                    }
                                                                    else {
                                                                        return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 6 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4 oder 6 setzen!");
                                                                    }
                                                                }
                                                                else {
                                                                    if(bevorzugteTopfgroessetopfx === "6") {
                                                                        if(alternativeTopfgroessetopfx === "5") {
                                                                            //ok
                                                                        }
                                                                        else {
                                                                            return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 5 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4 oder 5 setzen!");
                                                                        }
                                                                    }
                                                                    else {
                                                                        return( "Es würde klappen, wenn Sie hier die bevorzugte Gruppengröße auf 1, 3, 4, 5 oder 6 setzen!");
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                    else {
                                                        if(trainingseinheitentopfx === "12") {
                                                            if(bevorzugteTopfgroessetopfx === "3" || bevorzugteTopfgroessetopfx === "1") {
                                                                //ok
                                                            }
                                                            else {
                                                                if(bevorzugteTopfgroessetopfx === "4") {
                                                                    //ok
                                                                }
                                                                else {
                                                                    if(bevorzugteTopfgroessetopfx === "6") {
                                                                        //ok
                                                                    }
                                                                    else {
                                                                        return( "Es würde klappen, wenn Sie hier die bevorzugte Gruppengröße auf 1, 3, 4 oder 6 setzen!");
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        else {
                                                            if(trainingseinheitentopfx === "13") {
                                                                if(bevorzugteTopfgroessetopfx === "3" || bevorzugteTopfgroessetopfx === "1") {
                                                                    //ok
                                                                }
                                                                else {
                                                                    if(bevorzugteTopfgroessetopfx === "4") {
                                                                        //ok
                                                                    }
                                                                    else {
                                                                        if(bevorzugteTopfgroessetopfx === "5") {
                                                                            if(alternativeTopfgroessetopfx === "4") {
                                                                                //ok
                                                                            }
                                                                            else {
                                                                                return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 4 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, 6 oder 7 setzen!");
                                                                            }
                                                                        }
                                                                        else {
                                                                            if(bevorzugteTopfgroessetopfx === "6") {
                                                                                if(alternativeTopfgroessetopfx === "7") {
                                                                                    //ok
                                                                                }
                                                                                else {
                                                                                    return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 7 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, 5 oder 7 setzen!");
                                                                                }
                                                                            }
                                                                            else {
                                                                                if(bevorzugteTopfgroessetopfx === "7") {
                                                                                    if(alternativeTopfgroessetopfx === "6") {
                                                                                        //ok
                                                                                    }
                                                                                    else {
                                                                                        return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 6 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, 5 oder 6 setzen!");
                                                                                    }
                                                                                }
                                                                                else {
                                                                                    return( "Es würde klappen, wenn Sie hier die bevorzugte Gruppengröße auf 1, 3, 4, 5, 6 oder 7 setzen!");
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            else {
                                                                if(trainingseinheitentopfx === "14") {
                                                                    if(bevorzugteTopfgroessetopfx === "3" || bevorzugteTopfgroessetopfx === "1") {
                                                                        //ok
                                                                    }
                                                                    else {
                                                                        if(bevorzugteTopfgroessetopfx === "4") {
                                                                            //ok
                                                                        }
                                                                        else {
                                                                            if(bevorzugteTopfgroessetopfx === "5") {
                                                                                if(alternativeTopfgroessetopfx === "4") {
                                                                                    //ok
                                                                                }
                                                                                else {
                                                                                    return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 4 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4 oder 7 setzen!");
                                                                                }
                                                                            }
                                                                            else {
                                                                                if(bevorzugteTopfgroessetopfx === "7") {
                                                                                    //ok
                                                                                }
                                                                                else {
                                                                                    return( "Es würde klappen, wenn Sie hier die bevorzugte Gruppengröße auf 1, 3, 4, 5 oder 7 setzen!");
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                else {
                                                                    if(trainingseinheitentopfx === "15") {
                                                                        if(bevorzugteTopfgroessetopfx === "3" || bevorzugteTopfgroessetopfx === "1") {
                                                                            //ok
                                                                        }
                                                                        else {
                                                                            if(bevorzugteTopfgroessetopfx === "4") {
                                                                                if(alternativeTopfgroessetopfx === "3") {
                                                                                    //ok
                                                                                }
                                                                                else {
                                                                                    return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 3 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 5, 7 oder 8 setzen!");
                                                                                }
                                                                            }
                                                                            else {
                                                                                if(bevorzugteTopfgroessetopfx === "5") {
                                                                                    //ok
                                                                                }
                                                                                else {
                                                                                    if(bevorzugteTopfgroessetopfx === "7") {
                                                                                        if(alternativeTopfgroessetopfx === "8") {
                                                                                            //ok
                                                                                        }
                                                                                        else {
                                                                                            return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 8 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, 5 oder 8 setzen!");
                                                                                        }
                                                                                    }
                                                                                    else {
                                                                                        if(bevorzugteTopfgroessetopfx === "8") {
                                                                                            //ok
                                                                                        }
                                                                                        else {
                                                                                            return( "Es würde klappen, wenn Sie hier die bevorzugte Gruppengröße auf 1, 3, 4, 5, 7 oder 8 setzen!");
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                    else {
                                                                        if(trainingseinheitentopfx === "16") {
                                                                            if(bevorzugteTopfgroessetopfx === "3" || bevorzugteTopfgroessetopfx === "1") {
                                                                                //ok
                                                                            }
                                                                            else {
                                                                                if(bevorzugteTopfgroessetopfx === "4") {
                                                                                    //ok
                                                                                }
                                                                                else {
                                                                                    if(bevorzugteTopfgroessetopfx === "5") {
                                                                                        if(alternativeTopfgroessetopfx === "6") {
                                                                                            //ok
                                                                                        }
                                                                                        else {
                                                                                            return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 6 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, 6 oder 8 setzen!");
                                                                                        }
                                                                                    }
                                                                                    else {
                                                                                        if(bevorzugteTopfgroessetopfx === "6") {
                                                                                            if(alternativeTopfgroessetopfx === "5") {
                                                                                                //ok
                                                                                            }
                                                                                            else {
                                                                                                return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 5 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, 5 oder 8 setzen!");
                                                                                            }
                                                                                        }
                                                                                        else {
                                                                                            if(bevorzugteTopfgroessetopfx === "8") {
                                                                                                
                                                                                            }
                                                                                            else {
                                                                                                return( "Es würde klappen, wenn Sie hier die bevorzugte Gruppengröße auf 1, 3, 4, 5, 6 oder 8 setzen!");
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                        else {
                                                                            if(trainingseinheitentopfx === "17") {
                                                                                if(bevorzugteTopfgroessetopfx === "3" || bevorzugteTopfgroessetopfx === "1") {
                                                                                    //ok
                                                                                }
                                                                                else {
                                                                                    if(bevorzugteTopfgroessetopfx === "4") {
                                                                                        
                                                                                    }
                                                                                    else {
                                                                                        if(bevorzugteTopfgroessetopfx === "5") {
                                                                                            
                                                                                        }
                                                                                        else {
                                                                                            if(bevorzugteTopfgroessetopfx === "6") {
                                                                                                if(alternativeTopfgroessetopfx === "5") {
                                                                                                    //ok
                                                                                                }
                                                                                                else {
                                                                                                    return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 5 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, oder 5 setzen!");
                                                                                                }
                                                                                            }
                                                                                            else {
                                                                                                return( "Es würde klappen, wenn Sie hier die bevorzugte Gruppengröße auf 1, 3, 4, 5 oder 6 setzen!");
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                            else {
                                                                                if(trainingseinheitentopfx === "18") {
                                                                                    if(bevorzugteTopfgroessetopfx === "3" || bevorzugteTopfgroessetopfx === "1") {
                                                                                        
                                                                                    }
                                                                                    else {
                                                                                        if(bevorzugteTopfgroessetopfx === "4") {
                                                                                            
                                                                                        }
                                                                                        else {
                                                                                            if(bevorzugteTopfgroessetopfx === "5") {
                                                                                                if(alternativeTopfgroessetopfx === "4") {
                                                                                                    //ok
                                                                                                }
                                                                                                else {
                                                                                                    return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 4 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, oder 6 setzen!");
                                                                                                }
                                                                                            }
                                                                                            else {
                                                                                                if(bevorzugteTopfgroessetopfx === "6") {
                                                                                                    
                                                                                                }
                                                                                                else {
                                                                                                    return( "Es würde klappen, wenn Sie hier die bevorzugte Gruppengröße auf 1, 3, 4, 5 oder 6 setzen!");
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                                else {
                                                                                    if(trainingseinheitentopfx === "19") {
                                                                                        if(bevorzugteTopfgroessetopfx === "3" || bevorzugteTopfgroessetopfx === "1") {
                                                                                            //ok
                                                                                        }
                                                                                        else {
                                                                                            if(bevorzugteTopfgroessetopfx === "4") {
                                                                                                
                                                                                            }
                                                                                            else {
                                                                                                if(bevorzugteTopfgroessetopfx === "5") {
                                                                                                    if(alternativeTopfgroessetopfx === "4") {
                                                                                                        //ok
                                                                                                    }
                                                                                                    else {
                                                                                                        return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 4 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, 6 oder 7 setzen!");
                                                                                                    }
                                                                                                }
                                                                                                else {
                                                                                                    if(bevorzugteTopfgroessetopfx === "6") {
                                                                                                        if(alternativeTopfgroessetopfx === "7") {
                                                                                                            //ok
                                                                                                        }
                                                                                                        else {
                                                                                                            return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 7 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, 5 oder 7 setzen!");
                                                                                                        }
                                                                                                    }
                                                                                                    else {
                                                                                                        if(bevorzugteTopfgroessetopfx === "7") {
                                                                                                            if(alternativeTopfgroessetopfx === "6") {
                                                                                                                //ok
                                                                                                            }
                                                                                                            else {
                                                                                                                return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 6 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, 5 oder 6 setzen!");
                                                                                                            }
                                                                                                        }
                                                                                                        else {
                                                                                                            return( "Es würde klappen, wenn Sie hier die bevorzugte Gruppengröße auf 1, 3, 4, 5, 6 oder 7 setzen!");
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                    else {
                                                                                        if(trainingseinheitentopfx === "20") {
                                                                                            if(bevorzugteTopfgroessetopfx === "3" || bevorzugteTopfgroessetopfx === "1") {
                                                                                                //ok
                                                                                            }
                                                                                            else {
                                                                                                if(bevorzugteTopfgroessetopfx === "4") {
                                                                                                    
                                                                                                }
                                                                                                else {
                                                                                                    if(bevorzugteTopfgroessetopfx === "5") {
                                                                                                        
                                                                                                    }
                                                                                                    else {
                                                                                                        if(bevorzugteTopfgroessetopfx === "6") {
                                                                                                            if(alternativeTopfgroessetopfx === "7") {
                                                                                                                //ok
                                                                                                            }
                                                                                                            else {
                                                                                                                return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 7 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, 5 oder 7 setzen!");
                                                                                                            }
                                                                                                        }
                                                                                                        else {
                                                                                                            if(bevorzugteTopfgroessetopfx === "7") {
                                                                                                                if(alternativeTopfgroessetopfx === "6") {
                                                                                                                    //ok
                                                                                                                }
                                                                                                                else {
                                                                                                                    return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 6 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, 5 oder 6 setzen!");
                                                                                                                }
                                                                                                            }
                                                                                                            else {
                                                                                                                return( "Es würde klappen, wenn Sie hier die bevorzugte Gruppengröße auf 1, 3, 4, 5, 6 oder 7 setzen!");
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                        else {
                                                                                            if(trainingseinheitentopfx === "21") {
                                                                                                if(bevorzugteTopfgroessetopfx === "3" || bevorzugteTopfgroessetopfx === "1") {
                                                                                                    //ok
                                                                                                }
                                                                                                else {
                                                                                                    if(bevorzugteTopfgroessetopfx === "4") {
                                                                                                        
                                                                                                    }
                                                                                                    else {
                                                                                                        if(bevorzugteTopfgroessetopfx === "5") {
                                                                                                            if(alternativeTopfgroessetopfx === "6") {
                                                                                                                //ok
                                                                                                            }
                                                                                                            else {
                                                                                                                return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 6 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, 6 oder 7 setzen!");
                                                                                                            }
                                                                                                        }
                                                                                                        else {
                                                                                                            if(bevorzugteTopfgroessetopfx === "6") {
                                                                                                                if(alternativeTopfgroessetopfx === "5") {
                                                                                                                    //ok
                                                                                                                }
                                                                                                                else {
                                                                                                                    return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 5 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, 5 oder 7 setzen!");
                                                                                                                }
                                                                                                            }
                                                                                                            else {
                                                                                                                if(bevorzugteTopfgroessetopfx === "7") {
                                                                                                                    
                                                                                                                }
                                                                                                                else {
                                                                                                                    return( "Es würde klappen, wenn Sie hier die bevorzugte Gruppengröße auf 1, 3, 4, 5, 6 oder 7 setzen!");
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                            else {
                                                                                                if(trainingseinheitentopfx === "22") {
                                                                                                    if(bevorzugteTopfgroessetopfx === "3" || bevorzugteTopfgroessetopfx === "1") {
                                                                                                        //ok
                                                                                                    }
                                                                                                    else {
                                                                                                        if(bevorzugteTopfgroessetopfx === "4") {
                                                                                                            //ok
                                                                                                        }
                                                                                                        else {
                                                                                                            if(bevorzugteTopfgroessetopfx === "5") {
                                                                                                                
                                                                                                            }
                                                                                                            else {
                                                                                                                if(bevorzugteTopfgroessetopfx === "6") {
                                                                                                                    if(alternativeTopfgroessetopfx === "5") {
                                                                                                                        //ok
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 5 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, 5, 7 oder 8 setzen!");
                                                                                                                    }
                                                                                                                }
                                                                                                                else {
                                                                                                                    if(bevorzugteTopfgroessetopfx === "7") {
                                                                                                                        if(alternativeTopfgroessetopfx === "8") {
                                                                                                                            //ok
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 7 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, 5, 6 oder 8 setzen!");
                                                                                                                        }
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        if(bevorzugteTopfgroessetopfx === "8") {
                                                                                                                            
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                                else {
                                                                                                    if(trainingseinheitentopfx === "23") {
                                                                                                        if(bevorzugteTopfgroessetopfx === "3" || bevorzugteTopfgroessetopfx === "1") {
                                                                                                            //ok
                                                                                                        }
                                                                                                        else {
                                                                                                            if(bevorzugteTopfgroessetopfx === "4") {
                                                                                                                //ok
                                                                                                            }
                                                                                                            else {
                                                                                                                if(bevorzugteTopfgroessetopfx === "5") {
                                                                                                                    
                                                                                                                }
                                                                                                                else {
                                                                                                                    if(bevorzugteTopfgroessetopfx === "6") {
                                                                                                                        if(alternativeTopfgroessetopfx === "5") {
                                                                                                                            //ok
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 5 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, 5, 7 oder 8 setzen!");
                                                                                                                        }
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        if(bevorzugteTopfgroessetopfx === "7") {
                                                                                                                            if(alternativeTopfgroessetopfx === "8") {
                                                                                                                                //ok
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 8 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, 5, 6 oder 8 setzen!");
                                                                                                                            }
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            if(bevorzugteTopfgroessetopfx === "8") {
                                                                                                                                
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                    else {
                                                                                                        if(trainingseinheitentopfx === "24") {
                                                                                                            if(bevorzugteTopfgroessetopfx === "3" || bevorzugteTopfgroessetopfx === "1") {
                                                                                                                //ok
                                                                                                            }
                                                                                                            else {
                                                                                                                if(bevorzugteTopfgroessetopfx === "4") {
                                                                                                                    //ok
                                                                                                                }
                                                                                                                else {
                                                                                                                    if(bevorzugteTopfgroessetopfx === "5") {
                                                                                                                        if(alternativeTopfgroessetopfx === "4") {
                                                                                                                            //ok
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 4 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, 6 oder 8 setzen!");
                                                                                                                        }
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        if(bevorzugteTopfgroessetopfx === "6") {
                                                                                                                            
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            if(bevorzugteTopfgroessetopfx === "8") {
                                                                                                                                
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                return( "Es würde klappen, wenn Sie hier die bevorzugte Gruppengröße auf 1, 3, 4, 5, 6 oder 8 setzen!");
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                        else {
                                                                                                            if(trainingseinheitentopfx === "25") {
                                                                                                                if(bevorzugteTopfgroessetopfx === "3" || bevorzugteTopfgroessetopfx === "1") {
                                                                                                                    //ok
                                                                                                                }
                                                                                                                else {
                                                                                                                    if(bevorzugteTopfgroessetopfx === "4") {
                                                                                                                        //ok
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        if(bevorzugteTopfgroessetopfx === "5") {
                                                                                                                            
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            if(bevorzugteTopfgroessetopfx === "6") {
                                                                                                                                if(alternativeTopfgroessetopfx === "7") {
                                                                                                                                    //ok
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 7 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, 5 oder 7 setzen!");
                                                                                                                                }
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                if(bevorzugteTopfgroessetopfx === "7") {
                                                                                                                                    if(alternativeTopfgroessetopfx === "6") {
                                                                                                                                        //ok
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 6 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, 5 oder 6 setzen!");
                                                                                                                                    }
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    return( "Es würde klappen, wenn Sie hier die bevorzugte Gruppengröße auf 1, 3, 4, 5, 6 oder 7 setzen!");
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                            else {
                                                                                                                if(trainingseinheitentopfx === "26") {
                                                                                                                    if(bevorzugteTopfgroessetopfx === "3" || bevorzugteTopfgroessetopfx === "1") {
                                                                                                                        //ok
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        if(bevorzugteTopfgroessetopfx === "4") {
                                                                                                                            //ok
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            if(bevorzugteTopfgroessetopfx === "5") {
                                                                                                                                
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                if(bevorzugteTopfgroessetopfx === "6") {
                                                                                                                                    
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    if(bevorzugteTopfgroessetopfx === "7") {
                                                                                                                                        if(alternativeTopfgroessetopfx === "6") {
                                                                                                                                            //ok
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 6 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, 5 oder 6 setzen!");
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        return( "Es würde klappen, wenn Sie hier die bevorzugte Gruppengröße auf 1, 3, 4, 5, 6 oder 7 setzen!");
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                                else {
                                                                                                                    if(trainingseinheitentopfx === "27") {
                                                                                                                        if(bevorzugteTopfgroessetopfx === "3" || bevorzugteTopfgroessetopfx === "1") {
                                                                                                                            //ok
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            if(bevorzugteTopfgroessetopfx === "4") {
                                                                                                                                //ok
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                if(bevorzugteTopfgroessetopfx === "5") {
                                                                                                                                    
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    if(bevorzugteTopfgroessetopfx === "6") {
                                                                                                                                        
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        if(bevorzugteTopfgroessetopfx === "7") {
                                                                                                                                            if(alternativeTopfgroessetopfx === "6") {
                                                                                                                                                //ok
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 6 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, 5 oder 6 setzen!");
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            return( "Es würde klappen, wenn Sie hier die bevorzugte Gruppengröße auf 1, 3, 4, 5, 6 oder 7 setzen!");
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        if(trainingseinheitentopfx === "28") {
                                                                                                                            if(bevorzugteTopfgroessetopfx === "3" || bevorzugteTopfgroessetopfx === "1") {
                                                                                                                                //ok
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                if(bevorzugteTopfgroessetopfx === "4") {
                                                                                                                                    //ok
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    if(bevorzugteTopfgroessetopfx === "5") {
                                                                                                                                        //ok
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        if(bevorzugteTopfgroessetopfx === "6") {
                                                                                                                                            if(alternativeTopfgroessetopfx === "5") {
                                                                                                                                                //ok
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 5 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, 5 oder 7 setzen!");
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            if(bevorzugteTopfgroessetopfx === "7") {
                                                                                                                                                
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                return( "Es würde klappen, wenn Sie hier die bevorzugte Gruppengröße auf 1, 3, 4, 5, 6 oder 7 setzen!");
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            if(trainingseinheitentopfx === "29") {
                                                                                                                                if(bevorzugteTopfgroessetopfx === "3" || bevorzugteTopfgroessetopfx === "1") {
                                                                                                                                    //ok
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    if(bevorzugteTopfgroessetopfx === "4") {
                                                                                                                                        //ok
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        if(bevorzugteTopfgroessetopfx === "5") {
                                                                                                                                            //ok
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            if(bevorzugteTopfgroessetopfx === "6") {
                                                                                                                                                if(alternativeTopfgroessetopfx === "5") {
                                                                                                                                                    //ok
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                    return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 5 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, 5, 7 oder 8 setzen!");
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                if(bevorzugteTopfgroessetopfx === "7") {
                                                                                                                                                    if(alternativeTopfgroessetopfx === "8") {
                                                                                                                                                        //ok
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 8 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, 5, 6 oder 8 setzen!");
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                                if(bevorzugteTopfgroessetopfx === "8") {
                                                                                                                                                    
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                if(trainingseinheitentopfx === "30") {
                                                                                                                                    if(bevorzugteTopfgroessetopfx === "3" || bevorzugteTopfgroessetopfx === "1") {
                                                                                                                                        //ok
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        if(bevorzugteTopfgroessetopfx === "4") {
                                                                                                                                            //ok
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            if(bevorzugteTopfgroessetopfx === "5") {
                                                                                                                                                //ok
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                if(bevorzugteTopfgroessetopfx === "6") {
                                                                                                                                                    
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                    if(bevorzugteTopfgroessetopfx === "7") {
                                                                                                                                                        if(alternativeTopfgroessetopfx === "8") {
                                                                                                                                                            //ok
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                            return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 8 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, 5, 6 oder 8 setzen!");
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        if(bevorzugteTopfgroessetopfx === "8") {
                                                                                                                                                            
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    if(trainingseinheitentopfx === "31") {
                                                                                                                                        //Hier geht alles
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        if(trainingseinheitentopfx === "32") {
                                                                                                                                            if(bevorzugteTopfgroessetopfx === "3" || bevorzugteTopfgroessetopfx === "1") {
                                                                                                                                                //ok
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                if(bevorzugteTopfgroessetopfx === "4") {
                                                                                                                                                    //ok
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                    if(bevorzugteTopfgroessetopfx === "5") {
                                                                                                                                                        //ok
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        if(bevorzugteTopfgroessetopfx === "6") {
                                                                                                                                                            
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                            if(bevorzugteTopfgroessetopfx === "7") {
                                                                                                                                                                if(alternativeTopfgroessetopfx === "8") {
                                                                                                                                                                    //ok
                                                                                                                                                                }
                                                                                                                                                                else {
                                                                                                                                                                    return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 8 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, 5, 6 oder 8 setzen!");
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                                if(bevorzugteTopfgroessetopfx === "8") {
                                                                                                                                                                    
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            if(trainingseinheitentopfx === "33") {
                                                                                                                                                if(bevorzugteTopfgroessetopfx === "3" || bevorzugteTopfgroessetopfx === "1") {
                                                                                                                                                    //ok
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                    if(bevorzugteTopfgroessetopfx === "4") {
                                                                                                                                                        //ok
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        if(bevorzugteTopfgroessetopfx === "5") {
                                                                                                                                                            //ok
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                            if(bevorzugteTopfgroessetopfx === "6") {
                                                                                                                                                                
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                                if(bevorzugteTopfgroessetopfx === "7") {
                                                                                                                                                                    if(alternativeTopfgroessetopfx === "6") {
                                                                                                                                                                        //ok
                                                                                                                                                                    }
                                                                                                                                                                    else {
                                                                                                                                                                        return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 6 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, 5 oder 6 setzen!");
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                                else {
                                                                                                                                                                    return( "Es würde klappen, wenn Sie hier die bevorzugte Gruppengröße auf 1, 3, 4, 5, 6 oder 7 setzen!");
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                if(trainingseinheitentopfx === "34") {
                                                                                                                                                    if(bevorzugteTopfgroessetopfx === "3" || bevorzugteTopfgroessetopfx === "1") {
                                                                                                                                                        //ok
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        if(bevorzugteTopfgroessetopfx === "4") {
                                                                                                                                                            //ok
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                            if(bevorzugteTopfgroessetopfx === "5") {
                                                                                                                                                                //ok
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                                if(bevorzugteTopfgroessetopfx === "6") {
                                                                                                                                                                    
                                                                                                                                                                }
                                                                                                                                                                else {
                                                                                                                                                                    if(bevorzugteTopfgroessetopfx === "7") {
                                                                                                                                                                        if(alternativeTopfgroessetopfx === "6") {
                                                                                                                                                                            //ok
                                                                                                                                                                        }
                                                                                                                                                                        else {
                                                                                                                                                                            return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 6 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, 5, 6 oder 8 setzen!");
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                    else {
                                                                                                                                                                        return( "Es würde klappen, wenn Sie hier die bevorzugte Gruppengröße auf 1, 3, 4, 5, 6 oder 7 setzen!");
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                    if(trainingseinheitentopfx === "35") {
                                                                                                                                                        if(bevorzugteTopfgroessetopfx === "3" || bevorzugteTopfgroessetopfx === "1") {
                                                                                                                                                            //ok
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                            if(bevorzugteTopfgroessetopfx === "4") {
                                                                                                                                                                //ok
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                                if(bevorzugteTopfgroessetopfx === "5") {
                                                                                                                                                                    //ok
                                                                                                                                                                }
                                                                                                                                                                else {
                                                                                                                                                                    if(bevorzugteTopfgroessetopfx === "6") {
                                                                                                                                                                        if(alternativeTopfgroessetopfx === "5") {
                                                                                                                                                                            //ok
                                                                                                                                                                        }
                                                                                                                                                                        else {
                                                                                                                                                                            return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 5 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, 5 oder 7 setzen!");
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                    else {
                                                                                                                                                                        if(bevorzugteTopfgroessetopfx === "7") {
                                                                                                                                                                            
                                                                                                                                                                        }
                                                                                                                                                                        else {
                                                                                                                                                                            return( "Es würde klappen, wenn Sie hier die bevorzugte Gruppengröße auf 1, 3, 4, 5, 6 oder 7 setzen!");
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        if(trainingseinheitentopfx === "36") {
                                                                                                                                                            if(bevorzugteTopfgroessetopfx === "3" || bevorzugteTopfgroessetopfx === "1") {
                                                                                                                                                                //ok
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                                if(bevorzugteTopfgroessetopfx === "4") {
                                                                                                                                                                    //ok
                                                                                                                                                                }
                                                                                                                                                                else {
                                                                                                                                                                    if(bevorzugteTopfgroessetopfx === "5") {
                                                                                                                                                                        //ok
                                                                                                                                                                    }
                                                                                                                                                                    else {
                                                                                                                                                                        if(bevorzugteTopfgroessetopfx === "6") {
                                                                                                                                                                            
                                                                                                                                                                        }
                                                                                                                                                                        else {
                                                                                                                                                                            if(bevorzugteTopfgroessetopfx === "7") {
                                                                                                                                                                                if(alternativeTopfgroessetopfx === "8") {
                                                                                                                                                                                    //ok
                                                                                                                                                                                }
                                                                                                                                                                                else {
                                                                                                                                                                                    return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 8 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, 5, 6 oder 8 setzen!");
                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                            else {
                                                                                                                                                                                if(bevorzugteTopfgroessetopfx === "8") {
                                                                                                                                                                                    
                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                            if(trainingseinheitentopfx === "37") {
                                                                                                                                                                //Hier geht alles
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                                if(trainingseinheitentopfx === "38") {
                                                                                                                                                                    //Hier geht alles
                                                                                                                                                                }
                                                                                                                                                                else {
                                                                                                                                                                    if(trainingseinheitentopfx === "39") {
                                                                                                                                                                        //Hier geht alles
                                                                                                                                                                    }
                                                                                                                                                                    else {
                                                                                                                                                                        if(trainingseinheitentopfx === "40") {
                                                                                                                                                                            if(bevorzugteTopfgroessetopfx === "3" || bevorzugteTopfgroessetopfx === "1") {
                                                                                                                                                                                //ok
                                                                                                                                                                            }
                                                                                                                                                                            else {
                                                                                                                                                                                if(bevorzugteTopfgroessetopfx === "4") {
                                                                                                                                                                                    //ok
                                                                                                                                                                                }
                                                                                                                                                                                else {
                                                                                                                                                                                    if(bevorzugteTopfgroessetopfx === "5") {
                                                                                                                                                                                        //ok
                                                                                                                                                                                    }
                                                                                                                                                                                    else {
                                                                                                                                                                                        if(bevorzugteTopfgroessetopfx === "6") {
                                                                                                                                                                                            
                                                                                                                                                                                        }
                                                                                                                                                                                        else {
                                                                                                                                                                                            if(bevorzugteTopfgroessetopfx === "7") {
                                                                                                                                                                                                if(alternativeTopfgroessetopfx === "6") {
                                                                                                                                                                                                    //ok
                                                                                                                                                                                                }
                                                                                                                                                                                                else {
                                                                                                                                                                                                    return( "Es würde klappen, wenn Sie hier die alternative Gruppengröße auf 6 setzen! Alternativ können Sie auch die bevorzugte Gruppengröße auf 1, 3, 4, 5, 6 oder 8 setzen!");
                                                                                                                                                                                                }
                                                                                                                                                                                            }
                                                                                                                                                                                            else {
                                                                                                                                                                                                if(bevorzugteTopfgroessetopfx === "8") {
                                                                                                                                                                                                    
                                                                                                                                                                                                }
                                                                                                                                                                                            }
                                                                                                                                                                                        }
                                                                                                                                                                                    }
                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    return ("");
}

export function auswahlTopf(wert) {
    const array = [];
    for (let j = 0; j < wert + 1; j++) {
    array.push(j);
    }
    return array;
}

export function bevorzugteGruppengroesse(wert) {
    return [1, 2, 3, 4, 5, 6, 7, 8];
}

export function alternativeGruppengroesse(wert) {
    if (wert === 0) {
    return [""];
    }
    if (wert === 1) {
    return ["", 1];
    }
    if (wert === 2) {
    return ["", 2];
    }
    if (wert === 3) {
    return ["", 4];
    }
    if (wert === 4) {
    return ["", 3, 5];
    }
    if (wert === 5) {
    return ["", 4, 6];
    }
    if (wert === 6) {
    return ["", 5, 7];
    }
    if (wert === 7) {
    return ["", 6, 8];
    }
    if (wert === 8) {
    return ["", 7];
    }
}

export function alleNamen(namexxtopf1) {
    const array = [];
    for(let a=0; a<40; a++) {
        array.push(namexxtopf1[a]);
    }
    return array;
}

export function alleNamenSchritt7(namexxtopf1, trainingseinheitentopf1, WieOft) {
    const array = [];
    for(let a=0; a<parseInt(trainingseinheitentopf1); a++) {
        if(WieOft[a] > 0) {
            array.push(namexxtopf1[a]);
        }
    }
    return array;
}

export function Verbotmoeglichkeiten(name, namexxtopf1, trainingseinheitentopf1, WieOft) {
    var array = alleNamenSchritt7(namexxtopf1, trainingseinheitentopf1, WieOft);

    if (name === "") {
        return [];
    }

    var index = array.indexOf(name);
    delete array[index];

    return array;
}

export function WuenscheKompatibel(trainingseinheitentopf1, namexxtopf1MitLeerzeichen, zeitxxxx, WieOft) {
    //Das hier ist dazu da, um eventuelle Leerzeichen am Ende des Namens zu entfernen
    var namexxtopf1 = [];
    for(let i=0; i<namexxtopf1MitLeerzeichen.length; i++) {
      if(namexxtopf1MitLeerzeichen[i].slice(-1) === " ") {
        namexxtopf1[i] = namexxtopf1MitLeerzeichen[i].slice(0, namexxtopf1MitLeerzeichen[i].length-1)
      }
      else {
        namexxtopf1[i] = namexxtopf1MitLeerzeichen[i]
      }
    }

    const array = [];
    const arrayoutput = [];
    const arraynamen = alleNamen(namexxtopf1);

    for (let i = 0; i < trainingseinheitentopf1; i++) {
        array.push([]);
        array[i].push(new Array(trainingseinheitentopf1));
    }
    for (let j = 0; j < trainingseinheitentopf1; j++) {
        array[j][0] = arraynamen[j];
    }

    for(let x=1; x<trainingseinheitentopf1; x++) {
        for(let a=x; a<trainingseinheitentopf1; a++) {
            for(let b=0; b<48; b++) {
                if(zeitxxxx[48*(x-1) + b] === "X" || zeitxxxx[48*a + b] === "X" || zeitxxxx[48*(x-1) + b] === "0" || zeitxxxx[48*a + b] === "0") {
                }
                else{
                    array[x-1][a] = namexxtopf1[a];
                    array[a][x] = namexxtopf1[x-1];
                    b = 48;
                }
            }
        }
    }
    
    for(let i=0; i<40; i++) {
        if(parseInt(WieOft[i]) >= 2) {
            array[i][i+1] = undefined;
            array[i+1][i+1] = undefined;
            if(parseInt(WieOft[i]) >= 3) {
                array[i][i+2] = undefined;
                array[i+1][i+2] = undefined;
                array[i+2][i+1] = undefined;
                array[i+2][i+2] = undefined;
            }
        }
    }

    for (let i = 0; i < trainingseinheitentopf1; i++) {
        arrayoutput.push([]);
    }
    for (let j = 0; j < trainingseinheitentopf1; j++) {
        arrayoutput[j][0] = arraynamen[j];
    }
    for (let i = 0; i < trainingseinheitentopf1; i++) {
        for (let j = 1; j < array[i].length; j++) {
            if (array[i][j] !== undefined) {
            arrayoutput[i].push(array[i][j]);
            }
        }
    }
    return arrayoutput;
}

export function ZuVieleWuensche(trainingseinheitentopf1, bevorzugteTopfgroessetopf1, alternativeTopfgroessetopf1, AnzahlWuensche, wunschxxx) {
    let errorSchritt6 = "";
    let Error1 = 0;
    let Error2 = 0;
    let AnzahlRiesenWunsch = 0;

    if(trainingseinheitentopf1 === "6") {
        if(bevorzugteTopfgroessetopf1 === "3") {
          if(AnzahlWuensche > 2) {
            Error1 = 1;
          }
          for(let a=0; a<20; a++) {
            if(wunschxxx[8*a + 2] !== "") {
              AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
            }
          }
          if(AnzahlRiesenWunsch > 2) {
            Error2 = 1;
          }

          if(Error1 === 1 && Error2 === 1) {
            errorSchritt6 = "1. In diesem Topf kann es nur zwei 3er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 2 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
          }
          else {
            if(Error1 === 1) {
              errorSchritt6 = "In diesem Topf kann es insgesamt nur 2 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
            }
            else {
              if(Error2 === 1) {
                errorSchritt6 = "In diesem Topf kann es nur zwei 3er Wünsche geben.";
              }
            }
          }
        }
    }
    else {
        if(trainingseinheitentopf1 === "7") {
            if(bevorzugteTopfgroessetopf1 === "3" || bevorzugteTopfgroessetopf1 === "4") {
              if(AnzahlWuensche > 2) {
                Error1 = 1;
              }
              for(let a=0; a<20; a++) {
                if(wunschxxx[8*a + 3] !== "") {
                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                }
              }
              if(AnzahlRiesenWunsch > 1) {
                Error2 = 1;
              }
              
              if(Error1 === 1 && Error2 === 1) {
                errorSchritt6 = "1. In diesem Topf kann es nur einen 4er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 2 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
              }
              else {
                if(Error1 === 1) {
                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 2 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                }
                else {
                  if(Error2 === 1) {
                    errorSchritt6 = "In diesem Topf kann es nur einen 4er Wunsch geben.";
                  }
                }
              }
            }
        }
        else {
            if(trainingseinheitentopf1 === "8") {
              if(bevorzugteTopfgroessetopf1 === "4") {
                if(AnzahlWuensche > 2) {
                  Error1 = 1;
                }
                for(let a=0; a<20; a++) {
                  if(wunschxxx[8*a + 3] !== "") {
                    AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                  }
                }
                if(AnzahlRiesenWunsch > 2) {
                  Error2 = 1;
                }
      
                if(Error1 === 1 && Error2 === 1) {
                  errorSchritt6 = "1. In diesem Topf kann es nur zwei 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 2 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                }
                else {
                  if(Error1 === 1) {
                    errorSchritt6 = "In diesem Topf kann es insgesamt nur 2 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                  }
                  else {
                    if(Error2 === 1) {
                      errorSchritt6 = "In diesem Topf kann es nur zwei 4er Wünsche geben.";
                    }
                  }
                }
              }
            }
            else {
                if(trainingseinheitentopf1 === "9") {
                    if(bevorzugteTopfgroessetopf1 === "3") {
                      if(AnzahlWuensche > 3) {
                        Error1 = 1;
                      }
                      for(let a=0; a<20; a++) {
                        if(wunschxxx[8*a + 2] !== "") {
                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                        }
                      }
                      if(AnzahlRiesenWunsch > 3) {
                        Error2 = 1;
                      }
            
                      if(Error1 === 1 && Error2 === 1) {
                        errorSchritt6 = "1. In diesem Topf kann es nur drei 3er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                      }
                      else {
                        if(Error1 === 1) {
                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                        }
                        else {
                          if(Error2 === 1) {
                            errorSchritt6 = "In diesem Topf kann es nur drei 3er Wünsche geben.";
                          }
                        }
                      }
                    }
                    else {
                        if(bevorzugteTopfgroessetopf1 === "4" || bevorzugteTopfgroessetopf1 === "5") {
                          if(AnzahlWuensche > 2) {
                            Error1 = 1;
                          }
                          for(let a=0; a<20; a++) {
                            if(wunschxxx[8*a + 4] !== "") {
                              AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                            }
                          }
                          if(AnzahlRiesenWunsch > 1) {
                            Error2 = 1;
                          }
                          
                          if(Error1 === 1 && Error2 === 1) {
                            errorSchritt6 = "1. In diesem Topf kann es nur einen 5er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 2 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                          }
                          else {
                            if(Error1 === 1) {
                              errorSchritt6 = "In diesem Topf kann es insgesamt nur 2 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                            }
                            else {
                              if(Error2 === 1) {
                                errorSchritt6 = "In diesem Topf kann es nur einen 5er Wunsch geben.";
                              }
                            }
                          }
                        }
                    }
                }
                else {
                    if(trainingseinheitentopf1 === "10") {
                        if(bevorzugteTopfgroessetopf1 === "3" || bevorzugteTopfgroessetopf1 === "4") {
                          if(AnzahlWuensche > 3) {
                            Error1 = 1;
                          }
                          for(let a=0; a<20; a++) {
                            if(wunschxxx[8*a + 3] !== "") {
                              AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                            }
                          }
                          if(AnzahlRiesenWunsch > 1) {
                            Error2 = 1;
                          }
                          
                          if(Error1 === 1 && Error2 === 1) {
                            errorSchritt6 = "1. In diesem Topf kann es nur einen 4er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                          }
                          else {
                            if(Error1 === 1) {
                              errorSchritt6 = "In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                            }
                            else {
                              if(Error2 === 1) {
                                errorSchritt6 = "In diesem Topf kann es nur einen 4er Wunsch geben.";
                              }
                            }
                          }
                        }
                        else {
                            if(bevorzugteTopfgroessetopf1 === "5") {
                              if(AnzahlWuensche > 2) {
                                Error1 = 1;
                              }
                              for(let a=0; a<20; a++) {
                                if(wunschxxx[8*a + 4] !== "") {
                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                }
                              }
                              if(AnzahlRiesenWunsch > 2) {
                                Error2 = 1;
                              }
                              
                              if(Error1 === 1 && Error2 === 1) {
                                errorSchritt6 = "1. In diesem Topf kann es nur zwei 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 2 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                              }
                              else {
                                if(Error1 === 1) {
                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 2 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                }
                                else {
                                  if(Error2 === 1) {
                                    errorSchritt6 = "In diesem Topf kann es nur zwei 5er Wünsche geben.";
                                  }
                                }
                              }
                            }
                        }
                    }
                    else {
                        if(trainingseinheitentopf1 === "11") {
                            if(bevorzugteTopfgroessetopf1 === "3") {
                              if(AnzahlWuensche > 3) {
                                Error1 = 1;
                              }
                              for(let a=0; a<20; a++) {
                                if(wunschxxx[8*a + 3] !== "") {
                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                }
                              }
                              if(AnzahlRiesenWunsch > 2) {
                                Error2 = 1;
                              }
                              
                              if(Error1 === 1 && Error2 === 1) {
                                errorSchritt6 = "1. In diesem Topf kann es nur zwei 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                              }
                              else {
                                if(Error1 === 1) {
                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                }
                                else {
                                  if(Error2 === 1) {
                                    errorSchritt6 = "In diesem Topf kann es nur zwei 4er Wünsche geben.";
                                  }
                                }
                              }
                            }
                            else {
                                if(bevorzugteTopfgroessetopf1 === "5" || bevorzugteTopfgroessetopf1 === "6") {
                                  if(AnzahlWuensche > 2) {
                                    Error1 = 1;
                                  }
                                  for(let a=0; a<20; a++) {
                                    if(wunschxxx[8*a + 5] !== "") {
                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                    }
                                  }
                                  if(AnzahlRiesenWunsch > 1) {
                                    Error2 = 1;
                                  }
                                  
                                  if(Error1 === 1 && Error2 === 1) {
                                    errorSchritt6 = "1. In diesem Topf kann es nur einen 6er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 2 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                  }
                                  else {
                                    if(Error1 === 1) {
                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 2 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                    }
                                    else {
                                      if(Error2 === 1) {
                                        errorSchritt6 = "In diesem Topf kann es nur einen 6er Wunsch geben.";
                                      }
                                    }
                                  }
                                }
                            }
                        }
                        else {
                            if(trainingseinheitentopf1 === "12") {
                                if(bevorzugteTopfgroessetopf1 === "3") {
                                  if(AnzahlWuensche > 4) {
                                    Error1 = 1;
                                  }
                                  for(let a=0; a<20; a++) {
                                    if(wunschxxx[8*a + 2] !== "") {
                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                    }
                                  }
                                  if(AnzahlRiesenWunsch > 4) {
                                    Error2 = 1;
                                  }
                        
                                  if(Error1 === 1 && Error2 === 1) {
                                    errorSchritt6 = "1. In diesem Topf kann es nur vier 3er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                  }
                                  else {
                                    if(Error1 === 1) {
                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                    }
                                    else {
                                      if(Error2 === 1) {
                                        errorSchritt6 = "In diesem Topf kann es nur vier 3er Wünsche geben.";
                                      }
                                    }
                                  }
                                }
                                else {
                                    if(bevorzugteTopfgroessetopf1 === "4") {
                                      if(AnzahlWuensche > 3) {
                                        Error1 = 1;
                                      }
                                      for(let a=0; a<20; a++) {
                                        if(wunschxxx[8*a + 3] !== "") {
                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                        }
                                      }
                                      if(AnzahlRiesenWunsch > 3) {
                                        Error2 = 1;
                                      }
                            
                                      if(Error1 === 1 && Error2 === 1) {
                                        errorSchritt6 = "1. In diesem Topf kann es nur drei 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                      }
                                      else {
                                        if(Error1 === 1) {
                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                        }
                                        else {
                                          if(Error2 === 1) {
                                            errorSchritt6 = "In diesem Topf kann es nur drei 4er Wünsche geben.";
                                          }
                                        }
                                      }
                                    }
                                    else {
                                        if(bevorzugteTopfgroessetopf1 === "6") {
                                          if(AnzahlWuensche > 2) {
                                            Error1 = 1;
                                          }
                                          for(let a=0; a<20; a++) {
                                            if(wunschxxx[8*a + 5] !== "") {
                                              AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                            }
                                          }
                                          if(AnzahlRiesenWunsch > 2) {
                                            Error2 = 1;
                                          }
                                          
                                          if(Error1 === 1 && Error2 === 1) {
                                            errorSchritt6 = "1. In diesem Topf kann es nur zwei 6er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 2 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                          }
                                          else {
                                            if(Error1 === 1) {
                                              errorSchritt6 = "In diesem Topf kann es insgesamt nur 2 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                            }
                                            else {
                                              if(Error2 === 1) {
                                                errorSchritt6 = "In diesem Topf kann es nur zwei 6er Wünsche geben.";
                                              }
                                            }
                                          }
                                        }
                                    }
                                }
                            }
                            else {
                                if(trainingseinheitentopf1 === "13") {
                                    if(bevorzugteTopfgroessetopf1 === "3") {
                                      if(AnzahlWuensche > 4) {
                                        Error1 = 1;
                                      }
                                      for(let a=0; a<20; a++) {
                                        if(wunschxxx[8*a + 3] !== "") {
                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                        }
                                      }
                                      if(AnzahlRiesenWunsch > 1) {
                                        Error2 = 1;
                                      }
                                      
                                      if(Error1 === 1 && Error2 === 1) {
                                        errorSchritt6 = "1. In diesem Topf kann es nur einen 4er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                      }
                                      else {
                                        if(Error1 === 1) {
                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                        }
                                        else {
                                          if(Error2 === 1) {
                                            errorSchritt6 = "In diesem Topf kann es nur einen 4er Wunsch geben.";
                                          }
                                        }
                                      }
                                    }
                                    else {
                                        if(bevorzugteTopfgroessetopf1 === "4") {
                                          if(alternativeTopfgroessetopf1 === "3") {
                                            if(AnzahlWuensche > 4) {
                                              Error1 = 1;
                                            }
                                            for(let a=0; a<20; a++) {
                                              if(wunschxxx[8*a + 3] !== "") {
                                                AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                              }
                                            }
                                            if(AnzahlRiesenWunsch > 1) {
                                              Error2 = 1;
                                            }
                                            
                                            if(Error1 === 1 && Error2 === 1) {
                                              errorSchritt6 = "1. In diesem Topf kann es nur einen 4er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                            }
                                            else {
                                              if(Error1 === 1) {
                                                errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                              }
                                              else {
                                                if(Error2 === 1) {
                                                  errorSchritt6 = "In diesem Topf kann es nur einen 4er Wunsch geben.";
                                                }
                                              }
                                            }
                                          }
                                          else {
                                            if(alternativeTopfgroessetopf1 === "5") {
                                              if(AnzahlWuensche > 3) {
                                                Error1 = 1;
                                              }
                                              for(let a=0; a<20; a++) {
                                                if(wunschxxx[8*a + 4] !== "") {
                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                }
                                              }
                                              if(AnzahlRiesenWunsch > 1) {
                                                Error2 = 1;
                                              }
                                              
                                              if(Error1 === 1 && Error2 === 1) {
                                                errorSchritt6 = "1. In diesem Topf kann es nur einen 5er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                              }
                                              else {
                                                if(Error1 === 1) {
                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                }
                                                else {
                                                  if(Error2 === 1) {
                                                    errorSchritt6 = "In diesem Topf kann es nur einen 5er Wunsch geben.";
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                        else {
                                            if(bevorzugteTopfgroessetopf1 === "5") {
                                                if(alternativeTopfgroessetopf1 === "4") {
                                                  if(AnzahlWuensche > 3) {
                                                    Error1 = 1;
                                                  }
                                                  for(let a=0; a<20; a++) {
                                                    if(wunschxxx[8*a + 4] !== "") {
                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                    }
                                                  }
                                                  if(AnzahlRiesenWunsch > 1) {
                                                    Error2 = 1;
                                                  }
                                                  
                                                  if(Error1 === 1 && Error2 === 1) {
                                                    errorSchritt6 = "1. In diesem Topf kann es nur einen 5er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                  }
                                                  else {
                                                    if(Error1 === 1) {
                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                    }
                                                    else {
                                                      if(Error2 === 1) {
                                                        errorSchritt6 = "In diesem Topf kann es nur einen 5er Wunsch geben.";
                                                      }
                                                    }
                                                  }
                                                }
                                            }
                                            else {
                                                if(bevorzugteTopfgroessetopf1 === "6" || bevorzugteTopfgroessetopf1 === "7") {
                                                  if(AnzahlWuensche > 2) {
                                                    Error1 = 1;
                                                  }
                                                  for(let a=0; a<20; a++) {
                                                    if(wunschxxx[8*a + 6] !== "") {
                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                    }
                                                  }
                                                  if(AnzahlRiesenWunsch > 1) {
                                                    Error2 = 1;
                                                  }
                                                  
                                                  if(Error1 === 1 && Error2 === 1) {
                                                    errorSchritt6 = "1. In diesem Topf kann es nur einen 7er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 2 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                  }
                                                  else {
                                                    if(Error1 === 1) {
                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 2 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                    }
                                                    else {
                                                      if(Error2 === 1) {
                                                        errorSchritt6 = "In diesem Topf kann es nur einen 7er Wunsch geben.";
                                                      }
                                                    }
                                                  }
                                                }
                                            }
                                        }
                                    }
                                }
                                else {
                                    if(trainingseinheitentopf1 === "14") {
                                        if(bevorzugteTopfgroessetopf1 === "3") {
                                          if(AnzahlWuensche > 4) {
                                            Error1 = 1;
                                          }
                                          for(let a=0; a<20; a++) {
                                            if(wunschxxx[8*a + 3] !== "") {
                                              AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                            }
                                          }
                                          if(AnzahlRiesenWunsch > 2) {
                                            Error2 = 1;
                                          }
                                          
                                          if(Error1 === 1 && Error2 === 1) {
                                            errorSchritt6 = "1. In diesem Topf kann es nur zwei 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                          }
                                          else {
                                            if(Error1 === 1) {
                                              errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                            }
                                            else {
                                              if(Error2 === 1) {
                                                errorSchritt6 = "In diesem Topf kann es nur zwei 4er Wünsche geben.";
                                              }
                                            }
                                          }
                                        }
                                        else {
                                            if(bevorzugteTopfgroessetopf1 === "4") {
                                              if(alternativeTopfgroessetopf1 === "3") {
                                                if(AnzahlWuensche > 4) {
                                                  Error1 = 1;
                                                }
                                                for(let a=0; a<20; a++) {
                                                  if(wunschxxx[8*a + 3] !== "") {
                                                    AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                  }
                                                }
                                                if(AnzahlRiesenWunsch > 2) {
                                                  Error2 = 1;
                                                }
                                                
                                                if(Error1 === 1 && Error2 === 1) {
                                                  errorSchritt6 = "1. In diesem Topf kann es nur zwei 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                }
                                                else {
                                                  if(Error1 === 1) {
                                                    errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                  }
                                                  else {
                                                    if(Error2 === 1) {
                                                      errorSchritt6 = "In diesem Topf kann es nur zwei 4er Wünsche geben.";
                                                    }
                                                  }
                                                }
                                              }
                                              else {
                                                if(alternativeTopfgroessetopf1 === "5") {
                                                  if(AnzahlWuensche > 3) {
                                                    Error1 = 1;
                                                  }
                                                  for(let a=0; a<20; a++) {
                                                    if(wunschxxx[8*a + 4] !== "") {
                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                    }
                                                  }
                                                  if(AnzahlRiesenWunsch > 2) {
                                                    Error2 = 1;
                                                  }
                                                  
                                                  if(Error1 === 1 && Error2 === 1) {
                                                    errorSchritt6 = "1. In diesem Topf kann es nur zwei 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                  }
                                                  else {
                                                    if(Error1 === 1) {
                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                    }
                                                    else {
                                                      if(Error2 === 1) {
                                                        errorSchritt6 = "In diesem Topf kann es nur zwei 5er Wünsche geben.";
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                            else {
                                                if(bevorzugteTopfgroessetopf1 === "5") {
                                                    if(alternativeTopfgroessetopf1 === "4") {
                                                      if(AnzahlWuensche > 3) {
                                                        Error1 = 1;
                                                      }
                                                      for(let a=0; a<20; a++) {
                                                        if(wunschxxx[8*a + 4] !== "") {
                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                        }
                                                      }
                                                      if(AnzahlRiesenWunsch > 2) {
                                                        Error2 = 1;
                                                      }
                                                      
                                                      if(Error1 === 1 && Error2 === 1) {
                                                        errorSchritt6 = "1. In diesem Topf kann es nur zwei 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                      }
                                                      else {
                                                        if(Error1 === 1) {
                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                        }
                                                        else {
                                                          if(Error2 === 1) {
                                                            errorSchritt6 = "In diesem Topf kann es nur zwei 5er Wünsche geben.";
                                                          }
                                                        }
                                                      }
                                                    }
                                                }
                                                else {
                                                    if(bevorzugteTopfgroessetopf1 === "7") {
                                                      if(AnzahlWuensche > 2) {
                                                        Error1 = 1;
                                                      }
                                                      for(let a=0; a<20; a++) {
                                                        if(wunschxxx[8*a + 6] !== "") {
                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                        }
                                                      }
                                                      if(AnzahlRiesenWunsch > 2) {
                                                        Error2 = 1;
                                                      }
                                                      
                                                      if(Error1 === 1 && Error2 === 1) {
                                                        errorSchritt6 = "1. In diesem Topf kann es nur zwei 7er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 2 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                      }
                                                      else {
                                                        if(Error1 === 1) {
                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 2 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                        }
                                                        else {
                                                          if(Error2 === 1) {
                                                            errorSchritt6 = "In diesem Topf kann es nur zwei 7er Wünsche geben.";
                                                          }
                                                        }
                                                      }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        if(trainingseinheitentopf1 === "15") {
                                            if(bevorzugteTopfgroessetopf1 === "3") {
                                              if(AnzahlWuensche > 5) {
                                                Error1 = 1;
                                              }
                                              for(let a=0; a<20; a++) {
                                                if(wunschxxx[8*a + 2] !== "") {
                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                }
                                              }
                                              if(AnzahlRiesenWunsch > 5) {
                                                Error2 = 1;
                                              }
                                    
                                              if(Error1 === 1 && Error2 === 1) {
                                                errorSchritt6 = "1. In diesem Topf kann es nur fünf 3er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                              }
                                              else {
                                                if(Error1 === 1) {
                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                }
                                                else {
                                                  if(Error2 === 1) {
                                                    errorSchritt6 = "In diesem Topf kann es nur fünf 3er Wünsche geben.";
                                                  }
                                                }
                                              }
                                            }
                                            else {
                                                if(bevorzugteTopfgroessetopf1 === "4") {
                                                    if(alternativeTopfgroessetopf1 === "3") {
                                                      if(AnzahlWuensche > 4) {
                                                        Error1 = 1;
                                                      }
                                                      for(let a=0; a<20; a++) {
                                                        if(wunschxxx[8*a + 3] !== "") {
                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                        }
                                                      }
                                                      if(AnzahlRiesenWunsch > 3) {
                                                        Error2 = 1;
                                                      }
                                                      
                                                      if(Error1 === 1 && Error2 === 1) {
                                                        errorSchritt6 = "1. In diesem Topf kann es nur drei 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                      }
                                                      else {
                                                        if(Error1 === 1) {
                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                        }
                                                        else {
                                                          if(Error2 === 1) {
                                                            errorSchritt6 = "In diesem Topf kann es nur drei 4er Wünsche geben.";
                                                          }
                                                        }
                                                      }
                                                    }
                                                }
                                                else {
                                                    if(bevorzugteTopfgroessetopf1 === "5") {
                                                      if(AnzahlWuensche > 3) {
                                                        Error1 = 1;
                                                      }
                                                      for(let a=0; a<20; a++) {
                                                        if(wunschxxx[8*a + 4] !== "") {
                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                        }
                                                      }
                                                      if(AnzahlRiesenWunsch > 3) {
                                                        Error2 = 1;
                                                      }
                                                      
                                                      if(Error1 === 1 && Error2 === 1) {
                                                        errorSchritt6 = "1. In diesem Topf kann es nur drei 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                      }
                                                      else {
                                                        if(Error1 === 1) {
                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                        }
                                                        else {
                                                          if(Error2 === 1) {
                                                            errorSchritt6 = "In diesem Topf kann es nur drei 5er Wünsche geben.";
                                                          }
                                                        }
                                                      }
                                                    }
                                                    else {
                                                        if(bevorzugteTopfgroessetopf1 === "7") {
                                                            if(alternativeTopfgroessetopf1 === "8") {
                                                              if(AnzahlWuensche > 2) {
                                                                Error1 = 1;
                                                              }
                                                              for(let a=0; a<20; a++) {
                                                                if(wunschxxx[8*a + 7] !== "") {
                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                }
                                                              }
                                                              if(AnzahlRiesenWunsch > 1) {
                                                                Error2 = 1;
                                                              }
                                                              
                                                              if(Error1 === 1 && Error2 === 1) {
                                                                errorSchritt6 = "1. In diesem Topf kann es nur einen 8er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 2 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                              }
                                                              else {
                                                                if(Error1 === 1) {
                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 2 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                }
                                                                else {
                                                                  if(Error2 === 1) {
                                                                    errorSchritt6 = "In diesem Topf kann es nur einen 8er Wunsch geben.";
                                                                  }
                                                                }
                                                              }
                                                            }
                                                        }
                                                        else {
                                                            if(bevorzugteTopfgroessetopf1 === "8") {
                                                              if(AnzahlWuensche > 2) {
                                                                Error1 = 1;
                                                              }
                                                              for(let a=0; a<20; a++) {
                                                                if(wunschxxx[8*a + 7] !== "") {
                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                }
                                                              }
                                                              if(AnzahlRiesenWunsch > 1) {
                                                                Error2 = 1;
                                                              }
                                                              
                                                              if(Error1 === 1 && Error2 === 1) {
                                                                errorSchritt6 = "1. In diesem Topf kann es nur einen 8er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 2 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                              }
                                                              else {
                                                                if(Error1 === 1) {
                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 2 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                }
                                                                else {
                                                                  if(Error2 === 1) {
                                                                    errorSchritt6 = "In diesem Topf kann es nur einen 8er Wunsch geben.";
                                                                  }
                                                                }
                                                              }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        else {
                                            if(trainingseinheitentopf1 === "16") {
                                                if(bevorzugteTopfgroessetopf1 === "3") {
                                                  if(AnzahlWuensche > 5) {
                                                    Error1 = 1;
                                                  }
                                                  for(let a=0; a<20; a++) {
                                                    if(wunschxxx[8*a + 3] !== "") {
                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                    }
                                                  }
                                                  if(AnzahlRiesenWunsch > 1) {
                                                    Error2 = 1;
                                                  }
                                                  
                                                  if(Error1 === 1 && Error2 === 1) {
                                                    errorSchritt6 = "1. In diesem Topf kann es nur einen 4er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                  }
                                                  else {
                                                    if(Error1 === 1) {
                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                    }
                                                    else {
                                                      if(Error2 === 1) {
                                                        errorSchritt6 = "In diesem Topf kann es nur einen 4er Wunsch geben.";
                                                      }
                                                    }
                                                  }
                                                }
                                                else {
                                                    if(bevorzugteTopfgroessetopf1 === "4") {
                                                      if(AnzahlWuensche > 4) {
                                                        Error1 = 1;
                                                      }
                                                      for(let a=0; a<20; a++) {
                                                        if(wunschxxx[8*a + 3] !== "") {
                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                        }
                                                      }
                                                      if(AnzahlRiesenWunsch > 4) {
                                                        Error2 = 1;
                                                      }
                                            
                                                      if(Error1 === 1 && Error2 === 1) {
                                                        errorSchritt6 = "1. In diesem Topf kann es nur vier 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                      }
                                                      else {
                                                        if(Error1 === 1) {
                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                        }
                                                        else {
                                                          if(Error2 === 1) {
                                                            errorSchritt6 = "In diesem Topf kann es nur vier 4er Wünsche geben.";
                                                          }
                                                        }
                                                      }
                                                    }
                                                    else {
                                                        if(bevorzugteTopfgroessetopf1 === "5") {
                                                            if(alternativeTopfgroessetopf1 === "6") {
                                                              if(AnzahlWuensche > 3) {
                                                                Error1 = 1;
                                                              }
                                                              for(let a=0; a<20; a++) {
                                                                if(wunschxxx[8*a + 5] !== "") {
                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                }
                                                              }
                                                              if(AnzahlRiesenWunsch > 1) {
                                                                Error2 = 1;
                                                              }
                                                              
                                                              if(Error1 === 1 && Error2 === 1) {
                                                                errorSchritt6 = "1. In diesem Topf kann es nur einen 6er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                              }
                                                              else {
                                                                if(Error1 === 1) {
                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                }
                                                                else {
                                                                  if(Error2 === 1) {
                                                                    errorSchritt6 = "In diesem Topf kann es nur einen 6er Wunsch geben.";
                                                                  }
                                                                }
                                                              }
                                                            }
                                                        }
                                                        else {
                                                            if(bevorzugteTopfgroessetopf1 === "6") {
                                                                if(alternativeTopfgroessetopf1 === "5") {
                                                                  if(AnzahlWuensche > 3) {
                                                                    Error1 = 1;
                                                                  }
                                                                  for(let a=0; a<20; a++) {
                                                                    if(wunschxxx[8*a + 5] !== "") {
                                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                    }
                                                                  }
                                                                  if(AnzahlRiesenWunsch > 1) {
                                                                    Error2 = 1;
                                                                  }
                                                                  
                                                                  if(Error1 === 1 && Error2 === 1) {
                                                                    errorSchritt6 = "1. In diesem Topf kann es nur einen 6er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                  }
                                                                  else {
                                                                    if(Error1 === 1) {
                                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                    }
                                                                    else {
                                                                      if(Error2 === 1) {
                                                                        errorSchritt6 = "In diesem Topf kann es nur einen 6er Wunsch geben.";
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                            }
                                                            else {
                                                                if(bevorzugteTopfgroessetopf1 === "8") {
                                                                  if(AnzahlWuensche > 2) {
                                                                    Error1 = 1;
                                                                  }
                                                                  for(let a=0; a<20; a++) {
                                                                    if(wunschxxx[8*a + 7] !== "") {
                                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                    }
                                                                  }
                                                                  if(AnzahlRiesenWunsch > 2) {
                                                                    Error2 = 1;
                                                                  }
                                                                  
                                                                  if(Error1 === 1 && Error2 === 1) {
                                                                    errorSchritt6 = "1. In diesem Topf kann es nur zwei 8er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 2 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                  }
                                                                  else {
                                                                    if(Error1 === 1) {
                                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 2 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                    }
                                                                    else {
                                                                      if(Error2 === 1) {
                                                                        errorSchritt6 = "In diesem Topf kann es nur zwei 8er Wünsche geben.";
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            else {
                                                if(trainingseinheitentopf1 === "17") {
                                                    if(bevorzugteTopfgroessetopf1 === "3") {
                                                      if(AnzahlWuensche > 5) {
                                                        Error1 = 1;
                                                      }
                                                      for(let a=0; a<20; a++) {
                                                        if(wunschxxx[8*a + 3] !== "") {
                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                        }
                                                      }
                                                      if(AnzahlRiesenWunsch > 2) {
                                                        Error2 = 1;
                                                      }
                                                      
                                                      if(Error1 === 1 && Error2 === 1) {
                                                        errorSchritt6 = "1. In diesem Topf kann es nur zwei 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                      }
                                                      else {
                                                        if(Error1 === 1) {
                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                        }
                                                        else {
                                                          if(Error2 === 1) {
                                                            errorSchritt6 = "In diesem Topf kann es nur zwei 4er Wünsche geben.";
                                                          }
                                                        }
                                                      }
                                                    }
                                                    else {
                                                        if(bevorzugteTopfgroessetopf1 === "4") {
                                                          if(alternativeTopfgroessetopf1 === "3") {
                                                            if(AnzahlWuensche > 5) {
                                                              Error1 = 1;
                                                            }
                                                            for(let a=0; a<20; a++) {
                                                              if(wunschxxx[8*a + 3] !== "") {
                                                                AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                              }
                                                            }
                                                            if(AnzahlRiesenWunsch > 2) {
                                                              Error2 = 1;
                                                            }
                                                            
                                                            if(Error1 === 1 && Error2 === 1) {
                                                              errorSchritt6 = "1. In diesem Topf kann es nur zwei 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                            }
                                                            else {
                                                              if(Error1 === 1) {
                                                                errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                              }
                                                              else {
                                                                if(Error2 === 1) {
                                                                  errorSchritt6 = "In diesem Topf kann es nur zwei 4er Wünsche geben.";
                                                                }
                                                              }
                                                            }
                                                          }
                                                          else {
                                                            if(alternativeTopfgroessetopf1 === "5") {
                                                              if(AnzahlWuensche > 4) {
                                                                Error1 = 1;
                                                              }
                                                              for(let a=0; a<20; a++) {
                                                                if(wunschxxx[8*a + 4] !== "") {
                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                }
                                                              }
                                                              if(AnzahlRiesenWunsch > 1) {
                                                                Error2 = 1;
                                                              }
                                                              
                                                              if(Error1 === 1 && Error2 === 1) {
                                                                errorSchritt6 = "1. In diesem Topf kann es nur einen 5er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                              }
                                                              else {
                                                                if(Error1 === 1) {
                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                }
                                                                else {
                                                                  if(Error2 === 1) {
                                                                    errorSchritt6 = "In diesem Topf kann es nur einen 5er Wunsch geben.";
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                        else {
                                                            if(bevorzugteTopfgroessetopf1 === "5") {
                                                              if(alternativeTopfgroessetopf1 === "4") {
                                                                if(AnzahlWuensche > 4) {
                                                                  Error1 = 1;
                                                                }
                                                                for(let a=0; a<20; a++) {
                                                                  if(wunschxxx[8*a + 4] !== "") {
                                                                    AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                  }
                                                                }
                                                                if(AnzahlRiesenWunsch > 1) {
                                                                  Error2 = 1;
                                                                }
                                                                
                                                                if(Error1 === 1 && Error2 === 1) {
                                                                  errorSchritt6 = "1. In diesem Topf kann es nur einen 5er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                }
                                                                else {
                                                                  if(Error1 === 1) {
                                                                    errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                  }
                                                                  else {
                                                                    if(Error2 === 1) {
                                                                      errorSchritt6 = "In diesem Topf kann es nur einen 5er Wunsch geben.";
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                              else {
                                                                if(alternativeTopfgroessetopf1 === "6") {
                                                                  if(AnzahlWuensche > 3) {
                                                                    Error1 = 1;
                                                                  }
                                                                  for(let a=0; a<20; a++) {
                                                                    if(wunschxxx[8*a + 5] !== "") {
                                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                    }
                                                                  }
                                                                  if(AnzahlRiesenWunsch > 2) {
                                                                    Error2 = 1;
                                                                  }
                                                                  
                                                                  if(Error1 === 1 && Error2 === 1) {
                                                                    errorSchritt6 = "1. In diesem Topf kann es nur zwei 6er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                  }
                                                                  else {
                                                                    if(Error1 === 1) {
                                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                    }
                                                                    else {
                                                                      if(Error2 === 1) {
                                                                        errorSchritt6 = "In diesem Topf kann es nur zwei 6er Wünsche geben.";
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                            else {
                                                                if(bevorzugteTopfgroessetopf1 === "6") {
                                                                    if(alternativeTopfgroessetopf1 === "5") {
                                                                      if(AnzahlWuensche > 3) {
                                                                        Error1 = 1;
                                                                      }
                                                                      for(let a=0; a<20; a++) {
                                                                        if(wunschxxx[8*a + 5] !== "") {
                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                        }
                                                                      }
                                                                      if(AnzahlRiesenWunsch > 2) {
                                                                        Error2 = 1;
                                                                      }
                                                                      
                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                        errorSchritt6 = "1. In diesem Topf kann es nur zwei 6er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                      }
                                                                      else {
                                                                        if(Error1 === 1) {
                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                        }
                                                                        else {
                                                                          if(Error2 === 1) {
                                                                            errorSchritt6 = "In diesem Topf kann es nur zwei 6er Wünsche geben.";
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                else {
                                                    if(trainingseinheitentopf1 === "18") {
                                                        if(bevorzugteTopfgroessetopf1 === "3") {
                                                          if(AnzahlWuensche > 6) {
                                                            Error1 = 1;
                                                          }
                                                          for(let a=0; a<20; a++) {
                                                            if(wunschxxx[8*a + 2] !== "") {
                                                              AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                            }
                                                          }
                                                          if(AnzahlRiesenWunsch > 6) {
                                                            Error2 = 1;
                                                          }
                                                
                                                          if(Error1 === 1 && Error2 === 1) {
                                                            errorSchritt6 = "1. In diesem Topf kann es nur sechs 3er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                          }
                                                          else {
                                                            if(Error1 === 1) {
                                                              errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                            }
                                                            else {
                                                              if(Error2 === 1) {
                                                                errorSchritt6 = "In diesem Topf kann es nur sechs 3er Wünsche geben.";
                                                              }
                                                            }
                                                          }
                                                        }
                                                        else {
                                                            if(bevorzugteTopfgroessetopf1 === "4") {
                                                              if(alternativeTopfgroessetopf1 === "3") {
                                                                if(AnzahlWuensche > 5) {
                                                                  Error1 = 1;
                                                                }
                                                                for(let a=0; a<20; a++) {
                                                                  if(wunschxxx[8*a + 3] !== "") {
                                                                    AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                  }
                                                                }
                                                                if(AnzahlRiesenWunsch > 3) {
                                                                  Error2 = 1;
                                                                }
                                                                
                                                                if(Error1 === 1 && Error2 === 1) {
                                                                  errorSchritt6 = "1. In diesem Topf kann es nur drei 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                }
                                                                else {
                                                                  if(Error1 === 1) {
                                                                    errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                  }
                                                                  else {
                                                                    if(Error2 === 1) {
                                                                      errorSchritt6 = "In diesem Topf kann es nur drei 4er Wünsche geben.";
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                              else {
                                                                if(alternativeTopfgroessetopf1 === "5") {
                                                                  if(AnzahlWuensche > 4) {
                                                                    Error1 = 1;
                                                                  }
                                                                  for(let a=0; a<20; a++) {
                                                                    if(wunschxxx[8*a + 4] !== "") {
                                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                    }
                                                                  }
                                                                  if(AnzahlRiesenWunsch > 2) {
                                                                    Error2 = 1;
                                                                  }
                                                                  
                                                                  if(Error1 === 1 && Error2 === 1) {
                                                                    errorSchritt6 = "1. In diesem Topf kann es nur zwei 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                  }
                                                                  else {
                                                                    if(Error1 === 1) {
                                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                    }
                                                                    else {
                                                                      if(Error2 === 1) {
                                                                        errorSchritt6 = "In diesem Topf kann es nur zwei 5er Wünsche geben.";
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                            else {
                                                                if(bevorzugteTopfgroessetopf1 === "5") {
                                                                    if(alternativeTopfgroessetopf1 === "4") {
                                                                      if(AnzahlWuensche > 4) {
                                                                        Error1 = 1;
                                                                      }
                                                                      for(let a=0; a<20; a++) {
                                                                        if(wunschxxx[8*a + 4] !== "") {
                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                        }
                                                                      }
                                                                      if(AnzahlRiesenWunsch > 2) {
                                                                        Error2 = 1;
                                                                      }
                                                                      
                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                        errorSchritt6 = "1. In diesem Topf kann es nur zwei 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                      }
                                                                      else {
                                                                        if(Error1 === 1) {
                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                        }
                                                                        else {
                                                                          if(Error2 === 1) {
                                                                            errorSchritt6 = "In diesem Topf kann es nur zwei 5er Wünsche geben.";
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                }
                                                                else {
                                                                    if(bevorzugteTopfgroessetopf1 === "6") {
                                                                      if(AnzahlWuensche > 3) {
                                                                        Error1 = 1;
                                                                      }
                                                                      for(let a=0; a<20; a++) {
                                                                        if(wunschxxx[8*a + 5] !== "") {
                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                        }
                                                                      }
                                                                      if(AnzahlRiesenWunsch > 3) {
                                                                        Error2 = 1;
                                                                      }
                                                                      
                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                        errorSchritt6 = "1. In diesem Topf kann es nur drei 6er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                      }
                                                                      else {
                                                                        if(Error1 === 1) {
                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                        }
                                                                        else {
                                                                          if(Error2 === 1) {
                                                                            errorSchritt6 = "In diesem Topf kann es nur drei 6er Wünsche geben.";
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                    else {
                                                        if(trainingseinheitentopf1 === "19") {
                                                            if(bevorzugteTopfgroessetopf1 === "3") {
                                                              if(AnzahlWuensche > 6) {
                                                                Error1 = 1;
                                                              }
                                                              for(let a=0; a<20; a++) {
                                                                if(wunschxxx[8*a + 3] !== "") {
                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                }
                                                              }
                                                              if(AnzahlRiesenWunsch > 1) {
                                                                Error2 = 1;
                                                              }
                                                              
                                                              if(Error1 === 1 && Error2 === 1) {
                                                                errorSchritt6 = "1. In diesem Topf kann es nur einen 4er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                              }
                                                              else {
                                                                if(Error1 === 1) {
                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                }
                                                                else {
                                                                  if(Error2 === 1) {
                                                                    errorSchritt6 = "In diesem Topf kann es nur einen 4er Wunsch geben.";
                                                                  }
                                                                }
                                                              }
                                                            }
                                                            else {
                                                                if(bevorzugteTopfgroessetopf1 === "4") {
                                                                  if(alternativeTopfgroessetopf1 === "3") {
                                                                    if(AnzahlWuensche > 5) {
                                                                      Error1 = 1;
                                                                    }
                                                                    for(let a=0; a<20; a++) {
                                                                      if(wunschxxx[8*a + 3] !== "") {
                                                                        AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                      }
                                                                    }
                                                                    if(AnzahlRiesenWunsch > 4) {
                                                                      Error2 = 1;
                                                                    }
                                                                    
                                                                    if(Error1 === 1 && Error2 === 1) {
                                                                      errorSchritt6 = "1. In diesem Topf kann es nur vier 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                    }
                                                                    else {
                                                                      if(Error1 === 1) {
                                                                        errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                      }
                                                                      else {
                                                                        if(Error2 === 1) {
                                                                          errorSchritt6 = "In diesem Topf kann es nur vier 4er Wünsche geben.";
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                  else {
                                                                    if(alternativeTopfgroessetopf1 === "5") {
                                                                      if(AnzahlWuensche > 4) {
                                                                        Error1 = 1;
                                                                      }
                                                                      for(let a=0; a<20; a++) {
                                                                        if(wunschxxx[8*a + 4] !== "") {
                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                        }
                                                                      }
                                                                      if(AnzahlRiesenWunsch > 3) {
                                                                        Error2 = 1;
                                                                      }
                                                                      
                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                        errorSchritt6 = "1. In diesem Topf kann es nur drei 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                      }
                                                                      else {
                                                                        if(Error1 === 1) {
                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                        }
                                                                        else {
                                                                          if(Error2 === 1) {
                                                                            errorSchritt6 = "In diesem Topf kann es nur drei 5er Wünsche geben.";
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                                else {
                                                                    if(bevorzugteTopfgroessetopf1 === "5") {
                                                                        if(alternativeTopfgroessetopf1 === "4") {
                                                                          if(AnzahlWuensche > 4) {
                                                                            Error1 = 1;
                                                                          }
                                                                          for(let a=0; a<20; a++) {
                                                                            if(wunschxxx[8*a + 4] !== "") {
                                                                              AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                            }
                                                                          }
                                                                          if(AnzahlRiesenWunsch > 3) {
                                                                            Error2 = 1;
                                                                          }
                                                                          
                                                                          if(Error1 === 1 && Error2 === 1) {
                                                                            errorSchritt6 = "1. In diesem Topf kann es nur drei 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                          }
                                                                          else {
                                                                            if(Error1 === 1) {
                                                                              errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                            }
                                                                            else {
                                                                              if(Error2 === 1) {
                                                                                errorSchritt6 = "In diesem Topf kann es nur drei 5er Wünsche geben.";
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                    }
                                                                    else {
                                                                        if(bevorzugteTopfgroessetopf1 === "6") {
                                                                            if(alternativeTopfgroessetopf1 === "7") {
                                                                              if(AnzahlWuensche > 3) {
                                                                                Error1 = 1;
                                                                              }
                                                                              for(let a=0; a<20; a++) {
                                                                                if(wunschxxx[8*a + 6] !== "") {
                                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                }
                                                                              }
                                                                              if(AnzahlRiesenWunsch > 1) {
                                                                                Error2 = 1;
                                                                              }
                                                                              
                                                                              if(Error1 === 1 && Error2 === 1) {
                                                                                errorSchritt6 = "1. In diesem Topf kann es nur einen 7er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                              }
                                                                              else {
                                                                                if(Error1 === 1) {
                                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                }
                                                                                else {
                                                                                  if(Error2 === 1) {
                                                                                    errorSchritt6 = "In diesem Topf kann es nur einen 7er Wunsch geben.";
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                        }
                                                                        else {
                                                                            if(bevorzugteTopfgroessetopf1 === "7") {
                                                                                if(alternativeTopfgroessetopf1 === "6") {
                                                                                  if(AnzahlWuensche > 3) {
                                                                                    Error1 = 1;
                                                                                  }
                                                                                  for(let a=0; a<20; a++) {
                                                                                    if(wunschxxx[8*a + 6] !== "") {
                                                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                    }
                                                                                  }
                                                                                  if(AnzahlRiesenWunsch > 1) {
                                                                                    Error2 = 1;
                                                                                  }
                                                                                  
                                                                                  if(Error1 === 1 && Error2 === 1) {
                                                                                    errorSchritt6 = "1. In diesem Topf kann es nur einen 7er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                  }
                                                                                  else {
                                                                                    if(Error1 === 1) {
                                                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                    }
                                                                                    else {
                                                                                      if(Error2 === 1) {
                                                                                        errorSchritt6 = "In diesem Topf kann es nur einen 7er Wunsch geben.";
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        else {
                                                            if(trainingseinheitentopf1 === "20") {
                                                                if(bevorzugteTopfgroessetopf1 === "3") {
                                                                  if(AnzahlWuensche > 6) {
                                                                    Error1 = 1;
                                                                  }
                                                                  for(let a=0; a<20; a++) {
                                                                    if(wunschxxx[8*a + 3] !== "") {
                                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                    }
                                                                  }
                                                                  if(AnzahlRiesenWunsch > 2) {
                                                                    Error2 = 1;
                                                                  }
                                                                  
                                                                  if(Error1 === 1 && Error2 === 1) {
                                                                    errorSchritt6 = "1. In diesem Topf kann es nur zwei 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                  }
                                                                  else {
                                                                    if(Error1 === 1) {
                                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                    }
                                                                    else {
                                                                      if(Error2 === 1) {
                                                                        errorSchritt6 = "In diesem Topf kann es nur zwei 4er Wünsche geben.";
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                                else {
                                                                    if(bevorzugteTopfgroessetopf1 === "4") {
                                                                      if(AnzahlWuensche > 5) {
                                                                        Error1 = 1;
                                                                      }
                                                                      for(let a=0; a<20; a++) {
                                                                        if(wunschxxx[8*a + 3] !== "") {
                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                        }
                                                                      }
                                                                      if(AnzahlRiesenWunsch > 5) {
                                                                        Error2 = 1;
                                                                      }
                                                            
                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                        errorSchritt6 = "1. In diesem Topf kann es nur fünf 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                      }
                                                                      else {
                                                                        if(Error1 === 1) {
                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                        }
                                                                        else {
                                                                          if(Error2 === 1) {
                                                                            errorSchritt6 = "In diesem Topf kann es nur fünf 4er Wünsche geben.";
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                    else {
                                                                        if(bevorzugteTopfgroessetopf1 === "5") {
                                                                          if(AnzahlWuensche > 4) {
                                                                            Error1 = 1;
                                                                          }
                                                                          for(let a=0; a<20; a++) {
                                                                            if(wunschxxx[8*a + 4] !== "") {
                                                                              AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                            }
                                                                          }
                                                                          if(AnzahlRiesenWunsch > 4) {
                                                                            Error2 = 1;
                                                                          }
                                                                          
                                                                          if(Error1 === 1 && Error2 === 1) {
                                                                            errorSchritt6 = "1. In diesem Topf kann es nur vier 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                          }
                                                                          else {
                                                                            if(Error1 === 1) {
                                                                              errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                            }
                                                                            else {
                                                                              if(Error2 === 1) {
                                                                                errorSchritt6 = "In diesem Topf kann es nur vier 5er Wünsche geben.";
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                        else {
                                                                            if(bevorzugteTopfgroessetopf1 === "6") {
                                                                                if(alternativeTopfgroessetopf1 === "7") {
                                                                                  if(AnzahlWuensche > 3) {
                                                                                    Error1 = 1;
                                                                                  }
                                                                                  for(let a=0; a<20; a++) {
                                                                                    if(wunschxxx[8*a + 6] !== "") {
                                                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                    }
                                                                                  }
                                                                                  if(AnzahlRiesenWunsch > 2) {
                                                                                    Error2 = 1;
                                                                                  }
                                                                                  
                                                                                  if(Error1 === 1 && Error2 === 1) {
                                                                                    errorSchritt6 = "1. In diesem Topf kann es nur zwei 7er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                  }
                                                                                  else {
                                                                                    if(Error1 === 1) {
                                                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                    }
                                                                                    else {
                                                                                      if(Error2 === 1) {
                                                                                        errorSchritt6 = "In diesem Topf kann es nur zwei 7er Wünsche geben.";
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                            }
                                                                            else {
                                                                                if(bevorzugteTopfgroessetopf1 === "7") {
                                                                                    if(alternativeTopfgroessetopf1 === "6") {
                                                                                      if(AnzahlWuensche > 3) {
                                                                                        Error1 = 1;
                                                                                      }
                                                                                      for(let a=0; a<20; a++) {
                                                                                        if(wunschxxx[8*a + 6] !== "") {
                                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                        }
                                                                                      }
                                                                                      if(AnzahlRiesenWunsch > 2) {
                                                                                        Error2 = 1;
                                                                                      }
                                                                                      
                                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                                        errorSchritt6 = "1. In diesem Topf kann es nur zwei 7er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                      }
                                                                                      else {
                                                                                        if(Error1 === 1) {
                                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                        }
                                                                                        else {
                                                                                          if(Error2 === 1) {
                                                                                            errorSchritt6 = "In diesem Topf kann es nur zwei 7er Wünsche geben.";
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            else {
                                                                if(trainingseinheitentopf1 === "21") {
                                                                    if(bevorzugteTopfgroessetopf1 === "3") {
                                                                      if(AnzahlWuensche > 7) {
                                                                        Error1 = 1;
                                                                      }
                                                                      for(let a=0; a<20; a++) {
                                                                        if(wunschxxx[8*a + 2] !== "") {
                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                        }
                                                                      }
                                                                      if(AnzahlRiesenWunsch > 7) {
                                                                        Error2 = 1;
                                                                      }
                                                            
                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                        errorSchritt6 = "1. In diesem Topf kann es nur sieben 3er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                      }
                                                                      else {
                                                                        if(Error1 === 1) {
                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                        }
                                                                        else {
                                                                          if(Error2 === 1) {
                                                                            errorSchritt6 = "In diesem Topf kann es nur sieben 3er Wünsche geben.";
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                    else {
                                                                        if(bevorzugteTopfgroessetopf1 === "4") {
                                                                          if(alternativeTopfgroessetopf1 === "3") {
                                                                            if(AnzahlWuensche > 6) {
                                                                              Error1 = 1;
                                                                            }
                                                                            for(let a=0; a<20; a++) {
                                                                              if(wunschxxx[8*a + 3] !== "") {
                                                                                AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                              }
                                                                            }
                                                                            if(AnzahlRiesenWunsch > 3) {
                                                                              Error2 = 1;
                                                                            }
                                                                            
                                                                            if(Error1 === 1 && Error2 === 1) {
                                                                              errorSchritt6 = "1. In diesem Topf kann es nur drei 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                            }
                                                                            else {
                                                                              if(Error1 === 1) {
                                                                                errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                              }
                                                                              else {
                                                                                if(Error2 === 1) {
                                                                                  errorSchritt6 = "In diesem Topf kann es nur drei 4er Wünsche geben.";
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                          else {
                                                                            if(alternativeTopfgroessetopf1 === "5") {
                                                                              if(AnzahlWuensche > 5) {
                                                                                Error1 = 1;
                                                                              }
                                                                              for(let a=0; a<20; a++) {
                                                                                if(wunschxxx[8*a + 4] !== "") {
                                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                }
                                                                              }
                                                                              if(AnzahlRiesenWunsch > 1) {
                                                                                Error2 = 1;
                                                                              }
                                                                              
                                                                              if(Error1 === 1 && Error2 === 1) {
                                                                                errorSchritt6 = "1. In diesem Topf kann es nur einen 5er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                              }
                                                                              else {
                                                                                if(Error1 === 1) {
                                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                }
                                                                                else {
                                                                                  if(Error2 === 1) {
                                                                                    errorSchritt6 = "In diesem Topf kann es nur einen 5er Wunsch geben.";
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                        else {
                                                                            if(bevorzugteTopfgroessetopf1 === "5") {
                                                                                if(alternativeTopfgroessetopf1 === "6") {
                                                                                  if(AnzahlWuensche > 4) {
                                                                                    Error1 = 1;
                                                                                  }
                                                                                  for(let a=0; a<20; a++) {
                                                                                    if(wunschxxx[8*a + 5] !== "") {
                                                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                    }
                                                                                  }
                                                                                  if(AnzahlRiesenWunsch > 1) {
                                                                                    Error2 = 1;
                                                                                  }
                                                                                  
                                                                                  if(Error1 === 1 && Error2 === 1) {
                                                                                    errorSchritt6 = "1. In diesem Topf kann es nur einen 6er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                  }
                                                                                  else {
                                                                                    if(Error1 === 1) {
                                                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                    }
                                                                                    else {
                                                                                      if(Error2 === 1) {
                                                                                        errorSchritt6 = "In diesem Topf kann es nur einen 6er Wunsch geben.";
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                            }
                                                                            else {
                                                                                if(bevorzugteTopfgroessetopf1 === "6") {
                                                                                    if(alternativeTopfgroessetopf1 === "5") {
                                                                                      if(AnzahlWuensche > 4) {
                                                                                        Error1 = 1;
                                                                                      }
                                                                                      for(let a=0; a<20; a++) {
                                                                                        if(wunschxxx[8*a + 5] !== "") {
                                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                        }
                                                                                      }
                                                                                      if(AnzahlRiesenWunsch > 1) {
                                                                                        Error2 = 1;
                                                                                      }
                                                                                      
                                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                                        errorSchritt6 = "1. In diesem Topf kann es nur einen 6er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                      }
                                                                                      else {
                                                                                        if(Error1 === 1) {
                                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                        }
                                                                                        else {
                                                                                          if(Error2 === 1) {
                                                                                            errorSchritt6 = "In diesem Topf kann es nur einen 6er Wunsch geben.";
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                }
                                                                                else {
                                                                                    if(bevorzugteTopfgroessetopf1 === "7") {
                                                                                      if(AnzahlWuensche > 3) {
                                                                                        Error1 = 1;
                                                                                      }
                                                                                      for(let a=0; a<20; a++) {
                                                                                        if(wunschxxx[8*a + 6] !== "") {
                                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                        }
                                                                                      }
                                                                                      if(AnzahlRiesenWunsch > 3) {
                                                                                        Error2 = 1;
                                                                                      }
                                                                                      
                                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                                        errorSchritt6 = "1. In diesem Topf kann es nur drei 7er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                      }
                                                                                      else {
                                                                                        if(Error1 === 1) {
                                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                        }
                                                                                        else {
                                                                                          if(Error2 === 1) {
                                                                                            errorSchritt6 = "In diesem Topf kann es nur drei 7er Wünsche geben.";
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                else {
                                                                    if(trainingseinheitentopf1 === "22") {
                                                                        if(bevorzugteTopfgroessetopf1 === "3") {
                                                                          if(AnzahlWuensche > 7) {
                                                                            Error1 = 1;
                                                                          }
                                                                          for(let a=0; a<20; a++) {
                                                                            if(wunschxxx[8*a + 3] !== "") {
                                                                              AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                            }
                                                                          }
                                                                          if(AnzahlRiesenWunsch > 1) {
                                                                            Error2 = 1;
                                                                          }
                                                                          
                                                                          if(Error1 === 1 && Error2 === 1) {
                                                                            errorSchritt6 = "1. In diesem Topf kann es nur einen 4er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                          }
                                                                          else {
                                                                            if(Error1 === 1) {
                                                                              errorSchritt6 = "In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                            }
                                                                            else {
                                                                              if(Error2 === 1) {
                                                                                errorSchritt6 = "In diesem Topf kann es nur einen 4er Wunsch geben.";
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                        else {
                                                                            if(bevorzugteTopfgroessetopf1 === "4") {
                                                                              if(alternativeTopfgroessetopf1 === "3") {
                                                                                if(AnzahlWuensche > 6) {
                                                                                  Error1 = 1;
                                                                                }
                                                                                for(let a=0; a<20; a++) {
                                                                                  if(wunschxxx[8*a + 3] !== "") {
                                                                                    AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                  }
                                                                                }
                                                                                if(AnzahlRiesenWunsch > 4) {
                                                                                  Error2 = 1;
                                                                                }
                                                                                
                                                                                if(Error1 === 1 && Error2 === 1) {
                                                                                  errorSchritt6 = "1. In diesem Topf kann es nur vier 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                }
                                                                                else {
                                                                                  if(Error1 === 1) {
                                                                                    errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                  }
                                                                                  else {
                                                                                    if(Error2 === 1) {
                                                                                      errorSchritt6 = "In diesem Topf kann es nur vier 4er Wünsche geben.";
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                              else {
                                                                                if(alternativeTopfgroessetopf1 === "5") {
                                                                                  if(AnzahlWuensche > 5) {
                                                                                    Error1 = 1;
                                                                                  }
                                                                                  for(let a=0; a<20; a++) {
                                                                                    if(wunschxxx[8*a + 4] !== "") {
                                                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                    }
                                                                                  }
                                                                                  if(AnzahlRiesenWunsch > 2) {
                                                                                    Error2 = 1;
                                                                                  }
                                                                                  
                                                                                  if(Error1 === 1 && Error2 === 1) {
                                                                                    errorSchritt6 = "1. In diesem Topf kann es nur zwei 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                  }
                                                                                  else {
                                                                                    if(Error1 === 1) {
                                                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                    }
                                                                                    else {
                                                                                      if(Error2 === 1) {
                                                                                        errorSchritt6 = "In diesem Topf kann es nur zwei 5er Wünsche geben.";
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                            else {
                                                                                if(bevorzugteTopfgroessetopf1 === "5") {
                                                                                  if(alternativeTopfgroessetopf1 === "4") {
                                                                                    if(AnzahlWuensche > 5) {
                                                                                      Error1 = 1;
                                                                                    }
                                                                                    for(let a=0; a<20; a++) {
                                                                                      if(wunschxxx[8*a + 4] !== "") {
                                                                                        AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                      }
                                                                                    }
                                                                                    if(AnzahlRiesenWunsch > 2) {
                                                                                      Error2 = 1;
                                                                                    }
                                                                                    
                                                                                    if(Error1 === 1 && Error2 === 1) {
                                                                                      errorSchritt6 = "1. In diesem Topf kann es nur zwei 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                    }
                                                                                    else {
                                                                                      if(Error1 === 1) {
                                                                                        errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                      }
                                                                                      else {
                                                                                        if(Error2 === 1) {
                                                                                          errorSchritt6 = "In diesem Topf kann es nur zwei 5er Wünsche geben.";
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                  else {
                                                                                    if(alternativeTopfgroessetopf1 === "6") {
                                                                                      if(AnzahlWuensche > 4) {
                                                                                        Error1 = 1;
                                                                                      }
                                                                                      for(let a=0; a<20; a++) {
                                                                                        if(wunschxxx[8*a + 5] !== "") {
                                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                        }
                                                                                      }
                                                                                      if(AnzahlRiesenWunsch > 2) {
                                                                                        Error2 = 1;
                                                                                      }
                                                                                      
                                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                                        errorSchritt6 = "1. In diesem Topf kann es nur zwei 6er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                      }
                                                                                      else {
                                                                                        if(Error1 === 1) {
                                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                        }
                                                                                        else {
                                                                                          if(Error2 === 1) {
                                                                                            errorSchritt6 = "In diesem Topf kann es nur zwei 6er Wünsche geben.";
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                                else {
                                                                                    if(bevorzugteTopfgroessetopf1 === "6") {
                                                                                        if(alternativeTopfgroessetopf1 === "5") {
                                                                                          if(AnzahlWuensche > 4) {
                                                                                            Error1 = 1;
                                                                                          }
                                                                                          for(let a=0; a<20; a++) {
                                                                                            if(wunschxxx[8*a + 5] !== "") {
                                                                                              AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                            }
                                                                                          }
                                                                                          if(AnzahlRiesenWunsch > 2) {
                                                                                            Error2 = 1;
                                                                                          }
                                                                                          
                                                                                          if(Error1 === 1 && Error2 === 1) {
                                                                                            errorSchritt6 = "1. In diesem Topf kann es nur zwei 6er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                          }
                                                                                          else {
                                                                                            if(Error1 === 1) {
                                                                                              errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                            }
                                                                                            else {
                                                                                              if(Error2 === 1) {
                                                                                                errorSchritt6 = "In diesem Topf kann es nur zwei 6er Wünsche geben.";
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                    }
                                                                                    else {
                                                                                        if(bevorzugteTopfgroessetopf1 === "7") {
                                                                                            if(alternativeTopfgroessetopf1 === "8") {
                                                                                              if(AnzahlWuensche > 3) {
                                                                                                Error1 = 1;
                                                                                              }
                                                                                              for(let a=0; a<20; a++) {
                                                                                                if(wunschxxx[8*a + 7] !== "") {
                                                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                }
                                                                                              }
                                                                                              if(AnzahlRiesenWunsch > 1) {
                                                                                                Error2 = 1;
                                                                                              }
                                                                                              
                                                                                              if(Error1 === 1 && Error2 === 1) {
                                                                                                errorSchritt6 = "1. In diesem Topf kann es nur einen 8er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                              }
                                                                                              else {
                                                                                                if(Error1 === 1) {
                                                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                }
                                                                                                else {
                                                                                                  if(Error2 === 1) {
                                                                                                    errorSchritt6 = "In diesem Topf kann es nur einen 8er Wunsch geben.";
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                        }
                                                                                        else {
                                                                                            if(bevorzugteTopfgroessetopf1 === "8") {
                                                                                              if(AnzahlWuensche > 3) {
                                                                                                Error1 = 1;
                                                                                              }
                                                                                              for(let a=0; a<20; a++) {
                                                                                                if(wunschxxx[8*a + 7] !== "") {
                                                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                }
                                                                                              }
                                                                                              if(AnzahlRiesenWunsch > 1) {
                                                                                                Error2 = 1;
                                                                                              }
                                                                                              
                                                                                              if(Error1 === 1 && Error2 === 1) {
                                                                                                errorSchritt6 = "1. In diesem Topf kann es nur einen 8er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                              }
                                                                                              else {
                                                                                                if(Error1 === 1) {
                                                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                }
                                                                                                else {
                                                                                                  if(Error2 === 1) {
                                                                                                    errorSchritt6 = "In diesem Topf kann es nur einen 8er Wunsch geben.";
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                    else {
                                                                        if(trainingseinheitentopf1 === "23") {
                                                                            if(bevorzugteTopfgroessetopf1 === "3") {
                                                                              if(AnzahlWuensche > 7) {
                                                                                Error1 = 1;
                                                                              }
                                                                              for(let a=0; a<20; a++) {
                                                                                if(wunschxxx[8*a + 3] !== "") {
                                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                }
                                                                              }
                                                                              if(AnzahlRiesenWunsch > 2) {
                                                                                Error2 = 1;
                                                                              }
                                                                              
                                                                              if(Error1 === 1 && Error2 === 1) {
                                                                                errorSchritt6 = "1. In diesem Topf kann es nur zwei 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                              }
                                                                              else {
                                                                                if(Error1 === 1) {
                                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                }
                                                                                else {
                                                                                  if(Error2 === 1) {
                                                                                    errorSchritt6 = "In diesem Topf kann es nur zwei 4er Wünsche geben.";
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                            else {
                                                                                if(bevorzugteTopfgroessetopf1 === "4") {
                                                                                  if(alternativeTopfgroessetopf1 === "3") {
                                                                                    if(AnzahlWuensche > 6) {
                                                                                      Error1 = 1;
                                                                                    }
                                                                                    for(let a=0; a<20; a++) {
                                                                                      if(wunschxxx[8*a + 3] !== "") {
                                                                                        AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                      }
                                                                                    }
                                                                                    if(AnzahlRiesenWunsch > 5) {
                                                                                      Error2 = 1;
                                                                                    }
                                                                                    
                                                                                    if(Error1 === 1 && Error2 === 1) {
                                                                                      errorSchritt6 = "1. In diesem Topf kann es nur fünf 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                    }
                                                                                    else {
                                                                                      if(Error1 === 1) {
                                                                                        errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                      }
                                                                                      else {
                                                                                        if(Error2 === 1) {
                                                                                          errorSchritt6 = "In diesem Topf kann es nur fünf 4er Wünsche geben.";
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                  else {
                                                                                    if(alternativeTopfgroessetopf1 === "5") {
                                                                                      if(AnzahlWuensche > 5) {
                                                                                        Error1 = 1;
                                                                                      }
                                                                                      for(let a=0; a<20; a++) {
                                                                                        if(wunschxxx[8*a + 4] !== "") {
                                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                        }
                                                                                      }
                                                                                      if(AnzahlRiesenWunsch > 3) {
                                                                                        Error2 = 1;
                                                                                      }
                                                                                      
                                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                                        errorSchritt6 = "1. In diesem Topf kann es nur drei 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                      }
                                                                                      else {
                                                                                        if(Error1 === 1) {
                                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                        }
                                                                                        else {
                                                                                          if(Error2 === 1) {
                                                                                            errorSchritt6 = "In diesem Topf kann es nur drei 5er Wünsche geben.";
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                                else {
                                                                                    if(bevorzugteTopfgroessetopf1 === "5") {
                                                                                      if(alternativeTopfgroessetopf1 === "4") {
                                                                                        if(AnzahlWuensche > 5) {
                                                                                          Error1 = 1;
                                                                                        }
                                                                                        for(let a=0; a<20; a++) {
                                                                                          if(wunschxxx[8*a + 4] !== "") {
                                                                                            AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                          }
                                                                                        }
                                                                                        if(AnzahlRiesenWunsch > 3) {
                                                                                          Error2 = 1;
                                                                                        }
                                                                                        
                                                                                        if(Error1 === 1 && Error2 === 1) {
                                                                                          errorSchritt6 = "1. In diesem Topf kann es nur drei 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                        }
                                                                                        else {
                                                                                          if(Error1 === 1) {
                                                                                            errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                          }
                                                                                          else {
                                                                                            if(Error2 === 1) {
                                                                                              errorSchritt6 = "In diesem Topf kann es nur drei 5er Wünsche geben.";
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                      else {
                                                                                        if(alternativeTopfgroessetopf1 === "6") {
                                                                                          if(AnzahlWuensche > 4) {
                                                                                            Error1 = 1;
                                                                                          }
                                                                                          for(let a=0; a<20; a++) {
                                                                                            if(wunschxxx[8*a + 5] !== "") {
                                                                                              AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                            }
                                                                                          }
                                                                                          if(AnzahlRiesenWunsch > 3) {
                                                                                            Error2 = 1;
                                                                                          }
                                                                                          
                                                                                          if(Error1 === 1 && Error2 === 1) {
                                                                                            errorSchritt6 = "1. In diesem Topf kann es nur drei 6er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                          }
                                                                                          else {
                                                                                            if(Error1 === 1) {
                                                                                              errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                            }
                                                                                            else {
                                                                                              if(Error2 === 1) {
                                                                                                errorSchritt6 = "In diesem Topf kann es nur drei 6er Wünsche geben.";
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                    else {
                                                                                        if(bevorzugteTopfgroessetopf1 === "6") {
                                                                                            if(alternativeTopfgroessetopf1 === "5") {
                                                                                              if(AnzahlWuensche > 4) {
                                                                                                Error1 = 1;
                                                                                              }
                                                                                              for(let a=0; a<20; a++) {
                                                                                                if(wunschxxx[8*a + 5] !== "") {
                                                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                }
                                                                                              }
                                                                                              if(AnzahlRiesenWunsch > 3) {
                                                                                                Error2 = 1;
                                                                                              }
                                                                                              
                                                                                              if(Error1 === 1 && Error2 === 1) {
                                                                                                errorSchritt6 = "1. In diesem Topf kann es nur drei 6er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                              }
                                                                                              else {
                                                                                                if(Error1 === 1) {
                                                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                }
                                                                                                else {
                                                                                                  if(Error2 === 1) {
                                                                                                    errorSchritt6 = "In diesem Topf kann es nur drei 6er Wünsche geben.";
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                        }
                                                                                        else {
                                                                                            if(bevorzugteTopfgroessetopf1 === "7") {
                                                                                                if(alternativeTopfgroessetopf1 === "8") {
                                                                                                  if(AnzahlWuensche > 3) {
                                                                                                    Error1 = 1;
                                                                                                  }
                                                                                                  for(let a=0; a<20; a++) {
                                                                                                    if(wunschxxx[8*a + 7] !== "") {
                                                                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                    }
                                                                                                  }
                                                                                                  if(AnzahlRiesenWunsch > 2) {
                                                                                                    Error2 = 1;
                                                                                                  }
                                                                                                  
                                                                                                  if(Error1 === 1 && Error2 === 1) {
                                                                                                    errorSchritt6 = "1. In diesem Topf kann es nur zwei 8er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                  }
                                                                                                  else {
                                                                                                    if(Error1 === 1) {
                                                                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                    }
                                                                                                    else {
                                                                                                      if(Error2 === 1) {
                                                                                                        errorSchritt6 = "In diesem Topf kann es nur zwei 8er Wünsche geben.";
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                            }
                                                                                            else {
                                                                                                if(bevorzugteTopfgroessetopf1 === "8") {
                                                                                                  if(AnzahlWuensche > 3) {
                                                                                                    Error1 = 1;
                                                                                                  }
                                                                                                  for(let a=0; a<20; a++) {
                                                                                                    if(wunschxxx[8*a + 7] !== "") {
                                                                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                    }
                                                                                                  }
                                                                                                  if(AnzahlRiesenWunsch > 2) {
                                                                                                    Error2 = 1;
                                                                                                  }
                                                                                                  
                                                                                                  if(Error1 === 1 && Error2 === 1) {
                                                                                                    errorSchritt6 = "1. In diesem Topf kann es nur zwei 8er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                  }
                                                                                                  else {
                                                                                                    if(Error1 === 1) {
                                                                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                    }
                                                                                                    else {
                                                                                                      if(Error2 === 1) {
                                                                                                        errorSchritt6 = "In diesem Topf kann es nur zwei 8er Wünsche geben.";
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                        else {
                                                                            if(trainingseinheitentopf1 === "24") {
                                                                                if(bevorzugteTopfgroessetopf1 === "3") {
                                                                                  if(AnzahlWuensche > 8) {
                                                                                    Error1 = 1;
                                                                                  }
                                                                                  for(let a=0; a<20; a++) {
                                                                                    if(wunschxxx[8*a + 2] !== "") {
                                                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                    }
                                                                                  }
                                                                                  if(AnzahlRiesenWunsch > 8) {
                                                                                    Error2 = 1;
                                                                                  }
                                                                        
                                                                                  if(Error1 === 1 && Error2 === 1) {
                                                                                    errorSchritt6 = "1. In diesem Topf kann es nur acht 3er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 8 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                  }
                                                                                  else {
                                                                                    if(Error1 === 1) {
                                                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 8 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                    }
                                                                                    else {
                                                                                      if(Error2 === 1) {
                                                                                        errorSchritt6 = "In diesem Topf kann es nur acht 3er Wünsche geben.";
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                                else {
                                                                                    if(bevorzugteTopfgroessetopf1 === "4") {
                                                                                      if(AnzahlWuensche > 6) {
                                                                                        Error1 = 1;
                                                                                      }
                                                                                      for(let a=0; a<20; a++) {
                                                                                        if(wunschxxx[8*a + 3] !== "") {
                                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                        }
                                                                                      }
                                                                                      if(AnzahlRiesenWunsch > 6) {
                                                                                        Error2 = 1;
                                                                                      }
                                                                            
                                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                                        errorSchritt6 = "1. In diesem Topf kann es nur sechs 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                      }
                                                                                      else {
                                                                                        if(Error1 === 1) {
                                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                        }
                                                                                        else {
                                                                                          if(Error2 === 1) {
                                                                                            errorSchritt6 = "In diesem Topf kann es nur sechs 4er Wünsche geben.";
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                    else {
                                                                                        if(bevorzugteTopfgroessetopf1 === "5") {
                                                                                            if(alternativeTopfgroessetopf1 === "4") {
                                                                                              if(AnzahlWuensche > 5) {
                                                                                                Error1 = 1;
                                                                                              }
                                                                                              for(let a=0; a<20; a++) {
                                                                                                if(wunschxxx[8*a + 4] !== "") {
                                                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                }
                                                                                              }
                                                                                              if(AnzahlRiesenWunsch > 4) {
                                                                                                Error2 = 1;
                                                                                              }
                                                                                              
                                                                                              if(Error1 === 1 && Error2 === 1) {
                                                                                                errorSchritt6 = "1. In diesem Topf kann es nur vier 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                              }
                                                                                              else {
                                                                                                if(Error1 === 1) {
                                                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                }
                                                                                                else {
                                                                                                  if(Error2 === 1) {
                                                                                                    errorSchritt6 = "In diesem Topf kann es nur vier 5er Wünsche geben.";
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                        }
                                                                                        else {
                                                                                            if(bevorzugteTopfgroessetopf1 === "6") {
                                                                                              if(AnzahlWuensche > 4) {
                                                                                                Error1 = 1;
                                                                                              }
                                                                                              for(let a=0; a<20; a++) {
                                                                                                if(wunschxxx[8*a + 5] !== "") {
                                                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                }
                                                                                              }
                                                                                              if(AnzahlRiesenWunsch > 4) {
                                                                                                Error2 = 1;
                                                                                              }
                                                                                              
                                                                                              if(Error1 === 1 && Error2 === 1) {
                                                                                                errorSchritt6 = "1. In diesem Topf kann es nur vier 6er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                              }
                                                                                              else {
                                                                                                if(Error1 === 1) {
                                                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                }
                                                                                                else {
                                                                                                  if(Error2 === 1) {
                                                                                                    errorSchritt6 = "In diesem Topf kann es nur vier 6er Wünsche geben.";
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                            else {
                                                                                                if(bevorzugteTopfgroessetopf1 === "8") {
                                                                                                  if(AnzahlWuensche > 3) {
                                                                                                    Error1 = 1;
                                                                                                  }
                                                                                                  for(let a=0; a<20; a++) {
                                                                                                    if(wunschxxx[8*a + 7] !== "") {
                                                                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                    }
                                                                                                  }
                                                                                                  if(AnzahlRiesenWunsch > 3) {
                                                                                                    Error2 = 1;
                                                                                                  }
                                                                                                  
                                                                                                  if(Error1 === 1 && Error2 === 1) {
                                                                                                    errorSchritt6 = "1. In diesem Topf kann es nur drei 8er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                  }
                                                                                                  else {
                                                                                                    if(Error1 === 1) {
                                                                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 3 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                    }
                                                                                                    else {
                                                                                                      if(Error2 === 1) {
                                                                                                        errorSchritt6 = "In diesem Topf kann es nur drei 8er Wünsche geben.";
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                            else {
                                                                                if(trainingseinheitentopf1 === "25") {
                                                                                    if(bevorzugteTopfgroessetopf1 === "3") {
                                                                                      if(AnzahlWuensche > 8) {
                                                                                        Error1 = 1;
                                                                                      }
                                                                                      for(let a=0; a<20; a++) {
                                                                                        if(wunschxxx[8*a + 3] !== "") {
                                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                        }
                                                                                      }
                                                                                      if(AnzahlRiesenWunsch > 1) {
                                                                                        Error2 = 1;
                                                                                      }
                                                                                      
                                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                                        errorSchritt6 = "1. In diesem Topf kann es nur einen 4er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 8 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                      }
                                                                                      else {
                                                                                        if(Error1 === 1) {
                                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 8 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                        }
                                                                                        else {
                                                                                          if(Error2 === 1) {
                                                                                            errorSchritt6 = "In diesem Topf kann es nur einen 4er Wunsch geben.";
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                    else {
                                                                                        if(bevorzugteTopfgroessetopf1 === "4") {
                                                                                          if(alternativeTopfgroessetopf1 === "3") {
                                                                                            if(AnzahlWuensche > 7) {
                                                                                              Error1 = 1;
                                                                                            }
                                                                                            for(let a=0; a<20; a++) {
                                                                                              if(wunschxxx[8*a + 3] !== "") {
                                                                                                AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                              }
                                                                                            }
                                                                                            if(AnzahlRiesenWunsch > 4) {
                                                                                              Error2 = 1;
                                                                                            }
                                                                                            
                                                                                            if(Error1 === 1 && Error2 === 1) {
                                                                                              errorSchritt6 = "1. In diesem Topf kann es nur vier 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                            }
                                                                                            else {
                                                                                              if(Error1 === 1) {
                                                                                                errorSchritt6 = "In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                              }
                                                                                              else {
                                                                                                if(Error2 === 1) {
                                                                                                  errorSchritt6 = "In diesem Topf kann es nur vier 4er Wünsche geben.";
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                          else {
                                                                                            if(alternativeTopfgroessetopf1 === "5") {
                                                                                              if(AnzahlWuensche > 6) {
                                                                                                Error1 = 1;
                                                                                              }
                                                                                              for(let a=0; a<20; a++) {
                                                                                                if(wunschxxx[8*a + 4] !== "") {
                                                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                }
                                                                                              }
                                                                                              if(AnzahlRiesenWunsch > 1) {
                                                                                                Error2 = 1;
                                                                                              }
                                                                                              
                                                                                              if(Error1 === 1 && Error2 === 1) {
                                                                                                errorSchritt6 = "1. In diesem Topf kann es nur einen 5er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                              }
                                                                                              else {
                                                                                                if(Error1 === 1) {
                                                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                }
                                                                                                else {
                                                                                                  if(Error2 === 1) {
                                                                                                    errorSchritt6 = "In diesem Topf kann es nur einen 5er Wunsch geben.";
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                        else {
                                                                                            if(bevorzugteTopfgroessetopf1 === "5") {
                                                                                              if(AnzahlWuensche > 5) {
                                                                                                Error1 = 1;
                                                                                              }
                                                                                              for(let a=0; a<20; a++) {
                                                                                                if(wunschxxx[8*a + 4] !== "") {
                                                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                }
                                                                                              }
                                                                                              if(AnzahlRiesenWunsch > 5) {
                                                                                                Error2 = 1;
                                                                                              }
                                                                                              
                                                                                              if(Error1 === 1 && Error2 === 1) {
                                                                                                errorSchritt6 = "1. In diesem Topf kann es nur fünf 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                              }
                                                                                              else {
                                                                                                if(Error1 === 1) {
                                                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                }
                                                                                                else {
                                                                                                  if(Error2 === 1) {
                                                                                                    errorSchritt6 = "In diesem Topf kann es nur fünf 5er Wünsche geben.";
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                            else {
                                                                                                if(bevorzugteTopfgroessetopf1 === "6") {
                                                                                                    if(alternativeTopfgroessetopf1 === "7") {
                                                                                                      if(AnzahlWuensche > 4) {
                                                                                                        Error1 = 1;
                                                                                                      }
                                                                                                      for(let a=0; a<20; a++) {
                                                                                                        if(wunschxxx[8*a + 6] !== "") {
                                                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                        }
                                                                                                      }
                                                                                                      if(AnzahlRiesenWunsch > 1) {
                                                                                                        Error2 = 1;
                                                                                                      }
                                                                                                      
                                                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                                                        errorSchritt6 = "1. In diesem Topf kann es nur einen 7er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                      }
                                                                                                      else {
                                                                                                        if(Error1 === 1) {
                                                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                        }
                                                                                                        else {
                                                                                                          if(Error2 === 1) {
                                                                                                            errorSchritt6 = "In diesem Topf kann es nur einen 7er Wunsch geben.";
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                }
                                                                                                else {
                                                                                                    if(bevorzugteTopfgroessetopf1 === "7") {
                                                                                                        if(alternativeTopfgroessetopf1 === "6") {
                                                                                                          if(AnzahlWuensche > 4) {
                                                                                                            Error1 = 1;
                                                                                                          }
                                                                                                          for(let a=0; a<20; a++) {
                                                                                                            if(wunschxxx[8*a + 6] !== "") {
                                                                                                              AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                            }
                                                                                                          }
                                                                                                          if(AnzahlRiesenWunsch > 1) {
                                                                                                            Error2 = 1;
                                                                                                          }
                                                                                                          
                                                                                                          if(Error1 === 1 && Error2 === 1) {
                                                                                                            errorSchritt6 = "1. In diesem Topf kann es nur einen 7er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                          }
                                                                                                          else {
                                                                                                            if(Error1 === 1) {
                                                                                                              errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                            }
                                                                                                            else {
                                                                                                              if(Error2 === 1) {
                                                                                                                errorSchritt6 = "In diesem Topf kann es nur einen 7er Wunsch geben.";
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                                else {
                                                                                    if(trainingseinheitentopf1 === "26") {
                                                                                        if(bevorzugteTopfgroessetopf1 === "3") {
                                                                                          if(AnzahlWuensche > 8) {
                                                                                            Error1 = 1;
                                                                                          }
                                                                                          for(let a=0; a<20; a++) {
                                                                                            if(wunschxxx[8*a + 3] !== "") {
                                                                                              AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                            }
                                                                                          }
                                                                                          if(AnzahlRiesenWunsch > 2) {
                                                                                            Error2 = 1;
                                                                                          }
                                                                                          
                                                                                          if(Error1 === 1 && Error2 === 1) {
                                                                                            errorSchritt6 = "1. In diesem Topf kann es nur zwei 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 8 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                          }
                                                                                          else {
                                                                                            if(Error1 === 1) {
                                                                                              errorSchritt6 = "In diesem Topf kann es insgesamt nur 8 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                            }
                                                                                            else {
                                                                                              if(Error2 === 1) {
                                                                                                errorSchritt6 = "In diesem Topf kann es nur zwei 4er Wünsche geben.";
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                        else {
                                                                                            if(bevorzugteTopfgroessetopf1 === "4") {
                                                                                              if(alternativeTopfgroessetopf1 === "3") {
                                                                                                if(AnzahlWuensche > 7) {
                                                                                                  Error1 = 1;
                                                                                                }
                                                                                                for(let a=0; a<20; a++) {
                                                                                                  if(wunschxxx[8*a + 3] !== "") {
                                                                                                    AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                  }
                                                                                                }
                                                                                                if(AnzahlRiesenWunsch > 5) {
                                                                                                  Error2 = 1;
                                                                                                }
                                                                                                
                                                                                                if(Error1 === 1 && Error2 === 1) {
                                                                                                  errorSchritt6 = "1. In diesem Topf kann es nur fünf 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                }
                                                                                                else {
                                                                                                  if(Error1 === 1) {
                                                                                                    errorSchritt6 = "In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                  }
                                                                                                  else {
                                                                                                    if(Error2 === 1) {
                                                                                                      errorSchritt6 = "In diesem Topf kann es nur fünf 4er Wünsche geben.";
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                              else {
                                                                                                if(alternativeTopfgroessetopf1 === "5") {
                                                                                                  if(AnzahlWuensche > 6) {
                                                                                                    Error1 = 1;
                                                                                                  }
                                                                                                  for(let a=0; a<20; a++) {
                                                                                                    if(wunschxxx[8*a + 4] !== "") {
                                                                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                    }
                                                                                                  }
                                                                                                  if(AnzahlRiesenWunsch > 2) {
                                                                                                    Error2 = 1;
                                                                                                  }
                                                                                                  
                                                                                                  if(Error1 === 1 && Error2 === 1) {
                                                                                                    errorSchritt6 = "1. In diesem Topf kann es nur zwei 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                  }
                                                                                                  else {
                                                                                                    if(Error1 === 1) {
                                                                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                    }
                                                                                                    else {
                                                                                                      if(Error2 === 1) {
                                                                                                        errorSchritt6 = "In diesem Topf kann es nur zwei 5er Wünsche geben.";
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                            else {
                                                                                                if(bevorzugteTopfgroessetopf1 === "5") {
                                                                                                  if(alternativeTopfgroessetopf1 === "4") {
                                                                                                    if(AnzahlWuensche > 6) {
                                                                                                      Error1 = 1;
                                                                                                    }
                                                                                                    for(let a=0; a<20; a++) {
                                                                                                      if(wunschxxx[8*a + 4] !== "") {
                                                                                                        AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                      }
                                                                                                    }
                                                                                                    if(AnzahlRiesenWunsch > 2) {
                                                                                                      Error2 = 1;
                                                                                                    }
                                                                                                    
                                                                                                    if(Error1 === 1 && Error2 === 1) {
                                                                                                      errorSchritt6 = "1. In diesem Topf kann es nur zwei 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                    }
                                                                                                    else {
                                                                                                      if(Error1 === 1) {
                                                                                                        errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                      }
                                                                                                      else {
                                                                                                        if(Error2 === 1) {
                                                                                                          errorSchritt6 = "In diesem Topf kann es nur zwei 5er Wünsche geben.";
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                  else {
                                                                                                    if(alternativeTopfgroessetopf1 === "6") {
                                                                                                      if(AnzahlWuensche > 5) {
                                                                                                        Error1 = 1;
                                                                                                      }
                                                                                                      for(let a=0; a<20; a++) {
                                                                                                        if(wunschxxx[8*a + 5] !== "") {
                                                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                        }
                                                                                                      }
                                                                                                      if(AnzahlRiesenWunsch > 1) {
                                                                                                        Error2 = 1;
                                                                                                      }
                                                                                                      
                                                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                                                        errorSchritt6 = "1. In diesem Topf kann es nur einen 6er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                      }
                                                                                                      else {
                                                                                                        if(Error1 === 1) {
                                                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                        }
                                                                                                        else {
                                                                                                          if(Error2 === 1) {
                                                                                                            errorSchritt6 = "In diesem Topf kann es nur einen 6er Wunsch geben.";
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                                else {
                                                                                                    if(bevorzugteTopfgroessetopf1 === "6") {
                                                                                                      if(alternativeTopfgroessetopf1 === "5") {
                                                                                                        if(AnzahlWuensche > 5) {
                                                                                                          Error1 = 1;
                                                                                                        }
                                                                                                        for(let a=0; a<20; a++) {
                                                                                                          if(wunschxxx[8*a + 5] !== "") {
                                                                                                            AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                          }
                                                                                                        }
                                                                                                        if(AnzahlRiesenWunsch > 1) {
                                                                                                          Error2 = 1;
                                                                                                        }
                                                                                                        
                                                                                                        if(Error1 === 1 && Error2 === 1) {
                                                                                                          errorSchritt6 = "1. In diesem Topf kann es nur einen 6er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                        }
                                                                                                        else {
                                                                                                          if(Error1 === 1) {
                                                                                                            errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                          }
                                                                                                          else {
                                                                                                            if(Error2 === 1) {
                                                                                                              errorSchritt6 = "In diesem Topf kann es nur einen 6er Wunsch geben.";
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                      else {
                                                                                                        if(alternativeTopfgroessetopf1 === "7") {
                                                                                                          if(AnzahlWuensche > 4) {
                                                                                                            Error1 = 1;
                                                                                                          }
                                                                                                          for(let a=0; a<20; a++) {
                                                                                                            if(wunschxxx[8*a + 6] !== "") {
                                                                                                              AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                            }
                                                                                                          }
                                                                                                          if(AnzahlRiesenWunsch > 2) {
                                                                                                            Error2 = 1;
                                                                                                          }
                                                                                                          
                                                                                                          if(Error1 === 1 && Error2 === 1) {
                                                                                                            errorSchritt6 = "1. In diesem Topf kann es nur zwei 7er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                          }
                                                                                                          else {
                                                                                                            if(Error1 === 1) {
                                                                                                              errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                            }
                                                                                                            else {
                                                                                                              if(Error2 === 1) {
                                                                                                                errorSchritt6 = "In diesem Topf kann es nur zwei 7er Wünsche geben.";
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                    else {
                                                                                                        if(bevorzugteTopfgroessetopf1 === "7") {
                                                                                                            if(alternativeTopfgroessetopf1 === "6") {
                                                                                                              if(AnzahlWuensche > 4) {
                                                                                                                Error1 = 1;
                                                                                                              }
                                                                                                              for(let a=0; a<20; a++) {
                                                                                                                if(wunschxxx[8*a + 6] !== "") {
                                                                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                }
                                                                                                              }
                                                                                                              if(AnzahlRiesenWunsch > 2) {
                                                                                                                Error2 = 1;
                                                                                                              }
                                                                                                              
                                                                                                              if(Error1 === 1 && Error2 === 1) {
                                                                                                                errorSchritt6 = "1. In diesem Topf kann es nur zwei 7er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                              }
                                                                                                              else {
                                                                                                                if(Error1 === 1) {
                                                                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                }
                                                                                                                else {
                                                                                                                  if(Error2 === 1) {
                                                                                                                    errorSchritt6 = "In diesem Topf kann es nur zwei 7er Wünsche geben.";
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                    else {
                                                                                        if(trainingseinheitentopf1 === "27") {
                                                                                            if(bevorzugteTopfgroessetopf1 === "3") {
                                                                                              if(AnzahlWuensche > 9) {
                                                                                                Error1 = 1;
                                                                                              }
                                                                                              for(let a=0; a<20; a++) {
                                                                                                if(wunschxxx[8*a + 2] !== "") {
                                                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                }
                                                                                              }
                                                                                              if(AnzahlRiesenWunsch > 9) {
                                                                                                Error2 = 1;
                                                                                              }
                                                                                    
                                                                                              if(Error1 === 1 && Error2 === 1) {
                                                                                                errorSchritt6 = "1. In diesem Topf kann es nur neun 3er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 9 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                              }
                                                                                              else {
                                                                                                if(Error1 === 1) {
                                                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 9 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                }
                                                                                                else {
                                                                                                  if(Error2 === 1) {
                                                                                                    errorSchritt6 = "In diesem Topf kann es nur neun 3er Wünsche geben.";
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                            else {
                                                                                                if(bevorzugteTopfgroessetopf1 === "4") {
                                                                                                  if(alternativeTopfgroessetopf1 === "3") {
                                                                                                    if(AnzahlWuensche > 7) {
                                                                                                      Error1 = 1;
                                                                                                    }
                                                                                                    for(let a=0; a<20; a++) {
                                                                                                      if(wunschxxx[8*a + 3] !== "") {
                                                                                                        AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                      }
                                                                                                    }
                                                                                                    if(AnzahlRiesenWunsch > 6) {
                                                                                                      Error2 = 1;
                                                                                                    }
                                                                                                    
                                                                                                    if(Error1 === 1 && Error2 === 1) {
                                                                                                      errorSchritt6 = "1. In diesem Topf kann es nur sechs 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                    }
                                                                                                    else {
                                                                                                      if(Error1 === 1) {
                                                                                                        errorSchritt6 = "In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                      }
                                                                                                      else {
                                                                                                        if(Error2 === 1) {
                                                                                                          errorSchritt6 = "In diesem Topf kann es nur sechs 4er Wünsche geben.";
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                  else {
                                                                                                    if(alternativeTopfgroessetopf1 === "5") {
                                                                                                      if(AnzahlWuensche > 6) {
                                                                                                        Error1 = 1;
                                                                                                      }
                                                                                                      for(let a=0; a<20; a++) {
                                                                                                        if(wunschxxx[8*a + 4] !== "") {
                                                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                        }
                                                                                                      }
                                                                                                      if(AnzahlRiesenWunsch > 3) {
                                                                                                        Error2 = 1;
                                                                                                      }
                                                                                                      
                                                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                                                        errorSchritt6 = "1. In diesem Topf kann es nur drei 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                      }
                                                                                                      else {
                                                                                                        if(Error1 === 1) {
                                                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                        }
                                                                                                        else {
                                                                                                          if(Error2 === 1) {
                                                                                                            errorSchritt6 = "In diesem Topf kann es nur drei 5er Wünsche geben.";
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                                else {
                                                                                                    if(bevorzugteTopfgroessetopf1 === "5") {
                                                                                                      if(alternativeTopfgroessetopf1 === "4") {
                                                                                                        if(AnzahlWuensche > 6) {
                                                                                                          Error1 = 1;
                                                                                                        }
                                                                                                        for(let a=0; a<20; a++) {
                                                                                                          if(wunschxxx[8*a + 4] !== "") {
                                                                                                            AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                          }
                                                                                                        }
                                                                                                        if(AnzahlRiesenWunsch > 3) {
                                                                                                          Error2 = 1;
                                                                                                        }
                                                                                                        
                                                                                                        if(Error1 === 1 && Error2 === 1) {
                                                                                                          errorSchritt6 = "1. In diesem Topf kann es nur drei 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                        }
                                                                                                        else {
                                                                                                          if(Error1 === 1) {
                                                                                                            errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                          }
                                                                                                          else {
                                                                                                            if(Error2 === 1) {
                                                                                                              errorSchritt6 = "In diesem Topf kann es nur drei 5er Wünsche geben.";
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                      else {
                                                                                                        if(alternativeTopfgroessetopf1 === "6") {
                                                                                                          if(AnzahlWuensche > 5) {
                                                                                                            Error1 = 1;
                                                                                                          }
                                                                                                          for(let a=0; a<20; a++) {
                                                                                                            if(wunschxxx[8*a + 5] !== "") {
                                                                                                              AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                            }
                                                                                                          }
                                                                                                          if(AnzahlRiesenWunsch > 2) {
                                                                                                            Error2 = 1;
                                                                                                          }
                                                                                                          
                                                                                                          if(Error1 === 1 && Error2 === 1) {
                                                                                                            errorSchritt6 = "1. In diesem Topf kann es nur zwei 6er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                          }
                                                                                                          else {
                                                                                                            if(Error1 === 1) {
                                                                                                              errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                            }
                                                                                                            else {
                                                                                                              if(Error2 === 1) {
                                                                                                                errorSchritt6 = "In diesem Topf kann es nur zwei 6er Wünsche geben.";
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                    else {
                                                                                                        if(bevorzugteTopfgroessetopf1 === "6") {
                                                                                                          if(alternativeTopfgroessetopf1 === "5") {
                                                                                                            if(AnzahlWuensche > 5) {
                                                                                                              Error1 = 1;
                                                                                                            }
                                                                                                            for(let a=0; a<20; a++) {
                                                                                                              if(wunschxxx[8*a + 5] !== "") {
                                                                                                                AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                              }
                                                                                                            }
                                                                                                            if(AnzahlRiesenWunsch > 2) {
                                                                                                              Error2 = 1;
                                                                                                            }
                                                                                                            
                                                                                                            if(Error1 === 1 && Error2 === 1) {
                                                                                                              errorSchritt6 = "1. In diesem Topf kann es nur zwei 6er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                            }
                                                                                                            else {
                                                                                                              if(Error1 === 1) {
                                                                                                                errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                              }
                                                                                                              else {
                                                                                                                if(Error2 === 1) {
                                                                                                                  errorSchritt6 = "In diesem Topf kann es nur zwei 6er Wünsche geben.";
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                          else {
                                                                                                            if(alternativeTopfgroessetopf1 === "7") {
                                                                                                              if(AnzahlWuensche > 4) {
                                                                                                                Error1 = 1;
                                                                                                              }
                                                                                                              for(let a=0; a<20; a++) {
                                                                                                                if(wunschxxx[8*a + 6] !== "") {
                                                                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                }
                                                                                                              }
                                                                                                              if(AnzahlRiesenWunsch > 3) {
                                                                                                                Error2 = 1;
                                                                                                              }
                                                                                                              
                                                                                                              if(Error1 === 1 && Error2 === 1) {
                                                                                                                errorSchritt6 = "1. In diesem Topf kann es nur drei 7er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                              }
                                                                                                              else {
                                                                                                                if(Error1 === 1) {
                                                                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                }
                                                                                                                else {
                                                                                                                  if(Error2 === 1) {
                                                                                                                    errorSchritt6 = "In diesem Topf kann es nur drei 7er Wünsche geben.";
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                        else {
                                                                                                            if(bevorzugteTopfgroessetopf1 === "7") {
                                                                                                                if(alternativeTopfgroessetopf1 === "6") {
                                                                                                                  if(AnzahlWuensche > 4) {
                                                                                                                    Error1 = 1;
                                                                                                                  }
                                                                                                                  for(let a=0; a<20; a++) {
                                                                                                                    if(wunschxxx[8*a + 6] !== "") {
                                                                                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                    }
                                                                                                                  }
                                                                                                                  if(AnzahlRiesenWunsch > 3) {
                                                                                                                    Error2 = 1;
                                                                                                                  }
                                                                                                                  
                                                                                                                  if(Error1 === 1 && Error2 === 1) {
                                                                                                                    errorSchritt6 = "1. In diesem Topf kann es nur drei 7er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                  }
                                                                                                                  else {
                                                                                                                    if(Error1 === 1) {
                                                                                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                    }
                                                                                                                    else {
                                                                                                                      if(Error2 === 1) {
                                                                                                                        errorSchritt6 = "In diesem Topf kann es nur drei 7er Wünsche geben.";
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                        else {
                                                                                            if(trainingseinheitentopf1 === "28") {
                                                                                                if(bevorzugteTopfgroessetopf1 === "3") {
                                                                                                  if(AnzahlWuensche > 9) {
                                                                                                    Error1 = 1;
                                                                                                  }
                                                                                                  for(let a=0; a<20; a++) {
                                                                                                    if(wunschxxx[8*a + 3] !== "") {
                                                                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                    }
                                                                                                  }
                                                                                                  if(AnzahlRiesenWunsch > 1) {
                                                                                                    Error2 = 1;
                                                                                                  }
                                                                                                  
                                                                                                  if(Error1 === 1 && Error2 === 1) {
                                                                                                    errorSchritt6 = "1. In diesem Topf kann es nur einen 4er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 9 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                  }
                                                                                                  else {
                                                                                                    if(Error1 === 1) {
                                                                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 9 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                    }
                                                                                                    else {
                                                                                                      if(Error2 === 1) {
                                                                                                        errorSchritt6 = "In diesem Topf kann es nur einen 4er Wunsch geben.";
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                                else {
                                                                                                    if(bevorzugteTopfgroessetopf1 === "4") {
                                                                                                      if(AnzahlWuensche > 7) {
                                                                                                        Error1 = 1;
                                                                                                      }
                                                                                                      for(let a=0; a<20; a++) {
                                                                                                        if(wunschxxx[8*a + 3] !== "") {
                                                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                        }
                                                                                                      }
                                                                                                      if(AnzahlRiesenWunsch > 7) {
                                                                                                        Error2 = 1;
                                                                                                      }
                                                                                            
                                                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                                                        errorSchritt6 = "1. In diesem Topf kann es nur sieben 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                      }
                                                                                                      else {
                                                                                                        if(Error1 === 1) {
                                                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                        }
                                                                                                        else {
                                                                                                          if(Error2 === 1) {
                                                                                                            errorSchritt6 = "In diesem Topf kann es nur sieben 4er Wünsche geben.";
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                    else {
                                                                                                        if(bevorzugteTopfgroessetopf1 === "5") {
                                                                                                          if(alternativeTopfgroessetopf1 === "4") {
                                                                                                            if(AnzahlWuensche > 6) {
                                                                                                              Error1 = 1;
                                                                                                            }
                                                                                                            for(let a=0; a<20; a++) {
                                                                                                              if(wunschxxx[8*a + 4] !== "") {
                                                                                                                AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                              }
                                                                                                            }
                                                                                                            if(AnzahlRiesenWunsch > 4) {
                                                                                                              Error2 = 1;
                                                                                                            }
                                                                                                            
                                                                                                            if(Error1 === 1 && Error2 === 1) {
                                                                                                              errorSchritt6 = "1. In diesem Topf kann es nur vier 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                            }
                                                                                                            else {
                                                                                                              if(Error1 === 1) {
                                                                                                                errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                              }
                                                                                                              else {
                                                                                                                if(Error2 === 1) {
                                                                                                                  errorSchritt6 = "In diesem Topf kann es nur vier 5er Wünsche geben.";
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                          else {
                                                                                                            if(alternativeTopfgroessetopf1 === "6") {
                                                                                                              if(AnzahlWuensche > 5) {
                                                                                                                Error1 = 1;
                                                                                                              }
                                                                                                              for(let a=0; a<20; a++) {
                                                                                                                if(wunschxxx[8*a + 5] !== "") {
                                                                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                }
                                                                                                              }
                                                                                                              if(AnzahlRiesenWunsch > 3) {
                                                                                                                Error2 = 1;
                                                                                                              }
                                                                                                              
                                                                                                              if(Error1 === 1 && Error2 === 1) {
                                                                                                                errorSchritt6 = "1. In diesem Topf kann es nur drei 6er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                              }
                                                                                                              else {
                                                                                                                if(Error1 === 1) {
                                                                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                }
                                                                                                                else {
                                                                                                                  if(Error2 === 1) {
                                                                                                                    errorSchritt6 = "In diesem Topf kann es nur drei 6er Wünsche geben.";
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                        else {
                                                                                                            if(bevorzugteTopfgroessetopf1 === "6") {
                                                                                                                if(alternativeTopfgroessetopf1 === "5") {
                                                                                                                  if(AnzahlWuensche > 5) {
                                                                                                                    Error1 = 1;
                                                                                                                  }
                                                                                                                  for(let a=0; a<20; a++) {
                                                                                                                    if(wunschxxx[8*a + 5] !== "") {
                                                                                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                    }
                                                                                                                  }
                                                                                                                  if(AnzahlRiesenWunsch > 3) {
                                                                                                                    Error2 = 1;
                                                                                                                  }
                                                                                                                  
                                                                                                                  if(Error1 === 1 && Error2 === 1) {
                                                                                                                    errorSchritt6 = "1. In diesem Topf kann es nur drei 6er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                  }
                                                                                                                  else {
                                                                                                                    if(Error1 === 1) {
                                                                                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                    }
                                                                                                                    else {
                                                                                                                      if(Error2 === 1) {
                                                                                                                        errorSchritt6 = "In diesem Topf kann es nur drei 6er Wünsche geben.";
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                }
                                                                                                            }
                                                                                                            else {
                                                                                                                if(bevorzugteTopfgroessetopf1 === "7") {
                                                                                                                  if(AnzahlWuensche > 4) {
                                                                                                                    Error1 = 1;
                                                                                                                  }
                                                                                                                  for(let a=0; a<20; a++) {
                                                                                                                    if(wunschxxx[8*a + 6] !== "") {
                                                                                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                    }
                                                                                                                  }
                                                                                                                  if(AnzahlRiesenWunsch > 4) {
                                                                                                                    Error2 = 1;
                                                                                                                  }
                                                                                                                  
                                                                                                                  if(Error1 === 1 && Error2 === 1) {
                                                                                                                    errorSchritt6 = "1. In diesem Topf kann es nur vier 7er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                  }
                                                                                                                  else {
                                                                                                                    if(Error1 === 1) {
                                                                                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                    }
                                                                                                                    else {
                                                                                                                      if(Error2 === 1) {
                                                                                                                        errorSchritt6 = "In diesem Topf kann es nur vier 7er Wünsche geben.";
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                            else {
                                                                                                if(trainingseinheitentopf1 === "29") {
                                                                                                    if(bevorzugteTopfgroessetopf1 === "3") {
                                                                                                      if(AnzahlWuensche > 9) {
                                                                                                        Error1 = 1;
                                                                                                      }
                                                                                                      for(let a=0; a<20; a++) {
                                                                                                        if(wunschxxx[8*a + 3] !== "") {
                                                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                        }
                                                                                                      }
                                                                                                      if(AnzahlRiesenWunsch > 2) {
                                                                                                        Error2 = 1;
                                                                                                      }
                                                                                                      
                                                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                                                        errorSchritt6 = "1. In diesem Topf kann es nur zwei 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 9 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                      }
                                                                                                      else {
                                                                                                        if(Error1 === 1) {
                                                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 9 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                        }
                                                                                                        else {
                                                                                                          if(Error2 === 1) {
                                                                                                            errorSchritt6 = "In diesem Topf kann es nur zwei 4er Wünsche geben.";
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                    else {
                                                                                                        if(bevorzugteTopfgroessetopf1 === "4") {
                                                                                                          if(alternativeTopfgroessetopf1 === "3") {
                                                                                                            if(AnzahlWuensche > 8) {
                                                                                                              Error1 = 1;
                                                                                                            }
                                                                                                            for(let a=0; a<20; a++) {
                                                                                                              if(wunschxxx[8*a + 3] !== "") {
                                                                                                                AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                              }
                                                                                                            }
                                                                                                            if(AnzahlRiesenWunsch > 5) {
                                                                                                              Error2 = 1;
                                                                                                            }
                                                                                                            
                                                                                                            if(Error1 === 1 && Error2 === 1) {
                                                                                                              errorSchritt6 = "1. In diesem Topf kann es nur fünf 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 8 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                            }
                                                                                                            else {
                                                                                                              if(Error1 === 1) {
                                                                                                                errorSchritt6 = "In diesem Topf kann es insgesamt nur 8 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                              }
                                                                                                              else {
                                                                                                                if(Error2 === 1) {
                                                                                                                  errorSchritt6 = "In diesem Topf kann es nur fünf 4er Wünsche geben.";
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                          else {
                                                                                                            if(alternativeTopfgroessetopf1 === "5") {
                                                                                                              if(AnzahlWuensche > 7) {
                                                                                                                Error1 = 1;
                                                                                                              }
                                                                                                              for(let a=0; a<20; a++) {
                                                                                                                if(wunschxxx[8*a + 4] !== "") {
                                                                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                }
                                                                                                              }
                                                                                                              if(AnzahlRiesenWunsch > 1) {
                                                                                                                Error2 = 1;
                                                                                                              }
                                                                                                              
                                                                                                              if(Error1 === 1 && Error2 === 1) {
                                                                                                                errorSchritt6 = "1. In diesem Topf kann es nur einen 5er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                              }
                                                                                                              else {
                                                                                                                if(Error1 === 1) {
                                                                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                }
                                                                                                                else {
                                                                                                                  if(Error2 === 1) {
                                                                                                                    errorSchritt6 = "In diesem Topf kann es nur einen 5er Wunsch geben.";
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                        else {
                                                                                                            if(bevorzugteTopfgroessetopf1 === "5") {
                                                                                                              if(alternativeTopfgroessetopf1 === "4") {
                                                                                                                if(AnzahlWuensche > 6) {
                                                                                                                  Error1 = 1;
                                                                                                                }
                                                                                                                for(let a=0; a<20; a++) {
                                                                                                                  if(wunschxxx[8*a + 4] !== "") {
                                                                                                                    AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                  }
                                                                                                                }
                                                                                                                if(AnzahlRiesenWunsch > 5) {
                                                                                                                  Error2 = 1;
                                                                                                                }
                                                                                                                
                                                                                                                if(Error1 === 1 && Error2 === 1) {
                                                                                                                  errorSchritt6 = "1. In diesem Topf kann es nur fünf 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                }
                                                                                                                else {
                                                                                                                  if(Error1 === 1) {
                                                                                                                    errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                  }
                                                                                                                  else {
                                                                                                                    if(Error2 === 1) {
                                                                                                                      errorSchritt6 = "In diesem Topf kann es nur fünf 5er Wünsche geben.";
                                                                                                                    }
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                              else {
                                                                                                                if(alternativeTopfgroessetopf1 === "6") {
                                                                                                                  if(AnzahlWuensche > 5) {
                                                                                                                    Error1 = 1;
                                                                                                                  }
                                                                                                                  for(let a=0; a<20; a++) {
                                                                                                                    if(wunschxxx[8*a + 5] !== "") {
                                                                                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                    }
                                                                                                                  }
                                                                                                                  if(AnzahlRiesenWunsch > 4) {
                                                                                                                    Error2 = 1;
                                                                                                                  }
                                                                                                                  
                                                                                                                  if(Error1 === 1 && Error2 === 1) {
                                                                                                                    errorSchritt6 = "1. In diesem Topf kann es nur vier 6er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                  }
                                                                                                                  else {
                                                                                                                    if(Error1 === 1) {
                                                                                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                    }
                                                                                                                    else {
                                                                                                                      if(Error2 === 1) {
                                                                                                                        errorSchritt6 = "In diesem Topf kann es nur vier 6er Wünsche geben.";
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                            else {
                                                                                                                if(bevorzugteTopfgroessetopf1 === "6") {
                                                                                                                    if(alternativeTopfgroessetopf1 === "5") {
                                                                                                                      if(AnzahlWuensche > 5) {
                                                                                                                        Error1 = 1;
                                                                                                                      }
                                                                                                                      for(let a=0; a<20; a++) {
                                                                                                                        if(wunschxxx[8*a + 5] !== "") {
                                                                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                        }
                                                                                                                      }
                                                                                                                      if(AnzahlRiesenWunsch > 4) {
                                                                                                                        Error2 = 1;
                                                                                                                      }
                                                                                                                      
                                                                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                                                                        errorSchritt6 = "1. In diesem Topf kann es nur vier 6er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                      }
                                                                                                                      else {
                                                                                                                        if(Error1 === 1) {
                                                                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                        }
                                                                                                                        else {
                                                                                                                          if(Error2 === 1) {
                                                                                                                            errorSchritt6 = "In diesem Topf kann es nur vier 6er Wünsche geben.";
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                    }
                                                                                                                }
                                                                                                                else {
                                                                                                                    if(bevorzugteTopfgroessetopf1 === "7") {
                                                                                                                        if(alternativeTopfgroessetopf1 === "8") {
                                                                                                                          if(AnzahlWuensche > 4) {
                                                                                                                            Error1 = 1;
                                                                                                                          }
                                                                                                                          for(let a=0; a<20; a++) {
                                                                                                                            if(wunschxxx[8*a + 7] !== "") {
                                                                                                                              AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                            }
                                                                                                                          }
                                                                                                                          if(AnzahlRiesenWunsch > 1) {
                                                                                                                            Error2 = 1;
                                                                                                                          }
                                                                                                                          
                                                                                                                          if(Error1 === 1 && Error2 === 1) {
                                                                                                                            errorSchritt6 = "1. In diesem Topf kann es nur einen 8er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                          }
                                                                                                                          else {
                                                                                                                            if(Error1 === 1) {
                                                                                                                              errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                            }
                                                                                                                            else {
                                                                                                                              if(Error2 === 1) {
                                                                                                                                errorSchritt6 = "In diesem Topf kann es nur einen 8er Wunsch geben.";
                                                                                                                              }
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                    }
                                                                                                                    if(bevorzugteTopfgroessetopf1 === "8") {
                                                                                                                      if(AnzahlWuensche > 4) {
                                                                                                                        Error1 = 1;
                                                                                                                      }
                                                                                                                      for(let a=0; a<20; a++) {
                                                                                                                        if(wunschxxx[8*a + 7] !== "") {
                                                                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                        }
                                                                                                                      }
                                                                                                                      if(AnzahlRiesenWunsch > 1) {
                                                                                                                        Error2 = 1;
                                                                                                                      }
                                                                                                                      
                                                                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                                                                        errorSchritt6 = "1. In diesem Topf kann es nur einen 8er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                      }
                                                                                                                      else {
                                                                                                                        if(Error1 === 1) {
                                                                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                        }
                                                                                                                        else {
                                                                                                                          if(Error2 === 1) {
                                                                                                                            errorSchritt6 = "In diesem Topf kann es nur einen 8er Wunsch geben.";
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                                else {
                                                                                                    if(trainingseinheitentopf1 === "30") {
                                                                                                        if(bevorzugteTopfgroessetopf1 === "3") {
                                                                                                          if(AnzahlWuensche > 10) {
                                                                                                            Error1 = 1;
                                                                                                          }
                                                                                                          for(let a=0; a<20; a++) {
                                                                                                            if(wunschxxx[8*a + 2] !== "") {
                                                                                                              AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                            }
                                                                                                          }
                                                                                                          if(AnzahlRiesenWunsch > 10) {
                                                                                                            Error2 = 1;
                                                                                                          }
                                                                                                
                                                                                                          if(Error1 === 1 && Error2 === 1) {
                                                                                                            errorSchritt6 = "1. In diesem Topf kann es nur zehn 3er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 10 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                          }
                                                                                                          else {
                                                                                                            if(Error1 === 1) {
                                                                                                              errorSchritt6 = "In diesem Topf kann es insgesamt nur 10 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                            }
                                                                                                            else {
                                                                                                              if(Error2 === 1) {
                                                                                                                errorSchritt6 = "In diesem Topf kann es nur zehn 3er Wünsche geben.";
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                        else {
                                                                                                            if(bevorzugteTopfgroessetopf1 === "4") {
                                                                                                              if(alternativeTopfgroessetopf1 === "3") {
                                                                                                                if(AnzahlWuensche > 8) {
                                                                                                                  Error1 = 1;
                                                                                                                }
                                                                                                                for(let a=0; a<20; a++) {
                                                                                                                  if(wunschxxx[8*a + 3] !== "") {
                                                                                                                    AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                  }
                                                                                                                }
                                                                                                                if(AnzahlRiesenWunsch > 6) {
                                                                                                                  Error2 = 1;
                                                                                                                }
                                                                                                                
                                                                                                                if(Error1 === 1 && Error2 === 1) {
                                                                                                                  errorSchritt6 = "1. In diesem Topf kann es nur sechs 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 8 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                }
                                                                                                                else {
                                                                                                                  if(Error1 === 1) {
                                                                                                                    errorSchritt6 = "In diesem Topf kann es insgesamt nur 8 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                  }
                                                                                                                  else {
                                                                                                                    if(Error2 === 1) {
                                                                                                                      errorSchritt6 = "In diesem Topf kann es nur sechs 4er Wünsche geben.";
                                                                                                                    }
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                              else {
                                                                                                                if(alternativeTopfgroessetopf1 === "5") {
                                                                                                                  if(AnzahlWuensche > 7) {
                                                                                                                    Error1 = 1;
                                                                                                                  }
                                                                                                                  for(let a=0; a<20; a++) {
                                                                                                                    if(wunschxxx[8*a + 4] !== "") {
                                                                                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                    }
                                                                                                                  }
                                                                                                                  if(AnzahlRiesenWunsch > 2) {
                                                                                                                    Error2 = 1;
                                                                                                                  }
                                                                                                                  
                                                                                                                  if(Error1 === 1 && Error2 === 1) {
                                                                                                                    errorSchritt6 = "1. In diesem Topf kann es nur zwei 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                  }
                                                                                                                  else {
                                                                                                                    if(Error1 === 1) {
                                                                                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                    }
                                                                                                                    else {
                                                                                                                      if(Error2 === 1) {
                                                                                                                        errorSchritt6 = "In diesem Topf kann es nur zwei 5er Wünsche geben.";
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                            else {
                                                                                                                if(bevorzugteTopfgroessetopf1 === "5") {
                                                                                                                  if(AnzahlWuensche > 6) {
                                                                                                                    Error1 = 1;
                                                                                                                  }
                                                                                                                  for(let a=0; a<20; a++) {
                                                                                                                    if(wunschxxx[8*a + 4] !== "") {
                                                                                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                    }
                                                                                                                  }
                                                                                                                  if(AnzahlRiesenWunsch > 6) {
                                                                                                                    Error2 = 1;
                                                                                                                  }
                                                                                                                  
                                                                                                                  if(Error1 === 1 && Error2 === 1) {
                                                                                                                    errorSchritt6 = "1. In diesem Topf kann es nur sechs 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                  }
                                                                                                                  else {
                                                                                                                    if(Error1 === 1) {
                                                                                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                    }
                                                                                                                    else {
                                                                                                                      if(Error2 === 1) {
                                                                                                                        errorSchritt6 = "In diesem Topf kann es nur sechs 5er Wünsche geben.";
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                }
                                                                                                                else {
                                                                                                                    if(bevorzugteTopfgroessetopf1 === "6") {
                                                                                                                      if(AnzahlWuensche > 5) {
                                                                                                                        Error1 = 1;
                                                                                                                      }
                                                                                                                      for(let a=0; a<20; a++) {
                                                                                                                        if(wunschxxx[8*a + 5] !== "") {
                                                                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                        }
                                                                                                                      }
                                                                                                                      if(AnzahlRiesenWunsch > 5) {
                                                                                                                        Error2 = 1;
                                                                                                                      }
                                                                                                                      
                                                                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                                                                        errorSchritt6 = "1. In diesem Topf kann es nur fünf 6er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                      }
                                                                                                                      else {
                                                                                                                        if(Error1 === 1) {
                                                                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                        }
                                                                                                                        else {
                                                                                                                          if(Error2 === 1) {
                                                                                                                            errorSchritt6 = "In diesem Topf kann es nur fünf 6er Wünsche geben.";
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        if(bevorzugteTopfgroessetopf1 === "7") {
                                                                                                                            if(alternativeTopfgroessetopf1 === "8") {
                                                                                                                              if(AnzahlWuensche > 4) {
                                                                                                                                Error1 = 1;
                                                                                                                              }
                                                                                                                              for(let a=0; a<20; a++) {
                                                                                                                                if(wunschxxx[8*a + 7] !== "") {
                                                                                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                }
                                                                                                                              }
                                                                                                                              if(AnzahlRiesenWunsch > 2) {
                                                                                                                                Error2 = 1;
                                                                                                                              }
                                                                                                                              
                                                                                                                              if(Error1 === 1 && Error2 === 1) {
                                                                                                                                errorSchritt6 = "1. In diesem Topf kann es nur zwei 8er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                              }
                                                                                                                              else {
                                                                                                                                if(Error1 === 1) {
                                                                                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                  if(Error2 === 1) {
                                                                                                                                    errorSchritt6 = "In diesem Topf kann es nur zwei 8er Wünsche geben.";
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              }
                                                                                                                            }
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            if(bevorzugteTopfgroessetopf1 === "8") {
                                                                                                                              if(AnzahlWuensche > 4) {
                                                                                                                                Error1 = 1;
                                                                                                                              }
                                                                                                                              for(let a=0; a<20; a++) {
                                                                                                                                if(wunschxxx[8*a + 7] !== "") {
                                                                                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                }
                                                                                                                              }
                                                                                                                              if(AnzahlRiesenWunsch > 2) {
                                                                                                                                Error2 = 1;
                                                                                                                              }
                                                                                                                              
                                                                                                                              if(Error1 === 1 && Error2 === 1) {
                                                                                                                                errorSchritt6 = "1. In diesem Topf kann es nur zwei 8er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                              }
                                                                                                                              else {
                                                                                                                                if(Error1 === 1) {
                                                                                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                  if(Error2 === 1) {
                                                                                                                                    errorSchritt6 = "In diesem Topf kann es nur zwei 8er Wünsche geben.";
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                    else {
                                                                                                        if(trainingseinheitentopf1 === "31") {
                                                                                                          if(bevorzugteTopfgroessetopf1 === "3") {
                                                                                                            if(AnzahlWuensche > 10) {
                                                                                                              Error1 = 1;
                                                                                                            }
                                                                                                            for(let a=0; a<20; a++) {
                                                                                                              if(wunschxxx[8*a + 3] !== "") {
                                                                                                                AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                              }
                                                                                                            }
                                                                                                            if(AnzahlRiesenWunsch > 1) {
                                                                                                              Error2 = 1;
                                                                                                            }
                                                                                                            
                                                                                                            if(Error1 === 1 && Error2 === 1) {
                                                                                                              errorSchritt6 = "1. In diesem Topf kann es nur einen 4er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 10 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                            }
                                                                                                            else {
                                                                                                              if(Error1 === 1) {
                                                                                                                errorSchritt6 = "In diesem Topf kann es insgesamt nur 10 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                              }
                                                                                                              else {
                                                                                                                if(Error2 === 1) {
                                                                                                                  errorSchritt6 = "In diesem Topf kann es nur einen 4er Wunsch geben.";
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                          else {
                                                                                                              if(bevorzugteTopfgroessetopf1 === "4") {
                                                                                                                if(alternativeTopfgroessetopf1 === "3") {
                                                                                                                  if(AnzahlWuensche > 8) {
                                                                                                                    Error1 = 1;
                                                                                                                  }
                                                                                                                  for(let a=0; a<20; a++) {
                                                                                                                    if(wunschxxx[8*a + 3] !== "") {
                                                                                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                    }
                                                                                                                  }
                                                                                                                  if(AnzahlRiesenWunsch > 7) {
                                                                                                                    Error2 = 1;
                                                                                                                  }
                                                                                                                  
                                                                                                                  if(Error1 === 1 && Error2 === 1) {
                                                                                                                    errorSchritt6 = "1. In diesem Topf kann es nur sieben 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 8 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                  }
                                                                                                                  else {
                                                                                                                    if(Error1 === 1) {
                                                                                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 8 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                    }
                                                                                                                    else {
                                                                                                                      if(Error2 === 1) {
                                                                                                                        errorSchritt6 = "In diesem Topf kann es nur sieben 4er Wünsche geben.";
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                }
                                                                                                                else {
                                                                                                                  if(alternativeTopfgroessetopf1 === "5") {
                                                                                                                    if(AnzahlWuensche > 7) {
                                                                                                                      Error1 = 1;
                                                                                                                    }
                                                                                                                    for(let a=0; a<20; a++) {
                                                                                                                      if(wunschxxx[8*a + 4] !== "") {
                                                                                                                        AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                      }
                                                                                                                    }
                                                                                                                    if(AnzahlRiesenWunsch > 3) {
                                                                                                                      Error2 = 1;
                                                                                                                    }
                                                                                                                    
                                                                                                                    if(Error1 === 1 && Error2 === 1) {
                                                                                                                      errorSchritt6 = "1. In diesem Topf kann es nur drei 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                    }
                                                                                                                    else {
                                                                                                                      if(Error1 === 1) {
                                                                                                                        errorSchritt6 = "In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                      }
                                                                                                                      else {
                                                                                                                        if(Error2 === 1) {
                                                                                                                          errorSchritt6 = "In diesem Topf kann es nur drei 5er Wünsche geben.";
                                                                                                                        }
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                              else {
                                                                                                                  if(bevorzugteTopfgroessetopf1 === "5") {
                                                                                                                    if(alternativeTopfgroessetopf1 === "4") {
                                                                                                                      if(AnzahlWuensche > 7) {
                                                                                                                        Error1 = 1;
                                                                                                                      }
                                                                                                                      for(let a=0; a<20; a++) {
                                                                                                                        if(wunschxxx[8*a + 4] !== "") {
                                                                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                        }
                                                                                                                      }
                                                                                                                      if(AnzahlRiesenWunsch > 3) {
                                                                                                                        Error2 = 1;
                                                                                                                      }
                                                                                                                      
                                                                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                                                                        errorSchritt6 = "1. In diesem Topf kann es nur drei 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                      }
                                                                                                                      else {
                                                                                                                        if(Error1 === 1) {
                                                                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                        }
                                                                                                                        else {
                                                                                                                          if(Error2 === 1) {
                                                                                                                            errorSchritt6 = "In diesem Topf kann es nur drei 5er Wünsche geben.";
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                    }
                                                                                                                    else {
                                                                                                                      if(alternativeTopfgroessetopf1 === "6") {
                                                                                                                        if(AnzahlWuensche > 6) {
                                                                                                                          Error1 = 1;
                                                                                                                        }
                                                                                                                        for(let a=0; a<20; a++) {
                                                                                                                          if(wunschxxx[8*a + 5] !== "") {
                                                                                                                            AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                          }
                                                                                                                        }
                                                                                                                        if(AnzahlRiesenWunsch > 1) {
                                                                                                                          Error2 = 1;
                                                                                                                        }
                                                                                                                        
                                                                                                                        if(Error1 === 1 && Error2 === 1) {
                                                                                                                          errorSchritt6 = "1. In diesem Topf kann es nur einen 6er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                        }
                                                                                                                        else {
                                                                                                                          if(Error1 === 1) {
                                                                                                                            errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                          }
                                                                                                                          else {
                                                                                                                            if(Error2 === 1) {
                                                                                                                              errorSchritt6 = "In diesem Topf kann es nur einen 6er Wunsch geben.";
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                  else {
                                                                                                                      if(bevorzugteTopfgroessetopf1 === "6") {
                                                                                                                        if(alternativeTopfgroessetopf1 === "5") {
                                                                                                                          if(AnzahlWuensche > 6) {
                                                                                                                            Error1 = 1;
                                                                                                                          }
                                                                                                                          for(let a=0; a<20; a++) {
                                                                                                                            if(wunschxxx[8*a + 5] !== "") {
                                                                                                                              AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                            }
                                                                                                                          }
                                                                                                                          if(AnzahlRiesenWunsch > 1) {
                                                                                                                            Error2 = 1;
                                                                                                                          }
                                                                                                                          
                                                                                                                          if(Error1 === 1 && Error2 === 1) {
                                                                                                                            errorSchritt6 = "1. In diesem Topf kann es nur einen 6er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                          }
                                                                                                                          else {
                                                                                                                            if(Error1 === 1) {
                                                                                                                              errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                            }
                                                                                                                            else {
                                                                                                                              if(Error2 === 1) {
                                                                                                                                errorSchritt6 = "In diesem Topf kann es nur einen 6er Wunsch geben.";
                                                                                                                              }
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                        else {
                                                                                                                          if(alternativeTopfgroessetopf1 === "7") {
                                                                                                                            if(AnzahlWuensche > 5) {
                                                                                                                              Error1 = 1;
                                                                                                                            }
                                                                                                                            for(let a=0; a<20; a++) {
                                                                                                                              if(wunschxxx[8*a + 6] !== "") {
                                                                                                                                AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                              }
                                                                                                                            }
                                                                                                                            if(AnzahlRiesenWunsch > 1) {
                                                                                                                              Error2 = 1;
                                                                                                                            }
                                                                                                                            
                                                                                                                            if(Error1 === 1 && Error2 === 1) {
                                                                                                                              errorSchritt6 = "1. In diesem Topf kann es nur einen 7er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                            }
                                                                                                                            else {
                                                                                                                              if(Error1 === 1) {
                                                                                                                                errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                              }
                                                                                                                              else {
                                                                                                                                if(Error2 === 1) {
                                                                                                                                  errorSchritt6 = "In diesem Topf kann es nur einen 7er Wunsch geben.";
                                                                                                                                }
                                                                                                                              }
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                      else {
                                                                                                                          if(bevorzugteTopfgroessetopf1 === "7") {
                                                                                                                            if(alternativeTopfgroessetopf1 === "6") {
                                                                                                                              if(AnzahlWuensche > 5) {
                                                                                                                                Error1 = 1;
                                                                                                                              }
                                                                                                                              for(let a=0; a<20; a++) {
                                                                                                                                if(wunschxxx[8*a + 6] !== "") {
                                                                                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                }
                                                                                                                              }
                                                                                                                              if(AnzahlRiesenWunsch > 1) {
                                                                                                                                Error2 = 1;
                                                                                                                              }
                                                                                                                              
                                                                                                                              if(Error1 === 1 && Error2 === 1) {
                                                                                                                                errorSchritt6 = "1. In diesem Topf kann es nur einen 7er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                              }
                                                                                                                              else {
                                                                                                                                if(Error1 === 1) {
                                                                                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                  if(Error2 === 1) {
                                                                                                                                    errorSchritt6 = "In diesem Topf kann es nur einen 7er Wunsch geben.";
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              }
                                                                                                                            }
                                                                                                                            else {
                                                                                                                              if(alternativeTopfgroessetopf1 === "8") {
                                                                                                                                if(AnzahlWuensche > 4) {
                                                                                                                                  Error1 = 1;
                                                                                                                                }
                                                                                                                                for(let a=0; a<20; a++) {
                                                                                                                                  if(wunschxxx[8*a + 7] !== "") {
                                                                                                                                    AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                  }
                                                                                                                                }
                                                                                                                                if(AnzahlRiesenWunsch > 3) {
                                                                                                                                  Error2 = 1;
                                                                                                                                }
                                                                                                                                
                                                                                                                                if(Error1 === 1 && Error2 === 1) {
                                                                                                                                  errorSchritt6 = "1. In diesem Topf kann es nur drei 8er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                  if(Error1 === 1) {
                                                                                                                                    errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                  }
                                                                                                                                  else {
                                                                                                                                    if(Error2 === 1) {
                                                                                                                                      errorSchritt6 = "In diesem Topf kann es nur drei 8er Wünsche geben.";
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              }
                                                                                                                            }
                                                                                                                          }
                                                                                                                          else {
                                                                                                                              if(bevorzugteTopfgroessetopf1 === "8") {
                                                                                                                                if(AnzahlWuensche > 4) {
                                                                                                                                  Error1 = 1;
                                                                                                                                }
                                                                                                                                for(let a=0; a<20; a++) {
                                                                                                                                  if(wunschxxx[8*a + 7] !== "") {
                                                                                                                                    AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                  }
                                                                                                                                }
                                                                                                                                if(AnzahlRiesenWunsch > 3) {
                                                                                                                                  Error2 = 1;
                                                                                                                                }
                                                                                                                                
                                                                                                                                if(Error1 === 1 && Error2 === 1) {
                                                                                                                                  errorSchritt6 = "1. In diesem Topf kann es nur drei 8er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                  if(Error1 === 1) {
                                                                                                                                    errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                  }
                                                                                                                                  else {
                                                                                                                                    if(Error2 === 1) {
                                                                                                                                      errorSchritt6 = "In diesem Topf kann es nur drei 8er Wünsche geben.";
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              }
                                                                                                                          }
                                                                                                                      }
                                                                                                                  }
                                                                                                              }
                                                                                                          }
                                                                                                        }
                                                                                                        else {
                                                                                                            if(trainingseinheitentopf1 === "32") {
                                                                                                                if(bevorzugteTopfgroessetopf1 === "3") {
                                                                                                                  if(AnzahlWuensche > 10) {
                                                                                                                    Error1 = 1;
                                                                                                                  }
                                                                                                                  for(let a=0; a<20; a++) {
                                                                                                                    if(wunschxxx[8*a + 3] !== "") {
                                                                                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                    }
                                                                                                                  }
                                                                                                                  if(AnzahlRiesenWunsch > 2) {
                                                                                                                    Error2 = 1;
                                                                                                                  }
                                                                                                                  
                                                                                                                  if(Error1 === 1 && Error2 === 1) {
                                                                                                                    errorSchritt6 = "1. In diesem Topf kann es nur zwei 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 10 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                  }
                                                                                                                  else {
                                                                                                                    if(Error1 === 1) {
                                                                                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 10 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                    }
                                                                                                                    else {
                                                                                                                      if(Error2 === 1) {
                                                                                                                        errorSchritt6 = "In diesem Topf kann es nur zwei 4er Wünsche geben.";
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                }
                                                                                                                else {
                                                                                                                    if(bevorzugteTopfgroessetopf1 === "4") {
                                                                                                                      if(AnzahlWuensche > 8) {
                                                                                                                        Error1 = 1;
                                                                                                                      }
                                                                                                                      for(let a=0; a<20; a++) {
                                                                                                                        if(wunschxxx[8*a + 3] !== "") {
                                                                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                        }
                                                                                                                      }
                                                                                                                      if(AnzahlRiesenWunsch > 8) {
                                                                                                                        Error2 = 1;
                                                                                                                      }
                                                                                                            
                                                                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                                                                        errorSchritt6 = "1. In diesem Topf kann es nur acht 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 8 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                      }
                                                                                                                      else {
                                                                                                                        if(Error1 === 1) {
                                                                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 8 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                        }
                                                                                                                        else {
                                                                                                                          if(Error2 === 1) {
                                                                                                                            errorSchritt6 = "In diesem Topf kann es nur acht 4er Wünsche geben.";
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        if(bevorzugteTopfgroessetopf1 === "5") {
                                                                                                                          if(alternativeTopfgroessetopf1 === "4") {
                                                                                                                            if(AnzahlWuensche > 7) {
                                                                                                                              Error1 = 1;
                                                                                                                            }
                                                                                                                            for(let a=0; a<20; a++) {
                                                                                                                              if(wunschxxx[8*a + 4] !== "") {
                                                                                                                                AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                              }
                                                                                                                            }
                                                                                                                            if(AnzahlRiesenWunsch > 4) {
                                                                                                                              Error2 = 1;
                                                                                                                            }
                                                                                                                            
                                                                                                                            if(Error1 === 1 && Error2 === 1) {
                                                                                                                              errorSchritt6 = "1. In diesem Topf kann es nur vier 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                            }
                                                                                                                            else {
                                                                                                                              if(Error1 === 1) {
                                                                                                                                errorSchritt6 = "In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                              }
                                                                                                                              else {
                                                                                                                                if(Error2 === 1) {
                                                                                                                                  errorSchritt6 = "In diesem Topf kann es nur vier 5er Wünsche geben.";
                                                                                                                                }
                                                                                                                              }
                                                                                                                            }
                                                                                                                          }
                                                                                                                          else {
                                                                                                                            if(alternativeTopfgroessetopf1 === "6") {
                                                                                                                              if(AnzahlWuensche > 6) {
                                                                                                                                Error1 = 1;
                                                                                                                              }
                                                                                                                              for(let a=0; a<20; a++) {
                                                                                                                                if(wunschxxx[8*a + 5] !== "") {
                                                                                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                }
                                                                                                                              }
                                                                                                                              if(AnzahlRiesenWunsch > 2) {
                                                                                                                                Error2 = 1;
                                                                                                                              }
                                                                                                                              
                                                                                                                              if(Error1 === 1 && Error2 === 1) {
                                                                                                                                errorSchritt6 = "1. In diesem Topf kann es nur zwei 6er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                              }
                                                                                                                              else {
                                                                                                                                if(Error1 === 1) {
                                                                                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                  if(Error2 === 1) {
                                                                                                                                    errorSchritt6 = "In diesem Topf kann es nur zwei 6er Wünsche geben.";
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              }
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            if(bevorzugteTopfgroessetopf1 === "6") {
                                                                                                                              if(alternativeTopfgroessetopf1 === "5") {
                                                                                                                                if(AnzahlWuensche > 6) {
                                                                                                                                  Error1 = 1;
                                                                                                                                }
                                                                                                                                for(let a=0; a<20; a++) {
                                                                                                                                  if(wunschxxx[8*a + 5] !== "") {
                                                                                                                                    AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                  }
                                                                                                                                }
                                                                                                                                if(AnzahlRiesenWunsch > 2) {
                                                                                                                                  Error2 = 1;
                                                                                                                                }
                                                                                                                                
                                                                                                                                if(Error1 === 1 && Error2 === 1) {
                                                                                                                                  errorSchritt6 = "1. In diesem Topf kann es nur zwei 6er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                  if(Error1 === 1) {
                                                                                                                                    errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                  }
                                                                                                                                  else {
                                                                                                                                    if(Error2 === 1) {
                                                                                                                                      errorSchritt6 = "In diesem Topf kann es nur zwei 6er Wünsche geben.";
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              }
                                                                                                                              else {
                                                                                                                                if(alternativeTopfgroessetopf1 === "7") {
                                                                                                                                  if(AnzahlWuensche > 5) {
                                                                                                                                    Error1 = 1;
                                                                                                                                  }
                                                                                                                                  for(let a=0; a<20; a++) {
                                                                                                                                    if(wunschxxx[8*a + 6] !== "") {
                                                                                                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                  if(AnzahlRiesenWunsch > 2) {
                                                                                                                                    Error2 = 1;
                                                                                                                                  }
                                                                                                                                  
                                                                                                                                  if(Error1 === 1 && Error2 === 1) {
                                                                                                                                    errorSchritt6 = "1. In diesem Topf kann es nur zwei 7er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                  }
                                                                                                                                  else {
                                                                                                                                    if(Error1 === 1) {
                                                                                                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                      if(Error2 === 1) {
                                                                                                                                        errorSchritt6 = "In diesem Topf kann es nur zwei 7er Wünsche geben.";
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              }
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                if(bevorzugteTopfgroessetopf1 === "7") {
                                                                                                                                    if(alternativeTopfgroessetopf1 === "8") {
                                                                                                                                        
                                                                                                                                    }
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    if(bevorzugteTopfgroessetopf1 === "8") {
                                                                                                                                      if(AnzahlWuensche > 4) {
                                                                                                                                        Error1 = 1;
                                                                                                                                      }
                                                                                                                                      for(let a=0; a<20; a++) {
                                                                                                                                        if(wunschxxx[8*a + 7] !== "") {
                                                                                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                      if(AnzahlRiesenWunsch > 4) {
                                                                                                                                        Error2 = 1;
                                                                                                                                      }
                                                                                                                                      
                                                                                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                                                                                        errorSchritt6 = "1. In diesem Topf kann es nur vier 8er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                      }
                                                                                                                                      else {
                                                                                                                                        if(Error1 === 1) {
                                                                                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 4 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                          if(Error2 === 1) {
                                                                                                                                            errorSchritt6 = "In diesem Topf kann es nur vier 8er Wünsche geben.";
                                                                                                                                          }
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                            else {
                                                                                                                if(trainingseinheitentopf1 === "33") {
                                                                                                                    if(bevorzugteTopfgroessetopf1 === "3") {
                                                                                                                      if(AnzahlWuensche > 11) {
                                                                                                                        Error1 = 1;
                                                                                                                      }
                                                                                                                      for(let a=0; a<20; a++) {
                                                                                                                        if(wunschxxx[8*a + 2] !== "") {
                                                                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                        }
                                                                                                                      }
                                                                                                                      if(AnzahlRiesenWunsch > 11) {
                                                                                                                        Error2 = 1;
                                                                                                                      }
                                                                                                            
                                                                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                                                                        errorSchritt6 = "1. In diesem Topf kann es nur elf 3er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 11 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                      }
                                                                                                                      else {
                                                                                                                        if(Error1 === 1) {
                                                                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 11 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                        }
                                                                                                                        else {
                                                                                                                          if(Error2 === 1) {
                                                                                                                            errorSchritt6 = "In diesem Topf kann es nur elf 3er Wünsche geben.";
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        if(bevorzugteTopfgroessetopf1 === "4") {
                                                                                                                          if(alternativeTopfgroessetopf1 === "3") {
                                                                                                                            if(AnzahlWuensche > 9) {
                                                                                                                              Error1 = 1;
                                                                                                                            }
                                                                                                                            for(let a=0; a<20; a++) {
                                                                                                                              if(wunschxxx[8*a + 3] !== "") {
                                                                                                                                AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                              }
                                                                                                                            }
                                                                                                                            if(AnzahlRiesenWunsch > 6) {
                                                                                                                              Error2 = 1;
                                                                                                                            }
                                                                                                                            
                                                                                                                            if(Error1 === 1 && Error2 === 1) {
                                                                                                                              errorSchritt6 = "1. In diesem Topf kann es nur sechs 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 9 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                            }
                                                                                                                            else {
                                                                                                                              if(Error1 === 1) {
                                                                                                                                errorSchritt6 = "In diesem Topf kann es insgesamt nur 9 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                              }
                                                                                                                              else {
                                                                                                                                if(Error2 === 1) {
                                                                                                                                  errorSchritt6 = "In diesem Topf kann es nur sechs 4er Wünsche geben.";
                                                                                                                                }
                                                                                                                              }
                                                                                                                            }
                                                                                                                          }
                                                                                                                          else {
                                                                                                                            if(alternativeTopfgroessetopf1 === "5") {
                                                                                                                              if(AnzahlWuensche > 8) {
                                                                                                                                Error1 = 1;
                                                                                                                              }
                                                                                                                              for(let a=0; a<20; a++) {
                                                                                                                                if(wunschxxx[8*a + 4] !== "") {
                                                                                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                }
                                                                                                                              }
                                                                                                                              if(AnzahlRiesenWunsch > 1) {
                                                                                                                                Error2 = 1;
                                                                                                                              }
                                                                                                                              
                                                                                                                              if(Error1 === 1 && Error2 === 1) {
                                                                                                                                errorSchritt6 = "1. In diesem Topf kann es nur einen 5er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 8 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                              }
                                                                                                                              else {
                                                                                                                                if(Error1 === 1) {
                                                                                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 8 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                  if(Error2 === 1) {
                                                                                                                                    errorSchritt6 = "In diesem Topf kann es nur einen 5er Wunsch geben.";
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              }
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            if(bevorzugteTopfgroessetopf1 === "5") {
                                                                                                                              if(alternativeTopfgroessetopf1 === "4") {
                                                                                                                                if(AnzahlWuensche > 7) {
                                                                                                                                  Error1 = 1;
                                                                                                                                }
                                                                                                                                for(let a=0; a<20; a++) {
                                                                                                                                  if(wunschxxx[8*a + 4] !== "") {
                                                                                                                                    AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                  }
                                                                                                                                }
                                                                                                                                if(AnzahlRiesenWunsch > 5) {
                                                                                                                                  Error2 = 1;
                                                                                                                                }
                                                                                                                                
                                                                                                                                if(Error1 === 1 && Error2 === 1) {
                                                                                                                                  errorSchritt6 = "1. In diesem Topf kann es nur fünf 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                  if(Error1 === 1) {
                                                                                                                                    errorSchritt6 = "In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                  }
                                                                                                                                  else {
                                                                                                                                    if(Error2 === 1) {
                                                                                                                                      errorSchritt6 = "In diesem Topf kann es nur fünf 5er Wünsche geben.";
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              }
                                                                                                                              else {
                                                                                                                                if(alternativeTopfgroessetopf1 === "6") {
                                                                                                                                  if(AnzahlWuensche > 6) {
                                                                                                                                    Error1 = 1;
                                                                                                                                  }
                                                                                                                                  for(let a=0; a<20; a++) {
                                                                                                                                    if(wunschxxx[8*a + 5] !== "") {
                                                                                                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                  if(AnzahlRiesenWunsch > 3) {
                                                                                                                                    Error2 = 1;
                                                                                                                                  }
                                                                                                                                  
                                                                                                                                  if(Error1 === 1 && Error2 === 1) {
                                                                                                                                    errorSchritt6 = "1. In diesem Topf kann es nur drei 6er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                  }
                                                                                                                                  else {
                                                                                                                                    if(Error1 === 1) {
                                                                                                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                      if(Error2 === 1) {
                                                                                                                                        errorSchritt6 = "In diesem Topf kann es nur drei 6er Wünsche geben.";
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              }
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                if(bevorzugteTopfgroessetopf1 === "6") {
                                                                                                                                  if(alternativeTopfgroessetopf1 === "5") {
                                                                                                                                    if(AnzahlWuensche > 6) {
                                                                                                                                      Error1 = 1;
                                                                                                                                    }
                                                                                                                                    for(let a=0; a<20; a++) {
                                                                                                                                      if(wunschxxx[8*a + 5] !== "") {
                                                                                                                                        AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                    if(AnzahlRiesenWunsch > 3) {
                                                                                                                                      Error2 = 1;
                                                                                                                                    }
                                                                                                                                    
                                                                                                                                    if(Error1 === 1 && Error2 === 1) {
                                                                                                                                      errorSchritt6 = "1. In diesem Topf kann es nur drei 6er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                      if(Error1 === 1) {
                                                                                                                                        errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                      }
                                                                                                                                      else {
                                                                                                                                        if(Error2 === 1) {
                                                                                                                                          errorSchritt6 = "In diesem Topf kann es nur drei 6er Wünsche geben.";
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                  else {
                                                                                                                                    if(alternativeTopfgroessetopf1 === "7") {
                                                                                                                                      if(AnzahlWuensche > 5) {
                                                                                                                                        Error1 = 1;
                                                                                                                                      }
                                                                                                                                      for(let a=0; a<20; a++) {
                                                                                                                                        if(wunschxxx[8*a + 6] !== "") {
                                                                                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                      if(AnzahlRiesenWunsch > 3) {
                                                                                                                                        Error2 = 1;
                                                                                                                                      }
                                                                                                                                      
                                                                                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                                                                                        errorSchritt6 = "1. In diesem Topf kann es nur drei 7er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                      }
                                                                                                                                      else {
                                                                                                                                        if(Error1 === 1) {
                                                                                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                          if(Error2 === 1) {
                                                                                                                                            errorSchritt6 = "In diesem Topf kann es nur drei 7er Wünsche geben.";
                                                                                                                                          }
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    if(bevorzugteTopfgroessetopf1 === "7") {
                                                                                                                                        if(alternativeTopfgroessetopf1 === "6") {
                                                                                                                                          if(AnzahlWuensche > 5) {
                                                                                                                                            Error1 = 1;
                                                                                                                                          }
                                                                                                                                          for(let a=0; a<20; a++) {
                                                                                                                                            if(wunschxxx[8*a + 6] !== "") {
                                                                                                                                              AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                          if(AnzahlRiesenWunsch > 3) {
                                                                                                                                            Error2 = 1;
                                                                                                                                          }
                                                                                                                                          
                                                                                                                                          if(Error1 === 1 && Error2 === 1) {
                                                                                                                                            errorSchritt6 = "1. In diesem Topf kann es nur drei 7er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                          }
                                                                                                                                          else {
                                                                                                                                            if(Error1 === 1) {
                                                                                                                                              errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                              if(Error2 === 1) {
                                                                                                                                                errorSchritt6 = "In diesem Topf kann es nur drei 7er Wünsche geben.";
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                                else {
                                                                                                                    if(trainingseinheitentopf1 === "34") {
                                                                                                                        if(bevorzugteTopfgroessetopf1 === "3") {
                                                                                                                          if(AnzahlWuensche > 11) {
                                                                                                                            Error1 = 1;
                                                                                                                          }
                                                                                                                          for(let a=0; a<20; a++) {
                                                                                                                            if(wunschxxx[8*a + 3] !== "") {
                                                                                                                              AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                            }
                                                                                                                          }
                                                                                                                          if(AnzahlRiesenWunsch > 1) {
                                                                                                                            Error2 = 1;
                                                                                                                          }
                                                                                                                          
                                                                                                                          if(Error1 === 1 && Error2 === 1) {
                                                                                                                            errorSchritt6 = "1. In diesem Topf kann es nur einen 4er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 11 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                          }
                                                                                                                          else {
                                                                                                                            if(Error1 === 1) {
                                                                                                                              errorSchritt6 = "In diesem Topf kann es insgesamt nur 11 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                            }
                                                                                                                            else {
                                                                                                                              if(Error2 === 1) {
                                                                                                                                errorSchritt6 = "In diesem Topf kann es nur einen 4er Wunsch geben.";
                                                                                                                              }
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            if(bevorzugteTopfgroessetopf1 === "4") {
                                                                                                                              if(alternativeTopfgroessetopf1 === "3") {
                                                                                                                                if(AnzahlWuensche > 9) {
                                                                                                                                  Error1 = 1;
                                                                                                                                }
                                                                                                                                for(let a=0; a<20; a++) {
                                                                                                                                  if(wunschxxx[8*a + 3] !== "") {
                                                                                                                                    AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                  }
                                                                                                                                }
                                                                                                                                if(AnzahlRiesenWunsch > 7) {
                                                                                                                                  Error2 = 1;
                                                                                                                                }
                                                                                                                                
                                                                                                                                if(Error1 === 1 && Error2 === 1) {
                                                                                                                                  errorSchritt6 = "1. In diesem Topf kann es nur sieben 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 9 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                  if(Error1 === 1) {
                                                                                                                                    errorSchritt6 = "In diesem Topf kann es insgesamt nur 9 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                  }
                                                                                                                                  else {
                                                                                                                                    if(Error2 === 1) {
                                                                                                                                      errorSchritt6 = "In diesem Topf kann es nur sieben 4er Wünsche geben.";
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              }
                                                                                                                              else {
                                                                                                                                if(alternativeTopfgroessetopf1 === "5") {
                                                                                                                                  if(AnzahlWuensche > 8) {
                                                                                                                                    Error1 = 1;
                                                                                                                                  }
                                                                                                                                  for(let a=0; a<20; a++) {
                                                                                                                                    if(wunschxxx[8*a + 4] !== "") {
                                                                                                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                  if(AnzahlRiesenWunsch > 2) {
                                                                                                                                    Error2 = 1;
                                                                                                                                  }
                                                                                                                                  
                                                                                                                                  if(Error1 === 1 && Error2 === 1) {
                                                                                                                                    errorSchritt6 = "1. In diesem Topf kann es nur zwei 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 8 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                  }
                                                                                                                                  else {
                                                                                                                                    if(Error1 === 1) {
                                                                                                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 8 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                      if(Error2 === 1) {
                                                                                                                                        errorSchritt6 = "In diesem Topf kann es nur zwei 5er Wünsche geben.";
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              }
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                if(bevorzugteTopfgroessetopf1 === "5") {
                                                                                                                                  if(alternativeTopfgroessetopf1 === "4") {
                                                                                                                                    if(AnzahlWuensche > 7) {
                                                                                                                                      Error1 = 1;
                                                                                                                                    }
                                                                                                                                    for(let a=0; a<20; a++) {
                                                                                                                                      if(wunschxxx[8*a + 4] !== "") {
                                                                                                                                        AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                    if(AnzahlRiesenWunsch > 6) {
                                                                                                                                      Error2 = 1;
                                                                                                                                    }
                                                                                                                                    
                                                                                                                                    if(Error1 === 1 && Error2 === 1) {
                                                                                                                                      errorSchritt6 = "1. In diesem Topf kann es nur sechs 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                      if(Error1 === 1) {
                                                                                                                                        errorSchritt6 = "In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                      }
                                                                                                                                      else {
                                                                                                                                        if(Error2 === 1) {
                                                                                                                                          errorSchritt6 = "In diesem Topf kann es nur sechs 5er Wünsche geben.";
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                  else {
                                                                                                                                    if(alternativeTopfgroessetopf1 === "6") {
                                                                                                                                      if(AnzahlWuensche > 6) {
                                                                                                                                        Error1 = 1;
                                                                                                                                      }
                                                                                                                                      for(let a=0; a<20; a++) {
                                                                                                                                        if(wunschxxx[8*a + 5] !== "") {
                                                                                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                      if(AnzahlRiesenWunsch > 4) {
                                                                                                                                        Error2 = 1;
                                                                                                                                      }
                                                                                                                                      
                                                                                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                                                                                        errorSchritt6 = "1. In diesem Topf kann es nur vier 6er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                      }
                                                                                                                                      else {
                                                                                                                                        if(Error1 === 1) {
                                                                                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                          if(Error2 === 1) {
                                                                                                                                            errorSchritt6 = "In diesem Topf kann es nur vier 6er Wünsche geben.";
                                                                                                                                          }
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    if(bevorzugteTopfgroessetopf1 === "6") {
                                                                                                                                      if(alternativeTopfgroessetopf1 === "5") {
                                                                                                                                        if(AnzahlWuensche > 6) {
                                                                                                                                          Error1 = 1;
                                                                                                                                        }
                                                                                                                                        for(let a=0; a<20; a++) {
                                                                                                                                          if(wunschxxx[8*a + 5] !== "") {
                                                                                                                                            AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                          }
                                                                                                                                        }
                                                                                                                                        if(AnzahlRiesenWunsch > 4) {
                                                                                                                                          Error2 = 1;
                                                                                                                                        }
                                                                                                                                        
                                                                                                                                        if(Error1 === 1 && Error2 === 1) {
                                                                                                                                          errorSchritt6 = "1. In diesem Topf kann es nur vier 6er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                          if(Error1 === 1) {
                                                                                                                                            errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                          }
                                                                                                                                          else {
                                                                                                                                            if(Error2 === 1) {
                                                                                                                                              errorSchritt6 = "In diesem Topf kann es nur vier 6er Wünsche geben.";
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                      else {
                                                                                                                                        if(alternativeTopfgroessetopf1 === "7") {
                                                                                                                                          if(AnzahlWuensche > 5) {
                                                                                                                                            Error1 = 1;
                                                                                                                                          }
                                                                                                                                          for(let a=0; a<20; a++) {
                                                                                                                                            if(wunschxxx[8*a + 6] !== "") {
                                                                                                                                              AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                          if(AnzahlRiesenWunsch > 4) {
                                                                                                                                            Error2 = 1;
                                                                                                                                          }
                                                                                                                                          
                                                                                                                                          if(Error1 === 1 && Error2 === 1) {
                                                                                                                                            errorSchritt6 = "1. In diesem Topf kann es nur vier 7er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                          }
                                                                                                                                          else {
                                                                                                                                            if(Error1 === 1) {
                                                                                                                                              errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                              if(Error2 === 1) {
                                                                                                                                                errorSchritt6 = "In diesem Topf kann es nur vier 7er Wünsche geben.";
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        if(bevorzugteTopfgroessetopf1 === "7") {
                                                                                                                                            if(alternativeTopfgroessetopf1 === "6") {
                                                                                                                                              if(AnzahlWuensche > 5) {
                                                                                                                                                Error1 = 1;
                                                                                                                                              }
                                                                                                                                              for(let a=0; a<20; a++) {
                                                                                                                                                if(wunschxxx[8*a + 6] !== "") {
                                                                                                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                              if(AnzahlRiesenWunsch > 4) {
                                                                                                                                                Error2 = 1;
                                                                                                                                              }
                                                                                                                                              
                                                                                                                                              if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                errorSchritt6 = "1. In diesem Topf kann es nur vier 7er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                              }
                                                                                                                                              else {
                                                                                                                                                if(Error1 === 1) {
                                                                                                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                  if(Error2 === 1) {
                                                                                                                                                    errorSchritt6 = "In diesem Topf kann es nur vier 7er Wünsche geben.";
                                                                                                                                                  }
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        if(trainingseinheitentopf1 === "35") {
                                                                                                                            if(bevorzugteTopfgroessetopf1 === "3") {
                                                                                                                              if(AnzahlWuensche > 11) {
                                                                                                                                Error1 = 1;
                                                                                                                              }
                                                                                                                              for(let a=0; a<20; a++) {
                                                                                                                                if(wunschxxx[8*a + 3] !== "") {
                                                                                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                }
                                                                                                                              }
                                                                                                                              if(AnzahlRiesenWunsch > 2) {
                                                                                                                                Error2 = 1;
                                                                                                                              }
                                                                                                                              
                                                                                                                              if(Error1 === 1 && Error2 === 1) {
                                                                                                                                errorSchritt6 = "1. In diesem Topf kann es nur zwei 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 11 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                              }
                                                                                                                              else {
                                                                                                                                if(Error1 === 1) {
                                                                                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 11 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                  if(Error2 === 1) {
                                                                                                                                    errorSchritt6 = "In diesem Topf kann es nur zwei 4er Wünsche geben.";
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              }
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                if(bevorzugteTopfgroessetopf1 === "4") {
                                                                                                                                  if(alternativeTopfgroessetopf1 === "3") {
                                                                                                                                    if(AnzahlWuensche > 9) {
                                                                                                                                      Error1 = 1;
                                                                                                                                    }
                                                                                                                                    for(let a=0; a<20; a++) {
                                                                                                                                      if(wunschxxx[8*a + 3] !== "") {
                                                                                                                                        AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                    if(AnzahlRiesenWunsch > 8) {
                                                                                                                                      Error2 = 1;
                                                                                                                                    }
                                                                                                                                    
                                                                                                                                    if(Error1 === 1 && Error2 === 1) {
                                                                                                                                      errorSchritt6 = "1. In diesem Topf kann es nur acht 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 9 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                      if(Error1 === 1) {
                                                                                                                                        errorSchritt6 = "In diesem Topf kann es insgesamt nur 9 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                      }
                                                                                                                                      else {
                                                                                                                                        if(Error2 === 1) {
                                                                                                                                          errorSchritt6 = "In diesem Topf kann es nur acht 4er Wünsche geben.";
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                  else {
                                                                                                                                    if(alternativeTopfgroessetopf1 === "5") {
                                                                                                                                      if(AnzahlWuensche > 8) {
                                                                                                                                        Error1 = 1;
                                                                                                                                      }
                                                                                                                                      for(let a=0; a<20; a++) {
                                                                                                                                        if(wunschxxx[8*a + 4] !== "") {
                                                                                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                      if(AnzahlRiesenWunsch > 3) {
                                                                                                                                        Error2 = 1;
                                                                                                                                      }
                                                                                                                                      
                                                                                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                                                                                        errorSchritt6 = "1. In diesem Topf kann es nur drei 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 8 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                      }
                                                                                                                                      else {
                                                                                                                                        if(Error1 === 1) {
                                                                                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 8 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                          if(Error2 === 1) {
                                                                                                                                            errorSchritt6 = "In diesem Topf kann es nur drei 5er Wünsche geben.";
                                                                                                                                          }
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    if(bevorzugteTopfgroessetopf1 === "5") {
                                                                                                                                      if(AnzahlWuensche > 7) {
                                                                                                                                        Error1 = 1;
                                                                                                                                      }
                                                                                                                                      for(let a=0; a<20; a++) {
                                                                                                                                        if(wunschxxx[8*a + 4] !== "") {
                                                                                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                      if(AnzahlRiesenWunsch > 7) {
                                                                                                                                        Error2 = 1;
                                                                                                                                      }
                                                                                                                                      
                                                                                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                                                                                        errorSchritt6 = "1. In diesem Topf kann es nur sieben 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                      }
                                                                                                                                      else {
                                                                                                                                        if(Error1 === 1) {
                                                                                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                          if(Error2 === 1) {
                                                                                                                                            errorSchritt6 = "In diesem Topf kann es nur sieben 5er Wünsche geben.";
                                                                                                                                          }
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        if(bevorzugteTopfgroessetopf1 === "6") {
                                                                                                                                            if(alternativeTopfgroessetopf1 === "5") {
                                                                                                                                              if(AnzahlWuensche > 6) {
                                                                                                                                                Error1 = 1;
                                                                                                                                              }
                                                                                                                                              for(let a=0; a<20; a++) {
                                                                                                                                                if(wunschxxx[8*a + 5] !== "") {
                                                                                                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                              if(AnzahlRiesenWunsch > 5) {
                                                                                                                                                Error2 = 1;
                                                                                                                                              }
                                                                                                                                              
                                                                                                                                              if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                errorSchritt6 = "1. In diesem Topf kann es nur fünf 6er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                              }
                                                                                                                                              else {
                                                                                                                                                if(Error1 === 1) {
                                                                                                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                  if(Error2 === 1) {
                                                                                                                                                    errorSchritt6 = "In diesem Topf kann es nur fünf 6er Wünsche geben.";
                                                                                                                                                  }
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            if(bevorzugteTopfgroessetopf1 === "7") {
                                                                                                                                              if(AnzahlWuensche > 5) {
                                                                                                                                                Error1 = 1;
                                                                                                                                              }
                                                                                                                                              for(let a=0; a<20; a++) {
                                                                                                                                                if(wunschxxx[8*a + 6] !== "") {
                                                                                                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                              if(AnzahlRiesenWunsch > 5) {
                                                                                                                                                Error2 = 1;
                                                                                                                                              }
                                                                                                                                              
                                                                                                                                              if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                errorSchritt6 = "1. In diesem Topf kann es nur fünf 7er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                              }
                                                                                                                                              else {
                                                                                                                                                if(Error1 === 1) {
                                                                                                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                  if(Error2 === 1) {
                                                                                                                                                    errorSchritt6 = "In diesem Topf kann es nur fünf 7er Wünsche geben.";
                                                                                                                                                  }
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            if(trainingseinheitentopf1 === "36") {
                                                                                                                                if(bevorzugteTopfgroessetopf1 === "3") {
                                                                                                                                  if(AnzahlWuensche > 12) {
                                                                                                                                    Error1 = 1;
                                                                                                                                  }
                                                                                                                                  for(let a=0; a<20; a++) {
                                                                                                                                    if(wunschxxx[8*a + 2] !== "") {
                                                                                                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                  if(AnzahlRiesenWunsch > 12) {
                                                                                                                                    Error2 = 1;
                                                                                                                                  }
                                                                                                                        
                                                                                                                                  if(Error1 === 1 && Error2 === 1) {
                                                                                                                                    errorSchritt6 = "1. In diesem Topf kann es nur zwölf 3er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 12 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                  }
                                                                                                                                  else {
                                                                                                                                    if(Error1 === 1) {
                                                                                                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 12 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                      if(Error2 === 1) {
                                                                                                                                        errorSchritt6 = "In diesem Topf kann es nur zwölf 3er Wünsche geben.";
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    if(bevorzugteTopfgroessetopf1 === "4") {
                                                                                                                                      if(AnzahlWuensche > 9) {
                                                                                                                                        Error1 = 1;
                                                                                                                                      }
                                                                                                                                      for(let a=0; a<20; a++) {
                                                                                                                                        if(wunschxxx[8*a + 3] !== "") {
                                                                                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                      if(AnzahlRiesenWunsch > 9) {
                                                                                                                                        Error2 = 1;
                                                                                                                                      }
                                                                                                                            
                                                                                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                                                                                        errorSchritt6 = "1. In diesem Topf kann es nur neun 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 9 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                      }
                                                                                                                                      else {
                                                                                                                                        if(Error1 === 1) {
                                                                                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 9 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                          if(Error2 === 1) {
                                                                                                                                            errorSchritt6 = "In diesem Topf kann es nur neun 4er Wünsche geben.";
                                                                                                                                          }
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        if(bevorzugteTopfgroessetopf1 === "5") {
                                                                                                                                          if(alternativeTopfgroessetopf1 === "4") {
                                                                                                                                            if(AnzahlWuensche > 8) {
                                                                                                                                              Error1 = 1;
                                                                                                                                            }
                                                                                                                                            for(let a=0; a<20; a++) {
                                                                                                                                              if(wunschxxx[8*a + 4] !== "") {
                                                                                                                                                AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                            if(AnzahlRiesenWunsch > 4) {
                                                                                                                                              Error2 = 1;
                                                                                                                                            }
                                                                                                                                            
                                                                                                                                            if(Error1 === 1 && Error2 === 1) {
                                                                                                                                              errorSchritt6 = "1. In diesem Topf kann es nur vier 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 8 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                              if(Error1 === 1) {
                                                                                                                                                errorSchritt6 = "In diesem Topf kann es insgesamt nur 8 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                              }
                                                                                                                                              else {
                                                                                                                                                if(Error2 === 1) {
                                                                                                                                                  errorSchritt6 = "In diesem Topf kann es nur vier 5er Wünsche geben.";
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                          else {
                                                                                                                                            if(alternativeTopfgroessetopf1 === "6") {
                                                                                                                                              if(AnzahlWuensche > 7) {
                                                                                                                                                Error1 = 1;
                                                                                                                                              }
                                                                                                                                              for(let a=0; a<20; a++) {
                                                                                                                                                if(wunschxxx[8*a + 5] !== "") {
                                                                                                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                              if(AnzahlRiesenWunsch > 1) {
                                                                                                                                                Error2 = 1;
                                                                                                                                              }
                                                                                                                                              
                                                                                                                                              if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                errorSchritt6 = "1. In diesem Topf kann es nur einen 6er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                              }
                                                                                                                                              else {
                                                                                                                                                if(Error1 === 1) {
                                                                                                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                  if(Error2 === 1) {
                                                                                                                                                    errorSchritt6 = "In diesem Topf kann es nur einen 6er Wunsch geben.";
                                                                                                                                                  }
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            if(bevorzugteTopfgroessetopf1 === "6") {
                                                                                                                                              if(AnzahlWuensche > 6) {
                                                                                                                                                Error1 = 1;
                                                                                                                                              }
                                                                                                                                              for(let a=0; a<20; a++) {
                                                                                                                                                if(wunschxxx[8*a + 5] !== "") {
                                                                                                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                              if(AnzahlRiesenWunsch > 6) {
                                                                                                                                                Error2 = 1;
                                                                                                                                              }
                                                                                                                                              
                                                                                                                                              if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                errorSchritt6 = "1. In diesem Topf kann es nur sechs 6er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                              }
                                                                                                                                              else {
                                                                                                                                                if(Error1 === 1) {
                                                                                                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                  if(Error2 === 1) {
                                                                                                                                                    errorSchritt6 = "In diesem Topf kann es nur sechs 6er Wünsche geben.";
                                                                                                                                                  }
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                if(bevorzugteTopfgroessetopf1 === "7") {
                                                                                                                                                    if(alternativeTopfgroessetopf1 === "8") {
                                                                                                                                                      if(AnzahlWuensche > 5) {
                                                                                                                                                        Error1 = 1;
                                                                                                                                                      }
                                                                                                                                                      for(let a=0; a<20; a++) {
                                                                                                                                                        if(wunschxxx[8*a + 7] !== "") {
                                                                                                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                        }
                                                                                                                                                      }
                                                                                                                                                      if(AnzahlRiesenWunsch > 1) {
                                                                                                                                                        Error2 = 1;
                                                                                                                                                      }
                                                                                                                                                      
                                                                                                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                        errorSchritt6 = "1. In diesem Topf kann es nur einen 8er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                                      }
                                                                                                                                                      else {
                                                                                                                                                        if(Error1 === 1) {
                                                                                                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                          if(Error2 === 1) {
                                                                                                                                                            errorSchritt6 = "In diesem Topf kann es nur einen 8er Wunsch geben.";
                                                                                                                                                          }
                                                                                                                                                        }
                                                                                                                                                      }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                    if(bevorzugteTopfgroessetopf1 === "8") {
                                                                                                                                                      if(AnzahlWuensche > 5) {
                                                                                                                                                        Error1 = 1;
                                                                                                                                                      }
                                                                                                                                                      for(let a=0; a<20; a++) {
                                                                                                                                                        if(wunschxxx[8*a + 7] !== "") {
                                                                                                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                        }
                                                                                                                                                      }
                                                                                                                                                      if(AnzahlRiesenWunsch > 1) {
                                                                                                                                                        Error2 = 1;
                                                                                                                                                      }
                                                                                                                                                      
                                                                                                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                        errorSchritt6 = "1. In diesem Topf kann es nur einen 8er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                                      }
                                                                                                                                                      else {
                                                                                                                                                        if(Error1 === 1) {
                                                                                                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                          if(Error2 === 1) {
                                                                                                                                                            errorSchritt6 = "In diesem Topf kann es nur einen 8er Wunsch geben.";
                                                                                                                                                          }
                                                                                                                                                        }
                                                                                                                                                      }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                if(trainingseinheitentopf1 === "37") {
                                                                                                                                  if(bevorzugteTopfgroessetopf1 === "3") {
                                                                                                                                    if(AnzahlWuensche > 12) {
                                                                                                                                      Error1 = 1;
                                                                                                                                    }
                                                                                                                                    for(let a=0; a<20; a++) {
                                                                                                                                      if(wunschxxx[8*a + 3] !== "") {
                                                                                                                                        AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                    if(AnzahlRiesenWunsch > 1) {
                                                                                                                                      Error2 = 1;
                                                                                                                                    }
                                                                                                                                    
                                                                                                                                    if(Error1 === 1 && Error2 === 1) {
                                                                                                                                      errorSchritt6 = "1. In diesem Topf kann es nur einen 4er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 12 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                      if(Error1 === 1) {
                                                                                                                                        errorSchritt6 = "In diesem Topf kann es insgesamt nur 12 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                      }
                                                                                                                                      else {
                                                                                                                                        if(Error2 === 1) {
                                                                                                                                          errorSchritt6 = "In diesem Topf kann es nur einen 4er Wunsch geben.";
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                  else {
                                                                                                                                      if(bevorzugteTopfgroessetopf1 === "4") {
                                                                                                                                        if(alternativeTopfgroessetopf1 === "3") {
                                                                                                                                          if(AnzahlWuensche > 10) {
                                                                                                                                            Error1 = 1;
                                                                                                                                          }
                                                                                                                                          for(let a=0; a<20; a++) {
                                                                                                                                            if(wunschxxx[8*a + 3] !== "") {
                                                                                                                                              AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                          if(AnzahlRiesenWunsch > 7) {
                                                                                                                                            Error2 = 1;
                                                                                                                                          }
                                                                                                                                          
                                                                                                                                          if(Error1 === 1 && Error2 === 1) {
                                                                                                                                            errorSchritt6 = "1. In diesem Topf kann es nur sieben 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 10 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                          }
                                                                                                                                          else {
                                                                                                                                            if(Error1 === 1) {
                                                                                                                                              errorSchritt6 = "In diesem Topf kann es insgesamt nur 10 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                              if(Error2 === 1) {
                                                                                                                                                errorSchritt6 = "In diesem Topf kann es nur sieben 4er Wünsche geben.";
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                          if(alternativeTopfgroessetopf1 === "5") {
                                                                                                                                            if(AnzahlWuensche > 9) {
                                                                                                                                              Error1 = 1;
                                                                                                                                            }
                                                                                                                                            for(let a=0; a<20; a++) {
                                                                                                                                              if(wunschxxx[8*a + 4] !== "") {
                                                                                                                                                AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                            if(AnzahlRiesenWunsch > 1) {
                                                                                                                                              Error2 = 1;
                                                                                                                                            }
                                                                                                                                            
                                                                                                                                            if(Error1 === 1 && Error2 === 1) {
                                                                                                                                              errorSchritt6 = "1. In diesem Topf kann es nur einen 5er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 9 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                              if(Error1 === 1) {
                                                                                                                                                errorSchritt6 = "In diesem Topf kann es insgesamt nur 9 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                              }
                                                                                                                                              else {
                                                                                                                                                if(Error2 === 1) {
                                                                                                                                                  errorSchritt6 = "In diesem Topf kann es nur einen 5er Wunsch geben.";
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                            }   
                                                                                                                                          }
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                      else {
                                                                                                                                          if(bevorzugteTopfgroessetopf1 === "5") {
                                                                                                                                            if(alternativeTopfgroessetopf1 === "4") {
                                                                                                                                              if(AnzahlWuensche > 8) {
                                                                                                                                                Error1 = 1;
                                                                                                                                              }
                                                                                                                                              for(let a=0; a<20; a++) {
                                                                                                                                                if(wunschxxx[8*a + 4] !== "") {
                                                                                                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                              if(AnzahlRiesenWunsch > 5) {
                                                                                                                                                Error2 = 1;
                                                                                                                                              }
                                                                                                                                              
                                                                                                                                              if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                errorSchritt6 = "1. In diesem Topf kann es nur fünf 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 8 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                              }
                                                                                                                                              else {
                                                                                                                                                if(Error1 === 1) {
                                                                                                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 8 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                  if(Error2 === 1) {
                                                                                                                                                    errorSchritt6 = "In diesem Topf kann es nur fünf 5er Wünsche geben.";
                                                                                                                                                  }
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                              if(alternativeTopfgroessetopf1 === "6") {
                                                                                                                                                if(AnzahlWuensche > 7) {
                                                                                                                                                  Error1 = 1;
                                                                                                                                                }
                                                                                                                                                for(let a=0; a<20; a++) {
                                                                                                                                                  if(wunschxxx[8*a + 5] !== "") {
                                                                                                                                                    AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                  }
                                                                                                                                                }
                                                                                                                                                if(AnzahlRiesenWunsch > 2) {
                                                                                                                                                  Error2 = 1;
                                                                                                                                                }
                                                                                                                                                
                                                                                                                                                if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                  errorSchritt6 = "1. In diesem Topf kann es nur zwei 6er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                  if(Error1 === 1) {
                                                                                                                                                    errorSchritt6 = "In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                  }
                                                                                                                                                  else {
                                                                                                                                                    if(Error2 === 1) {
                                                                                                                                                      errorSchritt6 = "In diesem Topf kann es nur zwei 6er Wünsche geben.";
                                                                                                                                                    }
                                                                                                                                                  }
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                          else {
                                                                                                                                              if(bevorzugteTopfgroessetopf1 === "6") {
                                                                                                                                                if(alternativeTopfgroessetopf1 === "5") {
                                                                                                                                                  if(AnzahlWuensche > 7) {
                                                                                                                                                    Error1 = 1;
                                                                                                                                                  }
                                                                                                                                                  for(let a=0; a<20; a++) {
                                                                                                                                                    if(wunschxxx[8*a + 5] !== "") {
                                                                                                                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                    }
                                                                                                                                                  }
                                                                                                                                                  if(AnzahlRiesenWunsch > 2) {
                                                                                                                                                    Error2 = 1;
                                                                                                                                                  }
                                                                                                                                                  
                                                                                                                                                  if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                    errorSchritt6 = "1. In diesem Topf kann es nur zwei 6er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                                  }
                                                                                                                                                  else {
                                                                                                                                                    if(Error1 === 1) {
                                                                                                                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                      if(Error2 === 1) {
                                                                                                                                                        errorSchritt6 = "In diesem Topf kann es nur zwei 6er Wünsche geben.";
                                                                                                                                                      }
                                                                                                                                                    }
                                                                                                                                                  }
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                  if(alternativeTopfgroessetopf1 === "7") {
                                                                                                                                                    if(AnzahlWuensche > 6) {
                                                                                                                                                      Error1 = 1;
                                                                                                                                                    }
                                                                                                                                                    for(let a=0; a<20; a++) {
                                                                                                                                                      if(wunschxxx[8*a + 6] !== "") {
                                                                                                                                                        AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                      }
                                                                                                                                                    }
                                                                                                                                                    if(AnzahlRiesenWunsch > 1) {
                                                                                                                                                      Error2 = 1;
                                                                                                                                                    }
                                                                                                                                                    
                                                                                                                                                    if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                      errorSchritt6 = "1. In diesem Topf kann es nur einen 7er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                      if(Error1 === 1) {
                                                                                                                                                        errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                      }
                                                                                                                                                      else {
                                                                                                                                                        if(Error2 === 1) {
                                                                                                                                                          errorSchritt6 = "In diesem Topf kann es nur einen 7er Wunsch geben.";
                                                                                                                                                        }
                                                                                                                                                      }
                                                                                                                                                    }
                                                                                                                                                  }
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                              else {
                                                                                                                                                  if(bevorzugteTopfgroessetopf1 === "7") {
                                                                                                                                                    if(alternativeTopfgroessetopf1 === "6") {
                                                                                                                                                      if(AnzahlWuensche > 6) {
                                                                                                                                                        Error1 = 1;
                                                                                                                                                      }
                                                                                                                                                      for(let a=0; a<20; a++) {
                                                                                                                                                        if(wunschxxx[8*a + 6] !== "") {
                                                                                                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                        }
                                                                                                                                                      }
                                                                                                                                                      if(AnzahlRiesenWunsch > 1) {
                                                                                                                                                        Error2 = 1;
                                                                                                                                                      }
                                                                                                                                                      
                                                                                                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                        errorSchritt6 = "1. In diesem Topf kann es nur einen 7er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                                      }
                                                                                                                                                      else {
                                                                                                                                                        if(Error1 === 1) {
                                                                                                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                          if(Error2 === 1) {
                                                                                                                                                            errorSchritt6 = "In diesem Topf kann es nur einen 7er Wunsch geben.";
                                                                                                                                                          }
                                                                                                                                                        }
                                                                                                                                                      }
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                      if(alternativeTopfgroessetopf1 === "8") {
                                                                                                                                                        if(AnzahlWuensche > 5) {
                                                                                                                                                          Error1 = 1;
                                                                                                                                                        }
                                                                                                                                                        for(let a=0; a<20; a++) {
                                                                                                                                                          if(wunschxxx[8*a + 7] !== "") {
                                                                                                                                                            AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                          }
                                                                                                                                                        }
                                                                                                                                                        if(AnzahlRiesenWunsch > 2) {
                                                                                                                                                          Error2 = 1;
                                                                                                                                                        }
                                                                                                                                                        
                                                                                                                                                        if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                          errorSchritt6 = "1. In diesem Topf kann es nur zwei 8er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                          if(Error1 === 1) {
                                                                                                                                                            errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                          }
                                                                                                                                                          else {
                                                                                                                                                            if(Error2 === 1) {
                                                                                                                                                              errorSchritt6 = "In diesem Topf kann es nur zwei 8er Wünsche geben.";
                                                                                                                                                            }
                                                                                                                                                          }
                                                                                                                                                        }
                                                                                                                                                      }
                                                                                                                                                    }
                                                                                                                                                  }
                                                                                                                                                  else {
                                                                                                                                                      if(bevorzugteTopfgroessetopf1 === "8") {
                                                                                                                                                        if(AnzahlWuensche > 5) {
                                                                                                                                                          Error1 = 1;
                                                                                                                                                        }
                                                                                                                                                        for(let a=0; a<20; a++) {
                                                                                                                                                          if(wunschxxx[8*a + 7] !== "") {
                                                                                                                                                            AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                          }
                                                                                                                                                        }
                                                                                                                                                        if(AnzahlRiesenWunsch > 2) {
                                                                                                                                                          Error2 = 1;
                                                                                                                                                        }
                                                                                                                                                        
                                                                                                                                                        if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                          errorSchritt6 = "1. In diesem Topf kann es nur zwei 8er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                          if(Error1 === 1) {
                                                                                                                                                            errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                          }
                                                                                                                                                          else {
                                                                                                                                                            if(Error2 === 1) {
                                                                                                                                                              errorSchritt6 = "In diesem Topf kann es nur zwei 8er Wünsche geben.";
                                                                                                                                                            }
                                                                                                                                                          }
                                                                                                                                                        }
                                                                                                                                                      }
                                                                                                                                                  }
                                                                                                                                              }
                                                                                                                                          }
                                                                                                                                      }
                                                                                                                                  }
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    if(trainingseinheitentopf1 === "38") {
                                                                                                                                      if(bevorzugteTopfgroessetopf1 === "3") {
                                                                                                                                        if(AnzahlWuensche > 12) {
                                                                                                                                          Error1 = 1;
                                                                                                                                        }
                                                                                                                                        for(let a=0; a<20; a++) {
                                                                                                                                          if(wunschxxx[8*a + 3] !== "") {
                                                                                                                                            AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                          }
                                                                                                                                        }
                                                                                                                                        if(AnzahlRiesenWunsch > 2) {
                                                                                                                                          Error2 = 1;
                                                                                                                                        }
                                                                                                                                        
                                                                                                                                        if(Error1 === 1 && Error2 === 1) {
                                                                                                                                          errorSchritt6 = "1. In diesem Topf kann es nur zwei 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 12 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                          if(Error1 === 1) {
                                                                                                                                            errorSchritt6 = "In diesem Topf kann es insgesamt nur 12 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                          }
                                                                                                                                          else {
                                                                                                                                            if(Error2 === 1) {
                                                                                                                                              errorSchritt6 = "In diesem Topf kann es nur zwei 4er Wünsche geben.";
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                      else {
                                                                                                                                          if(bevorzugteTopfgroessetopf1 === "4") {
                                                                                                                                            if(alternativeTopfgroessetopf1 === "3") {
                                                                                                                                              if(AnzahlWuensche > 10) {
                                                                                                                                                Error1 = 1;
                                                                                                                                              }
                                                                                                                                              for(let a=0; a<20; a++) {
                                                                                                                                                if(wunschxxx[8*a + 3] !== "") {
                                                                                                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                              if(AnzahlRiesenWunsch > 8) {
                                                                                                                                                Error2 = 1;
                                                                                                                                              }
                                                                                                                                              
                                                                                                                                              if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                errorSchritt6 = "1. In diesem Topf kann es nur acht 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 10 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                              }
                                                                                                                                              else {
                                                                                                                                                if(Error1 === 1) {
                                                                                                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 10 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                  if(Error2 === 1) {
                                                                                                                                                    errorSchritt6 = "In diesem Topf kann es nur acht 4er Wünsche geben.";
                                                                                                                                                  }
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                              if(alternativeTopfgroessetopf1 === "5") {
                                                                                                                                                if(AnzahlWuensche > 9) {
                                                                                                                                                  Error1 = 1;
                                                                                                                                                }
                                                                                                                                                for(let a=0; a<20; a++) {
                                                                                                                                                  if(wunschxxx[8*a + 4] !== "") {
                                                                                                                                                    AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                  }
                                                                                                                                                }
                                                                                                                                                if(AnzahlRiesenWunsch > 2) {
                                                                                                                                                  Error2 = 1;
                                                                                                                                                }
                                                                                                                                                
                                                                                                                                                if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                  errorSchritt6 = "1. In diesem Topf kann es nur zwei 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 9 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                  if(Error1 === 1) {
                                                                                                                                                    errorSchritt6 = "In diesem Topf kann es insgesamt nur 9 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                  }
                                                                                                                                                  else {
                                                                                                                                                    if(Error2 === 1) {
                                                                                                                                                      errorSchritt6 = "In diesem Topf kann es nur zwei 5er Wünsche geben.";
                                                                                                                                                    }
                                                                                                                                                  }
                                                                                                                                                } 
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                          else {
                                                                                                                                              if(bevorzugteTopfgroessetopf1 === "5") {
                                                                                                                                                if(alternativeTopfgroessetopf1 === "4") {
                                                                                                                                                  if(AnzahlWuensche > 8) {
                                                                                                                                                    Error1 = 1;
                                                                                                                                                  }
                                                                                                                                                  for(let a=0; a<20; a++) {
                                                                                                                                                    if(wunschxxx[8*a + 4] !== "") {
                                                                                                                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                    }
                                                                                                                                                  }
                                                                                                                                                  if(AnzahlRiesenWunsch > 6) {
                                                                                                                                                    Error2 = 1;
                                                                                                                                                  }
                                                                                                                                                  
                                                                                                                                                  if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                    errorSchritt6 = "1. In diesem Topf kann es nur sechs 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 8 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                                  }
                                                                                                                                                  else {
                                                                                                                                                    if(Error1 === 1) {
                                                                                                                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 8 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                      if(Error2 === 1) {
                                                                                                                                                        errorSchritt6 = "In diesem Topf kann es nur sechs 5er Wünsche geben.";
                                                                                                                                                      }
                                                                                                                                                    }
                                                                                                                                                  }
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                  if(alternativeTopfgroessetopf1 === "6") {
                                                                                                                                                    if(AnzahlWuensche > 7) {
                                                                                                                                                      Error1 = 1;
                                                                                                                                                    }
                                                                                                                                                    for(let a=0; a<20; a++) {
                                                                                                                                                      if(wunschxxx[8*a + 5] !== "") {
                                                                                                                                                        AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                      }
                                                                                                                                                    }
                                                                                                                                                    if(AnzahlRiesenWunsch > 3) {
                                                                                                                                                      Error2 = 1;
                                                                                                                                                    }
                                                                                                                                                    
                                                                                                                                                    if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                      errorSchritt6 = "1. In diesem Topf kann es nur drei 6er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                      if(Error1 === 1) {
                                                                                                                                                        errorSchritt6 = "In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                      }
                                                                                                                                                      else {
                                                                                                                                                        if(Error2 === 1) {
                                                                                                                                                          errorSchritt6 = "In diesem Topf kann es nur drei 6er Wünsche geben.";
                                                                                                                                                        }
                                                                                                                                                      }
                                                                                                                                                    }
                                                                                                                                                  }
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                              else {
                                                                                                                                                  if(bevorzugteTopfgroessetopf1 === "6") {
                                                                                                                                                    if(alternativeTopfgroessetopf1 === "5") {
                                                                                                                                                      if(AnzahlWuensche > 7) {
                                                                                                                                                        Error1 = 1;
                                                                                                                                                      }
                                                                                                                                                      for(let a=0; a<20; a++) {
                                                                                                                                                        if(wunschxxx[8*a + 5] !== "") {
                                                                                                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                        }
                                                                                                                                                      }
                                                                                                                                                      if(AnzahlRiesenWunsch > 3) {
                                                                                                                                                        Error2 = 1;
                                                                                                                                                      }
                                                                                                                                                      
                                                                                                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                        errorSchritt6 = "1. In diesem Topf kann es nur drei 6er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                                      }
                                                                                                                                                      else {
                                                                                                                                                        if(Error1 === 1) {
                                                                                                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                          if(Error2 === 1) {
                                                                                                                                                            errorSchritt6 = "In diesem Topf kann es nur drei 6er Wünsche geben.";
                                                                                                                                                          }
                                                                                                                                                        }
                                                                                                                                                      }
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                      if(alternativeTopfgroessetopf1 === "7") {
                                                                                                                                                        if(AnzahlWuensche > 6) {
                                                                                                                                                          Error1 = 1;
                                                                                                                                                        }
                                                                                                                                                        for(let a=0; a<20; a++) {
                                                                                                                                                          if(wunschxxx[8*a + 6] !== "") {
                                                                                                                                                            AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                          }
                                                                                                                                                        }
                                                                                                                                                        if(AnzahlRiesenWunsch > 2) {
                                                                                                                                                          Error2 = 1;
                                                                                                                                                        }
                                                                                                                                                        
                                                                                                                                                        if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                          errorSchritt6 = "1. In diesem Topf kann es nur zwei 7er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                          if(Error1 === 1) {
                                                                                                                                                            errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                          }
                                                                                                                                                          else {
                                                                                                                                                            if(Error2 === 1) {
                                                                                                                                                              errorSchritt6 = "In diesem Topf kann es nur zwei 7er Wünsche geben.";
                                                                                                                                                            }
                                                                                                                                                          }
                                                                                                                                                        }
                                                                                                                                                      }
                                                                                                                                                    }
                                                                                                                                                  }
                                                                                                                                                  else {
                                                                                                                                                      if(bevorzugteTopfgroessetopf1 === "7") {
                                                                                                                                                        if(alternativeTopfgroessetopf1 === "6") {
                                                                                                                                                          if(AnzahlWuensche > 6) {
                                                                                                                                                            Error1 = 1;
                                                                                                                                                          }
                                                                                                                                                          for(let a=0; a<20; a++) {
                                                                                                                                                            if(wunschxxx[8*a + 6] !== "") {
                                                                                                                                                              AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                            }
                                                                                                                                                          }
                                                                                                                                                          if(AnzahlRiesenWunsch > 2) {
                                                                                                                                                            Error2 = 1;
                                                                                                                                                          }
                                                                                                                                                          
                                                                                                                                                          if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                            errorSchritt6 = "1. In diesem Topf kann es nur zwei 7er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                                          }
                                                                                                                                                          else {
                                                                                                                                                            if(Error1 === 1) {
                                                                                                                                                              errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                              if(Error2 === 1) {
                                                                                                                                                                errorSchritt6 = "In diesem Topf kann es nur zwei 7er Wünsche geben.";
                                                                                                                                                              }
                                                                                                                                                            }
                                                                                                                                                          }
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                          if(alternativeTopfgroessetopf1 === "8") {
                                                                                                                                                            if(AnzahlWuensche > 5) {
                                                                                                                                                              Error1 = 1;
                                                                                                                                                            }
                                                                                                                                                            for(let a=0; a<20; a++) {
                                                                                                                                                              if(wunschxxx[8*a + 7] !== "") {
                                                                                                                                                                AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                              }
                                                                                                                                                            }
                                                                                                                                                            if(AnzahlRiesenWunsch > 3) {
                                                                                                                                                              Error2 = 1;
                                                                                                                                                            }
                                                                                                                                                            
                                                                                                                                                            if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                              errorSchritt6 = "1. In diesem Topf kann es nur drei 8er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                              if(Error1 === 1) {
                                                                                                                                                                errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                              }
                                                                                                                                                              else {
                                                                                                                                                                if(Error2 === 1) {
                                                                                                                                                                  errorSchritt6 = "In diesem Topf kann es nur drei 8er Wünsche geben.";
                                                                                                                                                                }
                                                                                                                                                              }
                                                                                                                                                            }
                                                                                                                                                          }
                                                                                                                                                        }
                                                                                                                                                      }
                                                                                                                                                      else {
                                                                                                                                                          if(bevorzugteTopfgroessetopf1 === "8") {
                                                                                                                                                            if(AnzahlWuensche > 5) {
                                                                                                                                                              Error1 = 1;
                                                                                                                                                            }
                                                                                                                                                            for(let a=0; a<20; a++) {
                                                                                                                                                              if(wunschxxx[8*a + 7] !== "") {
                                                                                                                                                                AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                              }
                                                                                                                                                            }
                                                                                                                                                            if(AnzahlRiesenWunsch > 3) {
                                                                                                                                                              Error2 = 1;
                                                                                                                                                            }
                                                                                                                                                            
                                                                                                                                                            if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                              errorSchritt6 = "1. In diesem Topf kann es nur drei 8er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                              if(Error1 === 1) {
                                                                                                                                                                errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                              }
                                                                                                                                                              else {
                                                                                                                                                                if(Error2 === 1) {
                                                                                                                                                                  errorSchritt6 = "In diesem Topf kann es nur drei 8er Wünsche geben.";
                                                                                                                                                                }
                                                                                                                                                              }
                                                                                                                                                            }
                                                                                                                                                          }
                                                                                                                                                      }
                                                                                                                                                  }
                                                                                                                                              }
                                                                                                                                          }
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        if(trainingseinheitentopf1 === "39") {
                                                                                                                                          if(bevorzugteTopfgroessetopf1 === "3") {
                                                                                                                                            if(AnzahlWuensche > 13) {
                                                                                                                                              Error1 = 1;
                                                                                                                                            }
                                                                                                                                            for(let a=0; a<20; a++) {
                                                                                                                                              if(wunschxxx[8*a + 2] !== "") {
                                                                                                                                                AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                            if(AnzahlRiesenWunsch > 13) {
                                                                                                                                              Error2 = 1;
                                                                                                                                            }
                                                                                                                                  
                                                                                                                                            if(Error1 === 1 && Error2 === 1) {
                                                                                                                                              errorSchritt6 = "1. In diesem Topf kann es nur dreizehn 3er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 13 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                              if(Error1 === 1) {
                                                                                                                                                errorSchritt6 = "In diesem Topf kann es insgesamt nur 13 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                              }
                                                                                                                                              else {
                                                                                                                                                if(Error2 === 1) {
                                                                                                                                                  errorSchritt6 = "In diesem Topf kann es nur dreizehn 3er Wünsche geben.";
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                          else {
                                                                                                                                              if(bevorzugteTopfgroessetopf1 === "4") {
                                                                                                                                                if(alternativeTopfgroessetopf1 === "3") {
                                                                                                                                                  if(AnzahlWuensche > 10) {
                                                                                                                                                    Error1 = 1;
                                                                                                                                                  }
                                                                                                                                                  for(let a=0; a<20; a++) {
                                                                                                                                                    if(wunschxxx[8*a + 3] !== "") {
                                                                                                                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                    }
                                                                                                                                                  }
                                                                                                                                                  if(AnzahlRiesenWunsch > 9) {
                                                                                                                                                    Error2 = 1;
                                                                                                                                                  }
                                                                                                                                                  
                                                                                                                                                  if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                    errorSchritt6 = "1. In diesem Topf kann es nur neun 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 10 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                                  }
                                                                                                                                                  else {
                                                                                                                                                    if(Error1 === 1) {
                                                                                                                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 10 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                      if(Error2 === 1) {
                                                                                                                                                        errorSchritt6 = "In diesem Topf kann es nur neun 4er Wünsche geben.";
                                                                                                                                                      }
                                                                                                                                                    }
                                                                                                                                                  }
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                  if(alternativeTopfgroessetopf1 === "5") {
                                                                                                                                                    if(AnzahlWuensche > 9) {
                                                                                                                                                      Error1 = 1;
                                                                                                                                                    }
                                                                                                                                                    for(let a=0; a<20; a++) {
                                                                                                                                                      if(wunschxxx[8*a + 4] !== "") {
                                                                                                                                                        AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                      }
                                                                                                                                                    }
                                                                                                                                                    if(AnzahlRiesenWunsch > 3) {
                                                                                                                                                      Error2 = 1;
                                                                                                                                                    }
                                                                                                                                                    
                                                                                                                                                    if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                      errorSchritt6 = "1. In diesem Topf kann es nur drei 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 9 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                      if(Error1 === 1) {
                                                                                                                                                        errorSchritt6 = "In diesem Topf kann es insgesamt nur 9 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                      }
                                                                                                                                                      else {
                                                                                                                                                        if(Error2 === 1) {
                                                                                                                                                          errorSchritt6 = "In diesem Topf kann es nur drei 5er Wünsche geben.";
                                                                                                                                                        }
                                                                                                                                                      }
                                                                                                                                                    }
                                                                                                                                                  }
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                              else {
                                                                                                                                                  if(bevorzugteTopfgroessetopf1 === "5") {
                                                                                                                                                    if(alternativeTopfgroessetopf1 === "4") {
                                                                                                                                                      if(AnzahlWuensche > 8) {
                                                                                                                                                        Error1 = 1;
                                                                                                                                                      }
                                                                                                                                                      for(let a=0; a<20; a++) {
                                                                                                                                                        if(wunschxxx[8*a + 4] !== "") {
                                                                                                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                        }
                                                                                                                                                      }
                                                                                                                                                      if(AnzahlRiesenWunsch > 7) {
                                                                                                                                                        Error2 = 1;
                                                                                                                                                      }
                                                                                                                                                      
                                                                                                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                        errorSchritt6 = "1. In diesem Topf kann es nur sieben 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 8 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                                      }
                                                                                                                                                      else {
                                                                                                                                                        if(Error1 === 1) {
                                                                                                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 8 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                          if(Error2 === 1) {
                                                                                                                                                            errorSchritt6 = "In diesem Topf kann es nur sieben 5er Wünsche geben.";
                                                                                                                                                          }
                                                                                                                                                        }
                                                                                                                                                      }
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                      if(alternativeTopfgroessetopf1 === "6") {
                                                                                                                                                        if(AnzahlWuensche > 7) {
                                                                                                                                                          Error1 = 1;
                                                                                                                                                        }
                                                                                                                                                        for(let a=0; a<20; a++) {
                                                                                                                                                          if(wunschxxx[8*a + 5] !== "") {
                                                                                                                                                            AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                          }
                                                                                                                                                        }
                                                                                                                                                        if(AnzahlRiesenWunsch > 4) {
                                                                                                                                                          Error2 = 1;
                                                                                                                                                        }
                                                                                                                                                        
                                                                                                                                                        if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                          errorSchritt6 = "1. In diesem Topf kann es nur vier 6er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                          if(Error1 === 1) {
                                                                                                                                                            errorSchritt6 = "In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                          }
                                                                                                                                                          else {
                                                                                                                                                            if(Error2 === 1) {
                                                                                                                                                              errorSchritt6 = "In diesem Topf kann es nur vier 6er Wünsche geben.";
                                                                                                                                                            }
                                                                                                                                                          }
                                                                                                                                                        }
                                                                                                                                                      }
                                                                                                                                                    }
                                                                                                                                                  }
                                                                                                                                                  else {
                                                                                                                                                      if(bevorzugteTopfgroessetopf1 === "6") {
                                                                                                                                                        if(alternativeTopfgroessetopf1 === "5") {
                                                                                                                                                          if(AnzahlWuensche > 7) {
                                                                                                                                                            Error1 = 1;
                                                                                                                                                          }
                                                                                                                                                          for(let a=0; a<20; a++) {
                                                                                                                                                            if(wunschxxx[8*a + 5] !== "") {
                                                                                                                                                              AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                            }
                                                                                                                                                          }
                                                                                                                                                          if(AnzahlRiesenWunsch > 4) {
                                                                                                                                                            Error2 = 1;
                                                                                                                                                          }
                                                                                                                                                          
                                                                                                                                                          if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                            errorSchritt6 = "1. In diesem Topf kann es nur vier 6er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                                          }
                                                                                                                                                          else {
                                                                                                                                                            if(Error1 === 1) {
                                                                                                                                                              errorSchritt6 = "In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                              if(Error2 === 1) {
                                                                                                                                                                errorSchritt6 = "In diesem Topf kann es nur vier 6er Wünsche geben.";
                                                                                                                                                              }
                                                                                                                                                            }
                                                                                                                                                          }
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                          if(alternativeTopfgroessetopf1 === "7") {
                                                                                                                                                            if(AnzahlWuensche > 6) {
                                                                                                                                                              Error1 = 1;
                                                                                                                                                            }
                                                                                                                                                            for(let a=0; a<20; a++) {
                                                                                                                                                              if(wunschxxx[8*a + 6] !== "") {
                                                                                                                                                                AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                              }
                                                                                                                                                            }
                                                                                                                                                            if(AnzahlRiesenWunsch > 3) {
                                                                                                                                                              Error2 = 1;
                                                                                                                                                            }
                                                                                                                                                            
                                                                                                                                                            if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                              errorSchritt6 = "1. In diesem Topf kann es nur drei 7er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                              if(Error1 === 1) {
                                                                                                                                                                errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                              }
                                                                                                                                                              else {
                                                                                                                                                                if(Error2 === 1) {
                                                                                                                                                                  errorSchritt6 = "In diesem Topf kann es nur drei 7er Wünsche geben.";
                                                                                                                                                                }
                                                                                                                                                              }
                                                                                                                                                            }
                                                                                                                                                          }
                                                                                                                                                        }
                                                                                                                                                      }
                                                                                                                                                      else {
                                                                                                                                                          if(bevorzugteTopfgroessetopf1 === "7") {
                                                                                                                                                            if(alternativeTopfgroessetopf1 === "6") {
                                                                                                                                                              if(AnzahlWuensche > 6) {
                                                                                                                                                                Error1 = 1;
                                                                                                                                                              }
                                                                                                                                                              for(let a=0; a<20; a++) {
                                                                                                                                                                if(wunschxxx[8*a + 6] !== "") {
                                                                                                                                                                  AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                                }
                                                                                                                                                              }
                                                                                                                                                              if(AnzahlRiesenWunsch > 3) {
                                                                                                                                                                Error2 = 1;
                                                                                                                                                              }
                                                                                                                                                              
                                                                                                                                                              if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                                errorSchritt6 = "1. In diesem Topf kann es nur drei 7er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                                              }
                                                                                                                                                              else {
                                                                                                                                                                if(Error1 === 1) {
                                                                                                                                                                  errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                                }
                                                                                                                                                                else {
                                                                                                                                                                  if(Error2 === 1) {
                                                                                                                                                                    errorSchritt6 = "In diesem Topf kann es nur drei 7er Wünsche geben.";
                                                                                                                                                                  }
                                                                                                                                                                }
                                                                                                                                                              }
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                              if(alternativeTopfgroessetopf1 === "8") {
                                                                                                                                                                if(AnzahlWuensche > 5) {
                                                                                                                                                                  Error1 = 1;
                                                                                                                                                                }
                                                                                                                                                                for(let a=0; a<20; a++) {
                                                                                                                                                                  if(wunschxxx[8*a + 7] !== "") {
                                                                                                                                                                    AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                                  }
                                                                                                                                                                }
                                                                                                                                                                if(AnzahlRiesenWunsch > 4) {
                                                                                                                                                                  Error2 = 1;
                                                                                                                                                                }
                                                                                                                                                                
                                                                                                                                                                if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                                  errorSchritt6 = "1. In diesem Topf kann es nur vier 8er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                                                }
                                                                                                                                                                else {
                                                                                                                                                                  if(Error1 === 1) {
                                                                                                                                                                    errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                                  }
                                                                                                                                                                  else {
                                                                                                                                                                    if(Error2 === 1) {
                                                                                                                                                                      errorSchritt6 = "In diesem Topf kann es nur vier 8er Wünsche geben.";
                                                                                                                                                                    }
                                                                                                                                                                  }
                                                                                                                                                                }
                                                                                                                                                              }
                                                                                                                                                            }
                                                                                                                                                          }
                                                                                                                                                          else {
                                                                                                                                                              if(bevorzugteTopfgroessetopf1 === "8") {
                                                                                                                                                                if(AnzahlWuensche > 5) {
                                                                                                                                                                  Error1 = 1;
                                                                                                                                                                }
                                                                                                                                                                for(let a=0; a<20; a++) {
                                                                                                                                                                  if(wunschxxx[8*a + 7] !== "") {
                                                                                                                                                                    AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                                  }
                                                                                                                                                                }
                                                                                                                                                                if(AnzahlRiesenWunsch > 4) {
                                                                                                                                                                  Error2 = 1;
                                                                                                                                                                }
                                                                                                                                                                
                                                                                                                                                                if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                                  errorSchritt6 = "1. In diesem Topf kann es nur vier 8er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                                                }
                                                                                                                                                                else {
                                                                                                                                                                  if(Error1 === 1) {
                                                                                                                                                                    errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                                  }
                                                                                                                                                                  else {
                                                                                                                                                                    if(Error2 === 1) {
                                                                                                                                                                      errorSchritt6 = "In diesem Topf kann es nur vier 8er Wünsche geben.";
                                                                                                                                                                    }
                                                                                                                                                                  }
                                                                                                                                                                }
                                                                                                                                                              }
                                                                                                                                                          }
                                                                                                                                                      }
                                                                                                                                                  }
                                                                                                                                              }
                                                                                                                                          }
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            if(trainingseinheitentopf1 === "40") {
                                                                                                                                                if(bevorzugteTopfgroessetopf1 === "3") {
                                                                                                                                                  if(AnzahlWuensche > 13) {
                                                                                                                                                    Error1 = 1;
                                                                                                                                                  }
                                                                                                                                                  for(let a=0; a<20; a++) {
                                                                                                                                                    if(wunschxxx[8*a + 3] !== "") {
                                                                                                                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                    }
                                                                                                                                                  }
                                                                                                                                                  if(AnzahlRiesenWunsch > 1) {
                                                                                                                                                    Error2 = 1;
                                                                                                                                                  }
                                                                                                                                                  
                                                                                                                                                  if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                    errorSchritt6 = "1. In diesem Topf kann es nur einen 4er Wunsch geben. UND: 2. In diesem Topf kann es insgesamt nur 13 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                                  }
                                                                                                                                                  else {
                                                                                                                                                    if(Error1 === 1) {
                                                                                                                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 13 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                      if(Error2 === 1) {
                                                                                                                                                        errorSchritt6 = "In diesem Topf kann es nur einen 4er Wunsch geben.";
                                                                                                                                                      }
                                                                                                                                                    }
                                                                                                                                                  }
                                                                                                                                                }
                                                                                                                                                else {
                                                                                                                                                    if(bevorzugteTopfgroessetopf1 === "4") {
                                                                                                                                                      if(AnzahlWuensche > 10) {
                                                                                                                                                        Error1 = 1;
                                                                                                                                                      }
                                                                                                                                                      for(let a=0; a<20; a++) {
                                                                                                                                                        if(wunschxxx[8*a + 3] !== "") {
                                                                                                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                        }
                                                                                                                                                      }
                                                                                                                                                      if(AnzahlRiesenWunsch > 10) {
                                                                                                                                                        Error2 = 1;
                                                                                                                                                      }
                                                                                                                                            
                                                                                                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                        errorSchritt6 = "1. In diesem Topf kann es nur zehn 4er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 10 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                                      }
                                                                                                                                                      else {
                                                                                                                                                        if(Error1 === 1) {
                                                                                                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 10 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                          if(Error2 === 1) {
                                                                                                                                                            errorSchritt6 = "In diesem Topf kann es nur zehn 4er Wünsche geben.";
                                                                                                                                                          }
                                                                                                                                                        }
                                                                                                                                                      }
                                                                                                                                                    }
                                                                                                                                                    else {
                                                                                                                                                        if(bevorzugteTopfgroessetopf1 === "5") {
                                                                                                                                                          if(AnzahlWuensche > 8) {
                                                                                                                                                            Error1 = 1;
                                                                                                                                                          }
                                                                                                                                                          for(let a=0; a<20; a++) {
                                                                                                                                                            if(wunschxxx[8*a + 4] !== "") {
                                                                                                                                                              AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                            }
                                                                                                                                                          }
                                                                                                                                                          if(AnzahlRiesenWunsch > 8) {
                                                                                                                                                            Error2 = 1;
                                                                                                                                                          }
                                                                                                                                                          
                                                                                                                                                          if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                            errorSchritt6 = "1. In diesem Topf kann es nur acht 5er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 8 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                                          }
                                                                                                                                                          else {
                                                                                                                                                            if(Error1 === 1) {
                                                                                                                                                              errorSchritt6 = "In diesem Topf kann es insgesamt nur 8 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                              if(Error2 === 1) {
                                                                                                                                                                errorSchritt6 = "In diesem Topf kann es nur acht 5er Wünsche geben.";
                                                                                                                                                              }
                                                                                                                                                            }
                                                                                                                                                          }
                                                                                                                                                        }
                                                                                                                                                        else {
                                                                                                                                                            if(bevorzugteTopfgroessetopf1 === "6") {
                                                                                                                                                              if(alternativeTopfgroessetopf1 === "5") {
                                                                                                                                                                if(AnzahlWuensche > 7) {
                                                                                                                                                                  Error1 = 1;
                                                                                                                                                                }
                                                                                                                                                                for(let a=0; a<20; a++) {
                                                                                                                                                                  if(wunschxxx[8*a + 5] !== "") {
                                                                                                                                                                    AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                                  }
                                                                                                                                                                }
                                                                                                                                                                if(AnzahlRiesenWunsch > 5) {
                                                                                                                                                                  Error2 = 1;
                                                                                                                                                                }
                                                                                                                                                                
                                                                                                                                                                if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                                  errorSchritt6 = "1. In diesem Topf kann es nur fünf 6er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                                                }
                                                                                                                                                                else {
                                                                                                                                                                  if(Error1 === 1) {
                                                                                                                                                                    errorSchritt6 = "In diesem Topf kann es insgesamt nur 7 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                                  }
                                                                                                                                                                  else {
                                                                                                                                                                    if(Error2 === 1) {
                                                                                                                                                                      errorSchritt6 = "In diesem Topf kann es nur fünf 6er Wünsche geben.";
                                                                                                                                                                    }
                                                                                                                                                                  }
                                                                                                                                                                }
                                                                                                                                                              }
                                                                                                                                                              else {
                                                                                                                                                                if(alternativeTopfgroessetopf1 === "7") {
                                                                                                                                                                  if(AnzahlWuensche > 6) {
                                                                                                                                                                    Error1 = 1;
                                                                                                                                                                  }
                                                                                                                                                                  for(let a=0; a<20; a++) {
                                                                                                                                                                    if(wunschxxx[8*a + 6] !== "") {
                                                                                                                                                                      AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                                    }
                                                                                                                                                                  }
                                                                                                                                                                  if(AnzahlRiesenWunsch > 4) {
                                                                                                                                                                    Error2 = 1;
                                                                                                                                                                  }
                                                                                                                                                                  
                                                                                                                                                                  if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                                    errorSchritt6 = "1. In diesem Topf kann es nur vier 7er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                                                  }
                                                                                                                                                                  else {
                                                                                                                                                                    if(Error1 === 1) {
                                                                                                                                                                      errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                                    }
                                                                                                                                                                    else {
                                                                                                                                                                      if(Error2 === 1) {
                                                                                                                                                                        errorSchritt6 = "In diesem Topf kann es nur vier 7er Wünsche geben.";
                                                                                                                                                                      }
                                                                                                                                                                    }
                                                                                                                                                                  }
                                                                                                                                                                }
                                                                                                                                                              }
                                                                                                                                                            }
                                                                                                                                                            else {
                                                                                                                                                                if(bevorzugteTopfgroessetopf1 === "7") {
                                                                                                                                                                    if(alternativeTopfgroessetopf1 === "6") {
                                                                                                                                                                      if(AnzahlWuensche > 6) {
                                                                                                                                                                        Error1 = 1;
                                                                                                                                                                      }
                                                                                                                                                                      for(let a=0; a<20; a++) {
                                                                                                                                                                        if(wunschxxx[8*a + 6] !== "") {
                                                                                                                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                                        }
                                                                                                                                                                      }
                                                                                                                                                                      if(AnzahlRiesenWunsch > 4) {
                                                                                                                                                                        Error2 = 1;
                                                                                                                                                                      }
                                                                                                                                                                      
                                                                                                                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                                        errorSchritt6 = "1. In diesem Topf kann es nur vier 7er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                                                      }
                                                                                                                                                                      else {
                                                                                                                                                                        if(Error1 === 1) {
                                                                                                                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 6 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                                        }
                                                                                                                                                                        else {
                                                                                                                                                                          if(Error2 === 1) {
                                                                                                                                                                            errorSchritt6 = "In diesem Topf kann es nur vier 7er Wünsche geben.";
                                                                                                                                                                          }
                                                                                                                                                                        }
                                                                                                                                                                      }
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                                else {
                                                                                                                                                                    if(bevorzugteTopfgroessetopf1 === "8") {
                                                                                                                                                                      if(AnzahlWuensche > 5) {
                                                                                                                                                                        Error1 = 1;
                                                                                                                                                                      }
                                                                                                                                                                      for(let a=0; a<20; a++) {
                                                                                                                                                                        if(wunschxxx[8*a + 7] !== "") {
                                                                                                                                                                          AnzahlRiesenWunsch = AnzahlRiesenWunsch + 1;
                                                                                                                                                                        }
                                                                                                                                                                      }
                                                                                                                                                                      if(AnzahlRiesenWunsch > 5) {
                                                                                                                                                                        Error2 = 1;
                                                                                                                                                                      }
                                                                                                                                                                      
                                                                                                                                                                      if(Error1 === 1 && Error2 === 1) {
                                                                                                                                                                        errorSchritt6 = "1. In diesem Topf kann es nur fünf 8er Wünsche geben. UND: 2. In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche."
                                                                                                                                                                      }
                                                                                                                                                                      else {
                                                                                                                                                                        if(Error1 === 1) {
                                                                                                                                                                          errorSchritt6 = "In diesem Topf kann es insgesamt nur 5 Wünsche geben. Fassen Sie Wünsche zusammen oder entfernen Sie Wünsche.";
                                                                                                                                                                        }
                                                                                                                                                                        else {
                                                                                                                                                                          if(Error2 === 1) {
                                                                                                                                                                            errorSchritt6 = "In diesem Topf kann es nur fünf 8er Wünsche geben.";
                                                                                                                                                                          }
                                                                                                                                                                        }
                                                                                                                                                                      }
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    return errorSchritt6;
};

export function ExportAnzeigeSpDa(SpDa, SpDaC, NutzerId) {
  let Text = "short ";
  Text = "".concat(Text, "h=" + NutzerId + "; ");
  for(let k=0; k<57*800; k++) {
    if(SpDa[k] !== null) {
      Text = "".concat(Text, "SpDa[h][" + k + "]=\"" + SpDa[k] + "\"; ");
    }
  }

  let Text1 = "short ";
  Text1 = "".concat(Text1, "f=" + NutzerId + "; ");
  for(let k=0; k<21*400; k++) {
    if(SpDaC[k] !== null) {
      Text1 = "".concat(Text1, "SpDaC[f][" + k + "]=\"" + SpDaC[k] + "\"; ");
    }
  }

  return(
    <div>{Text}<br/><br/><br/>{Text1}</div>
  )
}

export function ExportAnzeigeAnFo(AnFo, AnFoC) {
  let Text = "short ";
  for(let m=0; m<200; m++) {
    const Wert = AnFo[m];
    if(Wert[0] !== null) {
      for(let k=0; k<5; k++) {
        if(k === 0) {
          Text = "".concat(Text, "m=" + m + "; ");
        }
        if(Wert[k] !== null) {
          Text = "".concat(Text, "AnFo[m][" + k + "]=\"" + Wert[k] + "\"; ");
        }
      }
    }
  }

  let Text1 = "short ";
  for(let n=0; n<200; n++) {
    const Wert = AnFoC[n];
    if(Wert[0] !== null) {
      for(let k=0; k<17; k++) {
        if(k === 0) {
          Text1 = "".concat(Text1, "n=" + n + "; ");
        }
        if(Wert[k] !== null) {
          Text1 = "".concat(Text1, "AnFoC[n][" + k + "]=\"" + Wert[k] + "\"; ");
        }
      }
    }
  }

  return(
    <div>{Text}<br/>{Text1}</div>
  )
}

export function ExportAnzeigeAnDa(AnDa) {
  let Text = "short ";
  for(let i=0; i<200; i++) {
    const Wert = AnDa[i];
    for(let k=0; k<7; k++) {
      if(Wert[k] === null) { //Nicht nötig das dann später in Spring zu importieren!
        k=7;
      }
      else {
        if(k === 0) {
          Text = "".concat(Text, "i=" + i + "; ");
        }
        Text = "".concat(Text, "AnDa[i][" + k + "]=\"" + Wert[k] + "\"; ");
      }
    }
  }
  return(
    <div>{Text}</div>
  )
}

export function ExportAnzeigeAlDa(AlDa, NutzerId) {
  let Text1 = "";
  let Text2 = "";
  let Text3 = "";
  let Text4 = "";
  let Text5 = "";
  let Text6 = "";
  let Text7 = "";
  let Text8 = "";
  let Text9 = "";
  let Text10 = "";
  let Text11 = "";
  let Text12 = "";
  let Text13 = "";
  let Text14 = "";
  let Text15 = "";
  let Text16 = "";
  let Text17 = "";
  let Text18 = "";
  
  let Text = "short ";
  Text = "".concat(Text, "j=" + NutzerId + "; ");
  for(let k=0; k<44527; k++) {
    if(AlDa[k] !== null) {
      if(k > 42500) {
        Text18 = "".concat(Text18, "AlDa[j][" + k + "]=\"" + AlDa[k] + "\"; ");
      }
      else {
        if(k > 40000) {
          Text17 = "".concat(Text17, "AlDa[j][" + k + "]=\"" + AlDa[k] + "\"; ");
        }
        else {
          if(k > 37500) {
            Text16 = "".concat(Text16, "AlDa[j][" + k + "]=\"" + AlDa[k] + "\"; ");
          }
          else {
            if(k > 35000) {
              Text15 = "".concat(Text15, "AlDa[j][" + k + "]=\"" + AlDa[k] + "\"; ");
            }
            else {
              if(k > 32500) {
                Text14 = "".concat(Text14, "AlDa[j][" + k + "]=\"" + AlDa[k] + "\"; ");
              }
              else {
                if(k > 30000) {
                  Text13 = "".concat(Text13, "AlDa[j][" + k + "]=\"" + AlDa[k] + "\"; ");
                }
                else {
                  if(k > 27500) {
                    Text12 = "".concat(Text12, "AlDa[j][" + k + "]=\"" + AlDa[k] + "\"; ");
                  }
                  else {
                    if(k > 25000) {
                      Text11 = "".concat(Text11, "AlDa[j][" + k + "]=\"" + AlDa[k] + "\"; ");
                    }
                    else {
                      if(k > 22500) {
                        Text10 = "".concat(Text10, "AlDa[j][" + k + "]=\"" + AlDa[k] + "\"; ");
                      }
                      else {
                        if(k > 20000) {
                          Text9 = "".concat(Text9, "AlDa[j][" + k + "]=\"" + AlDa[k] + "\"; ");
                        }
                        else {
                          if(k > 17500) {
                            Text8 = "".concat(Text8, "AlDa[j][" + k + "]=\"" + AlDa[k] + "\"; ");
                          }
                          else {
                            if(k > 15000) {
                              Text7 = "".concat(Text7, "AlDa[j][" + k + "]=\"" + AlDa[k] + "\"; ");
                            }
                            else {
                              if(k > 12500) {
                                Text6 = "".concat(Text6, "AlDa[j][" + k + "]=\"" + AlDa[k] + "\"; ");
                              }
                              else {
                                if(k > 10000) {
                                  Text5 = "".concat(Text5, "AlDa[j][" + k + "]=\"" + AlDa[k] + "\"; ");
                                }
                                else {
                                  if(k > 7500) {
                                    Text4 = "".concat(Text4, "AlDa[j][" + k + "]=\"" + AlDa[k] + "\"; ");
                                  }
                                  else {
                                    if(k > 5000) {
                                      Text3 = "".concat(Text3, "AlDa[j][" + k + "]=\"" + AlDa[k] + "\"; ");
                                    }
                                    else {
                                      if(k > 2500) {
                                        Text2 = "".concat(Text2, "AlDa[j][" + k + "]=\"" + AlDa[k] + "\"; ");
                                      }
                                      else {
                                        Text1 = "".concat(Text1, "AlDa[j][" + k + "]=\"" + AlDa[k] + "\"; ");
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  return(
    <div>
      <p>{Text}</p>
      <p>{Text1}</p>
      <p>{Text2}</p>
      <p>{Text3}</p>
      <p>{Text4}</p>
      <p>{Text5}</p>
      <p>{Text6}</p>
      <p>{Text7}</p>
      <p>{Text8}</p>
      <p>{Text9}</p>
      <p>{Text10}</p>
      <p>{Text11}</p>
      <p>{Text12}</p>
      <p>{Text13}</p>
      <p>{Text14}</p>
      <p>{Text15}</p>
      <p>{Text16}</p>
      <p>{Text17}</p>
      <p>{Text18}</p>
    </div>
  )
}

export function ExportAnzeigeLiga(Liga, NutzerId) {
  let Text = "";
  for(let m=0; m<1; m++) {
    const Wert = Liga[m];
    if(Wert[0] !== null) {
      for(let k=0; k<45; k++) {
        if(Wert[k] !== null) {
          Text = "".concat(Text, "Liga[" + NutzerId + "][" + k + "]=\"" + Wert[k] + "\"; ");
        }
      }
    }
  }

  let Text1 = "short ";
  for(let m=0; m<150+10; m++) {
    const Wert = Liga[1+m];
    if(Wert[0] !== null) {
      for(let k=0; k<9+2; k++) {
        if(k === 0) {
          Text1 = "".concat(Text1, "m=" + m + "; ");
        }
        if(Wert[k] !== null) {
          Text1 = "".concat(Text1, "SpieleGruppe1[" + NutzerId + "][m][" + k + "]=\"" + Wert[k] + "\"; ");
        }
      }
    }
  }

  let Text2 = "short ";
  for(let m=0; m<150+10; m++) {
    const Wert = Liga[1+150+10+m];
    if(Wert[0] !== null) {
      for(let k=0; k<9+2; k++) {
        if(k === 0) {
          Text2 = "".concat(Text2, "m=" + m + "; ");
        }
        if(Wert[k] !== null) {
          Text2 = "".concat(Text2, "SpieleGruppe2[" + NutzerId + "][m][" + k + "]=\"" + Wert[k] + "\"; ");
        }
      }
    }
  }

  return(
    <div>
      <p>{Text}</p>
      <p>{Text1}</p>
      <p>{Text2}</p>
    </div>
  )
}

export function ExportAnzeigeBewertung(Bewertung) {
  //Das hier muss nicht in Spring importiert werden
  let Text = "";
  for(let i=0; i<200; i++) {
    const Wert = Bewertung[i];
    Text = "".concat(Text, "i=" + i + " ");
    for(let k=0; k<800; k++) {
      if(Wert[k] !== null) {
        Text = "".concat(Text, Wert[k] + " | ");
      }
    }
  }
  return(
    <div>{Text}</div>
  )
}