import React from "react";
import "./footer.css";

export const Footer = () => (
  <footer>
    <div className="footer">
      <div className="containerfooter">
        <nav id="darkbluecolor">
          <ul className="navbar-nav">
            <li>
              <u><a className="weiss" href="/impressum">
                Impressum
              </a></u>
              {""} | {""}
              <u><a className="weiss" href="/agb">
                AGB
              </a></u>
            </li>
            <span className="querstrich">|</span>
            <li>
              <u><a className="weiss" href="/datenschutzerklaerung">
                Datenschutzerklärung
              </a></u>
            </li>
          </ul>

          <br/>
          
          <div className="copyright">
            &#169; 2021 TennisGroupOrganizer
          </div>

        </nav>
      </div>
    </div>
  </footer>
);
