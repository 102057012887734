import React from "react";
import { UnderHeaderDSE } from "./UnderHeaderDSE";
import "./fußzeile.css";

export const DSE = () => (
  <div>
    <UnderHeaderDSE />
    <div className="container">
      <p>
        <strong>
          1. Information über die Erhebung personenbezogener Daten und
          Kontaktdaten des Verantwortlichen
        </strong>
      </p>
      <p>
        1.1. Wir freuen uns, dass Sie unsere Website{" "}
        <a href="/">https://tennisgrouporganizer.com</a> (nachfolgend „Website“
        genannt) besuchen und bedanken uns für Ihr Interesse. Der Schutz Ihrer
        Privatsphäre ist für uns sehr wichtig. Nachstehend informieren wir Sie
        über den Umgang mit Ihren personenbezogenen Daten bei Nutzung unserer
        Website. Personenbezogene Daten sind alle Informationen, die sich auf
        eine identifizierte oder identifizierbare natürliche Person (im
        Folgenden „betroffene Person“) beziehen.
      </p>
      <p>
        1.2. Verantwortlicher für die Datenverarbeitung im Sinne der
        Datenschutz-Grundverordnung (DSGVO) ist:
        <br />
        <br />
        TennisGroupOrganizer GbR - Philipp Frohn und Niklas Wienand GbR
        <br />
        Nibelungenstraße 20
        <br />
        50354 Hürth
        <br />
        Deutschland
        <br />
        <br />
        Tel: 0176 47913345
        <br />
        E-Mail:{" "}
        <a href="mailto:info@tennisgrouporganizer.com">
          info@tennisgrouporganizer.com
        </a>
        <br />
        USt.-IdNr.: DE328949139
        <br/>
        <br />
        Der für die Verarbeitung von personenbezogenen Daten Verantwortliche ist
        diejenige natürliche oder juristische Person, die allein oder gemeinsam
        mit anderen über die Zwecke und Mittel der Verarbeitung von
        personenbezogenen Daten entscheidet.
      </p>
      <p>
        1.3. Diese Website nutzt aus Sicherheitsgründen und zum Schutz der
        Übertragung personenbezogener Daten und anderer vertraulicher Inhalte,
        wie z.B. Bestellungen oder Anfragen, die Sie an uns als Verantwortlichen
        senden, eine SSL-bzw. TLS-Verschlüsselung. Sie können eine
        verschlüsselte Verbindung an der Zeichenfolge &bdquo;https://&ldquo; und
        dem Schloss-Symbol in Ihrer Browserzeile erkennen.
      </p>

      <p>
        <strong>2. Datenerfassung beim Besuch unserer Website</strong>
      </p>

      <p>
        2.1. Sie haben die Möglichkeit unsere Website zu besuchen, ohne sich zu
        registrieren oder uns anderweitig Informationen zu übermitteln. Bei
        jedem Aufruf unserer Website erheben wir nur solche Daten, die Ihr
        Browser automatisch an unseren Server übermittelt. Dieses sind
        sogenannte Server-Logfiles. Beim Aufruf unserer Website erheben wir
        nachstehende Daten, die ausschließlich zum Zwecke der Sicherstellung
        eines störungsfreien Betriebs der Website erforderlich sind:
      </p>
      <p>
        <li>Unsere besuchte Website</li>
        <li>Datum und Uhrzeit zum Zeitpunkt des Zugriffes</li>
        <li>Menge der gesendeten Daten in Byte</li>
        <li>Quelle/Verweis, von welchem Sie auf die Seite gelangten</li>
        <li>Verwendeter Browser</li>
        <li>Verwendetes Betriebssystem</li>
        <li>Verwendete IP-Adresse (in anonymisierter Form)</li>
      </p>
      <p>
        2.2. Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis
        unseres berechtigten Interesses an der technisch fehlerfreien
        Darstellung und Optimierung unserer Website. Eine Weitergabe oder
        anderweitige Verwendung der Daten findet nicht statt.
      </p>
      <p>
        <strong>3. Hosting</strong>
      </p>

      <strong>Externes Hosting durch IONOS</strong>

      <p>
        Diese Website wird bei einem externen Dienstleister gehostet (Hoster).
        Hoster ist die 1&amp;1 IONOS SE, Elgendorfer Str. 57, 56410 Montabaur,
        Deutschland (im Folgenden &bdquo;IONOS&ldquo; genannt).
        Personenbezogenen Daten, die auf dieser Website erfasst werden, werden
        auf den Servern von IONOS gespeichert. Hierbei kann es sich u.a. um
        IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
        Vertragsdaten, Kontaktdaten, Namen, Websitenzugriffe und sonstige Daten,
        die über eine Website generiert werden, handeln.
        <br />
        Der Einsatz von IONOS erfolgt zum Zwecke der Vertragserfüllung gegenüber
        unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO)
        und im Interesse einer sicheren, schnellen und effizienten
        Bereitstellung unseres Online-Angebots durch einen professionellen
        Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
        <br />
        IONOS wird Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung
        seiner Leistungspflichten erforderlich ist und unsere Weisungen in Bezug
        auf diese Daten befolgen. Eine weitere Verarbeitung auf anderen Servern
        als den vorgenannten von IONOS findet nur im nachstehend mitgeteilten
        Rahmen statt.
        <br />
        Um die datenschutzkonforme Verarbeitung zu gewährleisten, haben wir
        einen Vertrag über Auftragsverarbeitung mit IONOS geschlossen.
        <br />
        Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
        Datenschutzerklärung von IONOS:{" "}
        <a
          href="https://www.ionos.de/terms-gtc/terms-privacy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.ionos.de/terms-gtc/terms-privacy/
        </a>
        .
      </p>
      <p>
        <strong>
          4. Kontaktaufnahme und Datenübermittlung
        </strong>
      </p>
      <p>
        Im Rahmen Ihrer Bestellung, einer Kontaktaufnahme mit uns (z.B. per
        Kontaktformular oder E-Mail) oder der Übermittlung Ihrer Daten für die
        Trainingsanmeldung werden Ihre personenbezogenen Daten direkt durch uns
        erhoben. Welche Daten erhoben werden, ist aus dem jeweiligen
        Formular ersichtlich. Diese Daten werden ausschließlich zum Zweck
        der Beantwortung Ihrer Anfrage, der Kontaktaufnahme oder der Übermittlung
        an den zuständigen Trainingskoordinator des jeweiligen Vereins und die
        damit zusammenhängende technische Verwaltung gespeichert und verwendet.
        Rechtsgrundlage der Verarbeitung sind unsere berechtigten Interessen
        gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO. Sofern Ihre Anfrage auf den
        Abschluss eines Vertrages gerichtet ist, ist ferner Rechtsgrundlage Art.
        6 Abs. 1 S. 1 lit. b DSGVO.
      </p>
      <p>
        <strong>
          5. Datenverarbeitung bei Eröffnung eines Kundenkontos und zur
          Vertragsabwicklung
        </strong>
      </p>
      <p>
        Sofern Sie uns zur Durchführung eines Vertrages oder bei der Eröffnung
        eines Kundenkontos personenbezogene Daten mitteilen, werden diese gem.
        Art. 6 Abs. 1 lit. b DSGVO erhoben und verarbeitet. Welche Daten erhoben
        werden, können Sie aus den jeweiligen Eingabeformularen entnehmen. Die
        Löschung Ihres Kundenkontos ist jederzeit möglich und kann durch eine
        E-Mail an den Verantwortlichen erfolgen.
      </p>
      <p>
        Die uns von Ihnen mitgeteilten Daten speichern und verwenden wir zur
        Vertragsabwicklung. Nach vollständiger Abwicklung des Vertrages oder
        Löschung Ihres Kundenkontos werden Ihre Daten im Hinblick auf steuer-
        und handelsrechtliche Aufbewahrungsfristen gesperrt und nach Ablauf
        dieser Fristen gelöscht, sofern Sie nicht ausdrücklich in eine weitere
        Nutzung Ihrer Daten eingewilligt haben oder wir uns eine darüber
        hinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist.
      </p>
      <p>
        <strong>6. Webanalysedienste</strong>
      </p>
      <strong>1&1 IONOS WebAnalytics</strong>
      <p>
        Diese Website verwendet „IONOS WebAnalytics“, einen Webanalysedienst der
        1&1 IONOS Internet SE, Elgendorfer Str. 57, 56410 Montabaur (im
        Folgenden „IONOS“ genannt), um bestimmte Nutzeraktionen nachzuvollziehen
        und auszuwerten. Hierfür ist auf unserer Website ein
        Javascript-basiertes Tracking-Pixel implementiert, das als
        Ein-Pixel-Datei durch Interaktion mit IONOS Nutzeraktionen nachverfolgen
        kann. Über das Tracking-Pixel werden bestimmte Nutzerinformationen in
        anonymisierter Form erhoben, an IONOS übertragen und dort ausgewertet.
        Zu diesen anonymisierten Informationen zählen unter anderem der Referrer
        (zuvor besuchte Website), die angeforderte Website oder Datei, der
        Browsertyp und Browserversion, das verwendete Betriebssystem, der
        verwendete Gerätetyp, die Uhrzeit des Zugriff, die IP-Adresse (wird nur
        zur Feststellung des Orts des Zugriffs verwendet). Es werden dabei keine
        Daten an Dritte weitergegeben.
      </p>
      <p>
        Zweck der Datenerhebung ist ausschließlich zur statistischen Auswertung
        und zur technischen Optimierung des Webangebots. Rechtsgrundlage ist
        unser berechtigtes Interesse an der statistischen Auswertung des
        Nutzerverhaltens zu Optimierungs- und Marketingzwecken gemäß gemäß Art.
        6 Abs. 1 lit. f DSGVO.
      </p>
      <p>
        Wenn Sie die künftige Verarbeitung von Informationen durch IONOS nicht
        wünschen, können Sie dies verhindern, indem sie die Ausführung von
        Java-Script in Ihrem Browser deaktivieren. Alternativ können Sie die
        Ausführung von Java-Script Code auch dadurch verhindern, dass Sie einen
        Java-Script-Blocker installieren (z.B.{" "}
        <a
          href="https://noscript.net/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://noscript.net/
        </a>{" "}
        oder{" "}
        <a
          href="https://www.ghostery.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.ghostery.com
        </a>
        ). Hierbei ist es allerdings sehr wahrscheinlich, dass Sie nicht alle
        Funktionen der Internetseite vollumfänglich nutzen können.
      </p>
      <p>
        Soweit rechtlich erforderlich, haben wir zur vorstehend dargestellten
        Verarbeitung Ihrer Daten Ihre Einwilligung gemäß Art. 6 Abs. 1 lit. a
        DSGVO eingeholt. Sie können Ihre erteilte Einwilligung jederzeit mit
        Wirkung für die Zukunft widerrufen. Um Ihren Widerruf auszuüben,
        befolgen Sie bitte die vorstehend geschilderte Möglichkeit zur Vornahme
        eines Widerspruchs. Weitere Informationen zum Umgang mit Nutzerdaten
        finden Sie in der Datenschutzerklärung von IONOS:{" "}
        <a
          href="https://www.ionos.de/terms-gtc/terms-privacy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.ionos.de/terms-gtc/terms-privacy/
        </a>
        .
      </p>
      <p>
        <strong>7. ​Ihre Rechte als Betroffener</strong>
      </p>
      <strong>7.1. Als Betroffener haben Sie das Recht:</strong>
      <p className="datenschutzerklaerung">
        <li>
          <strong>Auskunftsrecht gemäß Art. 15 DSGVO:</strong> Sie haben
          insbesondere ein Recht auf Auskunft über Ihre von uns verarbeiteten
          personenbezogenen Daten, die Verarbeitungszwecke, die Kategorien der
          verarbeiteten personenbezogenen Daten, die Empfänger oder Kategorien
          von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder
          werden, die geplante Speicherdauer bzw. die Kriterien für die
          Festlegung der Speicherdauer, das Bestehen eines Rechts auf
          Berichtigung, Löschung, Einschränkung der Verarbeitung, Widerspruch
          gegen die Verarbeitung, Beschwerde bei einer Aufsichtsbehörde, die
          Herkunft Ihrer Daten, wenn diese nicht durch uns bei Ihnen erhoben
          wurden, das Bestehen einer automatisierten Entscheidungsfindung
          einschließlich Profiling und ggf. aussagekräftige Informationen über
          die involvierte Logik und die Sie betreffende Tragweite und die
          angestrebten Auswirkungen einer solchen Verarbeitung, sowie Ihr Recht
          auf Unterrichtung, welche Garantien gemäß Art. 46 DSGVO bei
          Weiterleitung Ihrer Daten in Drittländer bestehen;
        </li>
        <li>
          <strong>Recht auf Berichtigung gemäß Art. 16 DSGVO:</strong> Sie haben
          ein Recht auf unverzügliche Berichtigung Sie betreffender unrichtiger
          Daten und/oder Vervollständigung Ihrer bei uns gespeicherten
          unvollständigen Daten
        </li>
        <li>
          <strong>Recht auf Löschung gemäß Art. 17 DSGVO:</strong> Sie haben das
          Recht, die Löschung Ihrer personenbezogenen Daten bei Vorliegen der
          Voraussetzungen des Art. 17 Abs. 1 DSGVO zu verlangen. Dieses Recht
          besteht jedoch insbesondere dann nicht, wenn die Verarbeitung zur
          Ausübung des Rechts auf freie Meinungsäußerung und Information, zur
          Erfüllung einer rechtlichen Verpflichtung, aus Gründen des
          öffentlichen Interesses oder zur Geltendmachung, Ausübung oder
          Verteidigung von Rechtsansprüchen erforderlich ist;
        </li>
        <li>
          <strong>
            Recht auf Einschränkung der Verarbeitung gemäß Art. 18 DSGVO:
          </strong>{" "}
          Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen, solange die von Ihnen
          bestrittene Richtigkeit Ihrer Daten überprüft wird, wenn Sie eine
          Löschung Ihrer Daten wegen unzulässiger Datenverarbeitung ablehnen und
          stattdessen die Einschränkung der Verarbeitung Ihrer Daten verlangen,
          wenn Sie Ihre Daten zur Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen benötigen, nachdem wir diese Daten nach
          Zweckerreichung nicht mehr benötigen oder wenn Sie Widerspruch aus
          Gründen Ihrer besonderen Situation eingelegt haben, solange noch nicht
          feststeht, ob unsere berechtigten Gründe überwiegen;
        </li>
        <li>
          <strong>Recht auf Unterrichtung gemäß Art. 19 DSGVO:</strong> Sie
          haben das Recht auf Berichtigung, Löschung oder Einschränkung der
          Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist
          dieser verpflichtet, allen Empfängern, denen die Sie betreffenden
          personenbezogenen Daten offengelegt wurden, diese Berichtigung oder
          Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es
          sei denn, dies erweist sich als unmöglich oder ist mit einem
          unverhältnismäßigen Aufwand verbunden. Ihnen steht das Recht zu, über
          diese Empfänger unterrichtet zu werden;
        </li>
        <li>
          <strong>Recht auf Datenübertragbarkeit gemäß Art. 20 DSGVO:</strong>{" "}
          Sie haben das Recht, Ihre personenbezogenen Daten, die Sie uns
          bereitgestellt haben, in einem strukturierten, gängigen und
          maschinenlesebaren Format zu erhalten oder die übermittlung an einen
          anderen Verantwortlichen zu verlangen, soweit dies technisch machbar
          ist;
        </li>
        <li>
          <strong>
            Recht auf Widerruf erteilter Einwilligungen gemäß Art. 7 Abs. 3
            DSGVO:
          </strong>
          Sie haben das Recht, eine einmal erteilte Einwilligung in die
          Verarbeitung von Daten jederzeit mit Wirkung für die Zukunft zu
          widerrufen. Im Falle des Widerrufs werden wir die betroffenen Daten
          unverzüglich löschen, sofern eine weitere Verarbeitung nicht auf eine
          Rechtsgrundlage zur einwilligungslosen Verarbeitung gestützt werden
          kann. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit, der
          aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung
          nicht berührt;
        </li>
        <li>
          <strong>Recht auf Beschwerde gemäß Art. 77 DSGVO:</strong> Wenn Sie
          der Ansicht sind, dass die Verarbeitung der Sie betreffenden
          personenbezogenen Daten gegen die DSGVO verstößt, haben Sie -
          unbeschadet eines anderweitigen verwaltungsrechtlichen oder
          gerichtlichen Rechtsbehelfs - das Recht auf Beschwerde bei einer
          Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres
          Aufenthaltsortes, Ihres Arbeitsplatzes oder des Ortes des mutmaßlichen
          Verstoßes.
        </li>
      </p>
      <div>
        <strong>7.2. Widerspruchsrecht</strong>
      </div>

      <p>
        <strong>
          WENN WIR IM RAHMEN EINER INTERESSENABWäGUNG IHRE PERSONENBEZOGENEN
          DATEN AUFGRUND UNSERES üBERWIEGENDEN BERECHTIGTEN INTERESSES
          VERARBEITEN, HABEN SIE DAS JEDERZEITIGE RECHT, AUS GRüNDEN, DIE SICH
          AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIESE VERARBEITUNG
          WIDERSPRUCH MIT WIRKUNG FüR DIE ZUKUNFT EINZULEGEN. MACHEN SIE VON
          IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE VERARBEITUNG DER
          BETROFFENEN DATEN. EINE WEITERVERARBEITUNG BLEIBT ABER VORBEHALTEN,
          WENN WIR ZWINGENDE SCHUTZWüRDIGE GRüNDE FüR DIE VERARBEITUNG
          NACHWEISEN KöNNEN, DIE IHRE INTERESSEN, GRUNDRECHTE UND
          GRUNDFREIHEITEN üBERWIEGEN, ODER WENN DIE VERARBEITUNG DER
          GELTENDMACHUNG, AUSüBUNG ODER VERTEIDIGUNG VON RECHTSANSPRüCHEN DIENT.
          WERDEN IHRE PERSONENBEZOGENEN DATEN VON UNS VERARBEITET, UM
          DIREKTWERBUNG ZU BETREIBEN, HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH
          GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM
          ZWECKE DERARTIGER WERBUNG EINZULEGEN. SIE KöNNEN DEN WIDERSPRUCH WIE
          OBEN BESCHRIEBEN AUSüBEN. MACHEN SIE VON IHREM WIDERSPRUCHSRECHT
          GEBRAUCH, BEENDEN WIR DIE VERARBEITUNG DER BETROFFENEN DATEN ZU
          DIREKTWERBEZWECKEN.
        </strong>
      </p>
      <p>
        <strong>8. Dauer der Speicherung personenbezogener Daten</strong>
      </p>
      <p>
        Die Dauer der Speicherung von personenbezogenen Daten ist abhängig von
        der jeweiligen Rechtsgrundlage, dem Verarbeitungszweck und &ndash;
        sofern einschlägig &ndash; zusätzlich von etwaiger steuer- und
        handelsrechtlicher Aufbewahrungsfristen. Ihre Daten werden nach Ihrer
        ausdrücklichen Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO von uns so
        lange gespeichert, bis Sie Ihre Einwilligung widerrufen. Wenn es
        gesetzliche Aufbewahrungsfristen für die Speicherung Ihrer Daten
        existieren, die während rechtsgeschäftlicher bzw.
        rechtsgeschäftsähnlicher Verpflichtungen verarbeitet werden, werden
        diese Daten nach Ablauf der Aufbewahrungsfristen routinemäßig gelöscht,
        sofern diese nicht mehr zur Verarbeitung für die Erfüllung eines
        Vertrages oder einer Vertragsanbahnung gemäß Art. 6 Abs. 1 lit. b DSGVO
        erforderlich sind und/oder unsererseits kein berechtigtes Interesse an
        der Weiterspeicherung fortbesteht. Ihre personenbezogenen Daten, die auf
        Rechtsgrundlage der Verarbeitung zur Wahrung unseres berechtigten
        Interesses gemäß Art. 6 Abs. 1 lit. f DSGVO gespeichert wurden, werden
        so lange gespeichert, bis Sie Ihr Widerspruchsrecht gemäß Art. 21 Abs. 1
        DSGVO ausüben, es sei denn, wir können zwingende schutzwürdige Gründe
        für die Verarbeitung nachweisen, die die Interessen, Rechte und
        Freiheiten der betroffenen Person überwiegen, oder die Verarbeitung
        dient der Geltendmachung, Ausübung oder Verteidigung von
        Rechtsansprüchen. Ihre Daten, die auf Rechtsgrundlage zum Zwecke der
        Direktwerbung gemäß Art. 6 Abs. 1 lit. f DSGVO gespeichert wurden,
        werden so lange gespeichert, bis Sie Ihr Widerspruchsrecht gemäß Art. 21
        Abs. 2 DSGVO ausüben. Ihre gespeicherten personenbezogenen Daten werden
        dann gelöscht, wenn diese für die Zwecke, für die diese erhoben oder auf
        sonstige Weise verarbeitet wurden, nicht mehr notwendig sind. Dies gilt
        nicht, wenn sich aus den sonstigen Informationen dieser Erklärung über
        spezifische Verarbeitungssituationen etwas anderes ergibt.
      </p>
      <strong>Stand: 01.01.2021</strong>
      
    </div>
  </div>
);
