import React from "react";
import { UnderHeaderDemoVideo } from "./UnderHeaderDemoVideo";
//import Video from "./Demo_Erklaervideo.mp4";
import "./demo.css";

export class DemoVideo extends React.Component {
  render() {
    return (
      <div>
        <UnderHeaderDemoVideo />
        {/* <div className="container demovideo">
          <video src={Video} controls></video>
        </div> */}
        <div className="container">
          Video folgt in Kürze!
        </div>
      </div>
    );
  }
}
