import React from "react";
import "./App.css";
import { UnderHeaderVereine } from "./components/UnderHeaderVereine";
import axios from "axios";

export class AnmeldungTraining extends React.Component {
  constructor() {
    super();
    this.state = {
      Auswahl: "",
      NameEltern: "",
      NameKind: "",
      Geschlecht: "Geschlecht",
      Alter: "",
      Jahr: null,
      Monat: null,
      Tag: null,
      Mitgliedsstatus: "--- bitte auswählen ---",
      Anzahl: "0",
      email: "",
      nummer: "",
      zeitxx: ["","","","","","","","","","","","","","","",""],
      matrixxx:["0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0"],
      rememberMe: false,
      ErrorAnmeldebedingungen: false,

      Matrixzeit: [ "X", "X", "X", "X", "X", "X", "X", "X", 
      "X", "X", "X", "X", "X", "X", "X", "X", 
      "X", "X", "X", "X", "X", "X", "X", "X", 
      "X", "X", "X", "X", "X", "X", "X", "X", 
      "X", "X", "X", "X", "X", "X", "X", "X", 
      "X", "X", "X", "X", "X", "X", "X", "X" ],

      Bemerkungen: "",
      Abgeschickt: 0,
      
      eilmeldung: "",
      sperren: "Yes",

      MaximaleAnzahlTraining: 1,
      MindestensTraining: 1,
      BemerkungenTrainer: "",
      BemerkungenTrainer2: "",
      BemerkungenTrainer3: "",
      Anmeldebedingungen: false,

      Sternanzahl: 0,
      Feedback: "",
      WurdeSchonGeschickt: false,
      Bestaetigungsmail: false,
    };
  }

  componentDidMount() {
    axios
    .get("/api/Vereine")
    .then((res) => {
      const message1 = res.data;
      this.setState({ sperren: message1[0] });
    })
    .catch((error) => {
      console.log(error.message1);
    });

    axios
    .get("/api/Eilmeldung")
    .then((res) => {
      const message1 = res.data;
      this.setState({ 
        eilmeldung: message1,
      });
    })
    .catch((error) => {
      console.log(error.message1);
    });

    let url = window.location.pathname
    let laenge = url.length - 1;
    let Vereinsname = url.slice(-laenge)
    while(Vereinsname.includes("_")) {
        let Teil1 = Vereinsname.slice(0, Vereinsname.indexOf("_"))
        let Teil2 = Vereinsname.slice(Vereinsname.indexOf("_") + 1, laenge)
        Vereinsname = Teil1 + " " + Teil2
    }
    this.setState({Auswahl: Vereinsname})

    var getlink4 = "".concat("/api/Uhrzeiten/", url.slice(-laenge));
    axios
    .get(getlink4)
    .then((res) => {
        const message = res.data;
        let zeitxxNeu = [];
        for(let i=0; i<16; i++) {
        zeitxxNeu.push(message[i]);
        }
        let matrixxxNeu = [];
        for(let i=0; i<48; i++) {
        matrixxxNeu.push(message[21+i]);
        }
        this.setState({ zeitxx: zeitxxNeu, MaximaleAnzahlTraining: message[16],
        BemerkungenTrainer: message[17], BemerkungenTrainer2: message[18],
        BemerkungenTrainer3: message[19], MindestensTraining: message[20],
        matrixxx: matrixxxNeu });
    })
    .catch((error) => {
        console.log(error.message);
    });
  }

  
  Bewertung() {
    if(this.state.Sternanzahl === 0) {
      return(
        <div>
          <span className="sterne">
            <button onClick={(event) => {this.setState({Sternanzahl: 1})}}>
              &#9734;
            </button>
          </span>
          <span className="sterne">
            <button onClick={(event) => {this.setState({Sternanzahl: 2})}}>
              &#9734;
            </button>
          </span>
          <span className="sterne">
            <button onClick={(event) => {this.setState({Sternanzahl: 3})}}>
              &#9734;
            </button>
          </span>
          <span className="sterne">
            <button onClick={(event) => {this.setState({Sternanzahl: 4})}}>
              &#9734;
            </button>
          </span>
          <span className="sterne">
            <button onClick={(event) => {this.setState({Sternanzahl: 5})}}>
              &#9734;
            </button>
          </span>
        </div>
      );
    }
    else {
      if(this.state.Sternanzahl === 1) {
        return(
          <div>
            <span className="gold">
              <button onClick={(event) => {this.setState({Sternanzahl: 1})}}>
                &#9733;
              </button>
            </span>
            <span className="sterne">
              <button onClick={(event) => {this.setState({Sternanzahl: 2})}}>
                &#9734;
              </button>
            </span>
            <span className="sterne">
              <button onClick={(event) => {this.setState({Sternanzahl: 3})}}>
                &#9734;
              </button>
            </span>
            <span className="sterne">
              <button onClick={(event) => {this.setState({Sternanzahl: 4})}}>
                &#9734;
              </button>
            </span>
            <span className="sterne">
              <button onClick={(event) => {this.setState({Sternanzahl: 5})}}>
                &#9734;
              </button>
            </span>
          </div>
        );
      }
      else {
        if(this.state.Sternanzahl === 2) {
          return(
            <div>
              <span className="gold">
                <button onClick={(event) => {this.setState({Sternanzahl: 1})}}>
                  &#9733;
                </button>
              </span>
              <span className="gold">
                <button onClick={(event) => {this.setState({Sternanzahl: 2})}}>
                  &#9733;
                </button>
              </span>
              <span className="sterne">
                <button onClick={(event) => {this.setState({Sternanzahl: 3})}}>
                  &#9734;
                </button>
              </span>
              <span className="sterne">
                <button onClick={(event) => {this.setState({Sternanzahl: 4})}}>
                  &#9734;
                </button>
              </span>
              <span className="sterne">
                <button onClick={(event) => {this.setState({Sternanzahl: 5})}}>
                  &#9734;
                </button>
              </span>
            </div>
          );
        }
        else {
          if(this.state.Sternanzahl === 3) {
            return(
              <div>
                <span className="gold">
                  <button onClick={(event) => {this.setState({Sternanzahl: 1})}}>
                    &#9733;
                  </button>
                </span>
                <span className="gold">
                  <button onClick={(event) => {this.setState({Sternanzahl: 2})}}>
                    &#9733;
                  </button>
                </span>
                <span className="gold">
                  <button onClick={(event) => {this.setState({Sternanzahl: 3})}}>
                    &#9733;
                  </button>
                </span>
                <span className="sterne">
                  <button onClick={(event) => {this.setState({Sternanzahl: 4})}}>
                    &#9734;
                  </button>
                </span>
                <span className="sterne">
                  <button onClick={(event) => {this.setState({Sternanzahl: 5})}}>
                    &#9734;
                  </button>
                </span>
              </div>
            );
          }
          else {
            if(this.state.Sternanzahl === 4) {
              return(
                <div>
                  <span className="gold">
                    <button onClick={(event) => {this.setState({Sternanzahl: 1})}}>
                      &#9733;
                    </button>
                  </span>
                  <span className="gold">
                    <button onClick={(event) => {this.setState({Sternanzahl: 2})}}>
                      &#9733;
                    </button>
                  </span>
                  <span className="gold">
                    <button onClick={(event) => {this.setState({Sternanzahl: 3})}}>
                      &#9733;
                    </button>
                  </span>
                  <span className="gold">
                    <button onClick={(event) => {this.setState({Sternanzahl: 4})}}>
                      &#9733;
                    </button>
                  </span>
                  <span className="sterne">
                    <button onClick={(event) => {this.setState({Sternanzahl: 5})}}>
                      &#9734;
                    </button>
                  </span>
                </div>
              );
            }
            else {
              if(this.state.Sternanzahl === 5) {
                return(
                  <div>
                    <span className="gold">
                      <button onClick={(event) => {this.setState({Sternanzahl: 1})}}>
                        &#9733;
                      </button>
                    </span>
                    <span className="gold">
                      <button onClick={(event) => {this.setState({Sternanzahl: 2})}}>
                        &#9733;
                      </button>
                    </span>
                    <span className="gold">
                      <button onClick={(event) => {this.setState({Sternanzahl: 3})}}>
                        &#9733;
                      </button>
                    </span>
                    <span className="gold">
                      <button onClick={(event) => {this.setState({Sternanzahl: 4})}}>
                        &#9733;
                      </button>
                    </span>
                    <span className="gold">
                      <button onClick={(event) => {this.setState({Sternanzahl: 5})}}>
                        &#9733;
                      </button>
                    </span>
                  </div>
                );
              }
            }
          }
        }
      }
    }
  }

  Bewertungsenden() {
    if(this.state.WurdeSchonGeschickt === false) {
      const Bewertung = {
        anzahlsterne: this.state.Sternanzahl,
        feedback: this.state.Feedback,
        auswahl: this.state.Auswahl
      };

      axios.post("/api/Bewertung", Bewertung).then(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );

      this.setState({Sternanzahl: 0, Feedback: "Vielen Dank für Ihre Bewertung.", WurdeSchonGeschickt: true})
    }
    else {
      this.setState({Feedback: "Sie haben Ihre Bewertung bereits abgeschickt."})
    }
  }

  AnzahlTraining() {
    let array = ["0"];
    if(this.state.MaximaleAnzahlTraining === "1") {
      array.push("1")
    }
    else {
      if(this.state.MaximaleAnzahlTraining === "2") {
        array.push("1")
        array.push("2")
      }
      else {
        if(this.state.MaximaleAnzahlTraining === "3") {
          array.push("1")
          array.push("2")
          array.push("3")
        }
      }
    }
    return array;
  }

  validate = () => {
    let ErrorNameEltern= "";
    let ErrorNameKind= "";
    let ErrorGeschlecht= "";
    let ErrorAlter= "";
    let ErrorMitgliedsstatus= "";
    let ErrorAnzahl= "";
    let Erroremail= "";
    let Errornummer= "";
    let ErrorrememberMe = "";
    let ErrorAnmeldebedingungen = "";
    let Error = "";


    let Zaehler = 0;
    for(let i=0; i<48; i++) {
      if(this.state.Matrixzeit[i] !== "X") {
        Zaehler = Zaehler + 1;
      }
    }
    if(Zaehler < this.state.MindestensTraining) {
      Error = "Die zuständige Person hat eingestellt, dass mindestens "
      + this.state.MindestensTraining + " Uhrzeiten angegeben werden müssen";
    }
    if (!this.state.NameEltern) {
      ErrorNameEltern = "Falls " + this.state.NameKind + " volljährig ist, schreiben Sie bitte \"-\" in das Feld";
      Error = "Es fehlen noch Angaben.";
    }
    if (!this.state.NameKind) {
      ErrorNameKind = "fehlt ";
      Error = "Es fehlen noch Angaben.";
    }
    if (this.state.Geschlecht === "Geschlecht") {
      ErrorGeschlecht = "fehlt";
      Error = "Es fehlen noch Angaben.";
    }
    if (!this.state.Alter) {
      ErrorAlter = "fehlt ";
      Error = "Es fehlen noch Angaben.";
    }
    if (this.state.Mitgliedsstatus === "--- bitte auswählen ---") {
      ErrorMitgliedsstatus = "fehlt ";
      Error = "Es fehlen noch Angaben.";
    }
    if (this.state.Anzahl === "0") {
      ErrorAnzahl = "fehlt ";
      Error = "Es fehlen noch Angaben.";
    }
    if (!this.state.email) {
      Erroremail = "fehlt ";
      Error = "Es fehlen noch Angaben.";
    }
    if (!this.state.nummer) {
      Errornummer = "fehlt ";
      Error = "Es fehlen noch Angaben.";
    }
    if (!this.state.rememberMe) {
      ErrorrememberMe = "fehlt";
      Error = "Es fehlen noch Angaben.";
    }
    if (!this.state.Anmeldebedingungen) {
      ErrorAnmeldebedingungen = "fehlt";
      Error = "Es fehlen noch Angaben.";
    }
    if (Error) {
      this.setState({
        ErrorNameEltern,
        ErrorNameKind,
        ErrorGeschlecht,
        ErrorAlter,
        ErrorMitgliedsstatus,
        ErrorAnzahl,
        Erroremail,
        Errornummer,
        ErrorrememberMe,
        ErrorAnmeldebedingungen,
        Error,
      });
      return false;
    }

    return true;
  };

  handleSubmit = (event) => {
    const isValid = this.validate();
    if (isValid) {
      let Mailschicken;
      if(this.state.Bestaetigungsmail === false) {
        Mailschicken = "Nein";
      }
      else {
        Mailschicken = "Ja";
      }

      let url = window.location.pathname
      let laenge = url.length - 1;
      let Vereinsname = url.slice(-laenge)

      const Spielerdaten = {
        auswahl: Vereinsname,
        nameEltern: this.state.NameEltern,
        nameKind: this.state.NameKind,
        geschlecht: this.state.Geschlecht,
        alter: this.state.Alter,
        mitgliedsstatus: this.state.Mitgliedsstatus,
        anzahl: this.state.Anzahl,
        email: this.state.email,
        nummer: this.state.nummer,
        matrixzeit: this.state.Matrixzeit,
        bemerkungen: this.state.Bemerkungen,
        mailschicken: Mailschicken,
        zeitxx: this.state.zeitxx,
      };
      
      axios.post("/api/Spieler", Spielerdaten).then(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );
      
      this.setState({Abgeschickt: 1})
      window.scrollTo(0, 0);
    }
  };

  render() {
    if(this.state.sperren === "No") {
      if(this.state.Abgeschickt === 0) {
        return(
        <div>
            <UnderHeaderVereine />
            <div className="container">
            <div>
                <div className="gross">
                <br/>
                Hiermit melde ich
                <input className="name"
                    type="text"
                    placeholder="Vor- & Nachname"
                    value={this.state.NameKind}
                    onChange={(event) => { this.setState({ NameKind: event.target.value })}}
                />
                <span id="roteFarbe">&nbsp;{this.state.ErrorNameKind}</span>
                (
                <select className="geschlecht"
                    value={this.state.Geschlecht}
                    onChange={(event) => {this.setState({Geschlecht: event.target.value})}}
                >
                    <option disabled>Geschlecht</option>
                    <option>weiblich</option>
                    <option>männlich</option>
                </select>
                <span id="roteFarbe">{this.state.ErrorGeschlecht}</span>
                , geboren am
                <input className="alter"
                    type="text"
                    value={this.state.Alter}
                    placeholder="TT.MM.JJJJ"
                    onChange={(event) => { this.setState({ Alter: event.target.value }) }}
                />
                <span id="roteFarbe">&nbsp;{this.state.ErrorAlter}</span>)
                zum Tennistraining im <span className="blauerhintergrund">{this.state.Auswahl}</span> an.
                {this.state.Geschlecht === "Geschlecht" ? (
                  <span> Sie/Er</span>
                ) : this.state.Geschlecht === "weiblich" ? (
                  <span> Sie</span>
                ) : (
                  <span> Er</span>
                )}
                &nbsp;ist
                <select className="mitglied"
                    value={this.state.Mitgliedsstatus}
                    onChange={(event) => {this.setState({Mitgliedsstatus: event.target.value})}}
                >
                    <option disabled>--- bitte auswählen ---</option>
                    <option>Mitglied</option>
                    <option>kein Mitglied</option>
                </select>
                <span id="roteFarbe">&nbsp;{this.state.ErrorMitgliedsstatus}</span>
                des Vereins und möchte
                <select className="anzahl"
                    value={this.state.Anzahl}
                    onChange={(event) => {this.setState({Anzahl: event.target.value})}}
                >
                {this.AnzahlTraining().map((e, key) => {
                    return <option key={e}>{e}</option>;
                })}
                </select>
                <span id="roteFarbe">&nbsp;{this.state.ErrorAnzahl}</span>
                Mal in der Woche trainieren.
                
                {this.state.NameEltern === "---Ü18---" ? (
                    <p>
                    <br/>
                    Kontaktdaten:
                    <input className="email"
                        type="mail"
                        value={this.state.email}
                        placeholder="E-Mail"
                        onChange={(event) => { this.setState({ email: event.target.value })}}
                    />
                    <span id="roteFarbe">&nbsp;{this.state.Erroremail}</span>
                    <input className="telefon"
                        type="telefon"
                        value={this.state.nummer}
                        placeholder="Telefon"
                        onChange={(event) => { this.setState({ nummer: event.target.value })}}
                    />
                    <span id="roteFarbe">&nbsp;{this.state.Errornummer}</span>
                    </p>
                ) : ( 
                    <p>
                    <br/>
                    Name eines Erziehungsberechtigten (bei Minderjährigen):
                    <input className="erziehung"
                        type="text"
                        value={this.state.NameEltern}
                        placeholder="Vor- & Nachname"
                        onChange={(event) => { this.setState({ NameEltern: event.target.value })}}
                    />
                    <span id="roteFarbe">&nbsp;{this.state.ErrorNameEltern}</span>
                    <br/>
                    Kontaktdaten:
                    <input className="email"
                        type="mail"
                        value={this.state.email}
                        placeholder="E-Mail"
                        onChange={(event) => { this.setState({ email: event.target.value })}}
                    />
                    <span id="roteFarbe">&nbsp;{this.state.Erroremail}</span>
                    <input className="telefon"
                        type="telefon"
                        value={this.state.nummer}
                        placeholder="Telefon"
                        onChange={(event) => { this.setState({ nummer: event.target.value })}}
                    />
                    <span id="roteFarbe">&nbsp;{this.state.Errornummer}</span>
                    </p>
                )}
                <input
                    type="checkbox"
                    checked={this.state.Bestaetigungsmail}
                    onChange={(event) => {
                    if(this.state.Bestaetigungsmail === false) {
                        this.setState({Bestaetigungsmail: true})
                    }
                    else {
                        this.setState({Bestaetigungsmail: false})
                    }
                    }}
                /> Ich möchte nach der Anmeldung eine Bestätigungsmail erhalten.
                </div>
                
                <p>
                <br/>
                <br/>
                Grauer Kasten = {this.state.NameKind} kann nicht zu dieser Uhrzeit<br/>
                Blauer Kasten (1x klicken) = {this.state.NameKind} kann zu dieser Uhrzeit<br/>
                Goldene Schrift (2x klicken) = {this.state.NameKind} bevorzugt diese Uhrzeit
                </p>
                
                <div className="platzauswahltabellegross">
                <div className="ZeitenTabelle">
                    <table>
                    <tbody>
                        <tr>
                        <td>
                            <strong>Montag</strong>
                        </td>
                        <td>
                            <strong>Dienstag</strong>
                        </td>
                        <td>
                            <strong>Mittwoch</strong>
                        </td>
                        <td>
                            <strong>Donnerstag</strong>
                        </td>
                        <td>
                            <strong>Freitag</strong>
                        </td>
                        <td>
                        &nbsp;&nbsp;
                        </td>
                        <td>
                            <strong>Samstag</strong>
                        </td>
                        </tr>
                        <tr>
                        {this.state.matrixxx[0] !== "0" ? (
                          this.state.Matrixzeit[0] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[0] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[0]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[0] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[0] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[0]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[0] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[0]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                            <td></td>
                        )}
                        {this.state.matrixxx[1] !== "0" ? (
                          this.state.Matrixzeit[1] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[1] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[0]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[1] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[1] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[0]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[1] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[0]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        {this.state.matrixxx[2] !== "0" ? (
                          this.state.Matrixzeit[2] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[2] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[0]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[2] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[2] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[0]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[2] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[0]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        {this.state.matrixxx[3] !== "0" ? (
                          this.state.Matrixzeit[3] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[3] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[0]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[3] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[3] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[0]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[3] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[0]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        {this.state.matrixxx[4] !== "0" ? (
                          this.state.Matrixzeit[4] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[4] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[0]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[4] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[4] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[0]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[4] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[0]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        <td>
                        &nbsp;&nbsp;
                        </td>
                        {this.state.matrixxx[5] !== "0" ? (
                          this.state.Matrixzeit[5] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[5] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[8]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[5] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[5] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[8]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[5] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[8]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        </tr>
                        <tr>
                        {this.state.matrixxx[6] !== "0" ? (
                          this.state.Matrixzeit[6] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[6] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[1]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[6] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[6] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[1]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[6] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[1]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        {this.state.matrixxx[7] !== "0" ? (
                          this.state.Matrixzeit[7] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[7] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[1]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[7] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[7] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[1]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[7] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[1]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        {this.state.matrixxx[8] !== "0" ? (
                          this.state.Matrixzeit[8] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[8] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[1]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[8] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[8] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[1]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[8] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[1]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        {this.state.matrixxx[9] !== "0" ? (
                          this.state.Matrixzeit[9] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[9] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[1]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[9] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[9] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[1]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[9] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[1]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        {this.state.matrixxx[10] !== "0" ? (
                          this.state.Matrixzeit[10] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[10] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[1]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[10] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[10] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[1]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[10] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[1]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        <td>
                        &nbsp;&nbsp;
                        </td>
                        {this.state.matrixxx[11] !== "0" ? (
                          this.state.Matrixzeit[11] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[11] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[9]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[11] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[11] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[9]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[11] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[9]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        </tr>
                        <tr>
                        {this.state.matrixxx[12] !== "0" ? (
                          this.state.Matrixzeit[12] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[12] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[2]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[12] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[12] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[2]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[12] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[2]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        {this.state.matrixxx[13] !== "0" ? (
                          this.state.Matrixzeit[13] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[13] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[2]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[13] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[13] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[2]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[13] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[2]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        {this.state.matrixxx[14] !== "0" ? (
                          this.state.Matrixzeit[14] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[14] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[2]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[14] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[14] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[2]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[14] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[2]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        {this.state.matrixxx[15] !== "0" ? (
                          this.state.Matrixzeit[15] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[15] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[2]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[15] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[15] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[2]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[15] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[2]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        {this.state.matrixxx[16] !== "0" ? (
                          this.state.Matrixzeit[16] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[16] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[2]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[16] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[16] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[2]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[16] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[2]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        <td>
                        &nbsp;&nbsp;
                        </td>
                        {this.state.matrixxx[17] !== "0" ? (
                          this.state.Matrixzeit[17] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[17] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[10]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[17] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[17] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[10]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[17] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[10]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        </tr>
                        <tr>
                        {this.state.matrixxx[18] !== "0" ? (
                          this.state.Matrixzeit[18] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[18] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[3]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[18] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[18] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[3]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[18] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[3]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        {this.state.matrixxx[19] !== "0" ? (
                          this.state.Matrixzeit[19] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[19] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[3]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[19] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[19] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[3]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[19] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[3]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        {this.state.matrixxx[20] !== "0" ? (
                          this.state.Matrixzeit[20] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[20] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[3]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[20] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[20] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[3]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[20] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[3]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        {this.state.matrixxx[21] !== "0" ? (
                          this.state.Matrixzeit[21] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[21] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[3]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[21] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[21] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[3]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[21] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[3]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        {this.state.matrixxx[22] !== "0" ? (
                          this.state.Matrixzeit[22] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[22] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[3]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[22] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[22] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[3]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[22] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[3]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        <td>
                        &nbsp;&nbsp;
                        </td>
                        {this.state.matrixxx[23] !== "0" ? (
                          this.state.Matrixzeit[23] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[23] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[11]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[23] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[23] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[11]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[23] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[11]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        </tr>
                        <tr>
                        {this.state.matrixxx[24] !== "0" ? (
                          this.state.Matrixzeit[24] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[24] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[4]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[24] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[24] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[4]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[24] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[4]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        {this.state.matrixxx[25] !== "0" ? (
                          this.state.Matrixzeit[25] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[25] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[4]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[25] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[25] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[4]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[25] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[4]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        {this.state.matrixxx[26] !== "0" ? (
                          this.state.Matrixzeit[26] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[26] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[4]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[26] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[26] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[4]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[26] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[4]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        {this.state.matrixxx[27] !== "0" ? (
                          this.state.Matrixzeit[27] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[27] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[4]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[27] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[27] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[4]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[27] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[4]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        {this.state.matrixxx[28] !== "0" ? (
                          this.state.Matrixzeit[28] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[28] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[4]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[28] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[28] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[4]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[28] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[4]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        <td>
                        &nbsp;&nbsp;
                        </td>
                        {this.state.matrixxx[29] !== "0" ? (
                          this.state.Matrixzeit[29] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[29] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[12]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[29] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[29] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[12]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[29] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[12]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        </tr>
                        <tr>
                        {this.state.matrixxx[30] !== "0" ? (
                          this.state.Matrixzeit[30] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[30] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[5]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[30] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[30] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[5]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[30] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[5]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        {this.state.matrixxx[31] !== "0" ? (
                          this.state.Matrixzeit[31] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[31] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[5]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[31] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[31] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[5]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[31] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[5]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        {this.state.matrixxx[32] !== "0" ? (
                          this.state.Matrixzeit[32] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[32] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[5]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[32] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[32] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[5]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[32] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[5]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        {this.state.matrixxx[33] !== "0" ? (
                          this.state.Matrixzeit[33] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[33] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[5]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[33] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[33] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[5]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[33] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[5]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        {this.state.matrixxx[34] !== "0" ? (
                          this.state.Matrixzeit[34] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[34] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[5]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[34] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[34] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[5]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[34] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[5]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        <td>
                        &nbsp;&nbsp;
                        </td>
                        {this.state.matrixxx[35] !== "0" ? (
                          this.state.Matrixzeit[35] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[35] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[13]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[35] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[35] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[13]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[35] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[13]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        </tr>
                        <tr>
                        {this.state.matrixxx[36] !== "0" ? (
                          this.state.Matrixzeit[36] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[36] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[6]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[36] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[36] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[6]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[36] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[6]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        {this.state.matrixxx[37] !== "0" ? (
                          this.state.Matrixzeit[37] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[37] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[6]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[37] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[37] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[6]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[37] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[6]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        {this.state.matrixxx[38] !== "0" ? (
                          this.state.Matrixzeit[38] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[38] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[6]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[38] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[38] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[6]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[38] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[6]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        {this.state.matrixxx[39] !== "0" ? (
                          this.state.Matrixzeit[39] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[39] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[6]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[39] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[39] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[6]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[39] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[6]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        {this.state.matrixxx[40] !== "0" ? (
                          this.state.Matrixzeit[40] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[40] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[6]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[40] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[40] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[6]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[40] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[6]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        <td>
                        &nbsp;&nbsp;
                        </td>
                        {this.state.matrixxx[41] !== "0" ? (
                          this.state.Matrixzeit[41] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[41] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[14]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[41] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[41] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[14]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[41] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[14]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        </tr>
                        <tr>
                        {this.state.matrixxx[42] !== "0" ? (
                          this.state.Matrixzeit[42] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[42] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[7]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[42] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[42] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[7]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[42] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[7]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        {this.state.matrixxx[43] !== "0" ? (
                          this.state.Matrixzeit[43] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[43] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[7]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[43] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[43] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[7]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[43] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[7]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        {this.state.matrixxx[44] !== "0" ? (
                          this.state.Matrixzeit[44] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[44] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[7]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[44] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[44] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[7]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[44] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[7]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        {this.state.matrixxx[45] !== "0" ? (
                          this.state.Matrixzeit[45] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[45] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[7]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[45] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[45] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[7]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[45] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[7]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        {this.state.matrixxx[46] !== "0" ? (
                          this.state.Matrixzeit[46] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[46] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[7]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[46] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[46] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[7]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[46] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[7]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        <td>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </td>
                        {this.state.matrixxx[47] !== "0" ? (
                          this.state.Matrixzeit[47] === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            let Neu = this.state.Matrixzeit; Neu[47] = "1"; this.setState({Matrixzeit: Neu})}}>
                                {this.state.zeitxx[15]}
                            </td>
                        ) : (
                            this.state.Matrixzeit[47] === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[47] = "2"; this.setState({Matrixzeit: Neu})}}>
                                <div className="sternweiss">{this.state.zeitxx[15]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                                let Neu = this.state.Matrixzeit; Neu[47] = "X";
                                this.setState({Matrixzeit: Neu})}}>
                                <div className="sterngold">{this.state.zeitxx[15]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                        )) : (
                          <td></td>
                        )}
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
                <div>
                <br/>
                <textarea className="bemerkungen"
                    value={this.state.Bemerkungen}
                    placeholder="Bemerkungen/Wünsche"
                    onChange={(event) => { this.setState({ Bemerkungen: event.target.value })}}
                />
                </div>
                
                <div className="gross">
                  <input
                      type="checkbox"
                      checked={this.state.rememberMe}
                      onChange={(event) => { this.setState({ rememberMe: event.target.checked })}}
                  /> <small>Ich habe die{" "}
                      <a href="/datenschutzerklaerung" target="_blank">
                      Datenschutzerklärung von TennisGroupOrganizer
                      </a>{" "}
                      zur Kenntnis genommen und stimme zu, dass meine Angaben und
                      Daten zur Übermittlung elektronisch erhoben und gespeichert werden.<br/>
                      <span id="roteFarbe">{this.state.ErrorrememberMe}</span></small>
                </div>
                <div className="gross">
                  <input
                      type="checkbox"
                      checked={this.state.Anmeldebedingungen}
                      onChange={(event) => { this.setState({ Anmeldebedingungen: event.target.checked })}}
                  /> <small>Ich akzeptiere die Anmeldebedingungen des Vereins/ der Tennisschule
                    und melde hiermit {this.state.NameKind} verpflichtend zum Training an.<br/>
                      <span id="roteFarbe">{this.state.ErrorAnmeldebedingungen}</span></small>
                </div>
                <br/>
                <div>
                    <div>
                        <div className="normal">
                        <button onClick={this.handleSubmit}>
                            Absenden
                        </button>
                        </div>
                    </div>
                    <div id="roteFarbe">{this.state.Error}</div>
                </div>

            </div>
            </div>
        </div>
        );
      }
      else {
        if(this.state.Mitgliedsstatus === "Mitglied") {
          return(
            <div className="container">
              <p>
                {this.state.BemerkungenTrainer}
              </p>
              <br/>
              <br/>
              <br/>
              <br/>
              <div className="zurueckweiter">
                Wie finden Sie diese Form der Anmeldung zum Tennistraining?
              </div>
              <div className="zurueckweiter">
                <div>
                  {this.Bewertung()}
                </div>
              </div>
              <div className="zurueckweiter">
                <textarea className="bemerkungen"
                    value={this.state.Feedback}
                    placeholder="optional"
                    onChange={(event) => { this.setState({ Feedback: event.target.value })}}
                />
              </div>
              <div className="zurueckweiter">
                <div className="normal">
                  <button onClick={(event) => {
                    if(this.state.Sternanzahl > 0) {
                      this.Bewertungsenden()
                    }
                    }}>
                    Bewertung senden
                  </button>
                </div>
              </div>
            </div>
          );
        }
        else {
          return(
            <div className="container">
              <p>
                {this.state.BemerkungenTrainer}
              </p>
              <p>
                {this.state.BemerkungenTrainer2} <a href={this.state.BemerkungenTrainer3} rel="noopener noreferrer" target="_blank">{this.state.BemerkungenTrainer3}</a>
              </p>
              <br/>
              <br/>
              <br/>
              <br/>
              <div className="zurueckweiter">
                Wie finden Sie diese Form der Anmeldung zum Tennistraining?
              </div>
              <div className="zurueckweiter">
                <div>
                  {this.Bewertung()}
                </div>
              </div>
              <div className="zurueckweiter">
                <textarea className="bemerkungen"
                    value={this.state.Feedback}
                    placeholder="optional"
                    onChange={(event) => { this.setState({ Feedback: event.target.value })}}
                />
              </div>
              <div className="zurueckweiter">
                <div className="normal">
                  <button onClick={(event) => {
                    if(this.state.Sternanzahl > 0) {
                      this.Bewertungsenden()
                    }
                    }}>
                    Bewertung senden
                  </button>
                </div>
              </div>
            </div>
          );
        }
      }
    }
    else {
      return(
        <div className="container">
          <h3><p id="roteFarbe">
            <strong>{this.state.eilmeldung}</strong>
          </p></h3>
        </div>
      );
    }
  }

}