import React from "react";
import "./components.css";

export const UnderHeaderGruenderstory = () => (
  <div className="underheader container">
    <div>
      <h1>Gründerstory</h1>
      <p> Wie alles begann</p>
    </div>
  </div>
);
