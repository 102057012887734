import React from "react";
import "./App.css";
import { UnderHeaderBestaetigung } from "./components/UnderheaderBestaetigung";

export const Bestätigung = () => (
  <div>
    <UnderHeaderBestaetigung />
    <div className="container">
      <h2>Vielen Dank für Ihre Bestellung!</h2>
      <br/>
      <p>
        Die Zugangsdaten für das Programm erhalten Sie in Kürze per Mail.
      </p>
    </div>
  </div>
);
