import React from "react";
import "./App.css";
import { UnderHeaderLogin } from "./components/UnderHeaderLogin";
import axios from "axios";

export class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      errormessageusername: "",
      errormessagepassword: "",
      username: "",
      password: "",
      show: false,

      link: "",
      eilmeldung: "",
      sperren: "No",
    };
  }

  componentDidMount() {
    axios
      .get("/api/vorlogin")
      .then((res) => {
        const message = res.data;
        this.setState({ link: message[0], sperren: message[1] });
        console.log(message)
      })
      .catch((error) => {
        console.log(error.message);
      });

    axios
      .get("/api/Eilmeldung")
      .then((res) => {
        const message = res.data;
        this.setState({ 
          eilmeldung: message,
        });
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  Eilmeldung() {
    if(this.state.eilmeldung === " ") {
      return(
        <div></div>
      )
    }
    else {
      return(
        <p id="roteFarbe">
          <strong>{this.state.eilmeldung}</strong>
        </p>
      );
    }
  }

  handleChangeUsername = (event) => {
    this.setState({ username: event.target.value });
  };

  handleChangePassword = (event) => {
    this.setState({ password: event.target.value });
  };

  handleSubmit = (event) => {
    if (this.state.username === "" || this.state.username === null) {
      this.setState({ errormessageusername: "Geben Sie einen Benutzernamen ein!" });
    }
    else {
      this.setState({ errormessageusername: "" });
    }

    if (this.state.password === "" || this.state.password === null) {
      this.setState({ errormessagepassword: "Geben Sie ein Passwort ein!" });
    }
    else {
      this.setState({ errormessagepassword: "" });
    }

    const Abfrage = {
      username: this.state.username,
      password: this.state.password,
    };
    
    if(this.state.errormessageusername && this.state.errormessagepassword) {
      //Nichts
    }
    else {

      const postrequest = "".concat("/api/Login/", this.state.link);
      axios.post(postrequest, Abfrage, {timeout: 0 }).then(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );

      var getlink = "".concat("/api/Login/", this.state.link);
      axios
        .get(getlink)
        .then((res) => {

          if(res.data === "Yes") {
            const Zugangskontrolle = {
              username: this.state.username,
            }
            const postrequest2 = "".concat("/api/Zugangskontrolle/", this.state.link);
            axios.post(postrequest2, Zugangskontrolle, {timeout: 0 }).then(
              (response) => {
                console.log(response);
              },
              (error) => {
                console.log(error);
              }
            );
            
            const postrequest3 = "".concat("/api/Abmelden24h/", this.state.link);

            axios.post(postrequest3, {timeout: 0})
                .then(
                (response) => {
                  console.log(response);
                },
                (error) => {
                  console.log(error);
                }
            )

            const abschluss = "".concat("/login=true/", this.state.link);
            document.location.href=abschluss;
          }
          else {
            this.setState({ errormessagepassword: "Falscher Benutzername oder falsches Passwort!" });
          }

        })
        .catch((error) => {
          console.log(error.message);
        });
    }

  };

  handleSubmitSperre = (event) => {
    if(this.state.username === "N&Padmin") {
      const Abfrage = {
        username: this.state.username,
        password: this.state.password,
      };
      
      const postrequest = "".concat("/api/Login/", this.state.link);
      axios.post(postrequest, Abfrage, {timeout: 0 }).then(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );

      let weiter = "";


      var getlink = "".concat("/api/Login/", this.state.link);
      axios
        .get(getlink)
        .then((res) => {
          weiter = res.data;

          if(weiter === "Yes") {
            const Zugangskontrolle = {
              username: this.state.username,
            }
            const postrequest2 = "".concat("/api/Zugangskontrolle/", this.state.link);
            axios.post(postrequest2, Zugangskontrolle, {timeout: 0 }).then(
              (response) => {
                console.log(response);
              },
              (error) => {
                console.log(error);
              }
            );
            
            const postrequest3 = "".concat("/api/Abmelden24h/", this.state.link);

            axios.post(postrequest3, {timeout: 0})
                .then(
                (response) => {
                  console.log(response);
                },
                (error) => {
                  console.log(error);
                }
            )

            const abschluss = "".concat("/login=true/", this.state.link);
            document.location.href=abschluss;
          }
          else {
            this.setState({ errormessagepassword: "Falscher Benutzername oder falsches Passwort!" });
          }

        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  }

  render() {
    if(window.screen.availWidth>580) {
      if(this.state.show === false) {
        if(this.state.sperren === "No") {
          return (
            <div>
              <UnderHeaderLogin />
                <div className="container">
                  <h3>
                    {this.Eilmeldung()}
                  </h3>

                  <div className="col">
                    <h3>&nbsp;&nbsp;&nbsp;Beutzername:</h3>
                    <div className="innercol">
                      <input
                        type="text"
                        value={this.state.username}
                        onChange={this.handleChangeUsername}
                      ></input>
                    </div>
                    <p id="roteFarbe">
                      {this.state.errormessageusername}
                    </p>
                  </div>
                  &nbsp;
                  <div className="col">
                    <h3>&nbsp;&nbsp;&nbsp;Passwort:</h3>
                    <div className="innercol">
                      <input
                        type="password"
                        value={this.state.password}
                        onChange={this.handleChangePassword}
                      ></input>
                      <span className="strich">&#47;</span>
                      <span className="auge">
                        <button onClick={(event) => {this.setState({show: true})}}>
                          &#128065;
                        </button>
                      </span>
                    </div>
                    <p id="roteFarbe">
                      {this.state.errormessagepassword}
                    </p>
                  </div>
                  <br/>
                  <p className="normal">
                    &nbsp;&nbsp;&nbsp;
                    <button onClick={this.handleSubmit}>
                        Anmelden
                    </button>
                  </p>
                </div>
            </div>
          );
        }
        else {
          return (
            <div>
              <UnderHeaderLogin />
              <div className="container">
                <h3>
                  {this.Eilmeldung()}
                </h3>

                <div className="col">
                  <h3>&nbsp;&nbsp;&nbsp;Beutzername:</h3>
                  <div className="innercol">
                    <input
                      type="text"
                      value={this.state.username}
                      onChange={this.handleChangeUsername}
                    ></input>
                  </div>
                  <p id="roteFarbe">
                    {this.state.errormessageusername}
                  </p>
                </div>
                &nbsp;
                <div className="col">
                  <h3>&nbsp;&nbsp;&nbsp;Passwort:</h3>
                  <div className="innercol">
                    <input
                      type="password"
                      value={this.state.password}
                      onChange={this.handleChangePassword}
                    ></input>
                    <span className="strich">&#47;</span>
                    <span className="auge">
                      <button onClick={(event) => {this.setState({show: true})}}>
                        &#128065;
                      </button>
                    </span>
                  </div>
                  <p id="roteFarbe">
                    {this.state.errormessagepassword}
                  </p>

                  <br/>

                  <p className="normal">
                    &nbsp;&nbsp;&nbsp;
                    <button onClick={this.handleSubmitSperre}>
                        Anmelden
                    </button>
                  </p>
                  <p id="roteFarbe">
                    &nbsp;&nbsp;&nbsp;Aktuell nicht möglich!
                  </p>
                </div>
              </div>
            </div>
          );
        }
      }
      else {
        if(this.state.sperren === "No") {
          return (
            <div>
              <UnderHeaderLogin />
                <div className="container">
                  <h3>
                    {this.Eilmeldung()}
                  </h3>

                  <div className="col">
                    <h3>&nbsp;&nbsp;&nbsp;Beutzername:</h3>
                    <div className="innercol">
                      <input
                        type="text"
                        value={this.state.username}
                        onChange={this.handleChangeUsername}
                      ></input>
                    </div>
                    <p id="roteFarbe">
                      {this.state.errormessageusername}
                    </p>
                  </div>
                  &nbsp;
                  <div className="col">
                    <h3>&nbsp;&nbsp;&nbsp;Passwort:</h3>
                    <div className="innercol">
                      <input
                        type="text"
                        value={this.state.password}
                        onChange={this.handleChangePassword}
                      ></input>
                      <span className="auge">
                        <button onClick={(event) => {this.setState({show: false})}}>
                          &#128065;
                        </button>
                      </span>
                    </div>
                    <p id="roteFarbe">
                      {this.state.errormessagepassword}
                    </p>
                  </div>
                  <br/>
                  <p className="normal">
                    &nbsp;&nbsp;&nbsp;
                    <button onClick={this.handleSubmit}>
                        Anmelden
                    </button>
                  </p>
                </div>
            </div>
          );
        }
        else {
          return (
            <div>
              <UnderHeaderLogin />
              <div className="container">
                <h3>
                  {this.Eilmeldung()}
                </h3>

                <div className="col">
                  <h3>&nbsp;&nbsp;&nbsp;Beutzername:</h3>
                  <div className="innercol">
                    <input
                      type="text"
                      value={this.state.username}
                      onChange={this.handleChangeUsername}
                    ></input>
                  </div>
                  <p id="roteFarbe">
                    {this.state.errormessageusername}
                  </p>
                </div>
                &nbsp;
                <div className="col">
                  <h3>&nbsp;&nbsp;&nbsp;Passwort:</h3>
                  <div className="innercol">
                    <input
                      type="text"
                      value={this.state.password}
                      onChange={this.handleChangePassword}
                    ></input>
                    <span className="auge">
                      <button onClick={(event) => {this.setState({show: false})}}>
                        &#128065;
                      </button>
                    </span>
                  </div>
                  <p id="roteFarbe">
                    {this.state.errormessagepassword}
                  </p>

                  <br/>

                  <p className="normal">
                    &nbsp;&nbsp;&nbsp;
                    <button onClick={this.handleSubmitSperre}>
                        Anmelden
                    </button>
                  </p>
                  <p id="roteFarbe">
                    &nbsp;&nbsp;&nbsp;Aktuell nicht möglich!
                  </p>
                </div>
              </div>
            </div>
          );
        }
      }
    }
    else {
      if(this.state.show === false) {
        if(this.state.sperren === "No") {
          return (
            <div>
              <UnderHeaderLogin />
                <div className="container">
                  <h3>
                    {this.Eilmeldung()}
                  </h3>

                  <div className="col">
                    <h3>&nbsp;&nbsp;&nbsp;Beutzername:</h3>
                    <div className="innercol">
                    &nbsp;&nbsp;&nbsp;<input
                        type="text"
                        value={this.state.username}
                        onChange={this.handleChangeUsername}
                      ></input>
                    </div>
                    <p id="roteFarbe">
                      {this.state.errormessageusername}
                    </p>
                  </div>
                  &nbsp;
                  <div className="col">
                    <h3>&nbsp;&nbsp;&nbsp;Passwort:</h3>
                    <div className="innercol">
                    &nbsp;&nbsp;&nbsp;<input
                        type="password"
                        value={this.state.password}
                        onChange={this.handleChangePassword}
                      ></input>
                      <span className="strich">&#47;</span>
                      <span className="auge">
                        <button onClick={(event) => {this.setState({show: true})}}>
                          &#128065;
                        </button>
                      </span>
                    </div>
                    <p id="roteFarbe">
                      {this.state.errormessagepassword}
                    </p>
                  </div>
                  <br/>
                  <p className="normal">
                    &nbsp;&nbsp;&nbsp;
                    <button onClick={this.handleSubmit}>
                        Anmelden
                    </button>
                  </p>
                </div>
            </div>
          );
        }
        else {
          return (
            <div>
              <UnderHeaderLogin />
              <div className="container">
                <h3>
                  {this.Eilmeldung()}
                </h3>

                <div className="col">
                  <h3>&nbsp;&nbsp;&nbsp;Beutzername:</h3>
                  <div className="innercol">
                  &nbsp;&nbsp;&nbsp;<input
                      type="text"
                      value={this.state.username}
                      onChange={this.handleChangeUsername}
                    ></input>
                  </div>
                  <p id="roteFarbe">
                    {this.state.errormessageusername}
                  </p>
                </div>
                &nbsp;
                <div className="col">
                  <h3>&nbsp;&nbsp;&nbsp;Passwort:</h3>
                  <div className="innercol">
                  &nbsp;&nbsp;&nbsp;<input
                      type="password"
                      value={this.state.password}
                      onChange={this.handleChangePassword}
                    ></input>
                    <span className="strich">&#47;</span>
                    <span className="auge">
                      <button onClick={(event) => {this.setState({show: true})}}>
                        &#128065;
                      </button>
                    </span>
                  </div>
                  <p id="roteFarbe">
                    {this.state.errormessagepassword}
                  </p>

                  <br/>

                  <p className="normal">
                    &nbsp;&nbsp;&nbsp;
                    <button onClick={this.handleSubmitSperre}>
                        Anmelden
                    </button>
                  </p>
                  <p id="roteFarbe">
                    &nbsp;&nbsp;&nbsp;Aktuell nicht möglich!
                  </p>
                </div>
              </div>
            </div>
          );
        }
      }
      else {
        if(this.state.sperren === "No") {
          return (
            <div>
              <UnderHeaderLogin />
                <div className="container">
                  <h3>
                    {this.Eilmeldung()}
                  </h3>

                  <div className="col">
                    <h3>&nbsp;&nbsp;&nbsp;Beutzername:</h3>
                    <div className="innercol">
                    &nbsp;&nbsp;&nbsp;<input
                        type="text"
                        value={this.state.username}
                        onChange={this.handleChangeUsername}
                      ></input>
                    </div>
                    <p id="roteFarbe">
                      {this.state.errormessageusername}
                    </p>
                  </div>
                  &nbsp;
                  <div className="col">
                    <h3>&nbsp;&nbsp;&nbsp;Passwort:</h3>
                    <div className="innercol">
                    &nbsp;&nbsp;&nbsp;<input
                        type="text"
                        value={this.state.password}
                        onChange={this.handleChangePassword}
                      ></input>
                      <span className="auge">
                        <button onClick={(event) => {this.setState({show: false})}}>
                          &#128065;
                        </button>
                      </span>
                    </div>
                    <p id="roteFarbe">
                      {this.state.errormessagepassword}
                    </p>
                  </div>
                  <br/>
                  <p className="normal">
                    &nbsp;&nbsp;&nbsp;
                    <button onClick={this.handleSubmit}>
                        Anmelden
                    </button>
                  </p>
                </div>
            </div>
          );
        }
        else {
          return (
            <div>
              <UnderHeaderLogin />
              <div className="container">
                <h3>
                  {this.Eilmeldung()}
                </h3>

                <div className="col">
                  <h3>&nbsp;&nbsp;&nbsp;Beutzername:</h3>
                  <div className="innercol">
                  &nbsp;&nbsp;&nbsp;<input
                      type="text"
                      value={this.state.username}
                      onChange={this.handleChangeUsername}
                    ></input>
                  </div>
                  <p id="roteFarbe">
                    {this.state.errormessageusername}
                  </p>
                </div>
                &nbsp;
                <div className="col">
                  <h3>&nbsp;&nbsp;&nbsp;Passwort:</h3>
                  <div className="innercol">
                  &nbsp;&nbsp;&nbsp;<input
                      type="text"
                      value={this.state.password}
                      onChange={this.handleChangePassword}
                    ></input>
                    <span className="auge">
                      <button onClick={(event) => {this.setState({show: false})}}>
                        &#128065;
                      </button>
                    </span>
                  </div>
                  <p id="roteFarbe">
                    {this.state.errormessagepassword}
                  </p>

                  <br/>

                  <p className="normal">
                    &nbsp;&nbsp;&nbsp;
                    <button onClick={this.handleSubmitSperre}>
                        Anmelden
                    </button>
                  </p>
                  <p id="roteFarbe">
                    &nbsp;&nbsp;&nbsp;Aktuell nicht möglich!
                  </p>
                </div>
              </div>
            </div>
          );
        }
      }
    }
  }
}
