import React from "react";
import { UnderHeaderDemoStundenplan } from "./UnderHeaderDemoStundenplan";
import "./demo.css";
import axios from "axios";

export class DemoStundenplan extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      message: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    var local = window.location.pathname;
    var output = "";
    if (local.length === 18) {
      output = local.slice(-1);
    }
    if (local.length === 19) {
      output = local.slice(-2);
    }
    if (local.length === 20) {
      output = local.slice(-3);
    }
    var getlink = "".concat("/api/demo1/", output);
    axios
      .get(getlink)
      .then((res) => {
        const message = res.data;
        this.setState({ message });
      })
      .catch((error) => {
        console.log(error.message);
      });

    this.setState({ loading: false });
  }

  Missing(text) {
    if (text === "") {
      return "Es konnten alle Tennisschüler*innen zugeordnet werden.";
    } else {
      return "Folgende Tennisschüler*innen konnten nicht zugeordnet werden:";
    }
  }

  render() {
    const zeile1 = this.state.message[0];
    const zeile2 = this.state.message[1];
    const zeile3 = this.state.message[2];
    const zeile4 = this.state.message[3];
    const zeile5 = this.state.message[4];
    const zeile6 = this.state.message[5];
    const zeile7 = this.state.message[6];
    const zeile8 = this.state.message[7];
    const zeile9 = this.state.message[8];

    if (this.state.loading || this.state.message.length === 0) {
      return (
        <div>
          <UnderHeaderDemoStundenplan />
          <div className="container zurueckweiter">
            Bitte gedulden Sie sich einige Sekunden.
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <UnderHeaderDemoStundenplan />
          <div className="container">
            <p id="roteFarbeDemo">
              In der Vollversion erhalten Sie Ihren Stundenplan als
              Excel-Datei per Mail.
            </p>
            <div className="stundenplan">
              <table>
                <tbody>
                  <tr>
                    <th>
                      <strong>Zeit</strong>
                    </th>
                    <th>
                      <strong>Montag</strong>
                    </th>
                    <th>
                      <strong>Dienstag</strong>
                    </th>
                    <th>
                      <strong>Mittwoch</strong>
                    </th>
                    <th>
                      <strong>Donnerstag</strong>
                    </th>
                    <th>
                      <strong>Freitag</strong>
                    </th>
                    <th>
                      <strong>Zeit</strong>
                    </th>
                    <th>
                      <strong>Samstag</strong>
                    </th>
                  </tr>
                  <tr>
                    {zeile1.map((item, i) => (
                      <td key={i}>{item}</td>
                    ))}
                  </tr>
                  <tr>
                    {zeile2.map((item, i) => (
                      <td key={i}>{item}</td>
                    ))}
                  </tr>
                  <tr>
                    {zeile3.map((item, i) => (
                      <td key={i}>{item}</td>
                    ))}
                  </tr>
                  <tr>
                    {zeile4.map((item, i) => (
                      <td key={i}>{item}</td>
                    ))}
                  </tr>
                  <tr>
                    {zeile5.map((item, i) => (
                      <td key={i}>{item}</td>
                    ))}
                  </tr>
                  <tr>
                    {zeile6.map((item, i) => (
                      <td key={i}>{item}</td>
                    ))}
                  </tr>
                  <tr>
                    {zeile7.map((item, i) => (
                      <td key={i}>{item}</td>
                    ))}
                  </tr>
                  <tr>
                    {zeile8.map((item, i) => (
                      <td key={i}>{item}</td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            <div>{this.Missing(zeile9[0])}</div>
            <br />
            <div>
              {zeile9.map((item, i) => (
                <div key={i}>{item}</div>
              ))}
            </div>
          </div>
        </div>
      );
    }
  }
}
