import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

const selectElement = (element) => document.querySelector(element);
if (window.location.pathname !== "/demoerklaervideo") {
  selectElement(".menu-icons").addEventListener("click", () => {
    selectElement("nav").classList.toggle("active");
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
