import React from "react";
import "./Navigation.css";

export default class Navigation extends React.Component {
  render() {
    return (
      <header>
        <div className="containerheader">
          
          <nav>
            <button onClick={(event) => {window.location.href="/"}}>
            </button>
            <div className="menu-icons">
              <div className="menu">&#8801;</div>
              <div className="close">&#10005;</div>
            </div>

            <ul className="nav-list">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <li>
                <a href="/">
                  Home
                </a>
              </li>
              <li>
                <a href="/produkte">
                  Produkte
                </a>
              </li>
              <li>
                <a href="/login">
                  Login
                </a>
              </li>
              <li>
                <a href="/demo">
                  Demo
                </a>
              </li>
              <li>
                <a href="/kontakt">
                  Kontakt
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    );
  }
}
