import React from "react";
import { UnderHeaderHauptprogramm } from "./UnderHeaderHauptprogramm";
import axios from "axios";


export class HauptprogrammAbschluss extends React.Component {
    constructor() {
        super();
        this.state = {
            naechsteSeite: false,
            Stundenplanberechnenlassen: "No",
        };
    }
    handleSubmitAbschluss = (event) => {
        var local = window.location.pathname;
        var output = "";
        if (local.length === 19) {
        output = local.slice(-1);
        }
        if (local.length === 20) {
        output = local.slice(-2);
        }
        if (local.length === 21) {
        output = local.slice(-3);
        }
        if (local.length === 22) {
        output = local.slice(-4);
        }
        if (local.length === 23) {
        output = local.slice(-5);
        }
        if (local.length === 24) {
        output = local.slice(-6);
        }
        if (local.length === 25) {
        output = local.slice(-7);
        }
        if (local.length === 26) {
        output = local.slice(-8);
        }

        var getlink = "".concat("/api/Stundenplanberechnenlassen/", output);
        axios
            .get(getlink)
            .then((res) => {
                this.setState({ Stundenplanberechnenlassen: res.data });
            })
            .catch((error) => {
                console.log(error.res.data);
            });
        
        this.setState({ naechsteSeite: true, })
    };
    
    render() {
        if(this.state.naechsteSeite === true) {
            if(this.state.Stundenplanberechnenlassen === "No") {
                return(
                    <div className="container">
                        Sie Sind nicht berechtigt!
                    </div>
                );
            }
            else {
                return(
                    <div className="container">
                        <p>
                            Die Stundenplanberechnung wurde erfolgreich gestartet.
                            Sie erhalten eine Mail mit Ihrem Stundenplan als Excel-Datei,
                            sobald dieser berechnet wurde.
                        </p>
                        <p>
                            Sollte dies unerwarteter Weise mehrere Tage dauern, so kontaktieren
                            Sie uns bitte.
                        </p>
                        <div className="normal">
                            <button
                            onClick={(event) => {
                                var local = window.location.pathname;
                                var output = "";
                                if (local.length === 19) {
                                output = local.slice(-1);
                                }
                                if (local.length === 20) {
                                output = local.slice(-2);
                                }
                                if (local.length === 21) {
                                output = local.slice(-3);
                                }
                                if (local.length === 22) {
                                output = local.slice(-4);
                                }
                                if (local.length === 23) {
                                output = local.slice(-5);
                                }
                                if (local.length === 24) {
                                output = local.slice(-6);
                                }
                                if (local.length === 25) {
                                output = local.slice(-7);
                                }
                                if (local.length === 26) {
                                output = local.slice(-8);
                                }
                                var link = "".concat("/login=true/", output);
                                document.location.href=link;
                            }}>Menü
                            </button>
                        </div>
                    </div>
                );
            }
        }
        else {
            return (
                <div>
                    <UnderHeaderHauptprogramm />
                    <div className="container topfauswahl">
                        <p className="zurueckweiter">
                        Stundenplan berechnen:
                        </p>
                        <div className="zurueckweiter">
                            <div className="normal">
                                <button onClick={this.handleSubmitAbschluss}>
                                    Los
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

}