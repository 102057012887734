import React from "react";
import { UnderHeaderImpressum } from "./UnderHeaderImpressum";

/* Umsatzsteuer:
      <h2>Umsatzsteuer-ID</h2>
      <p>
        Umsatzsteuer-Identifikations&shy;nummer gemäß § 27 a
        Umsatzsteuer&shy;gesetz:
        <br />
        DE 999 999 999
      </p>
*/

export const Impressum = () => (
  <div>
    <UnderHeaderImpressum />
    <div className="container">
      <h3>Angaben gemäß § 5 TMG</h3>
      <p>
        TennisGroupOrganizer - Philipp Frohn und Niklas Wienand GbR
        <br />
        Nibelungenstraße 20
        <br />
        50354 Hürth
      </p>

      <p>
        Kontakt:
        <br/>
        0176 47913345
        <br />
        <a href="mailto:info@tennisgrouporganizer.com">
          info@tennisgrouporganizer.com
        </a>
      </p>
      
      <p>
        Vertretungsberechtigte Geschäftsführer: Philipp Frohn und Niklas Wienand
        <br/>
        USt.-IdNr.: DE328949139
      </p>

      <br/>
      <br/>
      
      <h3>EU-Streit&shy;schlichtung</h3>
      <p>
        Die Europäische Kommission stellt eine Plattform zur
        Online-Streitbeilegung (OS) bereit:{" "}
        <a
          href="https://ec.europa.eu/consumers/odr"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://ec.europa.eu/consumers/odr
        </a>
        . Unsere E-Mail-Adresse finden Sie oben.
      </p>
      
      <br/>
      <br/>
      
      <h3>
        Verbraucher&shy;streitbeilegung / Universal&shy;schlichtungs&shy;stelle
      </h3>
      <p>
        Wir sind nicht bereit oder verpflichtet, an
        Streit&shy;beilegungs&shy;verfahren vor einer
        Verbraucher&shy;schlichtungs&shy;stelle teilzunehmen.
      </p>
    </div>
  </div>
);
