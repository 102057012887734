import React from "react";
import "./App.css";
import { UnderHeaderVereine } from "./components/UnderHeaderVereine";
import axios from "axios";

export class AnmeldungCamp extends React.Component {
  constructor() {
    super();
    this.state = {
      Auswahl: "",
      NameEltern: "",
      NameKind: "",
      Geschlecht: "Geschlecht",
      Alter: "",
      Jahr: null,
      Monat: null,
      Tag: null,
      TShirtGroesse: "Größe",
      SchwimmenAngabe: "Fähigkeit",
      RadAngabe: "Fahrrad",
      Mitgliedsstatus: "--- bitte auswählen ---",
      email: "",
      nummer: "",
      rememberMe: false,
      ErrorAnmeldebedingungen: false,

      Bemerkungen: "",
      Abgeschickt: 0,
      
      eilmeldung: "",
      sperren: "Yes",

      AnzahlCamps: "0",
      Camp1: "",
      Camp2: "",
      Camp3: "",
      Camp4: "",
      Camp5: "",
      Camp6: "",
      Camp7: "",
      Camp8: "",
      Camp9: "",
      Camp10: "",
      TShirt: "Nein",
      Schwimmen: "Nein",
      Rad: "Nein",
      BemerkungenTrainer: "",
      BemerkungenTrainer2: "",
      BemerkungenTrainer3: "",
      Anmeldebedingungen: false,

      Camp1Checkbox: false,
      Camp2Checkbox: false,
      Camp3Checkbox: false,
      Camp4Checkbox: false,
      Camp5Checkbox: false,
      Camp6Checkbox: false,
      Camp7Checkbox: false,
      Camp8Checkbox: false,
      Camp9Checkbox: false,
      Camp10Checkbox: false,

      Sternanzahl: 0,
      Feedback: "",
      WurdeSchonGeschickt: false,
      Bestaetigungsmail: false,
    };
  }

  componentDidMount() {
    axios
    .get("/api/Vereine")
    .then((res) => {
      const message1 = res.data;
      this.setState({ sperren: message1[0] });
    })
    .catch((error) => {
      console.log(error.message1);
    });

    axios
    .get("/api/Eilmeldung")
    .then((res) => {
      const message1 = res.data;
      this.setState({ 
        eilmeldung: message1,
      });
    })
    .catch((error) => {
      console.log(error.message1);
    });

    let url = window.location.pathname
    let laenge = url.length - 1;
    let Vereinsname = url.slice(-laenge)
    while(Vereinsname.includes("_")) {
        let Teil1 = Vereinsname.slice(0, Vereinsname.indexOf("_"))
        let Teil2 = Vereinsname.slice(Vereinsname.indexOf("_") + 1, laenge)
        Vereinsname = Teil1 + " " + Teil2
    }
    this.setState({Auswahl: Vereinsname})
    
    var getlink4 = "".concat("/api/UhrzeitenCamp/", url.slice(-laenge).slice(0,url.slice(-laenge).length - 5));
    axios
    .get(getlink4)
    .then((res) => {
        const message = res.data;
        this.setState({ AnzahlCamps: message[0],
          Camp1: message[1], Camp2: message[2], Camp3: message[3], Camp4: message[4],
          Camp5: message[5], Camp6: message[6], Camp7: message[7], Camp8: message[8],
          Camp9: message[9], Camp10: message[10], TShirt: message[11], Schwimmen: message[12],
          Rad: message[13], BemerkungenTrainer: message[14], BemerkungenTrainer2: message[15],
          BemerkungenTrainer3: message[16]});
    })
    .catch((error) => {
        console.log(error.message);
    });
  }

  Bewertung() {
    if(this.state.Sternanzahl === 0) {
      return(
        <div>
          <span className="sterne">
            <button onClick={(event) => {this.setState({Sternanzahl: 1})}}>
              &#9734;
            </button>
          </span>
          <span className="sterne">
            <button onClick={(event) => {this.setState({Sternanzahl: 2})}}>
              &#9734;
            </button>
          </span>
          <span className="sterne">
            <button onClick={(event) => {this.setState({Sternanzahl: 3})}}>
              &#9734;
            </button>
          </span>
          <span className="sterne">
            <button onClick={(event) => {this.setState({Sternanzahl: 4})}}>
              &#9734;
            </button>
          </span>
          <span className="sterne">
            <button onClick={(event) => {this.setState({Sternanzahl: 5})}}>
              &#9734;
            </button>
          </span>
        </div>
      );
    }
    else {
      if(this.state.Sternanzahl === 1) {
        return(
          <div>
            <span className="gold">
              <button onClick={(event) => {this.setState({Sternanzahl: 1})}}>
                &#9733;
              </button>
            </span>
            <span className="sterne">
              <button onClick={(event) => {this.setState({Sternanzahl: 2})}}>
                &#9734;
              </button>
            </span>
            <span className="sterne">
              <button onClick={(event) => {this.setState({Sternanzahl: 3})}}>
                &#9734;
              </button>
            </span>
            <span className="sterne">
              <button onClick={(event) => {this.setState({Sternanzahl: 4})}}>
                &#9734;
              </button>
            </span>
            <span className="sterne">
              <button onClick={(event) => {this.setState({Sternanzahl: 5})}}>
                &#9734;
              </button>
            </span>
          </div>
        );
      }
      else {
        if(this.state.Sternanzahl === 2) {
          return(
            <div>
              <span className="gold">
                <button onClick={(event) => {this.setState({Sternanzahl: 1})}}>
                  &#9733;
                </button>
              </span>
              <span className="gold">
                <button onClick={(event) => {this.setState({Sternanzahl: 2})}}>
                  &#9733;
                </button>
              </span>
              <span className="sterne">
                <button onClick={(event) => {this.setState({Sternanzahl: 3})}}>
                  &#9734;
                </button>
              </span>
              <span className="sterne">
                <button onClick={(event) => {this.setState({Sternanzahl: 4})}}>
                  &#9734;
                </button>
              </span>
              <span className="sterne">
                <button onClick={(event) => {this.setState({Sternanzahl: 5})}}>
                  &#9734;
                </button>
              </span>
            </div>
          );
        }
        else {
          if(this.state.Sternanzahl === 3) {
            return(
              <div>
                <span className="gold">
                  <button onClick={(event) => {this.setState({Sternanzahl: 1})}}>
                    &#9733;
                  </button>
                </span>
                <span className="gold">
                  <button onClick={(event) => {this.setState({Sternanzahl: 2})}}>
                    &#9733;
                  </button>
                </span>
                <span className="gold">
                  <button onClick={(event) => {this.setState({Sternanzahl: 3})}}>
                    &#9733;
                  </button>
                </span>
                <span className="sterne">
                  <button onClick={(event) => {this.setState({Sternanzahl: 4})}}>
                    &#9734;
                  </button>
                </span>
                <span className="sterne">
                  <button onClick={(event) => {this.setState({Sternanzahl: 5})}}>
                    &#9734;
                  </button>
                </span>
              </div>
            );
          }
          else {
            if(this.state.Sternanzahl === 4) {
              return(
                <div>
                  <span className="gold">
                    <button onClick={(event) => {this.setState({Sternanzahl: 1})}}>
                      &#9733;
                    </button>
                  </span>
                  <span className="gold">
                    <button onClick={(event) => {this.setState({Sternanzahl: 2})}}>
                      &#9733;
                    </button>
                  </span>
                  <span className="gold">
                    <button onClick={(event) => {this.setState({Sternanzahl: 3})}}>
                      &#9733;
                    </button>
                  </span>
                  <span className="gold">
                    <button onClick={(event) => {this.setState({Sternanzahl: 4})}}>
                      &#9733;
                    </button>
                  </span>
                  <span className="sterne">
                    <button onClick={(event) => {this.setState({Sternanzahl: 5})}}>
                      &#9734;
                    </button>
                  </span>
                </div>
              );
            }
            else {
              if(this.state.Sternanzahl === 5) {
                return(
                  <div>
                    <span className="gold">
                      <button onClick={(event) => {this.setState({Sternanzahl: 1})}}>
                        &#9733;
                      </button>
                    </span>
                    <span className="gold">
                      <button onClick={(event) => {this.setState({Sternanzahl: 2})}}>
                        &#9733;
                      </button>
                    </span>
                    <span className="gold">
                      <button onClick={(event) => {this.setState({Sternanzahl: 3})}}>
                        &#9733;
                      </button>
                    </span>
                    <span className="gold">
                      <button onClick={(event) => {this.setState({Sternanzahl: 4})}}>
                        &#9733;
                      </button>
                    </span>
                    <span className="gold">
                      <button onClick={(event) => {this.setState({Sternanzahl: 5})}}>
                        &#9733;
                      </button>
                    </span>
                  </div>
                );
              }
            }
          }
        }
      }
    }
  }

  Bewertungsenden() {
    if(this.state.WurdeSchonGeschickt === false) {
      const Bewertung = {
        anzahlsterne: this.state.Sternanzahl,
        feedback: this.state.Feedback,
        auswahl: this.state.Auswahl
      };

      axios.post("/api/Bewertung", Bewertung).then(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );

      this.setState({Sternanzahl: 0, Feedback: "Vielen Dank für Ihre Bewertung.", WurdeSchonGeschickt: true})
    }
    else {
      this.setState({Feedback: "Sie haben Ihre Bewertung bereits abgeschickt."})
    }
  }

  validate = () => {
    let ErrorNameEltern= "";
    let ErrorNameKind= "";
    let ErrorGeschlecht= "";
    let ErrorAlter= "";
    let ErrorTShirt= "";
    let ErrorSchwimmen= "";
    let ErrorRad= "";
    let ErrorMitgliedsstatus= "";
    let Erroremail= "";
    let Errornummer= "";
    let ErrorrememberMe = "";
    let ErrorAnmeldebedingungen = "";
    let Error = "";

    if (!this.state.NameEltern) {
      ErrorNameEltern = "Falls " + this.state.NameKind + " volljährig ist, schreiben Sie bitte \"-\" in das Feld";
      Error = "Es fehlen noch Angaben.";
    }
    if (!this.state.NameKind) {
      ErrorNameKind = "fehlt ";
      Error = "Es fehlen noch Angaben.";
    }
    if (this.state.Geschlecht === "Geschlecht") {
      ErrorGeschlecht = "fehlt";
      Error = "Es fehlen noch Angaben.";
    }
    if (!this.state.Alter) {
      ErrorAlter = "fehlt ";
      Error = "Es fehlen noch Angaben.";
    }
    if (this.state.TShirt === "Ja" && this.state.TShirtGroesse === "Größe") {
      ErrorTShirt = "fehlt";
      Error = "Es fehlen noch Angaben.";
    }
    if (this.state.Schwimmen === "Ja" && this.state.SchwimmenAngabe === "Fähigkeit") {
      ErrorSchwimmen = "fehlt";
      Error = "Es fehlen noch Angaben.";
    }
    if (this.state.Rad === "Ja" && this.state.RadAngabe === "Fahrrad") {
      ErrorRad = "fehlt";
      Error = "Es fehlen noch Angaben.";
    }
    if (this.state.Mitgliedsstatus === "--- bitte auswählen ---") {
      ErrorMitgliedsstatus = "fehlt ";
      Error = "Es fehlen noch Angaben.";
    }
    if (!this.state.email) {
      Erroremail = "fehlt ";
      Error = "Es fehlen noch Angaben.";
    }
    if (!this.state.nummer) {
      Errornummer = "fehlt ";
      Error = "Es fehlen noch Angaben.";
    }
    if (!this.state.rememberMe) {
      ErrorrememberMe = "fehlt";
      Error = "Es fehlen noch Angaben.";
    }
    if (!this.state.Anmeldebedingungen) {
      ErrorAnmeldebedingungen = "fehlt";
      Error = "Es fehlen noch Angaben.";
    }
    if(this.state.Camp1Checkbox === false && this.state.Camp2Checkbox === false && this.state.Camp3Checkbox === false && this.state.Camp4Checkbox === false && this.state.Camp5Checkbox === false && this.state.Camp6Checkbox === false && this.state.Camp7Checkbox === false && this.state.Camp8Checkbox === false && this.state.Camp9Checkbox === false && this.state.Camp10Checkbox === false) {
      Error = "Sie müssen mindestens ein Camp auswählen."
    }
    if (Error) {
      this.setState({
        ErrorNameEltern,
        ErrorNameKind,
        ErrorGeschlecht,
        ErrorAlter,
        ErrorTShirt,
        ErrorSchwimmen,
        ErrorRad,
        ErrorMitgliedsstatus,
        Erroremail,
        Errornummer,
        ErrorrememberMe,
        ErrorAnmeldebedingungen,
        Error,
      });
      return false;
    }

    return true;
  };

  handleSubmit = (event) => {
    const isValid = this.validate();
    if (isValid) {
      let Mailschicken;
      if(this.state.Bestaetigungsmail === false) {
        Mailschicken = "Nein";
      }
      else {
        Mailschicken = "Ja";
      }

      let Camps = [];
      if(this.state.Camp1Checkbox === true) {
        Camps[0] = "Ja"
      }
      else{
        Camps[0] = "Nein"
      }
      if(this.state.Camp2Checkbox === true) {
        Camps[1] = "Ja"
      }
      else{
        Camps[1] = "Nein"
      }
      if(this.state.Camp3Checkbox === true) {
        Camps[2] = "Ja"
      }
      else{
        Camps[2] = "Nein"
      }
      if(this.state.Camp4Checkbox === true) {
        Camps[3] = "Ja"
      }
      else{
        Camps[3] = "Nein"
      }
      if(this.state.Camp5Checkbox === true) {
        Camps[4] = "Ja"
      }
      else{
        Camps[4] = "Nein"
      }
      if(this.state.Camp6Checkbox === true) {
        Camps[5] = "Ja"
      }
      else{
        Camps[5] = "Nein"
      }
      if(this.state.Camp7Checkbox === true) {
        Camps[6] = "Ja"
      }
      else{
        Camps[6] = "Nein"
      }
      if(this.state.Camp8Checkbox === true) {
        Camps[7] = "Ja"
      }
      else{
        Camps[7] = "Nein"
      }
      if(this.state.Camp9Checkbox === true) {
        Camps[8] = "Ja"
      }
      else{
        Camps[8] = "Nein"
      }
      if(this.state.Camp10Checkbox === true) {
        Camps[9] = "Ja"
      }
      else{
        Camps[9] = "Nein"
      }

      let tshirtsendung = this.state.TShirtGroesse
      if(this.state.TShirt === "Nein") {
        tshirtsendung = "Keine Angabe notwendig!"
      }
      let schwimmensendung = this.state.SchwimmenAngabe
      if(this.state.Schwimmen === "Nein") {
        schwimmensendung = "Keine Angabe notwendig!"
      }
      let radsendung = this.state.RadAngabe
      if(this.state.Rad === "Nein") {
        radsendung = "Keine Angabe notwendig!"
      }

      let url = window.location.pathname
      let laenge = url.length - 1;
      let Vereinsname = url.slice(-laenge).slice(0,url.slice(-laenge).length-5)

      const Spielerdaten = {
        auswahl: Vereinsname,
        nameEltern: this.state.NameEltern,
        nameKind: this.state.NameKind,
        geschlecht: this.state.Geschlecht,
        alter: this.state.Alter,
        tshirtgroesse: tshirtsendung,
        schwimmenangabe: schwimmensendung,
        radangabe: radsendung,
        mitgliedsstatus: this.state.Mitgliedsstatus,
        camps: Camps,
        email: this.state.email,
        nummer: this.state.nummer,
        bemerkungen: this.state.Bemerkungen,
        mailschicken: Mailschicken,
      };
      
      axios.post("/api/SpielerCamp", Spielerdaten).then(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );
      
      this.setState({Abgeschickt: 1})
      window.scrollTo(0, 0);
    }
  };

  render() {
    if(this.state.sperren === "No") {
      if(this.state.Abgeschickt === 0) {
        return(
        <div>
            <UnderHeaderVereine />
            <div className="container">
            <div>
                <div className="gross">
                <br/>
                Hiermit melde ich
                <input className="name"
                    type="text"
                    placeholder="Vor- & Nachname"
                    value={this.state.NameKind}
                    onChange={(event) => { this.setState({ NameKind: event.target.value })}}
                />
                <span id="roteFarbe">&nbsp;{this.state.ErrorNameKind}</span>
                (
                <select className="geschlecht"
                    value={this.state.Geschlecht}
                    onChange={(event) => {this.setState({Geschlecht: event.target.value})}}
                >
                    <option disabled>Geschlecht</option>
                    <option>weiblich</option>
                    <option>männlich</option>
                </select>
                <span id="roteFarbe">&nbsp;{this.state.ErrorGeschlecht}</span>
                , geboren am
                <input className="alter"
                    type="text"
                    value={this.state.Alter}
                    placeholder="TT.MM.JJJJ"
                    onChange={(event) => { this.setState({ Alter: event.target.value }) }}
                />
                <span id="roteFarbe">&nbsp;{this.state.ErrorAlter}</span>
                ) zum Tenniscamp im <span className="blauerhintergrund">{this.state.Auswahl.slice(0,this.state.Auswahl.length - 5)}</span> an.
                {this.state.Geschlecht === "Geschlecht" ? (
                  <span> Sie/Er</span>
                ) : this.state.Geschlecht === "weiblich" ? (
                  <span> Sie</span>
                ) : (
                  <span> Er</span>
                )}
                &nbsp;ist
                <select className="mitglied"
                    value={this.state.Mitgliedsstatus}
                    onChange={(event) => {this.setState({Mitgliedsstatus: event.target.value})}}
                >
                    <option disabled>--- bitte auswählen ---</option>
                    <option>Mitglied</option>
                    <option>kein Mitglied</option>
                </select>
                <span id="roteFarbe">&nbsp;{this.state.ErrorMitgliedsstatus}</span>
                des Vereins und möchte an folgenden Camps teilnehmen (bitte auswählen):
                {this.state.AnzahlCamps === "1" ? (
                  <div>
                    <input
                      type="checkbox"
                      checked={this.state.Camp1Checkbox}
                      onChange={(event) => { this.setState({ Camp1Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp1}</small>
                  </div>
                ) : this.state.AnzahlCamps === "2" ? (
                  <div>
                    <input
                      type="checkbox"
                      checked={this.state.Camp1Checkbox}
                      onChange={(event) => { this.setState({ Camp1Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp1}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp2Checkbox}
                      onChange={(event) => { this.setState({ Camp2Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp2}</small>
                  </div>
                ) : this.state.AnzahlCamps === "3" ? (
                  <div>
                    <input
                      type="checkbox"
                      checked={this.state.Camp1Checkbox}
                      onChange={(event) => { this.setState({ Camp1Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp1}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp2Checkbox}
                      onChange={(event) => { this.setState({ Camp2Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp2}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp3Checkbox}
                      onChange={(event) => { this.setState({ Camp3Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp3}</small>
                  </div>
                ) : this.state.AnzahlCamps === "4" ? (
                  <div>
                    <input
                      type="checkbox"
                      checked={this.state.Camp1Checkbox}
                      onChange={(event) => { this.setState({ Camp1Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp1}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp2Checkbox}
                      onChange={(event) => { this.setState({ Camp2Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp2}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp3Checkbox}
                      onChange={(event) => { this.setState({ Camp3Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp3}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp4Checkbox}
                      onChange={(event) => { this.setState({ Camp4Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp4}</small>
                  </div>
                ) : this.state.AnzahlCamps === "5" ? (
                  <div>
                    <input
                      type="checkbox"
                      checked={this.state.Camp1Checkbox}
                      onChange={(event) => { this.setState({ Camp1Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp1}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp2Checkbox}
                      onChange={(event) => { this.setState({ Camp2Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp2}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp3Checkbox}
                      onChange={(event) => { this.setState({ Camp3Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp3}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp4Checkbox}
                      onChange={(event) => { this.setState({ Camp4Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp4}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp5Checkbox}
                      onChange={(event) => { this.setState({ Camp5Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp5}</small>
                  </div>
                ) : this.state.AnzahlCamps === "6" ? (
                  <div>
                    <input
                      type="checkbox"
                      checked={this.state.Camp1Checkbox}
                      onChange={(event) => { this.setState({ Camp1Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp1}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp2Checkbox}
                      onChange={(event) => { this.setState({ Camp2Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp2}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp3Checkbox}
                      onChange={(event) => { this.setState({ Camp3Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp3}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp4Checkbox}
                      onChange={(event) => { this.setState({ Camp4Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp4}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp5Checkbox}
                      onChange={(event) => { this.setState({ Camp5Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp5}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp6Checkbox}
                      onChange={(event) => { this.setState({ Camp6Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp6}</small>
                  </div>
                ) : this.state.AnzahlCamps === "7" ? (
                  <div>
                    <input
                      type="checkbox"
                      checked={this.state.Camp1Checkbox}
                      onChange={(event) => { this.setState({ Camp1Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp1}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp2Checkbox}
                      onChange={(event) => { this.setState({ Camp2Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp2}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp3Checkbox}
                      onChange={(event) => { this.setState({ Camp3Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp3}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp4Checkbox}
                      onChange={(event) => { this.setState({ Camp4Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp4}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp5Checkbox}
                      onChange={(event) => { this.setState({ Camp5Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp5}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp6Checkbox}
                      onChange={(event) => { this.setState({ Camp6Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp6}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp7Checkbox}
                      onChange={(event) => { this.setState({ Camp7Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp7}</small>
                  </div>
                ) : this.state.AnzahlCamps === "8" ? (
                  <div>
                    <input
                      type="checkbox"
                      checked={this.state.Camp1Checkbox}
                      onChange={(event) => { this.setState({ Camp1Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp1}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp2Checkbox}
                      onChange={(event) => { this.setState({ Camp2Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp2}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp3Checkbox}
                      onChange={(event) => { this.setState({ Camp3Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp3}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp4Checkbox}
                      onChange={(event) => { this.setState({ Camp4Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp4}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp5Checkbox}
                      onChange={(event) => { this.setState({ Camp5Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp5}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp6Checkbox}
                      onChange={(event) => { this.setState({ Camp6Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp6}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp7Checkbox}
                      onChange={(event) => { this.setState({ Camp7Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp7}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp8Checkbox}
                      onChange={(event) => { this.setState({ Camp8Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp8}</small>
                  </div>
                ) : this.state.AnzahlCamps === "9" ? (
                  <div>
                    <input
                      type="checkbox"
                      checked={this.state.Camp1Checkbox}
                      onChange={(event) => { this.setState({ Camp1Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp1}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp2Checkbox}
                      onChange={(event) => { this.setState({ Camp2Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp2}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp3Checkbox}
                      onChange={(event) => { this.setState({ Camp3Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp3}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp4Checkbox}
                      onChange={(event) => { this.setState({ Camp4Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp4}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp5Checkbox}
                      onChange={(event) => { this.setState({ Camp5Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp5}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp6Checkbox}
                      onChange={(event) => { this.setState({ Camp6Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp6}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp7Checkbox}
                      onChange={(event) => { this.setState({ Camp7Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp7}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp8Checkbox}
                      onChange={(event) => { this.setState({ Camp8Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp8}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp9Checkbox}
                      onChange={(event) => { this.setState({ Camp9Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp9}</small>
                  </div>
                ) : this.state.AnzahlCamps === "10" ? (
                  <div>
                    <input
                      type="checkbox"
                      checked={this.state.Camp1Checkbox}
                      onChange={(event) => { this.setState({ Camp1Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp1}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp2Checkbox}
                      onChange={(event) => { this.setState({ Camp2Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp2}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp3Checkbox}
                      onChange={(event) => { this.setState({ Camp3Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp3}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp4Checkbox}
                      onChange={(event) => { this.setState({ Camp4Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp4}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp5Checkbox}
                      onChange={(event) => { this.setState({ Camp5Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp5}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp6Checkbox}
                      onChange={(event) => { this.setState({ Camp6Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp6}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp7Checkbox}
                      onChange={(event) => { this.setState({ Camp7Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp7}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp8Checkbox}
                      onChange={(event) => { this.setState({ Camp8Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp8}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp9Checkbox}
                      onChange={(event) => { this.setState({ Camp9Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp9}</small><br/>
                  <input
                      type="checkbox"
                      checked={this.state.Camp10Checkbox}
                      onChange={(event) => { this.setState({ Camp10Checkbox: event.target.checked })}}
                  /> <small>&nbsp;{this.state.Camp10}</small>
                  </div>
                ) : ( //Dann hat der Trainer noch nicht freigeschalten
                  <div id="roteFarbe"><br/>Die zuständige Person hat die Anmeldung noch nicht freigeschalten!</div>
                )}

                {this.state.NameEltern === "---Ü18---" ? (
                    <p>
                    <br/>
                    <li><big><strong>Kontakmöglichkeiten</strong></big></li><br/>
                    Kontaktdaten:
                    <input className="email"
                        type="mail"
                        value={this.state.email}
                        placeholder="E-Mail"
                        onChange={(event) => { this.setState({ email: event.target.value })}}
                    />
                    <span id="roteFarbe">&nbsp;{this.state.Erroremail}</span>
                    <input className="telefon"
                        type="telefon"
                        value={this.state.nummer}
                        placeholder="Telefon"
                        onChange={(event) => { this.setState({ nummer: event.target.value })}}
                    />
                    <span id="roteFarbe">&nbsp;{this.state.Errornummer}</span>
                    </p>
                ) : ( 
                    <p>
                    <br/>
                    Name eines Erziehungsberechtigten (bei Minderjährigen):
                    <input className="erziehung"
                        type="text"
                        value={this.state.NameEltern}
                        placeholder="Vor- & Nachname"
                        onChange={(event) => { this.setState({ NameEltern: event.target.value })}}
                    />
                    <span id="roteFarbe">&nbsp;{this.state.ErrorNameEltern}</span>
                    <br/>
                    Kontaktdaten:
                    <input className="email"
                        type="mail"
                        value={this.state.email}
                        placeholder="E-Mail"
                        onChange={(event) => { this.setState({ email: event.target.value })}}
                    />
                    <span id="roteFarbe">&nbsp;{this.state.Erroremail}</span>
                    <input className="telefon"
                        type="telefon"
                        value={this.state.nummer}
                        placeholder="Telefon"
                        onChange={(event) => { this.setState({ nummer: event.target.value })}}
                    />
                    <span id="roteFarbe">&nbsp;{this.state.Errornummer}</span>
                    </p>
                )}
                <input
                    type="checkbox"
                    checked={this.state.Bestaetigungsmail}
                    onChange={(event) => {
                    if(this.state.Bestaetigungsmail === false) {
                      this.setState({Bestaetigungsmail: true})
                    }
                    else {
                      this.setState({Bestaetigungsmail: false})
                    }
                    }}
                /> Ich möchte nach der Anmeldung eine Bestätigungsmail erhalten.
                </div>
                <br/>
                
                <div>
                <br/>
                {this.state.TShirt === "Nein" ? (
                  null
                ) : (
                  <span>
                    T-Shirt-Größe&nbsp;
                    <select className="groesse"
                        value={this.state.TShirtGroesse}
                        onChange={(event) => {this.setState({TShirtGroesse: event.target.value})}}
                    >
                        <option disabled>Größe</option>
                        <option>104</option>
                        <option>116</option>
                        <option>128</option>
                        <option>140</option>
                        <option>152</option>
                        <option>164</option>
                        <option>S</option>
                        <option>M</option>
                        <option>L</option>
                    </select>
                    <span id="roteFarbe">&nbsp;{this.state.ErrorTShirt}</span>
                  </span>
                )}
                <br/>
                {this.state.Schwimmen === "Nein" ? (
                  null
                ) : (
                  <span>
                    Schwimmfähigkeit&nbsp;
                    <select className="schwimmen"
                        value={this.state.SchwimmenAngabe}
                        onChange={(event) => {this.setState({SchwimmenAngabe: event.target.value})}}
                    >
                        <option disabled>Fähigkeit</option>
                        <option>Schwimmer*in</option>
                        <option>Nichtschwimmer*in</option>
                    </select>
                    <span id="roteFarbe">&nbsp;{this.state.ErrorSchwimmen}</span>
                  </span>
                )}
                <br/>
                {this.state.Rad === "Nein" ? (
                  null
                ) : (
                  <span>
                    Beherrscht {this.state.NameKind} das Fahrradfahren und könnte täglich mit
                    einem eigenen Rad zum Camp fahren (um an einer Fahrradtour teilzunehmen)?&nbsp;
                    <select className="fahrrad"
                        value={this.state.RadAngabe}
                        onChange={(event) => {this.setState({RadAngabe: event.target.value})}}
                    >
                        <option disabled>Fahrrad</option>
                        <option>Ja</option>
                        <option>Nein</option>
                    </select>
                    <span id="roteFarbe">&nbsp;{this.state.ErrorRad}</span>
                  </span>
                )}
                <br/>
                <textarea className="bemerkungen"
                    value={this.state.Bemerkungen}
                    placeholder="Bemerkungen/Wünsche"
                    onChange={(event) => { this.setState({ Bemerkungen: event.target.value })}}
                />
                </div>
                <br/>
                <div className="gross">
                  <input
                      type="checkbox"
                      checked={this.state.rememberMe}
                      onChange={(event) => { this.setState({ rememberMe: event.target.checked })}}
                  /> <small>Ich habe die{" "}
                      <a href="/datenschutzerklaerung" target="_blank">
                      Datenschutzerklärung von TennisGroupOrganizer
                      </a>{" "}
                      zur Kenntnis genommen und stimme zu, dass meine Angaben und
                      Daten zur Übermittlung elektronisch erhoben und gespeichert werden.<br/>
                      <span id="roteFarbe">{this.state.ErrorrememberMe}</span></small>
                </div>
                <div className="gross">
                  <input
                      type="checkbox"
                      checked={this.state.Anmeldebedingungen}
                      onChange={(event) => { this.setState({ Anmeldebedingungen: event.target.checked })}}
                  /> <small>Ich akzeptiere die Anmeldebedingungen des Vereins/ der Tennisschule
                      und melde hiermit {this.state.NameKind} verpflichtend zu den ausgewählten
                      Camps an.<br/>
                      <span id="roteFarbe">{this.state.ErrorAnmeldebedingungen}</span></small>
                </div>
                <br/>
                <div>
                    <div>
                        <div className="normal">
                        <button onClick={this.handleSubmit}>
                            Absenden
                        </button>
                        </div>
                    </div>
                    <div id="roteFarbe">{this.state.Error}</div>
                </div>

            </div>
            </div>
        </div>
        );
      }
      else {
        if(this.state.Mitgliedsstatus === "Mitglied") {
          return(
            <div className="container">
              <p>
                {this.state.BemerkungenTrainer}
              </p>
              <br/>
              <br/>
              <br/>
              <br/>
              <div className="zurueckweiter">
                Wie finden Sie diese Form der Anmeldung zum Tenniscamp?
              </div>
              <div className="zurueckweiter">
                <div>
                  {this.Bewertung()}
                </div>
              </div>
              <div className="zurueckweiter">
                <textarea className="bemerkungen"
                    value={this.state.Feedback}
                    placeholder="optional"
                    onChange={(event) => { this.setState({ Feedback: event.target.value })}}
                />
              </div>
              <div className="zurueckweiter">
                <div className="normal">
                  <button onClick={(event) => {
                    if(this.state.Sternanzahl > 0) {
                      this.Bewertungsenden()
                    }
                    }}>
                    Bewertung senden
                  </button>
                </div>
              </div>
            </div>
          );
        }
        else {
          return(
            <div className="container">
              <p>
                {this.state.BemerkungenTrainer}
              </p>
              <p>
                {this.state.BemerkungenTrainer2} <a href={this.state.BemerkungenTrainer3} rel="noopener noreferrer" target="_blank">{this.state.BemerkungenTrainer3}</a>
              </p>
              <br/>
              <br/>
              <br/>
              <br/>
              <div className="zurueckweiter">
                Wie finden Sie diese Form der Anmeldung zum Tennistraining?
              </div>
              <div className="zurueckweiter">
                <div>
                  {this.Bewertung()}
                </div>
              </div>
              <div className="zurueckweiter">
                <textarea className="bemerkungen"
                    value={this.state.Feedback}
                    placeholder="optional"
                    onChange={(event) => { this.setState({ Feedback: event.target.value })}}
                />
              </div>
              <div className="zurueckweiter">
                <div className="normal">
                  <button onClick={(event) => {
                    if(this.state.Sternanzahl > 0) {
                      this.Bewertungsenden()
                    }
                    }}>
                    Bewertung senden
                  </button>
                </div>
              </div>
            </div>
          );
        }
      }
    }
    else {
      return(
        <div className="container">
          <h3><p id="roteFarbe">
            <strong>{this.state.eilmeldung}</strong>
          </p></h3>
        </div>
      );
    }
  }

}