import React from "react";
import "./App.css";
import { UnderHeader } from "./components/UnderHeader";
import axios from "axios";

export class Home extends React.Component {
  constructor() {
    super();
    this.state = {
        eilmeldung: "",
    };
}

  componentDidMount() {
    axios
      .get("/api/Eilmeldung")
      .then((res) => {
        const message = res.data;
        this.setState({ 
          eilmeldung: message,
        });
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  Eilmeldung() {
    if(this.state.eilmeldung === " ") {
      return(
        <div></div>
      )
    }
    else {
      return(
        <p id="roteFarbe">
          <strong>{this.state.eilmeldung}</strong>
        </p>
      );
    }
  }

  render() {
    if(window.screen.availWidth > 910) {
      return(
        <div>
          <UnderHeader />
          <div className="container">
            <h3>
              {this.Eilmeldung()}
            </h3>
            
            <br/>
            <br/>

            <div className="center">
              <h1>TGO ist <span className="underline">die</span> Software zur Wochenplanerstellung</h1>
              <h1>für jede Tennisschule!</h1>
            </div>
            
            <br/>
            <br/>
            <br/>
            <br/>
            
            <table>
              <tbody>
                <tr>
                  <td>
                    <big><big>
                      <strong className="blauerhintergrund">1. Anmeldungen</strong>
                      <br/>
                      Ihre Schüler*innen melden sich ganz unkompliziert auf unserer
                      Website für Ihr Training an. Sie bestimmen vorher, zu welchen
                      Uhrzeiten.
                      <br/><br/>
                      Sparen Sie sich den Papierkram!
                    </big></big>
                  </td>
                  <td>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </td>
                  <td>
                    <div className="Anmeldeformulargross"></div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                  </td>
                </tr>
                <tr>
                  <td>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <div id="green"><big><big>&#10004;</big></big></div>
                          </td>
                          <td>
                            &nbsp;&nbsp;&nbsp;<big>Homogene Gruppen</big>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div id="green"><big><big>&#10004;</big></big></div>
                          </td>
                          <td>
                            &nbsp;&nbsp;&nbsp;<big>Gruppenwünsche</big>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div id="green"><big><big>&#10004;</big></big></div>
                          </td>
                          <td>
                            &nbsp;&nbsp;&nbsp;<big>Beliebige Gruppengrößen</big>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div id="green"><big><big>&#10004;</big></big></div>
                          </td>
                          <td>
                            &nbsp;&nbsp;&nbsp;<big>Zeitliche Prioritäten</big>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div id="green"><big><big>&#10004;</big></big></div>
                          </td>
                          <td>
                            &nbsp;&nbsp;&nbsp;<big>Anzahl der Trainingsplätze</big>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </td>
                  <td>
                    <big><big>
                      <strong className="blauerhintergrund">2. Wochenplan berechnen lassen</strong>
                      <br/>
                      Unser Algorithmus erstellt Ihren Wochenplan selbstständig.<br/>
                      Sie bestimmen dabei die Spielstärken der Schüler*innen
                      und <i>können</i> Einfluss auf die Gruppenkonstellationen nehmen.
                    </big></big>
                  </td>
                </tr>
                <tr>
                  <td>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                  </td>
                </tr>
                <tr>
                  <td>
                    <big><big>
                      <strong className="blauerhintergrund">3. Zeit sparen</strong>
                      <br/>
                      Nutzen Sie Ihre Zeit für andere Dinge.
                    </big></big>
                  </td>
                  <td>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </td>
                  <td>
                    <div className="Zeitaufwand400"></div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="Ausgaben400"></div>
                  </td>
                  <td>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </td>
                  <td>
                    <big><big>
                      <strong className="blauerhintergrund">4. Stundenplan erhalten</strong>
                      <br/>
                      Sie erhalten eine E-Mail, sobald Ihr Wochenplan fertig berechnet wurde.
                      <br/>
                      <br/>
                      Dabei ist TGO in der ersten Saison komplett <i>kostenlos</i>.
                    </big></big>
                  </td>
                </tr>
                <tr>
                  <td>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                  </td>
                </tr>
                <tr>
                  <td>
                    <big><big>
                    <strong className="blauerhintergrund">Neugierig?</strong>
                      <br/>
                      Wir zeigen Ihnen gerne alle Funktionen unserer
                      Software und klären Ihre Fragen.
                    </big></big>
                  </td>
                  <td>
                  <big><big><big><big>&#10132;</big></big></big></big>
                  </td>
                  <td>
                      <div className="normal">
                        <button onClick={(event) => {document.location.href="/kontakt"}}>
                          Kontakt
                        </button>
                      </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <br/>
            <br/>

            <div className="partner">
              <br/>
              <center><big>Mit freundlicher Unterstützung von</big></center>
              <div className="NRW"></div>
              <div className="Gruenderstipendium"></div>
              <div className="Existenzgruenderpreis"></div>
              <div className="Kreissparkasse"></div>
            </div>
          </div>
        </div>
      );
    }
    else {
      return(
        <div>
          <UnderHeader />
          <div className="container">
            <h3>
              {this.Eilmeldung()}
            </h3>
            
            <br/>

            <table>
              <tbody>
                <tr>
                  <td>
                    <div className="center">
                      <h2>TGO ist <span className="underline">die</span> Software zur Wochenplanerstellung für jede Tennisschule!</h2>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <br/>
            <br/>
            <br/>
            <br/>
            
            <table>
              <tbody>
                <tr>
                  <td>
                    <big><big>
                      <strong className="blauerhintergrund">1. Anmeldungen</strong>
                      <br/>
                      Ihre Schüler*innen melden sich ganz unkompliziert auf unserer
                      Website für Ihr Training an. Sie bestimmen vorher, zu welchen
                      Uhrzeiten.
                      <br/><br/>
                      Sparen Sie sich den Papierkram!
                    </big></big>
                  </td>
                </tr>
              </tbody>
            </table>

            <br/>

            <table>
              <tbody>
                <tr>
                  <td>
                    <div className="Anmeldeformularklein"></div>
                  </td>
                </tr>
              </tbody>
            </table>
            
            <br/>
            <br/>
            <br/>

            <big><big>
              <strong className="blauerhintergrund">2. Wochenplan berechnen lassen</strong>
              <br/>
              Unser Algorithmus erstellt Ihren Wochenplan selbstständig.<br/>
              Sie bestimmen dabei die Spielstärken der Schüler*innen
              und <i>können</i> Einfluss auf die Gruppenkonstellationen nehmen.
            </big></big>

            <br/>
            <br/>

            <table>
              <tbody>
                <tr>
                  <td>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <div id="green"><big><big>&#10004;</big></big></div>
                          </td>
                          <td>
                            &nbsp;&nbsp;&nbsp;<big>Homogene Gruppen</big>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div id="green"><big><big>&#10004;</big></big></div>
                          </td>
                          <td>
                            &nbsp;&nbsp;&nbsp;<big>Gruppenwünsche</big>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div id="green"><big><big>&#10004;</big></big></div>
                          </td>
                          <td>
                            &nbsp;&nbsp;&nbsp;<big>Beliebige Gruppengrößen</big>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div id="green"><big><big>&#10004;</big></big></div>
                          </td>
                          <td>
                            &nbsp;&nbsp;&nbsp;<big>Zeitliche Prioritäten</big>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div id="green"><big><big>&#10004;</big></big></div>
                          </td>
                          <td>
                            &nbsp;&nbsp;&nbsp;<big>Anzahl der Trainingsplätze</big>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>

            <br/>
            <br/>
            <br/>

            <table>
              <tbody>
                <tr>
                  <td>
                    <big><big>
                      <strong className="blauerhintergrund">3. Zeit sparen</strong>
                      <br/>
                      Nutzen Sie Ihre Zeit für andere Dinge.
                    </big></big>
                  </td>
                </tr>
              </tbody>
            </table>

            <table>
              <tbody>
                <tr>
                  <td>
                    <div className="Zeitaufwand180"></div>
                  </td>
                </tr>
              </tbody>
            </table>

            <br/>
            <br/>
            <br/>
            
            <table>
              <tbody>
                <tr>
                  <td>
                    <big><big>
                      <strong className="blauerhintergrund">4. Stundenplan erhalten</strong>
                      <br/>
                      Sie erhalten eine Mail mit Ihrem Wochenplan als Excel-Datei, sobald
                      dieser fertig berechnet wurde.
                      <br/>
                      <br/>
                      Dabei ist TGO in der ersten Saison komplett <i>kostenlos</i>.
                    </big></big>
                  </td>
                </tr>
              </tbody>
            </table>

            <br/>

            <table>
              <tbody>
                <tr>
                  <td>
                    <div className="Ausgaben150"></div>
                  </td>
                </tr>
              </tbody>
            </table>

            <br/>
            <br/>
            <br/>

            <table>
              <tbody>
                <tr>
                  <td>
                    <big><big>
                    <strong className="blauerhintergrund">Neugierig?</strong>
                      <br/>
                      Wir zeigen Ihnen gerne alle Funktionen unserer
                      Software und klären Ihre Fragen.
                    </big></big>
                  </td>
                </tr>
              </tbody>
            </table>

            <br/>

            <table>
              <tbody>
                <tr>
                  <td>
                    <div className="zurueckweiter">
                      <div className="normal">
                        <button onClick={(event) => {document.location.href="/kontakt"}}>
                          Kontakt
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <br/>
            <br/>
            <br/>

            <div className="partner">
              <br/>
              <center><big>Mit freundlicher Unterstützung von</big></center>
              <div className="NRW"></div>
              <div className="Gruenderstipendium"></div>
              <div className="Existenzgruenderpreis"></div>
              <div className="Kreissparkasse"></div>
            </div>

          </div>
        </div>
      );
    }
  }
}
