import React from "react";
import { UnderHeaderProduktkauf } from "./components/UnderHeaderProduktkauf";
import "./subtitles.css";
import axios from "axios";

export class ProduktkaufAllin extends React.Component {
  constructor() {
    super();
    this.state = {
      firma: "",
      title: "",
      Nachname: "",
      Vorname: "",
      StrasseHausnummer: "",
      PLZ: "",
      email: "",
      nummer: "",
      selectedProdukt: "",
      rememberMe: false,
      Tennisverein: "",
    };
  }

  validate = () => {
    let Errorfirma = "";
    let ErrorPerson = "";
    let ErrorStrasseHausnummer = "";
    let ErrorPLZ = "";
    let Erroremail = "";
    let ErrorrememberMe = "";
    let Error = "";

    if (!this.state.firma) {
      Errorfirma = "Fehlt";
      Error = "Es fehlen noch Angaben.";
    }
    if (!this.state.title) {
      ErrorPerson = "Fehlt";
      Error = "Es fehlen noch Angaben.";
    }
    if (!this.state.StrasseHausnummer) {
      ErrorStrasseHausnummer = "Fehlt";
      Error = "Es fehlen noch Angaben.";
    }
    if (!this.state.PLZ) {
      ErrorPLZ = "Fehlt";
      Error = "Es fehlen noch Angaben.";
    }
    if (!this.state.email) {
      Erroremail = "Fehlt";
      Error = "Es fehlen noch Angaben.";
    }
    if (!this.state.rememberMe) {
      ErrorrememberMe =
        "Bestätigen Sie, dass Sie unsere AGB und unsere Datenschutzerklärung gelesen haben.";
      Error = "Es fehlen noch Angaben.";
    }
    if (
      Errorfirma ||
      ErrorPerson ||
      ErrorStrasseHausnummer ||
      ErrorPLZ ||
      Erroremail ||
      ErrorrememberMe
    ) {
      this.setState({
        Errorfirma,
        ErrorPerson,
        ErrorStrasseHausnummer,
        ErrorPLZ,
        Erroremail,
        ErrorrememberMe,
        Error,
      });
      return false;
    }

    return true;
  };

  handleChangeFirma = (event) => {
    this.setState({ firma: event.target.value });
  };

  handleSelect = (event) => {
    this.setState({ title: event.target.value });
  };

  handleChangeNachname = (event) => {
    this.setState({ Nachname: event.target.value });
  };

  handleChangeVorname = (event) => {
    this.setState({ Vorname: event.target.value });
  };

  handleChangeStrasseHausnummer = (event) => {
    this.setState({ StrasseHausnummer: event.target.value });
  };

  handleChangePLZ = (event) => {
    this.setState({ PLZ: event.target.value });
  };

  handleChangeTennisverein = (event) => {
    this.setState({ Tennisverein: event.target.value });
  };

  handleChangeemail = (event) => {
    this.setState({ email: event.target.value });
  };

  handleChangenummer = (event) => {
    this.setState({ nummer: event.target.value });
  };

  handleCheck = (event) => {
    this.setState({ rememberMe: event.target.checked });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const isValid = this.validate();
    if (isValid) {

      const Kaufen = {
        firma: this.state.firma,
        anrede: this.state.title,
        vorname: this.state.Vorname,
        nachname: this.state.Nachname,
        strasse: this.state.StrasseHausnummer,
        ort: this.state.PLZ,
        email: this.state.email,
        produkt: "TGO All-in",
        nummer: this.state.nummer,
      };
      
      axios.post("/api/kaufen", Kaufen, { timeout: 0 }).then(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );

      document.location.href="/bestaetigung";
    }
  };

  TabelleGross() {
    return(
      <div>
        <big><big><big><strong className="blauerhintergrund">TGO All-in</strong></big></big></big>
        <br/>
        <br/>
        <div id="roteFarbe"><big>Benutzen Sie für den Bestellvorgang keinesfalls den Safari-Browser!</big></div>
        <br/>
        <br/>
        <table>
            <tbody>
              <tr>
                <td>
                  <strong>Unternehmen/Verein<span id="roteFarbe">*</span></strong>
                  <div id="roteFarbe">&nbsp;{this.state.Errorfirma}</div>
                </td>
                <td>
                  <div className="innercol">
                      <input
                      type="text"
                      value={this.state.firma}
                      onChange={this.handleChangeFirma}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Personendaten<span id="roteFarbe">*</span></strong>
                  <div id="roteFarbe">&nbsp;{this.state.ErrorPerson}</div>
                </td>
                <td>
                  <div>
                    <div className="innercol">
                        &nbsp;&nbsp;&nbsp;&nbsp;<select 
                        value={this.state.title}
                        onChange={this.handleSelect}>

                        <option>Herr</option>
                        <option>Frau</option>
                        <option>Keine Angabe</option>
                        <option value="" disabled>Anrede wählen</option>
                      </select>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div className="innercol">
                      &nbsp;<input
                        type="text"
                        placeholder={"Vorname"}
                        value={this.state.Vorname}
                        onChange={this.handleChangeVorname}
                      />
                    </div>
                  </div>
                </td>
                <td>
                <div>
                    <div className="innercol">
                        &nbsp;<input
                        type="text"
                        placeholder={"Nachname"}
                        value={this.state.Nachname}
                        onChange={this.handleChangeNachname}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>E-Mail<span id="roteFarbe">*</span></strong>
                  <div id="roteFarbe">&nbsp;{this.state.Erroremail}</div>
                </td>
                <td>
                  <div className="innercol">
                    <input
                      type="mail"
                      value={this.state.email}
                      onChange={this.handleChangeemail}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Telefonnummer</strong>
                </td>
                <td>
                  <div className="innercol">
                    <input
                      type="telefon"
                      value={this.state.nummer}
                      onChange={this.handleChangenummer}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Straße, Hausnr.<span id="roteFarbe">*</span></strong>
                  <div id="roteFarbe">&nbsp;{this.state.ErrorStrasseHausnummer}</div>
                </td>
                <td>
                  <div className="innercol">
                      <input
                      type="text"
                      placeholder={"Rechnungsadresse"}
                      value={this.state.StrasseHausnummer}
                      onChange={this.handleChangeStrasseHausnummer}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>PLZ, Ort<span id="roteFarbe">*</span></strong>
                  <div id="roteFarbe">&nbsp;{this.state.ErrorPLZ}</div>
                </td>
                <td>
                  <div className="innercol">
                    <input
                    type="text"
                    placeholder={"Rechnungsadresse"}
                    value={this.state.PLZ}
                    onChange={this.handleChangePLZ}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    );
  }

  TabelleKlein() {
    return(
      <div>
        <big><big><big><strong className="blauerhintergrund">TGO All-in</strong></big></big></big>
        <br/>
        <br/>
        <div id="roteFarbe"><big>Benutzen Sie für den Bestellvorgang keinesfalls den Safari-Browser!</big></div>
        <br/>
        <br/>
        <table>
            <tbody>
              <tr>
                <td>
                  <strong>Unternehmen/Verein<span id="roteFarbe">*</span></strong>
                  <div id="roteFarbe">&nbsp;{this.state.Errorfirma}</div>
                </td>
                <td>
                  <div className="innercol">
                      <input
                      type="text"
                      value={this.state.firma}
                      onChange={this.handleChangeFirma}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <br/>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Personendaten<span id="roteFarbe">*</span></strong>
                  <div id="roteFarbe">&nbsp;{this.state.ErrorPerson}</div>
                </td>
                <td>
                  <div>
                    <div className="innercol">
                        &nbsp;&nbsp;&nbsp;&nbsp;<select 
                        value={this.state.title}
                        onChange={this.handleSelect}>

                        <option>Herr</option>
                        <option>Frau</option>
                        <option>Keine Angabe</option>
                        <option value="" disabled>Anrede wählen</option>
                      </select>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>

                </td>
                <td>
                  <div>
                    <div className="innercol">
                      <input
                        type="text"
                        placeholder={"Vorname"}
                        value={this.state.Vorname}
                        onChange={this.handleChangeVorname}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  
                </td>
                <td>
                <div>
                    <div className="innercol">
                        <input
                        type="text"
                        placeholder={"Nachname"}
                        value={this.state.Nachname}
                        onChange={this.handleChangeNachname}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <br/>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>E-Mail<span id="roteFarbe">*</span></strong>
                  <div id="roteFarbe">&nbsp;{this.state.Erroremail}</div>
                </td>
                <td>
                  <div className="innercol">
                    <input
                      type="mail"
                      value={this.state.email}
                      onChange={this.handleChangeemail}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Telefonnummer</strong>
                </td>
                <td>
                  <div className="innercol">
                    <input
                      type="telefon"
                      value={this.state.nummer}
                      onChange={this.handleChangenummer}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <br/>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Straße, Hausnr.<span id="roteFarbe">*</span></strong>
                  <div id="roteFarbe">&nbsp;{this.state.ErrorStrasseHausnummer}</div>
                </td>
                <td>
                  <div className="innercol">
                      <input
                      type="text"
                      placeholder={"Rechnungsadresse"}
                      value={this.state.StrasseHausnummer}
                      onChange={this.handleChangeStrasseHausnummer}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>PLZ, Ort<span id="roteFarbe">*</span></strong>
                  <div id="roteFarbe">&nbsp;{this.state.ErrorPLZ}</div>
                </td>
                <td>
                  <div className="innercol">
                    <input
                    type="text"
                    placeholder={"Rechnungsadresse"}
                    value={this.state.PLZ}
                    onChange={this.handleChangePLZ}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <br/>

          <table>
            <tbody>
              <tr>
                <td>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <h2>
                      Rechnungsbetrag (netto):
                    </h2>
                </td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                <td>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <h2>
                    1,00€/Person
                  </h2>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    );
  }

  TabelleSehrKlein() {
    return(
      <div>
        <big><big><big><strong className="blauerhintergrund">TGO All-in</strong></big></big></big>
        <br/>
        <br/>
        <div id="roteFarbe"><big>Benutzen Sie für den Bestellvorgang keinesfalls den Safari-Browser!</big></div>
        <br/>
        <br/>
        <table>
            <tbody>
              <tr>
                <td>
                  <strong>Unternehmen/Verein<span id="roteFarbe">*</span></strong>
                  <span id="roteFarbe">&nbsp;&nbsp;{this.state.Errorfirma}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="innercol">
                      <input
                      type="text"
                      value={this.state.firma}
                      onChange={this.handleChangeFirma}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <br/>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Personendaten<span id="roteFarbe">*</span></strong>
                  <span id="roteFarbe">&nbsp;&nbsp;{this.state.ErrorPerson}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div className="innercol">
                        <select 
                        value={this.state.title}
                        onChange={this.handleSelect}>

                        <option>Herr</option>
                        <option>Frau</option>
                        <option>Keine Angabe</option>
                        <option value="" disabled>Anrede wählen</option>
                      </select>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div className="innercol">
                      <input
                        type="text"
                        placeholder={"Vorname"}
                        value={this.state.Vorname}
                        onChange={this.handleChangeVorname}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                <div>
                    <div className="innercol">
                        <input
                        type="text"
                        placeholder={"Nachname"}
                        value={this.state.Nachname}
                        onChange={this.handleChangeNachname}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <br/>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>E-Mail<span id="roteFarbe">*</span></strong>
                  <span id="roteFarbe">&nbsp;&nbsp;{this.state.Erroremail}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="innercol">
                    <input
                      type="mail"
                      value={this.state.email}
                      onChange={this.handleChangeemail}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <br/>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Telefonnummer</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="innercol">
                    <input
                      type="telefon"
                      value={this.state.nummer}
                      onChange={this.handleChangenummer}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <br/>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Straße, Hausnr.<span id="roteFarbe">*</span></strong>
                  <span id="roteFarbe">&nbsp;&nbsp;{this.state.ErrorStrasseHausnummer}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="innercol">
                      <input
                      type="text"
                      placeholder={"Rechnungsadresse"}
                      value={this.state.StrasseHausnummer}
                      onChange={this.handleChangeStrasseHausnummer}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>PLZ, Ort<span id="roteFarbe">*</span></strong>
                  <span id="roteFarbe">&nbsp;&nbsp;{this.state.ErrorPLZ}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="innercol">
                    <input
                    type="text"
                    placeholder={"Rechnungsadresse"}
                    value={this.state.PLZ}
                    onChange={this.handleChangePLZ}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <br/>
          <br/>

          <table>
            <tbody>
              <tr>
                <td>
                  <h3>
                    Rechnungsbetrag (netto):
                  </h3>
                </td>
              </tr>
              <tr>
                <td>
                  <h3>
                    1,00€/Person
                  </h3>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    );
  }

  TabelleExtremKlein() {
    return(
      <div>
        <big><big><big><strong className="blauerhintergrund">TGO All-in</strong></big></big></big>
        <br/>
        <br/>
        <div id="roteFarbe"><big>Benutzen Sie für den Bestellvorgang keinesfalls den Safari-Browser!</big></div>
        <br/>
        <br/>
        <table>
            <tbody>
              <tr>
                <td>
                  <strong>Unternehmen/Verein<span id="roteFarbe">*</span></strong>
                  <span id="roteFarbe">&nbsp;{this.state.Errorfirma}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="extremklein">
                      <input
                      type="text"
                      value={this.state.firma}
                      onChange={this.handleChangeFirma}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <br/>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Personendaten<span id="roteFarbe">*</span></strong>
                  <span id="roteFarbe">&nbsp;{this.state.ErrorPerson}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div className="extremklein">
                        <select 
                        value={this.state.title}
                        onChange={this.handleSelect}>

                        <option>Herr</option>
                        <option>Frau</option>
                        <option>Keine Angabe</option>
                        <option value="" disabled>Anrede wählen</option>
                      </select>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div className="extremklein">
                      <input
                        type="text"
                        placeholder={"Vorname"}
                        value={this.state.Vorname}
                        onChange={this.handleChangeVorname}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div className="extremklein">
                        <input
                        type="text"
                        placeholder={"Nachname"}
                        value={this.state.Nachname}
                        onChange={this.handleChangeNachname}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <br/>
                </td>
              </tr>
              <tr>
                <td>
                    <strong>E-Mail<span id="roteFarbe">*</span></strong>
                    <span id="roteFarbe">&nbsp;{this.state.Erroremail}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="extremklein">
                    <input
                      type="mail"
                      value={this.state.email}
                      onChange={this.handleChangeemail}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <br/>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Telefonnummer</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="extremklein">
                    <input
                      type="telefon"
                      value={this.state.nummer}
                      onChange={this.handleChangenummer}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <br/>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Straße, Hausnr.<span id="roteFarbe">*</span></strong>
                  <span id="roteFarbe">&nbsp;{this.state.ErrorStrasseHausnummer}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="extremklein">
                      <input
                      type="text"
                      placeholder={"Rechnungsadresse"}
                      value={this.state.StrasseHausnummer}
                      onChange={this.handleChangeStrasseHausnummer}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td>
                  <strong>PLZ, Ort<span id="roteFarbe">*</span></strong>
                  <span id="roteFarbe">&nbsp;{this.state.ErrorPLZ}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="extremklein">
                    <input
                    type="text"
                    placeholder={"Rechnungsadresse"}
                    value={this.state.PLZ}
                    onChange={this.handleChangePLZ}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <br/>
          <br/>

          <table>
            <tbody>
              <tr>
                <td>
                  <h3>
                    Rechnungsbetrag (netto):
                  </h3>
                </td>
              </tr>
              <tr>
                <td>
                  <h3>
                    1,00€/Person
                  </h3>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    );
  }

  render() {
    return (
      <div>
        <UnderHeaderProduktkauf />
        <div className="container">
          <div>
            <div>

              {window.screen.availWidth > window.screen.availHeight ? ( //Querformat/Computer
                  
                window.screen.availWidth > 1140 ? (
                  <div>
                    {this.TabelleGross()}
                  </div>
                ) : window.screen.availWidth > 1080 ? (
                  <div>
                    {this.TabelleKlein()}
                  </div>
                ) : ( window.screen.availWidth > 980 ? (
                  <div>
                    {this.TabelleKlein()}
                  </div>
                ) : ( window.screen.availWidth > 880 ? (
                  <div>
                    {this.TabelleKlein()}
                  </div>
                ) : ( window.screen.availWidth > 780 ? (
                  <div>
                  {this.TabelleKlein()}
                  </div>
                ) : ( window.screen.availWidth > 680 ? (
                  <div>
                  {this.TabelleKlein()}
                  </div>
                ) : ( window.screen.availWidth > 580 ? (
                  <div>
                  {this.TabelleKlein()}
                  </div>
                ) : ( window.screen.availWidth > 480 ? (
                  <div>
                  {this.TabelleKlein()}
                  </div>
                ) : ( window.screen.availWidth > 380 ? (
                  <div>
                  {this.TabelleKlein()}
                  </div>
                ) : ( window.screen.availWidth > 280 ? (
                  <div>
                  {this.TabelleKlein()}
                  </div>
                ) : ( 
                  <div>
                  {this.TabelleKlein()}
                  </div>
                ) ) ) ) ) ) ) ) )

              ) : ( //Hochformat/Handy    <div className="MehrfachtrainingPfeilHochHochkant500px"></div>
                
                window.screen.availWidth > 1140 ? (
                  <div>
                  {this.TabelleGross()}
                  </div>
                ) : window.screen.availWidth > 1074 ? (
                  <div>
                  {this.TabelleKlein()}
                  </div>
                ) : ( window.screen.availWidth > 974 ? (
                  <div>
                  {this.TabelleKlein()}
                  </div>
                ) : ( window.screen.availWidth > 874 ? (
                  <div>
                  {this.TabelleKlein()}
                  </div>
                ) : ( window.screen.availWidth > 774 ? (
                  <div>
                  {this.TabelleKlein()}
                  </div>
                ) : ( window.screen.availWidth > 674 ? (
                  <div>
                  {this.TabelleKlein()}
                  </div>
                ) : ( window.screen.availWidth > 574 ? (
                  <div>
                  {this.TabelleKlein()}
                  </div>
                ) : ( window.screen.availWidth > 474 ? (
                  <div>
                  {this.TabelleSehrKlein()}
                  </div>
                ) : ( window.screen.availWidth > 374 ? (
                  <div>
                  {this.TabelleSehrKlein()}
                  </div>
                ) : ( window.screen.availWidth > 274 ? (
                  <div>
                  {this.TabelleExtremKlein()}
                  </div>
                ) : ( 
                  <div>
                  {this.TabelleExtremKlein()}
                  </div>
                ) ) ) ) ) ) ) ) )

              )}

              <br/>
              <br/>

              <div>
                <span id="roteFarbe">*</span> Pflichtfelder
                <br />
              </div>
              <br />
              <div>
                <div>
                  <div className="innercolcheck">
                    <input
                      type="checkbox"
                      checked={this.state.rememberMe}
                      onChange={this.handleCheck}
                    />
                    <label>
                      Ich erkläre mich mit den{" "}
                      <a href="/agb" target="_blank">AGB von TennisGroupOrganizer</a> einverstanden.
                      Ich habe außerdem die{" "}
                      <a href="/datenschutzerklaerung" target="_blank">
                        Datenschutzerklärung von TennisGroupOrganizer
                      </a>{" "}
                      zur Kenntnis genommen und stimme zu, dass meine Angaben und
                      Daten zur Bearbeitung meiner Bestellung elektronisch erhoben
                      und gespeichert werden.
                    </label>
                  </div>
                </div>
              </div>
              <div id="roteFarbe">{this.state.ErrorrememberMe}</div>
              <br/>
            </div>
            <div>
              <div>
                <div className="normal">
                  <button onClick={this.handleSubmit}>
                    Kaufen
                  </button>
                </div>
              </div>
              <div id="roteFarbe">{this.state.Error}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}