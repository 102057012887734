import React from "react";
import { UnderHeaderDemoProgramm } from "./UnderHeaderDemoProgramm";
import "./demo.css";
import axios from "axios";
//import Video1 from "./VideoSchritt1.mp4";
//import Video2 from "./VideoSchritt2.mp4";
//import Video3 from "./VideoSchritt3.mp4";
//import Video4 from "./VideoSchritt4.mp4";
//import Video5 from "./VideoSchritt5.mp4";
//import Video6 from "./VideoSchritt6.mp4";
//import Video7 from "./VideoSchritt7.mp4";

export class DemoProgramm extends React.Component {
  constructor() {
    super();
    this.state = {
      showMoreSchritt1: true,
      showMoreSchritt2: true,
      showMoreSchritt3: true,
      showMoreSchritt4: true,
      showMoreSchritt5: true,
      showMoreSchritt6: true,
      showMoreSchritt7: true,
      errorSchritt1: "",
      errorSchritt2Uhrzeit: "",
      errorSchritt2Platz: "",
      errorSchritt3: "",
      errorSchritt4Trainingseinheiten: "",
      errorSchritt4Bevorzugt: "",
      errorSchritt4Alternativ: "",
      errorSchritt5: "",
      errorSchritt5gleich: "",
      errorSchritt5Teilnehmer1Name: "",
      errorSchritt5Teilnehmer1Zeit: "",
      errorSchritt5Teilnehmer2Name: "",
      errorSchritt5Teilnehmer2Zeit: "",
      errorSchritt5Teilnehmer3Name: "",
      errorSchritt5Teilnehmer3Zeit: "",
      errorSchritt5Teilnehmer4Name: "",
      errorSchritt5Teilnehmer4Zeit: "",
      errorSchritt5Teilnehmer5Name: "",
      errorSchritt5Teilnehmer5Zeit: "",
      errorSchritt5Teilnehmer6Name: "",
      errorSchritt5Teilnehmer6Zeit: "",
      errorSchritt5Teilnehmer7Name: "",
      errorSchritt5Teilnehmer7Zeit: "",
      errorSchritt5Teilnehmer8Name: "",
      errorSchritt5Teilnehmer8Zeit: "",
      errorSchritt6nurzwei: "",
      errorSchritt6Wunsch1: "",
      errorSchritt6Wunsch2: "",
      errorSchritt6Wunsch3: "",
      errorSchritt6Wunsch4: "",
      errorSchritt6Wunsch5: "",
      errorSchritt6Wunsch6: "",
      errorSchritt6Wunsch7: "",
      errorSchritt6Wunsch8: "",
      errorSchritt6Wunsch9: "",
      errorSchritt6Wunsch10: "",
      errorSchritt6Wunsch11: "",
      errorSchritt6Wunsch12: "",
      errorSchritt6Wunsch13: "",
      errorSchritt6Wunsch14: "",
      errorSchritt6Wunsch15: "",
      errorSchritt6Wunsch16: "",
      errorSchritt6Wunsch17: "",
      errorSchritt6Wunsch18: "",
      errorSchritt6Wunsch19: "",
      errorSchritt6Wunsch20: "",
      errorSchritt6: "",
      errorSchritt7Verbot1: "",
      errorSchritt7Verbot2: "",
      errorSchritt7Verbot3: "",
      errorSchritt7Verbot4: "",
      errorSchritt7Verbot5: "",
      errorSchritt7Verbot6: "",
      errorSchritt7Verbot7: "",
      errorSchritt7Verbot8: "",
      errorSchritt7Verbot9: "",
      errorSchritt7Verbot10: "",
      errorSchritt7Verbot11: "",
      errorSchritt7Verbot12: "",
      errorSchritt7Verbot13: "",
      errorSchritt7Verbot14: "",
      errorSchritt7Verbot15: "",
      errorSchritt7Verbot16: "",
      errorSchritt7Verbot17: "",
      errorSchritt7Verbot18: "",
      errorSchritt7Verbot19: "",
      errorSchritt7Verbot20: "",
      errorSchritt7: "",
      schritt: 1,
      trainingsauswahl: "0",
      zeit1: "13:00-14:00",
      zeit2: "14:00-15:00",
      zeit3: "15:00-16:00",
      zeit4: "16:00-17:00",
      zeit5: "17:00-18:00",
      zeit6: "18:00-19:00",
      zeit7: "19:00-20:00",
      zeit8: "20:00-21:00",
      zeit9: "09:00-10:00",
      zeit10: "10:00-11:00",
      zeit11: "11:00-12:00",
      zeit12: "12:00-13:00",
      zeit13: "13:00-14:00",
      zeit14: "14:00-15:00",
      zeit15: "15:00-16:00",
      zeit16: "16:00-17:00",
      matrix11: "0",
      matrix12: "0",
      matrix13: "0",
      matrix14: "0",
      matrix15: "0",
      matrix16: "0",
      matrix21: "0",
      matrix22: "0",
      matrix23: "0",
      matrix24: "0",
      matrix25: "0",
      matrix26: "0",
      matrix31: "0",
      matrix32: "0",
      matrix33: "0",
      matrix34: "0",
      matrix35: "0",
      matrix36: "0",
      matrix41: "0",
      matrix42: "0",
      matrix43: "0",
      matrix44: "0",
      matrix45: "0",
      matrix46: "0",
      matrix51: "0",
      matrix52: "0",
      matrix53: "0",
      matrix54: "0",
      matrix55: "0",
      matrix56: "0",
      matrix61: "0",
      matrix62: "0",
      matrix63: "0",
      matrix64: "0",
      matrix65: "0",
      matrix66: "0",
      matrix71: "0",
      matrix72: "0",
      matrix73: "0",
      matrix74: "0",
      matrix75: "0",
      matrix76: "0",
      matrix81: "0",
      matrix82: "0",
      matrix83: "0",
      matrix84: "0",
      matrix85: "0",
      matrix86: "0",
      setzeAlle: "1",
      topfauswahl: "0",
      trainingseinheiten: "",
      bevorzugteTopfgroesse: 0,
      alternativeTopfgroesse: 0,
      name1: "",
      name2: "",
      name3: "",
      name4: "",
      name5: "",
      name6: "",
      name7: "",
      name8: "",
      zeit111: "X",
      zeit112: "X",
      zeit113: "X",
      zeit114: "X",
      zeit115: "X",
      zeit116: "X",
      zeit121: "X",
      zeit122: "X",
      zeit123: "X",
      zeit124: "X",
      zeit125: "X",
      zeit126: "X",
      zeit131: "X",
      zeit132: "X",
      zeit133: "X",
      zeit134: "X",
      zeit135: "X",
      zeit136: "X",
      zeit141: "X",
      zeit142: "X",
      zeit143: "X",
      zeit144: "X",
      zeit145: "X",
      zeit146: "X",
      zeit151: "X",
      zeit152: "X",
      zeit153: "X",
      zeit154: "X",
      zeit155: "X",
      zeit156: "X",
      zeit161: "X",
      zeit162: "X",
      zeit163: "X",
      zeit164: "X",
      zeit165: "X",
      zeit166: "X",
      zeit171: "X",
      zeit172: "X",
      zeit173: "X",
      zeit174: "X",
      zeit175: "X",
      zeit176: "X",
      zeit181: "X",
      zeit182: "X",
      zeit183: "X",
      zeit184: "X",
      zeit185: "X",
      zeit186: "X",
      zeit211: "X",
      zeit212: "X",
      zeit213: "X",
      zeit214: "X",
      zeit215: "X",
      zeit216: "X",
      zeit221: "X",
      zeit222: "X",
      zeit223: "X",
      zeit224: "X",
      zeit225: "X",
      zeit226: "X",
      zeit231: "X",
      zeit232: "X",
      zeit233: "X",
      zeit234: "X",
      zeit235: "X",
      zeit236: "X",
      zeit241: "X",
      zeit242: "X",
      zeit243: "X",
      zeit244: "X",
      zeit245: "X",
      zeit246: "X",
      zeit251: "X",
      zeit252: "X",
      zeit253: "X",
      zeit254: "X",
      zeit255: "X",
      zeit256: "X",
      zeit261: "X",
      zeit262: "X",
      zeit263: "X",
      zeit264: "X",
      zeit265: "X",
      zeit266: "X",
      zeit271: "X",
      zeit272: "X",
      zeit273: "X",
      zeit274: "X",
      zeit275: "X",
      zeit276: "X",
      zeit281: "X",
      zeit282: "X",
      zeit283: "X",
      zeit284: "X",
      zeit285: "X",
      zeit286: "X",
      zeit311: "X",
      zeit312: "X",
      zeit313: "X",
      zeit314: "X",
      zeit315: "X",
      zeit316: "X",
      zeit321: "X",
      zeit322: "X",
      zeit323: "X",
      zeit324: "X",
      zeit325: "X",
      zeit326: "X",
      zeit331: "X",
      zeit332: "X",
      zeit333: "X",
      zeit334: "X",
      zeit335: "X",
      zeit336: "X",
      zeit341: "X",
      zeit342: "X",
      zeit343: "X",
      zeit344: "X",
      zeit345: "X",
      zeit346: "X",
      zeit351: "X",
      zeit352: "X",
      zeit353: "X",
      zeit354: "X",
      zeit355: "X",
      zeit356: "X",
      zeit361: "X",
      zeit362: "X",
      zeit363: "X",
      zeit364: "X",
      zeit365: "X",
      zeit366: "X",
      zeit371: "X",
      zeit372: "X",
      zeit373: "X",
      zeit374: "X",
      zeit375: "X",
      zeit376: "X",
      zeit381: "X",
      zeit382: "X",
      zeit383: "X",
      zeit384: "X",
      zeit385: "X",
      zeit386: "X",
      zeit411: "X",
      zeit412: "X",
      zeit413: "X",
      zeit414: "X",
      zeit415: "X",
      zeit416: "X",
      zeit421: "X",
      zeit422: "X",
      zeit423: "X",
      zeit424: "X",
      zeit425: "X",
      zeit426: "X",
      zeit431: "X",
      zeit432: "X",
      zeit433: "X",
      zeit434: "X",
      zeit435: "X",
      zeit436: "X",
      zeit441: "X",
      zeit442: "X",
      zeit443: "X",
      zeit444: "X",
      zeit445: "X",
      zeit446: "X",
      zeit451: "X",
      zeit452: "X",
      zeit453: "X",
      zeit454: "X",
      zeit455: "X",
      zeit456: "X",
      zeit461: "X",
      zeit462: "X",
      zeit463: "X",
      zeit464: "X",
      zeit465: "X",
      zeit466: "X",
      zeit471: "X",
      zeit472: "X",
      zeit473: "X",
      zeit474: "X",
      zeit475: "X",
      zeit476: "X",
      zeit481: "X",
      zeit482: "X",
      zeit483: "X",
      zeit484: "X",
      zeit485: "X",
      zeit486: "X",
      zeit511: "X",
      zeit512: "X",
      zeit513: "X",
      zeit514: "X",
      zeit515: "X",
      zeit516: "X",
      zeit521: "X",
      zeit522: "X",
      zeit523: "X",
      zeit524: "X",
      zeit525: "X",
      zeit526: "X",
      zeit531: "X",
      zeit532: "X",
      zeit533: "X",
      zeit534: "X",
      zeit535: "X",
      zeit536: "X",
      zeit541: "X",
      zeit542: "X",
      zeit543: "X",
      zeit544: "X",
      zeit545: "X",
      zeit546: "X",
      zeit551: "X",
      zeit552: "X",
      zeit553: "X",
      zeit554: "X",
      zeit555: "X",
      zeit556: "X",
      zeit561: "X",
      zeit562: "X",
      zeit563: "X",
      zeit564: "X",
      zeit565: "X",
      zeit566: "X",
      zeit571: "X",
      zeit572: "X",
      zeit573: "X",
      zeit574: "X",
      zeit575: "X",
      zeit576: "X",
      zeit581: "X",
      zeit582: "X",
      zeit583: "X",
      zeit584: "X",
      zeit585: "X",
      zeit586: "X",
      zeit611: "X",
      zeit612: "X",
      zeit613: "X",
      zeit614: "X",
      zeit615: "X",
      zeit616: "X",
      zeit621: "X",
      zeit622: "X",
      zeit623: "X",
      zeit624: "X",
      zeit625: "X",
      zeit626: "X",
      zeit631: "X",
      zeit632: "X",
      zeit633: "X",
      zeit634: "X",
      zeit635: "X",
      zeit636: "X",
      zeit641: "X",
      zeit642: "X",
      zeit643: "X",
      zeit644: "X",
      zeit645: "X",
      zeit646: "X",
      zeit651: "X",
      zeit652: "X",
      zeit653: "X",
      zeit654: "X",
      zeit655: "X",
      zeit656: "X",
      zeit661: "X",
      zeit662: "X",
      zeit663: "X",
      zeit664: "X",
      zeit665: "X",
      zeit666: "X",
      zeit671: "X",
      zeit672: "X",
      zeit673: "X",
      zeit674: "X",
      zeit675: "X",
      zeit676: "X",
      zeit681: "X",
      zeit682: "X",
      zeit683: "X",
      zeit684: "X",
      zeit685: "X",
      zeit686: "X",
      zeit711: "X",
      zeit712: "X",
      zeit713: "X",
      zeit714: "X",
      zeit715: "X",
      zeit716: "X",
      zeit721: "X",
      zeit722: "X",
      zeit723: "X",
      zeit724: "X",
      zeit725: "X",
      zeit726: "X",
      zeit731: "X",
      zeit732: "X",
      zeit733: "X",
      zeit734: "X",
      zeit735: "X",
      zeit736: "X",
      zeit741: "X",
      zeit742: "X",
      zeit743: "X",
      zeit744: "X",
      zeit745: "X",
      zeit746: "X",
      zeit751: "X",
      zeit752: "X",
      zeit753: "X",
      zeit754: "X",
      zeit755: "X",
      zeit756: "X",
      zeit761: "X",
      zeit762: "X",
      zeit763: "X",
      zeit764: "X",
      zeit765: "X",
      zeit766: "X",
      zeit771: "X",
      zeit772: "X",
      zeit773: "X",
      zeit774: "X",
      zeit775: "X",
      zeit776: "X",
      zeit781: "X",
      zeit782: "X",
      zeit783: "X",
      zeit784: "X",
      zeit785: "X",
      zeit786: "X",
      zeit811: "X",
      zeit812: "X",
      zeit813: "X",
      zeit814: "X",
      zeit815: "X",
      zeit816: "X",
      zeit821: "X",
      zeit822: "X",
      zeit823: "X",
      zeit824: "X",
      zeit825: "X",
      zeit826: "X",
      zeit831: "X",
      zeit832: "X",
      zeit833: "X",
      zeit834: "X",
      zeit835: "X",
      zeit836: "X",
      zeit841: "X",
      zeit842: "X",
      zeit843: "X",
      zeit844: "X",
      zeit845: "X",
      zeit846: "X",
      zeit851: "X",
      zeit852: "X",
      zeit853: "X",
      zeit854: "X",
      zeit855: "X",
      zeit856: "X",
      zeit861: "X",
      zeit862: "X",
      zeit863: "X",
      zeit864: "X",
      zeit865: "X",
      zeit866: "X",
      zeit871: "X",
      zeit872: "X",
      zeit873: "X",
      zeit874: "X",
      zeit875: "X",
      zeit876: "X",
      zeit881: "X",
      zeit882: "X",
      zeit883: "X",
      zeit884: "X",
      zeit885: "X",
      zeit886: "X",
      wunsch1: "",
      wunsch2: "",
      wunsch3: "",
      wunsch4: "",
      wunsch5: "",
      wunsch6: "",
      wunsch7: "",
      wunsch8: "",
      wunsch9: "",
      wunsch10: "",
      wunsch11: "",
      wunsch12: "",
      wunsch13: "",
      wunsch14: "",
      wunsch15: "",
      wunsch16: "",
      wunsch17: "",
      wunsch18: "",
      wunsch19: "",
      wunsch20: "",
      wunsch21: "",
      wunsch22: "",
      wunsch23: "",
      wunsch24: "",
      wunsch25: "",
      wunsch26: "",
      wunsch27: "",
      wunsch28: "",
      wunsch29: "",
      wunsch30: "",
      wunsch31: "",
      wunsch32: "",
      wunsch33: "",
      wunsch34: "",
      wunsch35: "",
      wunsch36: "",
      wunsch37: "",
      wunsch38: "",
      wunsch39: "",
      wunsch40: "",
      wunsch41: "",
      wunsch42: "",
      wunsch43: "",
      wunsch44: "",
      wunsch45: "",
      wunsch46: "",
      wunsch47: "",
      wunsch48: "",
      wunsch49: "",
      wunsch50: "",
      wunsch51: "",
      wunsch52: "",
      wunsch53: "",
      wunsch54: "",
      wunsch55: "",
      wunsch56: "",
      wunsch57: "",
      wunsch58: "",
      wunsch59: "",
      wunsch60: "",
      wunsch61: "",
      wunsch62: "",
      wunsch63: "",
      wunsch64: "",
      wunsch65: "",
      wunsch66: "",
      wunsch67: "",
      wunsch68: "",
      wunsch69: "",
      wunsch70: "",
      wunsch71: "",
      wunsch72: "",
      wunsch73: "",
      wunsch74: "",
      wunsch75: "",
      wunsch76: "",
      wunsch77: "",
      wunsch78: "",
      wunsch79: "",
      wunsch80: "",
      verbot1: "",
      verbot2: "",
      verbot3: "",
      verbot4: "",
      verbot5: "",
      verbot6: "",
      verbot7: "",
      verbot8: "",
      verbot9: "",
      verbot10: "",
      verbot11: "",
      verbot12: "",
      verbot13: "",
      verbot14: "",
      verbot15: "",
      verbot16: "",
      verbot17: "",
      verbot18: "",
      verbot19: "",
      verbot20: "",
      verbot21: "",
      verbot22: "",
      verbot23: "",
      verbot24: "",
      verbot25: "",
      verbot26: "",
      verbot27: "",
      verbot28: "",
      verbot29: "",
      verbot30: "",
      verbot31: "",
      verbot32: "",
      verbot33: "",
      verbot34: "",
      verbot35: "",
      verbot36: "",
      verbot37: "",
      verbot38: "",
      verbot39: "",
      verbot40: "",
      messageget: "",
    };
    this.handleChangeTrainingsauswahl = this.handleChangeTrainingsauswahl.bind(
      this
    );

    this.auswahlTopf = this.auswahlTopf.bind(this);
    this.alternativeGruppengroesse = this.alternativeGruppengroesse.bind(this);
    this.bevorzugteGruppengroesse = this.bevorzugteGruppengroesse.bind(this);
  }

  componentDidMount() {
    axios
      .get("/api/vordemo")
      .then((res) => {
        const messageget = res.data;
        this.setState({ messageget });
      })
      .catch((error) => {
        console.log(error.messageget);
      });
  }

  /*
  Validate
  */

  validateSchritt1 = () => {
    let errorSchritt1 = "";
    if (this.state.trainingsauswahl !== "8") {
      errorSchritt1 =
        "In der Demo müssen Sie 8 Anmeldungen auswählen.";
    }
    if (errorSchritt1) {
      this.setState({ errorSchritt1 });
      return false;
    }
    this.setState({ errorSchritt1: "" });
    return true;
  };

  validateSchritt2 = () => {
    let errorSchritt2Uhrzeit = "";
    let errorSchritt2Platz = "";
    if (
      this.state.zeit1.length > 16 ||
      this.state.zeit2.length > 16 ||
      this.state.zeit3.length > 16 ||
      this.state.zeit4.length > 16 ||
      this.state.zeit5.length > 16 ||
      this.state.zeit6.length > 16 ||
      this.state.zeit7.length > 16 ||
      this.state.zeit8.length > 16 ||
      this.state.zeit9.length > 16 ||
      this.state.zeit10.length > 16 ||
      this.state.zeit11.length > 16 ||
      this.state.zeit12.length > 16 ||
      this.state.zeit13.length > 16 ||
      this.state.zeit14.length > 16 ||
      this.state.zeit15.length > 16 ||
      this.state.zeit16.length > 16
    ) {
      errorSchritt2Uhrzeit =
        "Die Eingabe einer Uhrzeit darf nicht länger als 16 Zeichen sein.";
    }
    if (
      this.state.matrix11 === "0" &&
      this.state.matrix12 === "0" &&
      this.state.matrix13 === "0" &&
      this.state.matrix14 === "0" &&
      this.state.matrix15 === "0" &&
      this.state.matrix16 === "0" &&
      this.state.matrix21 === "0" &&
      this.state.matrix22 === "0" &&
      this.state.matrix23 === "0" &&
      this.state.matrix24 === "0" &&
      this.state.matrix25 === "0" &&
      this.state.matrix26 === "0" &&
      this.state.matrix31 === "0" &&
      this.state.matrix32 === "0" &&
      this.state.matrix33 === "0" &&
      this.state.matrix34 === "0" &&
      this.state.matrix35 === "0" &&
      this.state.matrix36 === "0" &&
      this.state.matrix41 === "0" &&
      this.state.matrix42 === "0" &&
      this.state.matrix43 === "0" &&
      this.state.matrix44 === "0" &&
      this.state.matrix45 === "0" &&
      this.state.matrix46 === "0" &&
      this.state.matrix51 === "0" &&
      this.state.matrix52 === "0" &&
      this.state.matrix53 === "0" &&
      this.state.matrix54 === "0" &&
      this.state.matrix55 === "0" &&
      this.state.matrix56 === "0" &&
      this.state.matrix61 === "0" &&
      this.state.matrix62 === "0" &&
      this.state.matrix63 === "0" &&
      this.state.matrix64 === "0" &&
      this.state.matrix65 === "0" &&
      this.state.matrix66 === "0" &&
      this.state.matrix71 === "0" &&
      this.state.matrix72 === "0" &&
      this.state.matrix73 === "0" &&
      this.state.matrix74 === "0" &&
      this.state.matrix75 === "0" &&
      this.state.matrix76 === "0" &&
      this.state.matrix81 === "0" &&
      this.state.matrix82 === "0" &&
      this.state.matrix83 === "0" &&
      this.state.matrix84 === "0" &&
      this.state.matrix85 === "0" &&
      this.state.matrix86 === "0"
    ) {
      errorSchritt2Platz =
        "Es kann nicht sein, dass keine Plätze zur Verfügung stehen";
    }
    if (errorSchritt2Uhrzeit || errorSchritt2Platz) {
      this.setState({ errorSchritt2Uhrzeit });
      this.setState({ errorSchritt2Platz });
      return false;
    }
    this.setState({ errorSchritt2Uhrzeit: "" });
    this.setState({ errorSchritt2Platz: "" });
    return true;
  };

  validateSchritt3 = () => {
    let errorSchritt3 = "";
    if (this.state.topfauswahl === "0") {
      errorSchritt3 = "Wählen Sie mindestens einen Topf aus.";
    }
    if (errorSchritt3) {
      this.setState({ errorSchritt3 });
      return false;
    }
    this.setState({ errorSchritt3: "" });
    return true;
  };

  validateSchritt4 = () => {
    let errorSchritt4Trainingseinheiten = "";
    let errorSchritt4Bevorzugt = "";
    let errorSchritt4Alternativ = "";

    if (this.state.trainingseinheiten !== "8") {
      errorSchritt4Trainingseinheiten =
        "Verwenden Sie alle 8 Anmeldungen.";
    }
    if (this.state.bevorzugteTopfgroesse !== "4") {
      errorSchritt4Bevorzugt =
        "In der Demo muss hier eine 4 stehen.";
    }
    if (this.state.alternativeTopfgroesse !== "5") {
      errorSchritt4Alternativ =
        "In der Demo muss hier eine 5 stehen.";
    }
    if (
      errorSchritt4Trainingseinheiten ||
      errorSchritt4Bevorzugt ||
      errorSchritt4Alternativ
    ) {
      this.setState({
        errorSchritt4Trainingseinheiten,
        errorSchritt4Bevorzugt,
        errorSchritt4Alternativ,
      });
      return false;
    }
    this.setState({
      errorSchritt4Trainingseinheiten: "",
      errorSchritt4Bevorzugt: "",
      errorSchritt4Alternativ: "",
    });
    return true;
  };

  validateSchritt5 = () => {
    let errorSchritt5 = "";
    let errorSchritt5gleich = "";
    let errorSchritt5Teilnehmer1Name = "";
    let errorSchritt5Teilnehmer1Zeit = "";
    let errorSchritt5Teilnehmer2Name = "";
    let errorSchritt5Teilnehmer2Zeit = "";
    let errorSchritt5Teilnehmer3Name = "";
    let errorSchritt5Teilnehmer3Zeit = "";
    let errorSchritt5Teilnehmer4Name = "";
    let errorSchritt5Teilnehmer4Zeit = "";
    let errorSchritt5Teilnehmer5Name = "";
    let errorSchritt5Teilnehmer5Zeit = "";
    let errorSchritt5Teilnehmer6Name = "";
    let errorSchritt5Teilnehmer6Zeit = "";
    let errorSchritt5Teilnehmer7Name = "";
    let errorSchritt5Teilnehmer7Zeit = "";
    let errorSchritt5Teilnehmer8Name = "";
    let errorSchritt5Teilnehmer8Zeit = "";

    if (this.state.name1.length < 1) {
      errorSchritt5Teilnehmer1Name =
        "Name fehlt.";
      errorSchritt5 = "Es fehlen noch Angaben.";
    }
    if (this.state.name2.length < 1) {
      errorSchritt5Teilnehmer2Name =
        "Name fehlt.";
      errorSchritt5 = "Es fehlen noch Angaben.";
    }
    if (this.state.name3.length < 1) {
      errorSchritt5Teilnehmer3Name =
        "Name fehlt.";
      errorSchritt5 = "Es fehlen noch Angaben.";
    }
    if (this.state.name4.length < 1) {
      errorSchritt5Teilnehmer4Name =
        "Name fehlt.";
      errorSchritt5 = "Es fehlen noch Angaben.";
    }
    if (this.state.name5.length < 1) {
      errorSchritt5Teilnehmer5Name =
        "Name fehlt.";
      errorSchritt5 = "Es fehlen noch Angaben.";
    }
    if (this.state.name6.length < 1) {
      errorSchritt5Teilnehmer6Name =
        "Name fehlt.";
      errorSchritt5 = "Es fehlen noch Angaben.";
    }
    if (this.state.name7.length < 1) {
      errorSchritt5Teilnehmer7Name =
        "Name fehlt.";
      errorSchritt5 = "Es fehlen noch Angaben.";
    }
    if (this.state.name8.length < 1) {
      errorSchritt5Teilnehmer8Name =
        "Name fehlt.";
      errorSchritt5 = "Es fehlen noch Angaben.";
    }

    if (
      this.state.name1 === this.state.name2 ||
      this.state.name1 === this.state.name3 ||
      this.state.name1 === this.state.name4 ||
      this.state.name1 === this.state.name5 ||
      this.state.name1 === this.state.name6 ||
      this.state.name1 === this.state.name7 ||
      this.state.name1 === this.state.name8 ||
      this.state.name2 === this.state.name3 ||
      this.state.name2 === this.state.name4 ||
      this.state.name2 === this.state.name5 ||
      this.state.name2 === this.state.name6 ||
      this.state.name2 === this.state.name7 ||
      this.state.name2 === this.state.name8 ||
      this.state.name3 === this.state.name4 ||
      this.state.name3 === this.state.name5 ||
      this.state.name3 === this.state.name6 ||
      this.state.name3 === this.state.name7 ||
      this.state.name3 === this.state.name8 ||
      this.state.name4 === this.state.name5 ||
      this.state.name4 === this.state.name6 ||
      this.state.name4 === this.state.name7 ||
      this.state.name4 === this.state.name8 ||
      this.state.name5 === this.state.name6 ||
      this.state.name5 === this.state.name7 ||
      this.state.name5 === this.state.name8 ||
      this.state.name6 === this.state.name7 ||
      this.state.name6 === this.state.name8 ||
      this.state.name7 === this.state.name8
    ) {
      errorSchritt5gleich =
        "Jeder Name darf nur einmal vorkommen.";
      errorSchritt5 = "Es müssen noch Änderungen vorgenommen werden.";
    }

    if (
      this.state.zeit111 === "X" &&
      this.state.zeit112 === "X" &&
      this.state.zeit113 === "X" &&
      this.state.zeit114 === "X" &&
      this.state.zeit115 === "X" &&
      this.state.zeit116 === "X" &&
      this.state.zeit121 === "X" &&
      this.state.zeit122 === "X" &&
      this.state.zeit123 === "X" &&
      this.state.zeit124 === "X" &&
      this.state.zeit125 === "X" &&
      this.state.zeit126 === "X" &&
      this.state.zeit131 === "X" &&
      this.state.zeit132 === "X" &&
      this.state.zeit133 === "X" &&
      this.state.zeit134 === "X" &&
      this.state.zeit135 === "X" &&
      this.state.zeit136 === "X" &&
      this.state.zeit141 === "X" &&
      this.state.zeit142 === "X" &&
      this.state.zeit143 === "X" &&
      this.state.zeit144 === "X" &&
      this.state.zeit145 === "X" &&
      this.state.zeit146 === "X" &&
      this.state.zeit151 === "X" &&
      this.state.zeit152 === "X" &&
      this.state.zeit153 === "X" &&
      this.state.zeit154 === "X" &&
      this.state.zeit155 === "X" &&
      this.state.zeit156 === "X" &&
      this.state.zeit161 === "X" &&
      this.state.zeit162 === "X" &&
      this.state.zeit163 === "X" &&
      this.state.zeit164 === "X" &&
      this.state.zeit165 === "X" &&
      this.state.zeit166 === "X" &&
      this.state.zeit171 === "X" &&
      this.state.zeit172 === "X" &&
      this.state.zeit173 === "X" &&
      this.state.zeit174 === "X" &&
      this.state.zeit175 === "X" &&
      this.state.zeit176 === "X" &&
      this.state.zeit181 === "X" &&
      this.state.zeit182 === "X" &&
      this.state.zeit183 === "X" &&
      this.state.zeit184 === "X" &&
      this.state.zeit185 === "X" &&
      this.state.zeit186 === "X"
    ) {
      errorSchritt5Teilnehmer1Zeit =
        "Zeiten fehlen.";
      errorSchritt5 = "Es fehlen noch Angaben.";
    }
    if (
      this.state.zeit211 === "X" &&
      this.state.zeit212 === "X" &&
      this.state.zeit213 === "X" &&
      this.state.zeit214 === "X" &&
      this.state.zeit215 === "X" &&
      this.state.zeit216 === "X" &&
      this.state.zeit221 === "X" &&
      this.state.zeit222 === "X" &&
      this.state.zeit223 === "X" &&
      this.state.zeit224 === "X" &&
      this.state.zeit225 === "X" &&
      this.state.zeit226 === "X" &&
      this.state.zeit231 === "X" &&
      this.state.zeit232 === "X" &&
      this.state.zeit233 === "X" &&
      this.state.zeit234 === "X" &&
      this.state.zeit235 === "X" &&
      this.state.zeit236 === "X" &&
      this.state.zeit241 === "X" &&
      this.state.zeit242 === "X" &&
      this.state.zeit243 === "X" &&
      this.state.zeit244 === "X" &&
      this.state.zeit245 === "X" &&
      this.state.zeit246 === "X" &&
      this.state.zeit251 === "X" &&
      this.state.zeit252 === "X" &&
      this.state.zeit253 === "X" &&
      this.state.zeit254 === "X" &&
      this.state.zeit255 === "X" &&
      this.state.zeit256 === "X" &&
      this.state.zeit261 === "X" &&
      this.state.zeit262 === "X" &&
      this.state.zeit263 === "X" &&
      this.state.zeit264 === "X" &&
      this.state.zeit265 === "X" &&
      this.state.zeit266 === "X" &&
      this.state.zeit271 === "X" &&
      this.state.zeit272 === "X" &&
      this.state.zeit273 === "X" &&
      this.state.zeit274 === "X" &&
      this.state.zeit275 === "X" &&
      this.state.zeit276 === "X" &&
      this.state.zeit281 === "X" &&
      this.state.zeit282 === "X" &&
      this.state.zeit283 === "X" &&
      this.state.zeit284 === "X" &&
      this.state.zeit285 === "X" &&
      this.state.zeit286 === "X"
    ) {
      errorSchritt5Teilnehmer2Zeit =
        "Zeiten fehlen.";
      errorSchritt5 = "Es fehlen noch Angaben.";
    }
    if (
      this.state.zeit311 === "X" &&
      this.state.zeit312 === "X" &&
      this.state.zeit313 === "X" &&
      this.state.zeit314 === "X" &&
      this.state.zeit315 === "X" &&
      this.state.zeit316 === "X" &&
      this.state.zeit321 === "X" &&
      this.state.zeit322 === "X" &&
      this.state.zeit323 === "X" &&
      this.state.zeit324 === "X" &&
      this.state.zeit325 === "X" &&
      this.state.zeit326 === "X" &&
      this.state.zeit331 === "X" &&
      this.state.zeit332 === "X" &&
      this.state.zeit333 === "X" &&
      this.state.zeit334 === "X" &&
      this.state.zeit335 === "X" &&
      this.state.zeit336 === "X" &&
      this.state.zeit341 === "X" &&
      this.state.zeit342 === "X" &&
      this.state.zeit343 === "X" &&
      this.state.zeit344 === "X" &&
      this.state.zeit345 === "X" &&
      this.state.zeit346 === "X" &&
      this.state.zeit351 === "X" &&
      this.state.zeit352 === "X" &&
      this.state.zeit353 === "X" &&
      this.state.zeit354 === "X" &&
      this.state.zeit355 === "X" &&
      this.state.zeit356 === "X" &&
      this.state.zeit361 === "X" &&
      this.state.zeit362 === "X" &&
      this.state.zeit363 === "X" &&
      this.state.zeit364 === "X" &&
      this.state.zeit365 === "X" &&
      this.state.zeit366 === "X" &&
      this.state.zeit371 === "X" &&
      this.state.zeit372 === "X" &&
      this.state.zeit373 === "X" &&
      this.state.zeit374 === "X" &&
      this.state.zeit375 === "X" &&
      this.state.zeit376 === "X" &&
      this.state.zeit381 === "X" &&
      this.state.zeit382 === "X" &&
      this.state.zeit383 === "X" &&
      this.state.zeit384 === "X" &&
      this.state.zeit385 === "X" &&
      this.state.zeit386 === "X"
    ) {
      errorSchritt5Teilnehmer3Zeit =
        "Zeiten fehlen.";
      errorSchritt5 = "Es fehlen noch Angaben.";
    }
    if (
      this.state.zeit411 === "X" &&
      this.state.zeit412 === "X" &&
      this.state.zeit413 === "X" &&
      this.state.zeit414 === "X" &&
      this.state.zeit415 === "X" &&
      this.state.zeit416 === "X" &&
      this.state.zeit421 === "X" &&
      this.state.zeit422 === "X" &&
      this.state.zeit423 === "X" &&
      this.state.zeit424 === "X" &&
      this.state.zeit425 === "X" &&
      this.state.zeit426 === "X" &&
      this.state.zeit431 === "X" &&
      this.state.zeit432 === "X" &&
      this.state.zeit433 === "X" &&
      this.state.zeit434 === "X" &&
      this.state.zeit435 === "X" &&
      this.state.zeit436 === "X" &&
      this.state.zeit441 === "X" &&
      this.state.zeit442 === "X" &&
      this.state.zeit443 === "X" &&
      this.state.zeit444 === "X" &&
      this.state.zeit445 === "X" &&
      this.state.zeit446 === "X" &&
      this.state.zeit451 === "X" &&
      this.state.zeit452 === "X" &&
      this.state.zeit453 === "X" &&
      this.state.zeit454 === "X" &&
      this.state.zeit455 === "X" &&
      this.state.zeit456 === "X" &&
      this.state.zeit461 === "X" &&
      this.state.zeit462 === "X" &&
      this.state.zeit463 === "X" &&
      this.state.zeit464 === "X" &&
      this.state.zeit465 === "X" &&
      this.state.zeit466 === "X" &&
      this.state.zeit471 === "X" &&
      this.state.zeit472 === "X" &&
      this.state.zeit473 === "X" &&
      this.state.zeit474 === "X" &&
      this.state.zeit475 === "X" &&
      this.state.zeit476 === "X" &&
      this.state.zeit481 === "X" &&
      this.state.zeit482 === "X" &&
      this.state.zeit483 === "X" &&
      this.state.zeit484 === "X" &&
      this.state.zeit485 === "X" &&
      this.state.zeit486 === "X"
    ) {
      errorSchritt5Teilnehmer4Zeit =
        "Zeiten fehlen.";
      errorSchritt5 = "Es fehlen noch Angaben.";
    }
    if (
      this.state.zeit511 === "X" &&
      this.state.zeit512 === "X" &&
      this.state.zeit513 === "X" &&
      this.state.zeit514 === "X" &&
      this.state.zeit515 === "X" &&
      this.state.zeit516 === "X" &&
      this.state.zeit521 === "X" &&
      this.state.zeit522 === "X" &&
      this.state.zeit523 === "X" &&
      this.state.zeit524 === "X" &&
      this.state.zeit525 === "X" &&
      this.state.zeit526 === "X" &&
      this.state.zeit531 === "X" &&
      this.state.zeit532 === "X" &&
      this.state.zeit533 === "X" &&
      this.state.zeit534 === "X" &&
      this.state.zeit535 === "X" &&
      this.state.zeit536 === "X" &&
      this.state.zeit541 === "X" &&
      this.state.zeit542 === "X" &&
      this.state.zeit543 === "X" &&
      this.state.zeit544 === "X" &&
      this.state.zeit545 === "X" &&
      this.state.zeit546 === "X" &&
      this.state.zeit551 === "X" &&
      this.state.zeit552 === "X" &&
      this.state.zeit553 === "X" &&
      this.state.zeit554 === "X" &&
      this.state.zeit555 === "X" &&
      this.state.zeit556 === "X" &&
      this.state.zeit561 === "X" &&
      this.state.zeit562 === "X" &&
      this.state.zeit563 === "X" &&
      this.state.zeit564 === "X" &&
      this.state.zeit565 === "X" &&
      this.state.zeit566 === "X" &&
      this.state.zeit571 === "X" &&
      this.state.zeit572 === "X" &&
      this.state.zeit573 === "X" &&
      this.state.zeit574 === "X" &&
      this.state.zeit575 === "X" &&
      this.state.zeit576 === "X" &&
      this.state.zeit581 === "X" &&
      this.state.zeit582 === "X" &&
      this.state.zeit583 === "X" &&
      this.state.zeit584 === "X" &&
      this.state.zeit585 === "X" &&
      this.state.zeit586 === "X"
    ) {
      errorSchritt5Teilnehmer5Zeit =
        "Zeiten fehlen.";
      errorSchritt5 = "Es fehlen noch Angaben.";
    }
    if (
      this.state.zeit611 === "X" &&
      this.state.zeit612 === "X" &&
      this.state.zeit613 === "X" &&
      this.state.zeit614 === "X" &&
      this.state.zeit615 === "X" &&
      this.state.zeit616 === "X" &&
      this.state.zeit621 === "X" &&
      this.state.zeit622 === "X" &&
      this.state.zeit623 === "X" &&
      this.state.zeit624 === "X" &&
      this.state.zeit625 === "X" &&
      this.state.zeit626 === "X" &&
      this.state.zeit631 === "X" &&
      this.state.zeit632 === "X" &&
      this.state.zeit633 === "X" &&
      this.state.zeit634 === "X" &&
      this.state.zeit635 === "X" &&
      this.state.zeit636 === "X" &&
      this.state.zeit641 === "X" &&
      this.state.zeit642 === "X" &&
      this.state.zeit643 === "X" &&
      this.state.zeit644 === "X" &&
      this.state.zeit645 === "X" &&
      this.state.zeit646 === "X" &&
      this.state.zeit651 === "X" &&
      this.state.zeit652 === "X" &&
      this.state.zeit653 === "X" &&
      this.state.zeit654 === "X" &&
      this.state.zeit655 === "X" &&
      this.state.zeit656 === "X" &&
      this.state.zeit661 === "X" &&
      this.state.zeit662 === "X" &&
      this.state.zeit663 === "X" &&
      this.state.zeit664 === "X" &&
      this.state.zeit665 === "X" &&
      this.state.zeit666 === "X" &&
      this.state.zeit671 === "X" &&
      this.state.zeit672 === "X" &&
      this.state.zeit673 === "X" &&
      this.state.zeit674 === "X" &&
      this.state.zeit675 === "X" &&
      this.state.zeit676 === "X" &&
      this.state.zeit681 === "X" &&
      this.state.zeit682 === "X" &&
      this.state.zeit683 === "X" &&
      this.state.zeit684 === "X" &&
      this.state.zeit685 === "X" &&
      this.state.zeit686 === "X"
    ) {
      errorSchritt5Teilnehmer6Zeit =
        "Zeiten fehlen.";
      errorSchritt5 = "Es fehlen noch Angaben.";
    }
    if (
      this.state.zeit711 === "X" &&
      this.state.zeit712 === "X" &&
      this.state.zeit713 === "X" &&
      this.state.zeit714 === "X" &&
      this.state.zeit715 === "X" &&
      this.state.zeit716 === "X" &&
      this.state.zeit721 === "X" &&
      this.state.zeit722 === "X" &&
      this.state.zeit723 === "X" &&
      this.state.zeit724 === "X" &&
      this.state.zeit725 === "X" &&
      this.state.zeit726 === "X" &&
      this.state.zeit731 === "X" &&
      this.state.zeit732 === "X" &&
      this.state.zeit733 === "X" &&
      this.state.zeit734 === "X" &&
      this.state.zeit735 === "X" &&
      this.state.zeit736 === "X" &&
      this.state.zeit741 === "X" &&
      this.state.zeit742 === "X" &&
      this.state.zeit743 === "X" &&
      this.state.zeit744 === "X" &&
      this.state.zeit745 === "X" &&
      this.state.zeit746 === "X" &&
      this.state.zeit751 === "X" &&
      this.state.zeit752 === "X" &&
      this.state.zeit753 === "X" &&
      this.state.zeit754 === "X" &&
      this.state.zeit755 === "X" &&
      this.state.zeit756 === "X" &&
      this.state.zeit761 === "X" &&
      this.state.zeit762 === "X" &&
      this.state.zeit763 === "X" &&
      this.state.zeit764 === "X" &&
      this.state.zeit765 === "X" &&
      this.state.zeit766 === "X" &&
      this.state.zeit771 === "X" &&
      this.state.zeit772 === "X" &&
      this.state.zeit773 === "X" &&
      this.state.zeit774 === "X" &&
      this.state.zeit775 === "X" &&
      this.state.zeit776 === "X" &&
      this.state.zeit781 === "X" &&
      this.state.zeit782 === "X" &&
      this.state.zeit783 === "X" &&
      this.state.zeit784 === "X" &&
      this.state.zeit785 === "X" &&
      this.state.zeit786 === "X"
    ) {
      errorSchritt5Teilnehmer7Zeit =
        "Zeiten fehlen.";
      errorSchritt5 = "Es fehlen noch Angaben.";
    }
    if (
      this.state.zeit811 === "X" &&
      this.state.zeit812 === "X" &&
      this.state.zeit813 === "X" &&
      this.state.zeit814 === "X" &&
      this.state.zeit815 === "X" &&
      this.state.zeit816 === "X" &&
      this.state.zeit821 === "X" &&
      this.state.zeit822 === "X" &&
      this.state.zeit823 === "X" &&
      this.state.zeit824 === "X" &&
      this.state.zeit825 === "X" &&
      this.state.zeit826 === "X" &&
      this.state.zeit831 === "X" &&
      this.state.zeit832 === "X" &&
      this.state.zeit833 === "X" &&
      this.state.zeit834 === "X" &&
      this.state.zeit835 === "X" &&
      this.state.zeit836 === "X" &&
      this.state.zeit841 === "X" &&
      this.state.zeit842 === "X" &&
      this.state.zeit843 === "X" &&
      this.state.zeit844 === "X" &&
      this.state.zeit845 === "X" &&
      this.state.zeit846 === "X" &&
      this.state.zeit851 === "X" &&
      this.state.zeit852 === "X" &&
      this.state.zeit853 === "X" &&
      this.state.zeit854 === "X" &&
      this.state.zeit855 === "X" &&
      this.state.zeit856 === "X" &&
      this.state.zeit861 === "X" &&
      this.state.zeit862 === "X" &&
      this.state.zeit863 === "X" &&
      this.state.zeit864 === "X" &&
      this.state.zeit865 === "X" &&
      this.state.zeit866 === "X" &&
      this.state.zeit871 === "X" &&
      this.state.zeit872 === "X" &&
      this.state.zeit873 === "X" &&
      this.state.zeit874 === "X" &&
      this.state.zeit875 === "X" &&
      this.state.zeit876 === "X" &&
      this.state.zeit881 === "X" &&
      this.state.zeit882 === "X" &&
      this.state.zeit883 === "X" &&
      this.state.zeit884 === "X" &&
      this.state.zeit885 === "X" &&
      this.state.zeit886 === "X"
    ) {
      errorSchritt5Teilnehmer8Zeit =
        "Zeiten fehlen.";
      errorSchritt5 = "Es fehlen noch Angaben.";
    }

    if (
      errorSchritt5gleich ||
      errorSchritt5Teilnehmer1Name ||
      errorSchritt5Teilnehmer1Zeit ||
      errorSchritt5Teilnehmer2Name ||
      errorSchritt5Teilnehmer2Zeit ||
      errorSchritt5Teilnehmer3Name ||
      errorSchritt5Teilnehmer3Zeit ||
      errorSchritt5Teilnehmer4Name ||
      errorSchritt5Teilnehmer4Zeit ||
      errorSchritt5Teilnehmer5Name ||
      errorSchritt5Teilnehmer5Zeit ||
      errorSchritt5Teilnehmer6Name ||
      errorSchritt5Teilnehmer6Zeit ||
      errorSchritt5Teilnehmer7Name ||
      errorSchritt5Teilnehmer7Zeit ||
      errorSchritt5Teilnehmer8Name ||
      errorSchritt5Teilnehmer8Zeit
    ) {
      this.setState({
        errorSchritt5,
        errorSchritt5gleich,
        errorSchritt5Teilnehmer1Name,
        errorSchritt5Teilnehmer1Zeit,
        errorSchritt5Teilnehmer2Name,
        errorSchritt5Teilnehmer2Zeit,
        errorSchritt5Teilnehmer3Name,
        errorSchritt5Teilnehmer3Zeit,
        errorSchritt5Teilnehmer4Name,
        errorSchritt5Teilnehmer4Zeit,
        errorSchritt5Teilnehmer5Name,
        errorSchritt5Teilnehmer5Zeit,
        errorSchritt5Teilnehmer6Name,
        errorSchritt5Teilnehmer6Zeit,
        errorSchritt5Teilnehmer7Name,
        errorSchritt5Teilnehmer7Zeit,
        errorSchritt5Teilnehmer8Name,
        errorSchritt5Teilnehmer8Zeit,
      });
      return false;
    }
    this.setState({
      errorSchritt5: "",
      errorSchritt5gleich: "",
      errorSchritt5Teilnehmer1Name: "",
      errorSchritt5Teilnehmer1Zeit: "",
      errorSchritt5Teilnehmer2Name: "",
      errorSchritt5Teilnehmer2Zeit: "",
      errorSchritt5Teilnehmer3Name: "",
      errorSchritt5Teilnehmer3Zeit: "",
      errorSchritt5Teilnehmer4Name: "",
      errorSchritt5Teilnehmer4Zeit: "",
      errorSchritt5Teilnehmer5Name: "",
      errorSchritt5Teilnehmer5Zeit: "",
      errorSchritt5Teilnehmer6Name: "",
      errorSchritt5Teilnehmer6Zeit: "",
      errorSchritt5Teilnehmer7Name: "",
      errorSchritt5Teilnehmer7Zeit: "",
      errorSchritt5Teilnehmer8Name: "",
      errorSchritt5Teilnehmer8Zeit: "",
    });
    return true;
  };

  validateSchritt6 = () => {
    let errorSchritt6nurzwei = "";
    let errorSchritt6Wunsch1 = "";
    let errorSchritt6Wunsch2 = "";
    let errorSchritt6Wunsch3 = "";
    let errorSchritt6Wunsch4 = "";
    let errorSchritt6Wunsch5 = "";
    let errorSchritt6Wunsch6 = "";
    let errorSchritt6Wunsch7 = "";
    let errorSchritt6Wunsch8 = "";
    let errorSchritt6Wunsch9 = "";
    let errorSchritt6Wunsch10 = "";
    let errorSchritt6Wunsch11 = "";
    let errorSchritt6Wunsch12 = "";
    let errorSchritt6Wunsch13 = "";
    let errorSchritt6Wunsch14 = "";
    let errorSchritt6Wunsch15 = "";
    let errorSchritt6Wunsch16 = "";
    let errorSchritt6Wunsch17 = "";
    let errorSchritt6Wunsch18 = "";
    let errorSchritt6Wunsch19 = "";
    let errorSchritt6Wunsch20 = "";
    let errorSchritt6 = "";
    let zaehler = [];
    let hilfsarray = this.WuenscheKompatibel();
    for (let i = 0; i < this.state.trainingseinheiten; i++) {
      zaehler.push(0);
    }

    if (this.state.wunsch1 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch1 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch1 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch1 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch1 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch1 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch1 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch1 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch2 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch2 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch2 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch2 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch2 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch2 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch2 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch2 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch3 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch3 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch3 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch3 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch3 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch3 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch3 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch3 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch4 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch4 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch4 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch4 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch4 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch4 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch4 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch4 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch5 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch5 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch5 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch5 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch5 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch5 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch5 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch5 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch6 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch6 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch6 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch6 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch6 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch6 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch6 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch6 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch7 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch7 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch7 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch7 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch7 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch7 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch7 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch7 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch8 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch8 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch8 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch8 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch8 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch8 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch8 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch8 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch9 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch9 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch9 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch9 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch9 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch9 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch9 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch9 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch10 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch10 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch10 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch10 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch10 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch10 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch10 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch10 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch11 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch11 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch11 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch11 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch11 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch11 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch11 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch11 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch12 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch12 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch12 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch12 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch12 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch12 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch12 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch12 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch13 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch13 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch13 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch13 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch13 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch13 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch13 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch13 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch14 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch14 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch14 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch14 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch14 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch14 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch14 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch14 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch15 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch15 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch15 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch15 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch15 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch15 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch15 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch15 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch16 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch16 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch16 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch16 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch16 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch16 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch16 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch16 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch17 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch17 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch17 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch17 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch17 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch17 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch17 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch17 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch18 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch18 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch18 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch18 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch18 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch18 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch18 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch18 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch19 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch19 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch19 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch19 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch19 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch19 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch19 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch19 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch20 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch20 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch20 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch20 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch20 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch20 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch20 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch20 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch21 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch21 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch21 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch21 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch21 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch21 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch21 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch21 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch22 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch22 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch22 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch22 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch22 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch22 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch22 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch22 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch23 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch23 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch23 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch23 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch23 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch23 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch23 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch23 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch24 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch24 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch24 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch24 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch24 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch24 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch24 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch24 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch25 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch25 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch25 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch25 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch25 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch25 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch25 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch25 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch26 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch26 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch26 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch26 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch26 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch26 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch26 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch26 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch27 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch27 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch27 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch27 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch27 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch27 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch27 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch27 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch28 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch28 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch28 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch28 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch28 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch28 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch28 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch28 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch29 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch29 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch29 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch29 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch29 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch29 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch29 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch29 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch30 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch30 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch30 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch30 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch30 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch30 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch30 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch30 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch31 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch31 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch31 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch31 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch31 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch31 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch31 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch31 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch32 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch32 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch32 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch32 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch32 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch32 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch32 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch32 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch33 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch33 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch33 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch33 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch33 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch33 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch33 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch33 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch34 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch34 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch34 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch34 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch34 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch34 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch34 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch34 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch35 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch35 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch35 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch35 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch35 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch35 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch35 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch35 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch36 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch36 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch36 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch36 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch36 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch36 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch36 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch36 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch37 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch37 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch37 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch37 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch37 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch37 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch37 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch37 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch38 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch38 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch38 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch38 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch38 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch38 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch38 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch38 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch39 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch39 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch39 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch39 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch39 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch39 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch39 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch39 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch40 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch40 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch40 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch40 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch40 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch40 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch40 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch40 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch41 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch41 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch41 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch41 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch41 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch41 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch41 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch41 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch42 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch42 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch42 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch42 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch42 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch42 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch42 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch42 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch43 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch43 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch43 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch43 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch43 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch43 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch43 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch43 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch44 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch44 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch44 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch44 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch44 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch44 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch44 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch44 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch45 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch45 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch45 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch45 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch45 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch45 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch45 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch45 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch46 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch46 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch46 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch46 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch46 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch46 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch46 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch46 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch47 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch47 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch47 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch47 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch47 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch47 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch47 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch47 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch48 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch48 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch48 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch48 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch48 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch48 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch48 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch48 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch49 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch49 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch49 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch49 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch49 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch49 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch49 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch49 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch50 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch50 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch50 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch50 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch50 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch50 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch50 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch50 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch51 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch51 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch51 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch51 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch51 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch51 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch51 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch51 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch52 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch52 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch52 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch52 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch52 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch52 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch52 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch52 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch53 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch53 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch53 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch53 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch53 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch53 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch53 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch53 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch54 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch54 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch54 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch54 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch54 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch54 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch54 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch54 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch55 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch55 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch55 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch55 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch55 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch55 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch55 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch55 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch56 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch56 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch56 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch56 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch56 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch56 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch56 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch56 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch57 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch57 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch57 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch57 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch57 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch57 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch57 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch57 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch58 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch58 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch58 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch58 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch58 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch58 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch58 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch58 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch59 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch59 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch59 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch59 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch59 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch59 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch59 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch59 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch60 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch60 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch60 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch60 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch60 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch60 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch60 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch60 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch61 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch61 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch61 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch61 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch61 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch61 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch61 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch61 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch62 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch62 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch62 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch62 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch62 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch62 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch62 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch62 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch63 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch63 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch63 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch63 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch63 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch63 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch63 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch63 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch64 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch64 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch64 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch64 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch64 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch64 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch64 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch64 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch65 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch65 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch65 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch65 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch65 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch65 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch65 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch65 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch66 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch66 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch66 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch66 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch66 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch66 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch66 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch66 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch67 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch67 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch67 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch67 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch67 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch67 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch67 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch67 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch68 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch68 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch68 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch68 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch68 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch68 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch68 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch68 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch69 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch69 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch69 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch69 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch69 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch69 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch69 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch69 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch70 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch70 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch70 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch70 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch70 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch70 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch70 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch70 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch71 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch71 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch71 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch71 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch71 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch71 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch71 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch71 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch72 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch72 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch72 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch72 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch72 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch72 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch72 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch72 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch73 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch73 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch73 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch73 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch73 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch73 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch73 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch73 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch74 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch74 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch74 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch74 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch74 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch74 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch74 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch74 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch75 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch75 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch75 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch75 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch75 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch75 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch75 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch75 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch76 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch76 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch76 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch76 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch76 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch76 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch76 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch76 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch77 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch77 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch77 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch77 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch77 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch77 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch77 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch77 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch78 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch78 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch78 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch78 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch78 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch78 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch78 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch78 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch79 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch79 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch79 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch79 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch79 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch79 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch79 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch79 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    if (this.state.wunsch80 === this.state.name1) {
      zaehler[0] = zaehler[0] + 1;
    }
    if (this.state.wunsch80 === this.state.name2) {
      zaehler[1] = zaehler[1] + 1;
    }
    if (this.state.wunsch80 === this.state.name3) {
      zaehler[2] = zaehler[2] + 1;
    }
    if (this.state.wunsch80 === this.state.name4) {
      zaehler[3] = zaehler[3] + 1;
    }
    if (this.state.wunsch80 === this.state.name5) {
      zaehler[4] = zaehler[4] + 1;
    }
    if (this.state.wunsch80 === this.state.name6) {
      zaehler[5] = zaehler[5] + 1;
    }
    if (this.state.wunsch80 === this.state.name7) {
      zaehler[6] = zaehler[6] + 1;
    }
    if (this.state.wunsch80 === this.state.name8) {
      zaehler[7] = zaehler[7] + 1;
    }
    for (let i = 0; i < zaehler.length; i++) {
      if (zaehler[i] > 1) {
        errorSchritt6nurzwei =
          "Jeder Tennisschüler darf nur in einem Wunsch vorkommen.";
      }
    }

    if (this.state.wunsch1 !== "") {
      if (this.state.wunsch2 === "") {
        errorSchritt6Wunsch1 =
          "Geben Sie in diesem Wunsch mindestens 2 Tennisschüler an.";
        errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
      } else {
        for (let i = 0; i < this.state.trainingseinheiten; i++) {
          if (hilfsarray[i][0] === this.state.wunsch1) {
            if (!hilfsarray[i].includes(this.state.wunsch2)) {
              errorSchritt6Wunsch1 = "Dieser Wunsch ist leider nicht möglich.";
              errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
            }
            if (this.state.wunsch3 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch3)) {
                errorSchritt6Wunsch1 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
            if (this.state.wunsch4 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch4)) {
                errorSchritt6Wunsch1 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
          }
        }
      }
    }
    if (this.state.wunsch5 !== "") {
      if (this.state.wunsch6 === "") {
        errorSchritt6Wunsch2 =
          "Geben Sie in diesem Wunsch mindestens 2 Tennisschüler an.";
        errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
      } else {
        for (let i = 0; i < this.state.trainingseinheiten; i++) {
          if (hilfsarray[i][0] === this.state.wunsch5) {
            if (!hilfsarray[i].includes(this.state.wunsch6)) {
              errorSchritt6Wunsch2 = "Dieser Wunsch ist leider nicht möglich.";
              errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
            }
            if (this.state.wunsch7 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch7)) {
                errorSchritt6Wunsch2 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
            if (this.state.wunsch8 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch8)) {
                errorSchritt6Wunsch2 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
          }
        }
      }
    }
    if (this.state.wunsch9 !== "") {
      if (this.state.wunsch10 === "") {
        errorSchritt6Wunsch3 =
          "Geben Sie in diesem Wunsch mindestens 2 Tennisschüler an.";
        errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
      } else {
        for (let i = 0; i < this.state.trainingseinheiten; i++) {
          if (hilfsarray[i][0] === this.state.wunsch9) {
            if (!hilfsarray[i].includes(this.state.wunsch10)) {
              errorSchritt6Wunsch3 = "Dieser Wunsch ist leider nicht möglich.";
              errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
            }
            if (this.state.wunsch11 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch11)) {
                errorSchritt6Wunsch3 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
            if (this.state.wunsch12 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch12)) {
                errorSchritt6Wunsch3 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
          }
        }
      }
    }
    if (this.state.wunsch13 !== "") {
      if (this.state.wunsch14 === "") {
        errorSchritt6Wunsch4 =
          "Geben Sie in diesem Wunsch mindestens 2 Tennisschüler an.";
        errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
      } else {
        for (let i = 0; i < this.state.trainingseinheiten; i++) {
          if (hilfsarray[i][0] === this.state.wunsch13) {
            if (!hilfsarray[i].includes(this.state.wunsch14)) {
              errorSchritt6Wunsch4 = "Dieser Wunsch ist leider nicht möglich.";
              errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
            }
            if (this.state.wunsch15 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch15)) {
                errorSchritt6Wunsch4 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
            if (this.state.wunsch16 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch16)) {
                errorSchritt6Wunsch4 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
          }
        }
      }
    }
    if (this.state.wunsch17 !== "") {
      if (this.state.wunsch18 === "") {
        errorSchritt6Wunsch5 =
          "Geben Sie in diesem Wunsch mindestens 2 Tennisschüler an.";
        errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
      } else {
        for (let i = 0; i < this.state.trainingseinheiten; i++) {
          if (hilfsarray[i][0] === this.state.wunsch17) {
            if (!hilfsarray[i].includes(this.state.wunsch18)) {
              errorSchritt6Wunsch5 = "Dieser Wunsch ist leider nicht möglich.";
              errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
            }
            if (this.state.wunsch19 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch19)) {
                errorSchritt6Wunsch5 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
            if (this.state.wunsch20 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch20)) {
                errorSchritt6Wunsch5 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
          }
        }
      }
    }
    if (this.state.wunsch21 !== "") {
      if (this.state.wunsch22 === "") {
        errorSchritt6Wunsch6 =
          "Geben Sie in diesem Wunsch mindestens 2 Tennisschüler an.";
        errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
      } else {
        for (let i = 0; i < this.state.trainingseinheiten; i++) {
          if (hilfsarray[i][0] === this.state.wunsch21) {
            if (!hilfsarray[i].includes(this.state.wunsch22)) {
              errorSchritt6Wunsch6 = "Dieser Wunsch ist leider nicht möglich.";
              errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
            }
            if (this.state.wunsch23 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch23)) {
                errorSchritt6Wunsch6 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
            if (this.state.wunsch24 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch24)) {
                errorSchritt6Wunsch6 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
          }
        }
      }
    }
    if (this.state.wunsch25 !== "") {
      if (this.state.wunsch26 === "") {
        errorSchritt6Wunsch7 =
          "Geben Sie in diesem Wunsch mindestens 2 Tennisschüler an.";
        errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
      } else {
        for (let i = 0; i < this.state.trainingseinheiten; i++) {
          if (hilfsarray[i][0] === this.state.wunsch25) {
            if (!hilfsarray[i].includes(this.state.wunsch26)) {
              errorSchritt6Wunsch7 = "Dieser Wunsch ist leider nicht möglich.";
              errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
            }
            if (this.state.wunsch27 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch27)) {
                errorSchritt6Wunsch7 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
            if (this.state.wunsch28 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch28)) {
                errorSchritt6Wunsch7 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
          }
        }
      }
    }
    if (this.state.wunsch29 !== "") {
      if (this.state.wunsch30 === "") {
        errorSchritt6Wunsch8 =
          "Geben Sie in diesem Wunsch mindestens 2 Tennisschüler an.";
        errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
      } else {
        for (let i = 0; i < this.state.trainingseinheiten; i++) {
          if (hilfsarray[i][0] === this.state.wunsch29) {
            if (!hilfsarray[i].includes(this.state.wunsch30)) {
              errorSchritt6Wunsch8 = "Dieser Wunsch ist leider nicht möglich.";
              errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
            }
            if (this.state.wunsch31 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch31)) {
                errorSchritt6Wunsch8 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
            if (this.state.wunsch32 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch32)) {
                errorSchritt6Wunsch8 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
          }
        }
      }
    }
    if (this.state.wunsch33 !== "") {
      if (this.state.wunsch34 === "") {
        errorSchritt6Wunsch9 =
          "Geben Sie in diesem Wunsch mindestens 2 Tennisschüler an.";
        errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
      } else {
        for (let i = 0; i < this.state.trainingseinheiten; i++) {
          if (hilfsarray[i][0] === this.state.wunsch33) {
            if (!hilfsarray[i].includes(this.state.wunsch34)) {
              errorSchritt6Wunsch9 = "Dieser Wunsch ist leider nicht möglich.";
              errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
            }
            if (this.state.wunsch35 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch35)) {
                errorSchritt6Wunsch9 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
            if (this.state.wunsch36 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch36)) {
                errorSchritt6Wunsch9 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
          }
        }
      }
    }
    if (this.state.wunsch37 !== "") {
      if (this.state.wunsch38 === "") {
        errorSchritt6Wunsch10 =
          "Geben Sie in diesem Wunsch mindestens 2 Tennisschüler an.";
        errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
      } else {
        for (let i = 0; i < this.state.trainingseinheiten; i++) {
          if (hilfsarray[i][0] === this.state.wunsch37) {
            if (!hilfsarray[i].includes(this.state.wunsch38)) {
              errorSchritt6Wunsch10 = "Dieser Wunsch ist leider nicht möglich.";
              errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
            }
            if (this.state.wunsch39 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch39)) {
                errorSchritt6Wunsch10 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
            if (this.state.wunsch40 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch40)) {
                errorSchritt6Wunsch10 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
          }
        }
      }
    }
    if (this.state.wunsch41 !== "") {
      if (this.state.wunsch42 === "") {
        errorSchritt6Wunsch11 =
          "Geben Sie in diesem Wunsch mindestens 2 Tennisschüler an.";
        errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
      } else {
        for (let i = 0; i < this.state.trainingseinheiten; i++) {
          if (hilfsarray[i][0] === this.state.wunsch41) {
            if (!hilfsarray[i].includes(this.state.wunsch42)) {
              errorSchritt6Wunsch11 = "Dieser Wunsch ist leider nicht möglich.";
              errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
            }
            if (this.state.wunsch43 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch43)) {
                errorSchritt6Wunsch11 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
            if (this.state.wunsch44 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch44)) {
                errorSchritt6Wunsch11 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
          }
        }
      }
    }
    if (this.state.wunsch45 !== "") {
      if (this.state.wunsch46 === "") {
        errorSchritt6Wunsch12 =
          "Geben Sie in diesem Wunsch mindestens 2 Tennisschüler an.";
        errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
      } else {
        for (let i = 0; i < this.state.trainingseinheiten; i++) {
          if (hilfsarray[i][0] === this.state.wunsch45) {
            if (!hilfsarray[i].includes(this.state.wunsch46)) {
              errorSchritt6Wunsch12 = "Dieser Wunsch ist leider nicht möglich.";
              errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
            }
            if (this.state.wunsch47 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch47)) {
                errorSchritt6Wunsch12 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
            if (this.state.wunsch48 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch48)) {
                errorSchritt6Wunsch12 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
          }
        }
      }
    }
    if (this.state.wunsch49 !== "") {
      if (this.state.wunsch50 === "") {
        errorSchritt6Wunsch13 =
          "Geben Sie in diesem Wunsch mindestens 2 Tennisschüler an.";
        errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
      } else {
        for (let i = 0; i < this.state.trainingseinheiten; i++) {
          if (hilfsarray[i][0] === this.state.wunsch49) {
            if (!hilfsarray[i].includes(this.state.wunsch50)) {
              errorSchritt6Wunsch13 = "Dieser Wunsch ist leider nicht möglich.";
              errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
            }
            if (this.state.wunsch51 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch51)) {
                errorSchritt6Wunsch13 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
            if (this.state.wunsch52 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch52)) {
                errorSchritt6Wunsch13 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
          }
        }
      }
    }
    if (this.state.wunsch53 !== "") {
      if (this.state.wunsch54 === "") {
        errorSchritt6Wunsch14 =
          "Geben Sie in diesem Wunsch mindestens 2 Tennisschüler an.";
        errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
      } else {
        for (let i = 0; i < this.state.trainingseinheiten; i++) {
          if (hilfsarray[i][0] === this.state.wunsch53) {
            if (!hilfsarray[i].includes(this.state.wunsch54)) {
              errorSchritt6Wunsch14 = "Dieser Wunsch ist leider nicht möglich.";
              errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
            }
            if (this.state.wunsch55 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch55)) {
                errorSchritt6Wunsch14 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
            if (this.state.wunsch56 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch56)) {
                errorSchritt6Wunsch14 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
          }
        }
      }
    }
    if (this.state.wunsch57 !== "") {
      if (this.state.wunsch58 === "") {
        errorSchritt6Wunsch15 =
          "Geben Sie in diesem Wunsch mindestens 2 Tennisschüler an.";
        errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
      } else {
        for (let i = 0; i < this.state.trainingseinheiten; i++) {
          if (hilfsarray[i][0] === this.state.wunsch57) {
            if (!hilfsarray[i].includes(this.state.wunsch58)) {
              errorSchritt6Wunsch15 = "Dieser Wunsch ist leider nicht möglich.";
              errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
            }
            if (this.state.wunsch59 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch59)) {
                errorSchritt6Wunsch15 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
            if (this.state.wunsch60 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch60)) {
                errorSchritt6Wunsch15 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
          }
        }
      }
    }
    if (this.state.wunsch61 !== "") {
      if (this.state.wunsch62 === "") {
        errorSchritt6Wunsch16 =
          "Geben Sie in diesem Wunsch mindestens 2 Tennisschüler an.";
        errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
      } else {
        for (let i = 0; i < this.state.trainingseinheiten; i++) {
          if (hilfsarray[i][0] === this.state.wunsch61) {
            if (!hilfsarray[i].includes(this.state.wunsch62)) {
              errorSchritt6Wunsch16 = "Dieser Wunsch ist leider nicht möglich.";
              errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
            }
            if (this.state.wunsch63 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch63)) {
                errorSchritt6Wunsch16 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
            if (this.state.wunsch64 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch64)) {
                errorSchritt6Wunsch16 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
          }
        }
      }
    }
    if (this.state.wunsch65 !== "") {
      if (this.state.wunsch66 === "") {
        errorSchritt6Wunsch17 =
          "Geben Sie in diesem Wunsch mindestens 2 Tennisschüler an.";
        errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
      } else {
        for (let i = 0; i < this.state.trainingseinheiten; i++) {
          if (hilfsarray[i][0] === this.state.wunsch65) {
            if (!hilfsarray[i].includes(this.state.wunsch66)) {
              errorSchritt6Wunsch17 = "Dieser Wunsch ist leider nicht möglich.";
              errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
            }
            if (this.state.wunsch67 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch67)) {
                errorSchritt6Wunsch17 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
            if (this.state.wunsch68 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch68)) {
                errorSchritt6Wunsch17 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
          }
        }
      }
    }
    if (this.state.wunsch69 !== "") {
      if (this.state.wunsch70 === "") {
        errorSchritt6Wunsch18 =
          "Geben Sie in diesem Wunsch mindestens 2 Tennisschüler an.";
        errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
      } else {
        for (let i = 0; i < this.state.trainingseinheiten; i++) {
          if (hilfsarray[i][0] === this.state.wunsch69) {
            if (!hilfsarray[i].includes(this.state.wunsch70)) {
              errorSchritt6Wunsch18 = "Dieser Wunsch ist leider nicht möglich.";
              errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
            }
            if (this.state.wunsch71 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch71)) {
                errorSchritt6Wunsch18 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
            if (this.state.wunsch72 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch72)) {
                errorSchritt6Wunsch18 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
          }
        }
      }
    }
    if (this.state.wunsch73 !== "") {
      if (this.state.wunsch74 === "") {
        errorSchritt6Wunsch19 =
          "Geben Sie in diesem Wunsch mindestens 2 Tennisschüler an.";
        errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
      } else {
        for (let i = 0; i < this.state.trainingseinheiten; i++) {
          if (hilfsarray[i][0] === this.state.wunsch73) {
            if (!hilfsarray[i].includes(this.state.wunsch74)) {
              errorSchritt6Wunsch19 = "Dieser Wunsch ist leider nicht möglich.";
              errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
            }
            if (this.state.wunsch75 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch75)) {
                errorSchritt6Wunsch19 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
            if (this.state.wunsch76 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch76)) {
                errorSchritt6Wunsch19 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
          }
        }
      }
    }
    if (this.state.wunsch77 !== "") {
      if (this.state.wunsch78 === "") {
        errorSchritt6Wunsch20 =
          "Geben Sie in diesem Wunsch mindestens 2 Tennisschüler an.";
        errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
      } else {
        for (let i = 0; i < this.state.trainingseinheiten; i++) {
          if (hilfsarray[i][0] === this.state.wunsch77) {
            if (!hilfsarray[i].includes(this.state.wunsch78)) {
              errorSchritt6Wunsch20 = "Dieser Wunsch ist leider nicht möglich.";
              errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
            }
            if (this.state.wunsch79 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch79)) {
                errorSchritt6Wunsch20 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
            if (this.state.wunsch80 !== "") {
              if (!hilfsarray[i].includes(this.state.wunsch80)) {
                errorSchritt6Wunsch20 =
                  "Dieser Wunsch ist leider nicht möglich.";
                errorSchritt6 = "Es müssen noch Änderungen vorgenommen werden.";
              }
            }
          }
        }
      }
    }
    if (
      errorSchritt6nurzwei ||
      errorSchritt6Wunsch1 ||
      errorSchritt6Wunsch2 ||
      errorSchritt6Wunsch3 ||
      errorSchritt6Wunsch4 ||
      errorSchritt6Wunsch5 ||
      errorSchritt6Wunsch6 ||
      errorSchritt6Wunsch7 ||
      errorSchritt6Wunsch8 ||
      errorSchritt6Wunsch9 ||
      errorSchritt6Wunsch10 ||
      errorSchritt6Wunsch11 ||
      errorSchritt6Wunsch12 ||
      errorSchritt6Wunsch13 ||
      errorSchritt6Wunsch14 ||
      errorSchritt6Wunsch15 ||
      errorSchritt6Wunsch16 ||
      errorSchritt6Wunsch17 ||
      errorSchritt6Wunsch18 ||
      errorSchritt6Wunsch19 ||
      errorSchritt6Wunsch20
    ) {
      this.setState({
        errorSchritt6nurzwei,
        errorSchritt6Wunsch1,
        errorSchritt6Wunsch2,
        errorSchritt6Wunsch3,
        errorSchritt6Wunsch4,
        errorSchritt6Wunsch5,
        errorSchritt6Wunsch6,
        errorSchritt6Wunsch7,
        errorSchritt6Wunsch8,
        errorSchritt6Wunsch9,
        errorSchritt6Wunsch10,
        errorSchritt6Wunsch11,
        errorSchritt6Wunsch12,
        errorSchritt6Wunsch13,
        errorSchritt6Wunsch14,
        errorSchritt6Wunsch15,
        errorSchritt6Wunsch16,
        errorSchritt6Wunsch17,
        errorSchritt6Wunsch18,
        errorSchritt6Wunsch19,
        errorSchritt6Wunsch20,
        errorSchritt6,
      });
      return false;
    }
    this.setState({
      errorSchritt6nurzwei: "",
      errorSchritt6Wunsch1: "",
      errorSchritt6Wunsch2: "",
      errorSchritt6Wunsch3: "",
      errorSchritt6Wunsch4: "",
      errorSchritt6Wunsch5: "",
      errorSchritt6Wunsch6: "",
      errorSchritt6Wunsch7: "",
      errorSchritt6Wunsch8: "",
      errorSchritt6Wunsch9: "",
      errorSchritt6Wunsch10: "",
      errorSchritt6Wunsch11: "",
      errorSchritt6Wunsch12: "",
      errorSchritt6Wunsch13: "",
      errorSchritt6Wunsch14: "",
      errorSchritt6Wunsch15: "",
      errorSchritt6Wunsch16: "",
      errorSchritt6Wunsch17: "",
      errorSchritt6Wunsch18: "",
      errorSchritt6Wunsch19: "",
      errorSchritt6Wunsch20: "",
      errorSchritt6: "",
    });
    return true;
  };

  validateSchritt7 = () => {
    let errorSchritt7Verbot1 = "";
    let errorSchritt7Verbot2 = "";
    let errorSchritt7Verbot3 = "";
    let errorSchritt7Verbot4 = "";
    let errorSchritt7Verbot5 = "";
    let errorSchritt7Verbot6 = "";
    let errorSchritt7Verbot7 = "";
    let errorSchritt7Verbot8 = "";
    let errorSchritt7Verbot9 = "";
    let errorSchritt7Verbot10 = "";
    let errorSchritt7Verbot11 = "";
    let errorSchritt7Verbot12 = "";
    let errorSchritt7Verbot13 = "";
    let errorSchritt7Verbot14 = "";
    let errorSchritt7Verbot15 = "";
    let errorSchritt7Verbot16 = "";
    let errorSchritt7Verbot17 = "";
    let errorSchritt7Verbot18 = "";
    let errorSchritt7Verbot19 = "";
    let errorSchritt7Verbot20 = "";
    let errorSchritt7 = "";

    if (this.state.verbot1 !== "") {
      if (this.state.verbot2 === "") {
        errorSchritt7Verbot1 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (this.state.verbot3 !== "") {
      if (this.state.verbot4 === "") {
        errorSchritt7Verbot2 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (this.state.verbot5 !== "") {
      if (this.state.verbot6 === "") {
        errorSchritt7Verbot3 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (this.state.verbot7 !== "") {
      if (this.state.verbot8 === "") {
        errorSchritt7Verbot4 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (this.state.verbot9 !== "") {
      if (this.state.verbot10 === "") {
        errorSchritt7Verbot5 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (this.state.verbot11 !== "") {
      if (this.state.verbot12 === "") {
        errorSchritt7Verbot6 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (this.state.verbot13 !== "") {
      if (this.state.verbot14 === "") {
        errorSchritt7Verbot7 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (this.state.verbot15 !== "") {
      if (this.state.verbot16 === "") {
        errorSchritt7Verbot8 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (this.state.verbot17 !== "") {
      if (this.state.verbot18 === "") {
        errorSchritt7Verbot9 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (this.state.verbot19 !== "") {
      if (this.state.verbot20 === "") {
        errorSchritt7Verbot10 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (this.state.verbot21 !== "") {
      if (this.state.verbot22 === "") {
        errorSchritt7Verbot11 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (this.state.verbot23 !== "") {
      if (this.state.verbot24 === "") {
        errorSchritt7Verbot12 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (this.state.verbot25 !== "") {
      if (this.state.verbot26 === "") {
        errorSchritt7Verbot13 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (this.state.verbot27 !== "") {
      if (this.state.verbot28 === "") {
        errorSchritt7Verbot14 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (this.state.verbot29 !== "") {
      if (this.state.verbot30 === "") {
        errorSchritt7Verbot15 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (this.state.verbot31 !== "") {
      if (this.state.verbot32 === "") {
        errorSchritt7Verbot16 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (this.state.verbot33 !== "") {
      if (this.state.verbot34 === "") {
        errorSchritt7Verbot17 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (this.state.verbot35 !== "") {
      if (this.state.verbot36 === "") {
        errorSchritt7Verbot18 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (this.state.verbot37 !== "") {
      if (this.state.verbot38 === "") {
        errorSchritt7Verbot19 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }
    if (this.state.verbot39 !== "") {
      if (this.state.verbot40 === "") {
        errorSchritt7Verbot20 =
          "Füllen Sie entweder kein Feld oder beide Felder in dieser Zeile aus.";
        errorSchritt7 = "Es müssen noch Änderungen vorgenommen werden";
      }
    }

    if (
      errorSchritt7Verbot1 ||
      errorSchritt7Verbot2 ||
      errorSchritt7Verbot3 ||
      errorSchritt7Verbot4 ||
      errorSchritt7Verbot5 ||
      errorSchritt7Verbot6 ||
      errorSchritt7Verbot7 ||
      errorSchritt7Verbot8 ||
      errorSchritt7Verbot9 ||
      errorSchritt7Verbot10 ||
      errorSchritt7Verbot11 ||
      errorSchritt7Verbot12 ||
      errorSchritt7Verbot13 ||
      errorSchritt7Verbot14 ||
      errorSchritt7Verbot15 ||
      errorSchritt7Verbot16 ||
      errorSchritt7Verbot17 ||
      errorSchritt7Verbot18 ||
      errorSchritt7Verbot19 ||
      errorSchritt7Verbot20
    ) {
      this.setState({
        errorSchritt7Verbot1,
        errorSchritt7Verbot2,
        errorSchritt7Verbot3,
        errorSchritt7Verbot4,
        errorSchritt7Verbot5,
        errorSchritt7Verbot6,
        errorSchritt7Verbot7,
        errorSchritt7Verbot8,
        errorSchritt7Verbot9,
        errorSchritt7Verbot10,
        errorSchritt7Verbot11,
        errorSchritt7Verbot12,
        errorSchritt7Verbot13,
        errorSchritt7Verbot14,
        errorSchritt7Verbot15,
        errorSchritt7Verbot16,
        errorSchritt7Verbot17,
        errorSchritt7Verbot18,
        errorSchritt7Verbot19,
        errorSchritt7Verbot20,
        errorSchritt7,
      });
      return false;
    }
    this.setState({
      errorSchritt7Verbot1: "",
      errorSchritt7Verbot2: "",
      errorSchritt7Verbot3: "",
      errorSchritt7Verbot4: "",
      errorSchritt7Verbot5: "",
      errorSchritt7Verbot6: "",
      errorSchritt7Verbot7: "",
      errorSchritt7Verbot8: "",
      errorSchritt7Verbot9: "",
      errorSchritt7Verbot10: "",
      errorSchritt7Verbot11: "",
      errorSchritt7Verbot12: "",
      errorSchritt7Verbot13: "",
      errorSchritt7Verbot14: "",
      errorSchritt7Verbot15: "",
      errorSchritt7Verbot16: "",
      errorSchritt7Verbot17: "",
      errorSchritt7Verbot18: "",
      errorSchritt7Verbot19: "",
      errorSchritt7Verbot20: "",
      errorSchritt7: "",
    });
    return true;
  };

  /*
  Handle Submit
  */

  handleSubmitSchritt1 = (event) => {
    event.preventDefault();
    const isValid = this.validateSchritt1();
    if (isValid) {
      this.setState((state) => ({
        schritt: 2,
      }));
      window.scrollTo(0, 0);
    }
  };

  handleSubmitSchritt2 = (event) => {
    event.preventDefault();
    const isValid = this.validateSchritt2();
    if (isValid) {
      this.setState((state) => ({
        schritt: 3,
      }));
      window.scrollTo(0, 0);
    }
  };

  handleSubmitSchritt2Back = (event) => {
    this.setState((state) => ({
      schritt: 1,
    }));
    window.scrollTo(0, 0);
  };

  handleSubmitSchritt3 = (event) => {
    event.preventDefault();
    const isValid = this.validateSchritt3();
    if (isValid) {
      this.setState((state) => ({
        schritt: 4,
      }));
      window.scrollTo(0, 0);
    }
  };

  handleSubmitSchritt3Back = (event) => {
    this.setState((state) => ({
      schritt: 2,
    }));
    window.scrollTo(0, 0);
  };

  handleSubmitSchritt4 = (event) => {
    event.preventDefault();
    const isValid = this.validateSchritt4();
    if (isValid) {
      this.setState((state) => ({
        schritt: 5,
      }));
      window.scrollTo(0, 0);
    }
  };

  handleSubmitSchritt4Back = (event) => {
    this.setState((state) => ({
      schritt: 3,
    }));
    window.scrollTo(0, 0);
  };

  handleSubmitSchritt5 = (event) => {
    event.preventDefault();
    const isValid = this.validateSchritt5();
    if (isValid) {
      this.setState((state) => ({
        schritt: 6,
      }));
      window.scrollTo(0, 0);
    }
  };

  handleSubmitSchritt5Back = (event) => {
    this.setState((state) => ({
      schritt: 4,
    }));
    window.scrollTo(0, 0);
  };
  handleSubmitSchritt6 = (event) => {
    event.preventDefault();
    const isValid = this.validateSchritt6();
    if (isValid) {
      window.scrollTo(0, 0);
      this.setState((state) => ({
        schritt: 7,
      }));
    }
  };

  handleSubmitSchritt6Back = (event) => {
    this.setState((state) => ({
      schritt: 5,
    }));
    window.scrollTo(0, 0);
  };

  handleSubmitSchritt7 = (event) => {
    event.preventDefault();
    const isValid = this.validateSchritt7();

    if (isValid) {
      window.scrollTo(0, 0);

      const durchlauf = this.state.messageget;

      const demo = {
        trainingsauswahl: this.state.trainingsauswahl,
        zeit1: this.state.zeit1,
        zeit2: this.state.zeit2,
        zeit3: this.state.zeit3,
        zeit4: this.state.zeit4,
        zeit5: this.state.zeit5,
        zeit6: this.state.zeit6,
        zeit7: this.state.zeit7,
        zeit8: this.state.zeit8,
        zeit9: this.state.zeit9,
        zeit10: this.state.zeit10,
        zeit11: this.state.zeit11,
        zeit12: this.state.zeit12,
        zeit13: this.state.zeit13,
        zeit14: this.state.zeit14,
        zeit15: this.state.zeit15,
        zeit16: this.state.zeit16,
        matrix11: this.state.matrix11,
        matrix12: this.state.matrix12,
        matrix13: this.state.matrix13,
        matrix14: this.state.matrix14,
        matrix15: this.state.matrix15,
        matrix16: this.state.matrix16,
        matrix21: this.state.matrix21,
        matrix22: this.state.matrix22,
        matrix23: this.state.matrix23,
        matrix24: this.state.matrix24,
        matrix25: this.state.matrix25,
        matrix26: this.state.matrix26,
        matrix31: this.state.matrix31,
        matrix32: this.state.matrix32,
        matrix33: this.state.matrix33,
        matrix34: this.state.matrix34,
        matrix35: this.state.matrix35,
        matrix36: this.state.matrix36,
        matrix41: this.state.matrix41,
        matrix42: this.state.matrix42,
        matrix43: this.state.matrix43,
        matrix44: this.state.matrix44,
        matrix45: this.state.matrix45,
        matrix46: this.state.matrix46,
        matrix51: this.state.matrix51,
        matrix52: this.state.matrix52,
        matrix53: this.state.matrix53,
        matrix54: this.state.matrix54,
        matrix55: this.state.matrix55,
        matrix56: this.state.matrix56,
        matrix61: this.state.matrix61,
        matrix62: this.state.matrix62,
        matrix63: this.state.matrix63,
        matrix64: this.state.matrix64,
        matrix65: this.state.matrix65,
        matrix66: this.state.matrix66,
        matrix71: this.state.matrix71,
        matrix72: this.state.matrix72,
        matrix73: this.state.matrix73,
        matrix74: this.state.matrix74,
        matrix75: this.state.matrix75,
        matrix76: this.state.matrix76,
        matrix81: this.state.matrix81,
        matrix82: this.state.matrix82,
        matrix83: this.state.matrix83,
        matrix84: this.state.matrix84,
        matrix85: this.state.matrix85,
        matrix86: this.state.matrix86,
        topfauswahl: this.state.topfauswahl,
        trainingseinheiten: this.state.trainingseinheiten,
        bevorzugteTopfgroesse: this.state.bevorzugteTopfgroesse,
        alternativeTopfgroesse: this.state.alternativeTopfgroesse,
        name1: this.state.name1,
        name2: this.state.name2,
        name3: this.state.name3,
        name4: this.state.name4,
        name5: this.state.name5,
        name6: this.state.name6,
        name7: this.state.name7,
        name8: this.state.name8,
        zeit111: this.state.zeit111,
        zeit112: this.state.zeit112,
        zeit113: this.state.zeit113,
        zeit114: this.state.zeit114,
        zeit115: this.state.zeit115,
        zeit116: this.state.zeit116,
        zeit121: this.state.zeit121,
        zeit122: this.state.zeit122,
        zeit123: this.state.zeit123,
        zeit124: this.state.zeit124,
        zeit125: this.state.zeit125,
        zeit126: this.state.zeit126,
        zeit131: this.state.zeit131,
        zeit132: this.state.zeit132,
        zeit133: this.state.zeit133,
        zeit134: this.state.zeit134,
        zeit135: this.state.zeit135,
        zeit136: this.state.zeit136,
        zeit141: this.state.zeit141,
        zeit142: this.state.zeit142,
        zeit143: this.state.zeit143,
        zeit144: this.state.zeit144,
        zeit145: this.state.zeit145,
        zeit146: this.state.zeit146,
        zeit151: this.state.zeit151,
        zeit152: this.state.zeit152,
        zeit153: this.state.zeit153,
        zeit154: this.state.zeit154,
        zeit155: this.state.zeit155,
        zeit156: this.state.zeit156,
        zeit161: this.state.zeit161,
        zeit162: this.state.zeit162,
        zeit163: this.state.zeit163,
        zeit164: this.state.zeit164,
        zeit165: this.state.zeit165,
        zeit166: this.state.zeit166,
        zeit171: this.state.zeit171,
        zeit172: this.state.zeit172,
        zeit173: this.state.zeit173,
        zeit174: this.state.zeit174,
        zeit175: this.state.zeit175,
        zeit176: this.state.zeit176,
        zeit181: this.state.zeit181,
        zeit182: this.state.zeit182,
        zeit183: this.state.zeit183,
        zeit184: this.state.zeit184,
        zeit185: this.state.zeit185,
        zeit186: this.state.zeit186,
        zeit211: this.state.zeit211,
        zeit212: this.state.zeit212,
        zeit213: this.state.zeit213,
        zeit214: this.state.zeit214,
        zeit215: this.state.zeit215,
        zeit216: this.state.zeit216,
        zeit221: this.state.zeit221,
        zeit222: this.state.zeit222,
        zeit223: this.state.zeit223,
        zeit224: this.state.zeit224,
        zeit225: this.state.zeit225,
        zeit226: this.state.zeit226,
        zeit231: this.state.zeit231,
        zeit232: this.state.zeit232,
        zeit233: this.state.zeit233,
        zeit234: this.state.zeit234,
        zeit235: this.state.zeit235,
        zeit236: this.state.zeit236,
        zeit241: this.state.zeit241,
        zeit242: this.state.zeit242,
        zeit243: this.state.zeit243,
        zeit244: this.state.zeit244,
        zeit245: this.state.zeit245,
        zeit246: this.state.zeit246,
        zeit251: this.state.zeit251,
        zeit252: this.state.zeit252,
        zeit253: this.state.zeit253,
        zeit254: this.state.zeit254,
        zeit255: this.state.zeit255,
        zeit256: this.state.zeit256,
        zeit261: this.state.zeit261,
        zeit262: this.state.zeit262,
        zeit263: this.state.zeit263,
        zeit264: this.state.zeit264,
        zeit265: this.state.zeit265,
        zeit266: this.state.zeit266,
        zeit271: this.state.zeit271,
        zeit272: this.state.zeit272,
        zeit273: this.state.zeit273,
        zeit274: this.state.zeit274,
        zeit275: this.state.zeit275,
        zeit276: this.state.zeit276,
        zeit281: this.state.zeit281,
        zeit282: this.state.zeit282,
        zeit283: this.state.zeit283,
        zeit284: this.state.zeit284,
        zeit285: this.state.zeit285,
        zeit286: this.state.zeit286,
        zeit311: this.state.zeit311,
        zeit312: this.state.zeit312,
        zeit313: this.state.zeit313,
        zeit314: this.state.zeit314,
        zeit315: this.state.zeit315,
        zeit316: this.state.zeit316,
        zeit321: this.state.zeit321,
        zeit322: this.state.zeit322,
        zeit323: this.state.zeit323,
        zeit324: this.state.zeit324,
        zeit325: this.state.zeit325,
        zeit326: this.state.zeit326,
        zeit331: this.state.zeit331,
        zeit332: this.state.zeit332,
        zeit333: this.state.zeit333,
        zeit334: this.state.zeit334,
        zeit335: this.state.zeit335,
        zeit336: this.state.zeit336,
        zeit341: this.state.zeit341,
        zeit342: this.state.zeit342,
        zeit343: this.state.zeit343,
        zeit344: this.state.zeit344,
        zeit345: this.state.zeit345,
        zeit346: this.state.zeit346,
        zeit351: this.state.zeit351,
        zeit352: this.state.zeit352,
        zeit353: this.state.zeit353,
        zeit354: this.state.zeit354,
        zeit355: this.state.zeit355,
        zeit356: this.state.zeit356,
        zeit361: this.state.zeit361,
        zeit362: this.state.zeit362,
        zeit363: this.state.zeit363,
        zeit364: this.state.zeit364,
        zeit365: this.state.zeit365,
        zeit366: this.state.zeit366,
        zeit371: this.state.zeit371,
        zeit372: this.state.zeit372,
        zeit373: this.state.zeit373,
        zeit374: this.state.zeit374,
        zeit375: this.state.zeit375,
        zeit376: this.state.zeit376,
        zeit381: this.state.zeit381,
        zeit382: this.state.zeit382,
        zeit383: this.state.zeit383,
        zeit384: this.state.zeit384,
        zeit385: this.state.zeit385,
        zeit386: this.state.zeit386,
        zeit411: this.state.zeit411,
        zeit412: this.state.zeit412,
        zeit413: this.state.zeit413,
        zeit414: this.state.zeit414,
        zeit415: this.state.zeit415,
        zeit416: this.state.zeit416,
        zeit421: this.state.zeit421,
        zeit422: this.state.zeit422,
        zeit423: this.state.zeit423,
        zeit424: this.state.zeit424,
        zeit425: this.state.zeit425,
        zeit426: this.state.zeit426,
        zeit431: this.state.zeit431,
        zeit432: this.state.zeit432,
        zeit433: this.state.zeit433,
        zeit434: this.state.zeit434,
        zeit435: this.state.zeit435,
        zeit436: this.state.zeit436,
        zeit441: this.state.zeit441,
        zeit442: this.state.zeit442,
        zeit443: this.state.zeit443,
        zeit444: this.state.zeit444,
        zeit445: this.state.zeit445,
        zeit446: this.state.zeit446,
        zeit451: this.state.zeit451,
        zeit452: this.state.zeit452,
        zeit453: this.state.zeit453,
        zeit454: this.state.zeit454,
        zeit455: this.state.zeit455,
        zeit456: this.state.zeit456,
        zeit461: this.state.zeit461,
        zeit462: this.state.zeit462,
        zeit463: this.state.zeit463,
        zeit464: this.state.zeit464,
        zeit465: this.state.zeit465,
        zeit466: this.state.zeit466,
        zeit471: this.state.zeit471,
        zeit472: this.state.zeit472,
        zeit473: this.state.zeit473,
        zeit474: this.state.zeit474,
        zeit475: this.state.zeit475,
        zeit476: this.state.zeit476,
        zeit481: this.state.zeit481,
        zeit482: this.state.zeit482,
        zeit483: this.state.zeit483,
        zeit484: this.state.zeit484,
        zeit485: this.state.zeit485,
        zeit486: this.state.zeit486,
        zeit511: this.state.zeit511,
        zeit512: this.state.zeit512,
        zeit513: this.state.zeit513,
        zeit514: this.state.zeit514,
        zeit515: this.state.zeit515,
        zeit516: this.state.zeit516,
        zeit521: this.state.zeit521,
        zeit522: this.state.zeit522,
        zeit523: this.state.zeit523,
        zeit524: this.state.zeit524,
        zeit525: this.state.zeit525,
        zeit526: this.state.zeit526,
        zeit531: this.state.zeit531,
        zeit532: this.state.zeit532,
        zeit533: this.state.zeit533,
        zeit534: this.state.zeit534,
        zeit535: this.state.zeit535,
        zeit536: this.state.zeit536,
        zeit541: this.state.zeit541,
        zeit542: this.state.zeit542,
        zeit543: this.state.zeit543,
        zeit544: this.state.zeit544,
        zeit545: this.state.zeit545,
        zeit546: this.state.zeit546,
        zeit551: this.state.zeit551,
        zeit552: this.state.zeit552,
        zeit553: this.state.zeit553,
        zeit554: this.state.zeit554,
        zeit555: this.state.zeit555,
        zeit556: this.state.zeit556,
        zeit561: this.state.zeit561,
        zeit562: this.state.zeit562,
        zeit563: this.state.zeit563,
        zeit564: this.state.zeit564,
        zeit565: this.state.zeit565,
        zeit566: this.state.zeit566,
        zeit571: this.state.zeit571,
        zeit572: this.state.zeit572,
        zeit573: this.state.zeit573,
        zeit574: this.state.zeit574,
        zeit575: this.state.zeit575,
        zeit576: this.state.zeit576,
        zeit581: this.state.zeit581,
        zeit582: this.state.zeit582,
        zeit583: this.state.zeit583,
        zeit584: this.state.zeit584,
        zeit585: this.state.zeit585,
        zeit586: this.state.zeit586,
        zeit611: this.state.zeit611,
        zeit612: this.state.zeit612,
        zeit613: this.state.zeit613,
        zeit614: this.state.zeit614,
        zeit615: this.state.zeit615,
        zeit616: this.state.zeit616,
        zeit621: this.state.zeit621,
        zeit622: this.state.zeit622,
        zeit623: this.state.zeit623,
        zeit624: this.state.zeit624,
        zeit625: this.state.zeit625,
        zeit626: this.state.zeit626,
        zeit631: this.state.zeit631,
        zeit632: this.state.zeit632,
        zeit633: this.state.zeit633,
        zeit634: this.state.zeit634,
        zeit635: this.state.zeit635,
        zeit636: this.state.zeit636,
        zeit641: this.state.zeit641,
        zeit642: this.state.zeit642,
        zeit643: this.state.zeit643,
        zeit644: this.state.zeit644,
        zeit645: this.state.zeit645,
        zeit646: this.state.zeit646,
        zeit651: this.state.zeit651,
        zeit652: this.state.zeit652,
        zeit653: this.state.zeit653,
        zeit654: this.state.zeit654,
        zeit655: this.state.zeit655,
        zeit656: this.state.zeit656,
        zeit661: this.state.zeit661,
        zeit662: this.state.zeit662,
        zeit663: this.state.zeit663,
        zeit664: this.state.zeit664,
        zeit665: this.state.zeit665,
        zeit666: this.state.zeit666,
        zeit671: this.state.zeit671,
        zeit672: this.state.zeit672,
        zeit673: this.state.zeit673,
        zeit674: this.state.zeit674,
        zeit675: this.state.zeit675,
        zeit676: this.state.zeit676,
        zeit681: this.state.zeit681,
        zeit682: this.state.zeit682,
        zeit683: this.state.zeit683,
        zeit684: this.state.zeit684,
        zeit685: this.state.zeit685,
        zeit686: this.state.zeit686,
        zeit711: this.state.zeit711,
        zeit712: this.state.zeit712,
        zeit713: this.state.zeit713,
        zeit714: this.state.zeit714,
        zeit715: this.state.zeit715,
        zeit716: this.state.zeit716,
        zeit721: this.state.zeit721,
        zeit722: this.state.zeit722,
        zeit723: this.state.zeit723,
        zeit724: this.state.zeit724,
        zeit725: this.state.zeit725,
        zeit726: this.state.zeit726,
        zeit731: this.state.zeit731,
        zeit732: this.state.zeit732,
        zeit733: this.state.zeit733,
        zeit734: this.state.zeit734,
        zeit735: this.state.zeit735,
        zeit736: this.state.zeit736,
        zeit741: this.state.zeit741,
        zeit742: this.state.zeit742,
        zeit743: this.state.zeit743,
        zeit744: this.state.zeit744,
        zeit745: this.state.zeit745,
        zeit746: this.state.zeit746,
        zeit751: this.state.zeit751,
        zeit752: this.state.zeit752,
        zeit753: this.state.zeit753,
        zeit754: this.state.zeit754,
        zeit755: this.state.zeit755,
        zeit756: this.state.zeit756,
        zeit761: this.state.zeit761,
        zeit762: this.state.zeit762,
        zeit763: this.state.zeit763,
        zeit764: this.state.zeit764,
        zeit765: this.state.zeit765,
        zeit766: this.state.zeit766,
        zeit771: this.state.zeit771,
        zeit772: this.state.zeit772,
        zeit773: this.state.zeit773,
        zeit774: this.state.zeit774,
        zeit775: this.state.zeit775,
        zeit776: this.state.zeit776,
        zeit781: this.state.zeit781,
        zeit782: this.state.zeit782,
        zeit783: this.state.zeit783,
        zeit784: this.state.zeit784,
        zeit785: this.state.zeit785,
        zeit786: this.state.zeit786,
        zeit811: this.state.zeit811,
        zeit812: this.state.zeit812,
        zeit813: this.state.zeit813,
        zeit814: this.state.zeit814,
        zeit815: this.state.zeit815,
        zeit816: this.state.zeit816,
        zeit821: this.state.zeit821,
        zeit822: this.state.zeit822,
        zeit823: this.state.zeit823,
        zeit824: this.state.zeit824,
        zeit825: this.state.zeit825,
        zeit826: this.state.zeit826,
        zeit831: this.state.zeit831,
        zeit832: this.state.zeit832,
        zeit833: this.state.zeit833,
        zeit834: this.state.zeit834,
        zeit835: this.state.zeit835,
        zeit836: this.state.zeit836,
        zeit841: this.state.zeit841,
        zeit842: this.state.zeit842,
        zeit843: this.state.zeit843,
        zeit844: this.state.zeit844,
        zeit845: this.state.zeit845,
        zeit846: this.state.zeit846,
        zeit851: this.state.zeit851,
        zeit852: this.state.zeit852,
        zeit853: this.state.zeit853,
        zeit854: this.state.zeit854,
        zeit855: this.state.zeit855,
        zeit856: this.state.zeit856,
        zeit861: this.state.zeit861,
        zeit862: this.state.zeit862,
        zeit863: this.state.zeit863,
        zeit864: this.state.zeit864,
        zeit865: this.state.zeit865,
        zeit866: this.state.zeit866,
        zeit871: this.state.zeit871,
        zeit872: this.state.zeit872,
        zeit873: this.state.zeit873,
        zeit874: this.state.zeit874,
        zeit875: this.state.zeit875,
        zeit876: this.state.zeit876,
        zeit881: this.state.zeit881,
        zeit882: this.state.zeit882,
        zeit883: this.state.zeit883,
        zeit884: this.state.zeit884,
        zeit885: this.state.zeit885,
        zeit886: this.state.zeit886,
        wunsch1: this.state.wunsch1,
        wunsch2: this.state.wunsch2,
        wunsch3: this.state.wunsch3,
        wunsch4: this.state.wunsch4,
        wunsch5: this.state.wunsch5,
        wunsch6: this.state.wunsch6,
        wunsch7: this.state.wunsch7,
        wunsch8: this.state.wunsch8,
        wunsch9: this.state.wunsch9,
        wunsch10: this.state.wunsch10,
        wunsch11: this.state.wunsch11,
        wunsch12: this.state.wunsch12,
        wunsch13: this.state.wunsch13,
        wunsch14: this.state.wunsch14,
        wunsch15: this.state.wunsch15,
        wunsch16: this.state.wunsch16,
        wunsch17: this.state.wunsch17,
        wunsch18: this.state.wunsch18,
        wunsch19: this.state.wunsch19,
        wunsch20: this.state.wunsch20,
        wunsch21: this.state.wunsch21,
        wunsch22: this.state.wunsch22,
        wunsch23: this.state.wunsch23,
        wunsch24: this.state.wunsch24,
        wunsch25: this.state.wunsch25,
        wunsch26: this.state.wunsch26,
        wunsch27: this.state.wunsch27,
        wunsch28: this.state.wunsch28,
        wunsch29: this.state.wunsch29,
        wunsch30: this.state.wunsch30,
        wunsch31: this.state.wunsch31,
        wunsch32: this.state.wunsch32,
        wunsch33: this.state.wunsch33,
        wunsch34: this.state.wunsch34,
        wunsch35: this.state.wunsch35,
        wunsch36: this.state.wunsch36,
        wunsch37: this.state.wunsch37,
        wunsch38: this.state.wunsch38,
        wunsch39: this.state.wunsch39,
        wunsch40: this.state.wunsch40,
        wunsch41: this.state.wunsch41,
        wunsch42: this.state.wunsch42,
        wunsch43: this.state.wunsch43,
        wunsch44: this.state.wunsch44,
        wunsch45: this.state.wunsch45,
        wunsch46: this.state.wunsch46,
        wunsch47: this.state.wunsch47,
        wunsch48: this.state.wunsch48,
        wunsch49: this.state.wunsch49,
        wunsch50: this.state.wunsch50,
        wunsch51: this.state.wunsch51,
        wunsch52: this.state.wunsch52,
        wunsch53: this.state.wunsch53,
        wunsch54: this.state.wunsch54,
        wunsch55: this.state.wunsch55,
        wunsch56: this.state.wunsch56,
        wunsch57: this.state.wunsch57,
        wunsch58: this.state.wunsch58,
        wunsch59: this.state.wunsch59,
        wunsch60: this.state.wunsch60,
        wunsch61: this.state.wunsch61,
        wunsch62: this.state.wunsch62,
        wunsch63: this.state.wunsch63,
        wunsch64: this.state.wunsch64,
        wunsch65: this.state.wunsch65,
        wunsch66: this.state.wunsch66,
        wunsch67: this.state.wunsch67,
        wunsch68: this.state.wunsch68,
        wunsch69: this.state.wunsch69,
        wunsch70: this.state.wunsch70,
        wunsch71: this.state.wunsch71,
        wunsch72: this.state.wunsch72,
        wunsch73: this.state.wunsch73,
        wunsch74: this.state.wunsch74,
        wunsch75: this.state.wunsch75,
        wunsch76: this.state.wunsch76,
        wunsch77: this.state.wunsch77,
        wunsch78: this.state.wunsch78,
        wunsch79: this.state.wunsch79,
        wunsch80: this.state.wunsch80,
        verbot1: this.state.verbot1,
        verbot2: this.state.verbot2,
        verbot3: this.state.verbot3,
        verbot4: this.state.verbot4,
        verbot5: this.state.verbot5,
        verbot6: this.state.verbot6,
        verbot7: this.state.verbot7,
        verbot8: this.state.verbot8,
        verbot9: this.state.verbot9,
        verbot10: this.state.verbot10,
        verbot11: this.state.verbot11,
        verbot12: this.state.verbot12,
        verbot13: this.state.verbot13,
        verbot14: this.state.verbot14,
        verbot15: this.state.verbot15,
        verbot16: this.state.verbot16,
        verbot17: this.state.verbot17,
        verbot18: this.state.verbot18,
        verbot19: this.state.verbot19,
        verbot20: this.state.verbot20,
        verbot21: this.state.verbot21,
        verbot22: this.state.verbot22,
        verbot23: this.state.verbot23,
        verbot24: this.state.verbot24,
        verbot25: this.state.verbot25,
        verbot26: this.state.verbot26,
        verbot27: this.state.verbot27,
        verbot28: this.state.verbot28,
        verbot29: this.state.verbot29,
        verbot30: this.state.verbot30,
        verbot31: this.state.verbot31,
        verbot32: this.state.verbot32,
        verbot33: this.state.verbot33,
        verbot34: this.state.verbot34,
        verbot35: this.state.verbot35,
        verbot36: this.state.verbot36,
        verbot37: this.state.verbot37,
        verbot38: this.state.verbot38,
        verbot39: this.state.verbot39,
        verbot40: this.state.verbot40,
        durchlauf: durchlauf,
      };

      const postrequest = "".concat("/api/demo/", durchlauf);
      axios.post(postrequest, demo).then(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );
      this.setState((state) => ({ schritt: 9999 }));
    }
  };

  handleSubmitSchritt7Back = (event) => {
    this.setState((state) => ({
      schritt: 6,
    }));
    window.scrollTo(0, 0);
  };

  handleSubmitAbschluss = (event) => {
    const abschluss = "".concat("/demostundenplan/", this.state.messageget);
    document.location.href=abschluss;
  };

  handleSubmitAbschlussBack = (event) => {
    this.setState((state) => ({ schritt: 7 }));
  };

  /*
  Handle Change
  */
  handleChangeTrainingsauswahl = (event) => {
    this.setState({ trainingsauswahl: event.target.value });
  };
  handleChangezeit1 = (event) => {
    this.setState({ zeit1: event.target.value });
  };
  handleChangezeit2 = (event) => {
    this.setState({ zeit2: event.target.value });
  };
  handleChangezeit3 = (event) => {
    this.setState({ zeit3: event.target.value });
  };
  handleChangezeit4 = (event) => {
    this.setState({ zeit4: event.target.value });
  };
  handleChangezeit5 = (event) => {
    this.setState({ zeit5: event.target.value });
  };
  handleChangezeit6 = (event) => {
    this.setState({ zeit6: event.target.value });
  };
  handleChangezeit7 = (event) => {
    this.setState({ zeit7: event.target.value });
  };
  handleChangezeit8 = (event) => {
    this.setState({ zeit8: event.target.value });
  };
  handleChangezeit9 = (event) => {
    this.setState({ zeit9: event.target.value });
  };
  handleChangezeit10 = (event) => {
    this.setState({ zeit10: event.target.value });
  };
  handleChangezeit11 = (event) => {
    this.setState({ zeit11: event.target.value });
  };
  handleChangezeit12 = (event) => {
    this.setState({ zeit12: event.target.value });
  };
  handleChangezeit13 = (event) => {
    this.setState({ zeit13: event.target.value });
  };
  handleChangezeit14 = (event) => {
    this.setState({ zeit14: event.target.value });
  };
  handleChangezeit15 = (event) => {
    this.setState({ zeit15: event.target.value });
  };
  handleChangezeit16 = (event) => {
    this.setState({ zeit16: event.target.value });
  };

  handleChangematrix11 = (event) => {
    this.setState({ matrix11: event.target.value });
  };
  handleChangematrix12 = (event) => {
    this.setState({ matrix12: event.target.value });
  };
  handleChangematrix13 = (event) => {
    this.setState({ matrix13: event.target.value });
  };
  handleChangematrix14 = (event) => {
    this.setState({ matrix14: event.target.value });
  };
  handleChangematrix15 = (event) => {
    this.setState({ matrix15: event.target.value });
  };
  handleChangematrix16 = (event) => {
    this.setState({ matrix16: event.target.value });
  };
  handleChangematrix21 = (event) => {
    this.setState({ matrix21: event.target.value });
  };
  handleChangematrix22 = (event) => {
    this.setState({ matrix22: event.target.value });
  };
  handleChangematrix23 = (event) => {
    this.setState({ matrix23: event.target.value });
  };
  handleChangematrix24 = (event) => {
    this.setState({ matrix24: event.target.value });
  };
  handleChangematrix25 = (event) => {
    this.setState({ matrix25: event.target.value });
  };
  handleChangematrix26 = (event) => {
    this.setState({ matrix26: event.target.value });
  };
  handleChangematrix31 = (event) => {
    this.setState({ matrix31: event.target.value });
  };
  handleChangematrix32 = (event) => {
    this.setState({ matrix32: event.target.value });
  };
  handleChangematrix33 = (event) => {
    this.setState({ matrix33: event.target.value });
  };
  handleChangematrix34 = (event) => {
    this.setState({ matrix34: event.target.value });
  };
  handleChangematrix35 = (event) => {
    this.setState({ matrix35: event.target.value });
  };
  handleChangematrix36 = (event) => {
    this.setState({ matrix36: event.target.value });
  };
  handleChangematrix41 = (event) => {
    this.setState({ matrix41: event.target.value });
  };
  handleChangematrix42 = (event) => {
    this.setState({ matrix42: event.target.value });
  };
  handleChangematrix43 = (event) => {
    this.setState({ matrix43: event.target.value });
  };
  handleChangematrix44 = (event) => {
    this.setState({ matrix44: event.target.value });
  };
  handleChangematrix45 = (event) => {
    this.setState({ matrix45: event.target.value });
  };
  handleChangematrix46 = (event) => {
    this.setState({ matrix46: event.target.value });
  };
  handleChangematrix51 = (event) => {
    this.setState({ matrix51: event.target.value });
  };
  handleChangematrix52 = (event) => {
    this.setState({ matrix52: event.target.value });
  };
  handleChangematrix53 = (event) => {
    this.setState({ matrix53: event.target.value });
  };
  handleChangematrix54 = (event) => {
    this.setState({ matrix54: event.target.value });
  };
  handleChangematrix55 = (event) => {
    this.setState({ matrix55: event.target.value });
  };
  handleChangematrix56 = (event) => {
    this.setState({ matrix56: event.target.value });
  };
  handleChangematrix61 = (event) => {
    this.setState({ matrix61: event.target.value });
  };
  handleChangematrix62 = (event) => {
    this.setState({ matrix62: event.target.value });
  };
  handleChangematrix63 = (event) => {
    this.setState({ matrix63: event.target.value });
  };
  handleChangematrix64 = (event) => {
    this.setState({ matrix64: event.target.value });
  };
  handleChangematrix65 = (event) => {
    this.setState({ matrix65: event.target.value });
  };
  handleChangematrix66 = (event) => {
    this.setState({ matrix66: event.target.value });
  };
  handleChangematrix71 = (event) => {
    this.setState({ matrix71: event.target.value });
  };
  handleChangematrix72 = (event) => {
    this.setState({ matrix72: event.target.value });
  };
  handleChangematrix73 = (event) => {
    this.setState({ matrix73: event.target.value });
  };
  handleChangematrix74 = (event) => {
    this.setState({ matrix74: event.target.value });
  };
  handleChangematrix75 = (event) => {
    this.setState({ matrix75: event.target.value });
  };
  handleChangematrix76 = (event) => {
    this.setState({ matrix76: event.target.value });
  };
  handleChangematrix81 = (event) => {
    this.setState({ matrix81: event.target.value });
  };
  handleChangematrix82 = (event) => {
    this.setState({ matrix82: event.target.value });
  };
  handleChangematrix83 = (event) => {
    this.setState({ matrix83: event.target.value });
  };
  handleChangematrix84 = (event) => {
    this.setState({ matrix84: event.target.value });
  };
  handleChangematrix85 = (event) => {
    this.setState({ matrix85: event.target.value });
  };
  handleChangematrix86 = (event) => {
    this.setState({ matrix86: event.target.value });
  };

  handleChangeSetzeAlle = (event) => {
    this.setState({
      setzeAlle: event.target.value,
    });
  };

  handleChangeTopfauswahl = (event) => {
    this.setState({ topfauswahl: event.target.value });
  };

  handleChangeTrainingseinheiten = (event) => {
    this.setState({ trainingseinheiten: event.target.value });
  };
  handleChangeBevorzugteTopfgroesse = (event) => {
    this.setState({ bevorzugteTopfgroesse: event.target.value });
    this.setState({ alternativeTopfgroesse: parseInt(event.target.value) - 1 });
  };
  handleChangeAlternativeTopfgroesse = (event) => {
    this.setState({ alternativeTopfgroesse: event.target.value });
  };

  nochuebrig = (wert) => {
    return wert - this.state.trainingseinheiten;
  };

  auswahlTopf = (wert) => {
    const array = [];
    for (let j = 0; j < wert + 1; j++) {
      array.push(j);
    }
    return array;
  };

  bevorzugteGruppengroesse = (wert) => {
    const array = [];
    if (wert > 8) {
      return [1, 2, 3, 4, 5, 6, 7, 8];
    }
    for (let j = 1; j < wert + 1; j++) {
      array.push(j);
    }
    return array;
  };

  alternativeGruppengroesse = (wert) => {
    const array = [];
    if (wert === 1 || wert === 2) {
      return ["-"];
    }
    if (wert - 1 > 0) {
      array.push(wert - 1);
    }

    if (wert + 1 < parseInt(this.state.trainingsauswahl) + 1) {
      array.push(wert + 1);
    }
    return array;
  };

  handleChangeName1 = (event) => {
    this.setState({
      name1: event.target.value,
    });
    this.setState({
      wunsch1: "",
      wunsch2: "",
      wunsch3: "",
      wunsch4: "",
      wunsch5: "",
      wunsch6: "",
      wunsch7: "",
      wunsch8: "",
      wunsch9: "",
      wunsch10: "",
      wunsch11: "",
      wunsch12: "",
      wunsch13: "",
      wunsch14: "",
      wunsch15: "",
      wunsch16: "",
      wunsch17: "",
      wunsch18: "",
      wunsch19: "",
      wunsch20: "",
      wunsch21: "",
      wunsch22: "",
      wunsch23: "",
      wunsch24: "",
      wunsch25: "",
      wunsch26: "",
      wunsch27: "",
      wunsch28: "",
      wunsch29: "",
      wunsch30: "",
      wunsch31: "",
      wunsch32: "",
      wunsch33: "",
      wunsch34: "",
      wunsch35: "",
      wunsch36: "",
      wunsch37: "",
      wunsch38: "",
      wunsch39: "",
      wunsch40: "",
      wunsch41: "",
      wunsch42: "",
      wunsch43: "",
      wunsch44: "",
      wunsch45: "",
      wunsch46: "",
      wunsch47: "",
      wunsch48: "",
      wunsch49: "",
      wunsch50: "",
      wunsch51: "",
      wunsch52: "",
      wunsch53: "",
      wunsch54: "",
      wunsch55: "",
      wunsch56: "",
      wunsch57: "",
      wunsch58: "",
      wunsch59: "",
      wunsch60: "",
      wunsch61: "",
      wunsch62: "",
      wunsch63: "",
      wunsch64: "",
      wunsch65: "",
      wunsch66: "",
      wunsch67: "",
      wunsch68: "",
      wunsch69: "",
      wunsch70: "",
      wunsch71: "",
      wunsch72: "",
      wunsch73: "",
      wunsch74: "",
      wunsch75: "",
      wunsch76: "",
      wunsch77: "",
      wunsch78: "",
      wunsch79: "",
      wunsch80: "",
    });
  };
  handleChangeName2 = (event) => {
    this.setState({
      name2: event.target.value,
    });
    this.setState({
      wunsch1: "",
      wunsch2: "",
      wunsch3: "",
      wunsch4: "",
      wunsch5: "",
      wunsch6: "",
      wunsch7: "",
      wunsch8: "",
      wunsch9: "",
      wunsch10: "",
      wunsch11: "",
      wunsch12: "",
      wunsch13: "",
      wunsch14: "",
      wunsch15: "",
      wunsch16: "",
      wunsch17: "",
      wunsch18: "",
      wunsch19: "",
      wunsch20: "",
      wunsch21: "",
      wunsch22: "",
      wunsch23: "",
      wunsch24: "",
      wunsch25: "",
      wunsch26: "",
      wunsch27: "",
      wunsch28: "",
      wunsch29: "",
      wunsch30: "",
      wunsch31: "",
      wunsch32: "",
      wunsch33: "",
      wunsch34: "",
      wunsch35: "",
      wunsch36: "",
      wunsch37: "",
      wunsch38: "",
      wunsch39: "",
      wunsch40: "",
      wunsch41: "",
      wunsch42: "",
      wunsch43: "",
      wunsch44: "",
      wunsch45: "",
      wunsch46: "",
      wunsch47: "",
      wunsch48: "",
      wunsch49: "",
      wunsch50: "",
      wunsch51: "",
      wunsch52: "",
      wunsch53: "",
      wunsch54: "",
      wunsch55: "",
      wunsch56: "",
      wunsch57: "",
      wunsch58: "",
      wunsch59: "",
      wunsch60: "",
      wunsch61: "",
      wunsch62: "",
      wunsch63: "",
      wunsch64: "",
      wunsch65: "",
      wunsch66: "",
      wunsch67: "",
      wunsch68: "",
      wunsch69: "",
      wunsch70: "",
      wunsch71: "",
      wunsch72: "",
      wunsch73: "",
      wunsch74: "",
      wunsch75: "",
      wunsch76: "",
      wunsch77: "",
      wunsch78: "",
      wunsch79: "",
      wunsch80: "",
    });
  };
  handleChangeName3 = (event) => {
    this.setState({
      name3: event.target.value,
    });
    this.setState({
      wunsch1: "",
      wunsch2: "",
      wunsch3: "",
      wunsch4: "",
      wunsch5: "",
      wunsch6: "",
      wunsch7: "",
      wunsch8: "",
      wunsch9: "",
      wunsch10: "",
      wunsch11: "",
      wunsch12: "",
      wunsch13: "",
      wunsch14: "",
      wunsch15: "",
      wunsch16: "",
      wunsch17: "",
      wunsch18: "",
      wunsch19: "",
      wunsch20: "",
      wunsch21: "",
      wunsch22: "",
      wunsch23: "",
      wunsch24: "",
      wunsch25: "",
      wunsch26: "",
      wunsch27: "",
      wunsch28: "",
      wunsch29: "",
      wunsch30: "",
      wunsch31: "",
      wunsch32: "",
      wunsch33: "",
      wunsch34: "",
      wunsch35: "",
      wunsch36: "",
      wunsch37: "",
      wunsch38: "",
      wunsch39: "",
      wunsch40: "",
      wunsch41: "",
      wunsch42: "",
      wunsch43: "",
      wunsch44: "",
      wunsch45: "",
      wunsch46: "",
      wunsch47: "",
      wunsch48: "",
      wunsch49: "",
      wunsch50: "",
      wunsch51: "",
      wunsch52: "",
      wunsch53: "",
      wunsch54: "",
      wunsch55: "",
      wunsch56: "",
      wunsch57: "",
      wunsch58: "",
      wunsch59: "",
      wunsch60: "",
      wunsch61: "",
      wunsch62: "",
      wunsch63: "",
      wunsch64: "",
      wunsch65: "",
      wunsch66: "",
      wunsch67: "",
      wunsch68: "",
      wunsch69: "",
      wunsch70: "",
      wunsch71: "",
      wunsch72: "",
      wunsch73: "",
      wunsch74: "",
      wunsch75: "",
      wunsch76: "",
      wunsch77: "",
      wunsch78: "",
      wunsch79: "",
      wunsch80: "",
    });
  };
  handleChangeName4 = (event) => {
    this.setState({
      name4: event.target.value,
    });
    this.setState({
      wunsch1: "",
      wunsch2: "",
      wunsch3: "",
      wunsch4: "",
      wunsch5: "",
      wunsch6: "",
      wunsch7: "",
      wunsch8: "",
      wunsch9: "",
      wunsch10: "",
      wunsch11: "",
      wunsch12: "",
      wunsch13: "",
      wunsch14: "",
      wunsch15: "",
      wunsch16: "",
      wunsch17: "",
      wunsch18: "",
      wunsch19: "",
      wunsch20: "",
      wunsch21: "",
      wunsch22: "",
      wunsch23: "",
      wunsch24: "",
      wunsch25: "",
      wunsch26: "",
      wunsch27: "",
      wunsch28: "",
      wunsch29: "",
      wunsch30: "",
      wunsch31: "",
      wunsch32: "",
      wunsch33: "",
      wunsch34: "",
      wunsch35: "",
      wunsch36: "",
      wunsch37: "",
      wunsch38: "",
      wunsch39: "",
      wunsch40: "",
      wunsch41: "",
      wunsch42: "",
      wunsch43: "",
      wunsch44: "",
      wunsch45: "",
      wunsch46: "",
      wunsch47: "",
      wunsch48: "",
      wunsch49: "",
      wunsch50: "",
      wunsch51: "",
      wunsch52: "",
      wunsch53: "",
      wunsch54: "",
      wunsch55: "",
      wunsch56: "",
      wunsch57: "",
      wunsch58: "",
      wunsch59: "",
      wunsch60: "",
      wunsch61: "",
      wunsch62: "",
      wunsch63: "",
      wunsch64: "",
      wunsch65: "",
      wunsch66: "",
      wunsch67: "",
      wunsch68: "",
      wunsch69: "",
      wunsch70: "",
      wunsch71: "",
      wunsch72: "",
      wunsch73: "",
      wunsch74: "",
      wunsch75: "",
      wunsch76: "",
      wunsch77: "",
      wunsch78: "",
      wunsch79: "",
      wunsch80: "",
    });
  };
  handleChangeName5 = (event) => {
    this.setState({
      name5: event.target.value,
    });
    this.setState({
      wunsch1: "",
      wunsch2: "",
      wunsch3: "",
      wunsch4: "",
      wunsch5: "",
      wunsch6: "",
      wunsch7: "",
      wunsch8: "",
      wunsch9: "",
      wunsch10: "",
      wunsch11: "",
      wunsch12: "",
      wunsch13: "",
      wunsch14: "",
      wunsch15: "",
      wunsch16: "",
      wunsch17: "",
      wunsch18: "",
      wunsch19: "",
      wunsch20: "",
      wunsch21: "",
      wunsch22: "",
      wunsch23: "",
      wunsch24: "",
      wunsch25: "",
      wunsch26: "",
      wunsch27: "",
      wunsch28: "",
      wunsch29: "",
      wunsch30: "",
      wunsch31: "",
      wunsch32: "",
      wunsch33: "",
      wunsch34: "",
      wunsch35: "",
      wunsch36: "",
      wunsch37: "",
      wunsch38: "",
      wunsch39: "",
      wunsch40: "",
      wunsch41: "",
      wunsch42: "",
      wunsch43: "",
      wunsch44: "",
      wunsch45: "",
      wunsch46: "",
      wunsch47: "",
      wunsch48: "",
      wunsch49: "",
      wunsch50: "",
      wunsch51: "",
      wunsch52: "",
      wunsch53: "",
      wunsch54: "",
      wunsch55: "",
      wunsch56: "",
      wunsch57: "",
      wunsch58: "",
      wunsch59: "",
      wunsch60: "",
      wunsch61: "",
      wunsch62: "",
      wunsch63: "",
      wunsch64: "",
      wunsch65: "",
      wunsch66: "",
      wunsch67: "",
      wunsch68: "",
      wunsch69: "",
      wunsch70: "",
      wunsch71: "",
      wunsch72: "",
      wunsch73: "",
      wunsch74: "",
      wunsch75: "",
      wunsch76: "",
      wunsch77: "",
      wunsch78: "",
      wunsch79: "",
      wunsch80: "",
    });
  };
  handleChangeName6 = (event) => {
    this.setState({
      name6: event.target.value,
    });
    this.setState({
      wunsch1: "",
      wunsch2: "",
      wunsch3: "",
      wunsch4: "",
      wunsch5: "",
      wunsch6: "",
      wunsch7: "",
      wunsch8: "",
      wunsch9: "",
      wunsch10: "",
      wunsch11: "",
      wunsch12: "",
      wunsch13: "",
      wunsch14: "",
      wunsch15: "",
      wunsch16: "",
      wunsch17: "",
      wunsch18: "",
      wunsch19: "",
      wunsch20: "",
      wunsch21: "",
      wunsch22: "",
      wunsch23: "",
      wunsch24: "",
      wunsch25: "",
      wunsch26: "",
      wunsch27: "",
      wunsch28: "",
      wunsch29: "",
      wunsch30: "",
      wunsch31: "",
      wunsch32: "",
      wunsch33: "",
      wunsch34: "",
      wunsch35: "",
      wunsch36: "",
      wunsch37: "",
      wunsch38: "",
      wunsch39: "",
      wunsch40: "",
      wunsch41: "",
      wunsch42: "",
      wunsch43: "",
      wunsch44: "",
      wunsch45: "",
      wunsch46: "",
      wunsch47: "",
      wunsch48: "",
      wunsch49: "",
      wunsch50: "",
      wunsch51: "",
      wunsch52: "",
      wunsch53: "",
      wunsch54: "",
      wunsch55: "",
      wunsch56: "",
      wunsch57: "",
      wunsch58: "",
      wunsch59: "",
      wunsch60: "",
      wunsch61: "",
      wunsch62: "",
      wunsch63: "",
      wunsch64: "",
      wunsch65: "",
      wunsch66: "",
      wunsch67: "",
      wunsch68: "",
      wunsch69: "",
      wunsch70: "",
      wunsch71: "",
      wunsch72: "",
      wunsch73: "",
      wunsch74: "",
      wunsch75: "",
      wunsch76: "",
      wunsch77: "",
      wunsch78: "",
      wunsch79: "",
      wunsch80: "",
    });
  };
  handleChangeName7 = (event) => {
    this.setState({
      name7: event.target.value,
    });
    this.setState({
      wunsch1: "",
      wunsch2: "",
      wunsch3: "",
      wunsch4: "",
      wunsch5: "",
      wunsch6: "",
      wunsch7: "",
      wunsch8: "",
      wunsch9: "",
      wunsch10: "",
      wunsch11: "",
      wunsch12: "",
      wunsch13: "",
      wunsch14: "",
      wunsch15: "",
      wunsch16: "",
      wunsch17: "",
      wunsch18: "",
      wunsch19: "",
      wunsch20: "",
      wunsch21: "",
      wunsch22: "",
      wunsch23: "",
      wunsch24: "",
      wunsch25: "",
      wunsch26: "",
      wunsch27: "",
      wunsch28: "",
      wunsch29: "",
      wunsch30: "",
      wunsch31: "",
      wunsch32: "",
      wunsch33: "",
      wunsch34: "",
      wunsch35: "",
      wunsch36: "",
      wunsch37: "",
      wunsch38: "",
      wunsch39: "",
      wunsch40: "",
      wunsch41: "",
      wunsch42: "",
      wunsch43: "",
      wunsch44: "",
      wunsch45: "",
      wunsch46: "",
      wunsch47: "",
      wunsch48: "",
      wunsch49: "",
      wunsch50: "",
      wunsch51: "",
      wunsch52: "",
      wunsch53: "",
      wunsch54: "",
      wunsch55: "",
      wunsch56: "",
      wunsch57: "",
      wunsch58: "",
      wunsch59: "",
      wunsch60: "",
      wunsch61: "",
      wunsch62: "",
      wunsch63: "",
      wunsch64: "",
      wunsch65: "",
      wunsch66: "",
      wunsch67: "",
      wunsch68: "",
      wunsch69: "",
      wunsch70: "",
      wunsch71: "",
      wunsch72: "",
      wunsch73: "",
      wunsch74: "",
      wunsch75: "",
      wunsch76: "",
      wunsch77: "",
      wunsch78: "",
      wunsch79: "",
      wunsch80: "",
    });
  };
  handleChangeName8 = (event) => {
    this.setState({
      name8: event.target.value,
    });
    this.setState({
      wunsch1: "",
      wunsch2: "",
      wunsch3: "",
      wunsch4: "",
      wunsch5: "",
      wunsch6: "",
      wunsch7: "",
      wunsch8: "",
      wunsch9: "",
      wunsch10: "",
      wunsch11: "",
      wunsch12: "",
      wunsch13: "",
      wunsch14: "",
      wunsch15: "",
      wunsch16: "",
      wunsch17: "",
      wunsch18: "",
      wunsch19: "",
      wunsch20: "",
      wunsch21: "",
      wunsch22: "",
      wunsch23: "",
      wunsch24: "",
      wunsch25: "",
      wunsch26: "",
      wunsch27: "",
      wunsch28: "",
      wunsch29: "",
      wunsch30: "",
      wunsch31: "",
      wunsch32: "",
      wunsch33: "",
      wunsch34: "",
      wunsch35: "",
      wunsch36: "",
      wunsch37: "",
      wunsch38: "",
      wunsch39: "",
      wunsch40: "",
      wunsch41: "",
      wunsch42: "",
      wunsch43: "",
      wunsch44: "",
      wunsch45: "",
      wunsch46: "",
      wunsch47: "",
      wunsch48: "",
      wunsch49: "",
      wunsch50: "",
      wunsch51: "",
      wunsch52: "",
      wunsch53: "",
      wunsch54: "",
      wunsch55: "",
      wunsch56: "",
      wunsch57: "",
      wunsch58: "",
      wunsch59: "",
      wunsch60: "",
      wunsch61: "",
      wunsch62: "",
      wunsch63: "",
      wunsch64: "",
      wunsch65: "",
      wunsch66: "",
      wunsch67: "",
      wunsch68: "",
      wunsch69: "",
      wunsch70: "",
      wunsch71: "",
      wunsch72: "",
      wunsch73: "",
      wunsch74: "",
      wunsch75: "",
      wunsch76: "",
      wunsch77: "",
      wunsch78: "",
      wunsch79: "",
      wunsch80: "",
    });
  };
  handleChangeZeit111 = (event) => {
    this.setState({
      zeit111: event.target.value,
    });
  };
  handleChangeZeit112 = (event) => {
    this.setState({
      zeit112: event.target.value,
    });
  };
  handleChangeZeit113 = (event) => {
    this.setState({
      zeit113: event.target.value,
    });
  };
  handleChangeZeit114 = (event) => {
    this.setState({
      zeit114: event.target.value,
    });
  };
  handleChangeZeit115 = (event) => {
    this.setState({
      zeit115: event.target.value,
    });
  };
  handleChangeZeit116 = (event) => {
    this.setState({
      zeit116: event.target.value,
    });
  };
  handleChangeZeit121 = (event) => {
    this.setState({
      zeit121: event.target.value,
    });
  };
  handleChangeZeit122 = (event) => {
    this.setState({
      zeit122: event.target.value,
    });
  };
  handleChangeZeit123 = (event) => {
    this.setState({
      zeit123: event.target.value,
    });
  };
  handleChangeZeit124 = (event) => {
    this.setState({
      zeit124: event.target.value,
    });
  };
  handleChangeZeit125 = (event) => {
    this.setState({
      zeit125: event.target.value,
    });
  };
  handleChangeZeit126 = (event) => {
    this.setState({
      zeit126: event.target.value,
    });
  };
  handleChangeZeit131 = (event) => {
    this.setState({
      zeit131: event.target.value,
    });
  };
  handleChangeZeit132 = (event) => {
    this.setState({
      zeit132: event.target.value,
    });
  };
  handleChangeZeit133 = (event) => {
    this.setState({
      zeit133: event.target.value,
    });
  };
  handleChangeZeit134 = (event) => {
    this.setState({
      zeit134: event.target.value,
    });
  };
  handleChangeZeit135 = (event) => {
    this.setState({
      zeit135: event.target.value,
    });
  };
  handleChangeZeit136 = (event) => {
    this.setState({
      zeit136: event.target.value,
    });
  };
  handleChangeZeit141 = (event) => {
    this.setState({
      zeit141: event.target.value,
    });
  };
  handleChangeZeit142 = (event) => {
    this.setState({
      zeit142: event.target.value,
    });
  };
  handleChangeZeit143 = (event) => {
    this.setState({
      zeit143: event.target.value,
    });
  };
  handleChangeZeit144 = (event) => {
    this.setState({
      zeit144: event.target.value,
    });
  };
  handleChangeZeit145 = (event) => {
    this.setState({
      zeit145: event.target.value,
    });
  };
  handleChangeZeit146 = (event) => {
    this.setState({
      zeit146: event.target.value,
    });
  };
  handleChangeZeit151 = (event) => {
    this.setState({
      zeit151: event.target.value,
    });
  };
  handleChangeZeit152 = (event) => {
    this.setState({
      zeit152: event.target.value,
    });
  };
  handleChangeZeit153 = (event) => {
    this.setState({
      zeit153: event.target.value,
    });
  };
  handleChangeZeit154 = (event) => {
    this.setState({
      zeit154: event.target.value,
    });
  };
  handleChangeZeit155 = (event) => {
    this.setState({
      zeit155: event.target.value,
    });
  };
  handleChangeZeit156 = (event) => {
    this.setState({
      zeit156: event.target.value,
    });
  };
  handleChangeZeit161 = (event) => {
    this.setState({
      zeit161: event.target.value,
    });
  };
  handleChangeZeit162 = (event) => {
    this.setState({
      zeit162: event.target.value,
    });
  };
  handleChangeZeit163 = (event) => {
    this.setState({
      zeit163: event.target.value,
    });
  };
  handleChangeZeit164 = (event) => {
    this.setState({
      zeit164: event.target.value,
    });
  };
  handleChangeZeit165 = (event) => {
    this.setState({
      zeit165: event.target.value,
    });
  };
  handleChangeZeit166 = (event) => {
    this.setState({
      zeit166: event.target.value,
    });
  };
  handleChangeZeit171 = (event) => {
    this.setState({
      zeit171: event.target.value,
    });
  };
  handleChangeZeit172 = (event) => {
    this.setState({
      zeit172: event.target.value,
    });
  };
  handleChangeZeit173 = (event) => {
    this.setState({
      zeit173: event.target.value,
    });
  };
  handleChangeZeit174 = (event) => {
    this.setState({
      zeit174: event.target.value,
    });
  };
  handleChangeZeit175 = (event) => {
    this.setState({
      zeit175: event.target.value,
    });
  };
  handleChangeZeit176 = (event) => {
    this.setState({
      zeit176: event.target.value,
    });
  };
  handleChangeZeit181 = (event) => {
    this.setState({
      zeit181: event.target.value,
    });
  };
  handleChangeZeit182 = (event) => {
    this.setState({
      zeit182: event.target.value,
    });
  };
  handleChangeZeit183 = (event) => {
    this.setState({
      zeit183: event.target.value,
    });
  };
  handleChangeZeit184 = (event) => {
    this.setState({
      zeit184: event.target.value,
    });
  };
  handleChangeZeit185 = (event) => {
    this.setState({
      zeit185: event.target.value,
    });
  };
  handleChangeZeit186 = (event) => {
    this.setState({
      zeit186: event.target.value,
    });
  };
  handleChangeZeit211 = (event) => {
    this.setState({
      zeit211: event.target.value,
    });
  };
  handleChangeZeit212 = (event) => {
    this.setState({
      zeit212: event.target.value,
    });
  };
  handleChangeZeit213 = (event) => {
    this.setState({
      zeit213: event.target.value,
    });
  };
  handleChangeZeit214 = (event) => {
    this.setState({
      zeit214: event.target.value,
    });
  };
  handleChangeZeit215 = (event) => {
    this.setState({
      zeit215: event.target.value,
    });
  };
  handleChangeZeit216 = (event) => {
    this.setState({
      zeit216: event.target.value,
    });
  };
  handleChangeZeit221 = (event) => {
    this.setState({
      zeit221: event.target.value,
    });
  };
  handleChangeZeit222 = (event) => {
    this.setState({
      zeit222: event.target.value,
    });
  };
  handleChangeZeit223 = (event) => {
    this.setState({
      zeit223: event.target.value,
    });
  };
  handleChangeZeit224 = (event) => {
    this.setState({
      zeit224: event.target.value,
    });
  };
  handleChangeZeit225 = (event) => {
    this.setState({
      zeit225: event.target.value,
    });
  };
  handleChangeZeit226 = (event) => {
    this.setState({
      zeit226: event.target.value,
    });
  };
  handleChangeZeit231 = (event) => {
    this.setState({
      zeit231: event.target.value,
    });
  };
  handleChangeZeit232 = (event) => {
    this.setState({
      zeit232: event.target.value,
    });
  };
  handleChangeZeit233 = (event) => {
    this.setState({
      zeit233: event.target.value,
    });
  };
  handleChangeZeit234 = (event) => {
    this.setState({
      zeit234: event.target.value,
    });
  };
  handleChangeZeit235 = (event) => {
    this.setState({
      zeit235: event.target.value,
    });
  };
  handleChangeZeit236 = (event) => {
    this.setState({
      zeit236: event.target.value,
    });
  };
  handleChangeZeit241 = (event) => {
    this.setState({
      zeit241: event.target.value,
    });
  };
  handleChangeZeit242 = (event) => {
    this.setState({
      zeit242: event.target.value,
    });
  };
  handleChangeZeit243 = (event) => {
    this.setState({
      zeit243: event.target.value,
    });
  };
  handleChangeZeit244 = (event) => {
    this.setState({
      zeit244: event.target.value,
    });
  };
  handleChangeZeit245 = (event) => {
    this.setState({
      zeit245: event.target.value,
    });
  };
  handleChangeZeit246 = (event) => {
    this.setState({
      zeit246: event.target.value,
    });
  };
  handleChangeZeit251 = (event) => {
    this.setState({
      zeit251: event.target.value,
    });
  };
  handleChangeZeit252 = (event) => {
    this.setState({
      zeit252: event.target.value,
    });
  };
  handleChangeZeit253 = (event) => {
    this.setState({
      zeit253: event.target.value,
    });
  };
  handleChangeZeit254 = (event) => {
    this.setState({
      zeit254: event.target.value,
    });
  };
  handleChangeZeit255 = (event) => {
    this.setState({
      zeit255: event.target.value,
    });
  };
  handleChangeZeit256 = (event) => {
    this.setState({
      zeit256: event.target.value,
    });
  };
  handleChangeZeit261 = (event) => {
    this.setState({
      zeit261: event.target.value,
    });
  };
  handleChangeZeit262 = (event) => {
    this.setState({
      zeit262: event.target.value,
    });
  };
  handleChangeZeit263 = (event) => {
    this.setState({
      zeit263: event.target.value,
    });
  };
  handleChangeZeit264 = (event) => {
    this.setState({
      zeit264: event.target.value,
    });
  };
  handleChangeZeit265 = (event) => {
    this.setState({
      zeit265: event.target.value,
    });
  };
  handleChangeZeit266 = (event) => {
    this.setState({
      zeit266: event.target.value,
    });
  };
  handleChangeZeit271 = (event) => {
    this.setState({
      zeit271: event.target.value,
    });
  };
  handleChangeZeit272 = (event) => {
    this.setState({
      zeit272: event.target.value,
    });
  };
  handleChangeZeit273 = (event) => {
    this.setState({
      zeit273: event.target.value,
    });
  };
  handleChangeZeit274 = (event) => {
    this.setState({
      zeit274: event.target.value,
    });
  };
  handleChangeZeit275 = (event) => {
    this.setState({
      zeit275: event.target.value,
    });
  };
  handleChangeZeit276 = (event) => {
    this.setState({
      zeit276: event.target.value,
    });
  };
  handleChangeZeit281 = (event) => {
    this.setState({
      zeit281: event.target.value,
    });
  };
  handleChangeZeit282 = (event) => {
    this.setState({
      zeit282: event.target.value,
    });
  };
  handleChangeZeit283 = (event) => {
    this.setState({
      zeit283: event.target.value,
    });
  };
  handleChangeZeit284 = (event) => {
    this.setState({
      zeit284: event.target.value,
    });
  };
  handleChangeZeit285 = (event) => {
    this.setState({
      zeit285: event.target.value,
    });
  };
  handleChangeZeit286 = (event) => {
    this.setState({
      zeit286: event.target.value,
    });
  };
  handleChangeZeit311 = (event) => {
    this.setState({
      zeit311: event.target.value,
    });
  };
  handleChangeZeit312 = (event) => {
    this.setState({
      zeit312: event.target.value,
    });
  };
  handleChangeZeit313 = (event) => {
    this.setState({
      zeit313: event.target.value,
    });
  };
  handleChangeZeit314 = (event) => {
    this.setState({
      zeit314: event.target.value,
    });
  };
  handleChangeZeit315 = (event) => {
    this.setState({
      zeit315: event.target.value,
    });
  };
  handleChangeZeit316 = (event) => {
    this.setState({
      zeit316: event.target.value,
    });
  };
  handleChangeZeit321 = (event) => {
    this.setState({
      zeit321: event.target.value,
    });
  };
  handleChangeZeit322 = (event) => {
    this.setState({
      zeit322: event.target.value,
    });
  };
  handleChangeZeit323 = (event) => {
    this.setState({
      zeit323: event.target.value,
    });
  };
  handleChangeZeit324 = (event) => {
    this.setState({
      zeit324: event.target.value,
    });
  };
  handleChangeZeit325 = (event) => {
    this.setState({
      zeit325: event.target.value,
    });
  };
  handleChangeZeit326 = (event) => {
    this.setState({
      zeit326: event.target.value,
    });
  };
  handleChangeZeit331 = (event) => {
    this.setState({
      zeit331: event.target.value,
    });
  };
  handleChangeZeit332 = (event) => {
    this.setState({
      zeit332: event.target.value,
    });
  };
  handleChangeZeit333 = (event) => {
    this.setState({
      zeit333: event.target.value,
    });
  };
  handleChangeZeit334 = (event) => {
    this.setState({
      zeit334: event.target.value,
    });
  };
  handleChangeZeit335 = (event) => {
    this.setState({
      zeit335: event.target.value,
    });
  };
  handleChangeZeit336 = (event) => {
    this.setState({
      zeit336: event.target.value,
    });
  };
  handleChangeZeit341 = (event) => {
    this.setState({
      zeit341: event.target.value,
    });
  };
  handleChangeZeit342 = (event) => {
    this.setState({
      zeit342: event.target.value,
    });
  };
  handleChangeZeit343 = (event) => {
    this.setState({
      zeit343: event.target.value,
    });
  };
  handleChangeZeit344 = (event) => {
    this.setState({
      zeit344: event.target.value,
    });
  };
  handleChangeZeit345 = (event) => {
    this.setState({
      zeit345: event.target.value,
    });
  };
  handleChangeZeit346 = (event) => {
    this.setState({
      zeit346: event.target.value,
    });
  };
  handleChangeZeit351 = (event) => {
    this.setState({
      zeit351: event.target.value,
    });
  };
  handleChangeZeit352 = (event) => {
    this.setState({
      zeit352: event.target.value,
    });
  };
  handleChangeZeit353 = (event) => {
    this.setState({
      zeit353: event.target.value,
    });
  };
  handleChangeZeit354 = (event) => {
    this.setState({
      zeit354: event.target.value,
    });
  };
  handleChangeZeit355 = (event) => {
    this.setState({
      zeit355: event.target.value,
    });
  };
  handleChangeZeit356 = (event) => {
    this.setState({
      zeit356: event.target.value,
    });
  };
  handleChangeZeit361 = (event) => {
    this.setState({
      zeit361: event.target.value,
    });
  };
  handleChangeZeit362 = (event) => {
    this.setState({
      zeit362: event.target.value,
    });
  };
  handleChangeZeit363 = (event) => {
    this.setState({
      zeit363: event.target.value,
    });
  };
  handleChangeZeit364 = (event) => {
    this.setState({
      zeit364: event.target.value,
    });
  };
  handleChangeZeit365 = (event) => {
    this.setState({
      zeit365: event.target.value,
    });
  };
  handleChangeZeit366 = (event) => {
    this.setState({
      zeit366: event.target.value,
    });
  };
  handleChangeZeit371 = (event) => {
    this.setState({
      zeit371: event.target.value,
    });
  };
  handleChangeZeit372 = (event) => {
    this.setState({
      zeit372: event.target.value,
    });
  };
  handleChangeZeit373 = (event) => {
    this.setState({
      zeit373: event.target.value,
    });
  };
  handleChangeZeit374 = (event) => {
    this.setState({
      zeit374: event.target.value,
    });
  };
  handleChangeZeit375 = (event) => {
    this.setState({
      zeit375: event.target.value,
    });
  };
  handleChangeZeit376 = (event) => {
    this.setState({
      zeit376: event.target.value,
    });
  };
  handleChangeZeit381 = (event) => {
    this.setState({
      zeit381: event.target.value,
    });
  };
  handleChangeZeit382 = (event) => {
    this.setState({
      zeit382: event.target.value,
    });
  };
  handleChangeZeit383 = (event) => {
    this.setState({
      zeit383: event.target.value,
    });
  };
  handleChangeZeit384 = (event) => {
    this.setState({
      zeit384: event.target.value,
    });
  };
  handleChangeZeit385 = (event) => {
    this.setState({
      zeit385: event.target.value,
    });
  };
  handleChangeZeit386 = (event) => {
    this.setState({
      zeit386: event.target.value,
    });
  };
  handleChangeZeit411 = (event) => {
    this.setState({
      zeit411: event.target.value,
    });
  };
  handleChangeZeit412 = (event) => {
    this.setState({
      zeit412: event.target.value,
    });
  };
  handleChangeZeit413 = (event) => {
    this.setState({
      zeit413: event.target.value,
    });
  };
  handleChangeZeit414 = (event) => {
    this.setState({
      zeit414: event.target.value,
    });
  };
  handleChangeZeit415 = (event) => {
    this.setState({
      zeit415: event.target.value,
    });
  };
  handleChangeZeit416 = (event) => {
    this.setState({
      zeit416: event.target.value,
    });
  };
  handleChangeZeit421 = (event) => {
    this.setState({
      zeit421: event.target.value,
    });
  };
  handleChangeZeit422 = (event) => {
    this.setState({
      zeit422: event.target.value,
    });
  };
  handleChangeZeit423 = (event) => {
    this.setState({
      zeit423: event.target.value,
    });
  };
  handleChangeZeit424 = (event) => {
    this.setState({
      zeit424: event.target.value,
    });
  };
  handleChangeZeit425 = (event) => {
    this.setState({
      zeit425: event.target.value,
    });
  };
  handleChangeZeit426 = (event) => {
    this.setState({
      zeit426: event.target.value,
    });
  };
  handleChangeZeit431 = (event) => {
    this.setState({
      zeit431: event.target.value,
    });
  };
  handleChangeZeit432 = (event) => {
    this.setState({
      zeit432: event.target.value,
    });
  };
  handleChangeZeit433 = (event) => {
    this.setState({
      zeit433: event.target.value,
    });
  };
  handleChangeZeit434 = (event) => {
    this.setState({
      zeit434: event.target.value,
    });
  };
  handleChangeZeit435 = (event) => {
    this.setState({
      zeit435: event.target.value,
    });
  };
  handleChangeZeit436 = (event) => {
    this.setState({
      zeit436: event.target.value,
    });
  };
  handleChangeZeit441 = (event) => {
    this.setState({
      zeit441: event.target.value,
    });
  };
  handleChangeZeit442 = (event) => {
    this.setState({
      zeit442: event.target.value,
    });
  };
  handleChangeZeit443 = (event) => {
    this.setState({
      zeit443: event.target.value,
    });
  };
  handleChangeZeit444 = (event) => {
    this.setState({
      zeit444: event.target.value,
    });
  };
  handleChangeZeit445 = (event) => {
    this.setState({
      zeit445: event.target.value,
    });
  };
  handleChangeZeit446 = (event) => {
    this.setState({
      zeit446: event.target.value,
    });
  };
  handleChangeZeit451 = (event) => {
    this.setState({
      zeit451: event.target.value,
    });
  };
  handleChangeZeit452 = (event) => {
    this.setState({
      zeit452: event.target.value,
    });
  };
  handleChangeZeit453 = (event) => {
    this.setState({
      zeit453: event.target.value,
    });
  };
  handleChangeZeit454 = (event) => {
    this.setState({
      zeit454: event.target.value,
    });
  };
  handleChangeZeit455 = (event) => {
    this.setState({
      zeit455: event.target.value,
    });
  };
  handleChangeZeit456 = (event) => {
    this.setState({
      zeit456: event.target.value,
    });
  };
  handleChangeZeit461 = (event) => {
    this.setState({
      zeit461: event.target.value,
    });
  };
  handleChangeZeit462 = (event) => {
    this.setState({
      zeit462: event.target.value,
    });
  };
  handleChangeZeit463 = (event) => {
    this.setState({
      zeit463: event.target.value,
    });
  };
  handleChangeZeit464 = (event) => {
    this.setState({
      zeit464: event.target.value,
    });
  };
  handleChangeZeit465 = (event) => {
    this.setState({
      zeit465: event.target.value,
    });
  };
  handleChangeZeit466 = (event) => {
    this.setState({
      zeit466: event.target.value,
    });
  };
  handleChangeZeit471 = (event) => {
    this.setState({
      zeit471: event.target.value,
    });
  };
  handleChangeZeit472 = (event) => {
    this.setState({
      zeit472: event.target.value,
    });
  };
  handleChangeZeit473 = (event) => {
    this.setState({
      zeit473: event.target.value,
    });
  };
  handleChangeZeit474 = (event) => {
    this.setState({
      zeit474: event.target.value,
    });
  };
  handleChangeZeit475 = (event) => {
    this.setState({
      zeit475: event.target.value,
    });
  };
  handleChangeZeit476 = (event) => {
    this.setState({
      zeit476: event.target.value,
    });
  };
  handleChangeZeit481 = (event) => {
    this.setState({
      zeit481: event.target.value,
    });
  };
  handleChangeZeit482 = (event) => {
    this.setState({
      zeit482: event.target.value,
    });
  };
  handleChangeZeit483 = (event) => {
    this.setState({
      zeit483: event.target.value,
    });
  };
  handleChangeZeit484 = (event) => {
    this.setState({
      zeit484: event.target.value,
    });
  };
  handleChangeZeit485 = (event) => {
    this.setState({
      zeit485: event.target.value,
    });
  };
  handleChangeZeit486 = (event) => {
    this.setState({
      zeit486: event.target.value,
    });
  };
  handleChangeZeit511 = (event) => {
    this.setState({
      zeit511: event.target.value,
    });
  };
  handleChangeZeit512 = (event) => {
    this.setState({
      zeit512: event.target.value,
    });
  };
  handleChangeZeit513 = (event) => {
    this.setState({
      zeit513: event.target.value,
    });
  };
  handleChangeZeit514 = (event) => {
    this.setState({
      zeit514: event.target.value,
    });
  };
  handleChangeZeit515 = (event) => {
    this.setState({
      zeit515: event.target.value,
    });
  };
  handleChangeZeit516 = (event) => {
    this.setState({
      zeit516: event.target.value,
    });
  };
  handleChangeZeit521 = (event) => {
    this.setState({
      zeit521: event.target.value,
    });
  };
  handleChangeZeit522 = (event) => {
    this.setState({
      zeit522: event.target.value,
    });
  };
  handleChangeZeit523 = (event) => {
    this.setState({
      zeit523: event.target.value,
    });
  };
  handleChangeZeit524 = (event) => {
    this.setState({
      zeit524: event.target.value,
    });
  };
  handleChangeZeit525 = (event) => {
    this.setState({
      zeit525: event.target.value,
    });
  };
  handleChangeZeit526 = (event) => {
    this.setState({
      zeit526: event.target.value,
    });
  };
  handleChangeZeit531 = (event) => {
    this.setState({
      zeit531: event.target.value,
    });
  };
  handleChangeZeit532 = (event) => {
    this.setState({
      zeit532: event.target.value,
    });
  };
  handleChangeZeit533 = (event) => {
    this.setState({
      zeit533: event.target.value,
    });
  };
  handleChangeZeit534 = (event) => {
    this.setState({
      zeit534: event.target.value,
    });
  };
  handleChangeZeit535 = (event) => {
    this.setState({
      zeit535: event.target.value,
    });
  };
  handleChangeZeit536 = (event) => {
    this.setState({
      zeit536: event.target.value,
    });
  };
  handleChangeZeit541 = (event) => {
    this.setState({
      zeit541: event.target.value,
    });
  };
  handleChangeZeit542 = (event) => {
    this.setState({
      zeit542: event.target.value,
    });
  };
  handleChangeZeit543 = (event) => {
    this.setState({
      zeit543: event.target.value,
    });
  };
  handleChangeZeit544 = (event) => {
    this.setState({
      zeit544: event.target.value,
    });
  };
  handleChangeZeit545 = (event) => {
    this.setState({
      zeit545: event.target.value,
    });
  };
  handleChangeZeit546 = (event) => {
    this.setState({
      zeit546: event.target.value,
    });
  };
  handleChangeZeit551 = (event) => {
    this.setState({
      zeit551: event.target.value,
    });
  };
  handleChangeZeit552 = (event) => {
    this.setState({
      zeit552: event.target.value,
    });
  };
  handleChangeZeit553 = (event) => {
    this.setState({
      zeit553: event.target.value,
    });
  };
  handleChangeZeit554 = (event) => {
    this.setState({
      zeit554: event.target.value,
    });
  };
  handleChangeZeit555 = (event) => {
    this.setState({
      zeit555: event.target.value,
    });
  };
  handleChangeZeit556 = (event) => {
    this.setState({
      zeit556: event.target.value,
    });
  };
  handleChangeZeit561 = (event) => {
    this.setState({
      zeit561: event.target.value,
    });
  };
  handleChangeZeit562 = (event) => {
    this.setState({
      zeit562: event.target.value,
    });
  };
  handleChangeZeit563 = (event) => {
    this.setState({
      zeit563: event.target.value,
    });
  };
  handleChangeZeit564 = (event) => {
    this.setState({
      zeit564: event.target.value,
    });
  };
  handleChangeZeit565 = (event) => {
    this.setState({
      zeit565: event.target.value,
    });
  };
  handleChangeZeit566 = (event) => {
    this.setState({
      zeit566: event.target.value,
    });
  };
  handleChangeZeit571 = (event) => {
    this.setState({
      zeit571: event.target.value,
    });
  };
  handleChangeZeit572 = (event) => {
    this.setState({
      zeit572: event.target.value,
    });
  };
  handleChangeZeit573 = (event) => {
    this.setState({
      zeit573: event.target.value,
    });
  };
  handleChangeZeit574 = (event) => {
    this.setState({
      zeit574: event.target.value,
    });
  };
  handleChangeZeit575 = (event) => {
    this.setState({
      zeit575: event.target.value,
    });
  };
  handleChangeZeit576 = (event) => {
    this.setState({
      zeit576: event.target.value,
    });
  };
  handleChangeZeit581 = (event) => {
    this.setState({
      zeit581: event.target.value,
    });
  };
  handleChangeZeit582 = (event) => {
    this.setState({
      zeit582: event.target.value,
    });
  };
  handleChangeZeit583 = (event) => {
    this.setState({
      zeit583: event.target.value,
    });
  };
  handleChangeZeit584 = (event) => {
    this.setState({
      zeit584: event.target.value,
    });
  };
  handleChangeZeit585 = (event) => {
    this.setState({
      zeit585: event.target.value,
    });
  };
  handleChangeZeit586 = (event) => {
    this.setState({
      zeit586: event.target.value,
    });
  };
  handleChangeZeit611 = (event) => {
    this.setState({
      zeit611: event.target.value,
    });
  };
  handleChangeZeit612 = (event) => {
    this.setState({
      zeit612: event.target.value,
    });
  };
  handleChangeZeit613 = (event) => {
    this.setState({
      zeit613: event.target.value,
    });
  };
  handleChangeZeit614 = (event) => {
    this.setState({
      zeit614: event.target.value,
    });
  };
  handleChangeZeit615 = (event) => {
    this.setState({
      zeit615: event.target.value,
    });
  };
  handleChangeZeit616 = (event) => {
    this.setState({
      zeit616: event.target.value,
    });
  };
  handleChangeZeit621 = (event) => {
    this.setState({
      zeit621: event.target.value,
    });
  };
  handleChangeZeit622 = (event) => {
    this.setState({
      zeit622: event.target.value,
    });
  };
  handleChangeZeit623 = (event) => {
    this.setState({
      zeit623: event.target.value,
    });
  };
  handleChangeZeit624 = (event) => {
    this.setState({
      zeit624: event.target.value,
    });
  };
  handleChangeZeit625 = (event) => {
    this.setState({
      zeit625: event.target.value,
    });
  };
  handleChangeZeit626 = (event) => {
    this.setState({
      zeit626: event.target.value,
    });
  };
  handleChangeZeit631 = (event) => {
    this.setState({
      zeit631: event.target.value,
    });
  };
  handleChangeZeit632 = (event) => {
    this.setState({
      zeit632: event.target.value,
    });
  };
  handleChangeZeit633 = (event) => {
    this.setState({
      zeit633: event.target.value,
    });
  };
  handleChangeZeit634 = (event) => {
    this.setState({
      zeit634: event.target.value,
    });
  };
  handleChangeZeit635 = (event) => {
    this.setState({
      zeit635: event.target.value,
    });
  };
  handleChangeZeit636 = (event) => {
    this.setState({
      zeit636: event.target.value,
    });
  };
  handleChangeZeit641 = (event) => {
    this.setState({
      zeit641: event.target.value,
    });
  };
  handleChangeZeit642 = (event) => {
    this.setState({
      zeit642: event.target.value,
    });
  };
  handleChangeZeit643 = (event) => {
    this.setState({
      zeit643: event.target.value,
    });
  };
  handleChangeZeit644 = (event) => {
    this.setState({
      zeit644: event.target.value,
    });
  };
  handleChangeZeit645 = (event) => {
    this.setState({
      zeit645: event.target.value,
    });
  };
  handleChangeZeit646 = (event) => {
    this.setState({
      zeit646: event.target.value,
    });
  };
  handleChangeZeit651 = (event) => {
    this.setState({
      zeit651: event.target.value,
    });
  };
  handleChangeZeit652 = (event) => {
    this.setState({
      zeit652: event.target.value,
    });
  };
  handleChangeZeit653 = (event) => {
    this.setState({
      zeit653: event.target.value,
    });
  };
  handleChangeZeit654 = (event) => {
    this.setState({
      zeit654: event.target.value,
    });
  };
  handleChangeZeit655 = (event) => {
    this.setState({
      zeit655: event.target.value,
    });
  };
  handleChangeZeit656 = (event) => {
    this.setState({
      zeit656: event.target.value,
    });
  };
  handleChangeZeit661 = (event) => {
    this.setState({
      zeit661: event.target.value,
    });
  };
  handleChangeZeit662 = (event) => {
    this.setState({
      zeit662: event.target.value,
    });
  };
  handleChangeZeit663 = (event) => {
    this.setState({
      zeit663: event.target.value,
    });
  };
  handleChangeZeit664 = (event) => {
    this.setState({
      zeit664: event.target.value,
    });
  };
  handleChangeZeit665 = (event) => {
    this.setState({
      zeit665: event.target.value,
    });
  };
  handleChangeZeit666 = (event) => {
    this.setState({
      zeit666: event.target.value,
    });
  };
  handleChangeZeit671 = (event) => {
    this.setState({
      zeit671: event.target.value,
    });
  };
  handleChangeZeit672 = (event) => {
    this.setState({
      zeit672: event.target.value,
    });
  };
  handleChangeZeit673 = (event) => {
    this.setState({
      zeit673: event.target.value,
    });
  };
  handleChangeZeit674 = (event) => {
    this.setState({
      zeit674: event.target.value,
    });
  };
  handleChangeZeit675 = (event) => {
    this.setState({
      zeit675: event.target.value,
    });
  };
  handleChangeZeit676 = (event) => {
    this.setState({
      zeit676: event.target.value,
    });
  };
  handleChangeZeit681 = (event) => {
    this.setState({
      zeit681: event.target.value,
    });
  };
  handleChangeZeit682 = (event) => {
    this.setState({
      zeit682: event.target.value,
    });
  };
  handleChangeZeit683 = (event) => {
    this.setState({
      zeit683: event.target.value,
    });
  };
  handleChangeZeit684 = (event) => {
    this.setState({
      zeit684: event.target.value,
    });
  };
  handleChangeZeit685 = (event) => {
    this.setState({
      zeit685: event.target.value,
    });
  };
  handleChangeZeit686 = (event) => {
    this.setState({
      zeit686: event.target.value,
    });
  };
  handleChangeZeit711 = (event) => {
    this.setState({
      zeit711: event.target.value,
    });
  };
  handleChangeZeit712 = (event) => {
    this.setState({
      zeit712: event.target.value,
    });
  };
  handleChangeZeit713 = (event) => {
    this.setState({
      zeit713: event.target.value,
    });
  };
  handleChangeZeit714 = (event) => {
    this.setState({
      zeit714: event.target.value,
    });
  };
  handleChangeZeit715 = (event) => {
    this.setState({
      zeit715: event.target.value,
    });
  };
  handleChangeZeit716 = (event) => {
    this.setState({
      zeit716: event.target.value,
    });
  };
  handleChangeZeit721 = (event) => {
    this.setState({
      zeit721: event.target.value,
    });
  };
  handleChangeZeit722 = (event) => {
    this.setState({
      zeit722: event.target.value,
    });
  };
  handleChangeZeit723 = (event) => {
    this.setState({
      zeit723: event.target.value,
    });
  };
  handleChangeZeit724 = (event) => {
    this.setState({
      zeit724: event.target.value,
    });
  };
  handleChangeZeit725 = (event) => {
    this.setState({
      zeit725: event.target.value,
    });
  };
  handleChangeZeit726 = (event) => {
    this.setState({
      zeit726: event.target.value,
    });
  };
  handleChangeZeit731 = (event) => {
    this.setState({
      zeit731: event.target.value,
    });
  };
  handleChangeZeit732 = (event) => {
    this.setState({
      zeit732: event.target.value,
    });
  };
  handleChangeZeit733 = (event) => {
    this.setState({
      zeit733: event.target.value,
    });
  };
  handleChangeZeit734 = (event) => {
    this.setState({
      zeit734: event.target.value,
    });
  };
  handleChangeZeit735 = (event) => {
    this.setState({
      zeit735: event.target.value,
    });
  };
  handleChangeZeit736 = (event) => {
    this.setState({
      zeit736: event.target.value,
    });
  };
  handleChangeZeit741 = (event) => {
    this.setState({
      zeit741: event.target.value,
    });
  };
  handleChangeZeit742 = (event) => {
    this.setState({
      zeit742: event.target.value,
    });
  };
  handleChangeZeit743 = (event) => {
    this.setState({
      zeit743: event.target.value,
    });
  };
  handleChangeZeit744 = (event) => {
    this.setState({
      zeit744: event.target.value,
    });
  };
  handleChangeZeit745 = (event) => {
    this.setState({
      zeit745: event.target.value,
    });
  };
  handleChangeZeit746 = (event) => {
    this.setState({
      zeit746: event.target.value,
    });
  };
  handleChangeZeit751 = (event) => {
    this.setState({
      zeit751: event.target.value,
    });
  };
  handleChangeZeit752 = (event) => {
    this.setState({
      zeit752: event.target.value,
    });
  };
  handleChangeZeit753 = (event) => {
    this.setState({
      zeit753: event.target.value,
    });
  };
  handleChangeZeit754 = (event) => {
    this.setState({
      zeit754: event.target.value,
    });
  };
  handleChangeZeit755 = (event) => {
    this.setState({
      zeit755: event.target.value,
    });
  };
  handleChangeZeit756 = (event) => {
    this.setState({
      zeit756: event.target.value,
    });
  };
  handleChangeZeit761 = (event) => {
    this.setState({
      zeit761: event.target.value,
    });
  };
  handleChangeZeit762 = (event) => {
    this.setState({
      zeit762: event.target.value,
    });
  };
  handleChangeZeit763 = (event) => {
    this.setState({
      zeit763: event.target.value,
    });
  };
  handleChangeZeit764 = (event) => {
    this.setState({
      zeit764: event.target.value,
    });
  };
  handleChangeZeit765 = (event) => {
    this.setState({
      zeit765: event.target.value,
    });
  };
  handleChangeZeit766 = (event) => {
    this.setState({
      zeit766: event.target.value,
    });
  };
  handleChangeZeit771 = (event) => {
    this.setState({
      zeit771: event.target.value,
    });
  };
  handleChangeZeit772 = (event) => {
    this.setState({
      zeit772: event.target.value,
    });
  };
  handleChangeZeit773 = (event) => {
    this.setState({
      zeit773: event.target.value,
    });
  };
  handleChangeZeit774 = (event) => {
    this.setState({
      zeit774: event.target.value,
    });
  };
  handleChangeZeit775 = (event) => {
    this.setState({
      zeit775: event.target.value,
    });
  };
  handleChangeZeit776 = (event) => {
    this.setState({
      zeit776: event.target.value,
    });
  };
  handleChangeZeit781 = (event) => {
    this.setState({
      zeit781: event.target.value,
    });
  };
  handleChangeZeit782 = (event) => {
    this.setState({
      zeit782: event.target.value,
    });
  };
  handleChangeZeit783 = (event) => {
    this.setState({
      zeit783: event.target.value,
    });
  };
  handleChangeZeit784 = (event) => {
    this.setState({
      zeit784: event.target.value,
    });
  };
  handleChangeZeit785 = (event) => {
    this.setState({
      zeit785: event.target.value,
    });
  };
  handleChangeZeit786 = (event) => {
    this.setState({
      zeit786: event.target.value,
    });
  };
  handleChangeZeit811 = (event) => {
    this.setState({
      zeit811: event.target.value,
    });
  };
  handleChangeZeit812 = (event) => {
    this.setState({
      zeit812: event.target.value,
    });
  };
  handleChangeZeit813 = (event) => {
    this.setState({
      zeit813: event.target.value,
    });
  };
  handleChangeZeit814 = (event) => {
    this.setState({
      zeit814: event.target.value,
    });
  };
  handleChangeZeit815 = (event) => {
    this.setState({
      zeit815: event.target.value,
    });
  };
  handleChangeZeit816 = (event) => {
    this.setState({
      zeit816: event.target.value,
    });
  };
  handleChangeZeit821 = (event) => {
    this.setState({
      zeit821: event.target.value,
    });
  };
  handleChangeZeit822 = (event) => {
    this.setState({
      zeit822: event.target.value,
    });
  };
  handleChangeZeit823 = (event) => {
    this.setState({
      zeit823: event.target.value,
    });
  };
  handleChangeZeit824 = (event) => {
    this.setState({
      zeit824: event.target.value,
    });
  };
  handleChangeZeit825 = (event) => {
    this.setState({
      zeit825: event.target.value,
    });
  };
  handleChangeZeit826 = (event) => {
    this.setState({
      zeit826: event.target.value,
    });
  };
  handleChangeZeit831 = (event) => {
    this.setState({
      zeit831: event.target.value,
    });
  };
  handleChangeZeit832 = (event) => {
    this.setState({
      zeit832: event.target.value,
    });
  };
  handleChangeZeit833 = (event) => {
    this.setState({
      zeit833: event.target.value,
    });
  };
  handleChangeZeit834 = (event) => {
    this.setState({
      zeit834: event.target.value,
    });
  };
  handleChangeZeit835 = (event) => {
    this.setState({
      zeit835: event.target.value,
    });
  };
  handleChangeZeit836 = (event) => {
    this.setState({
      zeit836: event.target.value,
    });
  };
  handleChangeZeit841 = (event) => {
    this.setState({
      zeit841: event.target.value,
    });
  };
  handleChangeZeit842 = (event) => {
    this.setState({
      zeit842: event.target.value,
    });
  };
  handleChangeZeit843 = (event) => {
    this.setState({
      zeit843: event.target.value,
    });
  };
  handleChangeZeit844 = (event) => {
    this.setState({
      zeit844: event.target.value,
    });
  };
  handleChangeZeit845 = (event) => {
    this.setState({
      zeit845: event.target.value,
    });
  };
  handleChangeZeit846 = (event) => {
    this.setState({
      zeit846: event.target.value,
    });
  };
  handleChangeZeit851 = (event) => {
    this.setState({
      zeit851: event.target.value,
    });
  };
  handleChangeZeit852 = (event) => {
    this.setState({
      zeit852: event.target.value,
    });
  };
  handleChangeZeit853 = (event) => {
    this.setState({
      zeit853: event.target.value,
    });
  };
  handleChangeZeit854 = (event) => {
    this.setState({
      zeit854: event.target.value,
    });
  };
  handleChangeZeit855 = (event) => {
    this.setState({
      zeit855: event.target.value,
    });
  };
  handleChangeZeit856 = (event) => {
    this.setState({
      zeit856: event.target.value,
    });
  };
  handleChangeZeit861 = (event) => {
    this.setState({
      zeit861: event.target.value,
    });
  };
  handleChangeZeit862 = (event) => {
    this.setState({
      zeit862: event.target.value,
    });
  };
  handleChangeZeit863 = (event) => {
    this.setState({
      zeit863: event.target.value,
    });
  };
  handleChangeZeit864 = (event) => {
    this.setState({
      zeit864: event.target.value,
    });
  };
  handleChangeZeit865 = (event) => {
    this.setState({
      zeit865: event.target.value,
    });
  };
  handleChangeZeit866 = (event) => {
    this.setState({
      zeit866: event.target.value,
    });
  };
  handleChangeZeit871 = (event) => {
    this.setState({
      zeit871: event.target.value,
    });
  };
  handleChangeZeit872 = (event) => {
    this.setState({
      zeit872: event.target.value,
    });
  };
  handleChangeZeit873 = (event) => {
    this.setState({
      zeit873: event.target.value,
    });
  };
  handleChangeZeit874 = (event) => {
    this.setState({
      zeit874: event.target.value,
    });
  };
  handleChangeZeit875 = (event) => {
    this.setState({
      zeit875: event.target.value,
    });
  };
  handleChangeZeit876 = (event) => {
    this.setState({
      zeit876: event.target.value,
    });
  };
  handleChangeZeit881 = (event) => {
    this.setState({
      zeit881: event.target.value,
    });
  };
  handleChangeZeit882 = (event) => {
    this.setState({
      zeit882: event.target.value,
    });
  };
  handleChangeZeit883 = (event) => {
    this.setState({
      zeit883: event.target.value,
    });
  };
  handleChangeZeit884 = (event) => {
    this.setState({
      zeit884: event.target.value,
    });
  };
  handleChangeZeit885 = (event) => {
    this.setState({
      zeit885: event.target.value,
    });
  };
  handleChangeZeit886 = (event) => {
    this.setState({
      zeit886: event.target.value,
    });
  };
  handleChangeWunsch1 = (event) => {
    this.setState({
      wunsch1: event.target.value,
      wunsch2: "",
      wunsch3: "",
      wunsch4: "",
    });
  };
  handleChangeWunsch2 = (event) => {
    this.setState({
      wunsch2: event.target.value,
      wunsch3: "",
      wunsch4: "",
    });
  };
  handleChangeWunsch3 = (event) => {
    this.setState({
      wunsch3: event.target.value,
      wunsch4: "",
    });
  };
  handleChangeWunsch4 = (event) => {
    this.setState({
      wunsch4: event.target.value,
    });
  };
  handleChangeWunsch5 = (event) => {
    this.setState({
      wunsch5: event.target.value,
      wunsch6: "",
      wunsch7: "",
      wunsch8: "",
    });
  };
  handleChangeWunsch6 = (event) => {
    this.setState({
      wunsch6: event.target.value,
      wunsch7: "",
      wunsch8: "",
    });
  };
  handleChangeWunsch7 = (event) => {
    this.setState({
      wunsch7: event.target.value,
      wunsch8: "",
    });
  };
  handleChangeWunsch8 = (event) => {
    this.setState({
      wunsch8: event.target.value,
    });
  };
  handleChangeWunsch9 = (event) => {
    this.setState({
      wunsch9: event.target.value,
      wunsch10: "",
      wunsch11: "",
      wunsch12: "",
    });
  };
  handleChangeWunsch10 = (event) => {
    this.setState({
      wunsch10: event.target.value,
      wunsch11: "",
      wunsch12: "",
    });
  };
  handleChangeWunsch11 = (event) => {
    this.setState({
      wunsch11: event.target.value,
      wunsch12: "",
    });
  };
  handleChangeWunsch12 = (event) => {
    this.setState({
      wunsch12: event.target.value,
    });
  };
  handleChangeWunsch13 = (event) => {
    this.setState({
      wunsch13: event.target.value,
      wunsch14: "",
      wunsch15: "",
      wunsch16: "",
    });
  };
  handleChangeWunsch14 = (event) => {
    this.setState({
      wunsch14: event.target.value,
      wunsch15: "",
      wunsch16: "",
    });
  };
  handleChangeWunsch15 = (event) => {
    this.setState({
      wunsch15: event.target.value,
      wunsch16: "",
    });
  };
  handleChangeWunsch16 = (event) => {
    this.setState({
      wunsch16: event.target.value,
    });
  };
  handleChangeWunsch17 = (event) => {
    this.setState({
      wunsch17: event.target.value,
      wunsch18: "",
      wunsch19: "",
      wunsch20: "",
    });
  };
  handleChangeWunsch18 = (event) => {
    this.setState({
      wunsch18: event.target.value,
      wunsch19: "",
      wunsch20: "",
    });
  };
  handleChangeWunsch19 = (event) => {
    this.setState({
      wunsch19: event.target.value,
      wunsch20: "",
    });
  };
  handleChangeWunsch20 = (event) => {
    this.setState({
      wunsch20: event.target.value,
    });
  };
  handleChangeWunsch21 = (event) => {
    this.setState({
      wunsch21: event.target.value,
      wunsch22: "",
      wunsch23: "",
      wunsch24: "",
    });
  };
  handleChangeWunsch22 = (event) => {
    this.setState({
      wunsch22: event.target.value,
      wunsch23: "",
      wunsch24: "",
    });
  };
  handleChangeWunsch23 = (event) => {
    this.setState({
      wunsch23: event.target.value,
      wunsch24: "",
    });
  };
  handleChangeWunsch24 = (event) => {
    this.setState({
      wunsch24: event.target.value,
    });
  };
  handleChangeWunsch25 = (event) => {
    this.setState({
      wunsch25: event.target.value,
      wunsch26: "",
      wunsch27: "",
      wunsch28: "",
    });
  };
  handleChangeWunsch26 = (event) => {
    this.setState({
      wunsch26: event.target.value,
      wunsch27: "",
      wunsch28: "",
    });
  };
  handleChangeWunsch27 = (event) => {
    this.setState({
      wunsch27: event.target.value,
      wunsch28: "",
    });
  };
  handleChangeWunsch28 = (event) => {
    this.setState({
      wunsch28: event.target.value,
    });
  };
  handleChangeWunsch29 = (event) => {
    this.setState({
      wunsch29: event.target.value,
      wunsch30: "",
      wunsch31: "",
      wunsch32: "",
    });
  };
  handleChangeWunsch30 = (event) => {
    this.setState({
      wunsch30: event.target.value,
      wunsch31: "",
      wunsch32: "",
    });
  };
  handleChangeWunsch31 = (event) => {
    this.setState({
      wunsch31: event.target.value,
      wunsch32: "",
    });
  };
  handleChangeWunsch32 = (event) => {
    this.setState({
      wunsch32: event.target.value,
    });
  };
  handleChangeWunsch33 = (event) => {
    this.setState({
      wunsch33: event.target.value,
      wunsch34: "",
      wunsch35: "",
      wunsch36: "",
    });
  };
  handleChangeWunsch34 = (event) => {
    this.setState({
      wunsch34: event.target.value,
      wunsch35: "",
      wunsch36: "",
    });
  };
  handleChangeWunsch35 = (event) => {
    this.setState({
      wunsch35: event.target.value,
      wunsch36: "",
    });
  };
  handleChangeWunsch36 = (event) => {
    this.setState({
      wunsch36: event.target.value,
    });
  };
  handleChangeWunsch37 = (event) => {
    this.setState({
      wunsch37: event.target.value,
      wunsch38: "",
      wunsch39: "",
      wunsch40: "",
    });
  };
  handleChangeWunsch38 = (event) => {
    this.setState({
      wunsch38: event.target.value,
      wunsch39: "",
      wunsch40: "",
    });
  };
  handleChangeWunsch39 = (event) => {
    this.setState({
      wunsch39: event.target.value,
      wunsch40: "",
    });
  };
  handleChangeWunsch40 = (event) => {
    this.setState({
      wunsch40: event.target.value,
    });
  };
  handleChangeWunsch41 = (event) => {
    this.setState({
      wunsch41: event.target.value,
      wunsch42: "",
      wunsch43: "",
      wunsch44: "",
    });
  };
  handleChangeWunsch42 = (event) => {
    this.setState({
      wunsch42: event.target.value,
      wunsch43: "",
      wunsch44: "",
    });
  };
  handleChangeWunsch43 = (event) => {
    this.setState({
      wunsch43: event.target.value,
      wunsch44: "",
    });
  };
  handleChangeWunsch44 = (event) => {
    this.setState({
      wunsch44: event.target.value,
    });
  };
  handleChangeWunsch45 = (event) => {
    this.setState({
      wunsch45: event.target.value,
      wunsch46: "",
      wunsch47: "",
      wunsch48: "",
    });
  };
  handleChangeWunsch46 = (event) => {
    this.setState({
      wunsch46: event.target.value,
      wunsch47: "",
      wunsch48: "",
    });
  };
  handleChangeWunsch47 = (event) => {
    this.setState({
      wunsch47: event.target.value,
      wunsch48: "",
    });
  };
  handleChangeWunsch48 = (event) => {
    this.setState({
      wunsch48: event.target.value,
    });
  };
  handleChangeWunsch49 = (event) => {
    this.setState({
      wunsch49: event.target.value,
      wunsch50: "",
      wunsch51: "",
      wunsch52: "",
    });
  };
  handleChangeWunsch50 = (event) => {
    this.setState({
      wunsch50: event.target.value,
      wunsch51: "",
      wunsch52: "",
    });
  };
  handleChangeWunsch51 = (event) => {
    this.setState({
      wunsch51: event.target.value,
      wunsch52: "",
    });
  };
  handleChangeWunsch52 = (event) => {
    this.setState({
      wunsch52: event.target.value,
    });
  };
  handleChangeWunsch53 = (event) => {
    this.setState({
      wunsch53: event.target.value,
      wunsch54: "",
      wunsch55: "",
      wunsch56: "",
    });
  };
  handleChangeWunsch54 = (event) => {
    this.setState({
      wunsch54: event.target.value,
      wunsch55: "",
      wunsch56: "",
    });
  };
  handleChangeWunsch55 = (event) => {
    this.setState({
      wunsch55: event.target.value,
      wunsch56: "",
    });
  };
  handleChangeWunsch56 = (event) => {
    this.setState({
      wunsch56: event.target.value,
    });
  };
  handleChangeWunsch57 = (event) => {
    this.setState({
      wunsch57: event.target.value,
      wunsch58: "",
      wunsch59: "",
      wunsch60: "",
    });
  };
  handleChangeWunsch58 = (event) => {
    this.setState({
      wunsch58: event.target.value,
      wunsch59: "",
      wunsch60: "",
    });
  };
  handleChangeWunsch59 = (event) => {
    this.setState({
      wunsch59: event.target.value,
      wunsch60: "",
    });
  };
  handleChangeWunsch60 = (event) => {
    this.setState({
      wunsch60: event.target.value,
    });
  };
  handleChangeWunsch61 = (event) => {
    this.setState({
      wunsch61: event.target.value,
      wunsch62: "",
      wunsch63: "",
      wunsch64: "",
    });
  };
  handleChangeWunsch62 = (event) => {
    this.setState({
      wunsch62: event.target.value,
      wunsch63: "",
      wunsch64: "",
    });
  };
  handleChangeWunsch63 = (event) => {
    this.setState({
      wunsch63: event.target.value,
      wunsch64: "",
    });
  };
  handleChangeWunsch64 = (event) => {
    this.setState({
      wunsch64: event.target.value,
    });
  };
  handleChangeWunsch65 = (event) => {
    this.setState({
      wunsch65: event.target.value,
      wunsch66: "",
      wunsch67: "",
      wunsch68: "",
    });
  };
  handleChangeWunsch66 = (event) => {
    this.setState({
      wunsch66: event.target.value,
      wunsch67: "",
      wunsch68: "",
    });
  };
  handleChangeWunsch67 = (event) => {
    this.setState({
      wunsch67: event.target.value,
      wunsch68: "",
    });
  };
  handleChangeWunsch68 = (event) => {
    this.setState({
      wunsch68: event.target.value,
    });
  };
  handleChangeWunsch69 = (event) => {
    this.setState({
      wunsch69: event.target.value,
      wunsch70: "",
      wunsch71: "",
      wunsch72: "",
    });
  };
  handleChangeWunsch70 = (event) => {
    this.setState({
      wunsch70: event.target.value,
      wunsch71: "",
      wunsch72: "",
    });
  };
  handleChangeWunsch71 = (event) => {
    this.setState({
      wunsch71: event.target.value,
      wunsch72: "",
    });
  };
  handleChangeWunsch72 = (event) => {
    this.setState({
      wunsch72: event.target.value,
    });
  };
  handleChangeWunsch73 = (event) => {
    this.setState({
      wunsch73: event.target.value,
      wunsch74: "",
      wunsch75: "",
      wunsch76: "",
    });
  };
  handleChangeWunsch74 = (event) => {
    this.setState({
      wunsch74: event.target.value,
      wunsch75: "",
      wunsch76: "",
    });
  };
  handleChangeWunsch75 = (event) => {
    this.setState({
      wunsch75: event.target.value,
      wunsch76: "",
    });
  };
  handleChangeWunsch76 = (event) => {
    this.setState({
      wunsch76: event.target.value,
    });
  };
  handleChangeWunsch77 = (event) => {
    this.setState({
      wunsch77: event.target.value,
      wunsch78: "",
      wunsch79: "",
      wunsch80: "",
    });
  };
  handleChangeWunsch78 = (event) => {
    this.setState({
      wunsch78: event.target.value,
      wunsch79: "",
      wunsch80: "",
    });
  };
  handleChangeWunsch79 = (event) => {
    this.setState({
      wunsch79: event.target.value,
      wunsch80: "",
    });
  };
  handleChangeWunsch80 = (event) => {
    this.setState({
      wunsch80: event.target.value,
    });
  };
  handleChangeVerbot1 = (event) => {
    this.setState({
      verbot1: event.target.value,
      verbot2: "",
    });
  };
  handleChangeVerbot2 = (event) => {
    this.setState({
      verbot2: event.target.value,
    });
  };
  handleChangeVerbot3 = (event) => {
    this.setState({
      verbot3: event.target.value,
      verbot4: "",
    });
  };
  handleChangeVerbot4 = (event) => {
    this.setState({
      verbot4: event.target.value,
    });
  };
  handleChangeVerbot5 = (event) => {
    this.setState({
      verbot5: event.target.value,
      verbot6: "",
    });
  };
  handleChangeVerbot6 = (event) => {
    this.setState({
      verbot6: event.target.value,
    });
  };
  handleChangeVerbot7 = (event) => {
    this.setState({
      verbot7: event.target.value,
      verbot8: "",
    });
  };
  handleChangeVerbot8 = (event) => {
    this.setState({
      verbot8: event.target.value,
    });
  };
  handleChangeVerbot9 = (event) => {
    this.setState({
      verbot9: event.target.value,
      verbot10: "",
    });
  };
  handleChangeVerbot10 = (event) => {
    this.setState({
      verbot10: event.target.value,
    });
  };
  handleChangeVerbot11 = (event) => {
    this.setState({
      verbot11: event.target.value,
      verbot12: "",
    });
  };
  handleChangeVerbot12 = (event) => {
    this.setState({
      verbot12: event.target.value,
    });
  };
  handleChangeVerbot13 = (event) => {
    this.setState({
      verbot13: event.target.value,
      verbot14: "",
    });
  };
  handleChangeVerbot14 = (event) => {
    this.setState({
      verbot14: event.target.value,
    });
  };
  handleChangeVerbot15 = (event) => {
    this.setState({
      verbot15: event.target.value,
      verbot16: "",
    });
  };
  handleChangeVerbot16 = (event) => {
    this.setState({
      verbot16: event.target.value,
    });
  };
  handleChangeVerbot17 = (event) => {
    this.setState({
      verbot17: event.target.value,
      verbot18: "",
    });
  };
  handleChangeVerbot18 = (event) => {
    this.setState({
      verbot18: event.target.value,
    });
  };
  handleChangeVerbot19 = (event) => {
    this.setState({
      verbot19: event.target.value,
      verbot20: "",
    });
  };
  handleChangeVerbot20 = (event) => {
    this.setState({
      verbot20: event.target.value,
    });
  };
  handleChangeVerbot21 = (event) => {
    this.setState({
      verbot21: event.target.value,
      verbot22: "",
    });
  };
  handleChangeVerbot22 = (event) => {
    this.setState({
      verbot22: event.target.value,
    });
  };
  handleChangeVerbot23 = (event) => {
    this.setState({
      verbot23: event.target.value,
      verbot24: "",
    });
  };
  handleChangeVerbot24 = (event) => {
    this.setState({
      verbot24: event.target.value,
    });
  };
  handleChangeVerbot25 = (event) => {
    this.setState({
      verbot25: event.target.value,
      verbot26: "",
    });
  };
  handleChangeVerbot26 = (event) => {
    this.setState({
      verbot26: event.target.value,
    });
  };
  handleChangeVerbot27 = (event) => {
    this.setState({
      verbot27: event.target.value,
      verbot28: "",
    });
  };
  handleChangeVerbot28 = (event) => {
    this.setState({
      verbot28: event.target.value,
    });
  };
  handleChangeVerbot29 = (event) => {
    this.setState({
      verbot29: event.target.value,
      verbot30: "",
    });
  };
  handleChangeVerbot30 = (event) => {
    this.setState({
      verbot30: event.target.value,
    });
  };
  handleChangeVerbot31 = (event) => {
    this.setState({
      verbot31: event.target.value,
      verbot32: "",
    });
  };
  handleChangeVerbot32 = (event) => {
    this.setState({
      verbot32: event.target.value,
    });
  };
  handleChangeVerbot33 = (event) => {
    this.setState({
      verbot33: event.target.value,
      verbot34: "",
    });
  };
  handleChangeVerbot34 = (event) => {
    this.setState({
      verbot34: event.target.value,
    });
  };
  handleChangeVerbot35 = (event) => {
    this.setState({
      verbot35: event.target.value,
      verbot36: "",
    });
  };
  handleChangeVerbot36 = (event) => {
    this.setState({
      verbot36: event.target.value,
    });
  };
  handleChangeVerbot37 = (event) => {
    this.setState({
      verbot37: event.target.value,
      verbot38: "",
    });
  };
  handleChangeVerbot38 = (event) => {
    this.setState({
      verbot38: event.target.value,
    });
  };
  handleChangeVerbot39 = (event) => {
    this.setState({
      verbot39: event.target.value,
      verbot40: "",
    });
  };
  handleChangeVerbot40 = (event) => {
    this.setState({
      verbot40: event.target.value,
    });
  };

  /*
  Alle Namen aus dem Topf
  */
  alleNamen = () => {
    const array = [];
    array.push(this.state.name1);
    array.push(this.state.name2);
    array.push(this.state.name3);
    array.push(this.state.name4);
    array.push(this.state.name5);
    array.push(this.state.name6);
    array.push(this.state.name7);
    array.push(this.state.name8);
    return array;
  };

  Verbotmoeglichkeiten = (name) => {
    var array = this.alleNamen();

    if (name === "") {
      return [""];
    }

    var index = array.indexOf(name);
    delete array[index];

    return array;
  };

  handleChangeAllMatrixValues = (event) => {
    event.preventDefault();
    let matrix11 = "0";
    let matrix12 = "0";
    let matrix13 = "0";
    let matrix14 = "0";
    let matrix15 = "0";
    let matrix16 = "0";
    let matrix21 = "0";
    let matrix22 = "0";
    let matrix23 = "0";
    let matrix24 = "0";
    let matrix25 = "0";
    let matrix26 = "0";
    let matrix31 = "0";
    let matrix32 = "0";
    let matrix33 = "0";
    let matrix34 = "0";
    let matrix35 = "0";
    let matrix36 = "0";
    let matrix41 = "0";
    let matrix42 = "0";
    let matrix43 = "0";
    let matrix44 = "0";
    let matrix45 = "0";
    let matrix46 = "0";
    let matrix51 = "0";
    let matrix52 = "0";
    let matrix53 = "0";
    let matrix54 = "0";
    let matrix55 = "0";
    let matrix56 = "0";
    let matrix61 = "0";
    let matrix62 = "0";
    let matrix63 = "0";
    let matrix64 = "0";
    let matrix65 = "0";
    let matrix66 = "0";
    let matrix71 = "0";
    let matrix72 = "0";
    let matrix73 = "0";
    let matrix74 = "0";
    let matrix75 = "0";
    let matrix76 = "0";
    let matrix81 = "0";
    let matrix82 = "0";
    let matrix83 = "0";
    let matrix84 = "0";
    let matrix85 = "0";
    let matrix86 = "0";

    if (this.state.setzeAlle === "1") {
      matrix11 = "1";
      matrix12 = "1";
      matrix13 = "1";
      matrix14 = "1";
      matrix15 = "1";
      matrix16 = "1";
      matrix21 = "1";
      matrix22 = "1";
      matrix23 = "1";
      matrix24 = "1";
      matrix25 = "1";
      matrix26 = "1";
      matrix31 = "1";
      matrix32 = "1";
      matrix33 = "1";
      matrix34 = "1";
      matrix35 = "1";
      matrix36 = "1";
      matrix41 = "1";
      matrix42 = "1";
      matrix43 = "1";
      matrix44 = "1";
      matrix45 = "1";
      matrix46 = "1";
      matrix51 = "1";
      matrix52 = "1";
      matrix53 = "1";
      matrix54 = "1";
      matrix55 = "1";
      matrix56 = "1";
      matrix61 = "1";
      matrix62 = "1";
      matrix63 = "1";
      matrix64 = "1";
      matrix65 = "1";
      matrix66 = "1";
      matrix71 = "1";
      matrix72 = "1";
      matrix73 = "1";
      matrix74 = "1";
      matrix75 = "1";
      matrix76 = "1";
      matrix81 = "1";
      matrix82 = "1";
      matrix83 = "1";
      matrix84 = "1";
      matrix85 = "1";
      matrix86 = "1";
    }

    this.setState((state) => ({
      matrix11,
      matrix12,
      matrix13,
      matrix14,
      matrix15,
      matrix16,
      matrix21,
      matrix22,
      matrix23,
      matrix24,
      matrix25,
      matrix26,
      matrix31,
      matrix32,
      matrix33,
      matrix34,
      matrix35,
      matrix36,
      matrix41,
      matrix42,
      matrix43,
      matrix44,
      matrix45,
      matrix46,
      matrix51,
      matrix52,
      matrix53,
      matrix54,
      matrix55,
      matrix56,
      matrix61,
      matrix62,
      matrix63,
      matrix64,
      matrix65,
      matrix66,
      matrix71,
      matrix72,
      matrix73,
      matrix74,
      matrix75,
      matrix76,
      matrix81,
      matrix82,
      matrix83,
      matrix84,
      matrix85,
      matrix86,
    }));
    return "";
  };

  WuenscheKompatibel() {
    const array = [];
    const arrayoutput = [];
    const arraynamen = this.alleNamen();

    for (let i = 0; i < this.state.trainingseinheiten; i++) {
      array.push([]);
      array[i].push(new Array(this.state.trainingseinheiten));
    }
    for (let j = 0; j < this.state.trainingseinheiten; j++) {
      array[j][0] = arraynamen[j];
    }

    if (
      (this.state.zeit111 > 0 && this.state.zeit211 > 0) ||
      (this.state.zeit112 > 0 && this.state.zeit212 > 0) ||
      (this.state.zeit113 > 0 && this.state.zeit213 > 0) ||
      (this.state.zeit114 > 0 && this.state.zeit214 > 0) ||
      (this.state.zeit115 > 0 && this.state.zeit215 > 0) ||
      (this.state.zeit116 > 0 && this.state.zeit216 > 0) ||
      (this.state.zeit121 > 0 && this.state.zeit221 > 0) ||
      (this.state.zeit122 > 0 && this.state.zeit222 > 0) ||
      (this.state.zeit123 > 0 && this.state.zeit223 > 0) ||
      (this.state.zeit124 > 0 && this.state.zeit224 > 0) ||
      (this.state.zeit125 > 0 && this.state.zeit225 > 0) ||
      (this.state.zeit126 > 0 && this.state.zeit226 > 0) ||
      (this.state.zeit131 > 0 && this.state.zeit231 > 0) ||
      (this.state.zeit132 > 0 && this.state.zeit232 > 0) ||
      (this.state.zeit133 > 0 && this.state.zeit233 > 0) ||
      (this.state.zeit134 > 0 && this.state.zeit234 > 0) ||
      (this.state.zeit135 > 0 && this.state.zeit235 > 0) ||
      (this.state.zeit136 > 0 && this.state.zeit236 > 0) ||
      (this.state.zeit141 > 0 && this.state.zeit241 > 0) ||
      (this.state.zeit142 > 0 && this.state.zeit242 > 0) ||
      (this.state.zeit143 > 0 && this.state.zeit243 > 0) ||
      (this.state.zeit144 > 0 && this.state.zeit244 > 0) ||
      (this.state.zeit145 > 0 && this.state.zeit245 > 0) ||
      (this.state.zeit146 > 0 && this.state.zeit246 > 0) ||
      (this.state.zeit151 > 0 && this.state.zeit251 > 0) ||
      (this.state.zeit152 > 0 && this.state.zeit252 > 0) ||
      (this.state.zeit153 > 0 && this.state.zeit253 > 0) ||
      (this.state.zeit154 > 0 && this.state.zeit254 > 0) ||
      (this.state.zeit155 > 0 && this.state.zeit255 > 0) ||
      (this.state.zeit156 > 0 && this.state.zeit256 > 0) ||
      (this.state.zeit161 > 0 && this.state.zeit261 > 0) ||
      (this.state.zeit162 > 0 && this.state.zeit262 > 0) ||
      (this.state.zeit163 > 0 && this.state.zeit263 > 0) ||
      (this.state.zeit164 > 0 && this.state.zeit264 > 0) ||
      (this.state.zeit165 > 0 && this.state.zeit265 > 0) ||
      (this.state.zeit166 > 0 && this.state.zeit266 > 0) ||
      (this.state.zeit171 > 0 && this.state.zeit271 > 0) ||
      (this.state.zeit172 > 0 && this.state.zeit272 > 0) ||
      (this.state.zeit173 > 0 && this.state.zeit273 > 0) ||
      (this.state.zeit174 > 0 && this.state.zeit274 > 0) ||
      (this.state.zeit175 > 0 && this.state.zeit275 > 0) ||
      (this.state.zeit176 > 0 && this.state.zeit276 > 0) ||
      (this.state.zeit181 > 0 && this.state.zeit281 > 0) ||
      (this.state.zeit182 > 0 && this.state.zeit282 > 0) ||
      (this.state.zeit183 > 0 && this.state.zeit283 > 0) ||
      (this.state.zeit184 > 0 && this.state.zeit284 > 0) ||
      (this.state.zeit185 > 0 && this.state.zeit285 > 0) ||
      (this.state.zeit186 > 0 && this.state.zeit286 > 0)
    ) {
      array[0][1] = this.state.name2;
      array[1][1] = this.state.name1;
    }
    if (
      (this.state.zeit111 > 0 && this.state.zeit311 > 0) ||
      (this.state.zeit112 > 0 && this.state.zeit312 > 0) ||
      (this.state.zeit113 > 0 && this.state.zeit313 > 0) ||
      (this.state.zeit114 > 0 && this.state.zeit314 > 0) ||
      (this.state.zeit115 > 0 && this.state.zeit315 > 0) ||
      (this.state.zeit116 > 0 && this.state.zeit316 > 0) ||
      (this.state.zeit121 > 0 && this.state.zeit321 > 0) ||
      (this.state.zeit122 > 0 && this.state.zeit322 > 0) ||
      (this.state.zeit123 > 0 && this.state.zeit323 > 0) ||
      (this.state.zeit124 > 0 && this.state.zeit324 > 0) ||
      (this.state.zeit125 > 0 && this.state.zeit325 > 0) ||
      (this.state.zeit126 > 0 && this.state.zeit326 > 0) ||
      (this.state.zeit131 > 0 && this.state.zeit331 > 0) ||
      (this.state.zeit132 > 0 && this.state.zeit332 > 0) ||
      (this.state.zeit133 > 0 && this.state.zeit333 > 0) ||
      (this.state.zeit134 > 0 && this.state.zeit334 > 0) ||
      (this.state.zeit135 > 0 && this.state.zeit335 > 0) ||
      (this.state.zeit136 > 0 && this.state.zeit336 > 0) ||
      (this.state.zeit141 > 0 && this.state.zeit341 > 0) ||
      (this.state.zeit142 > 0 && this.state.zeit342 > 0) ||
      (this.state.zeit143 > 0 && this.state.zeit343 > 0) ||
      (this.state.zeit144 > 0 && this.state.zeit344 > 0) ||
      (this.state.zeit145 > 0 && this.state.zeit345 > 0) ||
      (this.state.zeit146 > 0 && this.state.zeit346 > 0) ||
      (this.state.zeit151 > 0 && this.state.zeit351 > 0) ||
      (this.state.zeit152 > 0 && this.state.zeit352 > 0) ||
      (this.state.zeit153 > 0 && this.state.zeit353 > 0) ||
      (this.state.zeit154 > 0 && this.state.zeit354 > 0) ||
      (this.state.zeit155 > 0 && this.state.zeit355 > 0) ||
      (this.state.zeit156 > 0 && this.state.zeit356 > 0) ||
      (this.state.zeit161 > 0 && this.state.zeit361 > 0) ||
      (this.state.zeit162 > 0 && this.state.zeit362 > 0) ||
      (this.state.zeit163 > 0 && this.state.zeit363 > 0) ||
      (this.state.zeit164 > 0 && this.state.zeit364 > 0) ||
      (this.state.zeit165 > 0 && this.state.zeit365 > 0) ||
      (this.state.zeit166 > 0 && this.state.zeit366 > 0) ||
      (this.state.zeit171 > 0 && this.state.zeit371 > 0) ||
      (this.state.zeit172 > 0 && this.state.zeit372 > 0) ||
      (this.state.zeit173 > 0 && this.state.zeit373 > 0) ||
      (this.state.zeit174 > 0 && this.state.zeit374 > 0) ||
      (this.state.zeit175 > 0 && this.state.zeit375 > 0) ||
      (this.state.zeit176 > 0 && this.state.zeit376 > 0) ||
      (this.state.zeit181 > 0 && this.state.zeit381 > 0) ||
      (this.state.zeit182 > 0 && this.state.zeit382 > 0) ||
      (this.state.zeit183 > 0 && this.state.zeit383 > 0) ||
      (this.state.zeit184 > 0 && this.state.zeit384 > 0) ||
      (this.state.zeit185 > 0 && this.state.zeit385 > 0) ||
      (this.state.zeit186 > 0 && this.state.zeit386 > 0)
    ) {
      array[0][2] = this.state.name3;
      array[2][1] = this.state.name1;
    }
    if (
      (this.state.zeit111 > 0 && this.state.zeit411 > 0) ||
      (this.state.zeit112 > 0 && this.state.zeit412 > 0) ||
      (this.state.zeit113 > 0 && this.state.zeit413 > 0) ||
      (this.state.zeit114 > 0 && this.state.zeit414 > 0) ||
      (this.state.zeit115 > 0 && this.state.zeit415 > 0) ||
      (this.state.zeit116 > 0 && this.state.zeit416 > 0) ||
      (this.state.zeit121 > 0 && this.state.zeit421 > 0) ||
      (this.state.zeit122 > 0 && this.state.zeit422 > 0) ||
      (this.state.zeit123 > 0 && this.state.zeit423 > 0) ||
      (this.state.zeit124 > 0 && this.state.zeit424 > 0) ||
      (this.state.zeit125 > 0 && this.state.zeit425 > 0) ||
      (this.state.zeit126 > 0 && this.state.zeit426 > 0) ||
      (this.state.zeit131 > 0 && this.state.zeit431 > 0) ||
      (this.state.zeit132 > 0 && this.state.zeit432 > 0) ||
      (this.state.zeit133 > 0 && this.state.zeit433 > 0) ||
      (this.state.zeit134 > 0 && this.state.zeit434 > 0) ||
      (this.state.zeit135 > 0 && this.state.zeit435 > 0) ||
      (this.state.zeit136 > 0 && this.state.zeit436 > 0) ||
      (this.state.zeit141 > 0 && this.state.zeit441 > 0) ||
      (this.state.zeit142 > 0 && this.state.zeit442 > 0) ||
      (this.state.zeit143 > 0 && this.state.zeit443 > 0) ||
      (this.state.zeit144 > 0 && this.state.zeit444 > 0) ||
      (this.state.zeit145 > 0 && this.state.zeit445 > 0) ||
      (this.state.zeit146 > 0 && this.state.zeit446 > 0) ||
      (this.state.zeit151 > 0 && this.state.zeit451 > 0) ||
      (this.state.zeit152 > 0 && this.state.zeit452 > 0) ||
      (this.state.zeit153 > 0 && this.state.zeit453 > 0) ||
      (this.state.zeit154 > 0 && this.state.zeit454 > 0) ||
      (this.state.zeit155 > 0 && this.state.zeit455 > 0) ||
      (this.state.zeit156 > 0 && this.state.zeit456 > 0) ||
      (this.state.zeit161 > 0 && this.state.zeit461 > 0) ||
      (this.state.zeit162 > 0 && this.state.zeit462 > 0) ||
      (this.state.zeit163 > 0 && this.state.zeit463 > 0) ||
      (this.state.zeit164 > 0 && this.state.zeit464 > 0) ||
      (this.state.zeit165 > 0 && this.state.zeit465 > 0) ||
      (this.state.zeit166 > 0 && this.state.zeit466 > 0) ||
      (this.state.zeit171 > 0 && this.state.zeit471 > 0) ||
      (this.state.zeit172 > 0 && this.state.zeit472 > 0) ||
      (this.state.zeit173 > 0 && this.state.zeit473 > 0) ||
      (this.state.zeit174 > 0 && this.state.zeit474 > 0) ||
      (this.state.zeit175 > 0 && this.state.zeit475 > 0) ||
      (this.state.zeit176 > 0 && this.state.zeit476 > 0) ||
      (this.state.zeit181 > 0 && this.state.zeit481 > 0) ||
      (this.state.zeit182 > 0 && this.state.zeit482 > 0) ||
      (this.state.zeit183 > 0 && this.state.zeit483 > 0) ||
      (this.state.zeit184 > 0 && this.state.zeit484 > 0) ||
      (this.state.zeit185 > 0 && this.state.zeit485 > 0) ||
      (this.state.zeit186 > 0 && this.state.zeit486 > 0)
    ) {
      array[0][3] = this.state.name4;
      array[3][1] = this.state.name1;
    }
    if (
      (this.state.zeit111 > 0 && this.state.zeit511 > 0) ||
      (this.state.zeit112 > 0 && this.state.zeit512 > 0) ||
      (this.state.zeit113 > 0 && this.state.zeit513 > 0) ||
      (this.state.zeit114 > 0 && this.state.zeit514 > 0) ||
      (this.state.zeit115 > 0 && this.state.zeit515 > 0) ||
      (this.state.zeit116 > 0 && this.state.zeit516 > 0) ||
      (this.state.zeit121 > 0 && this.state.zeit521 > 0) ||
      (this.state.zeit122 > 0 && this.state.zeit522 > 0) ||
      (this.state.zeit123 > 0 && this.state.zeit523 > 0) ||
      (this.state.zeit124 > 0 && this.state.zeit524 > 0) ||
      (this.state.zeit125 > 0 && this.state.zeit525 > 0) ||
      (this.state.zeit126 > 0 && this.state.zeit526 > 0) ||
      (this.state.zeit131 > 0 && this.state.zeit531 > 0) ||
      (this.state.zeit132 > 0 && this.state.zeit532 > 0) ||
      (this.state.zeit133 > 0 && this.state.zeit533 > 0) ||
      (this.state.zeit134 > 0 && this.state.zeit534 > 0) ||
      (this.state.zeit135 > 0 && this.state.zeit535 > 0) ||
      (this.state.zeit136 > 0 && this.state.zeit536 > 0) ||
      (this.state.zeit141 > 0 && this.state.zeit541 > 0) ||
      (this.state.zeit142 > 0 && this.state.zeit542 > 0) ||
      (this.state.zeit143 > 0 && this.state.zeit543 > 0) ||
      (this.state.zeit144 > 0 && this.state.zeit544 > 0) ||
      (this.state.zeit145 > 0 && this.state.zeit545 > 0) ||
      (this.state.zeit146 > 0 && this.state.zeit546 > 0) ||
      (this.state.zeit151 > 0 && this.state.zeit551 > 0) ||
      (this.state.zeit152 > 0 && this.state.zeit552 > 0) ||
      (this.state.zeit153 > 0 && this.state.zeit553 > 0) ||
      (this.state.zeit154 > 0 && this.state.zeit554 > 0) ||
      (this.state.zeit155 > 0 && this.state.zeit555 > 0) ||
      (this.state.zeit156 > 0 && this.state.zeit556 > 0) ||
      (this.state.zeit161 > 0 && this.state.zeit561 > 0) ||
      (this.state.zeit162 > 0 && this.state.zeit562 > 0) ||
      (this.state.zeit163 > 0 && this.state.zeit563 > 0) ||
      (this.state.zeit164 > 0 && this.state.zeit564 > 0) ||
      (this.state.zeit165 > 0 && this.state.zeit565 > 0) ||
      (this.state.zeit166 > 0 && this.state.zeit566 > 0) ||
      (this.state.zeit171 > 0 && this.state.zeit571 > 0) ||
      (this.state.zeit172 > 0 && this.state.zeit572 > 0) ||
      (this.state.zeit173 > 0 && this.state.zeit573 > 0) ||
      (this.state.zeit174 > 0 && this.state.zeit574 > 0) ||
      (this.state.zeit175 > 0 && this.state.zeit575 > 0) ||
      (this.state.zeit176 > 0 && this.state.zeit576 > 0) ||
      (this.state.zeit181 > 0 && this.state.zeit581 > 0) ||
      (this.state.zeit182 > 0 && this.state.zeit582 > 0) ||
      (this.state.zeit183 > 0 && this.state.zeit583 > 0) ||
      (this.state.zeit184 > 0 && this.state.zeit584 > 0) ||
      (this.state.zeit185 > 0 && this.state.zeit585 > 0) ||
      (this.state.zeit186 > 0 && this.state.zeit586 > 0)
    ) {
      array[0][4] = this.state.name5;
      array[4][1] = this.state.name1;
    }
    if (
      (this.state.zeit111 > 0 && this.state.zeit611 > 0) ||
      (this.state.zeit112 > 0 && this.state.zeit612 > 0) ||
      (this.state.zeit113 > 0 && this.state.zeit613 > 0) ||
      (this.state.zeit114 > 0 && this.state.zeit614 > 0) ||
      (this.state.zeit115 > 0 && this.state.zeit615 > 0) ||
      (this.state.zeit116 > 0 && this.state.zeit616 > 0) ||
      (this.state.zeit121 > 0 && this.state.zeit621 > 0) ||
      (this.state.zeit122 > 0 && this.state.zeit622 > 0) ||
      (this.state.zeit123 > 0 && this.state.zeit623 > 0) ||
      (this.state.zeit124 > 0 && this.state.zeit624 > 0) ||
      (this.state.zeit125 > 0 && this.state.zeit625 > 0) ||
      (this.state.zeit126 > 0 && this.state.zeit626 > 0) ||
      (this.state.zeit131 > 0 && this.state.zeit631 > 0) ||
      (this.state.zeit132 > 0 && this.state.zeit632 > 0) ||
      (this.state.zeit133 > 0 && this.state.zeit633 > 0) ||
      (this.state.zeit134 > 0 && this.state.zeit634 > 0) ||
      (this.state.zeit135 > 0 && this.state.zeit635 > 0) ||
      (this.state.zeit136 > 0 && this.state.zeit636 > 0) ||
      (this.state.zeit141 > 0 && this.state.zeit641 > 0) ||
      (this.state.zeit142 > 0 && this.state.zeit642 > 0) ||
      (this.state.zeit143 > 0 && this.state.zeit643 > 0) ||
      (this.state.zeit144 > 0 && this.state.zeit644 > 0) ||
      (this.state.zeit145 > 0 && this.state.zeit645 > 0) ||
      (this.state.zeit146 > 0 && this.state.zeit646 > 0) ||
      (this.state.zeit151 > 0 && this.state.zeit651 > 0) ||
      (this.state.zeit152 > 0 && this.state.zeit652 > 0) ||
      (this.state.zeit153 > 0 && this.state.zeit653 > 0) ||
      (this.state.zeit154 > 0 && this.state.zeit654 > 0) ||
      (this.state.zeit155 > 0 && this.state.zeit655 > 0) ||
      (this.state.zeit156 > 0 && this.state.zeit656 > 0) ||
      (this.state.zeit161 > 0 && this.state.zeit661 > 0) ||
      (this.state.zeit162 > 0 && this.state.zeit662 > 0) ||
      (this.state.zeit163 > 0 && this.state.zeit663 > 0) ||
      (this.state.zeit164 > 0 && this.state.zeit664 > 0) ||
      (this.state.zeit165 > 0 && this.state.zeit665 > 0) ||
      (this.state.zeit166 > 0 && this.state.zeit666 > 0) ||
      (this.state.zeit171 > 0 && this.state.zeit671 > 0) ||
      (this.state.zeit172 > 0 && this.state.zeit672 > 0) ||
      (this.state.zeit173 > 0 && this.state.zeit673 > 0) ||
      (this.state.zeit174 > 0 && this.state.zeit674 > 0) ||
      (this.state.zeit175 > 0 && this.state.zeit675 > 0) ||
      (this.state.zeit176 > 0 && this.state.zeit676 > 0) ||
      (this.state.zeit181 > 0 && this.state.zeit681 > 0) ||
      (this.state.zeit182 > 0 && this.state.zeit682 > 0) ||
      (this.state.zeit183 > 0 && this.state.zeit683 > 0) ||
      (this.state.zeit184 > 0 && this.state.zeit684 > 0) ||
      (this.state.zeit185 > 0 && this.state.zeit685 > 0) ||
      (this.state.zeit186 > 0 && this.state.zeit686 > 0)
    ) {
      array[0][5] = this.state.name6;
      array[5][1] = this.state.name1;
    }
    if (
      (this.state.zeit111 > 0 && this.state.zeit711 > 0) ||
      (this.state.zeit112 > 0 && this.state.zeit712 > 0) ||
      (this.state.zeit113 > 0 && this.state.zeit713 > 0) ||
      (this.state.zeit114 > 0 && this.state.zeit714 > 0) ||
      (this.state.zeit115 > 0 && this.state.zeit715 > 0) ||
      (this.state.zeit116 > 0 && this.state.zeit716 > 0) ||
      (this.state.zeit121 > 0 && this.state.zeit721 > 0) ||
      (this.state.zeit122 > 0 && this.state.zeit722 > 0) ||
      (this.state.zeit123 > 0 && this.state.zeit723 > 0) ||
      (this.state.zeit124 > 0 && this.state.zeit724 > 0) ||
      (this.state.zeit125 > 0 && this.state.zeit725 > 0) ||
      (this.state.zeit126 > 0 && this.state.zeit726 > 0) ||
      (this.state.zeit131 > 0 && this.state.zeit731 > 0) ||
      (this.state.zeit132 > 0 && this.state.zeit732 > 0) ||
      (this.state.zeit133 > 0 && this.state.zeit733 > 0) ||
      (this.state.zeit134 > 0 && this.state.zeit734 > 0) ||
      (this.state.zeit135 > 0 && this.state.zeit735 > 0) ||
      (this.state.zeit136 > 0 && this.state.zeit736 > 0) ||
      (this.state.zeit141 > 0 && this.state.zeit741 > 0) ||
      (this.state.zeit142 > 0 && this.state.zeit742 > 0) ||
      (this.state.zeit143 > 0 && this.state.zeit743 > 0) ||
      (this.state.zeit144 > 0 && this.state.zeit744 > 0) ||
      (this.state.zeit145 > 0 && this.state.zeit745 > 0) ||
      (this.state.zeit146 > 0 && this.state.zeit746 > 0) ||
      (this.state.zeit151 > 0 && this.state.zeit751 > 0) ||
      (this.state.zeit152 > 0 && this.state.zeit752 > 0) ||
      (this.state.zeit153 > 0 && this.state.zeit753 > 0) ||
      (this.state.zeit154 > 0 && this.state.zeit754 > 0) ||
      (this.state.zeit155 > 0 && this.state.zeit755 > 0) ||
      (this.state.zeit156 > 0 && this.state.zeit756 > 0) ||
      (this.state.zeit161 > 0 && this.state.zeit761 > 0) ||
      (this.state.zeit162 > 0 && this.state.zeit762 > 0) ||
      (this.state.zeit163 > 0 && this.state.zeit763 > 0) ||
      (this.state.zeit164 > 0 && this.state.zeit764 > 0) ||
      (this.state.zeit165 > 0 && this.state.zeit765 > 0) ||
      (this.state.zeit166 > 0 && this.state.zeit766 > 0) ||
      (this.state.zeit171 > 0 && this.state.zeit771 > 0) ||
      (this.state.zeit172 > 0 && this.state.zeit772 > 0) ||
      (this.state.zeit173 > 0 && this.state.zeit773 > 0) ||
      (this.state.zeit174 > 0 && this.state.zeit774 > 0) ||
      (this.state.zeit175 > 0 && this.state.zeit775 > 0) ||
      (this.state.zeit176 > 0 && this.state.zeit776 > 0) ||
      (this.state.zeit181 > 0 && this.state.zeit781 > 0) ||
      (this.state.zeit182 > 0 && this.state.zeit782 > 0) ||
      (this.state.zeit183 > 0 && this.state.zeit783 > 0) ||
      (this.state.zeit184 > 0 && this.state.zeit784 > 0) ||
      (this.state.zeit185 > 0 && this.state.zeit785 > 0) ||
      (this.state.zeit186 > 0 && this.state.zeit786 > 0)
    ) {
      array[0][6] = this.state.name7;
      array[6][1] = this.state.name1;
    }
    if (
      (this.state.zeit111 > 0 && this.state.zeit811 > 0) ||
      (this.state.zeit112 > 0 && this.state.zeit812 > 0) ||
      (this.state.zeit113 > 0 && this.state.zeit813 > 0) ||
      (this.state.zeit114 > 0 && this.state.zeit814 > 0) ||
      (this.state.zeit115 > 0 && this.state.zeit815 > 0) ||
      (this.state.zeit116 > 0 && this.state.zeit816 > 0) ||
      (this.state.zeit121 > 0 && this.state.zeit821 > 0) ||
      (this.state.zeit122 > 0 && this.state.zeit822 > 0) ||
      (this.state.zeit123 > 0 && this.state.zeit823 > 0) ||
      (this.state.zeit124 > 0 && this.state.zeit824 > 0) ||
      (this.state.zeit125 > 0 && this.state.zeit825 > 0) ||
      (this.state.zeit126 > 0 && this.state.zeit826 > 0) ||
      (this.state.zeit131 > 0 && this.state.zeit831 > 0) ||
      (this.state.zeit132 > 0 && this.state.zeit832 > 0) ||
      (this.state.zeit133 > 0 && this.state.zeit833 > 0) ||
      (this.state.zeit134 > 0 && this.state.zeit834 > 0) ||
      (this.state.zeit135 > 0 && this.state.zeit835 > 0) ||
      (this.state.zeit136 > 0 && this.state.zeit836 > 0) ||
      (this.state.zeit141 > 0 && this.state.zeit841 > 0) ||
      (this.state.zeit142 > 0 && this.state.zeit842 > 0) ||
      (this.state.zeit143 > 0 && this.state.zeit843 > 0) ||
      (this.state.zeit144 > 0 && this.state.zeit844 > 0) ||
      (this.state.zeit145 > 0 && this.state.zeit845 > 0) ||
      (this.state.zeit146 > 0 && this.state.zeit846 > 0) ||
      (this.state.zeit151 > 0 && this.state.zeit851 > 0) ||
      (this.state.zeit152 > 0 && this.state.zeit852 > 0) ||
      (this.state.zeit153 > 0 && this.state.zeit853 > 0) ||
      (this.state.zeit154 > 0 && this.state.zeit854 > 0) ||
      (this.state.zeit155 > 0 && this.state.zeit855 > 0) ||
      (this.state.zeit156 > 0 && this.state.zeit856 > 0) ||
      (this.state.zeit161 > 0 && this.state.zeit861 > 0) ||
      (this.state.zeit162 > 0 && this.state.zeit862 > 0) ||
      (this.state.zeit163 > 0 && this.state.zeit863 > 0) ||
      (this.state.zeit164 > 0 && this.state.zeit864 > 0) ||
      (this.state.zeit165 > 0 && this.state.zeit865 > 0) ||
      (this.state.zeit166 > 0 && this.state.zeit866 > 0) ||
      (this.state.zeit171 > 0 && this.state.zeit871 > 0) ||
      (this.state.zeit172 > 0 && this.state.zeit872 > 0) ||
      (this.state.zeit173 > 0 && this.state.zeit873 > 0) ||
      (this.state.zeit174 > 0 && this.state.zeit874 > 0) ||
      (this.state.zeit175 > 0 && this.state.zeit875 > 0) ||
      (this.state.zeit176 > 0 && this.state.zeit876 > 0) ||
      (this.state.zeit181 > 0 && this.state.zeit881 > 0) ||
      (this.state.zeit182 > 0 && this.state.zeit882 > 0) ||
      (this.state.zeit183 > 0 && this.state.zeit883 > 0) ||
      (this.state.zeit184 > 0 && this.state.zeit884 > 0) ||
      (this.state.zeit185 > 0 && this.state.zeit885 > 0) ||
      (this.state.zeit186 > 0 && this.state.zeit886 > 0)
    ) {
      array[0][7] = this.state.name8;
      array[7][1] = this.state.name1;
    }
    if (
      (this.state.zeit211 > 0 && this.state.zeit311 > 0) ||
      (this.state.zeit212 > 0 && this.state.zeit312 > 0) ||
      (this.state.zeit213 > 0 && this.state.zeit313 > 0) ||
      (this.state.zeit214 > 0 && this.state.zeit314 > 0) ||
      (this.state.zeit215 > 0 && this.state.zeit315 > 0) ||
      (this.state.zeit216 > 0 && this.state.zeit316 > 0) ||
      (this.state.zeit221 > 0 && this.state.zeit321 > 0) ||
      (this.state.zeit222 > 0 && this.state.zeit322 > 0) ||
      (this.state.zeit223 > 0 && this.state.zeit323 > 0) ||
      (this.state.zeit224 > 0 && this.state.zeit324 > 0) ||
      (this.state.zeit225 > 0 && this.state.zeit325 > 0) ||
      (this.state.zeit226 > 0 && this.state.zeit326 > 0) ||
      (this.state.zeit231 > 0 && this.state.zeit331 > 0) ||
      (this.state.zeit232 > 0 && this.state.zeit332 > 0) ||
      (this.state.zeit233 > 0 && this.state.zeit333 > 0) ||
      (this.state.zeit234 > 0 && this.state.zeit334 > 0) ||
      (this.state.zeit235 > 0 && this.state.zeit335 > 0) ||
      (this.state.zeit236 > 0 && this.state.zeit336 > 0) ||
      (this.state.zeit241 > 0 && this.state.zeit341 > 0) ||
      (this.state.zeit242 > 0 && this.state.zeit342 > 0) ||
      (this.state.zeit243 > 0 && this.state.zeit343 > 0) ||
      (this.state.zeit244 > 0 && this.state.zeit344 > 0) ||
      (this.state.zeit245 > 0 && this.state.zeit345 > 0) ||
      (this.state.zeit246 > 0 && this.state.zeit346 > 0) ||
      (this.state.zeit251 > 0 && this.state.zeit351 > 0) ||
      (this.state.zeit252 > 0 && this.state.zeit352 > 0) ||
      (this.state.zeit253 > 0 && this.state.zeit353 > 0) ||
      (this.state.zeit254 > 0 && this.state.zeit354 > 0) ||
      (this.state.zeit255 > 0 && this.state.zeit355 > 0) ||
      (this.state.zeit256 > 0 && this.state.zeit356 > 0) ||
      (this.state.zeit261 > 0 && this.state.zeit361 > 0) ||
      (this.state.zeit262 > 0 && this.state.zeit362 > 0) ||
      (this.state.zeit263 > 0 && this.state.zeit363 > 0) ||
      (this.state.zeit264 > 0 && this.state.zeit364 > 0) ||
      (this.state.zeit265 > 0 && this.state.zeit365 > 0) ||
      (this.state.zeit266 > 0 && this.state.zeit366 > 0) ||
      (this.state.zeit271 > 0 && this.state.zeit371 > 0) ||
      (this.state.zeit272 > 0 && this.state.zeit372 > 0) ||
      (this.state.zeit273 > 0 && this.state.zeit373 > 0) ||
      (this.state.zeit274 > 0 && this.state.zeit374 > 0) ||
      (this.state.zeit275 > 0 && this.state.zeit375 > 0) ||
      (this.state.zeit276 > 0 && this.state.zeit376 > 0) ||
      (this.state.zeit281 > 0 && this.state.zeit381 > 0) ||
      (this.state.zeit282 > 0 && this.state.zeit382 > 0) ||
      (this.state.zeit283 > 0 && this.state.zeit383 > 0) ||
      (this.state.zeit284 > 0 && this.state.zeit384 > 0) ||
      (this.state.zeit285 > 0 && this.state.zeit385 > 0) ||
      (this.state.zeit286 > 0 && this.state.zeit386 > 0)
    ) {
      array[1][2] = this.state.name3;
      array[2][2] = this.state.name2;
    }
    if (
      (this.state.zeit211 > 0 && this.state.zeit411 > 0) ||
      (this.state.zeit212 > 0 && this.state.zeit412 > 0) ||
      (this.state.zeit213 > 0 && this.state.zeit413 > 0) ||
      (this.state.zeit214 > 0 && this.state.zeit414 > 0) ||
      (this.state.zeit215 > 0 && this.state.zeit415 > 0) ||
      (this.state.zeit216 > 0 && this.state.zeit416 > 0) ||
      (this.state.zeit221 > 0 && this.state.zeit421 > 0) ||
      (this.state.zeit222 > 0 && this.state.zeit422 > 0) ||
      (this.state.zeit223 > 0 && this.state.zeit423 > 0) ||
      (this.state.zeit224 > 0 && this.state.zeit424 > 0) ||
      (this.state.zeit225 > 0 && this.state.zeit425 > 0) ||
      (this.state.zeit226 > 0 && this.state.zeit426 > 0) ||
      (this.state.zeit231 > 0 && this.state.zeit431 > 0) ||
      (this.state.zeit232 > 0 && this.state.zeit432 > 0) ||
      (this.state.zeit233 > 0 && this.state.zeit433 > 0) ||
      (this.state.zeit234 > 0 && this.state.zeit434 > 0) ||
      (this.state.zeit235 > 0 && this.state.zeit435 > 0) ||
      (this.state.zeit236 > 0 && this.state.zeit436 > 0) ||
      (this.state.zeit241 > 0 && this.state.zeit441 > 0) ||
      (this.state.zeit242 > 0 && this.state.zeit442 > 0) ||
      (this.state.zeit243 > 0 && this.state.zeit443 > 0) ||
      (this.state.zeit244 > 0 && this.state.zeit444 > 0) ||
      (this.state.zeit245 > 0 && this.state.zeit445 > 0) ||
      (this.state.zeit246 > 0 && this.state.zeit446 > 0) ||
      (this.state.zeit251 > 0 && this.state.zeit451 > 0) ||
      (this.state.zeit252 > 0 && this.state.zeit452 > 0) ||
      (this.state.zeit253 > 0 && this.state.zeit453 > 0) ||
      (this.state.zeit254 > 0 && this.state.zeit454 > 0) ||
      (this.state.zeit255 > 0 && this.state.zeit455 > 0) ||
      (this.state.zeit256 > 0 && this.state.zeit456 > 0) ||
      (this.state.zeit261 > 0 && this.state.zeit461 > 0) ||
      (this.state.zeit262 > 0 && this.state.zeit462 > 0) ||
      (this.state.zeit263 > 0 && this.state.zeit463 > 0) ||
      (this.state.zeit264 > 0 && this.state.zeit464 > 0) ||
      (this.state.zeit265 > 0 && this.state.zeit465 > 0) ||
      (this.state.zeit266 > 0 && this.state.zeit466 > 0) ||
      (this.state.zeit271 > 0 && this.state.zeit471 > 0) ||
      (this.state.zeit272 > 0 && this.state.zeit472 > 0) ||
      (this.state.zeit273 > 0 && this.state.zeit473 > 0) ||
      (this.state.zeit274 > 0 && this.state.zeit474 > 0) ||
      (this.state.zeit275 > 0 && this.state.zeit475 > 0) ||
      (this.state.zeit276 > 0 && this.state.zeit476 > 0) ||
      (this.state.zeit281 > 0 && this.state.zeit481 > 0) ||
      (this.state.zeit282 > 0 && this.state.zeit482 > 0) ||
      (this.state.zeit283 > 0 && this.state.zeit483 > 0) ||
      (this.state.zeit284 > 0 && this.state.zeit484 > 0) ||
      (this.state.zeit285 > 0 && this.state.zeit485 > 0) ||
      (this.state.zeit286 > 0 && this.state.zeit486 > 0)
    ) {
      array[1][3] = this.state.name4;
      array[3][2] = this.state.name2;
    }
    if (
      (this.state.zeit211 > 0 && this.state.zeit511 > 0) ||
      (this.state.zeit212 > 0 && this.state.zeit512 > 0) ||
      (this.state.zeit213 > 0 && this.state.zeit513 > 0) ||
      (this.state.zeit214 > 0 && this.state.zeit514 > 0) ||
      (this.state.zeit215 > 0 && this.state.zeit515 > 0) ||
      (this.state.zeit216 > 0 && this.state.zeit516 > 0) ||
      (this.state.zeit221 > 0 && this.state.zeit521 > 0) ||
      (this.state.zeit222 > 0 && this.state.zeit522 > 0) ||
      (this.state.zeit223 > 0 && this.state.zeit523 > 0) ||
      (this.state.zeit224 > 0 && this.state.zeit524 > 0) ||
      (this.state.zeit225 > 0 && this.state.zeit525 > 0) ||
      (this.state.zeit226 > 0 && this.state.zeit526 > 0) ||
      (this.state.zeit231 > 0 && this.state.zeit531 > 0) ||
      (this.state.zeit232 > 0 && this.state.zeit532 > 0) ||
      (this.state.zeit233 > 0 && this.state.zeit533 > 0) ||
      (this.state.zeit234 > 0 && this.state.zeit534 > 0) ||
      (this.state.zeit235 > 0 && this.state.zeit535 > 0) ||
      (this.state.zeit236 > 0 && this.state.zeit536 > 0) ||
      (this.state.zeit241 > 0 && this.state.zeit541 > 0) ||
      (this.state.zeit242 > 0 && this.state.zeit542 > 0) ||
      (this.state.zeit243 > 0 && this.state.zeit543 > 0) ||
      (this.state.zeit244 > 0 && this.state.zeit544 > 0) ||
      (this.state.zeit245 > 0 && this.state.zeit545 > 0) ||
      (this.state.zeit246 > 0 && this.state.zeit546 > 0) ||
      (this.state.zeit251 > 0 && this.state.zeit551 > 0) ||
      (this.state.zeit252 > 0 && this.state.zeit552 > 0) ||
      (this.state.zeit253 > 0 && this.state.zeit553 > 0) ||
      (this.state.zeit254 > 0 && this.state.zeit554 > 0) ||
      (this.state.zeit255 > 0 && this.state.zeit555 > 0) ||
      (this.state.zeit256 > 0 && this.state.zeit556 > 0) ||
      (this.state.zeit261 > 0 && this.state.zeit561 > 0) ||
      (this.state.zeit262 > 0 && this.state.zeit562 > 0) ||
      (this.state.zeit263 > 0 && this.state.zeit563 > 0) ||
      (this.state.zeit264 > 0 && this.state.zeit564 > 0) ||
      (this.state.zeit265 > 0 && this.state.zeit565 > 0) ||
      (this.state.zeit266 > 0 && this.state.zeit566 > 0) ||
      (this.state.zeit271 > 0 && this.state.zeit571 > 0) ||
      (this.state.zeit272 > 0 && this.state.zeit572 > 0) ||
      (this.state.zeit273 > 0 && this.state.zeit573 > 0) ||
      (this.state.zeit274 > 0 && this.state.zeit574 > 0) ||
      (this.state.zeit275 > 0 && this.state.zeit575 > 0) ||
      (this.state.zeit276 > 0 && this.state.zeit576 > 0) ||
      (this.state.zeit281 > 0 && this.state.zeit581 > 0) ||
      (this.state.zeit282 > 0 && this.state.zeit582 > 0) ||
      (this.state.zeit283 > 0 && this.state.zeit583 > 0) ||
      (this.state.zeit284 > 0 && this.state.zeit584 > 0) ||
      (this.state.zeit285 > 0 && this.state.zeit585 > 0) ||
      (this.state.zeit286 > 0 && this.state.zeit586 > 0)
    ) {
      array[1][4] = this.state.name5;
      array[4][2] = this.state.name2;
    }
    if (
      (this.state.zeit211 > 0 && this.state.zeit611 > 0) ||
      (this.state.zeit212 > 0 && this.state.zeit612 > 0) ||
      (this.state.zeit213 > 0 && this.state.zeit613 > 0) ||
      (this.state.zeit214 > 0 && this.state.zeit614 > 0) ||
      (this.state.zeit215 > 0 && this.state.zeit615 > 0) ||
      (this.state.zeit216 > 0 && this.state.zeit616 > 0) ||
      (this.state.zeit221 > 0 && this.state.zeit621 > 0) ||
      (this.state.zeit222 > 0 && this.state.zeit622 > 0) ||
      (this.state.zeit223 > 0 && this.state.zeit623 > 0) ||
      (this.state.zeit224 > 0 && this.state.zeit624 > 0) ||
      (this.state.zeit225 > 0 && this.state.zeit625 > 0) ||
      (this.state.zeit226 > 0 && this.state.zeit626 > 0) ||
      (this.state.zeit231 > 0 && this.state.zeit631 > 0) ||
      (this.state.zeit232 > 0 && this.state.zeit632 > 0) ||
      (this.state.zeit233 > 0 && this.state.zeit633 > 0) ||
      (this.state.zeit234 > 0 && this.state.zeit634 > 0) ||
      (this.state.zeit235 > 0 && this.state.zeit635 > 0) ||
      (this.state.zeit236 > 0 && this.state.zeit636 > 0) ||
      (this.state.zeit241 > 0 && this.state.zeit641 > 0) ||
      (this.state.zeit242 > 0 && this.state.zeit642 > 0) ||
      (this.state.zeit243 > 0 && this.state.zeit643 > 0) ||
      (this.state.zeit244 > 0 && this.state.zeit644 > 0) ||
      (this.state.zeit245 > 0 && this.state.zeit645 > 0) ||
      (this.state.zeit246 > 0 && this.state.zeit646 > 0) ||
      (this.state.zeit251 > 0 && this.state.zeit651 > 0) ||
      (this.state.zeit252 > 0 && this.state.zeit652 > 0) ||
      (this.state.zeit253 > 0 && this.state.zeit653 > 0) ||
      (this.state.zeit254 > 0 && this.state.zeit654 > 0) ||
      (this.state.zeit255 > 0 && this.state.zeit655 > 0) ||
      (this.state.zeit256 > 0 && this.state.zeit656 > 0) ||
      (this.state.zeit261 > 0 && this.state.zeit661 > 0) ||
      (this.state.zeit262 > 0 && this.state.zeit662 > 0) ||
      (this.state.zeit263 > 0 && this.state.zeit663 > 0) ||
      (this.state.zeit264 > 0 && this.state.zeit664 > 0) ||
      (this.state.zeit265 > 0 && this.state.zeit665 > 0) ||
      (this.state.zeit266 > 0 && this.state.zeit666 > 0) ||
      (this.state.zeit271 > 0 && this.state.zeit671 > 0) ||
      (this.state.zeit272 > 0 && this.state.zeit672 > 0) ||
      (this.state.zeit273 > 0 && this.state.zeit673 > 0) ||
      (this.state.zeit274 > 0 && this.state.zeit674 > 0) ||
      (this.state.zeit275 > 0 && this.state.zeit675 > 0) ||
      (this.state.zeit276 > 0 && this.state.zeit676 > 0) ||
      (this.state.zeit281 > 0 && this.state.zeit681 > 0) ||
      (this.state.zeit282 > 0 && this.state.zeit682 > 0) ||
      (this.state.zeit283 > 0 && this.state.zeit683 > 0) ||
      (this.state.zeit284 > 0 && this.state.zeit684 > 0) ||
      (this.state.zeit285 > 0 && this.state.zeit685 > 0) ||
      (this.state.zeit286 > 0 && this.state.zeit686 > 0)
    ) {
      array[1][5] = this.state.name6;
      array[5][2] = this.state.name2;
    }
    if (
      (this.state.zeit211 > 0 && this.state.zeit711 > 0) ||
      (this.state.zeit212 > 0 && this.state.zeit712 > 0) ||
      (this.state.zeit213 > 0 && this.state.zeit713 > 0) ||
      (this.state.zeit214 > 0 && this.state.zeit714 > 0) ||
      (this.state.zeit215 > 0 && this.state.zeit715 > 0) ||
      (this.state.zeit216 > 0 && this.state.zeit716 > 0) ||
      (this.state.zeit221 > 0 && this.state.zeit721 > 0) ||
      (this.state.zeit222 > 0 && this.state.zeit722 > 0) ||
      (this.state.zeit223 > 0 && this.state.zeit723 > 0) ||
      (this.state.zeit224 > 0 && this.state.zeit724 > 0) ||
      (this.state.zeit225 > 0 && this.state.zeit725 > 0) ||
      (this.state.zeit226 > 0 && this.state.zeit726 > 0) ||
      (this.state.zeit231 > 0 && this.state.zeit731 > 0) ||
      (this.state.zeit232 > 0 && this.state.zeit732 > 0) ||
      (this.state.zeit233 > 0 && this.state.zeit733 > 0) ||
      (this.state.zeit234 > 0 && this.state.zeit734 > 0) ||
      (this.state.zeit235 > 0 && this.state.zeit735 > 0) ||
      (this.state.zeit236 > 0 && this.state.zeit736 > 0) ||
      (this.state.zeit241 > 0 && this.state.zeit741 > 0) ||
      (this.state.zeit242 > 0 && this.state.zeit742 > 0) ||
      (this.state.zeit243 > 0 && this.state.zeit743 > 0) ||
      (this.state.zeit244 > 0 && this.state.zeit744 > 0) ||
      (this.state.zeit245 > 0 && this.state.zeit745 > 0) ||
      (this.state.zeit246 > 0 && this.state.zeit746 > 0) ||
      (this.state.zeit251 > 0 && this.state.zeit751 > 0) ||
      (this.state.zeit252 > 0 && this.state.zeit752 > 0) ||
      (this.state.zeit253 > 0 && this.state.zeit753 > 0) ||
      (this.state.zeit254 > 0 && this.state.zeit754 > 0) ||
      (this.state.zeit255 > 0 && this.state.zeit755 > 0) ||
      (this.state.zeit256 > 0 && this.state.zeit756 > 0) ||
      (this.state.zeit261 > 0 && this.state.zeit761 > 0) ||
      (this.state.zeit262 > 0 && this.state.zeit762 > 0) ||
      (this.state.zeit263 > 0 && this.state.zeit763 > 0) ||
      (this.state.zeit264 > 0 && this.state.zeit764 > 0) ||
      (this.state.zeit265 > 0 && this.state.zeit765 > 0) ||
      (this.state.zeit266 > 0 && this.state.zeit766 > 0) ||
      (this.state.zeit271 > 0 && this.state.zeit771 > 0) ||
      (this.state.zeit272 > 0 && this.state.zeit772 > 0) ||
      (this.state.zeit273 > 0 && this.state.zeit773 > 0) ||
      (this.state.zeit274 > 0 && this.state.zeit774 > 0) ||
      (this.state.zeit275 > 0 && this.state.zeit775 > 0) ||
      (this.state.zeit276 > 0 && this.state.zeit776 > 0) ||
      (this.state.zeit281 > 0 && this.state.zeit781 > 0) ||
      (this.state.zeit282 > 0 && this.state.zeit782 > 0) ||
      (this.state.zeit283 > 0 && this.state.zeit783 > 0) ||
      (this.state.zeit284 > 0 && this.state.zeit784 > 0) ||
      (this.state.zeit285 > 0 && this.state.zeit785 > 0) ||
      (this.state.zeit286 > 0 && this.state.zeit786 > 0)
    ) {
      array[1][6] = this.state.name7;
      array[6][2] = this.state.name2;
    }
    if (
      (this.state.zeit211 > 0 && this.state.zeit811 > 0) ||
      (this.state.zeit212 > 0 && this.state.zeit812 > 0) ||
      (this.state.zeit213 > 0 && this.state.zeit813 > 0) ||
      (this.state.zeit214 > 0 && this.state.zeit814 > 0) ||
      (this.state.zeit215 > 0 && this.state.zeit815 > 0) ||
      (this.state.zeit216 > 0 && this.state.zeit816 > 0) ||
      (this.state.zeit221 > 0 && this.state.zeit821 > 0) ||
      (this.state.zeit222 > 0 && this.state.zeit822 > 0) ||
      (this.state.zeit223 > 0 && this.state.zeit823 > 0) ||
      (this.state.zeit224 > 0 && this.state.zeit824 > 0) ||
      (this.state.zeit225 > 0 && this.state.zeit825 > 0) ||
      (this.state.zeit226 > 0 && this.state.zeit826 > 0) ||
      (this.state.zeit231 > 0 && this.state.zeit831 > 0) ||
      (this.state.zeit232 > 0 && this.state.zeit832 > 0) ||
      (this.state.zeit233 > 0 && this.state.zeit833 > 0) ||
      (this.state.zeit234 > 0 && this.state.zeit834 > 0) ||
      (this.state.zeit235 > 0 && this.state.zeit835 > 0) ||
      (this.state.zeit236 > 0 && this.state.zeit836 > 0) ||
      (this.state.zeit241 > 0 && this.state.zeit841 > 0) ||
      (this.state.zeit242 > 0 && this.state.zeit842 > 0) ||
      (this.state.zeit243 > 0 && this.state.zeit843 > 0) ||
      (this.state.zeit244 > 0 && this.state.zeit844 > 0) ||
      (this.state.zeit245 > 0 && this.state.zeit845 > 0) ||
      (this.state.zeit246 > 0 && this.state.zeit846 > 0) ||
      (this.state.zeit251 > 0 && this.state.zeit851 > 0) ||
      (this.state.zeit252 > 0 && this.state.zeit852 > 0) ||
      (this.state.zeit253 > 0 && this.state.zeit853 > 0) ||
      (this.state.zeit254 > 0 && this.state.zeit854 > 0) ||
      (this.state.zeit255 > 0 && this.state.zeit855 > 0) ||
      (this.state.zeit256 > 0 && this.state.zeit856 > 0) ||
      (this.state.zeit261 > 0 && this.state.zeit861 > 0) ||
      (this.state.zeit262 > 0 && this.state.zeit862 > 0) ||
      (this.state.zeit263 > 0 && this.state.zeit863 > 0) ||
      (this.state.zeit264 > 0 && this.state.zeit864 > 0) ||
      (this.state.zeit265 > 0 && this.state.zeit865 > 0) ||
      (this.state.zeit266 > 0 && this.state.zeit866 > 0) ||
      (this.state.zeit271 > 0 && this.state.zeit871 > 0) ||
      (this.state.zeit272 > 0 && this.state.zeit872 > 0) ||
      (this.state.zeit273 > 0 && this.state.zeit873 > 0) ||
      (this.state.zeit274 > 0 && this.state.zeit874 > 0) ||
      (this.state.zeit275 > 0 && this.state.zeit875 > 0) ||
      (this.state.zeit276 > 0 && this.state.zeit876 > 0) ||
      (this.state.zeit281 > 0 && this.state.zeit881 > 0) ||
      (this.state.zeit282 > 0 && this.state.zeit882 > 0) ||
      (this.state.zeit283 > 0 && this.state.zeit883 > 0) ||
      (this.state.zeit284 > 0 && this.state.zeit884 > 0) ||
      (this.state.zeit285 > 0 && this.state.zeit885 > 0) ||
      (this.state.zeit286 > 0 && this.state.zeit886 > 0)
    ) {
      array[1][7] = this.state.name8;
      array[7][2] = this.state.name2;
    }
    if (
      (this.state.zeit311 > 0 && this.state.zeit411 > 0) ||
      (this.state.zeit312 > 0 && this.state.zeit412 > 0) ||
      (this.state.zeit313 > 0 && this.state.zeit413 > 0) ||
      (this.state.zeit314 > 0 && this.state.zeit414 > 0) ||
      (this.state.zeit315 > 0 && this.state.zeit415 > 0) ||
      (this.state.zeit316 > 0 && this.state.zeit416 > 0) ||
      (this.state.zeit321 > 0 && this.state.zeit421 > 0) ||
      (this.state.zeit322 > 0 && this.state.zeit422 > 0) ||
      (this.state.zeit323 > 0 && this.state.zeit423 > 0) ||
      (this.state.zeit324 > 0 && this.state.zeit424 > 0) ||
      (this.state.zeit325 > 0 && this.state.zeit425 > 0) ||
      (this.state.zeit326 > 0 && this.state.zeit426 > 0) ||
      (this.state.zeit331 > 0 && this.state.zeit431 > 0) ||
      (this.state.zeit332 > 0 && this.state.zeit432 > 0) ||
      (this.state.zeit333 > 0 && this.state.zeit433 > 0) ||
      (this.state.zeit334 > 0 && this.state.zeit434 > 0) ||
      (this.state.zeit335 > 0 && this.state.zeit435 > 0) ||
      (this.state.zeit336 > 0 && this.state.zeit436 > 0) ||
      (this.state.zeit341 > 0 && this.state.zeit441 > 0) ||
      (this.state.zeit342 > 0 && this.state.zeit442 > 0) ||
      (this.state.zeit343 > 0 && this.state.zeit443 > 0) ||
      (this.state.zeit344 > 0 && this.state.zeit444 > 0) ||
      (this.state.zeit345 > 0 && this.state.zeit445 > 0) ||
      (this.state.zeit346 > 0 && this.state.zeit446 > 0) ||
      (this.state.zeit351 > 0 && this.state.zeit451 > 0) ||
      (this.state.zeit352 > 0 && this.state.zeit452 > 0) ||
      (this.state.zeit353 > 0 && this.state.zeit453 > 0) ||
      (this.state.zeit354 > 0 && this.state.zeit454 > 0) ||
      (this.state.zeit355 > 0 && this.state.zeit455 > 0) ||
      (this.state.zeit356 > 0 && this.state.zeit456 > 0) ||
      (this.state.zeit361 > 0 && this.state.zeit461 > 0) ||
      (this.state.zeit362 > 0 && this.state.zeit462 > 0) ||
      (this.state.zeit363 > 0 && this.state.zeit463 > 0) ||
      (this.state.zeit364 > 0 && this.state.zeit464 > 0) ||
      (this.state.zeit365 > 0 && this.state.zeit465 > 0) ||
      (this.state.zeit366 > 0 && this.state.zeit466 > 0) ||
      (this.state.zeit371 > 0 && this.state.zeit471 > 0) ||
      (this.state.zeit372 > 0 && this.state.zeit472 > 0) ||
      (this.state.zeit373 > 0 && this.state.zeit473 > 0) ||
      (this.state.zeit374 > 0 && this.state.zeit474 > 0) ||
      (this.state.zeit375 > 0 && this.state.zeit475 > 0) ||
      (this.state.zeit376 > 0 && this.state.zeit476 > 0) ||
      (this.state.zeit381 > 0 && this.state.zeit481 > 0) ||
      (this.state.zeit382 > 0 && this.state.zeit482 > 0) ||
      (this.state.zeit383 > 0 && this.state.zeit483 > 0) ||
      (this.state.zeit384 > 0 && this.state.zeit484 > 0) ||
      (this.state.zeit385 > 0 && this.state.zeit485 > 0) ||
      (this.state.zeit386 > 0 && this.state.zeit486 > 0)
    ) {
      array[2][3] = this.state.name4;
      array[3][3] = this.state.name3;
    }
    if (
      (this.state.zeit311 > 0 && this.state.zeit511 > 0) ||
      (this.state.zeit312 > 0 && this.state.zeit512 > 0) ||
      (this.state.zeit313 > 0 && this.state.zeit513 > 0) ||
      (this.state.zeit314 > 0 && this.state.zeit514 > 0) ||
      (this.state.zeit315 > 0 && this.state.zeit515 > 0) ||
      (this.state.zeit316 > 0 && this.state.zeit516 > 0) ||
      (this.state.zeit321 > 0 && this.state.zeit521 > 0) ||
      (this.state.zeit322 > 0 && this.state.zeit522 > 0) ||
      (this.state.zeit323 > 0 && this.state.zeit523 > 0) ||
      (this.state.zeit324 > 0 && this.state.zeit524 > 0) ||
      (this.state.zeit325 > 0 && this.state.zeit525 > 0) ||
      (this.state.zeit326 > 0 && this.state.zeit526 > 0) ||
      (this.state.zeit331 > 0 && this.state.zeit531 > 0) ||
      (this.state.zeit332 > 0 && this.state.zeit532 > 0) ||
      (this.state.zeit333 > 0 && this.state.zeit533 > 0) ||
      (this.state.zeit334 > 0 && this.state.zeit534 > 0) ||
      (this.state.zeit335 > 0 && this.state.zeit535 > 0) ||
      (this.state.zeit336 > 0 && this.state.zeit536 > 0) ||
      (this.state.zeit341 > 0 && this.state.zeit541 > 0) ||
      (this.state.zeit342 > 0 && this.state.zeit542 > 0) ||
      (this.state.zeit343 > 0 && this.state.zeit543 > 0) ||
      (this.state.zeit344 > 0 && this.state.zeit544 > 0) ||
      (this.state.zeit345 > 0 && this.state.zeit545 > 0) ||
      (this.state.zeit346 > 0 && this.state.zeit546 > 0) ||
      (this.state.zeit351 > 0 && this.state.zeit551 > 0) ||
      (this.state.zeit352 > 0 && this.state.zeit552 > 0) ||
      (this.state.zeit353 > 0 && this.state.zeit553 > 0) ||
      (this.state.zeit354 > 0 && this.state.zeit554 > 0) ||
      (this.state.zeit355 > 0 && this.state.zeit555 > 0) ||
      (this.state.zeit356 > 0 && this.state.zeit556 > 0) ||
      (this.state.zeit361 > 0 && this.state.zeit561 > 0) ||
      (this.state.zeit362 > 0 && this.state.zeit562 > 0) ||
      (this.state.zeit363 > 0 && this.state.zeit563 > 0) ||
      (this.state.zeit364 > 0 && this.state.zeit564 > 0) ||
      (this.state.zeit365 > 0 && this.state.zeit565 > 0) ||
      (this.state.zeit366 > 0 && this.state.zeit566 > 0) ||
      (this.state.zeit371 > 0 && this.state.zeit571 > 0) ||
      (this.state.zeit372 > 0 && this.state.zeit572 > 0) ||
      (this.state.zeit373 > 0 && this.state.zeit573 > 0) ||
      (this.state.zeit374 > 0 && this.state.zeit574 > 0) ||
      (this.state.zeit375 > 0 && this.state.zeit575 > 0) ||
      (this.state.zeit376 > 0 && this.state.zeit576 > 0) ||
      (this.state.zeit381 > 0 && this.state.zeit581 > 0) ||
      (this.state.zeit382 > 0 && this.state.zeit582 > 0) ||
      (this.state.zeit383 > 0 && this.state.zeit583 > 0) ||
      (this.state.zeit384 > 0 && this.state.zeit584 > 0) ||
      (this.state.zeit385 > 0 && this.state.zeit585 > 0) ||
      (this.state.zeit386 > 0 && this.state.zeit586 > 0)
    ) {
      array[2][4] = this.state.name5;
      array[4][3] = this.state.name3;
    }
    if (
      (this.state.zeit311 > 0 && this.state.zeit611 > 0) ||
      (this.state.zeit312 > 0 && this.state.zeit612 > 0) ||
      (this.state.zeit313 > 0 && this.state.zeit613 > 0) ||
      (this.state.zeit314 > 0 && this.state.zeit614 > 0) ||
      (this.state.zeit315 > 0 && this.state.zeit615 > 0) ||
      (this.state.zeit316 > 0 && this.state.zeit616 > 0) ||
      (this.state.zeit321 > 0 && this.state.zeit621 > 0) ||
      (this.state.zeit322 > 0 && this.state.zeit622 > 0) ||
      (this.state.zeit323 > 0 && this.state.zeit623 > 0) ||
      (this.state.zeit324 > 0 && this.state.zeit624 > 0) ||
      (this.state.zeit325 > 0 && this.state.zeit625 > 0) ||
      (this.state.zeit326 > 0 && this.state.zeit626 > 0) ||
      (this.state.zeit331 > 0 && this.state.zeit631 > 0) ||
      (this.state.zeit332 > 0 && this.state.zeit632 > 0) ||
      (this.state.zeit333 > 0 && this.state.zeit633 > 0) ||
      (this.state.zeit334 > 0 && this.state.zeit634 > 0) ||
      (this.state.zeit335 > 0 && this.state.zeit635 > 0) ||
      (this.state.zeit336 > 0 && this.state.zeit636 > 0) ||
      (this.state.zeit341 > 0 && this.state.zeit641 > 0) ||
      (this.state.zeit342 > 0 && this.state.zeit642 > 0) ||
      (this.state.zeit343 > 0 && this.state.zeit643 > 0) ||
      (this.state.zeit344 > 0 && this.state.zeit644 > 0) ||
      (this.state.zeit345 > 0 && this.state.zeit645 > 0) ||
      (this.state.zeit346 > 0 && this.state.zeit646 > 0) ||
      (this.state.zeit351 > 0 && this.state.zeit651 > 0) ||
      (this.state.zeit352 > 0 && this.state.zeit652 > 0) ||
      (this.state.zeit353 > 0 && this.state.zeit653 > 0) ||
      (this.state.zeit354 > 0 && this.state.zeit654 > 0) ||
      (this.state.zeit355 > 0 && this.state.zeit655 > 0) ||
      (this.state.zeit356 > 0 && this.state.zeit656 > 0) ||
      (this.state.zeit361 > 0 && this.state.zeit661 > 0) ||
      (this.state.zeit362 > 0 && this.state.zeit662 > 0) ||
      (this.state.zeit363 > 0 && this.state.zeit663 > 0) ||
      (this.state.zeit364 > 0 && this.state.zeit664 > 0) ||
      (this.state.zeit365 > 0 && this.state.zeit665 > 0) ||
      (this.state.zeit366 > 0 && this.state.zeit666 > 0) ||
      (this.state.zeit371 > 0 && this.state.zeit671 > 0) ||
      (this.state.zeit372 > 0 && this.state.zeit672 > 0) ||
      (this.state.zeit373 > 0 && this.state.zeit673 > 0) ||
      (this.state.zeit374 > 0 && this.state.zeit674 > 0) ||
      (this.state.zeit375 > 0 && this.state.zeit675 > 0) ||
      (this.state.zeit376 > 0 && this.state.zeit676 > 0) ||
      (this.state.zeit381 > 0 && this.state.zeit681 > 0) ||
      (this.state.zeit382 > 0 && this.state.zeit682 > 0) ||
      (this.state.zeit383 > 0 && this.state.zeit683 > 0) ||
      (this.state.zeit384 > 0 && this.state.zeit684 > 0) ||
      (this.state.zeit385 > 0 && this.state.zeit685 > 0) ||
      (this.state.zeit386 > 0 && this.state.zeit686 > 0)
    ) {
      array[2][5] = this.state.name6;
      array[5][3] = this.state.name3;
    }
    if (
      (this.state.zeit311 > 0 && this.state.zeit711 > 0) ||
      (this.state.zeit312 > 0 && this.state.zeit712 > 0) ||
      (this.state.zeit313 > 0 && this.state.zeit713 > 0) ||
      (this.state.zeit314 > 0 && this.state.zeit714 > 0) ||
      (this.state.zeit315 > 0 && this.state.zeit715 > 0) ||
      (this.state.zeit316 > 0 && this.state.zeit716 > 0) ||
      (this.state.zeit321 > 0 && this.state.zeit721 > 0) ||
      (this.state.zeit322 > 0 && this.state.zeit722 > 0) ||
      (this.state.zeit323 > 0 && this.state.zeit723 > 0) ||
      (this.state.zeit324 > 0 && this.state.zeit724 > 0) ||
      (this.state.zeit325 > 0 && this.state.zeit725 > 0) ||
      (this.state.zeit326 > 0 && this.state.zeit726 > 0) ||
      (this.state.zeit331 > 0 && this.state.zeit731 > 0) ||
      (this.state.zeit332 > 0 && this.state.zeit732 > 0) ||
      (this.state.zeit333 > 0 && this.state.zeit733 > 0) ||
      (this.state.zeit334 > 0 && this.state.zeit734 > 0) ||
      (this.state.zeit335 > 0 && this.state.zeit735 > 0) ||
      (this.state.zeit336 > 0 && this.state.zeit736 > 0) ||
      (this.state.zeit341 > 0 && this.state.zeit741 > 0) ||
      (this.state.zeit342 > 0 && this.state.zeit742 > 0) ||
      (this.state.zeit343 > 0 && this.state.zeit743 > 0) ||
      (this.state.zeit344 > 0 && this.state.zeit744 > 0) ||
      (this.state.zeit345 > 0 && this.state.zeit745 > 0) ||
      (this.state.zeit346 > 0 && this.state.zeit746 > 0) ||
      (this.state.zeit351 > 0 && this.state.zeit751 > 0) ||
      (this.state.zeit352 > 0 && this.state.zeit752 > 0) ||
      (this.state.zeit353 > 0 && this.state.zeit753 > 0) ||
      (this.state.zeit354 > 0 && this.state.zeit754 > 0) ||
      (this.state.zeit355 > 0 && this.state.zeit755 > 0) ||
      (this.state.zeit356 > 0 && this.state.zeit756 > 0) ||
      (this.state.zeit361 > 0 && this.state.zeit761 > 0) ||
      (this.state.zeit362 > 0 && this.state.zeit762 > 0) ||
      (this.state.zeit363 > 0 && this.state.zeit763 > 0) ||
      (this.state.zeit364 > 0 && this.state.zeit764 > 0) ||
      (this.state.zeit365 > 0 && this.state.zeit765 > 0) ||
      (this.state.zeit366 > 0 && this.state.zeit766 > 0) ||
      (this.state.zeit371 > 0 && this.state.zeit771 > 0) ||
      (this.state.zeit372 > 0 && this.state.zeit772 > 0) ||
      (this.state.zeit373 > 0 && this.state.zeit773 > 0) ||
      (this.state.zeit374 > 0 && this.state.zeit774 > 0) ||
      (this.state.zeit375 > 0 && this.state.zeit775 > 0) ||
      (this.state.zeit376 > 0 && this.state.zeit776 > 0) ||
      (this.state.zeit381 > 0 && this.state.zeit781 > 0) ||
      (this.state.zeit382 > 0 && this.state.zeit782 > 0) ||
      (this.state.zeit383 > 0 && this.state.zeit783 > 0) ||
      (this.state.zeit384 > 0 && this.state.zeit784 > 0) ||
      (this.state.zeit385 > 0 && this.state.zeit785 > 0) ||
      (this.state.zeit386 > 0 && this.state.zeit786 > 0)
    ) {
      array[2][6] = this.state.name7;
      array[6][3] = this.state.name3;
    }
    if (
      (this.state.zeit311 > 0 && this.state.zeit811 > 0) ||
      (this.state.zeit312 > 0 && this.state.zeit812 > 0) ||
      (this.state.zeit313 > 0 && this.state.zeit813 > 0) ||
      (this.state.zeit314 > 0 && this.state.zeit814 > 0) ||
      (this.state.zeit315 > 0 && this.state.zeit815 > 0) ||
      (this.state.zeit316 > 0 && this.state.zeit816 > 0) ||
      (this.state.zeit321 > 0 && this.state.zeit821 > 0) ||
      (this.state.zeit322 > 0 && this.state.zeit822 > 0) ||
      (this.state.zeit323 > 0 && this.state.zeit823 > 0) ||
      (this.state.zeit324 > 0 && this.state.zeit824 > 0) ||
      (this.state.zeit325 > 0 && this.state.zeit825 > 0) ||
      (this.state.zeit326 > 0 && this.state.zeit826 > 0) ||
      (this.state.zeit331 > 0 && this.state.zeit831 > 0) ||
      (this.state.zeit332 > 0 && this.state.zeit832 > 0) ||
      (this.state.zeit333 > 0 && this.state.zeit833 > 0) ||
      (this.state.zeit334 > 0 && this.state.zeit834 > 0) ||
      (this.state.zeit335 > 0 && this.state.zeit835 > 0) ||
      (this.state.zeit336 > 0 && this.state.zeit836 > 0) ||
      (this.state.zeit341 > 0 && this.state.zeit841 > 0) ||
      (this.state.zeit342 > 0 && this.state.zeit842 > 0) ||
      (this.state.zeit343 > 0 && this.state.zeit843 > 0) ||
      (this.state.zeit344 > 0 && this.state.zeit844 > 0) ||
      (this.state.zeit345 > 0 && this.state.zeit845 > 0) ||
      (this.state.zeit346 > 0 && this.state.zeit846 > 0) ||
      (this.state.zeit351 > 0 && this.state.zeit851 > 0) ||
      (this.state.zeit352 > 0 && this.state.zeit852 > 0) ||
      (this.state.zeit353 > 0 && this.state.zeit853 > 0) ||
      (this.state.zeit354 > 0 && this.state.zeit854 > 0) ||
      (this.state.zeit355 > 0 && this.state.zeit855 > 0) ||
      (this.state.zeit356 > 0 && this.state.zeit856 > 0) ||
      (this.state.zeit361 > 0 && this.state.zeit861 > 0) ||
      (this.state.zeit362 > 0 && this.state.zeit862 > 0) ||
      (this.state.zeit363 > 0 && this.state.zeit863 > 0) ||
      (this.state.zeit364 > 0 && this.state.zeit864 > 0) ||
      (this.state.zeit365 > 0 && this.state.zeit865 > 0) ||
      (this.state.zeit366 > 0 && this.state.zeit866 > 0) ||
      (this.state.zeit371 > 0 && this.state.zeit871 > 0) ||
      (this.state.zeit372 > 0 && this.state.zeit872 > 0) ||
      (this.state.zeit373 > 0 && this.state.zeit873 > 0) ||
      (this.state.zeit374 > 0 && this.state.zeit874 > 0) ||
      (this.state.zeit375 > 0 && this.state.zeit875 > 0) ||
      (this.state.zeit376 > 0 && this.state.zeit876 > 0) ||
      (this.state.zeit381 > 0 && this.state.zeit881 > 0) ||
      (this.state.zeit382 > 0 && this.state.zeit882 > 0) ||
      (this.state.zeit383 > 0 && this.state.zeit883 > 0) ||
      (this.state.zeit384 > 0 && this.state.zeit884 > 0) ||
      (this.state.zeit385 > 0 && this.state.zeit885 > 0) ||
      (this.state.zeit386 > 0 && this.state.zeit886 > 0)
    ) {
      array[2][7] = this.state.name8;
      array[7][3] = this.state.name3;
    }
    if (
      (this.state.zeit411 > 0 && this.state.zeit511 > 0) ||
      (this.state.zeit412 > 0 && this.state.zeit512 > 0) ||
      (this.state.zeit413 > 0 && this.state.zeit513 > 0) ||
      (this.state.zeit414 > 0 && this.state.zeit514 > 0) ||
      (this.state.zeit415 > 0 && this.state.zeit515 > 0) ||
      (this.state.zeit416 > 0 && this.state.zeit516 > 0) ||
      (this.state.zeit421 > 0 && this.state.zeit521 > 0) ||
      (this.state.zeit422 > 0 && this.state.zeit522 > 0) ||
      (this.state.zeit423 > 0 && this.state.zeit523 > 0) ||
      (this.state.zeit424 > 0 && this.state.zeit524 > 0) ||
      (this.state.zeit425 > 0 && this.state.zeit525 > 0) ||
      (this.state.zeit426 > 0 && this.state.zeit526 > 0) ||
      (this.state.zeit431 > 0 && this.state.zeit531 > 0) ||
      (this.state.zeit432 > 0 && this.state.zeit532 > 0) ||
      (this.state.zeit433 > 0 && this.state.zeit533 > 0) ||
      (this.state.zeit434 > 0 && this.state.zeit534 > 0) ||
      (this.state.zeit435 > 0 && this.state.zeit535 > 0) ||
      (this.state.zeit436 > 0 && this.state.zeit536 > 0) ||
      (this.state.zeit441 > 0 && this.state.zeit541 > 0) ||
      (this.state.zeit442 > 0 && this.state.zeit542 > 0) ||
      (this.state.zeit443 > 0 && this.state.zeit543 > 0) ||
      (this.state.zeit444 > 0 && this.state.zeit544 > 0) ||
      (this.state.zeit445 > 0 && this.state.zeit545 > 0) ||
      (this.state.zeit446 > 0 && this.state.zeit546 > 0) ||
      (this.state.zeit451 > 0 && this.state.zeit551 > 0) ||
      (this.state.zeit452 > 0 && this.state.zeit552 > 0) ||
      (this.state.zeit453 > 0 && this.state.zeit553 > 0) ||
      (this.state.zeit454 > 0 && this.state.zeit554 > 0) ||
      (this.state.zeit455 > 0 && this.state.zeit555 > 0) ||
      (this.state.zeit456 > 0 && this.state.zeit556 > 0) ||
      (this.state.zeit461 > 0 && this.state.zeit561 > 0) ||
      (this.state.zeit462 > 0 && this.state.zeit562 > 0) ||
      (this.state.zeit463 > 0 && this.state.zeit563 > 0) ||
      (this.state.zeit464 > 0 && this.state.zeit564 > 0) ||
      (this.state.zeit465 > 0 && this.state.zeit565 > 0) ||
      (this.state.zeit466 > 0 && this.state.zeit566 > 0) ||
      (this.state.zeit471 > 0 && this.state.zeit571 > 0) ||
      (this.state.zeit472 > 0 && this.state.zeit572 > 0) ||
      (this.state.zeit473 > 0 && this.state.zeit573 > 0) ||
      (this.state.zeit474 > 0 && this.state.zeit574 > 0) ||
      (this.state.zeit475 > 0 && this.state.zeit575 > 0) ||
      (this.state.zeit476 > 0 && this.state.zeit576 > 0) ||
      (this.state.zeit481 > 0 && this.state.zeit581 > 0) ||
      (this.state.zeit482 > 0 && this.state.zeit582 > 0) ||
      (this.state.zeit483 > 0 && this.state.zeit583 > 0) ||
      (this.state.zeit484 > 0 && this.state.zeit584 > 0) ||
      (this.state.zeit485 > 0 && this.state.zeit585 > 0) ||
      (this.state.zeit486 > 0 && this.state.zeit586 > 0)
    ) {
      array[3][4] = this.state.name5;
      array[4][4] = this.state.name4;
    }
    if (
      (this.state.zeit411 > 0 && this.state.zeit611 > 0) ||
      (this.state.zeit412 > 0 && this.state.zeit612 > 0) ||
      (this.state.zeit413 > 0 && this.state.zeit613 > 0) ||
      (this.state.zeit414 > 0 && this.state.zeit614 > 0) ||
      (this.state.zeit415 > 0 && this.state.zeit615 > 0) ||
      (this.state.zeit416 > 0 && this.state.zeit616 > 0) ||
      (this.state.zeit421 > 0 && this.state.zeit621 > 0) ||
      (this.state.zeit422 > 0 && this.state.zeit622 > 0) ||
      (this.state.zeit423 > 0 && this.state.zeit623 > 0) ||
      (this.state.zeit424 > 0 && this.state.zeit624 > 0) ||
      (this.state.zeit425 > 0 && this.state.zeit625 > 0) ||
      (this.state.zeit426 > 0 && this.state.zeit626 > 0) ||
      (this.state.zeit431 > 0 && this.state.zeit631 > 0) ||
      (this.state.zeit432 > 0 && this.state.zeit632 > 0) ||
      (this.state.zeit433 > 0 && this.state.zeit633 > 0) ||
      (this.state.zeit434 > 0 && this.state.zeit634 > 0) ||
      (this.state.zeit435 > 0 && this.state.zeit635 > 0) ||
      (this.state.zeit436 > 0 && this.state.zeit636 > 0) ||
      (this.state.zeit441 > 0 && this.state.zeit641 > 0) ||
      (this.state.zeit442 > 0 && this.state.zeit642 > 0) ||
      (this.state.zeit443 > 0 && this.state.zeit643 > 0) ||
      (this.state.zeit444 > 0 && this.state.zeit644 > 0) ||
      (this.state.zeit445 > 0 && this.state.zeit645 > 0) ||
      (this.state.zeit446 > 0 && this.state.zeit646 > 0) ||
      (this.state.zeit451 > 0 && this.state.zeit651 > 0) ||
      (this.state.zeit452 > 0 && this.state.zeit652 > 0) ||
      (this.state.zeit453 > 0 && this.state.zeit653 > 0) ||
      (this.state.zeit454 > 0 && this.state.zeit654 > 0) ||
      (this.state.zeit455 > 0 && this.state.zeit655 > 0) ||
      (this.state.zeit456 > 0 && this.state.zeit656 > 0) ||
      (this.state.zeit461 > 0 && this.state.zeit661 > 0) ||
      (this.state.zeit462 > 0 && this.state.zeit662 > 0) ||
      (this.state.zeit463 > 0 && this.state.zeit663 > 0) ||
      (this.state.zeit464 > 0 && this.state.zeit664 > 0) ||
      (this.state.zeit465 > 0 && this.state.zeit665 > 0) ||
      (this.state.zeit466 > 0 && this.state.zeit666 > 0) ||
      (this.state.zeit471 > 0 && this.state.zeit671 > 0) ||
      (this.state.zeit472 > 0 && this.state.zeit672 > 0) ||
      (this.state.zeit473 > 0 && this.state.zeit673 > 0) ||
      (this.state.zeit474 > 0 && this.state.zeit674 > 0) ||
      (this.state.zeit475 > 0 && this.state.zeit675 > 0) ||
      (this.state.zeit476 > 0 && this.state.zeit676 > 0) ||
      (this.state.zeit481 > 0 && this.state.zeit681 > 0) ||
      (this.state.zeit482 > 0 && this.state.zeit682 > 0) ||
      (this.state.zeit483 > 0 && this.state.zeit683 > 0) ||
      (this.state.zeit484 > 0 && this.state.zeit684 > 0) ||
      (this.state.zeit485 > 0 && this.state.zeit685 > 0) ||
      (this.state.zeit486 > 0 && this.state.zeit686 > 0)
    ) {
      array[3][5] = this.state.name6;
      array[5][4] = this.state.name4;
    }
    if (
      (this.state.zeit411 > 0 && this.state.zeit711 > 0) ||
      (this.state.zeit412 > 0 && this.state.zeit712 > 0) ||
      (this.state.zeit413 > 0 && this.state.zeit713 > 0) ||
      (this.state.zeit414 > 0 && this.state.zeit714 > 0) ||
      (this.state.zeit415 > 0 && this.state.zeit715 > 0) ||
      (this.state.zeit416 > 0 && this.state.zeit716 > 0) ||
      (this.state.zeit421 > 0 && this.state.zeit721 > 0) ||
      (this.state.zeit422 > 0 && this.state.zeit722 > 0) ||
      (this.state.zeit423 > 0 && this.state.zeit723 > 0) ||
      (this.state.zeit424 > 0 && this.state.zeit724 > 0) ||
      (this.state.zeit425 > 0 && this.state.zeit725 > 0) ||
      (this.state.zeit426 > 0 && this.state.zeit726 > 0) ||
      (this.state.zeit431 > 0 && this.state.zeit731 > 0) ||
      (this.state.zeit432 > 0 && this.state.zeit732 > 0) ||
      (this.state.zeit433 > 0 && this.state.zeit733 > 0) ||
      (this.state.zeit434 > 0 && this.state.zeit734 > 0) ||
      (this.state.zeit435 > 0 && this.state.zeit735 > 0) ||
      (this.state.zeit436 > 0 && this.state.zeit736 > 0) ||
      (this.state.zeit441 > 0 && this.state.zeit741 > 0) ||
      (this.state.zeit442 > 0 && this.state.zeit742 > 0) ||
      (this.state.zeit443 > 0 && this.state.zeit743 > 0) ||
      (this.state.zeit444 > 0 && this.state.zeit744 > 0) ||
      (this.state.zeit445 > 0 && this.state.zeit745 > 0) ||
      (this.state.zeit446 > 0 && this.state.zeit746 > 0) ||
      (this.state.zeit451 > 0 && this.state.zeit751 > 0) ||
      (this.state.zeit452 > 0 && this.state.zeit752 > 0) ||
      (this.state.zeit453 > 0 && this.state.zeit753 > 0) ||
      (this.state.zeit454 > 0 && this.state.zeit754 > 0) ||
      (this.state.zeit455 > 0 && this.state.zeit755 > 0) ||
      (this.state.zeit456 > 0 && this.state.zeit756 > 0) ||
      (this.state.zeit461 > 0 && this.state.zeit761 > 0) ||
      (this.state.zeit462 > 0 && this.state.zeit762 > 0) ||
      (this.state.zeit463 > 0 && this.state.zeit763 > 0) ||
      (this.state.zeit464 > 0 && this.state.zeit764 > 0) ||
      (this.state.zeit465 > 0 && this.state.zeit765 > 0) ||
      (this.state.zeit466 > 0 && this.state.zeit766 > 0) ||
      (this.state.zeit471 > 0 && this.state.zeit771 > 0) ||
      (this.state.zeit472 > 0 && this.state.zeit772 > 0) ||
      (this.state.zeit473 > 0 && this.state.zeit773 > 0) ||
      (this.state.zeit474 > 0 && this.state.zeit774 > 0) ||
      (this.state.zeit475 > 0 && this.state.zeit775 > 0) ||
      (this.state.zeit476 > 0 && this.state.zeit776 > 0) ||
      (this.state.zeit481 > 0 && this.state.zeit781 > 0) ||
      (this.state.zeit482 > 0 && this.state.zeit782 > 0) ||
      (this.state.zeit483 > 0 && this.state.zeit783 > 0) ||
      (this.state.zeit484 > 0 && this.state.zeit784 > 0) ||
      (this.state.zeit485 > 0 && this.state.zeit785 > 0) ||
      (this.state.zeit486 > 0 && this.state.zeit786 > 0)
    ) {
      array[3][6] = this.state.name7;
      array[6][4] = this.state.name4;
    }
    if (
      (this.state.zeit411 > 0 && this.state.zeit811 > 0) ||
      (this.state.zeit412 > 0 && this.state.zeit812 > 0) ||
      (this.state.zeit413 > 0 && this.state.zeit813 > 0) ||
      (this.state.zeit414 > 0 && this.state.zeit814 > 0) ||
      (this.state.zeit415 > 0 && this.state.zeit815 > 0) ||
      (this.state.zeit416 > 0 && this.state.zeit816 > 0) ||
      (this.state.zeit421 > 0 && this.state.zeit821 > 0) ||
      (this.state.zeit422 > 0 && this.state.zeit822 > 0) ||
      (this.state.zeit423 > 0 && this.state.zeit823 > 0) ||
      (this.state.zeit424 > 0 && this.state.zeit824 > 0) ||
      (this.state.zeit425 > 0 && this.state.zeit825 > 0) ||
      (this.state.zeit426 > 0 && this.state.zeit826 > 0) ||
      (this.state.zeit431 > 0 && this.state.zeit831 > 0) ||
      (this.state.zeit432 > 0 && this.state.zeit832 > 0) ||
      (this.state.zeit433 > 0 && this.state.zeit833 > 0) ||
      (this.state.zeit434 > 0 && this.state.zeit834 > 0) ||
      (this.state.zeit435 > 0 && this.state.zeit835 > 0) ||
      (this.state.zeit436 > 0 && this.state.zeit836 > 0) ||
      (this.state.zeit441 > 0 && this.state.zeit841 > 0) ||
      (this.state.zeit442 > 0 && this.state.zeit842 > 0) ||
      (this.state.zeit443 > 0 && this.state.zeit843 > 0) ||
      (this.state.zeit444 > 0 && this.state.zeit844 > 0) ||
      (this.state.zeit445 > 0 && this.state.zeit845 > 0) ||
      (this.state.zeit446 > 0 && this.state.zeit846 > 0) ||
      (this.state.zeit451 > 0 && this.state.zeit851 > 0) ||
      (this.state.zeit452 > 0 && this.state.zeit852 > 0) ||
      (this.state.zeit453 > 0 && this.state.zeit853 > 0) ||
      (this.state.zeit454 > 0 && this.state.zeit854 > 0) ||
      (this.state.zeit455 > 0 && this.state.zeit855 > 0) ||
      (this.state.zeit456 > 0 && this.state.zeit856 > 0) ||
      (this.state.zeit461 > 0 && this.state.zeit861 > 0) ||
      (this.state.zeit462 > 0 && this.state.zeit862 > 0) ||
      (this.state.zeit463 > 0 && this.state.zeit863 > 0) ||
      (this.state.zeit464 > 0 && this.state.zeit864 > 0) ||
      (this.state.zeit465 > 0 && this.state.zeit865 > 0) ||
      (this.state.zeit466 > 0 && this.state.zeit866 > 0) ||
      (this.state.zeit471 > 0 && this.state.zeit871 > 0) ||
      (this.state.zeit472 > 0 && this.state.zeit872 > 0) ||
      (this.state.zeit473 > 0 && this.state.zeit873 > 0) ||
      (this.state.zeit474 > 0 && this.state.zeit874 > 0) ||
      (this.state.zeit475 > 0 && this.state.zeit875 > 0) ||
      (this.state.zeit476 > 0 && this.state.zeit876 > 0) ||
      (this.state.zeit481 > 0 && this.state.zeit881 > 0) ||
      (this.state.zeit482 > 0 && this.state.zeit882 > 0) ||
      (this.state.zeit483 > 0 && this.state.zeit883 > 0) ||
      (this.state.zeit484 > 0 && this.state.zeit884 > 0) ||
      (this.state.zeit485 > 0 && this.state.zeit885 > 0) ||
      (this.state.zeit486 > 0 && this.state.zeit886 > 0)
    ) {
      array[3][7] = this.state.name8;
      array[7][4] = this.state.name4;
    }
    if (
      (this.state.zeit511 > 0 && this.state.zeit611 > 0) ||
      (this.state.zeit512 > 0 && this.state.zeit612 > 0) ||
      (this.state.zeit513 > 0 && this.state.zeit613 > 0) ||
      (this.state.zeit514 > 0 && this.state.zeit614 > 0) ||
      (this.state.zeit515 > 0 && this.state.zeit615 > 0) ||
      (this.state.zeit516 > 0 && this.state.zeit616 > 0) ||
      (this.state.zeit521 > 0 && this.state.zeit621 > 0) ||
      (this.state.zeit522 > 0 && this.state.zeit622 > 0) ||
      (this.state.zeit523 > 0 && this.state.zeit623 > 0) ||
      (this.state.zeit524 > 0 && this.state.zeit624 > 0) ||
      (this.state.zeit525 > 0 && this.state.zeit625 > 0) ||
      (this.state.zeit526 > 0 && this.state.zeit626 > 0) ||
      (this.state.zeit531 > 0 && this.state.zeit631 > 0) ||
      (this.state.zeit532 > 0 && this.state.zeit632 > 0) ||
      (this.state.zeit533 > 0 && this.state.zeit633 > 0) ||
      (this.state.zeit534 > 0 && this.state.zeit634 > 0) ||
      (this.state.zeit535 > 0 && this.state.zeit635 > 0) ||
      (this.state.zeit536 > 0 && this.state.zeit636 > 0) ||
      (this.state.zeit541 > 0 && this.state.zeit641 > 0) ||
      (this.state.zeit542 > 0 && this.state.zeit642 > 0) ||
      (this.state.zeit543 > 0 && this.state.zeit643 > 0) ||
      (this.state.zeit544 > 0 && this.state.zeit644 > 0) ||
      (this.state.zeit545 > 0 && this.state.zeit645 > 0) ||
      (this.state.zeit546 > 0 && this.state.zeit646 > 0) ||
      (this.state.zeit551 > 0 && this.state.zeit651 > 0) ||
      (this.state.zeit552 > 0 && this.state.zeit652 > 0) ||
      (this.state.zeit553 > 0 && this.state.zeit653 > 0) ||
      (this.state.zeit554 > 0 && this.state.zeit654 > 0) ||
      (this.state.zeit555 > 0 && this.state.zeit655 > 0) ||
      (this.state.zeit556 > 0 && this.state.zeit656 > 0) ||
      (this.state.zeit561 > 0 && this.state.zeit661 > 0) ||
      (this.state.zeit562 > 0 && this.state.zeit662 > 0) ||
      (this.state.zeit563 > 0 && this.state.zeit663 > 0) ||
      (this.state.zeit564 > 0 && this.state.zeit664 > 0) ||
      (this.state.zeit565 > 0 && this.state.zeit665 > 0) ||
      (this.state.zeit566 > 0 && this.state.zeit666 > 0) ||
      (this.state.zeit571 > 0 && this.state.zeit671 > 0) ||
      (this.state.zeit572 > 0 && this.state.zeit672 > 0) ||
      (this.state.zeit573 > 0 && this.state.zeit673 > 0) ||
      (this.state.zeit574 > 0 && this.state.zeit674 > 0) ||
      (this.state.zeit575 > 0 && this.state.zeit675 > 0) ||
      (this.state.zeit576 > 0 && this.state.zeit676 > 0) ||
      (this.state.zeit581 > 0 && this.state.zeit681 > 0) ||
      (this.state.zeit582 > 0 && this.state.zeit682 > 0) ||
      (this.state.zeit583 > 0 && this.state.zeit683 > 0) ||
      (this.state.zeit584 > 0 && this.state.zeit684 > 0) ||
      (this.state.zeit585 > 0 && this.state.zeit685 > 0) ||
      (this.state.zeit586 > 0 && this.state.zeit686 > 0)
    ) {
      array[4][5] = this.state.name6;
      array[5][5] = this.state.name5;
    }
    if (
      (this.state.zeit511 > 0 && this.state.zeit711 > 0) ||
      (this.state.zeit512 > 0 && this.state.zeit712 > 0) ||
      (this.state.zeit513 > 0 && this.state.zeit713 > 0) ||
      (this.state.zeit514 > 0 && this.state.zeit714 > 0) ||
      (this.state.zeit515 > 0 && this.state.zeit715 > 0) ||
      (this.state.zeit516 > 0 && this.state.zeit716 > 0) ||
      (this.state.zeit521 > 0 && this.state.zeit721 > 0) ||
      (this.state.zeit522 > 0 && this.state.zeit722 > 0) ||
      (this.state.zeit523 > 0 && this.state.zeit723 > 0) ||
      (this.state.zeit524 > 0 && this.state.zeit724 > 0) ||
      (this.state.zeit525 > 0 && this.state.zeit725 > 0) ||
      (this.state.zeit526 > 0 && this.state.zeit726 > 0) ||
      (this.state.zeit531 > 0 && this.state.zeit731 > 0) ||
      (this.state.zeit532 > 0 && this.state.zeit732 > 0) ||
      (this.state.zeit533 > 0 && this.state.zeit733 > 0) ||
      (this.state.zeit534 > 0 && this.state.zeit734 > 0) ||
      (this.state.zeit535 > 0 && this.state.zeit735 > 0) ||
      (this.state.zeit536 > 0 && this.state.zeit736 > 0) ||
      (this.state.zeit541 > 0 && this.state.zeit741 > 0) ||
      (this.state.zeit542 > 0 && this.state.zeit742 > 0) ||
      (this.state.zeit543 > 0 && this.state.zeit743 > 0) ||
      (this.state.zeit544 > 0 && this.state.zeit744 > 0) ||
      (this.state.zeit545 > 0 && this.state.zeit745 > 0) ||
      (this.state.zeit546 > 0 && this.state.zeit746 > 0) ||
      (this.state.zeit551 > 0 && this.state.zeit751 > 0) ||
      (this.state.zeit552 > 0 && this.state.zeit752 > 0) ||
      (this.state.zeit553 > 0 && this.state.zeit753 > 0) ||
      (this.state.zeit554 > 0 && this.state.zeit754 > 0) ||
      (this.state.zeit555 > 0 && this.state.zeit755 > 0) ||
      (this.state.zeit556 > 0 && this.state.zeit756 > 0) ||
      (this.state.zeit561 > 0 && this.state.zeit761 > 0) ||
      (this.state.zeit562 > 0 && this.state.zeit762 > 0) ||
      (this.state.zeit563 > 0 && this.state.zeit763 > 0) ||
      (this.state.zeit564 > 0 && this.state.zeit764 > 0) ||
      (this.state.zeit565 > 0 && this.state.zeit765 > 0) ||
      (this.state.zeit566 > 0 && this.state.zeit766 > 0) ||
      (this.state.zeit571 > 0 && this.state.zeit771 > 0) ||
      (this.state.zeit572 > 0 && this.state.zeit772 > 0) ||
      (this.state.zeit573 > 0 && this.state.zeit773 > 0) ||
      (this.state.zeit574 > 0 && this.state.zeit774 > 0) ||
      (this.state.zeit575 > 0 && this.state.zeit775 > 0) ||
      (this.state.zeit576 > 0 && this.state.zeit776 > 0) ||
      (this.state.zeit581 > 0 && this.state.zeit781 > 0) ||
      (this.state.zeit582 > 0 && this.state.zeit782 > 0) ||
      (this.state.zeit583 > 0 && this.state.zeit783 > 0) ||
      (this.state.zeit584 > 0 && this.state.zeit784 > 0) ||
      (this.state.zeit585 > 0 && this.state.zeit785 > 0) ||
      (this.state.zeit586 > 0 && this.state.zeit786 > 0)
    ) {
      array[4][6] = this.state.name7;
      array[6][5] = this.state.name5;
    }
    if (
      (this.state.zeit511 > 0 && this.state.zeit811 > 0) ||
      (this.state.zeit512 > 0 && this.state.zeit812 > 0) ||
      (this.state.zeit513 > 0 && this.state.zeit813 > 0) ||
      (this.state.zeit514 > 0 && this.state.zeit814 > 0) ||
      (this.state.zeit515 > 0 && this.state.zeit815 > 0) ||
      (this.state.zeit516 > 0 && this.state.zeit816 > 0) ||
      (this.state.zeit521 > 0 && this.state.zeit821 > 0) ||
      (this.state.zeit522 > 0 && this.state.zeit822 > 0) ||
      (this.state.zeit523 > 0 && this.state.zeit823 > 0) ||
      (this.state.zeit524 > 0 && this.state.zeit824 > 0) ||
      (this.state.zeit525 > 0 && this.state.zeit825 > 0) ||
      (this.state.zeit526 > 0 && this.state.zeit826 > 0) ||
      (this.state.zeit531 > 0 && this.state.zeit831 > 0) ||
      (this.state.zeit532 > 0 && this.state.zeit832 > 0) ||
      (this.state.zeit533 > 0 && this.state.zeit833 > 0) ||
      (this.state.zeit534 > 0 && this.state.zeit834 > 0) ||
      (this.state.zeit535 > 0 && this.state.zeit835 > 0) ||
      (this.state.zeit536 > 0 && this.state.zeit836 > 0) ||
      (this.state.zeit541 > 0 && this.state.zeit841 > 0) ||
      (this.state.zeit542 > 0 && this.state.zeit842 > 0) ||
      (this.state.zeit543 > 0 && this.state.zeit843 > 0) ||
      (this.state.zeit544 > 0 && this.state.zeit844 > 0) ||
      (this.state.zeit545 > 0 && this.state.zeit845 > 0) ||
      (this.state.zeit546 > 0 && this.state.zeit846 > 0) ||
      (this.state.zeit551 > 0 && this.state.zeit851 > 0) ||
      (this.state.zeit552 > 0 && this.state.zeit852 > 0) ||
      (this.state.zeit553 > 0 && this.state.zeit853 > 0) ||
      (this.state.zeit554 > 0 && this.state.zeit854 > 0) ||
      (this.state.zeit555 > 0 && this.state.zeit855 > 0) ||
      (this.state.zeit556 > 0 && this.state.zeit856 > 0) ||
      (this.state.zeit561 > 0 && this.state.zeit861 > 0) ||
      (this.state.zeit562 > 0 && this.state.zeit862 > 0) ||
      (this.state.zeit563 > 0 && this.state.zeit863 > 0) ||
      (this.state.zeit564 > 0 && this.state.zeit864 > 0) ||
      (this.state.zeit565 > 0 && this.state.zeit865 > 0) ||
      (this.state.zeit566 > 0 && this.state.zeit866 > 0) ||
      (this.state.zeit571 > 0 && this.state.zeit871 > 0) ||
      (this.state.zeit572 > 0 && this.state.zeit872 > 0) ||
      (this.state.zeit573 > 0 && this.state.zeit873 > 0) ||
      (this.state.zeit574 > 0 && this.state.zeit874 > 0) ||
      (this.state.zeit575 > 0 && this.state.zeit875 > 0) ||
      (this.state.zeit576 > 0 && this.state.zeit876 > 0) ||
      (this.state.zeit581 > 0 && this.state.zeit881 > 0) ||
      (this.state.zeit582 > 0 && this.state.zeit882 > 0) ||
      (this.state.zeit583 > 0 && this.state.zeit883 > 0) ||
      (this.state.zeit584 > 0 && this.state.zeit884 > 0) ||
      (this.state.zeit585 > 0 && this.state.zeit885 > 0) ||
      (this.state.zeit586 > 0 && this.state.zeit886 > 0)
    ) {
      array[4][7] = this.state.name8;
      array[7][5] = this.state.name5;
    }
    if (
      (this.state.zeit611 > 0 && this.state.zeit711 > 0) ||
      (this.state.zeit612 > 0 && this.state.zeit712 > 0) ||
      (this.state.zeit613 > 0 && this.state.zeit713 > 0) ||
      (this.state.zeit614 > 0 && this.state.zeit714 > 0) ||
      (this.state.zeit615 > 0 && this.state.zeit715 > 0) ||
      (this.state.zeit616 > 0 && this.state.zeit716 > 0) ||
      (this.state.zeit621 > 0 && this.state.zeit721 > 0) ||
      (this.state.zeit622 > 0 && this.state.zeit722 > 0) ||
      (this.state.zeit623 > 0 && this.state.zeit723 > 0) ||
      (this.state.zeit624 > 0 && this.state.zeit724 > 0) ||
      (this.state.zeit625 > 0 && this.state.zeit725 > 0) ||
      (this.state.zeit626 > 0 && this.state.zeit726 > 0) ||
      (this.state.zeit631 > 0 && this.state.zeit731 > 0) ||
      (this.state.zeit632 > 0 && this.state.zeit732 > 0) ||
      (this.state.zeit633 > 0 && this.state.zeit733 > 0) ||
      (this.state.zeit634 > 0 && this.state.zeit734 > 0) ||
      (this.state.zeit635 > 0 && this.state.zeit735 > 0) ||
      (this.state.zeit636 > 0 && this.state.zeit736 > 0) ||
      (this.state.zeit641 > 0 && this.state.zeit741 > 0) ||
      (this.state.zeit642 > 0 && this.state.zeit742 > 0) ||
      (this.state.zeit643 > 0 && this.state.zeit743 > 0) ||
      (this.state.zeit644 > 0 && this.state.zeit744 > 0) ||
      (this.state.zeit645 > 0 && this.state.zeit745 > 0) ||
      (this.state.zeit646 > 0 && this.state.zeit746 > 0) ||
      (this.state.zeit651 > 0 && this.state.zeit751 > 0) ||
      (this.state.zeit652 > 0 && this.state.zeit752 > 0) ||
      (this.state.zeit653 > 0 && this.state.zeit753 > 0) ||
      (this.state.zeit654 > 0 && this.state.zeit754 > 0) ||
      (this.state.zeit655 > 0 && this.state.zeit755 > 0) ||
      (this.state.zeit656 > 0 && this.state.zeit756 > 0) ||
      (this.state.zeit661 > 0 && this.state.zeit761 > 0) ||
      (this.state.zeit662 > 0 && this.state.zeit762 > 0) ||
      (this.state.zeit663 > 0 && this.state.zeit763 > 0) ||
      (this.state.zeit664 > 0 && this.state.zeit764 > 0) ||
      (this.state.zeit665 > 0 && this.state.zeit765 > 0) ||
      (this.state.zeit666 > 0 && this.state.zeit766 > 0) ||
      (this.state.zeit671 > 0 && this.state.zeit771 > 0) ||
      (this.state.zeit672 > 0 && this.state.zeit772 > 0) ||
      (this.state.zeit673 > 0 && this.state.zeit773 > 0) ||
      (this.state.zeit674 > 0 && this.state.zeit774 > 0) ||
      (this.state.zeit675 > 0 && this.state.zeit775 > 0) ||
      (this.state.zeit676 > 0 && this.state.zeit776 > 0) ||
      (this.state.zeit681 > 0 && this.state.zeit781 > 0) ||
      (this.state.zeit682 > 0 && this.state.zeit782 > 0) ||
      (this.state.zeit683 > 0 && this.state.zeit783 > 0) ||
      (this.state.zeit684 > 0 && this.state.zeit784 > 0) ||
      (this.state.zeit685 > 0 && this.state.zeit785 > 0) ||
      (this.state.zeit686 > 0 && this.state.zeit786 > 0)
    ) {
      array[5][6] = this.state.name7;
      array[6][6] = this.state.name6;
    }
    if (
      (this.state.zeit611 > 0 && this.state.zeit811 > 0) ||
      (this.state.zeit612 > 0 && this.state.zeit812 > 0) ||
      (this.state.zeit613 > 0 && this.state.zeit813 > 0) ||
      (this.state.zeit614 > 0 && this.state.zeit814 > 0) ||
      (this.state.zeit615 > 0 && this.state.zeit815 > 0) ||
      (this.state.zeit616 > 0 && this.state.zeit816 > 0) ||
      (this.state.zeit621 > 0 && this.state.zeit821 > 0) ||
      (this.state.zeit622 > 0 && this.state.zeit822 > 0) ||
      (this.state.zeit623 > 0 && this.state.zeit823 > 0) ||
      (this.state.zeit624 > 0 && this.state.zeit824 > 0) ||
      (this.state.zeit625 > 0 && this.state.zeit825 > 0) ||
      (this.state.zeit626 > 0 && this.state.zeit826 > 0) ||
      (this.state.zeit631 > 0 && this.state.zeit831 > 0) ||
      (this.state.zeit632 > 0 && this.state.zeit832 > 0) ||
      (this.state.zeit633 > 0 && this.state.zeit833 > 0) ||
      (this.state.zeit634 > 0 && this.state.zeit834 > 0) ||
      (this.state.zeit635 > 0 && this.state.zeit835 > 0) ||
      (this.state.zeit636 > 0 && this.state.zeit836 > 0) ||
      (this.state.zeit641 > 0 && this.state.zeit841 > 0) ||
      (this.state.zeit642 > 0 && this.state.zeit842 > 0) ||
      (this.state.zeit643 > 0 && this.state.zeit843 > 0) ||
      (this.state.zeit644 > 0 && this.state.zeit844 > 0) ||
      (this.state.zeit645 > 0 && this.state.zeit845 > 0) ||
      (this.state.zeit646 > 0 && this.state.zeit846 > 0) ||
      (this.state.zeit651 > 0 && this.state.zeit851 > 0) ||
      (this.state.zeit652 > 0 && this.state.zeit852 > 0) ||
      (this.state.zeit653 > 0 && this.state.zeit853 > 0) ||
      (this.state.zeit654 > 0 && this.state.zeit854 > 0) ||
      (this.state.zeit655 > 0 && this.state.zeit855 > 0) ||
      (this.state.zeit656 > 0 && this.state.zeit856 > 0) ||
      (this.state.zeit661 > 0 && this.state.zeit861 > 0) ||
      (this.state.zeit662 > 0 && this.state.zeit862 > 0) ||
      (this.state.zeit663 > 0 && this.state.zeit863 > 0) ||
      (this.state.zeit664 > 0 && this.state.zeit864 > 0) ||
      (this.state.zeit665 > 0 && this.state.zeit865 > 0) ||
      (this.state.zeit666 > 0 && this.state.zeit866 > 0) ||
      (this.state.zeit671 > 0 && this.state.zeit871 > 0) ||
      (this.state.zeit672 > 0 && this.state.zeit872 > 0) ||
      (this.state.zeit673 > 0 && this.state.zeit873 > 0) ||
      (this.state.zeit674 > 0 && this.state.zeit874 > 0) ||
      (this.state.zeit675 > 0 && this.state.zeit875 > 0) ||
      (this.state.zeit676 > 0 && this.state.zeit876 > 0) ||
      (this.state.zeit681 > 0 && this.state.zeit881 > 0) ||
      (this.state.zeit682 > 0 && this.state.zeit882 > 0) ||
      (this.state.zeit683 > 0 && this.state.zeit883 > 0) ||
      (this.state.zeit684 > 0 && this.state.zeit884 > 0) ||
      (this.state.zeit685 > 0 && this.state.zeit885 > 0) ||
      (this.state.zeit686 > 0 && this.state.zeit886 > 0)
    ) {
      array[5][7] = this.state.name8;
      array[7][6] = this.state.name6;
    }
    if (
      (this.state.zeit711 > 0 && this.state.zeit811 > 0) ||
      (this.state.zeit712 > 0 && this.state.zeit812 > 0) ||
      (this.state.zeit713 > 0 && this.state.zeit813 > 0) ||
      (this.state.zeit714 > 0 && this.state.zeit814 > 0) ||
      (this.state.zeit715 > 0 && this.state.zeit815 > 0) ||
      (this.state.zeit716 > 0 && this.state.zeit816 > 0) ||
      (this.state.zeit721 > 0 && this.state.zeit821 > 0) ||
      (this.state.zeit722 > 0 && this.state.zeit822 > 0) ||
      (this.state.zeit723 > 0 && this.state.zeit823 > 0) ||
      (this.state.zeit724 > 0 && this.state.zeit824 > 0) ||
      (this.state.zeit725 > 0 && this.state.zeit825 > 0) ||
      (this.state.zeit726 > 0 && this.state.zeit826 > 0) ||
      (this.state.zeit731 > 0 && this.state.zeit831 > 0) ||
      (this.state.zeit732 > 0 && this.state.zeit832 > 0) ||
      (this.state.zeit733 > 0 && this.state.zeit833 > 0) ||
      (this.state.zeit734 > 0 && this.state.zeit834 > 0) ||
      (this.state.zeit735 > 0 && this.state.zeit835 > 0) ||
      (this.state.zeit736 > 0 && this.state.zeit836 > 0) ||
      (this.state.zeit741 > 0 && this.state.zeit841 > 0) ||
      (this.state.zeit742 > 0 && this.state.zeit842 > 0) ||
      (this.state.zeit743 > 0 && this.state.zeit843 > 0) ||
      (this.state.zeit744 > 0 && this.state.zeit844 > 0) ||
      (this.state.zeit745 > 0 && this.state.zeit845 > 0) ||
      (this.state.zeit746 > 0 && this.state.zeit846 > 0) ||
      (this.state.zeit751 > 0 && this.state.zeit851 > 0) ||
      (this.state.zeit752 > 0 && this.state.zeit852 > 0) ||
      (this.state.zeit753 > 0 && this.state.zeit853 > 0) ||
      (this.state.zeit754 > 0 && this.state.zeit854 > 0) ||
      (this.state.zeit755 > 0 && this.state.zeit855 > 0) ||
      (this.state.zeit756 > 0 && this.state.zeit856 > 0) ||
      (this.state.zeit761 > 0 && this.state.zeit861 > 0) ||
      (this.state.zeit762 > 0 && this.state.zeit862 > 0) ||
      (this.state.zeit763 > 0 && this.state.zeit863 > 0) ||
      (this.state.zeit764 > 0 && this.state.zeit864 > 0) ||
      (this.state.zeit765 > 0 && this.state.zeit865 > 0) ||
      (this.state.zeit766 > 0 && this.state.zeit866 > 0) ||
      (this.state.zeit771 > 0 && this.state.zeit871 > 0) ||
      (this.state.zeit772 > 0 && this.state.zeit872 > 0) ||
      (this.state.zeit773 > 0 && this.state.zeit873 > 0) ||
      (this.state.zeit774 > 0 && this.state.zeit874 > 0) ||
      (this.state.zeit775 > 0 && this.state.zeit875 > 0) ||
      (this.state.zeit776 > 0 && this.state.zeit876 > 0) ||
      (this.state.zeit781 > 0 && this.state.zeit881 > 0) ||
      (this.state.zeit782 > 0 && this.state.zeit882 > 0) ||
      (this.state.zeit783 > 0 && this.state.zeit883 > 0) ||
      (this.state.zeit784 > 0 && this.state.zeit884 > 0) ||
      (this.state.zeit785 > 0 && this.state.zeit885 > 0) ||
      (this.state.zeit786 > 0 && this.state.zeit886 > 0)
    ) {
      array[6][7] = this.state.name8;
      array[7][7] = this.state.name7;
    }
    for (let i = 0; i < this.state.trainingseinheiten; i++) {
      arrayoutput.push([]);
    }
    for (let j = 0; j < this.state.trainingseinheiten; j++) {
      arrayoutput[j][0] = arraynamen[j];
    }
    for (let i = 0; i < this.state.trainingseinheiten; i++) {
      for (let j = 1; j < array[i].length; j++) {
        if (array[i][j] !== undefined) {
          arrayoutput[i].push(array[i][j]);
        }
      }
    }
    return arrayoutput;
  }
  
  InformationSchritt4 = () => {
    this.setState({ schritt: 999 });
  };

  InformationSchritt4Back = () => {
    this.setState({ schritt: 4 });
  };

  alleNamenWuensche = () => {
    var hilfsarray = this.WuenscheKompatibel();
    var array = [];
    if (hilfsarray[0].length !== 1) {
      array.push(this.state.name1);
    }
    if (hilfsarray[1].length !== 1) {
      array.push(this.state.name2);
    }
    if (hilfsarray[2].length !== 1) {
      array.push(this.state.name3);
    }
    if (hilfsarray[3].length !== 1) {
      array.push(this.state.name4);
    }
    if (hilfsarray[4].length !== 1) {
      array.push(this.state.name5);
    }
    if (hilfsarray[5].length !== 1) {
      array.push(this.state.name6);
    }
    if (hilfsarray[6].length !== 1) {
      array.push(this.state.name7);
    }
    if (hilfsarray[7].length !== 1) {
      array.push(this.state.name8);
    }
    return array;
  };

  ersterWunsch(name) {
    var hilfsarray = this.WuenscheKompatibel();
    var array = [];
    for (let i = 0; i < hilfsarray.length; i++) {
      if (hilfsarray[i][0] === name) {
        for (let j = 1; j < hilfsarray[i].length; j++) {
          array.push(hilfsarray[i][j]);
        }
      }
    }
    return array;
  }
  zweiterWunsch(name1, name2) {
    var hilfsarray = this.WuenscheKompatibel();
    var array = [];
    let index0;
    let index00;
    for (let i = 0; i < hilfsarray.length; i++) {
      if (hilfsarray[i][0] === name1) {
        index0 = i;
      }
      if (hilfsarray[i][0] === name2) {
        index00 = i;
      }
    }
    if (name1 === "" || name2 === "") {
      return [""];
    }

    for (let j = 1; j < hilfsarray[index0].length; j++) {
      if (
        hilfsarray[index0][j] !== name2 &&
        hilfsarray[index00].includes(hilfsarray[index0][j])
      ) {
        array.push(hilfsarray[index0][j]);
      }
    }
    return array;
  }
  dritterWunsch(name1, name2, name3) {
    var hilfsarray = this.WuenscheKompatibel();
    var array = [];
    let index0;
    let index00;
    let index000;
    for (let i = 0; i < hilfsarray.length; i++) {
      if (hilfsarray[i][0] === name1) {
        index0 = i;
      }
      if (hilfsarray[i][0] === name2) {
        index00 = i;
      }
      if (hilfsarray[i][0] === name3) {
        index000 = i;
      }
    }
    if (name1 === "" || name2 === "" || name3 === "") {
      return [""];
    }

    for (let j = 1; j < hilfsarray[index0].length; j++) {
      if (
        hilfsarray[index0][j] !== name2 &&
        hilfsarray[index00].includes(hilfsarray[index0][j])
      ) {
        if (
          hilfsarray[index0][j] !== name3 &&
          hilfsarray[index000].includes(hilfsarray[index0][j])
        ) {
          array.push(hilfsarray[index0][j]);
        }
      }
    }
    return array;
  }

  /*
  Beispiel für die Daten für Schritt 5
  */
  DemoBeispiel = () => {
    this.setState({
      name1: "Roger Federer",
      name2: "Rafael Nadal",
      name3: "Novak Djokovic",
      name4: "Dominic Thiem",
      name5: "Alexander Zverev",
      name6: "Daniil Medvedev",
      name7: "Stefanos Tsitsipas",
      name8: "Stan Wawrinka",
      zeit111: "3",
      zeit113: "1",
      zeit141: "2",
      zeit136: "2",
      zeit211: "2",
      zeit214: "1",
      zeit235: "3",
      zeit255: "2",
      zeit311: "3",
      zeit312: "2",
      zeit332: "1",
      zeit383: "1",
      zeit411: "2",
      zeit421: "3",
      zeit474: "2",
      zeit434: "1",
      zeit512: "2",
      zeit521: "2",
      zeit542: "1",
      zeit566: "1",
      zeit621: "2",
      zeit626: "2",
      zeit651: "1",
      zeit686: "1",
      zeit712: "2",
      zeit721: "3",
      zeit753: "1",
      zeit754: "1",
      zeit811: "2",
      zeit845: "2",
      zeit881: "2",
      zeit836: "1",
    });
  };

  handleChangeshowMoreSchritt1 = () => {
    let showMoreSchritt1 = true;
    if (this.state.showMoreSchritt1 === true) {
      showMoreSchritt1 = false;
    }
    this.setState({ showMoreSchritt1 });
  };

  handleChangeshowMoreSchritt2 = () => {
    let showMoreSchritt2 = true;
    if (this.state.showMoreSchritt2 === true) {
      showMoreSchritt2 = false;
    }
    this.setState({ showMoreSchritt2 });
  };

  handleChangeshowMoreSchritt3 = () => {
    let showMoreSchritt3 = true;
    if (this.state.showMoreSchritt3 === true) {
      showMoreSchritt3 = false;
    }
    this.setState({ showMoreSchritt3 });
  };
  handleChangeshowMoreSchritt4 = () => {
    let showMoreSchritt4 = true;
    if (this.state.showMoreSchritt4 === true) {
      showMoreSchritt4 = false;
    }
    this.setState({ showMoreSchritt4 });
  };

  handleChangeshowMoreSchritt5 = () => {
    let showMoreSchritt5 = true;
    if (this.state.showMoreSchritt5 === true) {
      showMoreSchritt5 = false;
    }
    this.setState({ showMoreSchritt5 });
  };

  handleChangeshowMoreSchritt6 = () => {
    let showMoreSchritt6 = true;
    if (this.state.showMoreSchritt6 === true) {
      showMoreSchritt6 = false;
    }
    this.setState({ showMoreSchritt6 });
  };
  handleChangeshowMoreSchritt7 = () => {
    let showMoreSchritt7 = true;
    if (this.state.showMoreSchritt7 === true) {
      showMoreSchritt7 = false;
    }
    this.setState({ showMoreSchritt7 });
  };
  /*

-------------------------- RENDER --------------------------

  */

  render() {
    /*
    Hier Anzahl von Trainingseinheiten eingeben.
    */
    const auswahlTrainingseinheiten = [];
    for (let i = 0; i < 9; i++) {
      auswahlTrainingseinheiten.push(i);
    }

    /*
Hilfetext Schritt 4
    */

    if (this.state.schritt === 999) {
      return (
        <div>
          <UnderHeaderDemoProgramm />
          <div className="container">
            <li>
              Wenn in einem Topf beispielsweise 16 Trainingseinheiten sind und
              die bevorzugte Gruppengröße 4 ist, so werden auch vier 4er Gruppen
              gebildet. In diesem Falle spielt die alternative Gruppengröße
              keine Rolle, muss aus technischen Gründen jedoch trotzdem
              angegeben werden.
            </li>
            <li>
              Anders sieht es z.B. mit 23 Trainingseinheiten aus! Wenn man
              hierbei bevorzugterweise 4er Gruppen bilden möchte, so ist dies
              nicht möglich. Nun gibt es zwei Möglichkeiten: Entweder werden
              fünf 4er Gruppen und eine 3er Gruppe gebildet oder es werden zwei
              4er Gruppen und drei 5er Gruppen gebildet (nur so schafft man es,
              alle 23 Tennisschüler*innen zuzuteilen). Sie entscheiden mit der
              Angabe der alternativen Gruppengröße („3“ oder „5“) welcher der
              beiden Varianten eintreten soll.
            </li>
            <li>
              Wir empfehlen jedoch mit dem Gedanken an die Wahl zu gehen, ob Sie
              „im Notfall“ lieber Gruppen mit mehr oder weniger
              Tennisschüler*innen als Ihrer bevorzugten Gruppengröße haben
              möchten.
            </li>
            <div className="zurueckweiter">
              <div className="normal">
                <button onClick={this.InformationSchritt4Back}>
                  Zurück
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    /*
    Abschluss
    */
    if (this.state.schritt === 9999) {
      return (
        <div>
          <UnderHeaderDemoProgramm />
          <div className="container topfauswahl">
            <p className="zurueckweiter">
              Sind Sie sich sicher, dass Sie die Abfrage abschließen wollen?
            </p>
            <div className="zurueckweiter">
              <div className="normal">
                <button onClick={this.handleSubmitAbschlussBack}>
                  Nein
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <button onClick={this.handleSubmitAbschluss}>
                  Ja
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    /*


-------------------------- SCHRITT 1 --------------------------

  */

    if (this.state.schritt === 1) {
      return (
        <div>
          <UnderHeaderDemoProgramm />
          <div className="container trainingsauswahl">
            <p id="roteFarbe">
              Verwenden Sie NIEMALS die Zurück-Taste Ihres Browsers!
            </p>
            <p>
              <strong>Schritt 1:</strong> Anmeldungen
            </p>
            {/* {this.state.showMoreSchritt1 ? (
              <div className="showmore">
                <p>
                  <button onClick={this.handleChangeshowMoreSchritt1}>
                    Weitere Infos &#9662;
                  </button>
                </p>
              </div>
            ) : (
              <div className="showmore">
                <p className="demovideo1">
                  <video src={Video1} controls></video>
                </p>
                <p>
                  <button onClick={this.handleChangeshowMoreSchritt1}>
                    Weniger Infos &#9652;
                  </button>
                </p>
              </div>
            )} */}
            <p>
              <strong className="underline">WICHTIG:</strong> Zählen Sie Ihre
              Tennisschüler*innen so oft, wie sie in der Woche trainieren möchten!
            </p>
            <p id="FarbeDemo">
              Demo: Nur „8“ ist auswählbar!
            </p>
            <p className="trainingsauswahl">
              <strong>Anzahl der Anmeldungen (inklusive Mehrfachtraining):</strong>
              <span className="cursorselect">
                <select
                  value={this.state.trainingsauswahl}
                  onChange={this.handleChangeTrainingsauswahl}
                >
                  {auswahlTrainingseinheiten.map((e, key) => {
                    return <option key={e}>{e}</option>;
                  })}
                </select>
              </span>
            </p>
            <div id="roteFarbe">{this.state.errorSchritt1}</div>
            <br />
          </div>
          <div className="zurueckweiter">
            <div className="normal">
              <button onClick={this.handleSubmitSchritt1}>
                Weiter
              </button>
            </div>
          </div>
        </div>
      );
    }

    /*

-------------------------- SCHRITT 2 --------------------------

  */
    if (this.state.schritt === 2) {
      return (
        <div>
          <UnderHeaderDemoProgramm />
          <div className="container">
            <div>
              <p>
                <strong>Schritt 2:</strong> Uhrzeiten und Plätze
              </p>
              <br/>
              <p>
                Sie können die Uhrzeiten verändern!
              </p>
              <p>
                Wie viele Plätze stehen Ihnen zu den jeweiligen Tagen und Uhrzeiten zur Verfügung?
              </p>
              {/* {this.state.showMoreSchritt2 ? (
                <div className="showmore">
                  <p>
                    <button onClick={this.handleChangeshowMoreSchritt2}>
                      Weitere Infos &#9662;
                    </button>
                  </p>
                </div>
              ) : (
                <div className="showmore">
                  <p className="demovideo1">
                    <video src={Video2} controls></video>
                  </p>
                  <p>
                    <button onClick={this.handleChangeshowMoreSchritt2}>
                      Weniger Infos &#9652;
                    </button>
                  </p>
                </div>
              )} */}
              <p id="FarbeDemo">
                Demo: Maximal 1 Platz.
              </p>
              <p id="FarbeDemo">
                Vollversion: Bis zu 10 Plätze.
              </p>
              <div className="platzauswahl">
                <div className="linkeTabellePlatzauswahl">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <strong>Uhrzeit</strong>
                        </td>
                        <td>
                          <strong>Montag</strong>
                        </td>
                        <td>
                          <strong>Dienstag</strong>
                        </td>
                        <td>
                          <strong>Mittwoch</strong>
                        </td>
                        <td>
                          <strong>Donnerstag</strong>
                        </td>
                        <td>
                          <strong>Freitag</strong>
                        </td>
                        <td>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </td>
                        <td>
                          <strong>Uhrzeit</strong>
                        </td>
                        <td>
                          <strong>Samstag</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input
                            type="text"
                            value={this.state.zeit1}
                            onChange={this.handleChangezeit1}
                          ></input>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix11}
                            onChange={this.handleChangematrix11}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix12}
                            onChange={this.handleChangematrix12}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix13}
                            onChange={this.handleChangematrix13}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix14}
                            onChange={this.handleChangematrix14}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix15}
                            onChange={this.handleChangematrix15}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                        &nbsp;&nbsp;
                        </td>
                        <td>
                          <input
                            type="text"
                            value={this.state.zeit9}
                            onChange={this.handleChangezeit9}
                          ></input>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix16}
                            onChange={this.handleChangematrix16}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input
                            type="text"
                            value={this.state.zeit2}
                            onChange={this.handleChangezeit2}
                          ></input>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix21}
                            onChange={this.handleChangematrix21}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix22}
                            onChange={this.handleChangematrix22}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix23}
                            onChange={this.handleChangematrix23}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix24}
                            onChange={this.handleChangematrix24}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix25}
                            onChange={this.handleChangematrix25}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                        &nbsp;&nbsp;
                        </td>
                        <td>
                          <input
                            type="text"
                            value={this.state.zeit10}
                            onChange={this.handleChangezeit10}
                          ></input>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix26}
                            onChange={this.handleChangematrix26}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input
                            type="text"
                            value={this.state.zeit3}
                            onChange={this.handleChangezeit3}
                          ></input>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix31}
                            onChange={this.handleChangematrix31}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix32}
                            onChange={this.handleChangematrix32}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix33}
                            onChange={this.handleChangematrix33}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix34}
                            onChange={this.handleChangematrix34}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix35}
                            onChange={this.handleChangematrix35}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                        &nbsp;&nbsp;
                        </td>
                        <td>
                          <input
                            type="text"
                            value={this.state.zeit11}
                            onChange={this.handleChangezeit11}
                          ></input>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix36}
                            onChange={this.handleChangematrix36}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input
                            type="text"
                            value={this.state.zeit4}
                            onChange={this.handleChangezeit4}
                          ></input>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix41}
                            onChange={this.handleChangematrix41}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix42}
                            onChange={this.handleChangematrix42}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix43}
                            onChange={this.handleChangematrix43}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix44}
                            onChange={this.handleChangematrix44}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix45}
                            onChange={this.handleChangematrix45}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                        &nbsp;&nbsp;
                        </td>
                        <td>
                          <input
                            type="text"
                            value={this.state.zeit12}
                            onChange={this.handleChangezeit12}
                          ></input>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix46}
                            onChange={this.handleChangematrix46}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input
                            type="text"
                            value={this.state.zeit5}
                            onChange={this.handleChangezeit5}
                          ></input>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix51}
                            onChange={this.handleChangematrix51}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix52}
                            onChange={this.handleChangematrix52}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix53}
                            onChange={this.handleChangematrix53}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix54}
                            onChange={this.handleChangematrix54}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix55}
                            onChange={this.handleChangematrix55}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                        &nbsp;&nbsp;
                        </td>
                        <td>
                          <input
                            type="text"
                            value={this.state.zeit13}
                            onChange={this.handleChangezeit13}
                          ></input>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix56}
                            onChange={this.handleChangematrix56}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input
                            type="text"
                            value={this.state.zeit6}
                            onChange={this.handleChangezeit6}
                          ></input>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix61}
                            onChange={this.handleChangematrix61}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix62}
                            onChange={this.handleChangematrix62}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix63}
                            onChange={this.handleChangematrix63}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix64}
                            onChange={this.handleChangematrix64}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix65}
                            onChange={this.handleChangematrix65}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                        &nbsp;&nbsp;
                        </td>
                        <td>
                          <input
                            type="text"
                            value={this.state.zeit14}
                            onChange={this.handleChangezeit14}
                          ></input>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix66}
                            onChange={this.handleChangematrix66}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input
                            type="text"
                            value={this.state.zeit7}
                            onChange={this.handleChangezeit7}
                          ></input>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix71}
                            onChange={this.handleChangematrix71}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix72}
                            onChange={this.handleChangematrix72}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix73}
                            onChange={this.handleChangematrix73}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix74}
                            onChange={this.handleChangematrix74}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix75}
                            onChange={this.handleChangematrix75}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                        &nbsp;&nbsp;
                        </td>
                        <td>
                          <input
                            type="text"
                            value={this.state.zeit15}
                            onChange={this.handleChangezeit15}
                          ></input>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix76}
                            onChange={this.handleChangematrix76}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input
                            type="text"
                            value={this.state.zeit8}
                            onChange={this.handleChangezeit8}
                          ></input>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix81}
                            onChange={this.handleChangematrix81}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix82}
                            onChange={this.handleChangematrix82}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix83}
                            onChange={this.handleChangematrix83}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix84}
                            onChange={this.handleChangematrix84}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix85}
                            onChange={this.handleChangematrix85}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                        <td>
                        &nbsp;&nbsp;
                        </td>
                        <td>
                          <input
                            type="text"
                            value={this.state.zeit16}
                            onChange={this.handleChangezeit16}
                          ></input>
                        </td>
                        <td>
                          <select
                            value={this.state.matrix86}
                            onChange={this.handleChangematrix86}
                          >
                            <option>0</option>
                            <option>1</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <br />
              <div id="roteFarbe">{this.state.errorSchritt2Uhrzeit}</div>
              <div id="roteFarbe">{this.state.errorSchritt2Platz}</div>
              <div>
                Setze alle Plätze auf
                &nbsp;<span className="cursorselect">
                  <select
                    value={this.state.setzeAlle}
                    onChange={this.handleChangeSetzeAlle}
                  >
                    <option>0</option>
                    <option>1</option>
                  </select>
                </span>
                &nbsp;&nbsp;<span className="normal">
                  <button onClick={this.handleChangeAllMatrixValues}>
                    Setze
                  </button>
                </span>
              </div>
              <br />
              <br />
              <div className="zurueckweiter">
                <div className="normal">
                  <button onClick={this.handleSubmitSchritt2Back}>
                    Zurück
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <button onClick={this.handleSubmitSchritt2}>
                    Weiter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    /*

-------------------------- SCHRITT 3 --------------------------

  */
    if (this.state.schritt === 3) {
      return (
        <div className="topfauswahl">
          <UnderHeaderDemoProgramm />
          <div className="container">
            <p>
              <strong>Schritt 3:</strong> Töpfe
            </p>
            <br/>
            <p>
              Bilden Sie nun Ihre Töpfe!
            </p>
            <p>
              Tennisschüler*innen können im Laufe des Programms NUR mit
              Tennisschüler*innen aus dem gleichen Topf in eine Trainingsgruppe
              gelangen.
            </p>
            <p>
              Sie unterteilen Ihre Tennisschüler*innen mithilfe der Töpfe
              in Spielstärken und/oder Geschlechter.
            </p>
            <p>
              Ein Beispiel wäre das Folgende:
            </p>
            <p className="aufzaehlung">
              <li>Topf 1: Mädchen, die erst seit kurzem mit gelben Bällen spielen</li>
              <li>Topf 2: Mädchen, die schon lang mit gelben Bällen spielen</li>
              <li>Topf 3: Jungen, die mit roten Bällen spielen</li>
              <li>Topf 4: Jungen, die mit grünen Bällen spielen</li>
            </p>
            <p>
              Es gibt eine Besonderheit: Alle Einzeltrainings gehören in einen gemeinsamen Topf!
            </p>
            <p>
              Nutzen Sie hierfür am besten Ihre Anmeldezettel und bilden Sie damit Stapel,
              die die einzelnen Töpfe darstellen. Ein Topf darf maximal 40 Anmeldungen
              (inklusive Mehrfachtraining) beinhalten.
              Es sollten nicht zu wenige Tennisschüler*innen in einem Topf
              sein, da sonst Trainingszeiten eventuell nicht erfüllt werden können.
            </p>
            {/* {this.state.showMoreSchritt3 ? (
              <div className="showmore">
                <p>
                  <button onClick={this.handleChangeshowMoreSchritt3}>
                    Weitere Infos &#9662;
                  </button>
                </p>
              </div>
            ) : (
              <div className="showmore">
                <p className="demovideo1">
                  <video src={Video3} controls></video>
                </p>
                <p>
                  <button onClick={this.handleChangeshowMoreSchritt3}>
                    Weniger Infos &#9652;
                  </button>
                </p>
              </div>
            )} */}
            <p id="FarbeDemo">
              Demo: 1 Topf
            </p>
            <p id="FarbeDemo">
              Vollversion: Bis zu 20 Töpfe
            </p>

            <p className="trainingsauswahl">
              <strong>Anzahl der Töpfe:</strong>
              <span className="cursorselect">
                <select
                  value={this.state.topfauswahl}
                  onChange={this.handleChangeTopfauswahl}
                >
                  <option>0</option>
                  <option>1</option>
                </select>
              </span>
            </p>
            <div id="roteFarbe">{this.state.errorSchritt3}</div>
            <br />
            <div className="zurueckweiter">
              <div className="normal">
                <button onClick={this.handleSubmitSchritt3Back}>
                  Zurück
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <button onClick={this.handleSubmitSchritt3}>
                  Weiter
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
    /*

-------------------------- SCHRITT 4 --------------------------

  */

    if (this.state.schritt === 4) {
      return (
        <div className="topfauswahl">
          <UnderHeaderDemoProgramm />
          <div className="container">
          <p>
              <strong>Schritt 4:</strong> Topfdetails
            </p>
            <p>
              Wie viele Anmeldungen (inklusive Mehrfachtraining) befinden sich
              in den einzelnen Töpfen?
            </p>
            <p>
              Das Programm wird versuchen möglichst viele Gruppen mit der bevorzugten
              Gruppengröße zu bilden. Wenn Sie mehr dazu erfahren wollen, klicken Sie{" "}
              <u onClick={this.InformationSchritt4}>hier</u>.
            </p>
            {/* {this.state.showMoreSchritt4 ? (
              <div className="showmore">
                <p>
                  <button onClick={this.handleChangeshowMoreSchritt4}>
                    Weitere Infos &#9662;
                  </button>
                </p>
              </div>
            ) : (
              <div className="showmore">
                <p className="demovideo1">
                  <video src={Video4} controls></video>
                </p>
                <p>
                  <button onClick={this.handleChangeshowMoreSchritt4}>
                    Weniger Infos &#9652;
                  </button>
                </p>
              </div>
            )} */}

            <p id="FarbeDemo">
              Demo: Bevorzugte Gruppengröße = 4; Alternative Gruppengröße = 5
            </p>
            <p id="FarbeDemo">
              Vollversion: Freie Wahl
            </p>
            <p>
              <strong>Noch zu verteilende</strong> Anmeldungen (inklusive Mehrfachtraining):{" "}
              <strong id="roteFarbe">{this.nochuebrig(this.state.trainingsauswahl)}</strong>
            </p>
            <div className="topfgroessen">
              <table border="0">
                <tbody>
                  <tr>
                    <td>
                      
                    </td>
                    <td>
                      <p><strong>Anzahl Anmeldungen</strong></p>
                      <p><strong>(inklusive Mehrfachtraining)</strong></p>
                    </td>
                    <td>
                      <strong>Bevorzugte Gruppengröße</strong>
                    </td>
                    <td>
                      <strong>Alternative Gruppengröße</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Topf 1</strong>
                    </td>
                    <td>
                      <span className="cursorselect">
                        <select
                          value={this.state.trainingseinheiten}
                          onChange={this.handleChangeTrainingseinheiten}
                        >
                          {this.auswahlTopf(
                            parseInt("8")
                          ).map((e, key) => {
                            return <option key={e}>{e}</option>;
                          })}
                        </select>
                      </span>
                    </td>
                    <td>
                      <span className="cursorselect">
                        <select
                          value={this.state.bevorzugteTopfgroesse}
                          onChange={this.handleChangeBevorzugteTopfgroesse}
                        >
                          {this.bevorzugteGruppengroesse(
                            parseInt("8")
                          ).map((e, key) => {
                            return <option key={e}>{e}</option>;
                          })}
                        </select>
                      </span>
                    </td>
                    <td>
                      <span className="cursorselect">
                        <select
                          value={this.state.alternativeTopfgroesse}
                          onChange={this.handleChangeAlternativeTopfgroesse}
                        >
                          {this.alternativeGruppengroesse(
                            parseInt(this.state.bevorzugteTopfgroesse)
                          ).map((e, key) => {
                            return <option key={e}>{e}</option>;
                          })}
                        </select>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt4Trainingseinheiten}
                      </div>
                    </td>

                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt4Bevorzugt}
                      </div>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt4Alternativ}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            <div className="zurueckweiter">
              <div className="normal">
                <button onClick={this.handleSubmitSchritt4Back}>
                  Zurück
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <button onClick={this.handleSubmitSchritt4}>
                  Weiter
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
    /*

-------------------------- SCHRITT 5 --------------------------

  */
    if (this.state.schritt === 5) {
      if (this.state.trainingseinheiten === "8") {
        return (
          <div>
            <UnderHeaderDemoProgramm />
            <div className="container">
              <div>
                <div>
                  <p>
                    <strong id="roteFarbeGross"> Topf 1: {" "}</strong>
                    Namen und Prioritäten der Tennisschüler*innen
                  </p>
                </div>
                {/* {this.state.showMoreSchritt5 ? (
                  <div className="showmore">
                    <p>
                      <button onClick={this.handleChangeshowMoreSchritt5}>
                        Weitere Infos &#9662;
                      </button>
                    </p>
                  </div>
                ) : (
                  <div className="showmore">
                    <p className="demovideo1">
                      <video src={Video5} controls></video>
                    </p>
                    <p>
                      <button onClick={this.handleChangeshowMoreSchritt5}>
                        Weniger Infos &#9652;
                      </button>
                    </p>
                  </div>
                )} */}

                <p>
                <br/>
                Grauer Kasten = Spieler*in kann nicht zu dieser Uhrzeit<br/>
                Blauer Kasten (1x klicken) = Spieler*in kann zu dieser Uhrzeit<br/>
                Goldene Schrift (2x klicken) = Spieler*in bevorzugt diese Uhrzeit
                </p>

                <p id="FarbeDemo">
                  Demo: 1 Mal Training pro Woche
                </p>
                <p id="FarbeDemo">
                  Vollversion: Bis zu 3 Mal Training pro Woche
                </p>

                <br/>
                
                <p>
                  Setzen Sie hier alle Daten für ein Beispiel von uns:{" "}
                  &nbsp;&nbsp;&nbsp;
                  <span className="normal">
                    <button onClick={this.DemoBeispiel}>
                      Fülle Werte
                    </button>
                  </span>
                </p>
                <br/>
                <p className="cursorselect">
                  <strong>Tennisschüler*in 1:</strong>
                  <input
                    type="text"
                    value={this.state.name1}
                    onChange={this.handleChangeName1}
                    placeholder="Name"
                  ></input>
                  <span id="roteFarbe">
                    &nbsp;&nbsp;{this.state.errorSchritt5Teilnehmer1Name}
                  </span>
                </p>
                <br/>
                <div className="platzauswahltabellegross">
                  <div className="ZeitenTabelle">
                    <table>
                      <tbody>
                        <tr>
                        <td>
                            <strong>Montag</strong>
                        </td>
                        <td>
                            <strong>Dienstag</strong>
                        </td>
                        <td>
                            <strong>Mittwoch</strong>
                        </td>
                        <td>
                            <strong>Donnerstag</strong>
                        </td>
                        <td>
                            <strong>Freitag</strong>
                        </td>
                        <td>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </td>
                        <td>
                          <strong>Samstag</strong>
                        </td>
                        </tr>
                        <tr>
                          {this.state.matrix11 !== "0" ? (
                            this.state.zeit111 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit111: "1"})}}>
                                {this.state.zeit1}
                              </td>
                          ) : (
                            this.state.zeit111 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit111: "2"})}}>
                                <div className="sternweiss">{this.state.zeit1}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit111: "X"})}}>
                                <div className="sterngold">{this.state.zeit1}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix12 !== "0" ? (
                            this.state.zeit112 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit112: "1"})}}>
                                {this.state.zeit1}
                              </td>
                          ) : (
                            this.state.zeit112 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit112: "2"})}}>
                                <div className="sternweiss">{this.state.zeit1}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit112: "X"})}}>
                                <div className="sterngold">{this.state.zeit1}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix13 !== "0" ? (
                            this.state.zeit113 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit113: "1"})}}>
                                {this.state.zeit1}
                              </td>
                          ) : (
                            this.state.zeit113 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit113: "2"})}}>
                                <div className="sternweiss">{this.state.zeit1}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit113: "X"})}}>
                                <div className="sterngold">{this.state.zeit1}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix14 !== "0" ? (
                            this.state.zeit114 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit114: "1"})}}>
                                {this.state.zeit1}
                              </td>
                          ) : (
                            this.state.zeit114 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit114: "2"})}}>
                                <div className="sternweiss">{this.state.zeit1}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit114: "X"})}}>
                                <div className="sterngold">{this.state.zeit1}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix15 !== "0" ? (
                            this.state.zeit115 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit115: "1"})}}>
                                {this.state.zeit1}
                              </td>
                          ) : (
                            this.state.zeit115 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit115: "2"})}}>
                                <div className="sternweiss">{this.state.zeit1}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit115: "X"})}}>
                                <div className="sterngold">{this.state.zeit1}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix16 !== "0" ? (
                            this.state.zeit116 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit116: "1"})}}>
                              {this.state.zeit9}
                            </td>
                          ) : (
                            this.state.zeit116 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit116: "2"})}}>
                                <div className="sternweiss">{this.state.zeit9}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit116: "X"})}}>
                                <div className="sterngold">{this.state.zeit9}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix21 !== "0" ? (
                            this.state.zeit121 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit121: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit121 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit121: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit121: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix22 !== "0" ? (
                            this.state.zeit122 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit122: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit122 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit122: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit122: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix23 !== "0" ? (
                            this.state.zeit123 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit123: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit123 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit123: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit123: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix24 !== "0" ? (
                            this.state.zeit124=== "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit124: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit124 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit124: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit124: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix25 !== "0" ? (
                            this.state.zeit125 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit125: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit125 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit125: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit125: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix26 !== "0" ? (
                            this.state.zeit126 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit126: "1"})}}>
                              {this.state.zeit10}
                            </td>
                          ) : (
                            this.state.zeit126 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit126: "2"})}}>
                                <div className="sternweiss">{this.state.zeit10}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit126: "X"})}}>
                                <div className="sterngold">{this.state.zeit10}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix31 !== "0" ? (
                            this.state.zeit131 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit131: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit131 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit131: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit131: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix32 !== "0" ? (
                            this.state.zeit132 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit132: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit132 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit132: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit132: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix33 !== "0" ? (
                            this.state.zeit133 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit133: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit133 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit133: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit133: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix34 !== "0" ? (
                            this.state.zeit134 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit134: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit134 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit134: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit134: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix35 !== "0" ? (
                            this.state.zeit135 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit135: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit135 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit135: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit135: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix36 !== "0" ? (
                            this.state.zeit136 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit136: "1"})}}>
                              {this.state.zeit11}
                            </td>
                          ) : (
                            this.state.zeit136 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit136: "2"})}}>
                                <div className="sternweiss">{this.state.zeit11}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit136: "X"})}}>
                                <div className="sterngold">{this.state.zeit11}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix41 !== "0" ? (
                            this.state.zeit141 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit141: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit141 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit141: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit141: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix42 !== "0" ? (
                            this.state.zeit142 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit142: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit142 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit142: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit142: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix43 !== "0" ? (
                            this.state.zeit143 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit143: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit143 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit143: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit143: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix44 !== "0" ? (
                            this.state.zeit144 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit144: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit144 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit144: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit144: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix45 !== "0" ? (
                            this.state.zeit145 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit145: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit145 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit145: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit145: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix46 !== "0" ? (
                            this.state.zeit146 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit146: "1"})}}>
                              {this.state.zeit12}
                            </td>
                          ) : (
                            this.state.zeit146 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit146: "2"})}}>
                                <div className="sternweiss">{this.state.zeit12}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit146: "X"})}}>
                                <div className="sterngold">{this.state.zeit12}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix51 !== "0" ? (
                            this.state.zeit151 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit151: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit151 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit151: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit151: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix52 !== "0" ? (
                            this.state.zeit152 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit152: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit152 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit152: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit152: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix53 !== "0" ? (
                            this.state.zeit153 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit153: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit153 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit153: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit153: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix54 !== "0" ? (
                            this.state.zeit154 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit154: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit154 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit154: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit154: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix55 !== "0" ? (
                            this.state.zeit155 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit155: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit155 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit155: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit155: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix56 !== "0" ? (
                            this.state.zeit156 === "X" ? (
                           <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit156: "1"})}}>
                              {this.state.zeit13}
                            </td>
                          ) : (
                            this.state.zeit156 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit156: "2"})}}>
                                <div className="sternweiss">{this.state.zeit13}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit156: "X"})}}>
                                <div className="sterngold">{this.state.zeit13}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix61 !== "0" ? (
                            this.state.zeit161 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit161: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit161 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit161: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit161: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix62 !== "0" ? (
                            this.state.zeit162 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit162: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit162 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit162: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit162: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix63 !== "0" ? (
                            this.state.zeit163 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit163: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit163 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit163: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit163: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix64 !== "0" ? (
                            this.state.zeit164 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit164: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit164 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit164: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit164: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix65 !== "0" ? (
                            this.state.zeit165 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit165: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit165 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit165: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit165: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix66 !== "0" ? (
                            this.state.zeit166 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit166: "1"})}}>
                              {this.state.zeit14}
                            </td>
                          ) : (
                            this.state.zeit166 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit166: "2"})}}>
                                <div className="sternweiss">{this.state.zeit14}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit166: "X"})}}>
                                <div className="sterngold">{this.state.zeit14}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix71 !== "0" ? (
                            this.state.zeit171 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit171: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit171=== "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit171: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit171: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix72 !== "0" ? (
                            this.state.zeit172 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit172: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit172 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit172: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit172: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix73 !== "0" ? (
                            this.state.zeit173 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit173: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit173 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit173: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit173: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix74 !== "0" ? (
                            this.state.zeit174 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit174: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit174 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit174: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit174: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix75 !== "0" ? (
                            this.state.zeit175 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit175: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit175 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit175: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit175: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix76 !== "0" ? (
                            this.state.zeit176 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit176: "1"})}}>
                              {this.state.zeit15}
                            </td>
                          ) : (
                            this.state.zeit176 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit176: "2"})}}>
                                <div className="sternweiss">{this.state.zeit15}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit176: "X"})}}>
                                <div className="sterngold">{this.state.zeit15}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix81 !== "0" ? (
                            this.state.zeit181 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit181: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit181 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit181: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit181: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix82 !== "0" ? (
                            this.state.zeit182 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit182: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit182 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit182: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit182: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix83 !== "0" ? (
                            this.state.zeit183 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit183: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit183 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit183: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit183: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix84 !== "0" ? (
                            this.state.zeit184 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit184: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit184 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit184: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit184: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix85 !== "0" ? (
                            this.state.zeit185 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit185: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit185 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit185: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit185: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix86 !== "0" ? (
                            this.state.zeit186 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit186: "1"})}}>
                              {this.state.zeit16}
                            </td>
                          ) : (
                            this.state.zeit186 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit186: "2"})}}>
                                <div className="sternweiss">{this.state.zeit16}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit186: "X"})}}>
                                <div className="sterngold">{this.state.zeit16}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <br/>
                <div id="roteFarbe">
                  {this.state.errorSchritt5Teilnehmer1Zeit}
                </div>
                <hr/>
                <br/>
                <p className="cursorselect">
                  <strong>Tennisschüler*in 2:</strong>
                  <input
                    type="text"
                    value={this.state.name2}
                    onChange={this.handleChangeName2}
                    placeholder="Name"
                  ></input>
                  <span id="roteFarbe">
                    &nbsp;&nbsp;{this.state.errorSchritt5Teilnehmer2Name}
                  </span>
                </p>
                <br/>
                <div className="platzauswahltabellegross">
                  <div className="ZeitenTabelle">
                    <table>
                      <tbody>
                        <tr>
                        <td>
                            <strong>Montag</strong>
                        </td>
                        <td>
                            <strong>Dienstag</strong>
                        </td>
                        <td>
                            <strong>Mittwoch</strong>
                        </td>
                        <td>
                            <strong>Donnerstag</strong>
                        </td>
                        <td>
                            <strong>Freitag</strong>
                        </td>
                        <td>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </td>
                        <td>
                          <strong>Samstag</strong>
                        </td>
                        </tr>
                        <tr>
                          {this.state.matrix11 !== "0" ? (
                            this.state.zeit211 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit211: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit211 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit211: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit211: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix12 !== "0" ? (
                            this.state.zeit212 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit212: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit212 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit212: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit212: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix13 !== "0" ? (
                            this.state.zeit213 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit213: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit213 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit213: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit213: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix14 !== "0" ? (
                            this.state.zeit214 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit214: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit214 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit214: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit214: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix15 !== "0" ? (
                            this.state.zeit215 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit215: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit215 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit215: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit215: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix16 !== "0" ? (
                            this.state.zeit216 === "X" ? (
                          <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit216: "1"})}}>
                              {this.state.zeit9}
                            </td>
                          ) : (
                            this.state.zeit216 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit216: "2"})}}>
                                <div className="sternweiss">{this.state.zeit9}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit216: "X"})}}>
                                <div className="sterngold">{this.state.zeit9}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix21 !== "0" ? (
                            this.state.zeit221 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit221: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit221 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit221: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit221: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix22 !== "0" ? (
                            this.state.zeit222 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit222: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit222 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit222: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit222: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix23 !== "0" ? (
                            this.state.zeit223 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit223: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit223 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit223: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit223: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix24 !== "0" ? (
                            this.state.zeit224=== "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit224: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit224 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit224: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit224: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix25 !== "0" ? (
                            this.state.zeit225 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit225: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit225 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit225: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit225: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix26 !== "0" ? (
                            this.state.zeit226 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit226: "1"})}}>
                              {this.state.zeit10}
                            </td>
                          ) : (
                            this.state.zeit226 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit226: "2"})}}>
                                <div className="sternweiss">{this.state.zeit10}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit226: "X"})}}>
                                <div className="sterngold">{this.state.zeit10}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix31 !== "0" ? (
                            this.state.zeit231 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit231: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit231 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit231: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit231: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix32 !== "0" ? (
                            this.state.zeit232 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit232: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit232 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit232: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit232: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix33 !== "0" ? (
                            this.state.zeit233 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit233: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit233 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit233: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit233: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix34 !== "0" ? (
                            this.state.zeit234 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit234: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit234 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit234: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit234: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix35 !== "0" ? (
                            this.state.zeit235 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit235: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit235 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit235: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit235: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix36 !== "0" ? (
                            this.state.zeit236 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit236: "1"})}}>
                              {this.state.zeit11}
                            </td>
                          ) : (
                            this.state.zeit236 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit236: "2"})}}>
                                <div className="sternweiss">{this.state.zeit11}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit236: "X"})}}>
                                <div className="sterngold">{this.state.zeit11}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix41 !== "0" ? (
                            this.state.zeit241 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit241: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit241 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit241: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit241: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix42 !== "0" ? (
                            this.state.zeit242 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit242: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit242 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit242: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit242: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix43 !== "0" ? (
                            this.state.zeit243 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit243: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit243 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit243: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit243: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix44 !== "0" ? (
                            this.state.zeit244 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit244: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit244 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit244: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit244: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix45 !== "0" ? (
                            this.state.zeit245 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit245: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit245 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit245: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit245: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix46 !== "0" ? (
                            this.state.zeit246 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit246: "1"})}}>
                              {this.state.zeit12}
                            </td>
                        ) : (
                          this.state.zeit246 === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                              this.setState({zeit246: "2"})}}>
                              <div className="sternweiss">{this.state.zeit12}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                          ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                              this.setState({zeit246: "X"})}}>
                              <div className="sterngold">{this.state.zeit12}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                          )
                        )) : (
                          <td></td>
                        )}
                        </tr>
                        <tr>
                          {this.state.matrix51 !== "0" ? (
                            this.state.zeit251 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit251: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit251 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit251: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit251: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix52 !== "0" ? (
                            this.state.zeit252 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit252: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit252 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit252: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit252: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix53 !== "0" ? (
                            this.state.zeit253 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit253: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit253 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit253: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit253: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix54 !== "0" ? (
                            this.state.zeit254 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit254: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit254 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit254: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit254: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix55 !== "0" ? (
                            this.state.zeit255 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit255: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit255 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit255: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit255: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix56 !== "0" ? (
                            this.state.zeit256 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit256: "1"})}}>
                              {this.state.zeit13}
                            </td>
                        ) : (
                          this.state.zeit256 === "1" ? (
                            <td className="blauezelle" onClick={(event) => {
                              this.setState({zeit256: "2"})}}>
                              <div className="sternweiss">{this.state.zeit13}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                          ) : ( 
                            <td className="blauezelle" onClick={(event) => {
                              this.setState({zeit256: "X"})}}>
                              <div className="sterngold">{this.state.zeit13}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                          )
                        )) : (
                          <td></td>
                        )}
                        </tr>
                        <tr>
                          {this.state.matrix61 !== "0" ? (
                            this.state.zeit261 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit261: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit261 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit261: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit261: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix62 !== "0" ? (
                            this.state.zeit262 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit262: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit262 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit262: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit262: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix63 !== "0" ? (
                            this.state.zeit263 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit263: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit263 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit263: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit263: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix64 !== "0" ? (
                            this.state.zeit264 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit264: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit264 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit264: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit264: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix65 !== "0" ? (
                            this.state.zeit265 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit265: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit265 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit265: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit265: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix66 !== "0" ? (
                            this.state.zeit266 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit266: "1"})}}>
                              {this.state.zeit14}
                            </td>
                          ) : (
                            this.state.zeit266 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit266: "2"})}}>
                                <div className="sternweiss">{this.state.zeit14}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit266: "X"})}}>
                                <div className="sterngold">{this.state.zeit14}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix71 !== "0" ? (
                            this.state.zeit271 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit271: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit271=== "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit271: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit271: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix72 !== "0" ? (
                            this.state.zeit272 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit272: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit272 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit272: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit272: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix73 !== "0" ? (
                            this.state.zeit273 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit273: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit273 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit273: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit273: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix74 !== "0" ? (
                            this.state.zeit274 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit274: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit274 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit274: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit274: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix75 !== "0" ? (
                            this.state.zeit275 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit275: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit275 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit275: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit275: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix76 !== "0" ? (
                            this.state.zeit276 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit276: "1"})}}>
                              {this.state.zeit15}
                            </td>
                          ) : (
                            this.state.zeit276 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit276: "2"})}}>
                                <div className="sternweiss">{this.state.zeit15}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit276: "X"})}}>
                                <div className="sterngold">{this.state.zeit15}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix81 !== "0" ? (
                            this.state.zeit281 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit281: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit281 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit281: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit281: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix82 !== "0" ? (
                            this.state.zeit282 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit282: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit282 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit282: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit282: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix83 !== "0" ? (
                            this.state.zeit283 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit283: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit283 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit283: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit283: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix84 !== "0" ? (
                            this.state.zeit284 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit284: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit284 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit284: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit284: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix85 !== "0" ? (
                            this.state.zeit285 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit285: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit285 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit285: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit285: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix86 !== "0" ? (
                            this.state.zeit286 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit286: "1"})}}>
                              {this.state.zeit16}
                            </td>
                          ) : (
                            this.state.zeit286 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit286: "2"})}}>
                                <div className="sternweiss">{this.state.zeit16}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit286: "X"})}}>
                                <div className="sterngold">{this.state.zeit16}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <br/>
                <div id="roteFarbe">
                  {this.state.errorSchritt5Teilnehmer2Zeit}
                </div>
                <hr />
                <br />
                <p className="cursorselect">
                  <strong>Tennisschüler*in 3:</strong>
                  <input
                    type="text"
                    value={this.state.name3}
                    onChange={this.handleChangeName3}
                    placeholder="Name"
                  ></input>
                  <span id="roteFarbe">
                    &nbsp;&nbsp;{this.state.errorSchritt5Teilnehmer3Name}
                  </span>
                </p>
                <br/>
                <div className="platzauswahltabellegross">
                  <div className="ZeitenTabelle">
                    <table>
                      <tbody>
                        <tr>
                        <td>
                            <strong>Montag</strong>
                        </td>
                        <td>
                            <strong>Dienstag</strong>
                        </td>
                        <td>
                            <strong>Mittwoch</strong>
                        </td>
                        <td>
                            <strong>Donnerstag</strong>
                        </td>
                        <td>
                            <strong>Freitag</strong>
                        </td>
                        <td>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </td>
                        <td>
                          <strong>Samstag</strong>
                        </td>
                        </tr>
                        <tr>
                          {this.state.matrix11 !== "0" ? (
                            this.state.zeit311 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit311: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit311 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit311: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit311: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix12 !== "0" ? (
                            this.state.zeit312 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit312: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit312 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit312: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit312: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix13 !== "0" ? (
                            this.state.zeit313 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit313: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit313 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit313: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit313: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix14 !== "0" ? (
                            this.state.zeit314 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit314: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit314 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit314: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit314: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix15 !== "0" ? (
                            this.state.zeit315 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit315: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit315 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit315: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit315: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix16 !== "0" ? (
                            this.state.zeit316 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit316: "1"})}}>
                              {this.state.zeit9}
                            </td>
                          ) : (
                            this.state.zeit316 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit316: "2"})}}>
                                <div className="sternweiss">{this.state.zeit9}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit316: "X"})}}>
                                <div className="sterngold">{this.state.zeit9}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix21 !== "0" ? (
                            this.state.zeit321 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit321: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit321 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit321: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit321: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix22 !== "0" ? (
                            this.state.zeit322 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit322: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit322 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit322: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit322: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix23 !== "0" ? (
                            this.state.zeit323 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit323: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit323 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit323: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit323: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix24 !== "0" ? (
                            this.state.zeit324=== "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit324: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit324 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit324: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit324: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix25 !== "0" ? (
                            this.state.zeit325 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit325: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit325 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit325: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit325: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix26 !== "0" ? (
                            this.state.zeit326 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit326: "1"})}}>
                              {this.state.zeit10}
                            </td>
                          ) : (
                            this.state.zeit326 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit326: "2"})}}>
                                <div className="sternweiss">{this.state.zeit10}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit326: "X"})}}>
                                <div className="sterngold">{this.state.zeit10}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix31 !== "0" ? (
                            this.state.zeit331 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit331: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit331 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit331: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit331: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix32 !== "0" ? (
                            this.state.zeit332 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit332: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit332 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit332: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit332: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix33 !== "0" ? (
                            this.state.zeit333 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit333: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit333 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit333: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit333: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix34 !== "0" ? (
                            this.state.zeit334 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit334: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit334 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit334: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit334: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix35 !== "0" ? (
                            this.state.zeit335 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit335: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit335 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit335: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit335: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix36 !== "0" ? (
                            this.state.zeit336 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit336: "1"})}}>
                              {this.state.zeit11}
                            </td>
                          ) : (
                            this.state.zeit336 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit336: "2"})}}>
                                <div className="sternweiss">{this.state.zeit11}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit336: "X"})}}>
                                <div className="sterngold">{this.state.zeit11}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix41 !== "0" ? (
                            this.state.zeit341 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit341: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit341 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit341: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit341: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix42 !== "0" ? (
                            this.state.zeit342 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit342: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit342 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit342: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit342: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix43 !== "0" ? (
                            this.state.zeit343 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit343: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit343 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit343: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit343: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix44 !== "0" ? (
                            this.state.zeit344 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit344: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit344 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit344: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit344: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix45 !== "0" ? (
                            this.state.zeit345 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit345: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit345 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit345: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit345: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix46 !== "0" ? (
                            this.state.zeit346 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit346: "1"})}}>
                              {this.state.zeit12}
                            </td>
                          ) : (
                            this.state.zeit346 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit346: "2"})}}>
                                <div className="sternweiss">{this.state.zeit12}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit346: "X"})}}>
                                <div className="sterngold">{this.state.zeit12}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix51 !== "0" ? (
                            this.state.zeit351 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit351: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit351 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit351: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit351: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix52 !== "0" ? (
                            this.state.zeit352 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit352: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit352 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit352: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit352: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix53 !== "0" ? (
                            this.state.zeit353 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit353: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit353 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit353: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit353: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix54 !== "0" ? (
                            this.state.zeit354 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit354: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit354 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit354: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit354: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix55 !== "0" ? (
                            this.state.zeit355 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit355: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit355 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit355: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit355: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix56 !== "0" ? (
                            this.state.zeit356 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit356: "1"})}}>
                              {this.state.zeit13}
                            </td>
                          ) : (
                            this.state.zeit356 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit356: "2"})}}>
                                <div className="sternweiss">{this.state.zeit13}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit356: "X"})}}>
                                <div className="sterngold">{this.state.zeit13}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix61 !== "0" ? (
                            this.state.zeit361 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit361: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit361 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit361: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit361: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix62 !== "0" ? (
                            this.state.zeit362 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit362: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit362 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit362: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit362: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix63 !== "0" ? (
                            this.state.zeit363 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit363: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit363 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit363: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit363: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix64 !== "0" ? (
                            this.state.zeit364 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit364: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit364 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit364: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit364: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix65 !== "0" ? (
                            this.state.zeit365 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit365: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit365 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit365: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit365: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix66 !== "0" ? (
                            this.state.zeit366 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit366: "1"})}}>
                              {this.state.zeit14}
                            </td>
                          ) : (
                            this.state.zeit366 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit366: "2"})}}>
                                <div className="sternweiss">{this.state.zeit14}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit366: "X"})}}>
                                <div className="sterngold">{this.state.zeit14}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix71 !== "0" ? (
                            this.state.zeit371 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit371: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit371=== "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit371: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit371: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix72 !== "0" ? (
                            this.state.zeit372 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit372: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit372 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit372: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit372: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix73 !== "0" ? (
                            this.state.zeit373 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit373: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit373 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit373: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit373: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix74 !== "0" ? (
                            this.state.zeit374 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit374: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit374 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit374: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit374: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix75 !== "0" ? (
                            this.state.zeit375 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit375: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit375 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit375: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit375: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix76 !== "0" ? (
                            this.state.zeit376 === "X" ? (
                           <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit376: "1"})}}>
                              {this.state.zeit15}
                            </td>
                          ) : (
                            this.state.zeit376 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit376: "2"})}}>
                                <div className="sternweiss">{this.state.zeit15}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit376: "X"})}}>
                                <div className="sterngold">{this.state.zeit15}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix81 !== "0" ? (
                            this.state.zeit381 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit381: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit381 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit381: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit381: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix82 !== "0" ? (
                            this.state.zeit382 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit382: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit382 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit382: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit382: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix83 !== "0" ? (
                            this.state.zeit383 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit383: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit383 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit383: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit383: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix84 !== "0" ? (
                            this.state.zeit384 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit384: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit384 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit384: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit384: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix85 !== "0" ? (
                            this.state.zeit385 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit385: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit385 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit385: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit385: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix86 !== "0" ? (
                            this.state.zeit386 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit386: "1"})}}>
                              {this.state.zeit16}
                            </td>
                          ) : (
                            this.state.zeit386 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit386: "2"})}}>
                                <div className="sternweiss">{this.state.zeit16}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit386: "X"})}}>
                                <div className="sterngold">{this.state.zeit16}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <br/>
                <div id="roteFarbe">
                  {this.state.errorSchritt5Teilnehmer3Zeit}
                </div>
                <hr/>
                <br />
                <p className="cursorselect">
                  <strong>Tennisschüler*in 4:</strong>
                  <input
                    type="text"
                    value={this.state.name4}
                    onChange={this.handleChangeName4}
                    placeholder="Name"
                  ></input>
                  <span id="roteFarbe">
                    &nbsp;&nbsp;{this.state.errorSchritt5Teilnehmer4Name}
                  </span>
                </p>
                <br />
                <div className="platzauswahltabellegross">
                  <div className="ZeitenTabelle">
                    <table>
                      <tbody>
                        <tr>
                        <td>
                            <strong>Montag</strong>
                        </td>
                        <td>
                            <strong>Dienstag</strong>
                        </td>
                        <td>
                            <strong>Mittwoch</strong>
                        </td>
                        <td>
                            <strong>Donnerstag</strong>
                        </td>
                        <td>
                            <strong>Freitag</strong>
                        </td>
                        <td>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </td>
                        <td>
                          <strong>Samstag</strong>
                        </td>
                        </tr>
                        <tr>
                          {this.state.matrix11 !== "0" ? (
                            this.state.zeit411 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit411: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit411 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit411: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit411: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix12 !== "0" ? (
                            this.state.zeit412 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit412: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit412 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit412: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit412: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix13 !== "0" ? (
                            this.state.zeit413 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit413: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit413 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit413: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit413: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix14 !== "0" ? (
                            this.state.zeit414 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit414: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit414 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit414: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit414: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix15 !== "0" ? (
                            this.state.zeit415 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit415: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit415 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit415: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit415: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix16 !== "0" ? (
                            this.state.zeit416 === "X" ? (
                          <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit416: "1"})}}>
                              {this.state.zeit9}
                            </td>
                          ) : (
                            this.state.zeit416 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit416: "2"})}}>
                                <div className="sternweiss">{this.state.zeit9}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit416: "X"})}}>
                                <div className="sterngold">{this.state.zeit9}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix21 !== "0" ? (
                            this.state.zeit421 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit421: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit421 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit421: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit421: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix22 !== "0" ? (
                            this.state.zeit422 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit422: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit422 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit422: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit422: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix23 !== "0" ? (
                            this.state.zeit423 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit423: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit423 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit423: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit423: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix24 !== "0" ? (
                            this.state.zeit424=== "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit424: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit424 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit424: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit424: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix25 !== "0" ? (
                            this.state.zeit425 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit425: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit425 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit425: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit425: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix26 !== "0" ? (
                            this.state.zeit426 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit426: "1"})}}>
                              {this.state.zeit10}
                            </td>
                          ) : (
                            this.state.zeit426 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit426: "2"})}}>
                                <div className="sternweiss">{this.state.zeit10}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit426: "X"})}}>
                                <div className="sterngold">{this.state.zeit10}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix31 !== "0" ? (
                            this.state.zeit431 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit431: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit431 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit431: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit431: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix32 !== "0" ? (
                            this.state.zeit432 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit432: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit432 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit432: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit432: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix33 !== "0" ? (
                            this.state.zeit433 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit433: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit433 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit433: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit433: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix34 !== "0" ? (
                            this.state.zeit434 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit434: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit434 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit434: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit434: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix35 !== "0" ? (
                            this.state.zeit435 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit435: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit435 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit435: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit435: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix36 !== "0" ? (
                            this.state.zeit436 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit436: "1"})}}>
                              {this.state.zeit11}
                            </td>
                          ) : (
                            this.state.zeit436 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit436: "2"})}}>
                                <div className="sternweiss">{this.state.zeit11}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit436: "X"})}}>
                                <div className="sterngold">{this.state.zeit11}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix41 !== "0" ? (
                            this.state.zeit441 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit441: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit441 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit441: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit441: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix42 !== "0" ? (
                            this.state.zeit442 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit442: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit442 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit442: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit442: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix43 !== "0" ? (
                            this.state.zeit443 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit443: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit443 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit443: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit443: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix44 !== "0" ? (
                            this.state.zeit444 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit444: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit444 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit444: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit444: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix45 !== "0" ? (
                            this.state.zeit445 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit445: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit445 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit445: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit445: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix46 !== "0" ? (
                            this.state.zeit446 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit446: "1"})}}>
                              {this.state.zeit12}
                            </td>
                          ) : (
                            this.state.zeit446 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit446: "2"})}}>
                                <div className="sternweiss">{this.state.zeit12}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit446: "X"})}}>
                                <div className="sterngold">{this.state.zeit12}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix51 !== "0" ? (
                            this.state.zeit451 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit451: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit451 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit451: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit451: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix52 !== "0" ? (
                            this.state.zeit452 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit452: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit452 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit452: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit452: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix53 !== "0" ? (
                            this.state.zeit453 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit453: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit453 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit453: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit453: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix54 !== "0" ? (
                            this.state.zeit454 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit454: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit454 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit454: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit454: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix55 !== "0" ? (
                            this.state.zeit455 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit455: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit455 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit455: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit455: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix56 !== "0" ? (
                            this.state.zeit456 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit456: "1"})}}>
                              {this.state.zeit13}
                            </td>
                          ) : (
                            this.state.zeit456 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit456: "2"})}}>
                                <div className="sternweiss">{this.state.zeit13}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit456: "X"})}}>
                                <div className="sterngold">{this.state.zeit13}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix61 !== "0" ? (
                            this.state.zeit461 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit461: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit461 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit461: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit461: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix62 !== "0" ? (
                            this.state.zeit462 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit462: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit462 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit462: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit462: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix63 !== "0" ? (
                            this.state.zeit463 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit463: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit463 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit463: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit463: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix64 !== "0" ? (
                            this.state.zeit464 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit464: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit464 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit464: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit464: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix65 !== "0" ? (
                            this.state.zeit465 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit465: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit465 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit465: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit465: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix66 !== "0" ? (
                            this.state.zeit466 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit466: "1"})}}>
                              {this.state.zeit14}
                            </td>
                          ) : (
                            this.state.zeit466 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit466: "2"})}}>
                                <div className="sternweiss">{this.state.zeit14}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit466: "X"})}}>
                                <div className="sterngold">{this.state.zeit14}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix71 !== "0" ? (
                            this.state.zeit471 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit471: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit471=== "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit471: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit471: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix72 !== "0" ? (
                            this.state.zeit472 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit472: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit472 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit472: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit472: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix73 !== "0" ? (
                            this.state.zeit473 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit473: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit473 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit473: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit473: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix74 !== "0" ? (
                            this.state.zeit474 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit474: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit474 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit474: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit474: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix75 !== "0" ? (
                            this.state.zeit475 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit475: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit475 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit475: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit475: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix76 !== "0" ? (
                            this.state.zeit476 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit476: "1"})}}>
                              {this.state.zeit15}
                            </td>
                          ) : (
                            this.state.zeit476 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit476: "2"})}}>
                                <div className="sternweiss">{this.state.zeit15}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit476: "X"})}}>
                                <div className="sterngold">{this.state.zeit15}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix81 !== "0" ? (
                            this.state.zeit481 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit481: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit481 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit481: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit481: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix82 !== "0" ? (
                            this.state.zeit482 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit482: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit482 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit482: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit482: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix83 !== "0" ? (
                            this.state.zeit483 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit483: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit483 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit483: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit483: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix84 !== "0" ? (
                            this.state.zeit484 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit484: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit484 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit484: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit484: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix85 !== "0" ? (
                            this.state.zeit485 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit485: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit485 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit485: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit485: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix86 !== "0" ? (
                            this.state.zeit486 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit486: "1"})}}>
                              {this.state.zeit16}
                            </td>
                          ) : (
                            this.state.zeit486 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit486: "2"})}}>
                                <div className="sternweiss">{this.state.zeit16}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit486: "X"})}}>
                                <div className="sterngold">{this.state.zeit16}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <br/>
                <div id="roteFarbe">
                  {this.state.errorSchritt5Teilnehmer4Zeit}
                </div>
                <hr />
                <br />
                <p className="cursorselect">
                  <strong>Tennisschüler*in 5:</strong>
                  <input
                    type="text"
                    value={this.state.name5}
                    onChange={this.handleChangeName5}
                    placeholder="Name"
                  ></input>
                  <span id="roteFarbe">
                    &nbsp;&nbsp;{this.state.errorSchritt5Teilnehmer5Name}
                  </span>
                </p>
                <br />
                <div className="platzauswahltabellegross">
                <div className="ZeitenTabelle">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                              <strong>Montag</strong>
                          </td>
                          <td>
                              <strong>Dienstag</strong>
                          </td>
                          <td>
                              <strong>Mittwoch</strong>
                          </td>
                          <td>
                              <strong>Donnerstag</strong>
                          </td>
                          <td>
                              <strong>Freitag</strong>
                          </td>
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          <td>
                            <strong>Samstag</strong>
                          </td>
                        </tr>
                        <tr>
                          {this.state.matrix11 !== "0" ? (
                            this.state.zeit511 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit511: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit511 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit511: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit511: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix12 !== "0" ? (
                            this.state.zeit512 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit512: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit512 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit512: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit512: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix13 !== "0" ? (
                            this.state.zeit513 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit513: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit513 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit513: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit513: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix14 !== "0" ? (
                            this.state.zeit514 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit514: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit514 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit514: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit514: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix15 !== "0" ? (
                            this.state.zeit515 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit515: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit515 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit515: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit515: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix16 !== "0" ? (
                            this.state.zeit516 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit516: "1"})}}>
                              {this.state.zeit9}
                            </td>
                          ) : (
                            this.state.zeit516 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit516: "2"})}}>
                                <div className="sternweiss">{this.state.zeit9}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit516: "X"})}}>
                                <div className="sterngold">{this.state.zeit9}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix21 !== "0" ? (
                            this.state.zeit521 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit521: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit521 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit521: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit521: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix22 !== "0" ? (
                            this.state.zeit522 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit522: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit522 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit522: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit522: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix23 !== "0" ? (
                            this.state.zeit523 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit523: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit523 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit523: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit523: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix24 !== "0" ? (
                            this.state.zeit524=== "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit524: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit524 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit524: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit524: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix25 !== "0" ? (
                            this.state.zeit525 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit525: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit525 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit525: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit525: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix26 !== "0" ? (
                            this.state.zeit526 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit526: "1"})}}>
                              {this.state.zeit10}
                            </td>
                          ) : (
                            this.state.zeit526 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit526: "2"})}}>
                                <div className="sternweiss">{this.state.zeit10}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit526: "X"})}}>
                                <div className="sterngold">{this.state.zeit10}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix31 !== "0" ? (
                            this.state.zeit531 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit531: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit531 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit531: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit531: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix32 !== "0" ? (
                            this.state.zeit532 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit532: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit532 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit532: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit532: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix33 !== "0" ? (
                            this.state.zeit533 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit533: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit533 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit533: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit533: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix34 !== "0" ? (
                            this.state.zeit534 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit534: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit534 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit534: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit534: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix35 !== "0" ? (
                            this.state.zeit535 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit535: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit535 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit535: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit535: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix36 !== "0" ? (
                            this.state.zeit536 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit536: "1"})}}>
                              {this.state.zeit11}
                            </td>
                          ) : (
                            this.state.zeit536 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit536: "2"})}}>
                                <div className="sternweiss">{this.state.zeit11}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit536: "X"})}}>
                                <div className="sterngold">{this.state.zeit11}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix41 !== "0" ? (
                            this.state.zeit541 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit541: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit541 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit541: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit541: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix42 !== "0" ? (
                            this.state.zeit542 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit542: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit542 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit542: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit542: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix43 !== "0" ? (
                            this.state.zeit543 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit543: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit543 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit543: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit543: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix44 !== "0" ? (
                            this.state.zeit544 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit544: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit544 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit544: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit544: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix45 !== "0" ? (
                            this.state.zeit545 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit545: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit545 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit545: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit545: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix46 !== "0" ? (
                            this.state.zeit546 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit546: "1"})}}>
                              {this.state.zeit12}
                            </td>
                          ) : (
                            this.state.zeit546 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit546: "2"})}}>
                                <div className="sternweiss">{this.state.zeit12}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit546: "X"})}}>
                                <div className="sterngold">{this.state.zeit12}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix51 !== "0" ? (
                            this.state.zeit551 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit551: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit551 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit551: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit551: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix52 !== "0" ? (
                            this.state.zeit552 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit552: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit552 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit552: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit552: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix53 !== "0" ? (
                            this.state.zeit553 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit553: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit553 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit553: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit553: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix54 !== "0" ? (
                            this.state.zeit554 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit554: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit554 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit554: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit554: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix55 !== "0" ? (
                            this.state.zeit555 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit555: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit555 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit555: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit555: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix56 !== "0" ? (
                            this.state.zeit556 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit556: "1"})}}>
                              {this.state.zeit13}
                            </td>
                          ) : (
                            this.state.zeit556 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit556: "2"})}}>
                                <div className="sternweiss">{this.state.zeit13}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit556: "X"})}}>
                                <div className="sterngold">{this.state.zeit13}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix61 !== "0" ? (
                            this.state.zeit561 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit561: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit561 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit561: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit561: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix62 !== "0" ? (
                            this.state.zeit562 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit562: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit562 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit562: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit562: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix63 !== "0" ? (
                            this.state.zeit563 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit563: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit563 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit563: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit563: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix64 !== "0" ? (
                            this.state.zeit564 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit564: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit564 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit564: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit564: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix65 !== "0" ? (
                            this.state.zeit565 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit565: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit565 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit565: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit565: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix66 !== "0" ? (
                            this.state.zeit566 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit566: "1"})}}>
                              {this.state.zeit14}
                            </td>
                          ) : (
                            this.state.zeit566 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit566: "2"})}}>
                                <div className="sternweiss">{this.state.zeit14}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit566: "X"})}}>
                                <div className="sterngold">{this.state.zeit14}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix71 !== "0" ? (
                            this.state.zeit571 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit571: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit571=== "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit571: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit571: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix72 !== "0" ? (
                            this.state.zeit572 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit572: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit572 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit572: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit572: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix73 !== "0" ? (
                            this.state.zeit573 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit573: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit573 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit573: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit573: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix74 !== "0" ? (
                            this.state.zeit574 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit574: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit574 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit574: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit574: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix75 !== "0" ? (
                            this.state.zeit575 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit575: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit575 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit575: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit575: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix76 !== "0" ? (
                            this.state.zeit576 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit576: "1"})}}>
                              {this.state.zeit15}
                            </td>
                          ) : (
                            this.state.zeit576 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit576: "2"})}}>
                                <div className="sternweiss">{this.state.zeit15}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit576: "X"})}}>
                                <div className="sterngold">{this.state.zeit15}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix81 !== "0" ? (
                            this.state.zeit581 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit581: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit581 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit581: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit581: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix82 !== "0" ? (
                            this.state.zeit582 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit582: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit582 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit582: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit582: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix83 !== "0" ? (
                            this.state.zeit583 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit583: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit583 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit583: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit583: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix84 !== "0" ? (
                            this.state.zeit584 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit584: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit584 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit584: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit584: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix85 !== "0" ? (
                            this.state.zeit585 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit585: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit585 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit585: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit585: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix86 !== "0" ? (
                            this.state.zeit586 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit586: "1"})}}>
                              {this.state.zeit16}
                            </td>
                          ) : (
                            this.state.zeit586 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit586: "2"})}}>
                                <div className="sternweiss">{this.state.zeit16}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit586: "X"})}}>
                                <div className="sterngold">{this.state.zeit16}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <br/>
                <div id="roteFarbe">
                  {this.state.errorSchritt5Teilnehmer5Zeit}
                </div>
                <hr/>
                <br />
                <p className="cursorselect">
                  <strong>Tennisschüler*in 6:</strong>
                  <input
                    type="text"
                    value={this.state.name6}
                    onChange={this.handleChangeName6}
                    placeholder="Name"
                  ></input>
                  <span id="roteFarbe">
                    &nbsp;&nbsp;{this.state.errorSchritt5Teilnehmer6Name}
                  </span>
                </p>
                <br />
                <div className="platzauswahltabellegross">
                <div className="ZeitenTabelle">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                              <strong>Montag</strong>
                          </td>
                          <td>
                              <strong>Dienstag</strong>
                          </td>
                          <td>
                              <strong>Mittwoch</strong>
                          </td>
                          <td>
                              <strong>Donnerstag</strong>
                          </td>
                          <td>
                              <strong>Freitag</strong>
                          </td>
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          <td>
                            <strong>Samstag</strong>
                          </td>
                        </tr>
                        <tr>
                          {this.state.matrix11 !== "0" ? (
                            this.state.zeit611 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit611: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit611 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit611: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit611: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix12 !== "0" ? (
                            this.state.zeit612 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit612: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit612 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit612: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit612: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix13 !== "0" ? (
                            this.state.zeit613 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit613: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit613 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit613: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit613: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix14 !== "0" ? (
                            this.state.zeit614 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit614: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit614 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit614: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit614: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix15 !== "0" ? (
                            this.state.zeit615 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit615: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit615 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit615: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit615: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix16 !== "0" ? (
                            this.state.zeit616 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit616: "1"})}}>
                              {this.state.zeit9}
                            </td>
                          ) : (
                            this.state.zeit616 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit616: "2"})}}>
                                <div className="sternweiss">{this.state.zeit9}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit616: "X"})}}>
                                <div className="sterngold">{this.state.zeit9}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix21 !== "0" ? (
                            this.state.zeit621 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit621: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit621 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit621: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit621: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix22 !== "0" ? (
                            this.state.zeit622 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit622: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit622 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit622: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit622: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix23 !== "0" ? (
                            this.state.zeit623 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit623: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit623 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit623: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit623: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix24 !== "0" ? (
                            this.state.zeit624=== "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit624: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit624 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit624: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit624: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix25 !== "0" ? (
                            this.state.zeit625 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit625: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit625 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit625: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit625: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix26 !== "0" ? (
                            this.state.zeit626 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit626: "1"})}}>
                              {this.state.zeit10}
                            </td>
                          ) : (
                            this.state.zeit626 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit626: "2"})}}>
                                <div className="sternweiss">{this.state.zeit10}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit626: "X"})}}>
                                <div className="sterngold">{this.state.zeit10}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix31 !== "0" ? (
                            this.state.zeit631 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit631: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit631 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit631: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit631: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix32 !== "0" ? (
                            this.state.zeit632 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit632: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit632 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit632: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit632: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix33 !== "0" ? (
                            this.state.zeit633 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit633: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit633 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit633: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit633: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix34 !== "0" ? (
                            this.state.zeit634 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit634: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit634 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit634: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit634: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix35 !== "0" ? (
                            this.state.zeit635 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit635: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit635 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit635: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit635: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix36 !== "0" ? (
                            this.state.zeit636 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit636: "1"})}}>
                              {this.state.zeit11}
                            </td>
                          ) : (
                            this.state.zeit636 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit636: "2"})}}>
                                <div className="sternweiss">{this.state.zeit11}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit636: "X"})}}>
                                <div className="sterngold">{this.state.zeit11}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix41 !== "0" ? (
                            this.state.zeit641 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit641: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit641 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit641: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit641: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix42 !== "0" ? (
                            this.state.zeit642 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit642: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit642 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit642: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit642: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix43 !== "0" ? (
                            this.state.zeit643 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit643: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit643 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit643: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit643: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix44 !== "0" ? (
                            this.state.zeit644 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit644: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit644 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit644: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit644: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix45 !== "0" ? (
                            this.state.zeit645 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit645: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit645 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit645: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit645: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix46 !== "0" ? (
                            this.state.zeit646 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit646: "1"})}}>
                              {this.state.zeit12}
                            </td>
                          ) : (
                            this.state.zeit646 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit646: "2"})}}>
                                <div className="sternweiss">{this.state.zeit12}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit646: "X"})}}>
                                <div className="sterngold">{this.state.zeit12}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix51 !== "0" ? (
                            this.state.zeit651 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit651: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit651 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit651: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit651: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix52 !== "0" ? (
                            this.state.zeit652 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit652: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit652 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit652: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit652: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix53 !== "0" ? (
                            this.state.zeit653 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit653: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit653 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit653: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit653: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix54 !== "0" ? (
                            this.state.zeit654 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit654: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit654 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit654: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit654: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix55 !== "0" ? (
                            this.state.zeit655 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit655: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit655 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit655: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit655: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix56 !== "0" ? (
                            this.state.zeit656 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit656: "1"})}}>
                              {this.state.zeit13}
                            </td>
                          ) : (
                            this.state.zeit656 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit656: "2"})}}>
                                <div className="sternweiss">{this.state.zeit13}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit656: "X"})}}>
                                <div className="sterngold">{this.state.zeit13}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix61 !== "0" ? (
                            this.state.zeit661 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit661: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit661 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit661: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit661: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix62 !== "0" ? (
                            this.state.zeit662 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit662: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit662 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit662: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit662: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix63 !== "0" ? (
                            this.state.zeit663 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit663: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit663 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit663: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit663: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix64 !== "0" ? (
                            this.state.zeit664 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit664: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit664 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit664: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit664: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix65 !== "0" ? (
                            this.state.zeit665 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit665: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit665 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit665: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit665: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix66 !== "0" ? (
                            this.state.zeit666 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit666: "1"})}}>
                              {this.state.zeit14}
                            </td>
                          ) : (
                            this.state.zeit666 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit666: "2"})}}>
                                <div className="sternweiss">{this.state.zeit14}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit666: "X"})}}>
                                <div className="sterngold">{this.state.zeit14}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix71 !== "0" ? (
                            this.state.zeit671 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit671: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit671=== "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit671: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit671: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix72 !== "0" ? (
                            this.state.zeit672 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit672: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit672 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit672: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit672: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix73 !== "0" ? (
                            this.state.zeit673 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit673: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit673 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit673: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit673: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix74 !== "0" ? (
                            this.state.zeit674 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit674: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit674 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit674: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit674: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix75 !== "0" ? (
                            this.state.zeit675 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit675: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit675 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit675: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit675: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix76 !== "0" ? (
                            this.state.zeit676 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit676: "1"})}}>
                              {this.state.zeit15}
                            </td>
                          ) : (
                            this.state.zeit676 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit676: "2"})}}>
                                <div className="sternweiss">{this.state.zeit15}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit676: "X"})}}>
                                <div className="sterngold">{this.state.zeit15}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix81 !== "0" ? (
                            this.state.zeit681 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit681: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit681 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit681: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit681: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix82 !== "0" ? (
                            this.state.zeit682 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit682: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit682 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit682: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit682: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix83 !== "0" ? (
                            this.state.zeit683 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit683: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit683 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit683: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit683: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix84 !== "0" ? (
                            this.state.zeit684 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit684: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit684 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit684: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit684: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix85 !== "0" ? (
                            this.state.zeit685 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit685: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit685 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit685: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit685: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix86 !== "0" ? (
                            this.state.zeit686 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit686: "1"})}}>
                              {this.state.zeit16}
                            </td>
                          ) : (
                            this.state.zeit686 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit686: "2"})}}>
                                <div className="sternweiss">{this.state.zeit16}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit686: "X"})}}>
                                <div className="sterngold">{this.state.zeit16}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <br/>
                <div id="roteFarbe">
                  {this.state.errorSchritt5Teilnehmer6Zeit}
                </div>
                <hr />
                <br />
                <p className="cursorselect">
                  <strong>Tennisschüler*in 7:</strong>
                  <input
                    type="text"
                    value={this.state.name7}
                    onChange={this.handleChangeName7}
                    placeholder="Name"
                  ></input>
                  <span id="roteFarbe">
                    &nbsp;&nbsp;{this.state.errorSchritt5Teilnehmer7Name}
                  </span>
                </p>
                
                <br />

                <div className="platzauswahltabellegross">
                <div className="ZeitenTabelle">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                              <strong>Montag</strong>
                          </td>
                          <td>
                              <strong>Dienstag</strong>
                          </td>
                          <td>
                              <strong>Mittwoch</strong>
                          </td>
                          <td>
                              <strong>Donnerstag</strong>
                          </td>
                          <td>
                              <strong>Freitag</strong>
                          </td>
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          <td>
                            <strong>Samstag</strong>
                          </td>
                        </tr>
                        <tr>
                          {this.state.matrix11 !== "0" ? (
                            this.state.zeit711 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit711: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit711 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit711: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit711: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix12 !== "0" ? (
                            this.state.zeit712 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit712: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit712 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit712: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit712: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix13 !== "0" ? (
                            this.state.zeit713 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit713: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit713 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit713: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit713: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix14 !== "0" ? (
                            this.state.zeit714 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit714: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit714 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit714: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit714: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix15 !== "0" ? (
                            this.state.zeit715 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit715: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit715 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit715: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit715: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix16 !== "0" ? (
                            this.state.zeit716 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit716: "1"})}}>
                              {this.state.zeit9}
                            </td>
                          ) : (
                            this.state.zeit716 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit716: "2"})}}>
                                <div className="sternweiss">{this.state.zeit9}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit716: "X"})}}>
                                <div className="sterngold">{this.state.zeit9}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix21 !== "0" ? (
                            this.state.zeit721 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit721: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit721 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit721: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit721: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix22 !== "0" ? (
                            this.state.zeit722 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit722: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit722 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit722: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit722: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix23 !== "0" ? (
                            this.state.zeit723 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit723: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit723 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit723: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit723: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix24 !== "0" ? (
                            this.state.zeit724=== "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit724: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit724 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit724: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit724: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix25 !== "0" ? (
                            this.state.zeit725 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit725: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit725 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit725: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit725: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix26 !== "0" ? (
                            this.state.zeit726 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit726: "1"})}}>
                              {this.state.zeit10}
                            </td>
                          ) : (
                            this.state.zeit726 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit726: "2"})}}>
                                <div className="sternweiss">{this.state.zeit10}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit726: "X"})}}>
                                <div className="sterngold">{this.state.zeit10}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix31 !== "0" ? (
                            this.state.zeit731 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit731: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit731 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit731: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit731: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix32 !== "0" ? (
                            this.state.zeit732 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit732: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit732 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit732: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit732: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix33 !== "0" ? (
                            this.state.zeit733 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit733: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit733 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit733: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit733: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix34 !== "0" ? (
                            this.state.zeit734 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit734: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit734 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit734: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit734: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix35 !== "0" ? (
                            this.state.zeit735 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit735: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit735 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit735: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit735: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix36 !== "0" ? (
                            this.state.zeit736 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit736: "1"})}}>
                              {this.state.zeit11}
                            </td>
                          ) : (
                            this.state.zeit736 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit736: "2"})}}>
                                <div className="sternweiss">{this.state.zeit11}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit736: "X"})}}>
                                <div className="sterngold">{this.state.zeit11}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix41 !== "0" ? (
                            this.state.zeit741 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit741: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit741 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit741: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit741: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix42 !== "0" ? (
                            this.state.zeit742 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit742: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit742 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit742: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit742: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix43 !== "0" ? (
                            this.state.zeit743 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit743: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit743 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit743: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit743: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix44 !== "0" ? (
                            this.state.zeit744 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit744: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit744 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit744: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit744: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix45 !== "0" ? (
                            this.state.zeit745 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit745: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit745 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit745: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit745: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix46 !== "0" ? (
                            this.state.zeit746 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit746: "1"})}}>
                              {this.state.zeit12}
                            </td>
                          ) : (
                            this.state.zeit746 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit746: "2"})}}>
                                <div className="sternweiss">{this.state.zeit12}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit746: "X"})}}>
                                <div className="sterngold">{this.state.zeit12}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix51 !== "0" ? (
                            this.state.zeit751 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit751: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit751 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit751: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit751: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix52 !== "0" ? (
                            this.state.zeit752 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit752: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit752 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit752: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit752: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix53 !== "0" ? (
                            this.state.zeit753 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit753: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit753 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit753: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit753: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix54 !== "0" ? (
                            this.state.zeit754 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit754: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit754 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit754: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit754: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix55 !== "0" ? (
                            this.state.zeit755 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit755: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit755 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit755: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit755: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix56 !== "0" ? (
                            this.state.zeit756 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit756: "1"})}}>
                              {this.state.zeit13}
                            </td>
                          ) : (
                            this.state.zeit756 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit756: "2"})}}>
                                <div className="sternweiss">{this.state.zeit13}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit756: "X"})}}>
                                <div className="sterngold">{this.state.zeit13}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix61 !== "0" ? (
                            this.state.zeit761 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit761: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit761 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit761: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit761: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix62 !== "0" ? (
                            this.state.zeit762 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit762: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit762 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit762: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit762: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix63 !== "0" ? (
                            this.state.zeit763 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit763: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit763 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit763: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit763: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix64 !== "0" ? (
                            this.state.zeit764 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit764: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit764 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit764: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit764: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix65 !== "0" ? (
                            this.state.zeit765 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit765: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit765 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit765: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit765: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix66 !== "0" ? (
                            this.state.zeit766 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit766: "1"})}}>
                              {this.state.zeit14}
                            </td>
                          ) : (
                            this.state.zeit766 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit766: "2"})}}>
                                <div className="sternweiss">{this.state.zeit14}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit766: "X"})}}>
                                <div className="sterngold">{this.state.zeit14}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix71 !== "0" ? (
                            this.state.zeit771 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit771: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit771=== "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit771: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit771: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix72 !== "0" ? (
                            this.state.zeit772 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit772: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit772 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit772: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit772: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix73 !== "0" ? (
                            this.state.zeit773 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit773: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit773 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit773: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit773: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix74 !== "0" ? (
                            this.state.zeit774 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit774: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit774 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit774: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit774: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix75 !== "0" ? (
                            this.state.zeit775 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit775: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit775 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit775: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit775: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix76 !== "0" ? (
                            this.state.zeit776 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit776: "1"})}}>
                              {this.state.zeit15}
                            </td>
                          ) : (
                            this.state.zeit776 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit776: "2"})}}>
                                <div className="sternweiss">{this.state.zeit15}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit776: "X"})}}>
                                <div className="sterngold">{this.state.zeit15}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix81 !== "0" ? (
                            this.state.zeit781 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit781: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit781 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit781: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit781: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix82 !== "0" ? (
                            this.state.zeit782 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit782: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit782 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit782: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit782: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix83 !== "0" ? (
                            this.state.zeit783 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit783: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit783 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit783: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit783: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix84 !== "0" ? (
                            this.state.zeit784 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit784: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit784 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit784: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit784: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix85 !== "0" ? (
                            this.state.zeit785 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit785: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit785 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit785: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit785: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix86 !== "0" ? (
                            this.state.zeit786 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit786: "1"})}}>
                              {this.state.zeit16}
                            </td>
                          ) : (
                            this.state.zeit786 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit786: "2"})}}>
                                <div className="sternweiss">{this.state.zeit16}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit786: "X"})}}>
                                <div className="sterngold">{this.state.zeit16}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <br/>
                <div id="roteFarbe">
                  {this.state.errorSchritt5Teilnehmer7Zeit}
                </div>
                <hr/>
                <br/>
                <p className="cursorselect">
                  <strong>Tennisschüler*in 8:</strong>
                  <input
                    type="text"
                    value={this.state.name8}
                    onChange={this.handleChangeName8}
                    placeholder="Name"
                  ></input>
                  <span id="roteFarbe">
                    &nbsp;&nbsp;{this.state.errorSchritt5Teilnehmer8Name}
                  </span>
                </p>
                
                <br />

                <div className="platzauswahltabellegross">
                <div className="ZeitenTabelle">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                              <strong>Montag</strong>
                          </td>
                          <td>
                              <strong>Dienstag</strong>
                          </td>
                          <td>
                              <strong>Mittwoch</strong>
                          </td>
                          <td>
                              <strong>Donnerstag</strong>
                          </td>
                          <td>
                              <strong>Freitag</strong>
                          </td>
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          <td>
                            <strong>Samstag</strong>
                          </td>
                        </tr>
                        <tr>
                          {this.state.matrix11 !== "0" ? (
                            this.state.zeit811 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit811: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit811 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit811: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit811: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix12 !== "0" ? (
                            this.state.zeit812 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit812: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit812 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit812: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit812: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix13 !== "0" ? (
                            this.state.zeit813 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit813: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit813 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit813: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit813: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix14 !== "0" ? (
                            this.state.zeit814 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit814: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit814 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit814: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit814: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix15 !== "0" ? (
                            this.state.zeit815 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit815: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit815 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit815: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit815: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix16 !== "0" ? (
                            this.state.zeit816 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit816: "1"})}}>
                              {this.state.zeit9}
                            </td>
                          ) : (
                            this.state.zeit816 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit816: "2"})}}>
                                <div className="sternweiss">{this.state.zeit9}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit816: "X"})}}>
                                <div className="sterngold">{this.state.zeit9}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix21 !== "0" ? (
                            this.state.zeit821 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit821: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit821 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit821: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit821: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix22 !== "0" ? (
                            this.state.zeit822 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit822: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit822 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit822: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit822: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix23 !== "0" ? (
                            this.state.zeit823 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit823: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit823 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit823: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit823: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix24 !== "0" ? (
                            this.state.zeit824=== "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit824: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit824 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit824: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit824: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix25 !== "0" ? (
                            this.state.zeit825 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit825: "1"})}}>
                                {this.state.zeit2}
                              </td>
                          ) : (
                            this.state.zeit825 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit825: "2"})}}>
                                <div className="sternweiss">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit825: "X"})}}>
                                <div className="sterngold">{this.state.zeit2}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix26 !== "0" ? (
                            this.state.zeit826 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit826: "1"})}}>
                              {this.state.zeit10}
                            </td>
                          ) : (
                            this.state.zeit826 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit826: "2"})}}>
                                <div className="sternweiss">{this.state.zeit10}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit826: "X"})}}>
                                <div className="sterngold">{this.state.zeit10}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix31 !== "0" ? (
                            this.state.zeit831 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit831: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit831 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit831: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit831: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix32 !== "0" ? (
                            this.state.zeit832 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit832: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit832 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit832: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit832: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix33 !== "0" ? (
                            this.state.zeit833 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit833: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit833 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit833: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit833: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix34 !== "0" ? (
                            this.state.zeit834 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit834: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit834 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit834: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit834: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix35 !== "0" ? (
                            this.state.zeit835 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit835: "1"})}}>
                                {this.state.zeit3}
                              </td>
                          ) : (
                            this.state.zeit835 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit835: "2"})}}>
                                <div className="sternweiss">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit835: "X"})}}>
                                <div className="sterngold">{this.state.zeit3}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix36 !== "0" ? (
                            this.state.zeit836 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit836: "1"})}}>
                              {this.state.zeit11}
                            </td>
                          ) : (
                            this.state.zeit836 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit836: "2"})}}>
                                <div className="sternweiss">{this.state.zeit11}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit836: "X"})}}>
                                <div className="sterngold">{this.state.zeit11}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix41 !== "0" ? (
                            this.state.zeit841 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit841: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit841 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit841: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit841: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix42 !== "0" ? (
                            this.state.zeit842 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit842: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit842 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit842: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit842: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix43 !== "0" ? (
                            this.state.zeit843 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit843: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit843 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit843: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit843: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix44 !== "0" ? (
                            this.state.zeit844 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit844: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit844 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit844: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit844: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix45 !== "0" ? (
                            this.state.zeit845 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit845: "1"})}}>
                                {this.state.zeit4}
                              </td>
                          ) : (
                            this.state.zeit845 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit845: "2"})}}>
                                <div className="sternweiss">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit845: "X"})}}>
                                <div className="sterngold">{this.state.zeit4}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix46 !== "0" ? (
                            this.state.zeit846 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit846: "1"})}}>
                              {this.state.zeit12}
                            </td>
                          ) : (
                            this.state.zeit846 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit846: "2"})}}>
                                <div className="sternweiss">{this.state.zeit12}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit846: "X"})}}>
                                <div className="sterngold">{this.state.zeit12}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix51 !== "0" ? (
                            this.state.zeit851 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit851: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit851 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit851: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit851: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix52 !== "0" ? (
                            this.state.zeit852 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit852: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit852 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit852: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit852: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix53 !== "0" ? (
                            this.state.zeit853 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit853: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit853 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit853: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit853: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix54 !== "0" ? (
                            this.state.zeit854 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit854: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit854 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit854: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit854: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix55 !== "0" ? (
                            this.state.zeit855 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit855: "1"})}}>
                                {this.state.zeit5}
                              </td>
                          ) : (
                            this.state.zeit855 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit855: "2"})}}>
                                <div className="sternweiss">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit855: "X"})}}>
                                <div className="sterngold">{this.state.zeit5}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix56 !== "0" ? (
                            this.state.zeit856 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit856: "1"})}}>
                              {this.state.zeit13}
                            </td>
                          ) : (
                            this.state.zeit856 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit856: "2"})}}>
                                <div className="sternweiss">{this.state.zeit13}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit856: "X"})}}>
                                <div className="sterngold">{this.state.zeit13}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix61 !== "0" ? (
                            this.state.zeit861 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit861: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit861 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit861: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit861: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix62 !== "0" ? (
                            this.state.zeit862 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit862: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit862 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit862: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit862: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix63 !== "0" ? (
                            this.state.zeit863 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit863: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit863 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit863: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit863: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix64 !== "0" ? (
                            this.state.zeit864 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit864: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit864 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit864: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit864: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix65 !== "0" ? (
                            this.state.zeit865 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit865: "1"})}}>
                                {this.state.zeit6}
                              </td>
                          ) : (
                            this.state.zeit865 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit865: "2"})}}>
                                <div className="sternweiss">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit865: "X"})}}>
                                <div className="sterngold">{this.state.zeit6}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix66 !== "0" ? (
                            this.state.zeit866 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit866: "1"})}}>
                              {this.state.zeit14}
                            </td>
                          ) : (
                            this.state.zeit866 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit866: "2"})}}>
                                <div className="sternweiss">{this.state.zeit14}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit866: "X"})}}>
                                <div className="sterngold">{this.state.zeit14}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix71 !== "0" ? (
                            this.state.zeit871 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit871: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit871=== "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit871: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit871: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix72 !== "0" ? (
                            this.state.zeit872 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit872: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit872 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit872: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit872: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix73 !== "0" ? (
                            this.state.zeit873 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit873: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit873 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit873: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit873: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix74 !== "0" ? (
                            this.state.zeit874 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit874: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit874 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit874: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit874: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix75 !== "0" ? (
                            this.state.zeit875 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit875: "1"})}}>
                                {this.state.zeit7}
                              </td>
                          ) : (
                            this.state.zeit875 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit875: "2"})}}>
                                <div className="sternweiss">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit875: "X"})}}>
                                <div className="sterngold">{this.state.zeit7}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix76 !== "0" ? (
                            this.state.zeit876 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit876: "1"})}}>
                              {this.state.zeit15}
                            </td>
                          ) : (
                            this.state.zeit876 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit876: "2"})}}>
                                <div className="sternweiss">{this.state.zeit15}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit876: "X"})}}>
                                <div className="sterngold">{this.state.zeit15}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          {this.state.matrix81 !== "0" ? (
                            this.state.zeit881 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit881: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit881 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit881: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit881: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix82 !== "0" ? (
                            this.state.zeit882 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit882: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit882 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit882: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit882: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix83 !== "0" ? (
                            this.state.zeit883 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit883: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit883 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit883: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit883: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix84 !== "0" ? (
                            this.state.zeit884 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit884: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit884 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit884: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit884: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          {this.state.matrix85 !== "0" ? (
                            this.state.zeit885 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                              this.setState({zeit885: "1"})}}>
                                {this.state.zeit8}
                              </td>
                          ) : (
                            this.state.zeit885 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit885: "2"})}}>
                                <div className="sternweiss">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit885: "X"})}}>
                                <div className="sterngold">{this.state.zeit8}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                          <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          {this.state.matrix86 !== "0" ? (
                            this.state.zeit886 === "X" ? (
                            <td className="blauerrand" onClick={(event) => {
                            this.setState({zeit886: "1"})}}>
                              {this.state.zeit16}
                            </td>
                          ) : (
                            this.state.zeit886 === "1" ? (
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit886: "2"})}}>
                                <div className="sternweiss">{this.state.zeit16}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9734;</div></td>
                            ) : ( 
                              <td className="blauezelle" onClick={(event) => {
                                this.setState({zeit886: "X"})}}>
                                <div className="sterngold">{this.state.zeit16}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9733;</div></td>
                            )
                          )) : (
                            <td></td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <br/>
                <div id="roteFarbe">
                  {this.state.errorSchritt5Teilnehmer8Zeit}
                </div>
                <br />
                <div id="roteFarbe">{this.state.errorSchritt5gleich}</div>
              </div>
            </div>
            <br/>
            <div className="zurueckweiter">
              <div className="normal">
                <button onClick={this.handleSubmitSchritt5Back}>
                  Zurück
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <button onClick={this.handleSubmitSchritt5}>
                  Weiter
                </button>
              </div>
            </div>
            <br/>
            <div className="zurueckweiter" id="roteFarbe">
              {this.state.errorSchritt5}
            </div>
          </div>
        );
      }
    }

    /*


-------------------------- SCHRITT 6 --------------------------

  */
    if (this.state.schritt === 6) {
      return (
        <div className="wuensche">
          <UnderHeaderDemoProgramm />
          <div className="container">
            <p>
              <strong id="roteFarbeGross"> 
                Topf 1: {" "}
              </strong>
              Gruppenwünsche
            </p>

            <br/>

            <p>
              Jeder Name darf in der ganzen Tabelle nur einmal vorkommen!
            </p>
            <p>
              Es werden nur Namen angezeigt, bei denen ein Wunsch zeitlich möglich ist.
            </p>

            {/* {this.state.showMoreSchritt6 ? (
              <div className="showmore">
                <p>
                  <button onClick={this.handleChangeshowMoreSchritt6}>
                    Weitere Infos &#9662;
                  </button>
                </p>
              </div>
            ) : (
              <div className="showmore">
                <p className="demovideo1">
                  <video src={Video6} controls></video>
                </p>
                <p>
                  <button onClick={this.handleChangeshowMoreSchritt6}>
                    Weniger Infos &#9652;
                  </button>
                </p>
              </div>
            )} */}
            <br/>
            <p id="FarbeDemo">
              Demo = Vollversion
            </p>
            <div className="tabellewuensche">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <strong>1. Wunsch:</strong>
                    </td>
                    <td>
                      <select
                        value={this.state.wunsch1}
                        onChange={this.handleChangeWunsch1}
                      >
                        <option key=""></option>
                        {this.alleNamenWuensche().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>mit</td>
                    <td>
                      <select
                        value={this.state.wunsch2}
                        onChange={this.handleChangeWunsch2}
                      >
                        <option key=""></option>
                        {this.ersterWunsch(this.state.wunsch1).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch3}
                        onChange={this.handleChangeWunsch3}
                      >
                        <option key=""></option>
                        {this.zweiterWunsch(
                          this.state.wunsch1,
                          this.state.wunsch2
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch4}
                        onChange={this.handleChangeWunsch4}
                      >
                        <option key=""></option>
                        {this.dritterWunsch(
                          this.state.wunsch1,
                          this.state.wunsch2,
                          this.state.wunsch3
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt6Wunsch1}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>2. Wunsch:</strong>
                    </td>
                    <td>
                      <select
                        value={this.state.wunsch5}
                        onChange={this.handleChangeWunsch5}
                      >
                        <option key=""></option>
                        {this.alleNamenWuensche().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>mit</td>
                    <td>
                      <select
                        value={this.state.wunsch6}
                        onChange={this.handleChangeWunsch6}
                      >
                        <option key=""></option>
                        {this.ersterWunsch(this.state.wunsch5).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch7}
                        onChange={this.handleChangeWunsch7}
                      >
                        <option key=""></option>
                        {this.zweiterWunsch(
                          this.state.wunsch5,
                          this.state.wunsch6
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch8}
                        onChange={this.handleChangeWunsch8}
                      >
                        <option key=""></option>
                        {this.dritterWunsch(
                          this.state.wunsch5,
                          this.state.wunsch6,
                          this.state.wunsch7
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt6Wunsch2}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>3. Wunsch:</strong>
                    </td>
                    <td>
                      <select
                        value={this.state.wunsch9}
                        onChange={this.handleChangeWunsch9}
                      >
                        <option key=""></option>
                        {this.alleNamenWuensche().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>mit</td>
                    <td>
                      <select
                        value={this.state.wunsch10}
                        onChange={this.handleChangeWunsch10}
                      >
                        <option key=""></option>
                        {this.ersterWunsch(this.state.wunsch9).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch11}
                        onChange={this.handleChangeWunsch11}
                      >
                        <option key=""></option>
                        {this.zweiterWunsch(
                          this.state.wunsch9,
                          this.state.wunsch10
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch12}
                        onChange={this.handleChangeWunsch12}
                      >
                        <option key=""></option>
                        {this.dritterWunsch(
                          this.state.wunsch9,
                          this.state.wunsch10,
                          this.state.wunsch11
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt6Wunsch3}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>4. Wunsch:</strong>
                    </td>
                    <td>
                      <select
                        value={this.state.wunsch13}
                        onChange={this.handleChangeWunsch13}
                      >
                        <option key=""></option>
                        {this.alleNamenWuensche().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>mit</td>
                    <td>
                      <select
                        value={this.state.wunsch14}
                        onChange={this.handleChangeWunsch14}
                      >
                        <option key=""></option>
                        {this.ersterWunsch(this.state.wunsch13).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch15}
                        onChange={this.handleChangeWunsch15}
                      >
                        <option key=""></option>
                        {this.zweiterWunsch(
                          this.state.wunsch13,
                          this.state.wunsch14
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch16}
                        onChange={this.handleChangeWunsch16}
                      >
                        <option key=""></option>
                        {this.dritterWunsch(
                          this.state.wunsch13,
                          this.state.wunsch14,
                          this.state.wunsch15
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt6Wunsch4}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>5. Wunsch:</strong>
                    </td>
                    <td>
                      <select
                        value={this.state.wunsch17}
                        onChange={this.handleChangeWunsch17}
                      >
                        <option key=""></option>
                        {this.alleNamenWuensche().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>mit</td>
                    <td>
                      <select
                        value={this.state.wunsch18}
                        onChange={this.handleChangeWunsch18}
                      >
                        <option key=""></option>
                        {this.ersterWunsch(this.state.wunsch17).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch19}
                        onChange={this.handleChangeWunsch19}
                      >
                        <option key=""></option>
                        {this.zweiterWunsch(
                          this.state.wunsch17,
                          this.state.wunsch18
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch20}
                        onChange={this.handleChangeWunsch20}
                      >
                        <option key=""></option>
                        {this.dritterWunsch(
                          this.state.wunsch17,
                          this.state.wunsch18,
                          this.state.wunsch19
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt6Wunsch5}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>6. Wunsch:</strong>
                    </td>
                    <td>
                      <select
                        value={this.state.wunsch21}
                        onChange={this.handleChangeWunsch21}
                      >
                        <option key=""></option>
                        {this.alleNamenWuensche().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>mit</td>
                    <td>
                      <select
                        value={this.state.wunsch22}
                        onChange={this.handleChangeWunsch22}
                      >
                        <option key=""></option>
                        {this.ersterWunsch(this.state.wunsch21).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch23}
                        onChange={this.handleChangeWunsch23}
                      >
                        <option key=""></option>
                        {this.zweiterWunsch(
                          this.state.wunsch21,
                          this.state.wunsch22
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch24}
                        onChange={this.handleChangeWunsch24}
                      >
                        <option key=""></option>
                        {this.dritterWunsch(
                          this.state.wunsch21,
                          this.state.wunsch22,
                          this.state.wunsch23
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt6Wunsch6}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>7. Wunsch:</strong>
                    </td>
                    <td>
                      <select
                        value={this.state.wunsch25}
                        onChange={this.handleChangeWunsch25}
                      >
                        <option key=""></option>
                        {this.alleNamenWuensche().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>mit</td>
                    <td>
                      <select
                        value={this.state.wunsch26}
                        onChange={this.handleChangeWunsch26}
                      >
                        <option key=""></option>
                        {this.ersterWunsch(this.state.wunsch25).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch27}
                        onChange={this.handleChangeWunsch27}
                      >
                        <option key=""></option>
                        {this.zweiterWunsch(
                          this.state.wunsch25,
                          this.state.wunsch26
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch28}
                        onChange={this.handleChangeWunsch28}
                      >
                        <option key=""></option>
                        {this.dritterWunsch(
                          this.state.wunsch25,
                          this.state.wunsch26,
                          this.state.wunsch27
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt6Wunsch7}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>8. Wunsch:</strong>
                    </td>
                    <td>
                      <select
                        value={this.state.wunsch29}
                        onChange={this.handleChangeWunsch29}
                      >
                        <option key=""></option>
                        {this.alleNamenWuensche().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>mit</td>
                    <td>
                      <select
                        value={this.state.wunsch30}
                        onChange={this.handleChangeWunsch30}
                      >
                        <option key=""></option>
                        {this.ersterWunsch(this.state.wunsch29).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch31}
                        onChange={this.handleChangeWunsch31}
                      >
                        <option key=""></option>
                        {this.zweiterWunsch(
                          this.state.wunsch29,
                          this.state.wunsch30
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch32}
                        onChange={this.handleChangeWunsch32}
                      >
                        <option key=""></option>
                        {this.dritterWunsch(
                          this.state.wunsch29,
                          this.state.wunsch30,
                          this.state.wunsch31
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt6Wunsch8}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>9. Wunsch:</strong>
                    </td>
                    <td>
                      <select
                        value={this.state.wunsch33}
                        onChange={this.handleChangeWunsch33}
                      >
                        <option key=""></option>
                        {this.alleNamenWuensche().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>mit</td>
                    <td>
                      <select
                        value={this.state.wunsch34}
                        onChange={this.handleChangeWunsch34}
                      >
                        <option key=""></option>
                        {this.ersterWunsch(this.state.wunsch33).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch35}
                        onChange={this.handleChangeWunsch35}
                      >
                        <option key=""></option>
                        {this.zweiterWunsch(
                          this.state.wunsch33,
                          this.state.wunsch34
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch36}
                        onChange={this.handleChangeWunsch36}
                      >
                        <option key=""></option>
                        {this.dritterWunsch(
                          this.state.wunsch33,
                          this.state.wunsch34,
                          this.state.wunsch35
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt6Wunsch9}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>10. Wunsch:</strong>
                    </td>
                    <td>
                      <select
                        value={this.state.wunsch37}
                        onChange={this.handleChangeWunsch37}
                      >
                        <option key=""></option>
                        {this.alleNamenWuensche().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>mit</td>
                    <td>
                      <select
                        value={this.state.wunsch38}
                        onChange={this.handleChangeWunsch38}
                      >
                        <option key=""></option>
                        {this.ersterWunsch(this.state.wunsch37).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch39}
                        onChange={this.handleChangeWunsch39}
                      >
                        <option key=""></option>
                        {this.zweiterWunsch(
                          this.state.wunsch37,
                          this.state.wunsch38
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch40}
                        onChange={this.handleChangeWunsch40}
                      >
                        <option key=""></option>
                        {this.dritterWunsch(
                          this.state.wunsch37,
                          this.state.wunsch38,
                          this.state.wunsch39
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt6Wunsch10}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>11. Wunsch:</strong>
                    </td>
                    <td>
                      <select
                        value={this.state.wunsch41}
                        onChange={this.handleChangeWunsch41}
                      >
                        <option key=""></option>
                        {this.alleNamenWuensche().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>mit</td>
                    <td>
                      <select
                        value={this.state.wunsch42}
                        onChange={this.handleChangeWunsch42}
                      >
                        <option key=""></option>
                        {this.ersterWunsch(this.state.wunsch41).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch43}
                        onChange={this.handleChangeWunsch43}
                      >
                        <option key=""></option>
                        {this.zweiterWunsch(
                          this.state.wunsch41,
                          this.state.wunsch42
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch44}
                        onChange={this.handleChangeWunsch44}
                      >
                        <option key=""></option>
                        {this.dritterWunsch(
                          this.state.wunsch41,
                          this.state.wunsch42,
                          this.state.wunsch43
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt6Wunsch11}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>12. Wunsch:</strong>
                    </td>
                    <td>
                      <select
                        value={this.state.wunsch45}
                        onChange={this.handleChangeWunsch45}
                      >
                        <option key=""></option>
                        {this.alleNamenWuensche().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>mit</td>
                    <td>
                      <select
                        value={this.state.wunsch46}
                        onChange={this.handleChangeWunsch46}
                      >
                        <option key=""></option>
                        {this.ersterWunsch(this.state.wunsch45).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch47}
                        onChange={this.handleChangeWunsch47}
                      >
                        <option key=""></option>
                        {this.zweiterWunsch(
                          this.state.wunsch45,
                          this.state.wunsch46
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch48}
                        onChange={this.handleChangeWunsch48}
                      >
                        <option key=""></option>
                        {this.dritterWunsch(
                          this.state.wunsch45,
                          this.state.wunsch46,
                          this.state.wunsch47
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt6Wunsch12}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>13. Wunsch:</strong>
                    </td>
                    <td>
                      <select
                        value={this.state.wunsch49}
                        onChange={this.handleChangeWunsch49}
                      >
                        <option key=""></option>
                        {this.alleNamenWuensche().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>mit</td>
                    <td>
                      <select
                        value={this.state.wunsch50}
                        onChange={this.handleChangeWunsch50}
                      >
                        <option key=""></option>
                        {this.ersterWunsch(this.state.wunsch49).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch51}
                        onChange={this.handleChangeWunsch51}
                      >
                        <option key=""></option>
                        {this.zweiterWunsch(
                          this.state.wunsch49,
                          this.state.wunsch50
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch52}
                        onChange={this.handleChangeWunsch52}
                      >
                        <option key=""></option>
                        {this.dritterWunsch(
                          this.state.wunsch49,
                          this.state.wunsch50,
                          this.state.wunsch51
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt6Wunsch13}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>14. Wunsch:</strong>
                    </td>
                    <td>
                      <select
                        value={this.state.wunsch53}
                        onChange={this.handleChangeWunsch53}
                      >
                        <option key=""></option>
                        {this.alleNamenWuensche().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>mit</td>
                    <td>
                      <select
                        value={this.state.wunsch54}
                        onChange={this.handleChangeWunsch54}
                      >
                        <option key=""></option>
                        {this.ersterWunsch(this.state.wunsch53).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch55}
                        onChange={this.handleChangeWunsch55}
                      >
                        <option key=""></option>
                        {this.zweiterWunsch(
                          this.state.wunsch53,
                          this.state.wunsch54
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch56}
                        onChange={this.handleChangeWunsch56}
                      >
                        <option key=""></option>
                        {this.dritterWunsch(
                          this.state.wunsch53,
                          this.state.wunsch54,
                          this.state.wunsch55
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt6Wunsch14}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>15. Wunsch:</strong>
                    </td>
                    <td>
                      <select
                        value={this.state.wunsch57}
                        onChange={this.handleChangeWunsch57}
                      >
                        <option key=""></option>
                        {this.alleNamenWuensche().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>mit</td>
                    <td>
                      <select
                        value={this.state.wunsch58}
                        onChange={this.handleChangeWunsch58}
                      >
                        <option key=""></option>
                        {this.ersterWunsch(this.state.wunsch57).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch59}
                        onChange={this.handleChangeWunsch59}
                      >
                        <option key=""></option>
                        {this.zweiterWunsch(
                          this.state.wunsch57,
                          this.state.wunsch58
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch60}
                        onChange={this.handleChangeWunsch60}
                      >
                        <option key=""></option>
                        {this.dritterWunsch(
                          this.state.wunsch57,
                          this.state.wunsch58,
                          this.state.wunsch59
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt6Wunsch15}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>16. Wunsch:</strong>
                    </td>
                    <td>
                      <select
                        value={this.state.wunsch61}
                        onChange={this.handleChangeWunsch61}
                      >
                        <option key=""></option>
                        {this.alleNamenWuensche().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>mit</td>
                    <td>
                      <select
                        value={this.state.wunsch62}
                        onChange={this.handleChangeWunsch62}
                      >
                        <option key=""></option>
                        {this.ersterWunsch(this.state.wunsch61).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch63}
                        onChange={this.handleChangeWunsch63}
                      >
                        <option key=""></option>
                        {this.zweiterWunsch(
                          this.state.wunsch61,
                          this.state.wunsch62
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch64}
                        onChange={this.handleChangeWunsch64}
                      >
                        <option key=""></option>
                        {this.dritterWunsch(
                          this.state.wunsch61,
                          this.state.wunsch62,
                          this.state.wunsch63
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt6Wunsch16}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>17. Wunsch:</strong>
                    </td>
                    <td>
                      <select
                        value={this.state.wunsch65}
                        onChange={this.handleChangeWunsch65}
                      >
                        <option key=""></option>
                        {this.alleNamenWuensche().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>mit</td>
                    <td>
                      <select
                        value={this.state.wunsch66}
                        onChange={this.handleChangeWunsch66}
                      >
                        <option key=""></option>
                        {this.ersterWunsch(this.state.wunsch65).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch67}
                        onChange={this.handleChangeWunsch67}
                      >
                        <option key=""></option>
                        {this.zweiterWunsch(
                          this.state.wunsch65,
                          this.state.wunsch66
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch68}
                        onChange={this.handleChangeWunsch68}
                      >
                        <option key=""></option>
                        {this.dritterWunsch(
                          this.state.wunsch65,
                          this.state.wunsch66,
                          this.state.wunsch67
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt6Wunsch17}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>18. Wunsch:</strong>
                    </td>
                    <td>
                      <select
                        value={this.state.wunsch69}
                        onChange={this.handleChangeWunsch69}
                      >
                        <option key=""></option>
                        {this.alleNamenWuensche().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>mit</td>
                    <td>
                      <select
                        value={this.state.wunsch70}
                        onChange={this.handleChangeWunsch70}
                      >
                        <option key=""></option>
                        {this.ersterWunsch(this.state.wunsch69).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch71}
                        onChange={this.handleChangeWunsch71}
                      >
                        <option key=""></option>
                        {this.zweiterWunsch(
                          this.state.wunsch69,
                          this.state.wunsch70
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch72}
                        onChange={this.handleChangeWunsch72}
                      >
                        <option key=""></option>
                        {this.dritterWunsch(
                          this.state.wunsch69,
                          this.state.wunsch70,
                          this.state.wunsch71
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt6Wunsch18}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>19. Wunsch:</strong>
                    </td>
                    <td>
                      <select
                        value={this.state.wunsch73}
                        onChange={this.handleChangeWunsch73}
                      >
                        <option key=""></option>
                        {this.alleNamenWuensche().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>mit</td>
                    <td>
                      <select
                        value={this.state.wunsch74}
                        onChange={this.handleChangeWunsch74}
                      >
                        <option key=""></option>
                        {this.ersterWunsch(this.state.wunsch73).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch75}
                        onChange={this.handleChangeWunsch75}
                      >
                        <option key=""></option>
                        {this.zweiterWunsch(
                          this.state.wunsch73,
                          this.state.wunsch74
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch76}
                        onChange={this.handleChangeWunsch76}
                      >
                        <option key=""></option>
                        {this.dritterWunsch(
                          this.state.wunsch73,
                          this.state.wunsch74,
                          this.state.wunsch75
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt6Wunsch19}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>20. Wunsch:</strong>
                    </td>
                    <td>
                      <select
                        value={this.state.wunsch77}
                        onChange={this.handleChangeWunsch77}
                      >
                        <option key=""></option>
                        {this.alleNamenWuensche().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>mit</td>
                    <td>
                      <select
                        value={this.state.wunsch78}
                        onChange={this.handleChangeWunsch78}
                      >
                        <option key=""></option>
                        {this.ersterWunsch(this.state.wunsch77).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch79}
                        onChange={this.handleChangeWunsch79}
                      >
                        <option key=""></option>
                        {this.zweiterWunsch(
                          this.state.wunsch77,
                          this.state.wunsch78
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>und</td>
                    <td>
                      <select
                        value={this.state.wunsch80}
                        onChange={this.handleChangeWunsch80}
                      >
                        <option key=""></option>
                        {this.dritterWunsch(
                          this.state.wunsch77,
                          this.state.wunsch78,
                          this.state.wunsch79
                        ).map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt6Wunsch20}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            <br />
            <div className="zurueckweiter">
              <div className="normal">
                <button onClick={this.handleSubmitSchritt6Back}>
                  Zurück
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <button onClick={this.handleSubmitSchritt6}>
                  Weiter
                </button>
              </div>
            </div>
            <div className="zurueckweiter" id="roteFarbe">
              {this.state.errorSchritt6nurzwei}
            </div>
            <div className="zurueckweiter" id="roteFarbe">
              {this.state.errorSchritt6mindestenszwei}
            </div>
            <div className="zurueckweiter" id="roteFarbe">
              {this.state.errorSchritt6}
            </div>
          </div>
        </div>
      );
    }

    /*


-------------------------- SCHRITT 7 --------------------------

  */
    if (this.state.schritt === 7) {
      return (
        <div className="verbot">
          <UnderHeaderDemoProgramm />
          <div className="container">
            <p>
              <strong id="roteFarbeGross"> 
                Topf 1: {" "}
              </strong>
              Gruppenverbote
            </p>
            <br/>
            <p>
              Welche Spielerpaare dürfen auf keinen Fall auftreten?
            </p>

            {/* {this.state.showMoreSchritt7 ? (
              <div className="showmore">
                <p>
                  <button onClick={this.handleChangeshowMoreSchritt7}>
                    Weitere Infos &#9662;
                  </button>
                </p>
              </div>
            ) : (
              <div className="showmore">
                <p className="demovideo1">
                  <video src={Video7} controls></video>
                </p>
                <p>
                  <button onClick={this.handleChangeshowMoreSchritt7}>
                    Weniger Infos &#9652;
                  </button>
                </p>
              </div>
            )} */}
            <br/>
            <p id="FarbeDemo">
              Demo = Vollversion
            </p>
            <div className="tabelleverbot">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <select
                        value={this.state.verbot1}
                        onChange={this.handleChangeVerbot1}
                      >
                        <option key=""></option>
                        {this.alleNamen().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>nicht mit</td>
                    <td>
                      <select
                        value={this.state.verbot2}
                        onChange={this.handleChangeVerbot2}
                      >
                        <option key=""></option>
                        {this.Verbotmoeglichkeiten(this.state.verbot1).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt7Verbot1}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <select
                        value={this.state.verbot3}
                        onChange={this.handleChangeVerbot3}
                      >
                        <option key=""></option>
                        {this.alleNamen().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>nicht mit</td>
                    <td>
                      <select
                        value={this.state.verbot4}
                        onChange={this.handleChangeVerbot4}
                      >
                        <option key=""></option>
                        {this.Verbotmoeglichkeiten(this.state.verbot3).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt7Verbot2}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <select
                        value={this.state.verbot5}
                        onChange={this.handleChangeVerbot5}
                      >
                        <option key=""></option>
                        {this.alleNamen().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>nicht mit</td>
                    <td>
                      <select
                        value={this.state.verbot6}
                        onChange={this.handleChangeVerbot6}
                      >
                        <option key=""></option>
                        {this.Verbotmoeglichkeiten(this.state.verbot5).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt7Verbot3}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <select
                        value={this.state.verbot7}
                        onChange={this.handleChangeVerbot7}
                      >
                        <option key=""></option>
                        {this.alleNamen().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>nicht mit</td>
                    <td>
                      <select
                        value={this.state.verbot8}
                        onChange={this.handleChangeVerbot8}
                      >
                        <option key=""></option>
                        {this.Verbotmoeglichkeiten(this.state.verbot7).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt7Verbot4}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <select
                        value={this.state.verbot9}
                        onChange={this.handleChangeVerbot9}
                      >
                        <option key=""></option>
                        {this.alleNamen().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>nicht mit</td>
                    <td>
                      <select
                        value={this.state.verbot10}
                        onChange={this.handleChangeVerbot10}
                      >
                        <option key=""></option>
                        {this.Verbotmoeglichkeiten(this.state.verbot9).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt7Verbot5}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <select
                        value={this.state.verbot11}
                        onChange={this.handleChangeVerbot11}
                      >
                        <option key=""></option>
                        {this.alleNamen().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>nicht mit</td>
                    <td>
                      <select
                        value={this.state.verbot12}
                        onChange={this.handleChangeVerbot12}
                      >
                        <option key=""></option>
                        {this.Verbotmoeglichkeiten(this.state.verbot11).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt7Verbot6}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <select
                        value={this.state.verbot13}
                        onChange={this.handleChangeVerbot13}
                      >
                        <option key=""></option>
                        {this.alleNamen().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>nicht mit</td>
                    <td>
                      <select
                        value={this.state.verbot14}
                        onChange={this.handleChangeVerbot14}
                      >
                        <option key=""></option>
                        {this.Verbotmoeglichkeiten(this.state.verbot13).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt7Verbot7}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <select
                        value={this.state.verbot15}
                        onChange={this.handleChangeVerbot15}
                      >
                        <option key=""></option>
                        {this.alleNamen().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>nicht mit</td>
                    <td>
                      <select
                        value={this.state.verbot16}
                        onChange={this.handleChangeVerbot16}
                      >
                        <option key=""></option>
                        {this.Verbotmoeglichkeiten(this.state.verbot15).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt7Verbot8}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <select
                        value={this.state.verbot17}
                        onChange={this.handleChangeVerbot17}
                      >
                        <option key=""></option>
                        {this.alleNamen().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>nicht mit</td>
                    <td>
                      <select
                        value={this.state.verbot18}
                        onChange={this.handleChangeVerbot18}
                      >
                        <option key=""></option>
                        {this.Verbotmoeglichkeiten(this.state.verbot17).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt7Verbot9}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <select
                        value={this.state.verbot19}
                        onChange={this.handleChangeVerbot19}
                      >
                        <option key=""></option>
                        {this.alleNamen().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>nicht mit</td>
                    <td>
                      <select
                        value={this.state.verbot20}
                        onChange={this.handleChangeVerbot20}
                      >
                        <option key=""></option>
                        {this.Verbotmoeglichkeiten(this.state.verbot19).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt7Verbot10}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <select
                        value={this.state.verbot21}
                        onChange={this.handleChangeVerbot21}
                      >
                        <option key=""></option>
                        {this.alleNamen().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>nicht mit</td>
                    <td>
                      <select
                        value={this.state.verbot22}
                        onChange={this.handleChangeVerbot22}
                      >
                        <option key=""></option>
                        {this.Verbotmoeglichkeiten(this.state.verbot21).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt7Verbot11}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <select
                        value={this.state.verbot23}
                        onChange={this.handleChangeVerbot23}
                      >
                        <option key=""></option>
                        {this.alleNamen().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>nicht mit</td>
                    <td>
                      <select
                        value={this.state.verbot24}
                        onChange={this.handleChangeVerbot24}
                      >
                        <option key=""></option>
                        {this.Verbotmoeglichkeiten(this.state.verbot23).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt7Verbot12}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <select
                        value={this.state.verbot25}
                        onChange={this.handleChangeVerbot25}
                      >
                        <option key=""></option>
                        {this.alleNamen().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>nicht mit</td>
                    <td>
                      <select
                        value={this.state.verbot26}
                        onChange={this.handleChangeVerbot26}
                      >
                        <option key=""></option>
                        {this.Verbotmoeglichkeiten(this.state.verbot25).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt7Verbot13}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <select
                        value={this.state.verbot27}
                        onChange={this.handleChangeVerbot27}
                      >
                        <option key=""></option>
                        {this.alleNamen().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>nicht mit</td>
                    <td>
                      <select
                        value={this.state.verbot28}
                        onChange={this.handleChangeVerbot28}
                      >
                        <option key=""></option>
                        {this.Verbotmoeglichkeiten(this.state.verbot27).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt7Verbot14}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <select
                        value={this.state.verbot29}
                        onChange={this.handleChangeVerbot29}
                      >
                        <option key=""></option>
                        {this.alleNamen().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>nicht mit</td>
                    <td>
                      <select
                        value={this.state.verbot30}
                        onChange={this.handleChangeVerbot30}
                      >
                        <option key=""></option>
                        {this.Verbotmoeglichkeiten(this.state.verbot29).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt7Verbot15}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <select
                        value={this.state.verbot31}
                        onChange={this.handleChangeVerbot31}
                      >
                        <option key=""></option>
                        {this.alleNamen().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>nicht mit</td>
                    <td>
                      <select
                        value={this.state.verbot32}
                        onChange={this.handleChangeVerbot32}
                      >
                        <option key=""></option>
                        {this.Verbotmoeglichkeiten(this.state.verbot31).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt7Verbot16}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <select
                        value={this.state.verbot33}
                        onChange={this.handleChangeVerbot33}
                      >
                        <option key=""></option>
                        {this.alleNamen().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>nicht mit</td>
                    <td>
                      <select
                        value={this.state.verbot34}
                        onChange={this.handleChangeVerbot34}
                      >
                        <option key=""></option>
                        {this.Verbotmoeglichkeiten(this.state.verbot33).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt7Verbot17}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <select
                        value={this.state.verbot35}
                        onChange={this.handleChangeVerbot35}
                      >
                        <option key=""></option>
                        {this.alleNamen().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>nicht mit</td>
                    <td>
                      <select
                        value={this.state.verbot36}
                        onChange={this.handleChangeVerbot36}
                      >
                        <option key=""></option>
                        {this.Verbotmoeglichkeiten(this.state.verbot35).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt7Verbot18}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <select
                        value={this.state.verbot37}
                        onChange={this.handleChangeVerbot37}
                      >
                        <option key=""></option>
                        {this.alleNamen().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>nicht mit</td>
                    <td>
                      <select
                        value={this.state.verbot38}
                        onChange={this.handleChangeVerbot38}
                      >
                        <option key=""></option>
                        {this.Verbotmoeglichkeiten(this.state.verbot37).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt7Verbot19}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <select
                        value={this.state.verbot39}
                        onChange={this.handleChangeVerbot39}
                      >
                        <option key=""></option>
                        {this.alleNamen().map((e, key) => {
                          return <option key={e}>{e}</option>;
                        })}
                      </select>
                    </td>
                    <td>nicht mit</td>
                    <td>
                      <select
                        value={this.state.verbot40}
                        onChange={this.handleChangeVerbot40}
                      >
                        <option key=""></option>
                        {this.Verbotmoeglichkeiten(this.state.verbot39).map(
                          (e, key) => {
                            return <option key={e}>{e}</option>;
                          }
                        )}
                      </select>
                    </td>
                    <td>
                      <div id="roteFarbe">
                        {this.state.errorSchritt7Verbot20}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            <div className="zurueckweiter">
              <div className="normal">
                <button onClick={this.handleSubmitSchritt7Back}>
                  Zurück
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <button onClick={this.handleSubmitSchritt7}>
                  Abschließen
                </button>
              </div>
            </div>
            <div className="zurueckweiter" id="roteFarbe">
              {this.state.errorSchritt7}
            </div>
          </div>
        </div>
      );
    }
  }
}
