import React from "react";
import { UnderHeaderAGB } from "./UnderHeaderAGB";

export const AGB = () => (
  <div>
    <UnderHeaderAGB />
    <div className="container">
      <p>
        <strong>
          1. Allgemeines und Geltungsbereich
        </strong>
      </p>
      <p>
        1.1.	Die nachfolgenden Allgemeinen Geschäftsbedingungen
        (im Folgenden „AGB“ genannt) gelten für alle Geschäftsbeziehungen 
        zwischen der
        <br/>
        <br/>
        TennisGroupOrganizer – Philipp Frohn und Niklas Wienand GbR
        <br/>
        vertreten durch die Geschäftsführer Philipp Frohn und Niklas Wienand
        <br/>
        Nibelungenstraße 20
        <br/>
        50354 Hürth
        <br/>
        <br/>
        Tel.: 0176 47913345
        <br/>
        E-Mail:{" "}
        <a href="mailto:info@tennisgrouporganizer.com">
          info@tennisgrouporganizer.com
        </a>
        <br/>
        Internet: <a href="/">https://tennisgrouporganizer.com</a>
        <br/>
        USt.-IdNr.: DE328949139
        <br/>
        <br/>
        (im Folgenden <strong>„Provider"</strong> genannt) und den Kunden
        (im Folgenden <strong>„Kunden“</strong>, gemeinschaftlich auch {""}
        <strong>„Parteien“</strong> genannt) des Providers.
      </p>
      <p>
        1.2.	Diese AGB gelten ausschließlich, wenn der Kunde Unternehmer ist.
        Unternehmer ist gem. § 14 BGB eine natürliche oder juristische Person
        oder
        eine rechtsfähige Personengesellschaft, die bei Abschluss eines
        Rechtsgeschäfts in Ausübung ihrer gewerblichen oder selbständigen
        beruflichen Tätigkeit handelt. Demgegenüber ist Verbraucher gem. § 13
        BGB jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt,
        die überwiegend weder ihrer gewerblichen noch ihrer selbständigen
        beruflichen Tätigkeit zugerechnet werden können.
      </p>
      <p>
        1.3.	Unternehmer im Sinne dieser AGB sind auch Vereine, Behörden, Körperschaften,
        Anstalten, Stiftungen, juristische Person des öffentlichen Rechts oder
        ein öffentlich-rechtliches Sondervermögen, die bei Vertragsschluss
        ausschließlich privatrechtlich handeln.
      </p>
      <p>
        1.4.	Die AGB des Providers gelten ausschließlich. Verwendet der Kunde
        entgegenstehende oder ergänzende Allgemeine Geschäftsbedingungen, wird
        deren Geltung hiermit widersprochen; sie werden nur dann
        Vertragsbestandteil, wenn der Provider dem ausdrücklich zugestimmt hat.
      </p>
      <p>
        1.5.	Sofern nichts anderes vereinbart, gelten diese AGB gegenüber Kunden
        in der zum Zeitpunkt der Bestellung des Kunden gültigen bzw. jedenfalls
        in der ihm zuletzt in Textform mitgeteilten Fassung als
        Rahmenvereinbarung auch für gleichartige künftige Verträge, ohne dass
        der Provider in jedem Einzelfall wieder auf sie hinweisen müsste.
      </p>
      <p>
        1.6.	Im Einzelfall getroffene, individuelle Vereinbarungen mit dem
        Kunden (einschließlich Nebenabreden, Ergänzungen und Änderungen) haben
        in jedem Fall Vorrang vor diesen AGB. Für den Inhalt derartiger
        Vereinbarungen ist vorbehaltlich des Gegenbeweises, ein schriftlicher
        Vertrag bzw. eine schriftliche Bestätigung des Providers maßgebend.
      </p>
      <p>
        1.7.	Rechtserhebliche Erklärungen und Anzeigen des Kunden in Bezug auf
        den Vertrag sind schriftlich, d.h. in Schrift- oder Textform (ein mit
        der Post versandter Brief oder E-Mail) abzugeben. Gesetzliche
        Formvorschriften und weitere Nachweise insbesondere bei Zweifeln über
        die Legitimation des Erklärenden bleiben unberührt.
      </p>
      <p>
        1.8.	Hinweise auf die Geltung gesetzlicher Vorschriften haben nur
        klarstellende Bedeutung. Auch ohne eine derartige Klarstellung gelten
        daher die gesetzlichen Vorschriften, soweit sie in diesen AGB nicht
        unmittelbar abgeändert oder ausdrücklich ausgeschlossen werden.
      </p>
      <p>
        <strong>
          2. Vertragsgegenstand
        </strong>
      </p>
      <p>
        2.1.	Der Provider erbringt für den Kunden SaaS-Dienstleistungen über
        das Medium Internet im Bereich der Tennisbranche.
      </p>
      <p>
        2.2.	Vertragsgegenstand ist die Bereitstellung der Software
        „TennisGroupOrganizer“ (nachfolgend als <strong>„Software“</strong>
        {""} bezeichnet) des Providers zur Nutzung über das Internet.
      </p>
      <p>
        <strong>
          3. Vertragsschluss 
        </strong>
      </p>
        <p>
        3.1.	Die Präsentation und Bewerbung der Software im Onlineshop des
        Providers stellen ein verbindliches Angebot seitens des Providers zum
        Abschluss eines SaaS-Vertrages dar.
      </p>
      <p>
        3.2.	Der Kunde kann die Software zunächst unverbindlich auswählen. Mit
        Absenden seiner Bestellung über den Onlineshop durch Anklicken des
        Button „Kaufen“ nimmt der Kunde das rechtsverbindliche Angebot des
        Providers an und der Vertrag kommt zwischen dem Provider und dem Kunden
        zustande. Vor Absenden seiner rechtsverbindlichen Bestellung kann der
        Kunde seine gemachten Eingaben jederzeit über die üblichen Tastatur-
        und Mausfunktionen einsehen und ändern. Der Kunde kann jedoch seine
        Annahme des Vertrages nur abgeben und übermitteln, wenn dieser durch
        Klicken auf den Button „AGB akzeptieren“ diese Vertragsbedingungen
        akzeptiert. Der Provider sendet dem Kunden nach dem Absenden der
        Bestellung eine Bestätigung per E-Mail.
      </p>
      <p>
        3.3.	Ferner lässt der Provider dem Kunden auf dessen Anfrage hin in
        Textform (ein mit der Post versandter Brief oder E-Mail), ein
        verbindliches Angebot zum Verkauf der vom Kunden zuvor ausgewählten
        Software des Providers zukommen.
      </p>
      <p>
        3.4.	Der Provider wird den Zugang der abgegebenen Bestellung des
        Kunden innerhalb von vier (4) Werktagen in Schriftform oder Textform
        (per E-Mail oder ein mit der Post versandter Brief) bestätigen. In
        einer solchen Bestätigung liegt noch keine verbindliche Annahme der
        Bestellung, es sei denn, darin wird neben der Bestätigung des Zugangs
        zugleich die Annahme erklärt.
      </p>
      <p>
        3.5.	Der Provider speichert die Vertragsbestimmungen einschließlich
        der AGB bei Vertragsschluss unter Wahrung des Datenschutzes und sendet
        diese dem Kunden nach Absendung von dessen Bestellung in Textform (per
        E-Mail) zu.
      </p>
      <p>
        3.6.	Dem Kunde wird nach der Kaufabwicklung vom Provider ein
        Nutzerkonto auf der Website des Providers einrichten. Die Bestelldaten
        und der Vertragstext werden nicht im System des Providers gespeichert
        und können aus Sicherheitsgründen nicht mehr über das Nutzerkonto vom
        Kunden eingesehen und abgerufen werden.
      </p>
      <p>
        3.7.	Der Vertragsschluss erfolgt in deutscher Sprache.
      </p>
      <p>
        3.8.	Der Kunde hat sicherzustellen, dass die von ihm zur
        Bestellabwicklung angegebene E-Mail-Adresse zutreffend ist, so dass
        unter dieser Adresse die vom Provider versandten E-Mails empfangen
        werden können. Insbesondere hat der Kunde bei dem Einsatz von
        SPAM-Filtern sicherzustellen, dass alle vom Provider oder von diesem mit
        der Bestellabwicklung beauftragten Dritten versandten E-Mails
        zugestellt werden können.
      </p>
      <p>
        3.9.	Sofern die Parteien Sonderkonditionen vereinbart haben, gelten
        diese grundsätzlich nicht für gleichzeitig laufende und zukünftige
        Vertragsverhältnisse mit dem Kunden.
      </p>
      <p>
        3.10.	Soweit sich nicht aus den gesetzlichen Vorschriften etwas anderes
        ergibt, schuldet der Provider Beratungs- und sonstige
        Unterstützungsleistungen nur, wenn diese als vertragliche
        Hauptleistungspflicht vereinbart werden.
      </p>
      <p>
        <strong>
          4. Softwareüberlassung
        </strong>
      </p>
      <p>
        4.1.	Der Provider stellt dem Kunden für die Dauer dieses Vertrages die
        Software in der jeweils aktuellen Version über das Internet entgeltlich
        zur Verfügung. Zu diesem Zweck richtet der Provider die Software auf
        einem Server ein, der über das Internet für den Kunden erreichbar ist.
      </p>
      <p>
        4.2.	Der jeweils aktuelle Funktionsumfang der Software ergibt sich aus
        ihrer aktuellen Leistungsbeschreibung auf der Website des Providers
        unter <a href="/">https://tennisgrouporganizer.com</a>.
      </p>
      <p>
        4.3.	Der Provider beseitigt nach Maßgabe der technischen Möglichkeiten
        unverzüglich sämtliche Softwarefehler. Ein Fehler liegt dann vor, wenn
        die Software die in der Leistungsbeschreibung angegebenen Funktionen
        nicht erfüllt, fehlerhafte Ergebnisse liefert oder in anderer Weise nicht
        funktionsgerecht arbeitet, so dass die Nutzung der Software unmöglich
        oder eingeschränkt ist.
      </p>
      <p>
        4.4.	Der Provider entwickelt die Software laufend weiter und wird diese
        durch laufende Updates und Upgrades verbessern.
      </p>
      <p>
        4.5.	Gegenüber den Erfüllungsgehilfen des Providers ist dieser alleine
        weisungsbefugt, soweit nicht gesetzliche Weisungsrechte des Kunden
        bestehen.
      </p>
      <p>
        4.6.	Der Provider wird die Interessen des Kunden wahrnehmen. Zur Abgabe
        und Entgegennahme rechtsgeschäftlicher Erklärungen, die den Kunden
        verpflichten, ist er jedoch nicht befugt. Eine Vertretung des Kunden
        gegenüber Dritten durch den Provider bedarf einer ausdrücklichen
        schriftlichen Vollmacht.
      </p>
      <p>
        4.7.	Der Provider ist an keine festen täglichen Zeiten der Erledigung
        seiner Arbeit gebunden, er hat keine Rechtspflicht zum regelmäßigen
        Erscheinen beim Kunden. Auch der Ort der Erledigung der Tätigkeit
        unterliegt der Entscheidung des Providers, es sei denn, der Ort der
        Leistungserbringung ergäbe sich sachnotwendig aus der einzelnen
        Aufgabenstellung.
      </p>
      <p>
        4.8.	Zusätzlich unterliegt der Provider auch keinen Weisungen
        hinsichtlich der Art und Weise der Arbeitsausführung. Auch wird der
        Provider in die Arbeitsorganisation des Kunden nicht eingegliedert,
        insb. erhält er keinen Büroraum zugewiesen und ist weder
        weisungsberechtigt gegenüber Mitarbeitern des Kunden noch ist er diesen
        gegenüber weisungsgebunden.
      </p>
      <p>
        <strong>
          5. Nutzungsrechte
        </strong>
      </p>
      <p>
        5.1.	Der Provider räumt dem Kunden das nicht ausschließliche und nicht
        übertragbare Recht ein, die in diesem Vertrag bezeichnete Software
        während der Dauer des Vertrages im Rahmen der SaaS-Dienste
        bestimmungsgemäß zu nutzen.
      </p>
      <p>
        5.2.	Der Kunde darf die Software nur bearbeiten, soweit dies durch die
        bestimmungsgemäße Benutzung der Software laut jeweils aktueller
        Leistungsbeschreibung abgedeckt ist.
      </p>
      <p>
        5.3.	Der Kunde darf die Software nur vervielfältigen, soweit dies durch
        die bestimmungsgemäße Benutzung der Software laut jeweils aktueller
        Leistungsbeschreibung abgedeckt ist. Zur notwendigen Vervielfältigung
        zählt das Laden der Software in den Arbeitsspeicher auf dem Server des
        Providers, nicht jedoch die auch nur vorübergehende Installation oder
        das Speichern der Software auf Datenträgern (wie etwa Festplatten o.Ä.)
        der vom Kunden eingesetzten Hardware.
      </p>
      <p>
        5.4.	Der Kunde ist nicht berechtigt, die Software Dritten entgeltlich
        oder unentgeltlich zur Nutzung zur Verfügung zu stellen. Eine
        Weitervermietung der Software wird dem Kunden somit ausdrücklich nicht
        gestattet.
      </p>
      <p>
        <strong>
          6. Unterbrechung, Beeinträchtigung der Erreichbarkeit
        </strong>
      </p>
      <p>
        6.1.	Anpassungen, Änderungen und Ergänzungen der
        vertragsgegenständlichen SaaS-Dienste sowie Maßnahmen, die der
        Feststellung und Behebung von Funktionsstörungen dienen, werden nur dann
        zu einer vorübergehenden Unterbrechung oder Beeinträchtigung der
        Erreichbarkeit führen, wenn dies aus technischen Gründen zwingend
        notwendig ist.
      </p>
      <p>
        6.2.	Die Überwachung der Grundfunktionen der SaaS-Dienste erfolgt
        wöchentlich. Die Wartung der SaaS-Dienste ist grundsätzlich täglich
        von 01:00 Uhr bis 23:59 Uhr gewährleistet. Bei schweren Fehlern – die
        Nutzung der SaaS-Dienste ist nicht mehr möglich bzw. ernstlich
        eingeschränkt – erfolgt die Wartung schnellstmöglich ab Kenntnis
        oder Information durch den Kunden. Der Provider wird den Kunden von den
        Wartungsarbeiten umgehend verständigen und den technischen Bedingungen
        entsprechend in der möglichst kürzesten Zeit durchführen.
      </p>
      <p>
        6.3.	Sofern die Fehlerbehebung nicht innerhalb von 72 Stunden möglich
        sein sollte, wird der Provider den Kunden davon binnen 48 Stunden unter
        Angabe von Gründen sowie des Zeitraums, der für die Fehlerbeseitigung
        voraussichtlich zu veranschlagen ist, per E-Mail verständigen.
      </p>
      <p>
        6.4.	Die Verfügbarkeit der jeweils vereinbarten Dienste nach Ziffer
        2.2. dieses Vertrags beträgt 98,5 % im Jahresdurchschnitt
        einschließlich Wartungsarbeiten, jedoch darf die Verfügbarkeit nicht
        länger als sieben (7) Tage in Folge beeinträchtigt oder unterbrochen
        sein. Unvermeidbare, unvorhersehbare und außergewöhnliche Ereignisse,
        die zur Nichtverfügbarkeit der SaaS-Dienste führen können, wie z.B.
        Stromausfälle, Hackingeingriffe, Ausfälle der
        Telekommunikationsleitungen ab dem Übergabepunkt an das Internet werden
        auf das Verfügbarkeitsminimum nicht angerechnet.
      </p>
      <p>
        <strong>
          7. Pflichten des Kunden
        </strong>
      </p>
      <p>
        7.1.	Der Kunde ist verpflichtet, den unbefugten Zugriff Dritter auf die
        geschützten Bereiche der Software durch geeignete Vorkehrungen zu
        verhindern. Zu diesem Zwecke wird der Kunde, soweit erforderlich, seine
        Mitarbeiter auf die Einhaltung des Urheberrechts hinweisen.
      </p>
      <p>
        7.2.	Unbeschadet der Verpflichtung des Providers zur Datensicherung ist
        der Kunde selbst für die Eingabe und Pflege seiner zur Nutzung der
        SaaS-Dienste erforderlichen Daten und Informationen verantwortlich.
      </p>
      <p>
        7.3.	Der Kunde ist verpflichtet, seine Daten und Informationen vor der
        Eingabe auf Viren oder sonstige schädliche Komponenten zu prüfen und
        hierzu dem Stand der Technik entsprechende Virenschutzprogramme
        einzusetzen.
      </p>
      <p>
        7.4.	Der Kunde wird für den Zugriff auf die Nutzung der SaaS-Dienste
        einen „Username“ und ein Passwort vom Provider erhalten, die zur
        weiteren Nutzung der SaaS-Dienste erforderlich sind. Der Kunde ist
        verpflichtet, sein „Username“ und Passwort geheim zu halten und Dritten
        gegenüber nicht zugänglich zu machen.
      </p>
      <p>
        <strong>
          8. Vergütung und Zahlungsbedingungen
        </strong>
      </p>
      <p>
        8.1.	Sofern sich aus dem Angebot des Providers nichts anderes ergibt,
        handelt es sich bei der angegebenen Vergütung um Gesamtpreise. Der Kunde
        verpflichtet sich, dem Provider für die Überlassung der Software die
        vereinbarte Vergütung zu bezahlen. Sofern nicht anders vereinbart,
        richtet sich die Vergütung nach der im Zeitpunkt des Vertragsschlusses
        gültigen Preisliste des Providers. Die angegebene Vergütung versteht
        sich in EURO und ist ein Nettopreis zzgl. der am Tage der
        Rechnungsstellung geltenden gesetzlichen Umsatzsteuer.
      </p>
      <p>
        8.2.	Einwendungen gegen die Abrechnung der vom Provider erbrachten
        Leistungen hat der Kunde innerhalb einer Frist von sieben (7) Tagen nach
        Zugang der Rechnung schriftlich bei der auf der Rechnung angegebenen
        Stelle zu erheben. Nach Ablauf der vorgenannten Frist gilt die
        Abrechnung als vom Kunden genehmigt. Der Provider wird den Kunden mit
        Übersendung der Rechnung auf die Bedeutung seines Verhaltens besonders
        hinweisen.
      </p>
      <p>
        8.3.	Die Zahlung ist sofort nach Vertragsabschluss fällig, sofern die
        Parteien keinen späteren Fälligkeitstermin vereinbart haben. Die
        Bereitstellung der Software erfolgt nach Zahlungseingang.
      </p>
      <p>
        8.4.	Aufrechnungsrechte stehen dem Kunden nur zu, wenn seine
        Gegenansprüche rechtskräftig festgestellt oder unbestritten mit der
        Hauptforderung des Providers gegenseitig verknüpft oder von diesem
        anerkannt sind.
      </p>
      <p>
        8.5.	Ein Zurückbehaltungsrecht des Kunden ist ausgeschlossen, es sei
        denn, die Gegenforderung des Kunden stammt aus demselben
        Vertragsverhältnis und ist unbestritten oder rechtskräftig festgestellt.
        Zur Geltendmachung des Rechts ist eine schriftliche Anzeige an den
        Provider erforderlich.
      </p>
      <p>
        8.6.	Wird nach Abschluss des Vertrags erkennbar (z.B. durch Antrag auf
        Eröffnung eines Insolvenzverfahrens), dass der Anspruch des Providers
        auf die Vergütung durch mangelnde Leistungsfähigkeit des Kunden
        gefährdet wird, so ist der Provider nach den gesetzlichen Vorschriften
        zur Leistungsverweigerung und – gegebenenfalls nach Fristsetzung – zum
        Rücktritt vom Vertrag berechtigt (§ 321 BGB).
      </p>
      <p>
        <strong>
          9. Haftung für Mängel und Schäden
        </strong>
      </p>
      <p>
        9.1.	Der Provider garantiert die Funktions- und die
        Betriebsbereitschaft der SaaS-Dienste nach den Bestimmungen dieses
        Vertrages.
      </p>
      <p>
        9.2.	Für den Fall, dass Leistungen des Providers von unberechtigten
        Dritten unter Verwendung der Zugangsdaten des Kunden in Anspruch
        genommen werden, haftet der Kunde für dadurch anfallende Entgelte im
        Rahmen der zivilrechtlichen Haftung bis zum Eingang des Kundenauftrages
        zur Änderung der Zugangsdaten oder der Meldung des Verlusts oder
        Diebstahls, sofern den Kunden am Zugriff des unberechtigten Dritten ein
        Verschulden trifft.
      </p>
      <p>
        9.3.	Schadensersatzansprüche gegen den Provider sind unabhängig vom
        Rechtsgrund ausgeschlossen, es sei denn, der Provider, seine
        gesetzlichen Vertreter oder Erfüllungsgehilfen haben vorsätzlich oder
        grob fahrlässig gehandelt.
      </p>
      <p>
        9.4.	Der Provider haftet nicht für Folgeschäden (z.B. Trainingsausfall
        durch nicht erstellten Wochenplan), die von Fehlern der Software
        verursacht wurden.
      </p>
      <p>
        <strong>
          10.	Vertragsdauer und Kündigung
        </strong>
      </p>
      <p>
        Der SaaS-Vertrag wird befristet für den Zeitraum einer Saison geschlossen.
      </p>
      <p>
        <strong>
          11.	Datenschutz und Geheimhaltung
        </strong>
      </p>
      <p>
        11.1.	Der Provider erhebt und speichert die für die Geschäftsabwicklung
        notwendigen Daten des Kunden. Bei der Verarbeitung der personenbezogenen
        Daten des Kunden beachtet der Provider die gesetzlichen Bestimmungen.
        Der Provider ist berechtigt, diese Daten an mit der Durchführung der
        Bestellung beauftragte Dritte zu übermitteln, soweit dies zur Erfüllung
        des Vertrages notwendig ist. Nähere Einzelheiten ergeben sich aus der
        sich im Online-Angebot abrufbaren Datenschutzerklärung des Providers.
      </p>
      <p>
        11.2.	Der Kunde erhält auf Anforderung jederzeit Auskunft über die zu
        seiner Person gespeicherten Daten.
      </p>
      <p>
        11.3.	Im Übrigen gelten die gesetzlichen Datenschutzbestimmungen,
        insbesondere der Datenschutz-Grundverordnung (DS-GVO), des
        Bundesdatenschutzgesetzes neue Fassung (BDSG-neu) und des
        Telemediengesetzes (TMG).
      </p>
      <p>
        11.4.	Der Provider verpflichtet sich, über alle ihm im Rahmen der
        Vorbereitung, Durchführung und Erfüllung dieses Vertrages zur Kenntnis
        gelangten vertraulichen Vorgänge, insbesondere Geschäfts- oder
        Betriebsgeheimnisse des Kunden, strengstes Stillschweigen zu bewahren
        und diese weder weiterzugeben noch auf sonstige Art zu verwerten. Dies
        gilt gegenüber jeglichen unbefugten Dritten, d.h. auch gegenüber
        unbefugten Mitarbeitern sowohl des Providers als auch des Kunden,
        sofern die Weitergabe von Informationen nicht zur ordnungsgemäßen
        Erfüllung der vertraglichen Verpflichtungen des Providers erforderlich
        ist. In Zweifelsfällen wird sich der Provider vom Kunden vor einer
        solchen Weitergabe eine Zustimmung erteilen lassen.
      </p>
      <p>
        11.5.	Der Provider verpflichtet sich, mit allen von ihm im Zusammenhang
        mit der Vorbereitung, Durchführung und Erfüllung dieses Vertrages
        eingesetzten Mitarbeitern und Nachunternehmern eine mit in Ziffer 11.4
        inhaltsgleiche Regelung zu vereinbaren.
      </p>
      <p>
        11.6.	Der Provider hat an allen Texten, Bildern, Filmen, GIFs, die auf seiner
        Website veröffentlicht werden, die Urheberrechte. Eine Verwendung der
        Bilder, GIFs, Filme und Texte ist ohne die ausdrückliche Zustimmung des
        Providers nicht gestattet.
      </p>
      <p>
        <strong>
          12.	Änderung der AGB
        </strong>
      </p>
      <p>
        12.1.	Der Provider behält sich vor, diese AGB jederzeit ohne Angabe von
        Gründen zu ändern, es sei denn, dies ist für den Kunden nicht zumutbar.
        Der Provider wird den Kunden über Änderungen der AGB rechtzeitig in
        Textform benachrichtigen. Widerspricht der Kunde der Geltung der neuen
        AGB nicht innerhalb einer Frist von vier (4) Wochen nach der
        Benachrichtigung, gelten die geänderten AGB als vom Kunden angenommen.
        Der Provider wird den Kunden in der Benachrichtigung auf sein
        Widerspruchsrecht und die Bedeutung der Widerspruchsfrist hinweisen.
        Widerspricht der Kunde den Änderungen innerhalb der vorgenannten Frist,
        so besteht das Vertragsverhältnis zu den ursprünglichen Bedingungen
        fort.
      </p>
      <p>
        12.2.	Der Provider behält sich darüber hinaus vor, diese AGB zu ändern,
        <br/>
        •	soweit er hierzu aufgrund einer Änderung der Rechtslage verpflichtet
        ist;
        <br/>
        •	soweit er damit einem gegen sich gerichteten Gerichtsurteil oder einer
        Behördenentscheidung nachkommt;
        <br/>
        •	soweit er zusätzliche, gänzlich neue Dienstleistungen, Dienste oder
        Dienstelemente einführt, die einer Leistungsbeschreibung in den AGB
        bedürfen, es sei denn, das bisherige Nutzungsverhältnis wird dadurch
        nachteilig verändert;
        <br/>
        •	wenn die Änderung lediglich vorteilhaft für den Kunden ist; oder
        <br/>
        •	wenn die Änderung rein technisch oder prozessual bedingt ist, es sei
        denn, sie hat wesentliche Auswirkungen für den Nutzer.
      </p>
      <p>
        12.3.	Das Kündigungsrecht des Kunden bleibt hiervon unberührt.
      </p>
      <p>
        <strong>
          13.	Schlussbestimmungen
        </strong>
      </p>
      <p>
        13.1.	Für diese AGB und die Vertragsbeziehung zwischen den Parteien gilt
        das Recht der Bundesrepublik Deutschland unter Ausschluss
        internationalen Einheitsrechts, insbesondere des UN-Kaufrechts.
      </p>
      <p>
        13.2.	Ist der Kunde Kaufmann i.S.d. Handelsgesetzbuchs, Unternehmer
        i.S.v. § 14 BGB, juristische Person des öffentlichen Rechts oder ein
        öffentlich-rechtliches Sondervermögen, ist ausschließlicher – auch
        internationaler Gerichtsstand für alle sich aus dem Vertragsverhältnis
        unmittelbar oder mittelbar ergebenden Streitigkeiten der Geschäftssitz
        des Providers in Hürth. Der Provider ist in allen Fällen auch
        berechtigt, Klage am Erfüllungsort der Leistungsverpflichtung gemäß
        diesen AGB bzw. einer vorrangigen Individualabrede oder am allgemeinen
        Gerichtsstand des Kunden zu erheben. Vorrangige gesetzliche Vorschriften,
        insbesondere zu ausschließlichen Zuständigkeiten, bleiben unberührt.
      </p>
      <strong>Stand: 01.01.2021</strong>

    </div>
  </div>
);
