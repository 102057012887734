import React from "react";
import { UnderHeaderKontakt } from "./components/UnderHeaderKontakt";

export class Kontakt extends React.Component {
  render() {
    if(window.screen.availWidth > 900) {
      return(
        <div>
          <UnderHeaderKontakt />
          <div className="container">
            <div>
              TennisGroupOrganizer - Philipp Frohn und Niklas Wienand GbR
              <br />
              Nibelungenstraße 20
              <br />
              50354 Hürth
              <br />
              <br />

              <table>
                <tbody>
                  <tr>
                    <td>
                      <big><big>&#9742;</big></big>
                    </td>
                    <td>
                      0176 47913345
                    </td>
                  </tr>
                  <tr>
                    <td>
                    <big><big>&#9993;</big></big>
                    </td>
                    <td>
                      <u><a href="mailto:info@tennisgrouporganizer.com">
                        info@tennisgrouporganizer.com
                      </a></u>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <br/>
            <br/>
            
            <table>
              <tbody>
                <tr>
                  <td>
                    <big><big>
                      <strong className="blauerhintergrund">
                        Lassen Sie uns über Ihr Anliegen sprechen.
                      </strong>
                    </big></big>
                  </td>
                </tr>
              </tbody>
            </table>
            
            Schreiben Sie uns einfach eine E-Mail oder rufen Sie uns an. Wir freuen
            uns von Ihnen zu hören.
          </div>

          <br/>
          <br/>

          <div className="container">
            <div className="black">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div className="IchFoto450"></div>
                    </td>
                    <td>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </td>
                    <td className="weiss">
                      <div className="container">
                        <big>
                          Gründer & Programmierer
                        </big>
                        <br/>
                        <big><big><big>
                          <strong className="weisserhintergrund">
                            Philipp Frohn
                          </strong>
                        </big></big></big>
                        <br/>
                        <br/>
                        Schon seit meinem 7. Lebensjahr spiele ich Tennis. Heute bin
                        ich lizensierter C-Trainer im THC Hürth. Bei einem
                        Gespräch mit meinem Chef-Trainer, der für die Gruppeneinteilung
                        zuständig ist, kam mir der Gedanke zum Programm. Dies
                        geschah nicht ganz durch Zufall: In meinem Mathematikstudium
                        hörte ich zuvor eine Vorlesung, die sich mit Problemen
                        dieser Art beschäftigte.
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <br/>
          <br/>
          <br/>
          <br/>
          
          <div className="container">
            <table>
              <tbody>
                <tr>
                  <td>
                    <big><big>
                      <strong className="blauerhintergrund">
                        Sie möchten mehr erfahren?
                      </strong>
                    </big></big>
                  </td>
                </tr>
              </tbody>
            </table>
            
            <p className="normal">
              <button
                onClick={(event) => {document.location.href="/gruenderstory"}}>
                Gründerstory
              </button>
              {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button
                onClick={(event) => {document.location.href="/faq"}}>
                FAQ
              </button> */}
            </p>
          </div>
        </div>
      );
    }
    else {
      return(
        <div>
          <UnderHeaderKontakt />
          <div className="container">
            <div>
              TennisGroupOrganizer - Philipp Frohn und Niklas Wienand GbR
              <br />
              Nibelungenstraße 20
              <br />
              50354 Hürth
              <br />
              <br />

              <table>
                <tbody>
                  <tr>
                    <td>
                      <big><big>&#9742;</big></big>
                    </td>
                    <td>
                      0176 47913345
                    </td>
                  </tr>
                  <tr>
                    <td>
                    <big><big>&#9993;</big></big>
                    </td>
                    <td>
                      <u><a href="mailto:info@tennisgrouporganizer.com">
                        info@tennisgrouporganizer.com
                      </a></u>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <br/>
            <br/>
            
            <table>
              <tbody>
                <tr>
                  <td>
                    <big><big>
                      <strong className="blauerhintergrund">
                        Lassen Sie uns über Ihr Anliegen sprechen.
                      </strong>
                    </big></big>
                  </td>
                </tr>
              </tbody>
            </table>
            
            Schreiben Sie uns einfach eine E-Mail oder rufen Sie uns an. Wir freuen
            uns von Ihnen zu hören.
          </div>

          <br/>
          <br/>

          <div className="black">
            <table>
              <tbody>
                <tr>
                  <td className="weiss">
                    <div className="container">
                      <big>
                        Gründer & Programmierer
                      </big>
                      <br/>
                      <big><big><big>
                        <strong className="weisserhintergrund">
                          Philipp Frohn
                        </strong>
                      </big></big></big>
                      <br/>
                      <br/>
                      Schon seit meinem 7. Lebensjahr spiele ich Tennis. Heute bin
                      ich lizensierter C-Trainer im THC Hürth. Bei einem
                      Gespräch mit meinem Chef-Trainer, der für die Gruppeneinteilung
                      zuständig ist, kam mir der Gedanke zum Programm. Dies
                      geschah nicht ganz durch Zufall: In meinem Mathematikstudium
                      hörte ich zuvor eine Vorlesung, die sich mit Problemen
                      dieser Art beschäftigte.
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <br/>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="IchFoto300"></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          
          <br/>
          <br/>
          <br/>
          <br/>
          
          <div className="container">
            <table>
              <tbody>
                <tr>
                  <td>
                    <big><big>
                      <strong className="blauerhintergrund">
                        Sie möchten mehr erfahren?
                      </strong>
                    </big></big>
                  </td>
                </tr>
              </tbody>
            </table>
            
            <p className="normal">
              <button
                onClick={(event) => {document.location.href="/gruenderstory"}}>
                Gründerstory
              </button>
              {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button
                onClick={(event) => {document.location.href="/faq"}}>
                FAQ
              </button> */}
            </p>
          </div>
        </div>
      );
    }
  }
}
