import React from "react";
import "./components.css";

export const UnderHeader = () => (
  <div className="underheader container">
    <div>
      <h1>Willkommen</h1>
      <p> auf der Website von TennisGroupOrganizer</p>
    </div>
  </div>
);
