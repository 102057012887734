import axios from "axios";
import React from "react";
import "./components.css";

export const UnderHeaderLogin = () => (
  <div className="underheader container">
    <h1>Login</h1>
  </div>
);

export const UnderHeaderAnmeldung = () => (
  <div className="underheader container">
    <h1>Anmeldung</h1>
  </div>
);

export const UnderHeaderEingelogt = () => (
  <div className="underheader container">
    <h1>Login-Bereich</h1>
    <div className="normal">
      <button
          onClick={() => {
            
            var local = window.location.pathname;
            var output = "";
            if (local.length === 13) {
            output = local.slice(-1);
            }
            if (local.length === 14) {
            output = local.slice(-2);
            }
            if (local.length === 15) {
            output = local.slice(-3);
            }
            if (local.length === 16) {
            output = local.slice(-4);
            }
            if (local.length === 17) {
            output = local.slice(-5);
            }
            if (local.length === 18) {
            output = local.slice(-6);
            }
            if (local.length === 19) {
            output = local.slice(-7);
            }
            if (local.length === 20) {
            output = local.slice(-8);
            }
              
            const postrequest = "".concat("/api/Abmelden/", output);

            axios.post(postrequest, {timeout: 0})
                .then(
                (response) => {
                  console.log(response);
                },
                (error) => {
                  console.log(error);
                }
            )

            document.location.href="/login";
            }
            
          }>
          Abmelden
      </button>
    </div>
  </div>
);

export const UnderHeaderAdmin = () => (
  <div className="underheader container">
    <h1>Admin-Bereich</h1>
    <div className="normal">
      <button
        onClick={() => {
          var local = window.location.pathname;
          var output = "";
          if (local.length === 13) {
          output = local.slice(-1);
          }
          if (local.length === 14) {
          output = local.slice(-2);
          }
          if (local.length === 15) {
          output = local.slice(-3);
          }
          if (local.length === 16) {
          output = local.slice(-4);
          }
          if (local.length === 17) {
          output = local.slice(-5);
          }
          if (local.length === 18) {
          output = local.slice(-6);
          }
          if (local.length === 19) {
          output = local.slice(-7);
          }
          if (local.length === 20) {
          output = local.slice(-8);
          }
            
          const postrequest = "".concat("/api/Abmelden/", output);

          axios.post(postrequest, {timeout: 0})
              .then(
              (response) => {
                console.log(response);
              },
              (error) => {
                console.log(error);
              }
          )
          
          document.location.href="/login";
          }
          
        }>
        Abmelden
      </button>
    </div>
  </div>
  
);